import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CALENDAR_ITEM_BASE_HEIGTH_REM } from '../../calendar-detail-view.component';
import { DragDropService } from '@service/drag-drop.service';

export class CreateCalendarItemData{
  public time: number;
  public position: number;
}

@Component({
  selector: 'tun-create-calendar-item',
  templateUrl: './create-calendar-item.component.html',
  styleUrls: ['./create-calendar-item.component.scss']
})
export class CreateCalendarItemComponent {

  public baseREM = CALENDAR_ITEM_BASE_HEIGTH_REM;

  menuOpen: boolean;

  @Input() public time: number;
  @Input() public position: number;

  @Output() createMusicChannelCalendarItem = new EventEmitter<CreateCalendarItemData>();
  @Output() createPlaylistCalendarItem = new EventEmitter<CreateCalendarItemData>();

  public get timeInMilliseconds(): number {
    return this.time * 3600000;
  }

  public get isMouseDown$(){
    return this._dragDropService.isMouseDown$;
  }

  constructor(
    private _dragDropService: DragDropService,
  ) {}

  public onChooseMusicChannel(){
    const data = new CreateCalendarItemData()
    data.time = this.time;
    data.position = this.position;
    this.createMusicChannelCalendarItem.emit(data);
  }

  public onChoosePlaylist(){
    const data = new CreateCalendarItemData()
    data.time = this.time;
    data.position = this.position;
    this.createPlaylistCalendarItem.emit(data);
  }
}
