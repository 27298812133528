<div
  tunCustomOverlay
  class="headerBarBackground blackBottomBorder"
  [style.height.px]="heightPerItem - 1"
>
  <ng-container *ngIf="selectedCalendar != null">
    <!-- Clock button for navigating to current day and hour -->
    <div class="currentDay">
      <div
        class="icon-container"
        (click)="goBackToCurrentDayAndHour(currentIndex)"
        [style.height.px]="clockIconHeight"
        [style.padding]="clockIconPadding"
      >
        <tun-clock-icon [color]="'#DEDEDE'"></tun-clock-icon>
      </div>

      <!-- Day Navigator-->
      <tun-day-navigator
        [heightPerItem]="heightPerItem"
        [iconHeight]="iconHeight"
        [iconPadding]="iconPadding"
        [inEditMode]="inEditMode"
        [currentIndex]="currentIndex"
        (daychange)="onDayChange($event)"
      ></tun-day-navigator>

      <!-- Edit calendar button-->
      <div
        #editButtonElement
        tunPopupOnHover
        (togglepopup)="toggleTooltip($event)"
        class="icon-container light-background-hover edit-icon"
        [class.disabled]="!isCalendarEditable"
        [style.height.px]="iconHeight"
        [style.padding]="iconPadding"
        (click)="toggleEditMode()"
      >
        <tun-edit-icon *ngIf="!inEditMode"></tun-edit-icon>
        <tun-close-icon [color]="'#FFF'" *ngIf="inEditMode"></tun-close-icon>
      </div>
    </div>
  </ng-container>
</div>
