import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tun-plus-icon',
  templateUrl: './plus-icon.component.html',
  styleUrls: ['./plus-icon.component.scss']
})
export class PlusIconComponent implements OnInit {

  @Input() color: string;
  
  constructor() { }

  ngOnInit() {
  }

}
