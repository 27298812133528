<tun-navigation-menu  fxFlex fxLayout="column"
  [mainNavigationItems]="mainNavigationItems"
  [numberExtraItems]="0"
  [heightPerItem]="heightForMenuItem"
  [helpMessage]="helpMessage"
  [loading]="loading$ | async"
  [showLoadError]="loadingError$ | async"
  (selectMainNavigationItem)="onSelectMainNavigationItem($event)"
  (selectNavigationItem)="onSelectNavigationItem($event)"
  (deleteNavigationItem)="onDeleteNavigationItem($event)"
  (createItemWithTitle)="onCreateItemWithTitle($event)"
  (changeNavigationItemTitle)="onChangeItemTitle($event)"
  (reloadMenuContent)="onReloadMenuContent()"
>
</tun-navigation-menu>
