import { Component, EventEmitter, Output } from '@angular/core';
import { SETTINGS_VIEW } from '../settings-panel.component';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { map } from 'rxjs/operators';
import { AppVersionService } from '@service/app-version.service';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tun-settings-home-panel',
  templateUrl: './settings-home-panel.component.html',
  styleUrls: ['./settings-home-panel.component.scss']
})
export class SettingsHomePanelComponent {

  @Output() openSettingsView = new EventEmitter<SETTINGS_VIEW>();

  public SETTINGS_VIEW = SETTINGS_VIEW;

  public get isDesktopApp(){
    return isDesktopApp();
  }

  get enableDownloadApp(){
    return environment.enableDownloadApp;
  }

  public get language$(){
    return this.zoneConfigurationService.language$
    .pipe(
      (map(lang => {
        return "settings.language." + lang;
      }))
    )
  }

  public get version$(){
    return this.appVersionService.version$;
  }

  constructor(
    private zoneConfigurationService: ZoneConfigurationService,
    private appVersionService: AppVersionService
  ) {

  }

  public onOpen(settingsView: SETTINGS_VIEW){
    this.openSettingsView.emit(settingsView);
  }
}
