<div class="header" translate>
  {{ header }}
  <button class="close-button" (click)="onCloseClick()">
    <tun-close-icon></tun-close-icon>
  </button>
</div>
<div class="section" *ngFor="let optionSection of optionItems">
  <button
    class="option"
    *ngFor="let option of optionSection"
    (click)="onOptionClick(option)"
    translate
  >
    <tun-check-icon
      [style.visibility]="isSelected(option) ? 'visible' : 'hidden'"
    ></tun-check-icon>
    {{ option.description }}
  </button>
</div>
<div *ngIf="showConnectedZone" fxLayout="column" class="section">

  <div fxLayout="column" class="zone-connection-container">

    <div fxFlex fxLayout="row">
      <div class="location-container icon-container">
        <tun-location-icon></tun-location-icon>
      </div>
      <div fxFlex fxLayout="column" class="zone-container">
        <label class="name-label">{{nameForView}}</label>
        <label *ngIf="locationName != null">{{locationName}}</label>
      </div>
    </div>

    <div class="button-container" [style.height.px]="heightPerItem$ | async" fxLayout="row">
      <button class="disconnect-button" (click)="disconnect()">{{disconnectLabel$ | async | translate}}</button>
    </div>

  </div>
</div>
