import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface UpgradeVersionPopupComponentData{
  confirm: boolean;
}

@Component({
  selector: 'tun-upgrade-version-popup',
  templateUrl: './upgrade-version-popup.component.html',
  styleUrls: ['./upgrade-version-popup.component.scss'],
})
export class UpgradeVersionPopupComponent {

  public static widthForPopup = "max(20rem,50vw)";

  constructor(
    public dialogRef: MatDialogRef<UpgradeVersionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpgradeVersionPopupComponentData,
    private trans: TranslateService
  ) {

  }

  public onYesClick(){
    this.data.confirm = true;
    this.dialogRef.close(this.data);
  }

  public onNoClick(){
    this.data.confirm = false;
    this.dialogRef.close(this.data);
  }
}
