import { Component, ChangeDetectorRef } from '@angular/core';
import { PlaylistComponent } from '@components/app-components/panels/playlist/playlist.component';
import { AppService } from '@service/app.service';
import { SongSorter } from '@components/app-components/fields/song-grid/song-sorter.pipe';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { PopupService } from '@service/popup.service';
import {
  trigger
} from '@angular/animations';
import { PlaylistService } from '@service/playlist.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tun-search-results-playlist',
  templateUrl: './search-results-playlist.component.html',
  styleUrls: ['./search-results-playlist.component.scss'],
  // memory leak work-around: add no-op animation in parent component of each songGrid. Otherwise, the @HostBinding('@fadeIn') animation causes the component to stay in memory.
  animations: [trigger("noop", [])]
})
export class SearchResultsPlaylistComponent extends PlaylistComponent {
  constructor(
    appService: AppService,
    _songSorter: SongSorter,
    _zoneConfigurationService: ZoneConfigurationService,
    _popupService: PopupService,
    _musicManipulationService: MusicManipulationService,
    _playlistService: PlaylistService,
    _changeDetectorRef: ChangeDetectorRef
  ) {
    super(appService, _songSorter, _zoneConfigurationService, _popupService, _musicManipulationService, _playlistService, _changeDetectorRef);
  }

  get enableSongRequests(){
    return environment.enableSongRequests;
  }
}
