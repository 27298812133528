<div fxFlex fxLayout="column" class="content">

    <div  class="title">
      <ng-container *ngIf="!(showRemotePlayer$ | async) || (remotePlayerActive$ | async)">
        Tunify Connect
      </ng-container>
      <ng-container *ngIf="(showRemotePlayer$ | async) && !(remotePlayerActive$ | async)">
        {{'connect.closed' | translate}}
      </ng-container>

    </div>


    <div class="remoteButtonContainer">
      <button
        class="connectButton {{(showRemotePlayer$ | async) ? 'remoteButton' : 'playerButton'}}"
        disabled="true"
      >
        <tun-remote-icon class="remote-icon"></tun-remote-icon>
      </button>
    </div>



    <!-- we are remote: show player -->
    <ng-container *ngIf="(showRemotePlayer$ | async) && (remotePlayerActive$ | async)">
      <div class="info">
        {{'connect.info1' | translate}}
      </div>

      <div fxLayout="column" class="devices">
        <div *ngIf="(remotePlayerApplicationInfo$ | async) != null">
          {{(remotePlayerApplicationInfo$ | async).deviceInfo}}
        </div>
      </div>

      <div class="info infoLast">
        {{'connect.info2' | translate}}
      </div>
    </ng-container>

    <!-- we are remote: no player active -->
    <ng-container *ngIf="(showRemotePlayer$ | async) && !(remotePlayerActive$ | async)">
      <div class="info">
        {{'connect.noPlayer.info1' | translate}}
      </div>

      <div *ngIf="(lastPlayerApplicationInfo$ | async) != null" fxLayout="column" class="devices">
        {{(lastPlayerApplicationInfo$ | async).deviceInfo}}
      </div>

      <div class="info infoLast">
        {{'connect.noPlayer.info2' | translate}}
      </div>
    </ng-container>

    <!-- we are a player: show remotes -->
    <ng-container *ngIf="!(showRemotePlayer$ | async)">
      <div class="info">
        {{'connect.remote.info1' | translate}}
      </div>

      <div fxLayout="column" class="devices">
        <div *ngFor="let remoteApplicationInfo of (remoteRemoteApplicationInfos$ | async)">
          {{remoteApplicationInfo.deviceInfo}}
        </div>
      </div>

      <div class="info infoLast">
        {{'connect.remote.info2' | translate}}
      </div>
    </ng-container>

    <div class="buttons-container">
      <div *ngIf="(showRemotePlayer$ | async)" class="buttons-left-side">
        <button class="confirm-button" (click)="stopConnect()">{{'connect.stop' | translate}}</button>
      </div>
      <div class="buttons-right-side">
        <button class="confirm-button" (click)="close()" cdkFocusInitial>OK</button>
      </div>
    </div>






  </div>
