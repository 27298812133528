import { BehaviorSubject, Observable } from 'rxjs';

export class NavigationItem{

    public showAsInfoItem: boolean = false;
    public translateableInfoText: String;

    public translateableTitlePrefix: String = '';
    public showTitleAsLink: boolean = false;
    public isSelecteable: boolean = true;

    private _titleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public title$: Observable<string> = this._titleSubject.asObservable();
    public get title(): string{
      return this._titleSubject.value;
    }
    public set title(value: string){
      if (this.title != value){
        this._titleSubject.next(value);
      }
    }

    public originalData: Object;

    public isFavorite: boolean;
    public isNew: boolean;
    public isEditable: boolean;

    public acceptTrackDrops: boolean;

    // keep a reference to its parent so it is easy to update the selected state (only for Orange)
    parent: NavigationItem;

    /**
     * selected property - available as subscription
     */
    public get selected(): boolean  {
      return this._selectedSubject.value;
    }

    public set selected(value: boolean) {
      if (this.selected !== value) {
        this._selectedSubject.next(value);
      }

      // Update my parent if it exists
      if (this.parent) {
        this.parent.selected = value;
      }
    }
    protected _selectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selected$: Observable<boolean> = this._selectedSubject.asObservable();
}

