<sass-helper></sass-helper>
<div class="container">
  <!-- Left and right slider. -->
  <div class="sliders">
    <div
      #leftSliderBox
      class="slider-box"
      [style.left]="leftSliderBoxLeft"
    ></div>
    <div
      class="slider-container"
      [style.left]="leftSliderLeft"
      [style.transition]="transitionSpeed"
      [style.-webkit-transition]="transitionSpeed"
      [style.-moz-transition]="transitionSpeed"
    >
      <div [style.border-top]="sliderStyle" class="slider slider-left"></div>
    </div>
    <div
      #rightSliderBox
      class="slider-box"
      [style.right]="rightSliderBoxRight"
    ></div>
    <div
      class="slider-container"
      [style.right]="rightSliderRight"
      [style.transition]="transitionSpeed"
      [style.-webkit-transition]="transitionSpeed"
      [style.-moz-transition]="transitionSpeed"
    >
      <div [style.border-top]="sliderStyle" class="slider slider-right"></div>
    </div>
  </div>

  <!-- Selected and unselected lines. -->
  <div class="lines" (click)="lineClicked($event)">
    <div
      #unselectedline
      [style.border-bottom]="unselectedLineStyle"
      class="line unselected-line"
    ></div>
    <div
      #selectedline
      [style.border-bottom]="selectedLineStyle"
      class="line selected-line"
      [style.left]="selectedLineLeft"
      [style.right]="selectedLineRight"
      [style.transition]="transitionSpeed"
      [style.-webkit-transition]="transitionSpeed"
      [style.-moz-transition]="transitionSpeed"
    ></div>
    <div
      #verticalLine
      class="vertical-line"
      *ngFor="let option of options | slice: 1; let i = index"
      [style.left.%]="(100 / getAmountOfOptions()) * (i + 1)"
    ></div>
  </div>

  <!-- Labels for slider with multiple labels. -->
  <div class="options" *ngIf="!isSingleLabel">
    <div #column class="column" *ngFor="let option of options">
      {{ option.label }}
    </div>
  </div>

  <!-- Label for slider with a single label. -->
  <div class="options options-single" *ngIf="isSingleLabel">
    <div class="lower-value">
      {{ getFirstOptionLabel() }}
    </div>
    <div class="single-label">
      {{ label }}
    </div>
    <div class="upper-value">
      {{ getLastOptionLabel() }}
    </div>
  </div>
</div>
