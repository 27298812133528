import { Component, OnInit, ElementRef, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { SuggestionPopupContent } from '../popup/interfaces';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { PopupService } from '@service/popup.service';
import { SongRequestService, SongRequest } from '@service/song-request.service';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { Subject, pipe, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-suggestion-popup',
  templateUrl: './suggestion-popup.component.html',
  styleUrls: ['./suggestion-popup.component.scss']
})
export class SuggestionPopupComponent implements OnInit, OnDestroy, SuggestionPopupContent {

  // === Props === //
  @Input() connectedElementRef: ElementRef;

  // === State === //
  suggestionForm: UntypedFormGroup;
  creating: boolean;
  created: boolean;

  get titleField(): AbstractControl {
    return this.suggestionForm.get('title');
  }

  get groupField(): AbstractControl {
    return this.suggestionForm.get('group');
  }

  get titleFieldTouched(): boolean {
    return this.titleField.touched;
  }

  get groupFieldTouched(): boolean {
    return this.groupField.touched;
  }

  get titleFieldHasError(): boolean {
    return this.titleField.hasError('required');
  }

  get groupFieldHasError(): boolean {
    return this.groupField.hasError('required');
  }

  get showErrorMsg(): boolean {
    // Only show the error if both fields (title and group) are touched and one of them has an error
    return this.titleFieldTouched && this.groupFieldTouched && (this.titleFieldHasError || this.groupFieldHasError);
  }

  constructor(
    private _fb: UntypedFormBuilder,
    private _popupService: PopupService,
    private _songRequestService: SongRequestService,
    public elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  doSuggestion() {
    this.creating = true;
    const { value } = this.suggestionForm;

    const songRequestToCreate = new SongRequest();
    songRequestToCreate.title = value.title;
    songRequestToCreate.groupname = value.group;
    songRequestToCreate.remarks = value.remarks;

    this._songRequestService.createSongRequest(songRequestToCreate)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status: AsyncStatus) => {
        if (status === AsyncStatus.COMPLETED) {
          // Close the popup
          this.creating = false;
          this.created = true;
          timer(2000)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this._popupService.hidePopup$.next(this.connectedElementRef);
          });
        } else if (status === AsyncStatus.ERROR) {
          // Show the error message and stop loading
          this.creating = false;
          this.suggestionForm.setErrors({
            generalError: true
          });
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  onCloseClick = () => this._popupService.hidePopup$.next(this.connectedElementRef);

  private buildForm() {
    this.suggestionForm = this._fb.group({
      title: ['', [Validators.required]],
      group: ['', [Validators.required]],
      remarks: ['']
    });
  }
}
