import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrangeViewComponent } from './orange-view.component';
import { PlayerComponentsModule } from '@components/player-components/player-components.module';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OrangePlayerPanelComponent } from './orange-player-panel/orange-player-panel.component';
import { MenuOrangePanelComponent } from './menu-orange-panel/menu-orange-panel.component';
import { OrangeMenuComponent } from './menu-orange-panel/orange-menu/orange-menu.component';
import { AppComponentsModule } from '@components/app-components/app-components.module';
import { OrangeSubMenuComponent } from './menu-orange-panel/orange-menu/orange-sub-menu/orange-sub-menu.component';
import { ScrollbarModule } from 'src/app/scrollbar/scrollbar.module';
import { IconsModule } from '@components/icons/icons.module';
import { UtilsModule } from '@util/utils.module';
import { PopupsModule } from '@components/popups/popups.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [OrangeViewComponent,
    OrangePlayerPanelComponent,
    MenuOrangePanelComponent,
    OrangeMenuComponent,
    OrangeSubMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    PlayerComponentsModule,
    AppComponentsModule,
    ScrollbarModule,
    IconsModule,
    UtilsModule,
    PopupsModule,
    TranslateModule
  ],
  exports: [OrangeViewComponent]
})
export class OrangeViewModule { }
