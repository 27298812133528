<sass-helper></sass-helper>
<div class="wrapper">
  <!-- This first *ngIf is used to force the configuration-panel to disappear
       when the user selects a new MusicCollection. -->
  <div *ngIf="!forceCloseConfigurationPanel">
    <tun-music-collection-configuration-panel
      #configPanel
      *ngIf="showConfigurationPanel"
      class="configuration-panel"
      [maxScrollbarHeight]="maxScrollbarHeight"
      [musicCollection]="selectedMusicCollection"
      [showPlayAnimation]="
        selectedMusicCollection.id == showPlayAnimationForMusicCollectionId
      "
      [animatePlayAnimation]="playing"
      [audioFileProperty]="audioFileProperty"
      (closeConfiguration)="closeMusicConfigurationPanel()"
      (trackEvent)="onTrackEvent($event)"
      (tracksEvent)="onTracksEvent($event)"
      (musicCollectionEvent)="onMusicCollectionEvent($event)"
      [musicPlayAnimationColors]="musicPlayAnimationColors"
      [@slideInOut]="{
        value: openCloseTweakPanel,
        params: {
          height: musicCategoryBlockHeight,
          width: animationWidth,
          top: animationTop,
          left: animationLeft
        }
      }"
    ></tun-music-collection-configuration-panel>
  </div>
  <div #container (tunResize)="onResizeContainer($event)" class="container">
    <tun-scrollbar
      [disabled]="
        musicCategoryBlockHeight !== MINIMUM_MUSIC_CATEGORY_BLOCK_HEIGHT
      "
    >
      <div *ngIf="musicChannel">
        <div
          class="grid-container"
          *ngFor="let item of amountOfMusicBlockGroups; let i = index"
          [style.margin-right]="gridMarginRight"
          [style.grid-gap.px]="spacingBetweenMusicCategoryBlocks"
          [style.padding-bottom.px]="
            i !== 3 ? spacingBetweenMusicCategoryBlockGroups : 0
          "
          [style.margin-top.rem]="i === 0 ? 1.5 : 0"
          [style.margin-bottom.rem]="i === 3 ? 1.5 : 0"
        >
          <tun-music-category-block
            *ngFor="let item of amountOfMusicBlocksPerGroup; let j = index"
            [musicCollection]="
              musicChannel.musicCollections | musicCollectionSorter: i:j
            "
            [componentHeight]="musicCategoryBlockHeight"
            (musicCollectionEvent)="onMusicCollectionEvent($event)"
            [showPlayAnimationForMusicCollectionId]="
              showPlayAnimationForMusicCollectionId
            "
            [animatePlayAnimation]="playing"
            [musicPlayAnimationColors]="musicPlayAnimationColors"
            [selectedMusicCategoryBlockColors]="
              selectedMusicCategoryBlockColors
            "
            [unselectedMusicCategoryBlockColors]="
              unselectedMusicCategoryBlockColors
            "
            [style.opacity]="showGrid ? '1' : '0'"
          >
          </tun-music-category-block>
        </div>
      </div>
    </tun-scrollbar>
  </div>
</div>
