import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Context } from '@model/context';
import { MusicCollection } from '@model/musicCollection';
import { Playlist } from '@model/playlist';
import { PlaylistComponent } from '@components/app-components/panels/playlist/playlist.component';
import { AppService } from '@service/app.service';
import { PopupService } from '@service/popup.service';
import { SongSorter } from '@components/app-components/fields/song-grid/song-sorter.pipe';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { PlaylistService } from '@service/playlist.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { Util } from '../../../../utils/util';
import { trigger } from '@angular/animations';

@Component({
  selector: 'tun-music-collection',
  templateUrl: './music-collection.component.html',
  styleUrls: [
    './music-collection.component.scss',
    '../editable-playlist/editable-playlist.component.scss'
  ],
  // memory leak work-around: add no-op animation in parent component of each songGrid. Otherwise, the @HostBinding('@fadeIn') animation causes the component to stay in memory.
  animations: [trigger("noop", [])]
})
export class MusicCollectionComponent extends PlaylistComponent
  implements OnInit {
  // === Props === //
  @Input() musicCollection: MusicCollection | Context | Playlist;
  @Input() canAddSongs: boolean;

  // === Output === //
  @Output() tweak = new EventEmitter<void>();
  @Output() refresh = new EventEmitter<void>();

  // === Getters === //
  get isContext() {
    return this.musicCollection instanceof Context;
  }

  /*
  get title() {
    if (this.musicCollection instanceof Playlist && (this.musicCollection as Playlist).audioFiles && (this.musicCollection as Playlist).audioFiles.length > 0){
        let duration = (this.musicCollection as Playlist).audioFiles.reduce((sum, track) => sum + track.duration / 1000, 0);
        return this.musicCollection.title + ' (' + Util.formatSeconds(duration) + ')';

    }
    return this.musicCollection.title;
  }
  */

  get title() {
    return this.musicCollection.title;
  }

  constructor(
    appService: AppService,
    _songSorter: SongSorter,
    _zoneConfigurationService: ZoneConfigurationService,
    _popupService: PopupService,
    _musicManipulationService: MusicManipulationService,
    _playlistService: PlaylistService,
    _changeDetectorRef: ChangeDetectorRef
  ) {
    super(appService, _songSorter, _zoneConfigurationService, _popupService, _musicManipulationService, _playlistService, _changeDetectorRef);
  }

  ngOnInit() {}

  // === Event handlers === //
  onTweakClicked() {
    this.tweak.emit();
  }

  onRefreshClicked() {
    this.refresh.emit();
  }

  /*
  onAddToQueueClick() {
    super.onAddToQueueClick();
  }
  */

}
