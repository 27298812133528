import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  OnChanges,
  AfterViewChecked,
} from '@angular/core';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'tun-scrollbar',
  templateUrl: './scrollbar.component.html',
  styleUrls: ['./scrollbar.component.scss'],
})
export class ScrollbarComponent
  implements AfterViewInit, OnChanges, AfterViewChecked {
  // FA Icons
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;

  @ViewChild(PerfectScrollbarComponent, { static: true })
  componentRef?: PerfectScrollbarComponent;

  @Input() public iconColor: string;
  @Input() public disabled: boolean;
  @Input()
  public set shouldUpdate(value: boolean) {
    if (value) {
      this.updateScrollbar();
    }
  }

  private _maxScrollbarHeight: number;
  public get maxScrollbarHeight(): number {
    return this._maxScrollbarHeight;
  }
  @Input()
  public set maxScrollbarHeight(value: number) {
    this._maxScrollbarHeight = value;
  }

  constructor(private _cdRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this._cdRef.detectChanges();
  }

  ngAfterViewChecked() {
    this._cdRef.detectChanges();
  }

  ngOnChanges() {
    this._cdRef.detectChanges();
  }

  public updateScrollbar() {
    this.componentRef.directiveRef.update();
    this._cdRef.detectChanges();
  }

  // make sure an area becomes visible. The area between startYpos and endYpos will be visible on screen.
  // if the area is already on screen, no scrollposition will be adjusted.
  public makeVisible(startYpos, endYpos) {
    const visibleyPos = this.componentRef.directiveRef.position(true).y;

    // translate perfect scrollbar value to number
    let startVisibleArea: number = 0;
    if (visibleyPos === 'start') {
      startVisibleArea = 0;
    } else if (visibleyPos === 'end') {
      // todo -> what does this mean?
      startVisibleArea = 0;
    } else {
      startVisibleArea = visibleyPos;
    }

    const geom = this.componentRef.directiveRef.geometry('offset');
    const componentHeight = geom.h;
    const endVisibleArea = startVisibleArea + componentHeight;

    if (startYpos < startVisibleArea) {
      // scroll to startYpos to put start in visible area
      this.componentRef.directiveRef.scrollToY(startYpos, 200);
    } else if (endYpos > endVisibleArea) {
      // scroll to endYpos to put end in visible area
      this.componentRef.directiveRef.scrollToY(Math.max(0, endYpos - componentHeight), 200);
    }

  }
}
