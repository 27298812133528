import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
  NgZone,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tun-song-field',
  templateUrl: './song-field.component.html',
  styleUrls: ['./song-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SongFieldComponent implements AfterViewInit, OnDestroy, OnChanges {
  // === Props === //
  @Input() text: string;
  @Input() isSorted: boolean;
  @Input() isClickable = false;
  @Input() isDoubleClickable = false;

  // === ViewChildren === //
  @ViewChild('songfield') songField: ElementRef;
  @ViewChild('songfieldtext') songFieldText: ElementRef;

  // === Emitters === //
  @Output() fieldClick = new EventEmitter();
  @Output() fieldDoubleClick = new EventEmitter();

  // === State === //
  private clickAnimationTime1 = 50;
  private clickAnimationTime2 = 500;
  private songFieldHoverTimeout: NodeJS.Timer;
  private whiteBackgroundTimer: NodeJS.Timer;

  songFieldMarginLeft: SafeStyle = '0px';
  offsetWidth = 0;
  scrollWidth = 0;
  transitionSpeed = 0;
  transition: SafeStyle = 'margin 0ms linear 0s';
  backgroundColor = 'transparent';

  // Used for deciding whether the user performs
  // a single click or a double click.
  timerClick: NodeJS.Timer;
  clickDelay = 400;

  // Opacity over the white background that overlays the container,
  // used for the click animation.
  opacity = 0;
  whiteBackgroundTransitionSpeed = 50;

  constructor(
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.text) {
      this.resetLabel();
    }
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.songField.nativeElement.addEventListener(
        'mouseenter',
        this.onSongFieldHover
      );
      this.songField.nativeElement.addEventListener(
        'mousedown',
        this.onMouseDown
      );
      this.songField.nativeElement.addEventListener(
        'mouseleave',
        this.onSongFieldStopHover
      );
      this.songField.nativeElement.addEventListener(
        'click',
        this.onSongFieldSingleClick
      );
    });
  }

  ngOnDestroy() {
    this.ngZone.runOutsideAngular(() => {
      this.songField.nativeElement.removeEventListener(
        'mouseenter',
        this.onSongFieldHover
      );
      this.songField.nativeElement.removeEventListener(
        'mousedown',
        this.onMouseDown
      );
      this.songField.nativeElement.removeEventListener(
        'mouseleave',
        this.onSongFieldStopHover
      );
      this.songField.nativeElement.removeEventListener(
        'click',
        this.onSongFieldSingleClick
      );
    });
  }

  onSongFieldHover = () => {
    clearTimeout(this.songFieldHoverTimeout);

    if (this.offsetWidth === 0) {
      this.offsetWidth = this.songFieldText.nativeElement.offsetWidth;
    }

    if (this.scrollWidth === 0) {
      this.scrollWidth = this.songFieldText.nativeElement.scrollWidth;
    }

    if (this.offsetWidth < this.scrollWidth) {
      this.transitionSpeed =
        (((this.scrollWidth - this.offsetWidth) * 50) / 11) * 3;
      this.transition = `margin ${this.transitionSpeed}ms linear 0s`;
      this.songFieldMarginLeft = this.sanitizer.bypassSecurityTrustStyle(
        `-${this.scrollWidth - this.offsetWidth}px`
      );
    }
    this.cdRef.detectChanges();
  }

  onSongFieldStopHover = () => {
    if (this.songFieldMarginLeft !== ('0px' as SafeStyle)) {
      this.songFieldMarginLeft = '0px';
      this.transitionSpeed = ((this.scrollWidth - this.offsetWidth) * 50) / 11;
      this.transition = `margin ${this.transitionSpeed}ms linear 0s`;

      this.songFieldHoverTimeout = setTimeout(() => {
        this.transitionSpeed = 0;
        this.transition = 'margin 0ms linear 0s';
        this.cdRef.detectChanges();
      }, this.transitionSpeed);
    }
    this.cdRef.detectChanges();
  }

  onMouseDown = () => {
    this.resetLabel();
  }

  resetLabel = () => {
    this.offsetWidth = 0;
    this.scrollWidth = 0;
    this.transitionSpeed = 0;
    this.transition = 'margin 0ms linear 0s';
    this.songFieldMarginLeft = '0px';
    this.cdRef.detectChanges();
  }

  onSongFieldSingleClick = () => {
    if (this.isClickable && !this.timerClick){
      this.performClickAnimation();
    }
    
    if (this.timerClick) {
      // run normal events in angular zone
      this.ngZone.run(() => {
        if (this.isDoubleClickable){
          this.fieldDoubleClick.emit();
        }
      });
      clearTimeout(this.timerClick);
      this.timerClick = null;
    } else {
      this.timerClick = setTimeout(() => {
        this.timerClick = null;
        if (this.isClickable){
          // run normal events in angular zone
          this.ngZone.run(() => {
            this.fieldClick.emit();
          });
        }
      }, this.clickDelay);
    }
  }

  performClickAnimation = () => {
    if (this.whiteBackgroundTimer) {
      clearTimeout(this.whiteBackgroundTimer);
    }
    this.whiteBackgroundTransitionSpeed = this.clickAnimationTime1;
    this.opacity = 0.5;
    this.cdRef.detectChanges();
    this.whiteBackgroundTimer = setTimeout(() => {
      this.whiteBackgroundTransitionSpeed = this.clickAnimationTime2;
      this.opacity = 0;
      this.cdRef.detectChanges();
    }, this.clickAnimationTime1);
  }
}
