import { AudioFile } from '@model/audioFile';

export enum TrackIndexAction {
    ADD_AT_INDEX = "addAtIndex",
    MOVE_TO_INDEX = "moveToIndex",
}

export class TrackIndexEvent {
    track: AudioFile;
    index: number;
    action: TrackIndexAction;
    
    constructor(action: TrackIndexAction, track: AudioFile, index: number){
        this.action = action;
        this.track = track;
        this.index = index;
    }

    public toString(): string {
        return 'perform ' + this.action + ' for ' + this.track + " (index: " + this.index + ")";
      }
}   