import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tun-chevron-right-icon',
  templateUrl: './chevron-right.component.html',
  styleUrls: ['./chevron-right.component.scss'],
})
export class ChevronRightComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
