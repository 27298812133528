<div fxFlex fxLayout="column" class="connectingContainer loading-indicator-container">
    
    <div class="loading-indicator">
        <mat-spinner [diameter]="30"></mat-spinner>
        Searching for other players.. {{secondsBeforeAutoStartPlayerMode}}
    </div>

    <div class="startPlaying">
        <button (click)="startnow()">Just start playing!</button>
    </div>

  
</div>>
