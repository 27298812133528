import { Component, OnInit, OnChanges, SimpleChange, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { LoggerService } from '@service/loggers/logger.service';
import { Subject } from 'rxjs';
import { AppService } from '@service/app.service';
import { takeUntil} from 'rxjs/operators';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { PopupPosition, PopupDirection } from '../../../../components/popups/popup/enums';

@Component({
  selector: 'tun-tab-selector',
  templateUrl: './tab-selector.component.html',
  styleUrls: ['./tab-selector.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class TabSelectorComponent implements OnInit, OnDestroy, OnChanges {

  @Input() selectedTunifyColor: TunifyColor = TunifyColor.BLUE;
  @Input() componentWidth: number = 100;
  
  /* properties to draw the view */
  public barStyle = "";
  public positionForBar = 0;
  public widthForBar = 0;
  public widthForButton = 33;
  public heightForIcon = 0;
  public barOpacity = 0;

  //we need to make this enum public so we can use it in our html view file
  public TunifyColor = TunifyColor;
  public PopupDirection = PopupDirection;
  public PopupPosition = PopupPosition;


  // === Observables === //
  private _destroy$ = new Subject();

  private LOGGER_CLASSNAME = 'TabSelectorComponent';

  constructor(private loggerService: LoggerService,
              private appService: AppService,
              private activeMusicSelectionService:ActiveMusicSelectionService) { }

  ngOnInit() {
    this.adjustSizes();

    this.appService.heightForIcon$
    .pipe(
      takeUntil(this._destroy$)
    )
    .subscribe(
      (heightForIcon) => {
        this.heightForIcon = heightForIcon;
      }
    );
    }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
  }

  ngOnChanges(inputChanges:  { [propName: string]: SimpleChange }) {

    // detect mainNavigationItem
    if (inputChanges['selectedTunifyColor']) {
      this.adjustPositioning();
    }
    if (inputChanges['componentWidth']){
      this.adjustSizes();
    }
  }

  private adjustPositioning(){
    if (this.selectedTunifyColor == TunifyColor.GREEN){
      this.positionForBar = 3;
      this.barOpacity = 1;
      this.barStyle = "greenBar";
    }else if (this.selectedTunifyColor == TunifyColor.BLUE){
      this.positionForBar = 3 + this.widthForButton;
      this.barOpacity = 1;
      this.barStyle = "blueBar";
    }else if (this.selectedTunifyColor == TunifyColor.ORANGE){
      this.positionForBar = 3 + 2 * this.widthForButton;
      this.barOpacity = 1;
      this.barStyle = "orangeBar";
    }else{
      this.barOpacity = 0;
      this.barStyle = "";
      if (this.selectedTunifyColor != null){
        this.loggerService.error(this.LOGGER_CLASSNAME, "adjustPositioning", "tunify color not recognized: " + this.selectedTunifyColor);
      }
    }
  }

  private adjustSizes = () => {
    this.widthForButton = Math.floor((this.componentWidth - 2) / 3); //-1 for the border on the right
    this.widthForBar = this.widthForButton - 4;

    //console.log("size " + this.componentWidth + " results in width " + this.widthForButton);

    this.adjustPositioning();
  }

  public selectPlayerView(tunifyColor: TunifyColor){
    this.activeMusicSelectionService.selectedPlayerView = tunifyColor;
  }
}
