import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ResizedEvent } from '@components/resize/resize.directive';
import { AudioFileProperty } from '@model/enums/audioFileProperty';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import {
  MusicCollectionAction,
  MusicCollectionEvent,
} from '@model/events/musicCollectionEvent';
import { TracksEvent } from '@model/events/tracksEvent';
import { MusicCategoryBlockColors } from '@model/fieldModels/musicCategoryBlockColors';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { MusicChannel } from '@model/musicChannel';
import { MusicCollection } from '@model/musicCollection';
import { openCloseTweakPanel } from '@util/animations';
import { SassHelperComponent } from 'src/providers/sass-helper/sass-helper.component';

@Component({
  selector: 'tun-music-category-block-grid',
  templateUrl: './music-category-block-grid.component.html',
  styleUrls: ['./music-category-block-grid.component.scss'],
  animations: [openCloseTweakPanel],
})
export class MusicCategoryBlockGridComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() set musicChannel(value: MusicChannel) {
    if (this.configPanel) {
      this.forceCloseConfigurationPanel = true;
    }
    this._musicChannel = value;
    this.calculateSpacingAndHeight();
  }
  get musicChannel(): MusicChannel {
    return this._musicChannel;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}
  // this property is used from the html part so it should be public
  public readonly MINIMUM_MUSIC_CATEGORY_BLOCK_HEIGHT = 38;
  @ViewChild(SassHelperComponent)
  private sassHelper: SassHelperComponent;

  // Reference to the container element which is used for calculations using height and width.
  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('configPanel') configPanel: ElementRef;

  private _musicChannel: MusicChannel;

  @Input() showPlayAnimationForMusicCollectionId = -1;
  @Input() playing = false;
  @Input() audioFileProperty: AudioFileProperty;

  @Input() heightPerItem = 0;

  @Input() tunifyColor: TunifyColor = TunifyColor.BLUE;

  @Output() trackEvent = new EventEmitter<TrackEvent>();
  @Output() tracksEvent = new EventEmitter<TracksEvent>();
  @Output() musicCollectionEvent = new EventEmitter<MusicCollectionEvent>();

  // Spacing between four music category blocks in one group.
  spacingBetweenMusicCategoryBlocks: number;

  // The height of a music category block.
  musicCategoryBlockHeight: number;

  // Spacing between the four groups.
  spacingBetweenMusicCategoryBlockGroups: number;

  // Margin to the right of the groups.
  gridMarginRight: SafeStyle = this.sanitizer.bypassSecurityTrustStyle(
    `calc(1rem)`
  );

  // Maximum height of the component.
  maxScrollbarHeight: number;

  // Last music category block the user clicked.
  selectedMusicCollection: MusicCollection;

  // Indicates whether the configuration panel of the selected music collection should be shown or not.
  showConfigurationPanel = false;

  showGrid = false;

  // Amount of music blocks in each group.
  amountOfMusicBlocksPerGroup: Array<null> = new Array(4);

  // Amount of music block groups.
  amountOfMusicBlockGroups: Array<null> = new Array(4);

  animationElement: HTMLElement;
  animationLeft = 0;
  animationTop = 0;
  animationWidth = 0;

  animationDuration = 400;
  controlsBackgroundColorBefore: String;
  controlsBackgroundColorAfter: String;

  musicPlayAnimationColors: MusicPlayAnimationColors = new MusicPlayAnimationColors();
  selectedMusicCategoryBlockColors: MusicCategoryBlockColors = new MusicCategoryBlockColors();
  unselectedMusicCategoryBlockColors: MusicCategoryBlockColors = new MusicCategoryBlockColors();

  forceCloseConfigurationPanel = false;

  // todo -> there is still a little bug when the container is shown for the first time:
  // width starts with a value that is too high, after adding properly to the screen, the container height is adjusted
  // for example from 835px to 655px for screen height of 934

  ngOnInit() {
    this.fetchColors();
  }

  public onResizeContainer(event: ResizedEvent){
    this.calculateSpacingAndHeight();
    this.animationWidth = this.animationElement
      ? this.animationElement.offsetWidth
      : 0;
    if (this.musicChannel) {
      this.calculateAnimationProps();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showGrid = true;
    }, 0);
  }

  ngOnDestroy() {

  }

  getFullWidth = () => {
    return this.container ? this.container.nativeElement.offsetWidth : 0;
  }

  getFullHeight = () => {
    return this.container ? this.container.nativeElement.offsetHeight : 0;
  }

  // Calculations for the height of the music category blocks and
  // the spacing within and between the groups.
  calculateSpacingAndHeight = () => {
    const spacingBetweenMusicCategoryBlocks =
      Math.max(Math.floor(this.getFullWidth() / 140), 7);
    this.maxScrollbarHeight = this.getFullHeight();
    const maxTrollbarHeight =
      this.maxScrollbarHeight - this._convertRemToPixels(3);
    this.cdRef.detectChanges();

    let musicCategoryBlockHeight = Math.max(
      Math.round(
        (maxTrollbarHeight - 16 * spacingBetweenMusicCategoryBlocks) / 8
      ) - 2,
      this.MINIMUM_MUSIC_CATEGORY_BLOCK_HEIGHT
    );
    const musicCategoryBlockWidth =
      (this.getFullWidth() - spacingBetweenMusicCategoryBlocks) / 2;
    const ratioMusicCategoryBlockHeight = Math.round(
      (musicCategoryBlockWidth * 55) / 260
    );

    musicCategoryBlockHeight = Math.min(
      musicCategoryBlockHeight,
      ratioMusicCategoryBlockHeight
    );

    this.gridMarginRight = this.sanitizer.bypassSecurityTrustStyle(
      `calc(1.5rem + ${
        musicCategoryBlockHeight === this.MINIMUM_MUSIC_CATEGORY_BLOCK_HEIGHT
          ? 1
          : 0
      }rem + ${this.spacingBetweenMusicCategoryBlocks}px)`
    );

    const spacingBetweenMusicCategoryBlockGroups = Math.min(
      Math.max(
        Math.floor(
          (maxTrollbarHeight -
            8 * (musicCategoryBlockHeight + 2) -
            4 * spacingBetweenMusicCategoryBlocks) /
            3
        ),
        4 * spacingBetweenMusicCategoryBlocks
      ),
      12 * spacingBetweenMusicCategoryBlocks
    );

    this.musicCategoryBlockHeight = musicCategoryBlockHeight;
    this.spacingBetweenMusicCategoryBlocks = spacingBetweenMusicCategoryBlocks;
    this.spacingBetweenMusicCategoryBlockGroups = spacingBetweenMusicCategoryBlockGroups;
    this.cdRef.detectChanges();
  }

  calculateAnimationProps = () => {
    // Index of a block in a block group, to visualize it:
    // 0 2
    // 1 3
    const musicCollectionIndex =
      this.musicChannel.musicCollections.indexOf(this.selectedMusicCollection) %
      4;

    // Index of the block group itself, to visualize it:
    // 0
    // 1
    // 2
    // 3
    const musicCollectionBlockIndex = Math.floor(
      this.musicChannel.musicCollections.indexOf(this.selectedMusicCollection) /
        4
    );

    this.animationLeft =
      Math.floor(musicCollectionIndex / 2) *
      (this.spacingBetweenMusicCategoryBlocks + this.animationWidth);

    this.animationTop =
      musicCollectionBlockIndex * this.spacingBetweenMusicCategoryBlockGroups +
      (musicCollectionIndex % 2) *
        (this.spacingBetweenMusicCategoryBlocks +
          this.musicCategoryBlockHeight) +
      musicCollectionBlockIndex * this.musicCategoryBlockHeight * 2;
  }

  pickConfigurationPanelColors = (isSelected: boolean) => {
    this.controlsBackgroundColorBefore = isSelected ? '#12AEDA' : '#2A3233';
    this.controlsBackgroundColorAfter = this.sassHelper.readProperty('blue-0');
  }

  // Closes the music configuration panel.
  closeMusicConfigurationPanel = () => {
    this.selectedMusicCollection = null;
    this.showConfigurationPanel = false;
  }

  // Fetch colors used by itself and its children.
  fetchColors = () => {
    // Colors for the MusicCategoryBlockComponent when it's unselected.
    const unselectedMusicCategoryBlockColors = new MusicCategoryBlockColors()
      .containerBorderColor(
        this.sassHelper.readProperty('black-container-border')
      )
      .labelBackgroundGradientStartColor(
        this.sassHelper.readProperty('grey-label-gradient-start')
      )
      .labelBackgroundGradientEndColor(
        this.sassHelper.readProperty('grey-label-gradient-end')
      )
      .labelBackgroundGradientHoverStartColor(
        this.sassHelper.readProperty('grey-label-gradient-start-hover')
      )
      .labelBackgroundGradientHoverEndColor(
        this.sassHelper.readProperty('grey-label-gradient-end-hover')
      )
      .labelBorderColor(this.sassHelper.readProperty('grey-3'))
      .labelBorderRightColor(this.sassHelper.readProperty('grey-11'))
      .iconBackgroundColor(this.sassHelper.readProperty('grey-11'))
      .iconBackgroundHoverColor(this.sassHelper.readProperty('grey-icon-hover'))
      .iconBorderColor(this.sassHelper.readProperty('grey-10'))
      .iconColor(this.sassHelper.readProperty('grey-5'))
      .textColor(this.sassHelper.readProperty('white-1'));
    this.unselectedMusicCategoryBlockColors = unselectedMusicCategoryBlockColors;

    // Colors depending on the Tunify color.
    switch (this.tunifyColor) {
      // Tunify Blue
      case TunifyColor.BLUE:
        // Colors for the music play animation.
        const blueMusicPlayAnimationColors = new MusicPlayAnimationColors()
          .selectedColor(this.sassHelper.readProperty('blue-3'))
          .unselectedColor(this.sassHelper.readProperty('grey-8'));
        this.musicPlayAnimationColors = blueMusicPlayAnimationColors;

        // Colors for the MusicCategoryBlockComponent when it's selected.
        const blueSelectedMusicCategoryBlockColors = new MusicCategoryBlockColors()
          .containerBorderColor(this.sassHelper.readProperty('blue-7'))
          .labelBackgroundGradientStartColor(
            this.sassHelper.readProperty('blue-label-gradient-start')
          )
          .labelBackgroundGradientEndColor(
            this.sassHelper.readProperty('blue-label-gradient-end')
          )
          .labelBackgroundGradientHoverStartColor(
            this.sassHelper.readProperty('blue-label-gradient-start-hover')
          )
          .labelBackgroundGradientHoverEndColor(
            this.sassHelper.readProperty('blue-label-gradient-end-hover')
          )
          .labelBorderColor(this.sassHelper.readProperty('blue-13'))
          .labelBorderRightColor(this.sassHelper.readProperty('blue-12'))
          .iconBackgroundColor(this.sassHelper.readProperty('blue-8'))
          .iconBackgroundHoverColor(
            this.sassHelper.readProperty('blue-icon-hover')
          )
          .iconBorderColor(this.sassHelper.readProperty('blue-10'))
          .iconColor(this.sassHelper.readProperty('blue-11'))
          .textColor(this.sassHelper.readProperty('blue-8'));
        this.selectedMusicCategoryBlockColors = blueSelectedMusicCategoryBlockColors;
        break;

      // If no Tunify color is selected.
      default:
        // Colors for the music play animation.
        const defaultMusicPlayAnimationColors = new MusicPlayAnimationColors()
          .selectedColor(this.sassHelper.readProperty('blue-3'))
          .unselectedColor(this.sassHelper.readProperty('grey-8'));
        this.musicPlayAnimationColors = defaultMusicPlayAnimationColors;

        // Colors for the MusicCategoryBlockComponent when it's selected.
        const defaultSelectedMusicCategoryBlockColors = new MusicCategoryBlockColors()
          .containerBorderColor(this.sassHelper.readProperty('blue-7'))
          .labelBackgroundGradientStartColor(
            this.sassHelper.readProperty('blue-label-gradient-start')
          )
          .labelBackgroundGradientEndColor(
            this.sassHelper.readProperty('blue-label-gradient-end')
          )
          .labelBackgroundGradientHoverStartColor(
            this.sassHelper.readProperty('blue-label-gradient-start-hover')
          )
          .labelBackgroundGradientHoverEndColor(
            this.sassHelper.readProperty('blue-label-gradient-end-hover')
          )
          .labelBorderColor(this.sassHelper.readProperty('blue-13'))
          .labelBorderRightColor(this.sassHelper.readProperty('blue-12'))
          .iconBackgroundColor(this.sassHelper.readProperty('blue-8'))
          .iconBackgroundHoverColor(
            this.sassHelper.readProperty('blue-icon-hover')
          )
          .iconBorderColor(this.sassHelper.readProperty('blue-10'))
          .iconColor(this.sassHelper.readProperty('blue-11'))
          .textColor(this.sassHelper.readProperty('blue-8'));
        this.selectedMusicCategoryBlockColors = defaultSelectedMusicCategoryBlockColors;
        break;
    }
  }

  onTrackEvent(trackEvent: TrackEvent) {
    this.trackEvent.emit(trackEvent);
  }

  onTracksEvent(tracksEvent: TracksEvent) {
    this.tracksEvent.emit(tracksEvent);
  }

  onMusicCollectionEvent(musicCollectionEvent: MusicCollectionEvent) {
    this.musicCollectionEvent.emit(musicCollectionEvent);
    if (musicCollectionEvent.action === MusicCollectionAction.SHOW_DETAILS) {
      this.openMusicCollectionDetailPanel(musicCollectionEvent.musicCollection);
    }
  }

  // Opens the configuration panel of the selected music collection.
  private openMusicCollectionDetailPanel(musicCollection: MusicCollection) {
    this.forceCloseConfigurationPanel = false;

    this.selectedMusicCollection = musicCollection;

    // Calculate the width of the music category block.
    this.animationElement = event.target as HTMLElement;
    while (this.animationElement.nodeName !== 'TUN-MUSIC-CATEGORY-BLOCK') {
      this.animationElement = this.animationElement.parentElement;
    }
    this.animationWidth = this.animationElement.offsetWidth;

    this.calculateAnimationProps();
    this.pickConfigurationPanelColors(this.selectedMusicCollection.selected);

    this.showConfigurationPanel = true;
  }
  private _convertRemToPixels(rem: number) {
    return (
      rem * parseFloat(getComputedStyle(this.container.nativeElement).fontSize)
    );
  }
}
