<div
  class="titleBox"
  [class]="tunifyColor == TunifyColor.GREEN ? 'green' : 'blue'"
  fxFlex
  fxLayout="row"
>
  <div fxFlex class="boxSizer">

      <div
        class="titleLabel label"
        style="min-width: 0;"
        tunFitSize
        minFontSize="1"
        maxFontSize="2.2"
        fontUnit="rem"
        textToFit="{{ title }}"
      ></div>
  </div>

  <div *ngIf="showImage" class="imageBox"></div>

  {{ logChangeDetection() }}

  <!--
  <div class="titleLabel" fxFlex>
      <div>{{title}}</div>

  </div>
  -->

  <!-- Using the fitText library does not work properly-->
</div>
