<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
  >
  <path 
    fill="currentColor"
    d="M9.2 8.5c0-0.3 0.1-0.5 0.4-0.6l7.4-6.1c0.2-0.1 0.5-0.1 0.7 0 0.2 0.1 0.4 0.4 0.4 0.6v12.3c0 0.3-0.1 0.5-0.4 0.6 -0.1 0.1-0.2 0.1-0.4 0.1 -0.1 0-0.2 0-0.4-0.1L9.5 9.1C9.3 9 9.2 8.8 9.2 8.5zM0.4 9.1l7.4 6.1c0.1 0.1 0.2 0.1 0.4 0.1 0.1 0 0.2 0 0.4-0.1 0.2-0.1 0.4-0.4 0.4-0.6V2.4c0-0.3-0.1-0.5-0.4-0.6 -0.2-0.1-0.5-0.1-0.7 0L0.4 7.9C0.1 8 0 8.3 0 8.5 0 8.8 0.1 9 0.4 9.1z" 
  />
</svg>