<div fxFlex fxLayout="column" class="main">

  <tun-title-box
    fxLayout="column"
    [heightPerItem]="heightPerItem$ | async"
    [tunifyColor]="selectedPlayerView"
    [title]="titleForHeader"
    [style.min-height.px]="heightForHeader$ | async"
    style="min-width: 0;"
  >
  </tun-title-box>



  <!-- Option A: flex containers. Angular will splace both of them in the dom for a very short period of time-->
  <!--
  <div fxFlex class="lightPanelBackground flexBoxMinimumSizing"></div>

    <tun-music-channel-content-panel
      *ngIf="selectedPlayerView!==TunifyColor.GREEN;"
      fxFlex class="flexBoxMinimumSizing"
      [heightPerItem]="heightPerItem"
    >
    </tun-music-channel-content-panel>

    <tun-calendar-content-panel
      *ngIf="selectedPlayerView===TunifyColor.GREEN;"
      fxFlex class="flexBoxMinimumSizing"
      (isEditModeEmitter)='changeEditMode($event)'
      [heightPerItem]="heightPerItem"
      >
      </tun-calendar-content-panel>
  </div>

  -->

  <!-- option B: a layered container where each panel is placed on top of each other-->
  <div fxFlex class="layerContainer lightPanelBackground flexBoxMinimumSizing">
    <tun-music-channel-content-panel
      *ngIf="selectedPlayerView!=TunifyColor.GREEN; else calendarContentPanel"
      class="layer"
      [heightPerItem]="heightPerItem$ | async"
    >
    </tun-music-channel-content-panel>

    <ng-template #calendarContentPanel>
      <tun-calendar-content-panel
        class="layer"
        [heightPerItem]="heightPerItem$ | async"
      >
      </tun-calendar-content-panel>
    </ng-template>

  </div>




</div>
