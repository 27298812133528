<sass-helper></sass-helper>

<div class="container flexBoxMinimumSizing" #container fxFlex fxLayout="column">
  <tun-calendar-content-header
    [inEditMode]="inEditMode"
    [selectedCalendar]="selectedCalendar"
    [calendarLoaded]="calendarLoaded"
    [currentIndex]="currentIndex"
    [heightPerItem]="heightPerItem"
    [isCalendarEditable]="isCalendarEditable"
    (editmode)="toggleEditMode($event)"
    (scrollToCurrentHour)="_scrollToCurrentDate()"
    (daychange)="onDayChange($event)"
  ></tun-calendar-content-header>

  <!-- Calendar -->
  <tun-music-collection-configuration-panel
    *ngIf="selectedCalendarItem && showTweakPanel"
    class="calendar-item-tweak-panel"
    [style.width.%]="100"
    [style.height.px]="tweakPanelHeight"
    [style.margin-top.px]="heightPerItem"
    [calendarItem]="selectedCalendarItem"
    [maxScrollbarHeight]="maxScrollbarHeight - heightPerItem"
    (closeConfiguration)="closeTweakPanel()"
    (musicCollectionEvent)="
      onMusicCollectionEvent($event, selectedCalendarItem)
    "
    (shuffleChanged)="onShuffleChanged($event)"
    [@slideInOutCalendarItem]="{
      value: openCloseCalendarItemTweakPanel,
      params: {
        height: calendarItemHeight,
        width: animationWidth,
        heightPerItem: heightPerItem,
        top: animationTop,
        left: animationLeft
      }
    }"
  ></tun-music-collection-configuration-panel>
  <div
    #calendarpanel
    fxFlex
    class="darkPanelBackground testContent"
    id="calendarContentPanel"
  >
    <ng-container *ngIf="selectedCalendar">
      <!-- Special calendar states indicator -->

      <div
        *ngIf="calendarState != CalendarState.normal"
        [ngSwitch]="calendarState"
        class="feedback-container"
      >
        <div
          *ngSwitchCase="CalendarState.loadError"
          class="error-feedback-container"
        >
          <p>
            {{'calendar.contentPanel.load.error' | translate }}
          </p>
          <button (click)="retryClick()" class="retryButton">
            <p>
              {{'general.tryAgain' | translate }}
            </p>
          </button>
        </div>

        <ng-container *ngSwitchCase="CalendarState.loading">
          <tun-loader></tun-loader>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.clearingCurrentDay">
          <tun-loader text="clearing day .."></tun-loader>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.creating">
          <tun-loader text="calendar.contentPanel.create.busy"></tun-loader>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.creatingCopy">
          <tun-loader text="calendar.contentPanel.createCopy.busy"></tun-loader>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.deleting">
          <tun-loader text="calendar.contentPanel.delete.busy"></tun-loader>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.undoingDelete">
          <tun-loader text="calendar.contentPanel.undoDelete.busy"></tun-loader>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.copyingDay">
          <tun-loader text="calendar.contentPanel.copyDay.busy"></tun-loader>
        </ng-container>

        <div
          *ngSwitchCase="CalendarState.deleted"
          class="error-feedback-container"
        >
          <p translate>calendar.contentPanel.delete.done</p>
          <button (click)="undoDelete()" class="retryButton">
            <p translate>general.undo</p>
          </button>
        </div>
      </div>

      <ng-container *ngIf="calendarState == CalendarState.normal">
        <div class="left-side" #leftSideWidth>
          <tun-timeline
            [currentTimeMultiplier]="currentTimeMultiplier"
            [hours]="hours"
          ></tun-timeline>
        </div>

        <div class="animation-container">
          <ng-container *ngFor="let day of daysOfWeek; let dayIndex = index">
            <div
              class="calendar-items-columns"
              *ngIf="dayIndex === currentIndex"
              [@carousel]="{
                value: direction,
                params: {
                  duration: CAROUSEL_DURATION
                }
              }"
            >
              <div class="first-column" #column>
                <ng-container *ngIf="inEditMode">
                  <ng-container
                    *ngFor="let hour of hoursForDropdown; let i = index"
                  >
                    <tun-calendar-dropdown
                      (dropdown)="onDropdown($event)"
                      [style.pointer-events]="isMouseDown ? 'none' : 'all'"
                      [time]="hour"
                      [top]="heightPerItem / 2 + i * heightPerItem"
                      [position]="1"
                      (calendarItemDrop)="onCalendarItemDrop($event)"
                      (dropdownitemselect)="
                        onDropdownItemSelect($event, i, 0, 1)
                      "
                    ></tun-calendar-dropdown>
                    <tun-calendar-dropdown
                      (dropdown)="onDropdown($event)"
                      [style.pointer-events]="isMouseDown ? 'none' : 'all'"
                      [time]="hour + 0.5"
                      [top]="
                        heightPerItem / 2 +
                        i * heightPerItem +
                        heightPerItem / 2
                      "
                      [position]="1"
                      (calendarItemDrop)="onCalendarItemDrop($event)"
                      (dropdownitemselect)="
                        onDropdownItemSelect($event, i, 30, 1)
                      "
                    ></tun-calendar-dropdown>
                  </ng-container>
                </ng-container>
                <ng-container
                  *ngFor="
                    let calendarItem of selectedCalendar?.calendarItems
                      | filterCalendarItems: currentIndex:1
                  "
                >
                  <tun-calendar-item
                    (tooltip)="onTooltip($event)"
                    [calendarItem]="calendarItem"
                    [currentAudioFileFromCalendarItemWithId]="
                      currentAudioFileFromCalendarItemWithId
                    "
                    [musicPlayAnimationColors]="musicPlayAnimationColors"
                    [selectedMusicCategoryBlockColors]="
                      selectedMusicCategoryBlockColors
                    "
                    [unselectedMusicCategoryBlockColors]="
                      unselectedMusicCategoryBlockColors
                    "
                    [isSelected]="true"
                    [inEditMode]="inEditMode"
                    [heightPerItem]="heightPerItem"
                    [resizeTrigger]="resizeTrigger"
                    [isCurrentDay]="currentDayIndex === dayIndex"
                    [currentTimeMultiplier]="currentTimeMultiplier"
                    (openTweakPanel)="openCalendarItemTweakPanel($event)"
                    (deleteItem)="onDeleteCalendarItem($event)"
                    [isPlaying]="playing"
                  ></tun-calendar-item>
                </ng-container>
              </div>
              <div class="second-column">
                <ng-container *ngIf="inEditMode">
                  <ng-container
                    *ngFor="let hour of hoursForDropdown; let i = index"
                  >
                    <tun-calendar-dropdown
                      (dropdown)="onDropdown($event)"
                      [style.pointer-events]="isMouseDown ? 'none' : 'all'"
                      [time]="hour"
                      [top]="heightPerItem / 2 + i * heightPerItem"
                      [position]="2"
                      (calendarItemDrop)="onCalendarItemDrop($event)"
                      (dropdownitemselect)="
                        onDropdownItemSelect($event, i, 0, 2)
                      "
                    ></tun-calendar-dropdown>
                    <tun-calendar-dropdown
                      (dropdown)="onDropdown($event)"
                      [style.pointer-events]="isMouseDown ? 'none' : 'all'"
                      [time]="hour + 0.5"
                      [top]="
                        heightPerItem / 2 +
                        i * heightPerItem +
                        heightPerItem / 2
                      "
                      [position]="2"
                      (calendarItemDrop)="onCalendarItemDrop($event)"
                      (dropdownitemselect)="
                        onDropdownItemSelect($event, i, 30, 2)
                      "
                    ></tun-calendar-dropdown>
                  </ng-container>
                </ng-container>
                <ng-container
                  *ngFor="
                    let calendarItem of selectedCalendar?.calendarItems
                      | filterCalendarItems: currentIndex:2
                  "
                >
                  <tun-calendar-item
                    (tooltip)="onTooltip($event)"
                    [calendarItem]="calendarItem"
                    [currentAudioFileFromCalendarItemWithId]="
                      currentAudioFileFromCalendarItemWithId
                    "
                    [musicPlayAnimationColors]="musicPlayAnimationColors"
                    [selectedMusicCategoryBlockColors]="
                      selectedMusicCategoryBlockColors
                    "
                    [unselectedMusicCategoryBlockColors]="
                      unselectedMusicCategoryBlockColors
                    "
                    [isSelected]="true"
                    [inEditMode]="inEditMode"
                    [heightPerItem]="heightPerItem"
                    [resizeTrigger]="resizeTrigger"
                    [isCurrentDay]="currentDayIndex === dayIndex"
                    [currentTimeMultiplier]="currentTimeMultiplier"
                    (openTweakPanel)="openCalendarItemTweakPanel($event)"
                    (deleteItem)="onDeleteCalendarItem($event)"
                    [isPlaying]="playing"
                  ></tun-calendar-item>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
