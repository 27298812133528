<tun-panel-header
  [class.editing]="inEditMode"
  [class.transition]="enableTransition"
  tunThemed
  [showClose]="showClose"
  [title]="title"
  (close)="onCloseClick()"
>
  <div tunThemed class="left-header-buttons">
    <tun-icon-button
      tunTooltipOnHover
      [tooltipText]="'tooltip.trackList.addAllToQueue'"
      *ngIf="!inEditMode"
      [height]="heigthForTitle$ | async"
      (click)="onAddToQueueClick()"
    >
      <tun-add-to-queue-icon></tun-add-to-queue-icon>
    </tun-icon-button>
    <tun-icon-button
      *ngIf="inEditMode"
      [height]="heigthForTitle$ | async"
      (click)="toggleEditMode()"
      [style.visibility]="canAddSongs ? 'hidden' : 'visible'"
    >
      <tun-edit-arrow-left-icon></tun-edit-arrow-left-icon>
    </tun-icon-button>
  </div>

  <div class="right-header-buttons">
    <div>
      <tun-icon-button
        tunTooltipOnHover
        [tooltipText]="'tooltip.trackList.sort'"
        tunSongSortOnClick
        [height]="heigthForTitle$ | async"
        (songSortModeChange)="onSongSortModeChange($event)"
      >
        <tun-sort-icon></tun-sort-icon>
      </tun-icon-button>

      <tun-icon-button
        tunTooltipOnHover
        [tooltipText]="'tooltip.trackList.addAllToCustomList'"
        [tooltipEnabled]="!showingAddToListPopup"
        *ngIf="!inEditMode"
        [height]="heigthForTitle$ | async"
        #addToListButton
        tunPopupOnClick
        (togglepopup)="onToggleAddToListPopup($event)"
        [closeOnPopupClick]="false"
        [closeOnOutsideElementClick]="true"
      >
        <tun-add-to-list-icon></tun-add-to-list-icon>
      </tun-icon-button>

      <tun-icon-button
        *ngIf="!inEditMode"
        [height]="heigthForTitle$ | async"
        tunTooltipOnHover
        [tooltipText]="'tooltip.trackList.startEditMode'"
        (click)="toggleEditMode()"
        [style.visibility]="canAddSongs ? 'hidden' : 'visible'"
      >
        <tun-edit-arrow-right-icon></tun-edit-arrow-right-icon>
      </tun-icon-button>
    </div>
  </div>
</tun-panel-header>

<ng-container [ngSwitch]="playlistState">

  <div 
    *ngSwitchCase="(playlistState === PlaylistState.tracks || playlistState === PlaylistState.empty ? playlistState : null)"
    class="layered"
  >
    <div  
      *ngIf="playlistState == PlaylistState.empty"
      class="layer feedback-container"
    >
      <p translate>
        orange.playlist.empty
      </p>
    </div>

    <tun-song-grid
      class="layer"
      [playlist]="audioFiles"
      [showFrontDelete]="inEditMode"
      [showAddArrow]="canAddSongs"
      [isEditable]="inEditMode"
      [draggable]="inEditMode"
      [songSortMode]="songSortMode"
      [borderColor]="inEditMode ? '#7D4728' : '#171C1D'"
      (trackIndexEvent)="onTrackIndexEvent($event)"
      (trackEvent)="onTrackEvent($event)"
      [maxScrollbarHeight]="
        (availableHeightForPlayer$ | async) -
        (heightPerItem$ | async) -
        (heightForHeader$ | async) -
        (heightForTopBar$ | async) * 2
      "
    ></tun-song-grid>
  </div>

  <ng-container *ngSwitchCase="PlaylistState.loading">
    <tun-loader></tun-loader>
  </ng-container>

  <div  *ngSwitchCase="PlaylistState.error"
        class="error-feedback-container">
    <p translate>
      general.loadError
    </p>
    <button (click)="retryClick()" class="retryButton">
      <p translate>
        general.tryAgain
      </p>
    </button>
  </div>

  


</ng-container>
