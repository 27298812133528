import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from '@service/app.service';
import { AudioFile } from '@model/audioFile';
import { TracksEvent, TracksAction } from '@model/events/tracksEvent';
import { MusicManipulationService } from '@service/music-manipulation.service';

@Component({
  selector: 'tun-track-list',
  templateUrl: './track-list.component.html',
  styleUrls: ['./track-list.component.scss']
})
export class TrackListComponent {

  // === Props === //
  @Input() showSuggestion = true;
  @Input() title: string;
  @Input() trackList: AudioFile[];
  @Input() loading: boolean = true;

  // === State === //
  public get widthForContentPanel$(){
    return this.appService.widthForContentPanel$;
  }

  // === Emitters === //
  @Output() close = new EventEmitter<void>();

  constructor(
    private appService: AppService,
    private _musicManipulationService: MusicManipulationService
  ) { }


  onCloseSearch() {
    this.close.emit();
  }

  public onTracksEvent(tracksEvent: TracksEvent) {
    if (tracksEvent.action === TracksAction.ADD_TO_QUEUE) {
      this._musicManipulationService.addAudioFilesToQueue(tracksEvent.tracks);
    }
  }
}
