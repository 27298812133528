import { Component } from '@angular/core';

@Component({
  selector: 'tun-queue-icon',
  templateUrl: './queue-icon.component.html',
  styleUrls: ['./queue-icon.component.scss']
})
export class QueueIconComponent {
  constructor() {}
}
