import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { AppService } from '@service/app.service';

@Component({
  selector: 'tun-track-list-title-box',
  templateUrl: './track-list-title-box.component.html',
  styleUrls: ['./track-list-title-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackListTitleBoxComponent {
  // === Props === //
  @Input() title = '';

  // === Emitters === //
  @Output() close = new EventEmitter<void>();

  // === Getters === //
  get height$() {
    return this.appService.heightForHeader$;
  }

  get width$() {
    return this.appService.widthForContentPanel$;
  }

  constructor(private appService: AppService) {}

  onCloseIconClicked() {
    this.close.emit();
  }
}
