<div id="tabSelector" class="layerContainer">
    <div class="flexBoxMinimumSizing layer1 blackRightBorder" fxLayout="row">
        
        <!-- extra container for popups-->
        <div    tunCheckAccess
                [checkAccessToGreen]="true"
                tunTooltipOnHover
                [tooltipText]="'tooltip.tunifyTab.green'"
                [tooltipDirection]="PopupDirection.DOWN"
                [tooltipPosition]="PopupPosition.BOTTOM_CENTER"
                (clickWithAccess)="selectPlayerView(TunifyColor.GREEN)"
                fxFlex fxLayout="row" 
                class="flexBoxMinimumSizing">
            <tun-tab-button class="menuButton"
                            fxFlex 
                            [heightForIcon]="heightForIcon"
                            [selected]="selectedTunifyColor==TunifyColor.GREEN">
                <tun-calendar-icon [class]="selectedTunifyColor==TunifyColor.GREEN?'menuIcon selectedGreenMenuIcon':'menuIcon greenMenuIcon'"></tun-calendar-icon>        
            </tun-tab-button>
        </div>

        <!-- extra container for popups-->
        <div    tunCheckAccess
                [checkAccessToBlue]="true"
                tunTooltipOnHover
                [tooltipText]="'tooltip.tunifyTab.blue'"
                [tooltipDirection]="PopupDirection.DOWN"
                [tooltipPosition]="PopupPosition.BOTTOM_CENTER"
                (clickWithAccess)="selectPlayerView(TunifyColor.BLUE)"
                fxFlex fxLayout="row" 
                class="flexBoxMinimumSizing">
            <tun-tab-button class="menuButton"
                            fxFlex 
                            [heightForIcon]="heightForIcon"
                            [selected]="selectedTunifyColor==TunifyColor.BLUE">      
                <tun-stream-icon [class]="selectedTunifyColor==TunifyColor.BLUE?'menuIcon selectedBlueMenuIcon':'menuIcon blueMenuIcon'"></tun-stream-icon>
            </tun-tab-button>
        </div> 
        <!-- extra container for popups-->
        <div    tunCheckAccess
                [checkAccessToOrange]="true"
                tunTooltipOnHover
                [tooltipText]="'tooltip.tunifyTab.orange'"
                [tooltipDirection]="PopupDirection.DOWN"
                [tooltipPosition]="PopupPosition.BOTTOM_CENTER"
                [popupDirection]
                (clickWithAccess)="selectPlayerView(TunifyColor.ORANGE)"
                fxFlex fxLayout="row" 
                class="flexBoxMinimumSizing">
            <tun-tab-button class="menuButton"
                            fxFlex
                            [heightForIcon]="heightForIcon"
                            [selected]="selectedTunifyColor==TunifyColor.ORANGE">

                <tun-deejay-icon color="#ff692b"></tun-deejay-icon>
                  <!-- 
                    <tun-deejay-icon [class]="selectedTunifyColor==TunifyColor.ORANGE?'menuIcon selectedOrangeMenuIcon':'menuIcon orangeMenuIcon'"></tun-deejay-icon>
                --->
            </tun-tab-button>
        </div>
        

      
        <div class="verticalLine"></div>
    </div>
    <div class="layer2 barContainer noMouse">
        <div class="coloredBackground {{barStyle}}" 
            [style.width.px]="widthForBar" 
            [style.left.px]="positionForBar"
            [style.opacity]="barOpacity"
        >
        </div>
    </div>
</div>
