<sass-helper></sass-helper>
<tun-panel-header
  tunThemed
  [isCurrentMusicCollection]="isCurrentMusicCollection"
  [title]="title"
  (close)="onCloseClick()"
>
  <div class="right-header-buttons">
    <tun-tweakstrength-bar
      *ngIf="!shufflePlaylist && tweakInfoLoaded && !isContainerAnimating"
      @fadeIn
      [percentage]="tweakInfo?.percentage || 0"
      [status]="tweakInfo?.status"
    ></tun-tweakstrength-bar>
    <tun-icon-button
      tunTooltipOnHover
      [tooltipText]="'tooltip.parameters.defaultSettings'"
      *ngIf="!shufflePlaylist"
      [height]="heigthForTitle$ | async"
      (click)="onFactoryResetClick()"
    >
      <tun-factory-settings-icon></tun-factory-settings-icon>
    </tun-icon-button>
  </div>
</tun-panel-header>
<div
  class="fields-container"
  *ngIf="
    (!parametersLoading && !isContainerAnimating && changeableParameters) ||
    shufflePlaylist
  "
  @fadeIn
>
  <tun-scrollbar [maxScrollbarHeight]="maxScrollbarHeight">
    <div
      class="field-container"
      *ngFor="let param of changeableParameters; let i = index; let last = last"
      [class.bordered]="!last"
      [class.bottom-space]="last"
    >
      <div class="field checkboxes" *ngIf="isCheckboxSelectionParameter(param)">
        <div class="checkbox" *ngFor="let checkbox of param.value">
          <tun-checkbox
            [title]="checkbox.value"
            [isChecked]="
              checkbox.selected === undefined ? true : checkbox.selected
            "
            (isCheckedChanged)="onCheckedChanged(checkbox, $event)"
          >
          </tun-checkbox>
        </div>
      </div>

      <div
        class="field multi-label-text-slider"
        *ngIf="isSliderRangeSelectionParameter(param)"
      >
        <tun-text-slider
          [options]="param | mapSliderOptions"
          [selectedMinValue]="minSelectedValue(param)"
          [selectedMaxValue]="maxSelectedValue(param)"
          (selectedMinValueEmitter)="adjustMinValue(param, $event)"
          (selectedMaxValueEmitter)="adjustMaxValue(param, $event)"
        ></tun-text-slider>
      </div>

      <div
        class="field flexible-slider"
        *ngIf="isFlexibleSliderParameter(param)"
      >
        <tun-flexible-slider
          [minValue]="param.minimum"
          [maxValue]="param.maximum"
          [selectedMinValue]="param.lowerSelection"
          [selectedMaxValue]="param.upperSelection"
          [label]="
            param.metadataDisplayName === 'year'
              ? ''
              : param.metadataDisplayName
          "
          (selectedMinValueEmitter)="adjustMinValue(param, $event)"
          (selectedMaxValueEmitter)="adjustMaxValue(param, $event)"
        >
        </tun-flexible-slider>
      </div>

      <div
        class="field single-label-text-slider"
        *ngIf="isSingleLabelTextSliderParameter(param)"
      >
        <tun-text-slider
          [options]="param | mapSliderOptions"
          [selectedMinValue]="param.lowerSelection"
          [selectedMaxValue]="param.upperSelection"
          [isSingleLabel]="true"
          [label]="param.name"
          (selectedMinValueEmitter)="adjustMinValue(param, $event)"
          (selectedMaxValueEmitter)="adjustMaxValue(param, $event)"
        >
        </tun-text-slider>
      </div>
    </div>
    <div class="field-container" *ngIf="shufflePlaylist">
      <div
        class="field checkboxes"
        [style.flex-direction]="isTablet() ? 'column' : 'row'"
      >
        <div #checkboxcontainer class="checkbox-container">
          <tun-checkbox
            [title]="'calendar.tweak.randomOrder' | translate"
            [isChecked]="shufflePlaylistValue"
            [isShuffleCheckbox]="true"
            (isCheckedChanged)="onShufflePlaylistChanged($event)"
          >
          </tun-checkbox>
        </div>
      </div>
    </div>
  </tun-scrollbar>
</div>
<ng-container *ngIf="parametersLoading || !changeableParameters">
  <tun-loader></tun-loader>
</ng-container>
