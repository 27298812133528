import { PlayState } from '@model/enums/playState.enum';
import { environment } from '../../../../../environments/environment';
import { AudioProperties } from '../../../../model/audioFile';
import { TrackOrigin } from '../../../../model/trackOrigin';


  /**
  * Model for sharing information on the connected device
  */

  export class AppInfo{
    connectionType: ConnectionType;
    appVersion = environment.VERSION;
    deviceInfo: string;

    dedicated: Boolean  = false;
    connectEnabled: Boolean = true;
    listensToRemoteCommands: Boolean = true;
  }

  export enum ConnectionType{
    player = 'player',
    remote = 'remote',
    listener = 'listener',
    none = 'none',
  }


export class ExternalApplicationInfo{
  public clientId: string;
  public connectionId: string;

  public appVersion: string;
  public deviceInfo: string;
  public connectionType: ConnectionType;

  //when it is a player:
  public dedicated: Boolean = false;
  public connectEnabled: Boolean = true;
  public listensToRemoteCommands: Boolean = true;
}



  /**
   * Model for sharing the current player state between players and remotes
   */
  export class PlayerStatusForRemoteSharing{
    current: CurrentTrackInfoForRemoteSharing;
  }

  export class CurrentTrackInfoForRemoteSharing{
    track: TrackForRemoteSharing;
    playMode: PlayModeForRemoteSharing;
    progress: ProgressForRemoteSharing;
  }

  export class TrackForRemoteSharing implements AudioProperties{
    id: number;
    title: string;
    group: string;
    duration: number;
    type: RemoteAudioType;

    origin: TrackOrigin;

    get canPerformTrackActions(){
      return this.type == RemoteAudioType.song;
    }
  }

  export enum RemoteAudioType{
    song = "song",
    message = "message",
    brandMessage = "brandMessage",
    none = "none" //special type to indicate nothing is playing
  }

  export function canPerformTrackActionsOnRemoteAudioType(type: RemoteAudioType){
    return type == RemoteAudioType.song;
  }

  export enum PlayModeForRemoteSharing{
    stopped = "stopped",
    playing = "playing",
    startingPlay = "startingPlay",
    pause = "pause"
  }

  export class ProgressForRemoteSharing{
    currentTime: number;
    totalTime: number;
  }

  export function playModeForRemoteSharingToPlayState(playMode: PlayModeForRemoteSharing): PlayState{
    switch(playMode){
      case PlayModeForRemoteSharing.playing:
        return PlayState.PLAYING;
      case PlayModeForRemoteSharing.startingPlay:
        return PlayState.STARTING_TO_PLAY;
      case PlayModeForRemoteSharing.pause:
        return PlayState.PAUSED;
      case PlayModeForRemoteSharing.stopped:
        return PlayState.STOPPED;
    }
  }


  /**
   * Model for sending action from remotes to players
   */
  export class PlayerAction{
    action: PlayerActionEnum
  }

  export enum PlayerActionEnum{
    play = 'play',
    pause = 'pause',
    skip = 'skip',
  }
  export class TrackAction{
    trackId: number;
    trackOrigin: TrackOrigin;
    command: TrackCommand;
  }

  export enum TrackCommand{
    play = "play",
    addFirstInQueue = "addFirstInQueue"
  }

  /**
   * Model for sending actions to the prelisten application (headphone app)
   */

  export class PrelistenAction{
    trackId: string;
    prelistenCommand: PrelistenCommand;

    public toString():string{
      return this.prelistenCommand + ": track " + this.trackId;
  }
  }

  export enum PrelistenCommand{
    play = "play",
    pause = "pause",
    skipForward = "skipForward",
    skipBack = "skipBack"
  }
