import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Song } from '@model/song';
import { AudioFile } from '@model/audioFile';
import { PrelistenDeviceStatus, PrelistenService } from '@service/prelisten.service';
import { BehaviorSubject, Subject, timer, merge } from 'rxjs';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import { takeUntil } from 'rxjs/operators';
import { PrelistenCommand, PrelistenAction } from '@service/vo/remote/remote-objects';

@Component({
  selector: 'tun-song-preview',
  templateUrl: './song-preview.component.html',
  styleUrls: ['./song-preview.component.scss'],
})

export class SongPreviewComponent implements OnInit, OnDestroy {

  @Input() track: AudioFile;

  //make available in html
  PopupDirection = PopupDirection;
  PopupPosition = PopupPosition;

  public playing = true;
  public feedback = "";
  public enableButtons = true;

  private sendingSubject = new BehaviorSubject<boolean>(false);
  get loading$() {
    return this.sendingSubject.asObservable();
  }

  get prelistenDeviceDetection$() {
    return this.prelistenService.prelistenDeviceDetection$;
  }

  //expose enum to html
  public PrelistenDeviceStatus = PrelistenDeviceStatus;

  constructor(
    private prelistenService: PrelistenService,
    private changeDetectorRef: ChangeDetectorRef
  ) {

  }

  private _lastCommand: PrelistenCommand = null;
  private set lastCommand(command: PrelistenCommand) {
    this._lastCommand = command;
    if (this._lastCommand) {
      this.enableButtons = false;
      this.sendingSubject.next(true);
    }
  }
  private get lastCommand():PrelistenCommand{
    return this._lastCommand;
  }

  private autoCommand = false;
  ngOnInit() {
    this.lastCommand = PrelistenCommand.play;
    this.autoCommand = true;
    this.prelistenService.performPrelistenAction(this.track, PrelistenCommand.play);

    this.prelistenService.prelistenFeedback$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (succes) => {
          this.sendingSubject.next(false);
          if (succes && this._lastCommand != null) {
            if (this.autoCommand) {
              //directly show status feedback
              this.autoCommand = false;
              if (this.playing) {
                this.feedback = "popup.prelisten.status.done";
              } else {
                this.feedback = "";
              }
              this.enableButtons = true;
            } else {
              this.feedback = 'popup.prelisten.message.' + this._lastCommand;
              timer(2000)
                .pipe(
                  takeUntil(
                    this.destroyed$
                  )
                )
                .subscribe(
                  () => {
                    if (this.playing) {
                      this.feedback = "popup.prelisten.status.done";
                    } else {
                      this.feedback = "";
                    }
                    this.enableButtons = true;
                  }
                );
            }

          } else {
            this.playing = false;
            this.enableButtons = false;
          }
          this.lastCommand = null;
        }

      )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.prelistenService.performPrelistenAction(this.track, PrelistenCommand.pause);

    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  onSkipBackClick() {
    this.lastCommand = PrelistenCommand.skipBack;
    this.prelistenService.performPrelistenAction(this.track, this.lastCommand);
  }

  onTogglePlayClick() {
    if (this.playing) {
      this.lastCommand = PrelistenCommand.pause;
    } else {
      this.lastCommand = PrelistenCommand.play;
    }
    this.prelistenService.performPrelistenAction(this.track, this.lastCommand);
    this.playing = !this.playing;
  }

  onSkipForwardClick() {
    this.lastCommand = PrelistenCommand.skipForward;
    this.prelistenService.performPrelistenAction(this.track, this.lastCommand);
  }

}
