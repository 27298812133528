<div
  class="dropdown-field"
  tunPopupOnClick
  [closePopupAfterMouseLeave]="false"
  [closeOnOutsideElementClick]="false"
  [closeOnPopupClick]="false"
  (togglepopup)="toggleDropdown($event)"
  [class.selected]="showDropdown"
  [style.height.px]="(heightPerItem * 30) / 60 - 9"
  [style.top.px]="top"
  [style.border-color]="isDraggingOver ? '#62c362' : '#373f41'"
  (mouseenter)="onFieldHover()"
  (mouseleave)="onFieldLeaveHover()"
  ondragover="return false"
  #dropdownField
>
  <fa-icon class="dropdown-field-icon" size="lg" [icon]="faCaretDown"></fa-icon>
  <span class="dropdown-text">
    {{
      showTime || showDropdown ? (timeInMilliseconds | date: "HH:mm":"CET") : ""
    }}
  </span>
</div>
