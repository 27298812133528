<div class="dropdown-content" [style.width.px]="width">
  <div
    class="dropdown-item"
    #dropdownItemRef
    *ngFor="let dropdownItem of dropdownItems; let i = index"
    [class.selected]="i === selectedItemIndex"
    (click)="onItemSelect(dropdownItem, i)"
    (mouseenter)="onItemHover(dropdownItem, i, $event)"
  >
    <tun-thumb-up-icon *ngIf="dropdownItem.isFavorite" class="icon"></tun-thumb-up-icon>
    <div class="title-container">
      <span class="title" tunScrollOverflow>
        {{ dropdownItem.title }}
      </span>
    </div>
    <fa-icon
      class="dropdown-item-icon"
      *ngIf="dropdownItem.hasSubMenu"
      size="lg"
      [icon]="faAngleRight"
    ></fa-icon>
  </div>
</div>
<ng-content></ng-content>
