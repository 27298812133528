<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M9.545,4H6V1.5L2.638,5L6,8.5V6.928V6h3.545C11.45,6,13,7.55,13,9.545C13,11.45,11.45,13,9.545,13H7H6.786
	H6.675H4v2h2.786H7h2.545C12.553,15,15,12.553,15,9.455C15,6.447,12.553,4,9.545,4z"
  />
</svg>
