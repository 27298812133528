<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M13.575,6.271H12V1.849C12,0.828,10.71,0,9.688,0L7,5.949v8.201h6.617l2.275-3.779
	C16.979,8.568,15.68,6.271,13.575,6.271z"
  />
  <rect fill="currentColor" x="2" y="6.198" width="3" height="8" />
</svg>
