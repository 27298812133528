<div class="header" mat-dialog-title>
  <tun-popup-header [isInMatDialog]="true">
    {{'settings.option.banlist' | translate}}
  </tun-popup-header>
</div>

<div mat-dialog-content>
  <!-- Wrap the table in a div to make it scrollable -->
  <div class="blocked-songs-table">
    <table *ngIf="(songs$ | async).length; else noBlockedSongs">
      <!-- We can't use the component tag because this will break the HTML table -->
      <tr
        tun-blocked-song-row
        *ngFor="let song of songs$ | async"
        [song]="song"
        [loading]="isSongLoading(song)"
        [audioFileProperty]="audioFileProperty$ | async"
        (unblock)="onSongUnblocked($event)">
      </tr>
    </table>
  </div>
</div>

<ng-template #noBlockedSongs>
  <p>
    {{'popup.blockedSongs.noSongs' | translate}}
  </p>
</ng-template>

