 <!-- Current day -->
 <div class="current-message-container">
        <ng-container *ngFor="let message of allMessages; let messageIndex = index">
          <tun-on-screen-message
            [message]="message"
            class="message"
            *ngIf="messageIndex === currentIndex"
            [@carouselY]="{
              value: 'down',
              params: {
                duration: MESSAGE_ANIMATION_DURATION
              }
            }"
            ></tun-on-screen-message
          >
        </ng-container>
      </div>