<div
  class="search-field-container"
  [style.width.px]="widthForSearchBar"
  #searchFieldContainer
>
  <tun-search-field
    [previewValue]="searchTerm"
    (search)="onSearchWithText($event)"
		(searchfocus)="onFocusSearch()"
		(searchescape)="closeAutocompletionPopup()"
    (autocomplete)="onSearchAutocomplete($event)"
  ></tun-search-field>
</div>
