import { Component, OnDestroy, OnInit } from '@angular/core';
import { DTO_Region } from '@service/api/zone-configuration-api.service';
import { AppVersionService, MAJOR_VERSION } from '@service/app-version.service';
import { ExternalLinksService } from '@service/external-links.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Intercom } from '@supy-io/ngx-intercom';
import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import customProtocolCheck from 'custom-protocol-check';

@Component({
  selector: 'tun-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss']
})
export class HelpMenuComponent implements OnInit, OnDestroy {

  public previousVersionAllowed = false


  public showDownloadLink = false;

      public get helpCenterAvailable$(){
        return this.zoneConfigurationService.language$
          .pipe(
            map(
              (language) => {
                return language != null && language != "fr";
              }
            )
          )
      }

  public get phoneNumber$(){
    return combineLatest([this.zoneConfigurationService.region$, this.zoneConfigurationService.language$])
      .pipe(
        map(
          ([region, language]) => {
            if (language != null){
              if (language == "de"){
                return "0800 100 68 45"
              }else if (language == "nl"){
                if (region != null){
                  if (this.isBelgiumRegion(region)){
                    return "0800 17 017";
                  }else if (this.isNetherlandRegion(region)){
                    return "0800 023 3029";
                  }
                }
              }
            }
            return "0800 17 017";
          }
        )
      )
  }

  public get email$(){
    return this.zoneConfigurationService.language$
    .pipe(
      map(
        (language) => {
          if (language != null){
            if (language == "de"){
              return "info.de@tunify.com";
            }
          }
          return "info@tunify.com";
        }
      )
    )
  }

  //Helper functions
  private isBelgiumRegion(dtoRegion: DTO_Region){
    return dtoRegion != null && dtoRegion.code != null && (dtoRegion.code.toLowerCase() == "vl" || dtoRegion.code.toLowerCase() == "wa");
  }

  private isNetherlandRegion(dtoRegion: DTO_Region){
    return dtoRegion != null && dtoRegion.code != null && dtoRegion.code.toLowerCase() == "nl";
  }

  constructor(
    private appVersionService: AppVersionService,
    private zoneConfigurationService: ZoneConfigurationService,
    private intercom: Intercom,
    private externalLinksService: ExternalLinksService
  ) {
    this.appVersionService.allowedVersionsSubject$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (value) => {
        this.previousVersionAllowed = this.appVersionService.showLinkForVersion(MAJOR_VERSION.V4);
        }
    );
  }

  ngOnInit(): void {
      this.showDownloadLink = !isDesktopApp()
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }



  public onPreviousVersionClick(){
    this.appVersionService.changeVersionToUse(MAJOR_VERSION.V4);
  }

  public onOpenChat(){
    this.intercom.show();
  }

  public onOpenHelpCenter(){
    this.externalLinksService.openHelpCenter();
  }
}
