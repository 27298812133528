import { Component, ViewChild, OnDestroy, Input, OnChanges, SimpleChange, Output, EventEmitter, OnInit, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { LoggerService } from '@service/loggers/logger.service';
import { Subscription } from 'rxjs';
import { AudioFileWithPlayInfo } from '@service/audioFileWithPlayInfo';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { AudioFile } from '@model/audioFile';

@Component({
  selector: 'tun-audio-file-progress',
  templateUrl: './audio-file-progress.component.html',
  styleUrls: ['./audio-file-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioFileProgressComponent implements OnInit, OnDestroy, OnChanges {

    @Input() audioFile: AudioFile;
    @Input() audioFileWithPlayInfo: AudioFileWithPlayInfo;
    @Input() tunifyColor: TunifyColor = TunifyColor.BLUE;

    @Output() seekToTime:EventEmitter<number> = new EventEmitter<number>();


    private LOGGER_CLASSNAME = 'AudioFileProgressComponent';


    @ViewChild('unselectedline') unselectedline:any;



    public timeProgressString: string = '0:00';
    public timeLeftString: string = '0:00';
    public progressBarPercent = 0;
    public progressBarPercentInverse = 100; //100 - current percent, we need this for binding of the 'right' property

    private currentTime = 0;
    private currentDuration = 0;

    public progressBarStyle = "blueProgressBar";

    constructor(  private loggerService: LoggerService,
                  private ref: ChangeDetectorRef) {
      ref.detach();
    }

    ngOnInit(){
      this.adjustProgressBarStyle();
      //this.watchProgressProperties();
    }

    ngOnChanges(inputChanges:  SimpleChanges) {
      if (inputChanges.audioFileWithPlayInfo) {
        this.loggerService.info(this.LOGGER_CLASSNAME, 'ngOnChanges', 'audioFileWithPlayInfo input changed to ' + this.audioFileWithPlayInfo);
        this.watchProgressProperties();
      }
      if (inputChanges.tunifyColor) {
        this.adjustProgressBarStyle();
      }
      if (inputChanges.audioFile){
        this.adjustTimeProperties();
      }
    }

    private adjustProgressBarStyle(){
      if (this.tunifyColor == TunifyColor.BLUE){
        this.progressBarStyle = "blueProgressBar";
      }else if (this.tunifyColor == TunifyColor.GREEN){
        this.progressBarStyle = "greenProgressBar";
      }else if (this.tunifyColor == TunifyColor.ORANGE){
        this.progressBarStyle = "orangeProgressBar";
      }else {
        //this is a normal case at startup -> not yet set
        this.loggerService.debug(this.LOGGER_CLASSNAME, "adjustProgressBarStyle", "tunifyColor not recognized");
        this.progressBarStyle = "blueProgressBar";
      }
      this.ref.detectChanges();
    }

    private progressSubscription: Subscription;
    private durationSubscription: Subscription;
    private watchProgressProperties() {
      this.cleanupProgressSubscriptions();

      if (this.audioFileWithPlayInfo) {

        //These properties are undefined to enforce calculation is only executed once both are loaded
        this.currentDuration = undefined;
        this.currentTime = undefined;

        this.durationSubscription = this.audioFileWithPlayInfo.duration$.subscribe(
          (duration) => {
            this.currentDuration = duration;
            this.adjustToTime();
          }
        );

        this.progressSubscription = this.audioFileWithPlayInfo.currentTime$.subscribe(
          (currentTime) => {
            this.currentTime = currentTime;
            this.adjustToTime();
          }
        );

      }else{
        this.adjustTimeProperties();
      }
    }

    private adjustTimeProperties(){
      if (this.audioFileWithPlayInfo){
        this.currentDuration = this.audioFileWithPlayInfo.duration;
        this.currentTime = this.audioFileWithPlayInfo.currentTime;
      }else if (this.audioFile){
        this.currentDuration = this.audioFile.duration / 1000;
        this.currentTime = 0;
      }else{
        this.currentDuration = 0;
        this.currentTime = 0;
      }
      this.adjustToTime();
    }

    private cleanupProgressSubscriptions(){
      if (this.progressSubscription) {
        this.progressSubscription.unsubscribe();
        this.progressSubscription = null;
      }
      if (this.durationSubscription) {
        this.durationSubscription.unsubscribe();
        this.durationSubscription = null;
      }
    }

    ngOnDestroy() {
      this.cleanupProgressSubscriptions();
    }

    adjustToTime() {
      if (this.currentTime != undefined && this.currentDuration != undefined){
        this.timeProgressString = this.formatSecondsToTimeString(this.currentTime);
        this.timeLeftString = this.formatSecondsToTimeString(this.currentDuration - this.currentTime);

        var progressPercent:number = 0;
        if (this.currentDuration > 0 && this.currentTime > 0){
            progressPercent = this.currentTime * 100 / this.currentDuration;
        }

        this.progressBarPercent = progressPercent;
        this.progressBarPercentInverse = 100 - this.progressBarPercent;

         // console.log('adjustToTime done: ' + this.timeProgressString + ' - ' + this.timeLeftString + ': ' + this.progressBarPercent + '%');

        this.ref.detectChanges();
      }



    }

    formatSecondsToTimeString(seconds:number):string{
        var hours:number = Math.floor(( seconds / 3600 ) % 24);
        var minutes:number = Math.floor(( seconds / 60 ) % 60);
        var seconds:number = Math.floor(seconds % 60);

        return (hours>0?hours+":":"") + minutes + ":" + (seconds<10?"0":"") + seconds;
    }


    onClick(event: MouseEvent) {
      if (this.audioFileWithPlayInfo !== null){
        var rect = this.unselectedline.nativeElement.getBoundingClientRect();
        var x = event.clientX - rect.left;

        var percentClicked = x / rect.width;

        var timeToSeek: number = this.currentDuration * percentClicked;

        this.loggerService.debug(this.LOGGER_CLASSNAME, "onProgressBarClick", "We should seek to " + timeToSeek);

        this.seekToTime.emit(timeToSeek);
      }
    }
}
