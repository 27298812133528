<div class="messageContainer" fxLayout="colums=">
    <div    *ngIf="showMessage"
            class="messageLabel flexBoxMinimumSizing">
        <span tunScrollOverflow>{{message.message | translate}}</span>
    </div>
    <div    *ngIf="showLink"
            class="messageLink"
            (click)="onActionClick()">
            {{message.link | translate}}
    </div>
</div>
