import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { IconButtonComponent } from '@components/app-components/buttons/icon-button/icon-button.component';
import { SongSorter } from '@components/app-components/fields/song-grid/song-sorter.pipe';
import { MyListsPopupComponent } from '@components/popups/my-lists-popup/my-lists-popup.component';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import {
  AddToListSelection,
  ListOption,
  MyListsPopupContent,
  PopupContent
} from '@components/popups/popup/interfaces';
import { SuggestionPopupComponent } from '@components/popups/suggestion-popup/suggestion-popup.component';
import { AudioFile } from '@model/audioFile';
import { SongSortMode } from '@model/enums/songSortMode';
import { TracksAction, TracksEvent } from '@model/events/tracksEvent';
import { AppService } from '@service/app.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { PlaylistService } from '@service/playlist.service';
import { PopupService } from '@service/popup.service';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { merge, Subject } from 'rxjs';
import { filter, finalize, switchMap, takeUntil, map } from 'rxjs/operators';
import { PanelComponent } from '../panel.component';
import { SongInfoPopupComponent } from '@components/popups/song-info-popup/song-info-popup.component';
import { Util } from '../../../../utils/util';

export enum PlaylistState {
  loading,
  error,
  tracks,
  empty
}

@Component({
  selector: 'tun-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss'],
  // memory leak work-around: add no-op animation in parent component of each songGrid. Otherwise, the @HostBinding('@fadeIn') animation causes the component to stay in memory.
  animations: [trigger("noop", [])]
})
export class PlaylistComponent extends PanelComponent implements AfterViewInit, OnDestroy, OnChanges {
  // === Props === //
  @Input() tracksLoading = false;
  @Input() tracksLoadingError: string = null;
  @Input() audioFiles: AudioFile[];
  @Input() showPopupDelete = false;
  @Input() canAddSongs: boolean;
  @Input() showClose = true;

  // === State === //
  private _songSortMode = SongSortMode.NONE;

  // === Emitters === //
  @Output() tracksEvent = new EventEmitter<TracksEvent>();
  @Output() trackEvent = new EventEmitter<TrackEvent>();
  @Output() reloadTracksEvent = new EventEmitter<void>();

  // === ViewChildren === //
  @ViewChild('addToListButton')
  addToListButton: IconButtonComponent;
  @ViewChild('suggestionButton')
  suggestionButton: IconButtonComponent;

  // === Getters === //
  get availableHeightForPlayer$() {
    return this.appService.availableHeightForPlayer$;
  }

  get heightForHeader$() {
    return this.appService.heightForHeader$;
  }

  get heightForTopBar$() {
    return this.appService.heightForTopBar$;
  }
  get songSortMode() {
    return this._songSortMode;
  }

  get heightPerItem$() {
    return this.appService.heightPerItem$;
  }

  get heigthForTitle$() {
    return this.appService.heightPerItem$.pipe(map(value => value - 1));
  }

  get totalDuration(){
    if (this.audioFiles){
      return this.audioFiles.reduce((sum, track) => sum + track.duration / 1000, 0);
    }
    return 0;
  }

  public get title() {
    if (this.audioFiles != null && this.audioFiles.length > 0){
        let duration = this.audioFiles.reduce((sum, track) => sum + track.duration / 1000, 0);
        return this._title + ' (' + Util.formatSeconds(duration) + ')';

    }
    return this._title;
  }

  public set title(value: string){
    this._title = value;
  }


  get PlaylistState() {
    return PlaylistState;
  }

  get playlistState() {
    if (this.tracksLoading) {
      return PlaylistState.loading;
    } else if (this.tracksLoadingError != null) {
      return PlaylistState.error;
    } else if (this.audioFiles != null && this.audioFiles.length > 0) {
      return PlaylistState.tracks;
    } else {
      return PlaylistState.empty;
    }
  }

  get showingAddToListPopup() {
    return this.goingToShowAddToListPopup || this.addToListPopup != null;
  }

  constructor(
    protected appService: AppService,
    private _songSorter: SongSorter,
    private _zoneConfigurationService: ZoneConfigurationService,
    protected _popupService: PopupService,
    private _musicManipulationService: MusicManipulationService,
    private _playlistService: PlaylistService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterViewInit() {
    this._popupService.currentInstance$
      .pipe(
        filter(
          (popup: PopupContent) =>
            popup &&
            this.addToListButton != null &&
            popup.connectedElementRef === this.addToListButton.elementRef &&
            popup instanceof MyListsPopupComponent
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (instance: MyListsPopupComponent) => {
          this.addToListPopup = instance;
          this.goingToShowAddToListPopup = false;
        });

        this._popupService.currentInstance$
        .pipe(
          filter(
            (popup: PopupContent) =>
              popup &&
              this.suggestionButton != null &&
              popup.connectedElementRef === this.suggestionButton.elementRef &&
              popup instanceof SuggestionPopupComponent
          ),
          takeUntil(this.destroy$)
        )
        .subscribe(
          (instance: SuggestionPopupComponent) => {
            //do something
          });

    this._popupService.hidePopup$
      .pipe(
        filter(
          (elementRef: ElementRef) =>
            elementRef && this.addToListButton && elementRef === this.addToListButton.elementRef
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.addToListPopup = null;
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.tracksLoading) {
      //console.log("tracksLoading changed: " + this.tracksLoading);
    }
  }

  ngOnDestroy() {
    this.addToListPopupChange$.next();
    this.addToListPopupChange$.complete();
    this.addToListPopupChange$ = null;

    super.ngOnDestroy();
  }

  protected determineState() { }

  // === Event handlers === //
  onSongSortModeChange(songSortMode: SongSortMode) {
    this._songSortMode = songSortMode;
  }

  onAddToQueueClick() {
    if (this.audioFiles && this.audioFiles.length > 0) {
      this.tracksEvent.emit(
        new TracksEvent(TracksAction.ADD_TO_QUEUE, this.tracksAsInView())
      );
    }
  }

  onTrackEvent(trackEvent: TrackEvent) {
    this.trackEvent.emit(trackEvent);
  }

  private goingToShowAddToListPopup = false;
  onToggleAddToListPopup(showPopup: boolean) {
    if (showPopup) {
      this.goingToShowAddToListPopup = true;
      this._changeDetectorRef.detectChanges();
      this._popupService.showPopup$.next({
        connector: this.addToListButton.elementRef,
        componentType: MyListsPopupComponent,
        popupPosition: PopupPosition.BOTTOM_CENTER,
        showArrow: true,
        animationDuration: 300,
        popupDirection: PopupDirection.DOWN,
        dynamicPosition: true
      });
    } else {
      this._popupService.hidePopup$.next(this.addToListButton.elementRef);
    }
  }

  onToggleSuggestionPopup(showPopup: boolean) {
    if (showPopup) {
      this._popupService.showPopup$.next({
        connector: this.suggestionButton.elementRef,
        popupPosition: PopupPosition.BOTTOM_CENTER,
        componentType: SuggestionPopupComponent,
        showArrow: true,
        animationDuration: 300,
        popupDirection: PopupDirection.DOWN
      });
    } else {
      this._popupService.hidePopup$.next(this.suggestionButton.elementRef);
    }
  }

  hidePopups() {
    if (this.suggestionButton) {
      this._popupService.hidePopup$.next(this.suggestionButton.elementRef);
    }
    if (this.addToListButton) {
      this._popupService.hidePopup$.next(this.addToListButton.elementRef);
    }
  }

  //helper function to get a copy of the songs as appearing in the view (sorted or not)
  private tracksAsInView(): AudioFile[] {
    if (this.audioFiles) {
      return this._songSorter.transform(
        this.audioFiles,
        this.songSortMode,
        this._zoneConfigurationService.audioFileProperty
      );
    }
    return [];
  }

  //listener for add all songs to playlist popup
  private _addToListPopup: MyListsPopupContent;
  private addToListPopupChange$ = new Subject<void>();
  private set addToListPopup(instance: MyListsPopupContent) {
    this.addToListPopupChange$.next();
    this._addToListPopup = instance;
    //this._changeDetectorRef.detectChanges();

    if (instance) {
      instance.title = 'popup.addToPlaylist.title';
      instance.isSearchEnabled = false;
      instance.isAddToQueueEnabled = false;

      instance.onSelect$
        .pipe(
          switchMap(({ listOption, playlist }: AddToListSelection) => {
            switch (listOption) {
              case ListOption.QUEUE:
                return this._musicManipulationService.addAudioFilesToQueue(
                  this.tracksAsInView()
                );
              case ListOption.PLAYLIST:
                return this._playlistService.addAudioFileToPlaylist(
                  playlist,
                  this.tracksAsInView()
                );
              case ListOption.NEW_LIST:
                playlist.audioFiles = this.tracksAsInView();
                return this._playlistService.createPlaylist(playlist);
              case ListOption.FAVORITES:
                return this._playlistService.addAudioFileToPlaylist(
                  this._playlistService.favorites,
                  this.tracksAsInView()
                );
              default:
                return this._playlistService.addAudioFileToPlaylist(
                  playlist,
                  this.tracksAsInView()
                );
            }
          }),
          takeUntil(this.addToListPopupChange$),
          finalize(() => (this._addToListPopup = undefined))
        )
        .subscribe(
          (status: AsyncStatus) => (this._addToListPopup.status = status)
        );
    }
  }
  private get addToListPopup():MyListsPopupContent{
    return this._addToListPopup;
  }

  private closeAddToListPopup() {
    this._popupService.hidePopup$.next(this.addToListButton.elementRef);
  }
}
