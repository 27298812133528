<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M16.08,10.335V7.587h-1.748c-0.13-0.497-0.327-0.963-0.581-1.395l1.238-1.239l-1.941-1.942l-1.24,1.239
	c-0.431-0.254-0.897-0.451-1.395-0.58V1.92H7.665v1.748C7.169,3.797,6.703,3.994,6.27,4.249L5.032,3.011L3.09,4.953l1.238,1.238
	C4.074,6.623,3.877,7.09,3.747,7.587H2v2.748h1.747c0.13,0.497,0.327,0.963,0.581,1.395L3.09,12.968l1.943,1.943l1.238-1.238
	c0.433,0.254,0.898,0.451,1.395,0.58V16h2.748v-1.748c0.498-0.129,0.964-0.326,1.395-0.581l1.24,1.24l1.941-1.943l-1.238-1.238
	c0.254-0.433,0.451-0.898,0.581-1.395H16.08z M9.038,12.405c-1.898,0-3.444-1.545-3.444-3.444c0-1.9,1.546-3.445,3.444-3.445
	c1.9,0,3.447,1.545,3.447,3.445C12.485,10.86,10.937,12.405,9.038,12.405z"
  />
</svg>
