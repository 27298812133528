<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 18 18" enable-background="new 0 0 18 18" xml:space="preserve">
<rect x="4" y="14" fill="currentColor" width="12" height="1"/>
<rect x="4" y="11" fill="currentColor" width="12" height="1"/>
<rect x="8" y="8" fill="currentColor" width="8" height="1"/>
<rect x="10" y="5" fill="currentColor" width="6" height="1"/>
<g>
	<polygon fill="currentColor" points="1,2 1,10.518 7.693,6.259 	"/>
</g>
</svg>
