import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';

@Component({
  selector: 'tun-popup-navigation',
  templateUrl: './popup-navigation.component.html',
  styleUrls: ['./popup-navigation.component.scss'],
})
export class PopupNavigationComponent implements OnInit, OnDestroy {

  @Input() public showDelete: boolean;

  //public showAddToList: boolean;
  //public showSearchSimilar: boolean;

  public PopupDirection = PopupDirection;
  public PopupPosition = PopupPosition;


  private _destroyed$ = new Subject();

  // === Emitters === //
  @Output() public navigate = new EventEmitter<number>();

  constructor(private _zoneConfigurationService: ZoneConfigurationService) {}

  ngOnInit(
  ) {
    /*this._zoneConfigurationService.accessRights$
      .pipe(
        takeUntil(this._destroyed$)
      )
      .subscribe(
        (accessRights) => {
          this.showAddToList = accessRights && accessRights.customPlaylists;
          this.showSearchSimilar = environment.enableSearch && accessRights && accessRights.search;
        }
      )*/
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$ = null;
  }

  onButtonClick(index: number) {
    this.navigate.emit(index);
  }
}
