<div fxFlex class="darkRasterBackground logoPadding flexBoxMinimumSizing"
      [style.paddingTop.px]="verticalPadding"
      [style.paddingBottom.px]="verticalPadding">
  <div class="imageContainer"
        [style.height.px]="heightForLogo">
    <img class="fullfit" [src]="royaltyFree?'assets/logo/tunify_white_royaltyFree2.svg':'assets/logo/tunify_white.svg'">
  </div>
     
</div>

