import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StreamViewComponent } from './stream-view.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuStreamPanelComponent } from './menu-stream-panel/menu-stream-panel.component';
import { MidStreamPanelComponent } from './mid-stream-panel/mid-stream-panel.component';
import { MusicChannelMenuComponent } from './menu-stream-panel/menu/music-channel-menu/music-channel-menu.component';
import { CalendarMenuComponent } from './menu-stream-panel/menu/calendar-menu/calendar-menu.component';
import { TitleBoxComponent } from './mid-stream-panel/title-box/title-box.component';
import { CalendarContentPanelComponent } from './mid-stream-panel/calendar-content-panel/calendar-content-panel.component';
import { MusicChannelContentPanelComponent } from './mid-stream-panel/music-channel-content-panel/music-channel-content-panel.component';
import { PlayerComponentsModule } from '@components/player-components/player-components.module';
import { AppComponentsModule } from '@components/app-components/app-components.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { IconsModule } from '@components/icons/icons.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CalendarContentHeaderComponent } from './mid-stream-panel/calendar-content-header/calendar-content-header.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FilterCalendarItems } from './mid-stream-panel/calendar-content-panel/calendar-items.pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import { TrackListTitleBoxComponent } from './track-list/track-list-title-box/track-list-title-box.component';
import { TrackListComponent } from './track-list/track-list.component';
import { TimelineComponent } from './mid-stream-panel/calendar-content-panel/timeline/timeline.component';
import { DayNavigatorComponent } from './mid-stream-panel/calendar-content-header/day-navigator/day-navigator.component';
import { PopupsModule } from '@components/popups/popups.module';
import { UtilsModule } from '@util/utils.module';
import { TrackListContentComponent } from './track-list/track-list-content/track-list-content.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BulkActionFeedbackBottomSheetComponent } from './mid-stream-panel/calendar-content-panel/bulk-action-feedback-bottom-sheet/bulk-action-feedback-bottom-sheet.component';
import { ResizeModule } from '@components/resize/resize.module';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        PlayerComponentsModule,
        AppComponentsModule,
        MatCheckboxModule,
        IconsModule,
        PerfectScrollbarModule,
        FontAwesomeModule,
        MatProgressSpinnerModule,
        OverlayModule,
        PopupsModule,
        UtilsModule,
        TranslateModule,
        MatBottomSheetModule,
        ResizeModule
    ],
    declarations: [
        StreamViewComponent,
        MenuStreamPanelComponent,
        MusicChannelMenuComponent,
        CalendarMenuComponent,
        MidStreamPanelComponent,
        TitleBoxComponent,
        CalendarContentPanelComponent,
        MusicChannelContentPanelComponent,
        CalendarContentHeaderComponent,
        FilterCalendarItems,
        TrackListComponent,
        TrackListTitleBoxComponent,
        TrackListContentComponent,
        TimelineComponent,
        DayNavigatorComponent,
        BulkActionFeedbackBottomSheetComponent,
    ],
    exports: [StreamViewComponent]
})
export class StreamViewModule {}
