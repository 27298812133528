import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { Song } from '@model/song';
import { AudioFileProperty, formatTrackProperty } from '@model/enums/audioFileProperty';

@Component({
  selector: '[tun-blocked-song-row]',
  templateUrl: './blocked-song-row.component.html',
  styleUrls: ['./blocked-song-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockedSongRowComponent {

  // === Props === //
  @Input()
  song: Song;

  @Input()
  loading = false;

  @Input()
  set audioFileProperty(prop: AudioFileProperty) {
    // Do not recalculate the value of the third column on every render, only when the input value is set
    this.customColumnValue = formatTrackProperty(this.song, prop);
  }

  // === State === //
  // The value of the third column is chosen by the user in the SongInfoPopup
  customColumnValue = '';

  // === Emitters === //
  @Output()
  unblock = new EventEmitter<Song>();

  onUnblockClicked() {
    this.unblock.emit(this.song);
  }
}
