<td class="icon-container">
  <button 
    tunTooltipOnHover
    [tooltipText]="'tooltip.banlist.unban'"
    (click)="onUnblockClicked()" 
    [disabled]="loading"
  >
    <mat-spinner *ngIf="loading; else unBlockIcon"></mat-spinner>
  </button>
</td>
<td>
  {{ song.title }}
</td>
<td>
  {{ song.group }}
</td>
<td>
  {{ customColumnValue }}
</td>

<ng-template #unBlockIcon>
  <tun-factory-settings-icon></tun-factory-settings-icon>
</ng-template>
