import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppVersionService } from '@service/app-version.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tun-version-popup',
  templateUrl: './version-popup.component.html',
  styleUrls: ['./version-popup.component.scss']
})
export class VersionPopupComponent implements OnInit, OnDestroy {

  public static widthForPopup = '30vw';

  public tunifyVersion = "";

  constructor(
    public dialogRef: MatDialogRef<VersionPopupComponent>,
    private appVersionService: AppVersionService
  ){
    this.appVersionService.version$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (version) => {
        this.tunifyVersion = version;
      }
    )

  }

  ngOnInit() {
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public onCancelClick(){
    this.dialogRef.close();
  }

}
