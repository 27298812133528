import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppService } from '@service/app.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'tun-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, OnDestroy {
  // === Props === //
  @Input() public currentTimeMultiplier: number;
  @Input() public hours: string[];

  // === State === //
  private _heightPerItem: number;

  // === Observables === //
  private _destroy$ = new Subject();

  // === Getters === //
  public get heightPerItem(): number {
    return this._heightPerItem;
  }

  constructor(appService: AppService) {
    appService.heightPerItem$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this._heightPerItem = value));
  }

  ngOnInit() {}

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
  }
}
