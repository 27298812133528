import { transition, trigger, useAnimation } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AudioFileProperty } from '@model/enums/audioFileProperty';
import {
  isSearchAction,
  TrackAction,
  TrackEvent
} from '@model/events/trackEvent';
import { Playlist } from '@model/playlist';
import { Song } from '@model/song';
import { SuggestionsType } from '@service/api/search-api.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { PlaylistService } from '@service/playlist.service';
import { PopupService } from '@service/popup.service';
import { QueueService } from '@service/queue.service';
import { SearchService } from '@service/search.service';
import { AsyncStatus, isFinal } from '@service/vo/asyncStatus';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { fadeAnimation } from '@util/animations';
import { Observable, Subject, timer, merge } from 'rxjs';
import { slideInLeft, slideInRight } from '../popup-animations';
import {
  AddToListSelection,
  ListOption,
  SongInfoPopupContent
} from '../popup/interfaces';
import { PopupNavigationComponent } from './popup-navigation/popup-navigation.component';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PrelistenService } from '@service/prelisten.service';
import { TrackManipulationControllerService } from '../../../services/audio/track-manipulation-controller.service';

@Component({
  selector: 'tun-song-info-popup',
  templateUrl: './song-info-popup.component.html',
  styleUrls: ['./song-info-popup.component.scss'],
  animations: [
    slideInLeft(),
    slideInRight(),
    trigger('fadeInOut', [
      transition(':enter', [
        // fade in
        useAnimation(fadeAnimation, { params: { delay: 300 } })
      ]),
      transition(':leave', [
        // fade out
        useAnimation(fadeAnimation, { params: { from: 1, to: 0 } })
      ])
    ])
  ]
})
export class SongInfoPopupComponent
  implements OnInit, SongInfoPopupContent, AfterViewInit, OnDestroy {
  // === Props === //
  @Input() public song = new Song();
  @Input() public showNavigation: boolean;
  @Input() public showDelete: boolean;
  @Input() public connectedElementRef: ElementRef;

  public selectTrackEvent$ = new Subject<TrackEvent>();

  // === State === //
  private _navigationIndex = -1;
  /** This is used to disable the animation for song options content upon entering the DOM for the first time */
  private _disableAnimation = true;

  showAddToNewList: boolean;

  private readonly DELAY_STATUS_CHANGE = 2000;

  // === Observables === //
  private _destroy$ = new Subject();
  private _status$: Observable<AsyncStatus>;
  private cancelStatus$ = new Subject<void>();
  public set status$(statusObservable: Observable<AsyncStatus>){
    //cancel any previous subscriptions
    this.cancelStatus$.next(null);
    this._status$ = statusObservable;
    if (this._status$){
      this.status$.pipe(
        takeUntil(
          merge(
            this._destroy$,
            this.cancelStatus$
          )
        )
      )
      .subscribe(
        (asyncStatus) => {
          if (isFinal(asyncStatus)){
            timer(this.DELAY_STATUS_CHANGE)
              .pipe(takeUntil(
                merge(
                  this._destroy$,
                  this.cancelStatus$
                )
              ))
              .subscribe(() => this.closePopup());
          }
        }
      );
    }
  }
  public get status$(): Observable<AsyncStatus>{
    return this._status$;
  }

  // === ViewChildren === //
  @ViewChild(PopupNavigationComponent, { static: true })
  navigation: PopupNavigationComponent;

  // === Getters === //
  get header(): string {
    switch (this._navigationIndex) {
      case 3:
        return this._translateService.instant('popup.addToPlaylist.title');
      case 1:
        return this._translateService.instant('popup.prelisten.title.short');
      default:
        return this._translateService.instant('popup.songInfo.title');
    }
  }

  get currentAudioFileProperty(): AudioFileProperty {
    return this._zoneConfigurationService.audioFileProperty;
  }

  get navigationIndex(): number {
    return this._navigationIndex;
  }

  get playlists(): Playlist[] {
    return this._playlistService.playlists;
  }

  get favorites(): Playlist {
    return this._playlistService.favorites;
  }

  get disableAnimation(): boolean {
    return this._disableAnimation;
  }

  get isSafari() {
    return (
      window.navigator.userAgent.indexOf('Safari') >= 0 &&
      window.navigator.userAgent.indexOf('Chrome') < 0
    );
  }

  constructor(
    private _zoneConfigurationService: ZoneConfigurationService,
    private _playlistService: PlaylistService,
    private _popupService: PopupService,
    private _searchService: SearchService, // directly connect to the search service
    private _musicManipulationService: MusicManipulationService,
    private _trackManipulationControllerService: TrackManipulationControllerService,
    public elementRef: ElementRef,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    // After song options content has entered the DOM for the first time, allow it to animate again
    timer(100).subscribe(() => (this._disableAnimation = false));
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$ = null;
    this.cancelStatus$.complete();
    this.cancelStatus$ = null;
  }

  // === Event handlers === //
  onAddToList({ listOption, playlist }: AddToListSelection) {
    // implement add to list here
    switch (listOption) {
      case ListOption.QUEUE:
        this.status$ = this._musicManipulationService.addAudioFilesToQueue([this.song]);
        break;
      case ListOption.NEW_LIST:
        playlist.audioFiles = [this.song];
        this.status$ = this._playlistService.createPlaylist(playlist);
        break;
      default:
        this.status$ = this._playlistService.addAudioFileToPlaylist(playlist, [
          this.song
        ]);
    }
  }

  onPropertySelect(audioFileProperty: AudioFileProperty) {
    this._zoneConfigurationService.changeAudioFileProperty(audioFileProperty);
  }

  onNavigate(index: number) {
    this._navigationIndex = index;
    //back clicked -> stop listening for status or cancel close on delay
    if (index === -1){
      this.status$ = null;
    }
    else if (index === 0) {
      // play clicked
      this._trackManipulationControllerService.playAudioFile(this.song);
      this.closePopup();
    } else if (index === 2) {
      // search similar
      this._searchService.suggestionsForTrack(
        SuggestionsType.SUGGEST_SIMILAR,
        this.song
      );
      this.closePopup();
    } else if (index === 4) {
      // remove track
      this.selectTrackEvent$.next(
        new TrackEvent(TrackAction.REMOVE, this.song)
      );
      this.closePopup();
    }
  }

  onCloseClick() {
    this.closePopup();
  }

  onTrackEvent(trackEvent: TrackEvent) {
    if (isSearchAction(trackEvent.action)) {
      this._searchService.handleSearchOnTrack(trackEvent);
      this._popupService.hidePopup$.next(this.connectedElementRef);
    } else {
      this.selectTrackEvent$.next(trackEvent);
    }
  }
  /*
  onAddToNewList(title: string) {
    const playlist = new Playlist();
    playlist.title = title;

    // TODO: Dries, connect this to the service
  }
  */

  private closePopup(){
    this._popupService.hidePopup$.next(this.connectedElementRef);
  }
}
