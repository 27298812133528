<div  class="timeline-container"
     >
 
  <div class="hours" [style.top.px]="heightPerItem / 2">
    <div
      class="hour"
      *ngFor="let item of hours; let i = index"
      [class.first-hour]="i == 0"
      [style.min-height.px]="heightPerItem"
    >
      {{ item }}
    </div>
  </div>

  <div
  class="time-indicator left"
  [style.top.px]="heightPerItem / 2 +
    heightPerItem * currentTimeMultiplier - 2
  "
></div>
<div
  class="time-indicator right"
  [style.top.px]=" heightPerItem / 2 +
    heightPerItem * currentTimeMultiplier - 2
  "
></div>
  
</div>
