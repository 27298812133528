<div fxLayout="row" class="fullContainer">
    
  <div id="timeLabel1" class="timeLabel leftLabel">
    <span>{{timeProgressString}}</span>
  </div>
  
  <div fxFlex class="lines" (click)="onClick($event)">
    
        <div
          #unselectedline
          class="line unselected-line"
        ></div>
        <div
          #selectedline
          class="line selected-line"
          [style.right.%]="progressBarPercentInverse"
        ></div>
      

  </div>

  <div id="timeLabel2" class="timeLabel rightLabel">
    {{timeLeftString}}
  </div>

</div>