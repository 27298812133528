import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { Playlist } from '@model/playlist';

@Component({
  selector: 'tun-my-lists-popup-content',
  templateUrl: './my-lists-popup-content.component.html',
  styleUrls: ['./my-lists-popup-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyListsPopupContentComponent {

  // Make the enum available in the HTML
  AsyncStatus = AsyncStatus;

  isAddingToNewList = false;

  // === Props === //
  @Input() status: AsyncStatus;
  @Input() playlists: Playlist[];
  @Input() favorites: Playlist;
  @Input() isSearchEnabled: boolean;
  @Input() isAddToQueueEnabled: boolean;

  // === EventEmitters === //
  @Output() search = new EventEmitter<Playlist>();
  @Output() select = new EventEmitter<Playlist>();
	@Output() newplaylist = new EventEmitter<Playlist>();

  onSearch(playlist: Playlist) {
    this.search.emit(playlist);
  }

  onSelectPlaylist(playlist: Playlist) {
    this.select.emit(playlist);
  }

  toggleAddToNewList() {
    this.isAddingToNewList = !this.isAddingToNewList;
  }

	onAddToNewList(name: string) {
		const playlist = new Playlist(null);
		playlist.title = name;
		this.newplaylist.emit(playlist);
	}
}
