<form (ngSubmit)="onSave()" [formGroup]="titleForm">
  <p translate>
      popup.addToPlaylist.addToNew.info
  </p>

  <div class="input-wrapper">
    <input type="text" formControlName="title" (keydown.enter)="onSave()" />
  </div>

  <div class="buttons">
    <button type="submit" [disabled]="!titleForm.valid" translate>
      general.save
    </button>
    <button type="button" (click)="onCancel()" translate>
      general.cancel
    </button>
  </div>
</form>
