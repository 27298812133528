import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ElementRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { ChangeableIntervalParameter } from '@model/changeableIntervalParameter';
import { ChangeableParameter } from '@model/changeableParameter';
import { ChangeableSelectionParameter } from '@model/changeableSelectionParameter';
import { MetadataDisplayType } from '@model/enums/metadataDisplayType';
import { TweakInfo } from '@model/tweakInfo';
import { Value } from '@model/value';
import { AppService } from '@service/app.service';
import { PanelComponent } from '../panel.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tun-changeable-parameters',
  templateUrl: './changeable-parameters.component.html',
  styleUrls: ['./changeable-parameters.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter, :leave', animate('300ms ease-in-out'))
    ])
  ]
})
export class ChangeableParametersComponent extends PanelComponent
  implements OnInit {
  // === Props === //
  @Input() changeableParameters: ChangeableParameter[];
  @Input() parametersLoading: boolean;
  @Input() tweakInfo: TweakInfo;
  @Input() tweakInfoLoaded: boolean;
  @Input() maxScrollbarHeight: number;
  @Input() shufflePlaylist: boolean;
  @Input() shufflePlaylistValue: boolean = false;

  // === Emitters === //
  @Output() reset = new EventEmitter();
  @Output() parameterchange = new EventEmitter<ChangeableParameter[]>();

  @Output() shufflePlaylistChange = new EventEmitter<boolean>();

  get heightPerItem$(): Observable<number> {
    return this._appService.heightPerItem$;
  }

  get heigthForTitle$(){
    return this._appService.heightPerItem$.pipe(map(value=> value -1));
  }

  constructor(public elementRef: ElementRef, private _appService: AppService) {
    super();
  }

  ngOnInit() {}

  // === Event handlers === //
  onFactoryResetClick() {
    this.reset.emit();
  }

  onCheckedChanged(paramValue: Value, newValue: boolean) {
    paramValue.selected = newValue;
    this.parameterchange.emit(this.changeableParameters);
  }

  adjustMinValue(param: ChangeableParameter, value: number) {
    if (param instanceof ChangeableIntervalParameter) {
      if (param.lowerSelection !== value) {
        param.lowerSelection = value;
        this.parameterchange.emit(this.changeableParameters);
      }
    } else if (param instanceof ChangeableSelectionParameter) {
      const selectUpTo = this.maxSelectedValue(param);
      let valueHasChanged = false;
      param.value.forEach((valueObject: Value, index: number) => {
        const shouldSelect = index >= value && index <= selectUpTo;
        if (valueObject.selected !== shouldSelect) {
          valueObject.selected = shouldSelect;
          valueHasChanged = true;
        }
      });
      if (valueHasChanged) {
        this.parameterchange.emit(this.changeableParameters);
      }
    }
  }

  adjustMaxValue(param: ChangeableParameter, value: number) {
    //console.log('max: ', value);
    if (param instanceof ChangeableIntervalParameter) {
      if (param.upperSelection !== value) {
        param.upperSelection = value;
        this.parameterchange.emit(this.changeableParameters);
      }
    } else if (param instanceof ChangeableSelectionParameter) {
      const selectFrom = this.minSelectedValue(param);
      let valueHasChanged = false;
      param.value.forEach((valueObject: Value, index: number) => {
        const shouldSelect = index >= selectFrom && index <= value;
        if (valueObject.selected !== shouldSelect) {
          valueObject.selected = shouldSelect;
          valueHasChanged = true;
        }
      });
      if (valueHasChanged) {
        this.parameterchange.emit(this.changeableParameters);
      }
    }
  }

  // return the index of the first selected value
  minSelectedValue(param: ChangeableParameter): number {
    let minIndex = 0;
    let found = false;
    if (param instanceof ChangeableSelectionParameter && param.value) {
      param.value.forEach((value, index) => {
        if (value.selected && !found) {
          minIndex = index;
          found = true;
        }
      });
    }
    return minIndex;
  }

  // return the index of the last selected value
  maxSelectedValue(param: ChangeableParameter): number {
    let maxIndex = 0;
    if (param instanceof ChangeableSelectionParameter && param.value) {
      param.value.forEach((value, index) => {
        if (value.selected) {
          maxIndex = index;
        }
      });
    }
    return maxIndex;
  }

  // === Helper functions === //
  isCheckboxSelectionParameter(changeableParameter: ChangeableParameter) {
    return (
      changeableParameter instanceof ChangeableSelectionParameter &&
      changeableParameter.metadataDisplayType ===
        MetadataDisplayType.TEXT_CHECKBOX_SELECTION
    );
  }

  isSliderRangeSelectionParameter(changeableParameter: ChangeableParameter) {
    return (
      changeableParameter instanceof ChangeableSelectionParameter &&
      changeableParameter.metadataDisplayType ===
        MetadataDisplayType.TEXT_SLIDER_RANGE_SELECTION
    );
  }

  isFlexibleSliderParameter(changeableParameter: ChangeableParameter) {
    return (
      changeableParameter instanceof ChangeableIntervalParameter &&
      changeableParameter.minimum > 7
    );
  }

  isSingleLabelTextSliderParameter(changeableParameter: ChangeableParameter) {
    return (
      changeableParameter instanceof ChangeableIntervalParameter &&
      changeableParameter.minimum <= 7
    );
  }

  isTablet = () => {
    return window.innerWidth < 768;
  };

  onShufflePlaylistChanged(newValue: boolean) {
    this.shufflePlaylistValue = newValue;
    this.shufflePlaylistChange.emit(this.shufflePlaylistValue);
  }
}
