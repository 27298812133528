import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BlockedSongsPopupComponent } from '@components/popups/blocked-songs-popup/blocked-songs-popup.component';
import { OptionItem, Options, OptionsMenuComponent } from '@components/popups/options-menu/options-menu.component';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import { OptionsMenuPopupContent } from '@components/popups/popup/interfaces';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { AppService } from '@service/app.service';
import { AudioTagService } from '@service/audio.tag.service';
import { PopupService } from '@service/popup.service';
import { UpdateService } from '@service/update.service';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SabamPopupComponent } from '@components/popups/sabam-popup/sabam-popup.component';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { ExternalLinksService } from '@service/external-links.service';
import { VersionPopupComponent } from '@components/popups/version-popup/version-popup.component';
import { LanguagePopupComponent } from '@components/popups/language-popup/language-popup.component';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { Intercom } from '@supy-io/ngx-intercom';

/**
 * Todo: We need ViewEncapsulation.None in order to apply the styles to our svg icon components
 * Check if there is a better way to apply colors to svg's
 */
@Component({
  selector: 'tun-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy, AfterViewInit {
  // === Constants === //
  readonly POPUP_ANIMATION_DURATION = 300;
  readonly SETTINGS_MENU_HEADER = 'settings.title';
  readonly SETTINGS_MENU_ITEMS: OptionItem[][] = [
    // add more options here

    [
      { description: 'settings.option.banlist', type: Options.BLOCKED_SONGS },
      { description: 'settings.option.language', type: Options.LANGUAGE }
    ],
    [
      { description: 'settings.option.buy', type: Options.SUBSCRIPTION },
      { description: 'settings.option.authorRight', type: Options.COPYRIGHT },
      { description: 'settings.option.version', type: Options.VERSION }
    ],
  ];

  readonly HELP_MENU_HEADER = 'settings.help.title';
  readonly optionItemHelpGreen = { description: 'settings.option.helpGreen', type: Options.HELP };
  readonly optionItemHelpBlue = { description: 'settings.option.helpBlue', type: Options.HELP };
  readonly optionItemHelpOrange = { description: 'settings.option.helpOrange', type: Options.HELP };
  readonly HELP_MENU_ITEM: OptionItem[][] = [
    [this.optionItemHelpGreen, this.optionItemHelpBlue, this.optionItemHelpOrange]
  ]

  // === Props === //
  @Input() selectedTunifyColor: TunifyColor = TunifyColor.BLUE;
  @Input() heightForTopBar: number = 0; //used to determine the height of the icons

  // === State === //
  public availableHeightForPlayer = 0;
  public availableWidthForPlayer = 0;

  public get widthForMenuPanel$(){
    return this.appService.widthForMenuPanel$;
  }
  public get widthForSearchBar$(){
    return this.appService.widthForContentPanel$;
  }

  public mockData = environment.mockBackend;

  public newVersionAvailable = false;

  public audioContextState;
  public needClickToPlayAudio = false;

  public newInfoAvaible = false;
  private newInfo = '';

  get enableIntercomButton(){
    return environment.enableIntercomMessenger;
  }

  // === Observables === //
  private componentDestroyed$ = new Subject<boolean>();

  // === ViewChildren === //
  @ViewChild('optionsButton') optionsButton: ElementRef;
  @ViewChild('helpButton') helpButton: ElementRef;

  constructor(
    private appService: AppService,
    private updateService: UpdateService,
    private audioTagService: AudioTagService,
    private changeDetectorRef: ChangeDetectorRef,
    private _popupService: PopupService,
    private _matDialog: MatDialog,
    private _zoneConfigurationService: ZoneConfigurationService,
    private zoneConnectionsService: ZoneConnectionsService,
    private _externalLinksService: ExternalLinksService,
    private _intercom: Intercom
  ) { }

  public unreadIntercomMessages = 0;
  private onScreenKeyboardOptionItem: OptionItem = null;
  ngOnInit() {

    //fill options
    if (environment.enableOnScreenKeyboard) {

      const subMenu = [];

      if (environment.enableOnScreenKeyboard){
        this.onScreenKeyboardOptionItem = {
          description: 'settings.option.onscreenKeyboard',
          type: Options.ONSCREENKEYBOARD,
          selectable: true,
        };
        subMenu.push(this.onScreenKeyboardOptionItem);
      }


      this.SETTINGS_MENU_ITEMS.unshift(subMenu);
    }

      this._zoneConfigurationService.enableOnScreenKeyboard$
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (value) => {
          if (this.onScreenKeyboardOptionItem != null) {
            this.onScreenKeyboardOptionItem.selected = value;
          }
        }
      );


    this.audioTagService.audioContextState$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(state => {
        this.audioContextState = state;
        this.changeDetectorRef.detectChanges();
      });

    this.audioTagService.needClickToStartAudio$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.needClickToPlayAudio = value;
        this.changeDetectorRef.detectChanges();
      });

    //this._intercom.onUnreadCountChange(this.intercomChanged);

  }
  /*
  private intercomChanged = (count) => {
    this.unreadIntercomMessages = count;
    this.changeDetectorRef.detectChanges();
  }
  */

  ngAfterViewInit() {

    //settings menu

    this._popupService.currentInstance$
      .pipe(
        filter(
          ({ connectedElementRef }) =>
            connectedElementRef === this.optionsButton
        ),
        tap((instance: OptionsMenuPopupContent) => {
          instance.optionItems = this.SETTINGS_MENU_ITEMS;
          instance.header = this.SETTINGS_MENU_HEADER;
          instance.showConnectedZone = true;
        }),
        switchMap(({ optionClick$ }: OptionsMenuPopupContent) => optionClick$),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(option => {
        // Implement settings menu option click here
        switch (option.type) {
          case Options.BLOCKED_SONGS:
            this._matDialog.open(BlockedSongsPopupComponent, { width: BlockedSongsPopupComponent.widthForPopup });
            break;
          case Options.LANGUAGE:
            this._matDialog.open(LanguagePopupComponent, { width: LanguagePopupComponent.widthForPopup });
            break;
          case Options.COPYRIGHT:
            this._matDialog.open(SabamPopupComponent, { width: SabamPopupComponent.widthForPopup });
            break;
          case Options.ONSCREENKEYBOARD:
            this._zoneConfigurationService.enableOnScreenKeyboard = !option.selected;
            break;
          case Options.SUBSCRIPTION:
            this._externalLinksService.openZoneBuyPage();
            break;
          case Options.VERSION:
            this._matDialog.open(VersionPopupComponent, { width: VersionPopupComponent.widthForPopup });
            break
          case Options.LOGOUT:
            this.zoneConnectionsService.deactivateCurrentZoneConnection(null);
            break;
          case Options.CORONA_APP:
            this._externalLinksService.openCoronaPlayer();
            break;
        }
      });

    //help menu
    this._popupService.currentInstance$
      .pipe(
        filter(
          ({ connectedElementRef }) =>
            connectedElementRef === this.helpButton
        ),
        tap((instance: OptionsMenuPopupContent) => {
          instance.optionItems = this.HELP_MENU_ITEM;
          instance.header = this.HELP_MENU_HEADER;
        }),
        switchMap(({ optionClick$ }: OptionsMenuPopupContent) => optionClick$),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(option => {
        if (option == this.optionItemHelpGreen) {
          this._externalLinksService.openHelpGreen();
        } else if (option == this.optionItemHelpBlue) {
          this._externalLinksService.openHelpBlue();
        } else if (option == this.optionItemHelpOrange) {
          this._externalLinksService.openHelpOrange();
        }
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.componentDestroyed$ = null;
  }

  public startNewVersion() {
    this.updateService.forceUpdate();
  }

  public showWhatIsNew() {
    alert(this.newInfo);
  }

  public toggleContext() {
    if (this.audioTagService.audioContext.state === 'running') {
      this.audioTagService.audioContext.suspend();
    } else {
      this.audioTagService.audioContext.resume();
    }
  }

  openOptionsMenu(showPopup: boolean) {
    if (showPopup) {
      this._popupService.showPopup$.next({
        connector: this.optionsButton,
        componentType: OptionsMenuComponent,
        animationDuration: this.POPUP_ANIMATION_DURATION,
        popupDirection: PopupDirection.DOWN,
        showArrow: true,
        popupPosition: PopupPosition.BOTTOM_CENTER,
      });
    } else {
      this._popupService.hidePopup$.next(this.optionsButton);
    }
  }

  openHelpMenu(showPopup: boolean) {
    if (showPopup) {
      this._popupService.showPopup$.next({
        connector: this.helpButton,
        componentType: OptionsMenuComponent,
        animationDuration: this.POPUP_ANIMATION_DURATION,
        popupDirection: PopupDirection.DOWN,
        showArrow: true,
        popupPosition: PopupPosition.BOTTOM_CENTER,
      });
    } else {
      this._popupService.hidePopup$.next(this.helpButton);
    }
  }
}
