import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface LanguageItem {
  name: string;
  selected: boolean;
  shortCode: string;
}

@Component({
  selector: 'tun-language-popup',
  templateUrl: './language-popup.component.html',
  styleUrls: ['./language-popup.component.scss']
})
export class LanguagePopupComponent implements OnInit, OnDestroy {

  public static widthForPopup = '22vw';

  private langGerman:LanguageItem = {name: "Deutsch", selected: false, shortCode: "de"};
  private languageEnglish:LanguageItem = {name: "English", selected: false, shortCode: "en"};
  private languageFrench:LanguageItem = {name: "Français", selected: false, shortCode: "fr"};
  private languageDutch:LanguageItem = {name: "Nederlands", selected: false, shortCode: "nl"};

  public languageItems = [
    this.langGerman,
    this.languageEnglish,
    this.languageFrench,
    this.languageDutch
  ]

  constructor(
    public dialogRef: MatDialogRef<LanguagePopupComponent>,
    public zoneConfigurationService: ZoneConfigurationService
  ) {

  }

  ngOnInit() {
    this.zoneConfigurationService.language$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (lang) => {
        this.languageItems.forEach((languageItem)=>{
          languageItem.selected = languageItem.shortCode == lang; //only select the item that is configured
        })
      }
    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public onCancelClick() {
    this.dialogRef.close();
  }

  public onLanguageClick(languageItem: LanguageItem){
    this.zoneConfigurationService.changeLanguage(languageItem.shortCode) ;
  }

}
