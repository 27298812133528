import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { NavigationItem } from '@components/app-components/menu/navigation-menu/model/NavigationItem';
import { OrangeNavigationItem } from '../model/OrangeNavigationItem';
import { TunifyColor } from '@model/enums/tunifyColor.enum';

@Component({
  selector: 'tun-orange-sub-menu',
  templateUrl: './orange-sub-menu.component.html',
  styleUrls: ['./orange-sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrangeSubMenuComponent {

  // === Props === //
  @Input() heightPerItem = 0;
  @Input() widthForMenuPanel = 0;

  @Input() set navigationItem({ title, items }: OrangeNavigationItem) {
    this.title = title;
    this.navigationItems = items;
  }

  // === State === //
  tunifyColor = TunifyColor.ORANGE;
  navigationItems: NavigationItem[] = [];
  title = '';

  // === Emitters === //
  @Output() back = new EventEmitter<void>();
  @Output() selectItem = new EventEmitter<NavigationItem>();
  @Output() createItemWithTitle = new EventEmitter<string>();

  goBack() {
    this.back.emit();
  }

  onItemClicked(item: NavigationItem) {
    this.selectItem.emit(item);
  }

  onCreateItemWithTitle(title: string) {
    this.createItemWithTitle.emit(title);
  }
}
