import { Component, ElementRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { PopupContent } from '@components/popups/popup/interfaces';

@Component({
  selector: 'tun-new-navigation-item-popup',
  templateUrl: './new-navigation-item-popup.component.html',
  styleUrls: ['./new-navigation-item-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewNavigationItemPopupComponent implements PopupContent {

  // === Props === //
  @Input() public connectedElementRef: ElementRef;

  constructor(public elementRef: ElementRef) { }
}
