<!-- Title and Artist-->
<div *ngIf="searchEnabled; else noSearch" class="title-artist">
    
    <tun-search-button
      (click)="onSearchAction(TrackAction.SEARCH_ON_TITLE)"
      [text]="title"
    ></tun-search-button>

    <tun-search-button
      (click)="onSearchAction(TrackAction.SEARCH_ON_GROUP)"
      [text]="artist"
    ></tun-search-button>
   
</div>

<ng-template #noSearch>
  <div class="title-artist-no-search">
    <div class="title-field">
      <span tunScrollOverflow>{{ title }}</span>
    </div>
   
    <div class="title-field">
      <span tunScrollOverflow>{{ artist }}</span>
    </div>
  </div>
</ng-template>

<!-- Other properties -->
<div class="details">
  <div class="detail-field" *ngFor="let detail of songDetails; let i = index">
    <span
      class="label"
      [class.active]="detail.property === audioFileProperty"
      (click)="onClickLabel(detail, i)"
    >
      <tun-eye-icon></tun-eye-icon>
      {{ detail.label }}
    </span>
    <tun-search-button
      (click)="onClickSearch(detail, i)"
      *ngIf="detail.searchable === true; else noButton"
      [text]="detail.value"
    ></tun-search-button>
    <ng-template #noButton>
      <span class="duration-field">
        {{ detail.value }}
      </span>
    </ng-template>
  </div>
</div>
<!-- Navigation Bar (if applicable) -->
<div class="navigation" *ngIf="showNavigation">
  <tun-popup-navigation 
    (navigate)="onNavigate($event)"
    [showDelete]="showDelete"
    [showPrelisten]="showPrelisten">
  </tun-popup-navigation>
</div>
