import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupService } from '@service/popup.service';
import { fromEvent, merge, of, Subject } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  mapTo,
  scan,
  switchMap,
  takeUntil,
  takeWhile,
  take
} from 'rxjs/operators';
import { AccessRightsChecker, hasAccessViolations } from './access-checks/check-access-functions';
import { SubscriptionsService } from '../../services/data/subscriptions.service';


// TODO: check every use of this directive
/**
 * Apply this directive to an element to toggle a popup when it is clicked
 */
@Directive({
  selector: '[tunPopupOnClick]'
})
export class PopupOnClickDirective implements AfterViewInit, OnDestroy, AccessRightsChecker {
  // === Props === //

  //check access to general tabs
  @Input() checkAccessToGreen: boolean;
  @Input() checkAccessToBlue: boolean;
  @Input() checkAccessToOrange: boolean;

  //check access to specific functions
  @Input() checkAccessToSearch: boolean;
  @Input() checkAccessToCustomPlaylists: boolean;
  @Input() checkAccessToAddToQueue: boolean;
  @Input() checkAccessToStartTrack: boolean;


  /** Indicates whether the popup should close after the cursor hovers away from the on click element */
  @Input() closePopupAfterMouseLeave = true;
  @Input() closeOnPopupClick = true;
  @Input() closeOnOutsideElementClick = true;
  /** Use this prop to include a specified delay if the on click interferes with the outside click listener of another popup directive */
  @Input() clickDelay = 0;

  // === Events and emitters === //
  @Output() public togglepopup = new EventEmitter<boolean>();

  // === Observables === //
  private _destroy$ = new Subject();

  constructor(
    private _elementRef: ElementRef,
    private _popupService: PopupService,
    private _matDialog: MatDialog,
    private subscriptionsService: SubscriptionsService
  ) { }


  ngAfterViewInit() {

    //when there are accessRights defined, we only want to handle clicks when the zone has the correct access right
    const clickWithAccess$ = fromEvent(this._elementRef.nativeElement, 'click')
      .pipe(
        filter(event => !hasAccessViolations(this, this.subscriptionsService.accessRights)),
        takeUntil(this._destroy$)
      )


    const click$ = this.clickDelay
      ? clickWithAccess$.pipe(
        delay(this.clickDelay),
        takeUntil(this._destroy$)
      )
      : clickWithAccess$;
    const mouseEnter$ = fromEvent(this._elementRef.nativeElement, 'mouseenter').pipe(takeUntil(this._destroy$));
    const mouseLeave$ = fromEvent(this._elementRef.nativeElement, 'mouseleave').pipe(takeUntil(this._destroy$));

    const state$ = merge(
      click$.pipe(
        mapTo((value: boolean) => !value),
        takeUntil(this._destroy$)
      ),
      this._popupService.currentInstance$
        .pipe(
          filter(instance => !!instance),
          filter(
            ({ connectedElementRef }) =>
              connectedElementRef &&
              connectedElementRef.nativeElement === this._elementRef.nativeElement
          ),
          switchMap(({ elementRef: { nativeElement: popup } }) => {
            const mergedMouseEnter$ =
              merge(
                mouseEnter$,
                fromEvent(popup, 'mouseenter').pipe(takeUntil(this._destroy$)
              ).pipe(takeUntil(this._destroy$))
              );
            const mergedMouseLeave$ =
              merge(
                mouseLeave$,
                fromEvent(popup, 'mouseleave').pipe(takeUntil(this._destroy$)
              ).pipe(takeUntil(this._destroy$))
            );
            const windowClick$ = fromEvent(window, 'mousedown').pipe(
              filter(({ target }) => {
                let current = target as Node;
                while (current) {
                  if (
                    current === this._elementRef.nativeElement ||
                    current === popup ||
                    this._matDialog.openDialogs.length > 0 || //don't close if a modal window is open
                    ((<Element>current).classList &&
                      (<Element>current).classList.contains('popup-button')) //popup-button class is used for buttons inside the popup -> don't close when clicking inside the popup
                  ) {
                    return false;
                  }
                  current = current.parentNode;
                }
                return true;
              }),
              takeUntil(this._destroy$)
            );

            const mergedClick$ = merge(
              click$,
              fromEvent(popup, 'click').pipe(takeUntil(this._destroy$))
              ).pipe(takeUntil(this._destroy$));

            return merge(
              mergedMouseLeave$.pipe(
                takeWhile(() => this.closePopupAfterMouseLeave),
                switchMap((event: Event) =>
                  of(event).pipe(
                    delay(1000),
                    takeUntil(merge(mergedMouseEnter$, mergedClick$, this._destroy$))
                  )
                ),
                takeUntil(this._destroy$)
              ),
              this.closeOnPopupClick ? mergedClick$ : click$,
              windowClick$.pipe(takeWhile(() => this.closeOnOutsideElementClick), takeUntil(this._destroy$)),
              this._popupService.hidePopup$.pipe(
                filter(
                  ({ nativeElement: connector = {} }) =>
                    this._elementRef.nativeElement === connector
                ),
                takeUntil(this._destroy$)
              )
            ).pipe(
              mapTo(() => false),
              take(1), //we only want to go to not showing state once for each popup
              takeUntil(this._destroy$)
              );
          }),
          takeUntil(this._destroy$)
        )
    ).pipe(
      scan((previousState, changeState) => changeState(previousState), false),
      takeUntil(this._destroy$)
    );

    state$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this._destroy$)
      )
      .subscribe(
        showPopup => this.performTogglePopup(showPopup)
        );
  }

  protected performTogglePopup(showPopup) {
    this.togglepopup.emit(showPopup)
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$ = null;

    //when our component is cleaned up -> emit close popup
    this.performTogglePopup(false);
    this.togglepopup.complete();
  }
}
