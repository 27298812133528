<div
  #checkbox
  [ngClass]="['checkbox-container', checkboxColor, colorAdjustment]"
>
  <mat-checkbox
    [disableRipple]="false"
    [checked]="isChecked"
    (change)="toggleChecked()"
  >
    <div class="label-container" [class.active]="isChecked">
      <tun-shuffle-icon *ngIf="isShuffleCheckbox"></tun-shuffle-icon>
      <div #label class="label" [class.shuffle-label]="isShuffleCheckbox" tunScrollOverflow>
        {{ title }}
      </div>
    </div>
  </mat-checkbox>
</div>
