import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlaylistService } from '@service/playlist.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Song } from '@model/song';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { AudioFileProperty } from '@model/enums/audioFileProperty';
import { AsyncStatus } from '@service/vo/asyncStatus';

@Component({
  selector: 'tun-blocked-songs',
  templateUrl: './blocked-songs-popup.component.html',
  styleUrls: ['./blocked-songs-popup.component.scss']
})
export class BlockedSongsPopupComponent implements OnDestroy {

  // TODO: pass this variable to the Dialog service (to the width option)
  public static widthForPopup = '50vw';

  // === Getters === //
  get songs$(): Observable<Song[]> {
    return this._playlistService.banlist$
      .pipe(
        map(({ audioFiles }) => audioFiles.map((audioFile) => audioFile as Song))
      );
  }

  get audioFileProperty$(): Observable<AudioFileProperty> {
    return this._zoneConfigService.audioFileProperty$;
  }

  // === State === //
  loadingSongs: number[] = [];

  constructor(private _playlistService: PlaylistService,
    private _zoneConfigService: ZoneConfigurationService) { }

  
  onSongUnblocked(song: Song) {
    this.loadingSongs.push(song.id);
    this._playlistService.deleteAudioFileInPlaylist(this._playlistService.banlist, [song])
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((status) => {
        if (status === AsyncStatus.COMPLETED) {
          this.loadingSongs = this.loadingSongs.filter((id) => id !== song.id);
        }
      });
  }

  isSongLoading(song: Song): boolean {
    return this.loadingSongs.indexOf(song.id) >= 0;
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }
}
