import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { SongSortMode } from '@model/enums/songSortMode';
import { MusicCollection } from '@model/musicCollection';
import { Playlist } from '@model/playlist';
import { SearchType } from '@service/api/search-api.service';
import { AppService } from '@service/app.service';
import { OrangeStateService } from '@service/orange-state.service';
import { SearchData, SearchService, translatedDescriptionForSearchData } from '@service/search.service';
import { combineLatest, merge, Observable, Subject, BehaviorSubject } from 'rxjs';
import { filter, map, pluck, scan, mapTo, delay, switchMap, takeUntil } from 'rxjs/operators';
import { TrackEvent, TrackAction } from '@model/events/trackEvent';
import {
  TrackIndexEvent,
  TrackIndexAction
} from '@model/events/trackIndexEvent';
import { PlaylistService } from '@service/playlist.service';
import { TracksEvent, TracksAction } from '@model/events/tracksEvent';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { ChangeableParameter } from '@model/changeableParameter';
import { Context } from '@model/context';
import { MusicCollectionService } from '@service/music-collection.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@service/loggers/logger.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Util } from '../../../utils/util';
import { TrackManipulationControllerService } from '../../../services/audio/track-manipulation-controller.service';

interface OrangeViewState {
  widthForMenuPanel: number;
  widthForContentPanel: number;
  heightPerItem: number;
  heightForHeader: number;
  selectedMusicCollection: MusicCollection;
  searchResult: AudioFile[];
  showSearch: boolean;
  searchTerm: string;
  playlistPanels: Playlist[];
  editingPlaylist: Playlist;
  isTweaking: boolean;
  tweakPanelAnimating: boolean;
}

const initialState: OrangeViewState = {
  widthForMenuPanel: 0,
  widthForContentPanel: 0,
  heightPerItem: 0,
  heightForHeader: 0,
  playlistPanels: [],
  editingPlaylist: null,
  selectedMusicCollection: null,
  searchResult: null,
  showSearch: false,
  searchTerm: '',
  isTweaking: false,
  tweakPanelAnimating: false
};

@Component({
  selector: 'tun-orange-view',
  templateUrl: './orange-view.component.html',
  styleUrls: ['./orange-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideIn', [
      state('void', style({ left: 0 })),
      state('*', style({ left: '100%' })),
      transition(':enter, :leave', animate('300ms ease-out'))
    ]),
    trigger('slide', [
      state('left', style({ left: 0, backgroundColor: '#1a2021' })),
      state('right', style({ left: '100%', backgroundColor: '#773d1d' })),
      transition('left <=> right', animate('300ms ease-out'))
    ])
  ]
})
export class OrangeViewComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly LOGGER_CLASSNAME = "OrangeViewComponent";

  // === ViewChildren === //
  @ViewChild('panelContainer') panelContainer: ElementRef;

  // === Observables === //
  state$: Observable<OrangeViewState>;
  private isTweakingSubject = new BehaviorSubject<boolean>(false);

  get heightForContentPanel() {
    return (
      (this.panelContainer && this.panelContainer.nativeElement.offsetHeight) ||
      0
    );
  }


  /*
  get isTweaking() {
    return this._tweaking;
  }
  */


  //we can't use an observable with the async pipe in the html view -> this does not trigger an update in the view
  get musicCollectionLoadingTracks() {
    return this._orangeStateService.loadingTracksForMusicCollection;
  }
  get musicCollectionLoadingTracksError$() {
    return this._orangeStateService.loadingTracksForMusicCollectionError$;
  }
  get musicCollectionTracks$() {
    return this._orangeStateService.tracksForMusicCollection$;
  }

  get searching(){
    return this._searchService.searching;
  }

  get searchError$(){
    return this._searchService.searchError$;
  }

  get royaltyFree$(){
    return this._zoneConfigurationService.royaltyFree$;
  }

  // === State === //
  //private _tweaking: boolean;
  songSortMode: SongSortMode;

  constructor(
    private _appService: AppService,
    private _orangeStateService: OrangeStateService,
    private _searchService: SearchService,
    private _playlistService: PlaylistService,
    private _musicManipulationService: MusicManipulationService,
    private trackManipulationControllerService: TrackManipulationControllerService,
    private _musicCollectionService: MusicCollectionService,
    private _translateService: TranslateService,
    private _loggerService: LoggerService,
    private _changeDetectorRef:ChangeDetectorRef,
    private _zoneConfigurationService: ZoneConfigurationService
  ) {}

  ngOnInit() {

    const heightPerItem$ = this._appService.heightPerItem$.pipe(
      map(heightPerItem => ({ heightPerItem }))
    );

    const widthForContentPanel$ = this._appService.widthForContentPanel$.pipe(
      map(widthForContentPanel => ({widthForContentPanel}))
    );

    const widthForMenuPanel$ = this._appService.widthForMenuPanel$.pipe(
      map(widthForMenuPanel => ({ widthForMenuPanel }))
    );

    const selectedMusicCollection$ = this._orangeStateService.selectedMusicCollection$.pipe(
      map(selectedMusicCollection => ({ selectedMusicCollection }))
    );

    const showSearch$ = this._orangeStateService.showSearch$.pipe(
      map(showSearch => ({ showSearch }))
    );

    const heightForHeader$ = this._appService.heightForHeader$.pipe(
      map(heightForHeader => ({ heightForHeader }))
    );

    const lastSearch$ = this._searchService.lastSearch$.pipe(
      filter(Boolean),
      map((searchData: SearchData) => ({
        searchTerm: translatedDescriptionForSearchData(this._translateService, searchData)
      }))
    );

    const editingPlaylist$ = this._orangeStateService.editingPlaylist$.pipe(
      map(editingPlaylist => ({ editingPlaylist }))
    );

    const playlistPanels$ = combineLatest(
      this._orangeStateService.selectedPlaylist$,
      editingPlaylist$.pipe(pluck('editingPlaylist'))
    ).pipe(
      map(([sp, ep]) => (sp === ep && ep !== null ? [ep] : [sp, ep])),
      map(playlists => playlists.filter(Boolean)),
      map(playlistPanels => ({ playlistPanels }))
    );

    const searchResult$ = this._searchService.searchResult$.pipe(
      map(searchResult => ({ searchResult }))
    );

    const isTweaking$ = this.isTweakingSubject.pipe(
      map(isTweaking => ({ isTweaking, tweakPanelAnimating: true }))
    );

    const tweakPanelAnimating$ = isTweaking$.pipe(
      delay(300),
      mapTo({ tweakPanelAnimating: false })
    );

    // empty commands to trigger change detection
    const playlistLoaded$ = merge(
      this._orangeStateService.selectedPlaylist$,
      this._orangeStateService.editingPlaylist$
    ).pipe(
      filter(Boolean),
      map(pl => pl as MusicCollection),
      switchMap(pl => pl.detailsloadingDone.pipe(filter(done => done))),
      mapTo({})
    );

    //trigger change detection
    const tweakInfoLoadingChanged$ = this._orangeStateService.selectedMusicCollection$
    .pipe(
      filter(c => c instanceof Context),
      map(mc => mc as Context),
      switchMap(c => c.tweakInfoLoading$),
      mapTo({})
    );

    const audioFilesChanged$ = merge(
      this._orangeStateService.selectedPlaylist$,
      this._orangeStateService.editingPlaylist$
    )
    .pipe(
      filter(ep => ep !== null),
      switchMap(ep => ep.audioFilesHaveChanged),
      mapTo({})
    )

    this.state$ = merge(
      heightPerItem$,
      widthForContentPanel$,
      widthForMenuPanel$,
      selectedMusicCollection$,
      showSearch$,
      heightForHeader$,
      lastSearch$,
      playlistPanels$,
      editingPlaylist$,
      searchResult$,
      isTweaking$,
      tweakPanelAnimating$,
      playlistLoaded$,
      audioFilesChanged$,
      tweakInfoLoadingChanged$
    ).pipe(
      scan(
        (previousState, command) => ({ ...previousState, ...command }),
        initialState
      )
    );
  }

  ngAfterViewInit(){
    this._orangeStateService.loadingTracksForMusicCollection$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(()=> {
      if (this._orangeStateService.selectedMusicCollection){
        this._changeDetectorRef.detectChanges()
      }

    });

    this._orangeStateService.selectedMusicCollection$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(()=> {
      if (this._orangeStateService.selectedMusicCollection == null || !(this._orangeStateService.selectedMusicCollection instanceof Context)){
        this.closeTweakPanel();
      }

    });
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this._orangeStateService.editingPlaylist = null;
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  // === Event handlers === //
  onSongSortModeChanged(sortMode: SongSortMode) {
    this.songSortMode = sortMode;
  }

  toggleEditMode(playlist: Playlist = null) {
    this._orangeStateService.editingPlaylist = playlist;
  }

  closePlaylist() {
    this._orangeStateService.selectedPlaylist = null;
  }

  closeEditPlaylist() {
    this._orangeStateService.editingPlaylist = null;
  }

  closeSearchResults() {
    this._searchService.closeSearch();
    this._orangeStateService.showSearch = false;
  }

  toggleTweakPanel() {
    this.isTweakingSubject.next(!this.isTweakingSubject.value);
  }

  private closeTweakPanel(){
    if (this.isTweakingSubject.value){
      this.isTweakingSubject.next(false);
    }
  }

  //music collection handlers

  onRefreshMusicCollectionResult() {
    this._orangeStateService.loadTracksForCurrentMusicCollection();
  }

  onResetParameters() {
    if (
      this._orangeStateService.selectedMusicCollection &&
      this._orangeStateService.selectedMusicCollection instanceof Context
    ) {
      this._orangeStateService.selectedMusicCollection.resetParameters();
      this._orangeStateService.loadTracksForCurrentMusicCollection();
      this._musicCollectionService.loadTweakInfo(
        this._orangeStateService.selectedMusicCollection
      );
    }
  }

  onParametersChange(changeableParameters: ChangeableParameter[]) {
    if (
      this._orangeStateService.selectedMusicCollection &&
      this._orangeStateService.selectedMusicCollection instanceof Context
    ) {
      this._orangeStateService.selectedMusicCollection.changeableParameter = changeableParameters;
      this._orangeStateService.loadTracksForCurrentMusicCollection();
      this._musicCollectionService.loadTweakInfo(
        this._orangeStateService.selectedMusicCollection
      );
    }
  }

  closeMusicCollectionPanel() {
    this._orangeStateService.clearMusicCollectionSelection();
  }

  onTrackIndex({ index, track, action }: TrackIndexEvent) {
    if (action === TrackIndexAction.ADD_AT_INDEX) {
      this._playlistService.addAudioFileToPlaylist(
        this._orangeStateService.editingPlaylist,
        [track],
        index
      );
    } else if (action === TrackIndexAction.MOVE_TO_INDEX) {
      this._playlistService.moveAudioFileInPlaylist(
        this._orangeStateService.editingPlaylist,
        [track],
        index
      );
    }
  }

  //special handler for playlists, to know on what playlist we need to apply the action on.
  public onPlaylistTrackEvent(playlist: Playlist, trackEvent: TrackEvent){
    if (trackEvent.action === TrackAction.REMOVE) {
      this._playlistService.deleteAudioFileInPlaylist(playlist, [trackEvent.track]);
    }else{
      this.onTrackEvent(trackEvent);
    }
  }

  public onTrackEvent(trackEvent: TrackEvent) {
    if (trackEvent.action === TrackAction.PLAY) {
      this.trackManipulationControllerService.playAudioFile(trackEvent.track);
    } else if (trackEvent.action === TrackAction.ADD_TO_QUEUE) {
      this._musicManipulationService.addAudioFilesToQueue([trackEvent.track]);
    }else if (trackEvent.action === TrackAction.ADD_TO_EDITING_PLAYLIST) {
      this._playlistService.addAudioFileToPlaylist(this._orangeStateService.editingPlaylist, [trackEvent.track]);
    }else{
      this._loggerService.error(this.LOGGER_CLASSNAME, "onTrackEvent", "no handler for track action: " + trackEvent.action);
    }
  }

  public onTracksEvent(tracksEvent: TracksEvent) {
    if (tracksEvent.action === TracksAction.ADD_TO_QUEUE) {
      this._musicManipulationService.addAudioFilesToQueue(tracksEvent.tracks);
    }
  }
}
