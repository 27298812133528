import { Component, Input } from '@angular/core';

@Component({
  selector: 'tun-shuffle-icon',
  templateUrl: './shuffle-icon.component.html',
  styleUrls: ['./shuffle-icon.component.scss']
})
export class ShuffleIconComponent {

  @Input() color: string;

  constructor() { }

}
