<ng-container *ngIf="state$ | async as state">
  <div tunThemed class="header-title" [style.height.px]="heigthForTitle$ | async">
    <ng-content select=".left-header-buttons"></ng-content>
    <span class="title-text" tunScrollOverflow>
      {{ title }}
    </span>
    <tun-music-play-animation
      *ngIf="isCurrentMusicCollection && showPlayAnimation"
      [playing]="isPlaying"
      [musicPlayAnimationColors]="state.musicPlayAnimationColors"
    ></tun-music-play-animation>
  </div>
  <div
    tunThemed
    class="header-controls"
    [style.height.px]="heigthForTitle$ | async"
  >
    <!-- Custom controls appear here -->
    <ng-content
      select=".right-header-buttons"
      heightPerItem="heigthForTitle$ | async"
    ></ng-content>

    <tun-icon-button
      *ngIf="showClose"
      [height]="heigthForTitle$ | async"
      (click)="onCloseClick()"
    >
      <tun-close-icon></tun-close-icon>
    </tun-icon-button>
  </div>
</ng-container>
