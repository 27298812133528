<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M13.401,12h-1.03c-0.753,0-1.655-0.305-2.161-0.942c-0.358,0.691-0.697,1.327-1.01,1.839
	C10.028,13.598,11.142,14,12.37,14h1.03v2.5l3.362-3.5l-3.362-3.5V12z"
  />
  <path
    fill="currentColor"
    d="M5.583,6.94C5.94,6.254,6.279,5.628,6.599,5.113C5.757,4.406,4.633,4,3.406,4H2v2h1.406
	C4.222,6,5.06,6.315,5.583,6.94z"
  />
  <path
    fill="currentColor"
    d="M12.37,6h1.03v2.5L16.763,5l-3.362-3.5V4h-1.03c-1.684,0-3.176,0.761-3.993,2.036
	c-0.409,0.638-2.3,4.312-2.628,4.839C5.315,11.569,4.418,12,3.406,12H2v2h1.406c1.721,0,3.23-0.771,4.039-2.065
	c0.373-0.597,2.257-4.26,2.615-4.819C10.502,6.428,11.387,6,12.37,6z"
  />
</svg>
