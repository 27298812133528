import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { PopupService } from '@service/popup.service';
import { fromEvent, merge, of, Subject } from 'rxjs';
import { delay, filter, mapTo, switchMap, takeUntil, take } from 'rxjs/operators';
import { PopupContent } from './popup/interfaces';

/**
 * Apply this directive to an element to show a popup on hover
 */
@Directive({
  selector: '[tunPopupOnHover]',
})
export class PopupOnHoverDirective implements AfterViewInit, OnDestroy {
  // === Props === //
  /** Indicates the time it takes for a popup to show and hide after a mouseenter or mouseleave event (in milliseconds) */
  @Input() public hoverDelay = 1000;

  // === Emitters === //
  @Output() public togglepopup = new EventEmitter<boolean>();

  // === Observables === //
  protected _destroy$ = new Subject();

  constructor(
    protected _elementRef: ElementRef,
    protected _popupService: PopupService
  ) {}

  ngAfterViewInit() {
    const click$ = fromEvent(this._elementRef.nativeElement, 'click').pipe(takeUntil(this._destroy$));
    const mouseEnter$ = fromEvent(this._elementRef.nativeElement, 'mouseenter').pipe(takeUntil(this._destroy$));
    const mouseLeave$ = fromEvent(this._elementRef.nativeElement, 'mouseleave').pipe(takeUntil(this._destroy$));

    const state$ = merge(
      mouseEnter$.pipe(
        switchMap((event: Event) =>
          of(event).pipe(
            delay(this.hoverDelay),
            mapTo(true),
            takeUntil(merge(mouseLeave$, click$, this._destroy$))
          )
        ),
        takeUntil(this._destroy$)
      ),
      this._popupService.currentInstance$.pipe(
        filter((instance: PopupContent) => !!instance),
        filter(
          ({ connectedElementRef }) =>
            connectedElementRef &&
            connectedElementRef.nativeElement === this._elementRef.nativeElement
        ),
        switchMap(({ elementRef: { nativeElement: popup = {} } }) => {
          const mergedMouseEnter$ = merge(
            mouseEnter$,
            fromEvent(popup, 'mouseenter').pipe(takeUntil(this._destroy$))
          );
          const mergedMouseLeave$ = merge(
            mouseLeave$,
            fromEvent(popup, 'mouseleave').pipe(takeUntil(this._destroy$))
          );
          const windowClick$ = fromEvent(window, 'mousedown').pipe(
            filter(({ target }) => {
              let current = target as Node;
              while (current) {
                if (
                  current === this._elementRef.nativeElement ||
                  current === popup
                ) {
                  return false;
                }
                current = current.parentNode;
              }
              return true;
            }),
            takeUntil(this._destroy$)
          );

          const mergedClick$ = merge(click$, fromEvent(popup, 'click').pipe(takeUntil(this._destroy$)));
          return merge(
            mergedMouseLeave$.pipe(
              switchMap((event: Event) =>
                of(event).pipe(
                  delay(this.hoverDelay),
                  takeUntil(merge(mergedMouseEnter$, mergedClick$, this._destroy$))
                )
              )
            ),
            mergedClick$,
            windowClick$
          ).pipe(
            mapTo(false),
            takeUntil(this._destroy$)
          );
        })
      )
    );

    state$
      .pipe(takeUntil(this._destroy$))
      .subscribe(showPopup => this.performTogglePopup(showPopup));
  }

  protected performTogglePopup(showPopup){
    this.togglepopup.emit(showPopup)
  }

  ngOnDestroy() {
    //when our component is cleaned up -> emit close popup
    this.performTogglePopup(false);
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$ = null;
  }
}
