import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tun-chevron-left-icon',
  templateUrl: './chevron-left.component.html',
  styleUrls: ['./chevron-left.component.scss'],
})
export class ChevronLeftComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
