<div class="confirmPopup">


  <div mat-dialog-content class="content-container section">

    <!-- header -->
    <tun-popup-header
      (close)="onCancelClick()"
      [isInMatDialog]="'true'">
        {{ 'settings.option.language' | translate }}
    </tun-popup-header>


      <button
        class="option"
        *ngFor="let languageItem of languageItems"
        (click)="onLanguageClick(languageItem)"
      >
        <tun-check-icon
          [style.visibility]="languageItem.selected ? 'visible' : 'hidden'"
        ></tun-check-icon>
        {{ languageItem.name }}
      </button>
    </div>

</div>
