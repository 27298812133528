<div class="wrapper">
  <div
    class="container"
    [class.no-navigation]="!showNavigation"
    [style.padding-bottom.rem]="isSafari ? 1.5 : 0"
  >
    <div class="content">
      <!-- Header and back + close button -->
      <div>
        <tun-popup-header
          [showBackButton]="navigationIndex === 3 || navigationIndex === 1"
          (back)="onNavigate(-1)"
          (close)="onCloseClick()"
        >
          {{ header }}
        </tun-popup-header>
      </div>
      <!-- Content tabs -->
      <!-- Song Info -->
      <div
        class="popup-tab"
        *ngIf="
          navigationIndex !== 3 &&
          navigationIndex !== 1 &&
          navigationIndex !== 4
        "
        @slideInLeft
        [@.disabled]="disableAnimation"
      >
        <tun-song-details
          [song]="song"
          [audioFileProperty]="currentAudioFileProperty"
          [showNavigation]="
            showNavigation &&
            navigationIndex !== 3 &&
            navigationIndex !== 1 &&
            navigationIndex !== 4
          "
          [showDelete]="showDelete"
          (propertyselect)="onPropertySelect($event)"
          (navigate)="onNavigate($event)"
          (trackEvent)="onTrackEvent($event)"
        ></tun-song-details>
      </div>

      <!-- Add to list -->
      <div class="popup-tab" *ngIf="navigationIndex === 3" @slideInRight>
        <tun-add-song-to-list
          [status]="status$ | async"
          (addtolist)="onAddToList($event)"
          [playlists]="playlists"
          [favorites]="favorites"
        ></tun-add-song-to-list>
      </div>

      <!-- Prelisten -->
      <div class="popup-tab" *ngIf="navigationIndex === 1" @slideInRight>
        <tun-song-preview
          [track]="song"
        >
        </tun-song-preview>
      </div>

      <div
        class="popup-tab addToNewList"
        *ngIf="navigationIndex === 4"
        @fadeInOut
      >
      </div>
    </div>

    <!-- <div class="addToNewList" *ngIf="showAddToNewList" @slideInRight>
      Add to new list
    </div> -->
  </div>
</div>
