<tun-panel-header
  tunThemed
  [isCurrentMusicCollection]="isCurrentMusicCollection"
  [title]="title"
	 [showClose]="showClose"
  (close)="onCloseClick()"
>
  <div class="left-header-buttons">
    <tun-icon-button
      tunTooltipOnHover
      [tooltipText]="'tooltip.trackList.addAllToQueue'"
      [checkAccessToAddToQueue]="true"
      [height]="heigthForTitle$ | async"
      (clickWithAccess)="onAddToQueueClick()"
    >
      <tun-add-to-queue-icon></tun-add-to-queue-icon>
    </tun-icon-button>
  </div>
  <div tunThemed class="right-header-buttons">
    <div>
      <!-- sort button-->
      <tun-icon-button
        tunTooltipOnHover
        [tooltipText]="'tooltip.trackList.sort'"
        tunSongSortOnClick
        [height]="heigthForTitle$ | async"
        (songSortModeChange)="onSongSortModeChange($event)"
      >
        <tun-sort-icon></tun-sort-icon>
      </tun-icon-button>
      <tun-icon-button
        tunTooltipOnHover
        [tooltipText]="'tooltip.trackList.addAllToCustomList'"
        [tooltipEnabled]="!showingAddToListPopup"
        [height]="heigthForTitle$ | async"
        #addToListButton
        tunPopupOnClick
        [closeOnPopupClick]="false"
        [closeOnOutsideElementClick]="true"
        (togglepopup)="onToggleAddToListPopup($event)"
      >
        <tun-add-to-list-icon></tun-add-to-list-icon>
      </tun-icon-button>
    </div>
  </div>
</tun-panel-header>

<ng-container [ngSwitch]="playlistState">
  <tun-song-grid
    *ngSwitchCase="PlaylistState.tracks"
    [playlist]="audioFiles"
    [showAddArrow]="canAddSongs"
    [songSortMode]="songSortMode"
    [showPopupDelete]="showPopupDelete"
    (trackEvent)="onTrackEvent($event)"
    [maxScrollbarHeight]="
      (availableHeightForPlayer$ | async) -
      (heightPerItem$ | async) -
      (heightForHeader$ | async) -
      (heightForTopBar$ | async) * 2
    "
  ></tun-song-grid>

  <ng-container *ngSwitchCase="PlaylistState.loading">
    <tun-loader></tun-loader>
  </ng-container>

  <div  *ngSwitchCase="PlaylistState.error"
        class="error-feedback-container">
    <p translate>
      general.loadError
    </p>
    <button (click)="retryClick()" class="retryButton">
      <p translate>
        general.tryAgain
      </p>
    </button>
  </div>

  <div  *ngSwitchCase="PlaylistState.empty"
    class="feedback-container">
    <p translate>
      orange.playlist.empty
    </p>
  </div>


</ng-container>


