import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'tun-bulk-action-feedback-bottom-sheet',
  templateUrl: './bulk-action-feedback-bottom-sheet.component.html',
  styleUrls: ['./bulk-action-feedback-bottom-sheet.component.scss']
})
export class BulkActionFeedbackBottomSheetComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<BulkActionFeedbackBottomSheetComponent>) { }

  ngOnInit() {
  }

  openLink(clickEvent: any){

  }

}
