import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { LoggerService } from '@service/loggers/logger.service';
import { RemotePlayStateService } from '@service/remote-play-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tun-remote-progress',
  templateUrl: './remote-progress.component.html',
  styleUrls: ['./remote-progress.component.scss']
})
export class RemoteProgressComponent implements OnInit, OnDestroy {

  @Output() seekToTime:EventEmitter<number> = new EventEmitter<number>();
  
  public timeProgressString: string = '0:00';
  public timeLeftString: string = '0:00';
  public progressBarPercent = 0;
  public progressBarPercentInverse = 100; //100 - current percent, we need this for binding of the 'right' property

  private currentTime = 0;
  private currentDuration = 0;

  private LOGGER_CLASSNAME = 'RemoteProgressComponent';

  @ViewChild('unselectedline') unselectedline:any;
  
  constructor(private loggerService: LoggerService,
              private ref: ChangeDetectorRef,
              private remotePlayStateService: RemotePlayStateService) { 
    ref.detach();
  }

  private progressSubscription: Subscription;
  private totalTimeSubscription: Subscription;
  ngOnInit() {
    this.progressSubscription = this.remotePlayStateService.progress$.subscribe(
      (progress) => {
        this.currentTime = progress;
        this.adjustToTime();
      }
    );
    this.totalTimeSubscription = this.remotePlayStateService.totalTime$.subscribe(
      (totalTime) => {
        this.currentDuration = totalTime;
        this.adjustToTime();
      }
    )
  }

  ngOnDestroy() {
    this.progressSubscription.unsubscribe();
    this.progressSubscription = null;
    this.totalTimeSubscription.unsubscribe();
    this.totalTimeSubscription = null;
  }


  adjustToTime() {
    if (this.currentTime != undefined && this.currentDuration != undefined){
      this.timeProgressString = this.formatSecondsToTimeString(this.currentTime);
      this.timeLeftString = this.formatSecondsToTimeString(this.currentDuration - this.currentTime);

      var progressPercent:number = 0;
      if (this.currentDuration > 0 && this.currentTime > 0){
          progressPercent = this.currentTime * 100 / this.currentDuration;
      }

      this.progressBarPercent = progressPercent;
      this.progressBarPercentInverse = 100 - this.progressBarPercent;

       // console.log('adjustToTime done: ' + this.timeProgressString + ' - ' + this.timeLeftString + ': ' + this.progressBarPercent + '%');

      this.ref.detectChanges();
    }
      

   
  }

  formatSecondsToTimeString(seconds:number):string{
      var hours:number = Math.floor(( seconds / 3600 ) % 24);
      var minutes:number = Math.floor(( seconds / 60 ) % 60);
      var seconds:number = Math.floor(seconds % 60);

      return (hours>0?hours+":":"") + minutes + ":" + (seconds<10?"0":"") + seconds;
  }


  onClick(event: MouseEvent) {
      var rect = this.unselectedline.nativeElement.getBoundingClientRect();
      var x = event.clientX - rect.left;

      var percentClicked = x / rect.width;

      var timeToSeek: number = this.currentDuration * percentClicked;

      this.loggerService.debug(this.LOGGER_CLASSNAME, "onProgressBarClick", "We should seek to " + timeToSeek);

      this.seekToTime.emit(timeToSeek);
      //this.musicPlayerService.seekToTime(this.currentDuration * percentClicked);
  }



}
