<div class="doublePopup">

  <!-- header -->
  <div
    class="header-container blackBottomBorder">

    <div class="header-left-side">
      <div class="label-container">
        {{'popup.doubleTrack.title' | translate}}
      </div>
    </div>
    <div class="header-right-side">
      <div
          class="icon-container"
          (click)="onCancelClick()">
          <tun-close-icon [color]="'#283031'"></tun-close-icon>
      </div>
    </div>
  </div>

  <div mat-dialog-content class="content-container">
    <div class="text-container">
      {{'popup.doubleTrack.trackInfo' | translate}} {{audioFileToConfirm.group}} - {{audioFileToConfirm.title}}
    </div>
    <div class="text-container">
      {{'popup.doubleTrack.confirmInfo' | translate}}
    </div>

    <div class="amount-container">
      <div *ngIf="amountOfAudioFilesToConfirm > 1" class="text-container">
        {{'popup.doubleTrack.amountDoubles' | translate}} {{amountOfAudioFilesToConfirm}}
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="buttons-container">
        <button class="{{amountOfAudioFilesToConfirm > 1?'':'hiddenButton'}}" (click)="onYesToAllClick()">{{'popup.doubleTrack.confirmButton.yesToAll' | translate}}</button>
        <button (click)="onYesClick()">{{'popup.doubleTrack.confirmButton.yes' | translate}}</button>
        <button (click)="onNoClick()">{{'popup.doubleTrack.confirmButton.no' | translate}}</button>
        <button class="{{amountOfAudioFilesToConfirm > 1?'':'hiddenButton'}}" (click)="onNoToAllClick()">{{'popup.doubleTrack.confirmButton.noToAll' | translate}}</button>
    </div>
    <div class="buttons-right-side">
        <button (click)="onCancelClick()" cdkFocusInitial>{{'general.cancel' | translate}}</button>
    </div>
  </div>

  <div class="text-container">

    <!-- if we want to use this component, we need to change the label color-->
    <tun-checkbox 
      [isChecked]="data.stopAsking" 
      (isCheckedEmitter)="toggleStopAsking()" 
      [title]="'popup.doubleTrack.stopAsking' | translate" 
      [checkBoxColor]="checkBoxColor">

    </tun-checkbox>

    <!--
    <mat-checkbox [checked]="data.stopAsking " (change)="toggleStopAsking()">
        Niet meer vragen
    </mat-checkbox>
    -->

  </div>

</div>

