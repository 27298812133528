<div #playerView (tunResize)="onResize($event)" class="wrapper">

  <div
    *ngIf="!useTunifyV4 && sizeCalculated"
    class="redirect-container playerBackground"
  >

    <div class="fade-borders">
      <div
        [style.height.px]="heightPerItem$ | async"
        [style.padding-top.px]="2 * (heightPerItem$ | async)"
      >
        <tun-tunify-icon></tun-tunify-icon>
      </div>

      <div class="spinner-container">
        <mat-spinner class="redirect-spinner"></mat-spinner>
      <span class="redirect-text">{{'general.redirecting' | translate}}</span>
      </div>
    </div>


  </div>


  <div fxFlex *ngIf="useTunifyV4 && sizeCalculated" fxLayout="column" class="flexBoxMinimumSizing">

    <tun-top-bar  id="topBar"
        class="flexBoxMinimumSizing appBarBackground blackBottomBorder"
        [style.height.px]="heightForTopBar"
        [heightForTopBar]="heightForTopBar"
        [selectedTunifyColor]="selectTunifyColor">
    </tun-top-bar>

    <!-- Original setup with an ngIf for every screen (orange / stream)-->
    <!--
    <tun-stream-view *ngIf="!showDjScreen" fxFlex class="flexBoxMinimumSizing"></tun-stream-view>
    <tun-orange-view *ngIf="showDjScreen" fxFlex class="flexBoxMinimumSizing" style="background-color: lightsalmon"></tun-orange-view>
    -->

    <!--
      It looks like angular creates and inserts the element before the other one is removed
      For a short period, both components will be in the dom. If we use flex layout, the components will be half screen high. That is why we need to place them on top of each other, not in one flex container.
    -->
    <div fxFlex fxLayout="row" class="flexBoxMinimumSizing">

      <div fxFlex class="flexBoxMinimumSizing">
        <div class="layerContainer">

            <tun-loading-view *ngIf="showLoading" class="layer"></tun-loading-view>
            <tun-stream-view *ngIf="!showLoading && !showDjScreen" class="layer"></tun-stream-view>
            <tun-orange-view *ngIf="!showLoading && showDjScreen" class="layer"></tun-orange-view>

        </div>
      </div>

      <tun-right-stream-panel [style.width.px]="widthForContentPanel"
                              class="flexBoxMinimumSizing">
      </tun-right-stream-panel>

    </div>

    <tun-bottom-bar id="bottomBar" class="flexBoxMinimumSizing appBarBackground blackTopBorder" [style.height.px]="heightForBottomBar-1">
    </tun-bottom-bar>
  </div>


</div>



