import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { PopupContent } from '@components/popups/popup/interfaces';
import { PopupService } from '@service/popup.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationMode, ZoneConnectionsService } from '../../../services/authentication/zone-connections.service';

export interface OptionItem {
  description: string;
  type: Options;
  selectable?: boolean;
  selected?: boolean;
}

export enum Options {
  ONSCREENKEYBOARD,
  BLOCKED_SONGS,
  SUBSCRIPTION,
  LANGUAGE,
  COPYRIGHT,
  CORONA_APP,
  VERSION,
  LOGOUT,
  HELP
}

@Component({
  selector: 'tun-options-menu',
  templateUrl: './options-menu.component.html',
  styleUrls: ['./options-menu.component.scss'],
})
export class OptionsMenuComponent implements OnInit, PopupContent, OnDestroy {
  // === Props === //
  @Input() public connectedElementRef: ElementRef;
  @Input() public header: string;
  @Input() public optionItems: OptionItem[][];
  @Input() public showConnectedZone: Boolean = false;

  // === State === //
  //private _selectedOptions: OptionItem[] = [];

  // === Observables === //
  private _optionClick$ = new Subject<OptionItem>();

  // === ViewChildren === //
  @ViewChildren('option') options: QueryList<ElementRef>;

  // === Getters === //
  get optionClick$(): Observable<OptionItem> {
    return this._optionClick$.asObservable();
  }

  public get disconnectLabel$(){
    return this.zoneConnectionsService.applicationMode$
    .pipe(
      map(applicationMode => {
        if (applicationMode == ApplicationMode.remoteMode){
          return 'connect.stop';
        }
        return 'settings.option.logout';
      })
    )
  }

  constructor(
    public elementRef: ElementRef,
    private _popupService: PopupService,
    private zoneConnectionsService: ZoneConnectionsService
  ) {

  }

  ngOnInit() {}

  ngOnDestroy() {
    this._optionClick$.complete();
  }

  isSelected( optionItem: OptionItem) {
    return optionItem.selected;
  }

  // === Event handlers === //
  onOptionClick(option: OptionItem) {
    this._optionClick$.next(option);
    if (option.selectable) {
      //the selected state will be updated by the model change
      //don't close the option popup when toggling selectables
    } else {
      this._popupService.hidePopup$.next(this.connectedElementRef);
    }
  }

  onCloseClick() {
    this._popupService.hidePopup$.next(this.connectedElementRef);
  }

  get nameForView(){
    if (this.zoneConnectionsService.activeZoneConnection != null){
      if (this.zoneConnectionsService.activeZoneConnection.name){
        return this.zoneConnectionsService.activeZoneConnection.name;
      }else if (this.zoneConnectionsService.activeZoneConnection.username){
        return this.zoneConnectionsService.activeZoneConnection.username;
      }
    }
    return '';
  }

  get locationName(){
    if (this.zoneConnectionsService.activeZoneConnection != null && this.zoneConnectionsService.activeZoneConnection.locationName){
      return this.zoneConnectionsService.activeZoneConnection.locationName;
    }
    return '';
  }

  public disconnect(){
    this._optionClick$.next({ description: 'settings.option.logout', type: Options.LOGOUT })
  }
}
