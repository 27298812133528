import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  NgZone,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { BaseSliderComponent } from '../base-slider/base-slider.component';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';

@Component({
  selector: 'tun-flexible-slider',
  templateUrl: './flexible-slider.component.html',
  styleUrls: ['./flexible-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexibleSliderComponent extends BaseSliderComponent
  implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('leftSlider', { static: true }) leftSlider: ElementRef;
  @ViewChild('leftSliderValue', { static: true }) leftSliderValue: ElementRef;
  @ViewChild('leftSliderBox', { static: true }) leftSliderBox: ElementRef;
  @ViewChild('rightSliderBox', { static: true }) rightSliderBox: ElementRef;
  @ViewChild('rightSliderValue', { static: true }) rightSliderValue: ElementRef;

  // Input for the lowest number;
  @Input() minValue: number;

  // Input for the highest number;
  @Input() maxValue: number;

  // Currently selected minimum value.
  @Input() selectedMinValue = 0;

  // Currently selected maximal value.
  @Input() selectedMaxValue = 0;

  // Left attribute of the selected line, used for positioning.
  selectedLineLeft = 0;

  // Right attribute of the selected line, used for positioning.
  selectedLineRight = 0;

  // Padding of the box surrounding the left slider, changes based on width of label.
  leftSliderBoxPadding: SafeStyle;

  // Left attribute of the left slider value in percent.
  leftSliderValueLeft: SafeStyle = '1rem';

  // Padding of the box surrounding the right slider, changes based on width of label.
  rightSliderBoxPadding: SafeStyle;

  // Right attribute of the right slider value in percent.
  rightSliderValueRight: SafeStyle = '1rem';

  // List of breakpoints a slider can snap to.
  breakpoints: number[] = [];

  // Style of the value next to the slider.
  valueStyle: SafeStyle;

  // when one of the values goes beyond this percentage, the label is flipped to the other side
  private insideFromPercentage = 90;

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected sanitizer: DomSanitizer,
    protected ngZone: NgZone,
    protected activeMusicSelectionService: ActiveMusicSelectionService
  ) {
    super(sanitizer, cdRef, activeMusicSelectionService);
  }

  // Called when the window is resized to fix the positions of the sliders.
  onResize = () => {
    super.onResize();
    this.calculateLeftSliderBoxPadding(
      this.leftSliderLeftPercent < this.insideFromPercentage
    );
    this.calculateRightSliderStyle(
      this.rightSliderRightPercent < this.insideFromPercentage
    );
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    let breakPointsChanged = false;
    if (simpleChanges.minValue || simpleChanges.maxValue) {
      this.breakpoints = this.getBreakpoints();
      breakPointsChanged = true;
    }
    let sliderChanged = false;
    if (simpleChanges.selectedMinValue || breakPointsChanged) {
      const percentage =
        (100 * (this.selectedMinValue - this.minValue)) /
        (this.maxValue - this.minValue);
      // if the representation value is the same -> don't adjust (= don't snap to real values)
      if (
        this.closestBreakPointIndexOfPercentage(this.leftSliderLeftPercent) !=
        this.closestBreakPointIndexOfPercentage(percentage)
      ) {
        this.leftSliderLeftPercent = percentage;
        this.calculateLeftSliderBoxPadding(
          this.leftSliderLeftPercent < this.insideFromPercentage
        );
        sliderChanged = true;
      }
    }

    if (simpleChanges.selectedMaxValue) {
      const percentage =
        (100 * (this.selectedMaxValue - this.minValue)) /
        (this.maxValue - this.minValue);
      // if the representation value is the same -> don't adjust (= don't snap to real values)
      if (
        this.closestBreakPointIndexOfPercentage(this.rightSliderRightPercent) !=
        this.closestBreakPointIndexOfPercentage(100 - percentage)
      ) {
        this.rightSliderRightPercent = 100 - percentage;
        this.calculateRightSliderStyle(
          this.rightSliderRightPercent < this.insideFromPercentage
        );
        sliderChanged = true;
      }
    }

    if (sliderChanged) {
      this.updateSelectedLine();
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.calculateLeftSliderBoxPadding(
      this.leftSliderLeftPercent < this.insideFromPercentage
    );
    this.calculateRightSliderStyle(
      this.rightSliderRightPercent < this.insideFromPercentage
    );
    this.cdRef.detectChanges();
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('mouseup', this.onMouseUp);
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseleave', this.onMouseLeave);
      window.addEventListener('touchmove', this.onTouchMove);
      window.addEventListener('touchend', this.onTouchEnd);
      window.addEventListener('touchcancel', this.onTouchCancel);
      window.addEventListener('resize', this.onResize);

      this.leftSliderBox.nativeElement.addEventListener(
        'mousedown',
        this.leftSliderMouseDown
      );
      this.leftSliderBox.nativeElement.addEventListener(
        'touchstart',
        this.leftSliderMouseDown
      );
      this.rightSliderBox.nativeElement.addEventListener(
        'mousedown',
        this.rightSliderMouseDown
      );
      this.rightSliderBox.nativeElement.addEventListener(
        'touchstart',
        this.rightSliderMouseDown
      );
    });
  }

  ngOnDestroy() {
    this.ngZone.runOutsideAngular(() => {
      window.removeEventListener('mouseup', this.onMouseUp);
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseleave', this.onMouseLeave);
      window.removeEventListener('touchmove', this.onTouchMove);
      window.removeEventListener('touchend', this.onTouchEnd);
      window.removeEventListener('touchcancel', this.onTouchCancel);
      window.removeEventListener('resize', this.onResize);

      this.leftSliderBox.nativeElement.removeEventListener(
        'mousedown',
        this.leftSliderMouseDown
      );
      this.leftSliderBox.nativeElement.removeEventListener(
        'touchstart',
        this.leftSliderMouseDown
      );
      this.rightSliderBox.nativeElement.removeEventListener(
        'mousedown',
        this.rightSliderMouseDown
      );
      this.rightSliderBox.nativeElement.removeEventListener(
        'touchstart',
        this.rightSliderMouseDown
      );
    });
  }

  // Called when the user clicks the line to move a slider.
  lineClicked = (e: MouseEvent) => {
    super.lineClicked(e);

    const width = this.unselectedLine.nativeElement.offsetWidth;
    const percentage = ((e.clientX - this.unselectedLineClientX) / width) * 100;

    //for debugging weird bug where slider values was not correctly adjusted on a click
    //console.log("percentage: " + percentage + "e.clientX: " + e.clientX + " --- unselectedLineClientX: " + this.unselectedLineClientX + " --- width:" + width )

    // If the left slider is closer to the point on the line that was clicked.
    if (
      Math.max(percentage, this.leftSliderLeftPercent) -
        Math.min(percentage, this.leftSliderLeftPercent) <
      Math.max(percentage, 100 - this.rightSliderRightPercent) -
        Math.min(percentage, 100 - this.rightSliderRightPercent)
    ) {
      this.leftSliderLeftPercent = percentage;
      this.moveLeftSlider();
    } else if (
      Math.max(percentage, this.leftSliderLeftPercent) -
        Math.min(percentage, this.leftSliderLeftPercent) >
      Math.max(percentage, 100 - this.rightSliderRightPercent) -
        Math.min(percentage, 100 - this.rightSliderRightPercent)
    ) {
      this.rightSliderRightPercent = 100 - percentage;
      this.moveRightSlider();
    } else {
      // If the clicked point is to the left of the left slider.
      if (this.calculateLeftSliderClientX() >= e.clientX) {
        this.leftSliderLeftPercent = percentage;
        this.moveLeftSlider();
      } else {
        this.rightSliderRightPercent = 100 - percentage;
        this.moveRightSlider();
      }
    }

    this.resetTransitionSpeed();

    this.emitOutput(this.selectedMinValue, this.selectedMaxValue);
  }

  // Calculates the amount of options.
  getAmountOfBreakpoints(): number {
    return this.maxValue - this.minValue + 1;
  }

  // Get an array of percentages used for snapping.
  getBreakpoints() {
    const length = this.getAmountOfBreakpoints();
    return new Array(length).fill(0).map((e, i) => this.minValue + i);
  }

  // Calcultates the X-coordinate of the left slider.
  calculateLeftSliderClientX(): number {
    const { x } = this.leftSlider.nativeElement.getBoundingClientRect();
    return x;
  }

  // Calculates the padding the left slider box should have.
  calculateLeftSliderBoxPadding(inside: boolean) {
    if (inside) {
      this.leftSliderValueLeft = '1rem';
      this.leftSliderBoxPadding = this.sanitizer.bypassSecurityTrustStyle(
        `1rem calc(1.5rem + ${
          this.leftSliderValue.nativeElement.offsetWidth
        }px) 1rem 1rem`
      );
      this.leftSliderBoxLeft = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.leftSliderLeftPercent}% - 0.8rem)`
      );
    } else {
      this.leftSliderValueLeft = this.sanitizer.bypassSecurityTrustStyle(
        `calc(-0.8rem - ${this.leftSliderValue.nativeElement.offsetWidth}px)`
      );
      this.leftSliderBoxPadding = this.sanitizer.bypassSecurityTrustStyle(
        `1rem 1rem 1rem calc(1.5rem + ${
          this.leftSliderValue.nativeElement.offsetWidth
        }px)`
      );
      this.leftSliderBoxLeft = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.leftSliderLeftPercent}% - ${
          this.leftSliderValue.nativeElement.offsetWidth
        }px - 0.8rem)`
      );
    }
  }

  // Calculates the padding the right slider box should have.
  calculateRightSliderStyle(inside: boolean) {
    if (inside) {
      this.rightSliderValueRight = '1rem';
      this.rightSliderBoxPadding = this.sanitizer.bypassSecurityTrustStyle(
        `1rem 1rem 1rem calc(1.5rem + ${
          this.rightSliderValue.nativeElement.offsetWidth
        }px)`
      );
      this.rightSliderBoxRight = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.rightSliderRightPercent}% - 0.8rem)`
      );
    } else {
      this.rightSliderValueRight = this.sanitizer.bypassSecurityTrustStyle(
        `calc(-0.8rem - ${this.rightSliderValue.nativeElement.offsetWidth}px)`
      );
      this.rightSliderBoxPadding = this.sanitizer.bypassSecurityTrustStyle(
        `1rem calc(1.5rem + ${
          this.rightSliderValue.nativeElement.offsetWidth
        }px) 1rem 1rem`
      );
      this.rightSliderBoxRight = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.rightSliderRightPercent}% - ${
          this.rightSliderValue.nativeElement.offsetWidth
        }px - 0.8rem)`
      );
    }
  }

  // Updates the left and right attributes of the selected line to match the sliders.
  updateSelectedLine() {
    if (
      Math.round(this.leftSliderLeftPercent + this.rightSliderRightPercent) <
      100
    ) {
      this.selectedLineLeft = this.leftSliderLeftPercent;
      this.selectedLineRight = this.rightSliderRightPercent;
    } else {
      if (this.leftSliderLeftPercent <= 1) {
        this.selectedLineLeft = this.leftSliderLeftPercent;
        this.selectedLineRight = this.rightSliderRightPercent - 1;
      } else if (this.rightSliderRightPercent <= 1) {
        this.selectedLineLeft = this.leftSliderLeftPercent - 1;
        this.selectedLineRight = this.rightSliderRightPercent;
      } else {
        this.selectedLineLeft = this.leftSliderLeftPercent - 0.5;
        this.selectedLineRight = this.rightSliderRightPercent - 0.5;
      }
    }
  }

  // If a slider is clicked, it is released and the outputs are emitted.
  releaseSliderClicks() {
    if (this.leftSliderIsClicked) {
      this.leftSliderIsClicked = false;
      this.leftSliderClientX = 0;
      this.emitOutput(this.selectedMinValue, this.selectedMaxValue);
    } else if (this.rightSliderIsClicked) {
      this.rightSliderIsClicked = false;
      this.rightSliderClientX = 0;
      this.emitOutput(this.selectedMinValue, this.selectedMaxValue);
    }
  }

  // Registration that the user is moving the left slider.
  leftSliderMouseMove(eventClientX: number) {
    // Current width of the left slider value.
    const leftSliderValueWidth = this.leftSliderValue.nativeElement.offsetWidth;

    super.leftSliderMouseMove(eventClientX, leftSliderValueWidth);

    // Move the slider.
    this.moveLeftSlider();
  }

  // Calculate and set the currently selected minimum value.
  updateMinValue() {
    const index = this.closestBreakPointIndexOfPercentage(
      this.leftSliderLeftPercent
    );
    this.selectedMinValue = this.breakpoints[index];
  }

  private closestBreakPointIndexOfPercentage(percentage: number) {
    return Math.round(((this.getAmountOfBreakpoints() - 1) * percentage) / 100);
  }

  // Move the left slider.
  moveLeftSlider() {
    this.calculateLeftSliderBoxPadding(
      this.leftSliderLeftPercent < this.insideFromPercentage
    );
    this.updateMinValue();
    this.updateSelectedLine();
  }

  // Registration that the user is moving the right slider.
  rightSliderMouseMove(eventClientX: number) {
    // Current width of the right slider value.
    const rightSliderValueWidth = this.rightSliderValue.nativeElement
      .offsetWidth;

    super.rightSliderMouseMove(eventClientX, rightSliderValueWidth);

    // Move the slider.
    this.moveRightSlider();
  }

  // Move the right slider.
  moveRightSlider() {
    this.calculateRightSliderStyle(
      this.rightSliderRightPercent < this.insideFromPercentage
    );
    this.updateMaxValue();
    this.updateSelectedLine();
  }

  // Calculate and set the currently selected maximum value.
  updateMaxValue() {
    const index = this.closestBreakPointIndexOfPercentage(
      100 - this.rightSliderRightPercent
    );
    this.selectedMaxValue = this.breakpoints[index];
  }

  // Change the colors of the slider.
  changeSliderColors() {
    super.changeSliderColors();
    this.valueStyle = this.sanitizer.bypassSecurityTrustStyle(
      `${this.sliderColors._selectedColor}`
    );
  }
}
