import { OverlayRef } from '@angular/cdk/overlay';
import { ElementRef, Type } from '@angular/core';
import { ItemSelect, DropdownMenuItem } from '@components/app-components/fields/calendar-dropdown/dropdown-menu/dropdown-menu.component';
import { OptionItem } from '@components/popups/options-menu/options-menu.component';
import { Playlist } from '@model/playlist';
import { Song } from '@model/song';
import {
  AutocompletionObject,
  AutocompletionResult
} from '@service/autocompletion.service';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { TooltipAction, TooltipAlignment } from '../tooltip/tooltip.component';
import { Observable, Subject } from 'rxjs';
import { PopupArrowComponent } from '../popup-arrow/popup-arrow.component';
import { PopupDirection, PopupPosition } from './enums';
import { TrackEvent } from '@model/events/trackEvent';

export interface PopupContent {
  elementRef: ElementRef;
  connectedElementRef: ElementRef;
}

export interface CopyDropdownPopupContent extends PopupContent {
  items: string[];
  selectedIndex: number;
  header?: string;
  lastItem?: string;
  itemSelect$?: Subject<number>;
}

export interface TooltipPopupContent extends PopupContent {
  text: string;
  alignment: TooltipAlignment;
  tooltipAction?: TooltipAction;
  darkThemed?: boolean;
}

export interface SongInfoPopupContent extends PopupContent {
  song: Song;
  showNavigation: boolean;
  showDelete: boolean;
  selectTrackEvent$: Subject<TrackEvent>;
}

export interface CalendarDropdownPopupContent extends PopupContent {
  width: number;
  menuItems: DropdownMenuItem[][];
  itemSelect$: Subject<ItemSelect>;
  hideSubmenu: () => void;
  containsNode: (node: Node) => boolean;
}

export interface AutocompleteResultPopupContent extends PopupContent {
  autocompletionResult: AutocompletionResult;
  width: number;
  searchTerm: string;
  selectedSuggestion: Subject<AutocompletionObject>;
  onSearch: Subject<AutocompletionObject>;
}

export interface SuggestionPopupContent extends PopupContent {}

export interface OptionsMenuPopupContent extends PopupContent {
  header: string;
  optionItems: OptionItem[][];
  optionClick$: Observable<OptionItem>;
  showConnectedZone: Boolean;
}

export interface MyListsPopupContent extends PopupContent {
  title: string;
  status: AsyncStatus;
  isSearchEnabled: boolean;
  isAddToQueueEnabled: boolean;
  onSearch$: Observable<Playlist>;
  onSelect$: Observable<AddToListSelection>;
}

export interface PopupConfig {
  connector: ElementRef;
  popupPosition: PopupPosition;
  componentType: Type<any>;
  showArrow?: boolean;
  animationDuration?: number;
  dynamicPosition?: boolean;
  popupDirection?: PopupDirection;
  darkThemed?: boolean;
}

export interface PopupArrow {
  overlayRef: OverlayRef;
  popupArrowComponent: PopupArrowComponent;
}

export enum ListOption {
  QUEUE,
  PLAYLIST,
  FAVORITES,
  NEW_LIST,
}

export interface AddToListSelection {
  listOption: ListOption;
  playlist?: Playlist; // is undefined when QUEUE or NEW_LIST
}
