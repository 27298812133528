import { Component, ChangeDetectorRef, ChangeDetectionStrategy, HostListener, Output, ElementRef, Input } from '@angular/core';
import { AutocompletionResult, AutocompletionObject } from '@service/autocompletion.service';
import { Subject } from 'rxjs';
import { AutocompleteResultPopupContent } from '@components/popups/popup/interfaces';

@Component({
  selector: 'tun-autocomplete-result',
  templateUrl: './autocomplete-result.component.html',
  styleUrls: ['./autocomplete-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteResultComponent implements AutocompleteResultPopupContent {

  // === Props === //
  private _suggestions:  AutocompletionObject[] = [];
  private _width: number;
  private _searchTerm: string;

  @Input() connectedElementRef: ElementRef;

  // === State === //
  // the index of the row currently selected using the arrows
  selectedIndex = -1;

  // === Subjects === //
  selectedSuggestion = new Subject<AutocompletionObject>();

  onSearch = new Subject<AutocompletionObject>();

  // === Getters and setters === //
  get suggestions(): AutocompletionObject[] {
    return this._suggestions;
  }

  get selectedItem(): AutocompletionObject {
    return this.suggestions[this.selectedIndex];
  }

  get width(): number {
    return this._width;
  }

  set width(value: number) {
    this._width = value;
    this.cdr.detectChanges();
  }

  get searchTerm(): string {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
    this.cdr.detectChanges();
  }

  set autocompletionResult(value: AutocompletionResult) {
    this._suggestions = value.suggestions || [];
    this.cdr.detectChanges();
  }

  constructor(private cdr: ChangeDetectorRef,
    public elementRef: ElementRef) {
    this.cdr.detach();
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event) {
    if (this.selectedItem) {
      event.preventDefault();
      this.onClickItem(this.selectedItem);
    }
  }

  onClickItem(item: AutocompletionObject) {
    this.onSearch.next(item);
  }

  @HostListener('document:keydown.arrowdown', ['$event'])
  onArrowDown(event: KeyboardEvent) {
    event.preventDefault();

    // stay in the interval [0, n]
    this.selectedIndex = (this.selectedIndex + 1 ) % (this.suggestions.length + 1);
    this.selectedSuggestion.next(this.selectedItem);
    this.cdr.detectChanges();
  }

  @HostListener('document:keydown.arrowup', ['$event'])
  onArrowUp(event: KeyboardEvent) {
    event.preventDefault();

    // stay in the interval [-1, n-1]
    this.selectedIndex = this.selectedIndex <= -1 ? this.suggestions.length - 1 : this.selectedIndex - 1;
    this.selectedSuggestion.next(this.suggestions[this.selectedIndex]);
    this.cdr.detectChanges();
  }
}
