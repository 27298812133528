import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'tun-suggestion-icon',
  templateUrl: './suggestion-icon.component.html',
  styleUrls: ['./suggestion-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionIconComponent {

  @Input() color: string;
}
