<div class="flexBoxMinimumSizing topBarContent" fxFlex fxLayout="row">

  <tun-tab-selector [style.width.px]="widthForMenuPanel$ | async" [componentWidth]="widthForMenuPanel$ | async"
    class="flexBoxMinimumSizing" [selectedTunifyColor]="selectedTunifyColor">
  </tun-tab-selector>

  <tun-top-bar-search-field [widthForSearchBar]="widthForSearchBar$ | async"></tun-top-bar-search-field>

  <tun-message-center fxFlex class="flexBoxMinimumSizing">
  </tun-message-center>

  <div class="icons-container">

    <div class="icons right-icons">

      <!-- Extra container to correctly position the popup - if we add the tunPopupOnClick directive on the tun-icon-button, the popup is not getting the correct position-->
      <div #helpButton data-intercom-target="Help button" tunPopupOnClick [closePopupAfterMouseLeave]="false"
        [closeOnPopupClick]="false" (togglepopup)="openHelpMenu($event)">
        <tun-icon-button class="menuButton" [height]="heightForTopBar">
          <tun-help-icon></tun-help-icon>
        </tun-icon-button>
      </div>

      <div #optionsButton data-intercom-target="Settings button" tunPopupOnClick [closePopupAfterMouseLeave]="false"
        [closeOnPopupClick]="false" (togglepopup)="openOptionsMenu($event)">
        <tun-icon-button class="menuButton" [height]="heightForTopBar">
          <tun-settings-icon></tun-settings-icon>
        </tun-icon-button>
      </div>

    </div>

  </div>


</div>
