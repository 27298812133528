<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="10px"
  y="10px"
  width="1792px"
  height="1792px"
  viewBox="0 0 1792 1792"
  enable-background="new 0 0 1792 1792"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
  />
</svg>
