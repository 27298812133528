<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M14.094,4H12V2H7v2H4.906C4.406,4,4,4.406,4,4.906V6h1v7.073C5,14.137,5.863,15,6.927,15h5.146
	C13.137,15,14,14.137,14,13.073V6h1V4.906C15,4.406,14.594,4,14.094,4z M8,3h3v1H8V3z M13,13.007C13,13.555,12.555,14,12.007,14
	H6.993C6.445,14,6,13.555,6,13.007V6h7V13.007z"
  />
  <rect x="7" y="8" fill="currentColor" width="1" height="4" />
  <rect x="11" y="8" fill="currentColor" width="1" height="4" />
  <rect x="9" y="8" fill="currentColor" width="1" height="4" />
</svg>
