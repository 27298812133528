import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'tun-autocomplete-result-row',
  templateUrl: './autocomplete-result-row.component.html',
  styleUrls: ['./autocomplete-result-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteResultRowComponent {

  // === Props === //
  @Input()
  type?: string;

  @Input()
  text: string;

  @Input()
  selected: boolean;
}
