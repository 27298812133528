<div class="wrapper">

  <div class="contentWrapper">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <div class="title">
          {{ 'settings.desktopapp.title' | translate }}
        </div>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <!-- normal content -->
    <div class="scrollContainer">
      <div class="real-content-container">

        <!-- install app -->

        <div class="small-title" translate>
          Download de Tunify app
        </div>

        <div class="small-info" translate>
          Download en installeer de Tunify app op je computer. Zo heb je altijd toegang tot je muziek en kun je eenvoudig muziek afspelen.
        </div>

        <div class="settings-button" (click)="onDownload()">
          <span class="button-title">Start download</span>
        </div>

        <!-- start app -->

        <div class="small-title" translate>
          Start de Tunify app
        </div>

        <div class="small-info" translate>
          Start de Tunify app op je computer.
        </div>

        <div class="settings-button" (click)="onStartDesktop()">
          <span class="button-title">Start Tunify Desktop</span>
        </div>

        <div *ngIf="playerNotFound" class="error-info">
            De Tunify app is niet gevonden op je computer. Start de app handmatig of download de app opnieuw.
        </div>


      </div>
    </div>


</div>
