import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { SingleDropdownComponent } from '@components/app-components/fields/single-dropdown/single-dropdown.component';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import {
  CopyDropdownPopupContent,
  PopupContent,
  TooltipPopupContent,
} from '@components/popups/popup/interfaces';
import { PopupService } from '@service/popup.service';
import { Subject, fromEvent, merge, timer, of } from 'rxjs';
import {
  filter,
  take,
  mapTo,
  switchMapTo,
  takeUntil,
  scan,
  switchMap,
  delay,
} from 'rxjs/operators';
import { carouselAnimation } from '@util/animations';
import { CalendarService } from '@service/calendar.service';
import { TooltipComponent, TooltipAlignment } from '@components/popups/tooltip/tooltip.component';
import { Day } from '@model/day';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';

@Component({
  selector: 'tun-day-navigator',
  templateUrl: './day-navigator.component.html',
  styleUrls: ['./day-navigator.component.scss'],
  animations: [carouselAnimation],
})
export class DayNavigatorComponent implements OnInit, AfterViewInit, OnDestroy {
  // === Constants === //
  static readonly CAROUSEL_ANIMATION_DURATION = 700;
  readonly CAROUSEL_ANIMATION_DURATION = DayNavigatorComponent.CAROUSEL_ANIMATION_DURATION;
  readonly DROPDOWN_ANIMATION_DURATION = 200;
  readonly ALL_DAYS_TEXT = 'calendar.header.copyDay.allDays';
  readonly DROPDOWN_HEADER_TEXT = 'calendar.header.copyDay.title';
  readonly EMPTY_CALENDAR_TEXT = 'calendar.header.clearDay.tooltip';
  readonly CLICK_DELAY = 0;

  // === Props === //
  @Input() heightPerItem: number;
  @Input() iconHeight: number;
  @Input() iconPadding: SafeStyle;
  @Input() inEditMode: boolean;
  @Input() animationI: number;
  @Input() currentIndex = 0;

  // === State === //
  private _animatingInIndex: number;
  private _direction: 'next' | 'prev';

  // === Emitters === //
  @Output() daychange = new EventEmitter<number>();

  // === Observables === //
  private _destroy$ = new Subject();

  // === ViewChildren === //
  @ViewChild('copyButton') copyButton: ElementRef;
  @ViewChild('previousButton') previousButton: ElementRef;
  @ViewChild('nextButton') nextButton: ElementRef;

  // === Getters === //
  get animatingInIndex(): number {
    return this._animatingInIndex;
  }
  get direction(): string {
    return this._direction;
  }
  get daysOfWeek(): Day[] {
    return Day.DAYS;
  }

  constructor(
    private _popupService: PopupService,
    private _cdRef: ChangeDetectorRef,
    private _calendarService: CalendarService,
    private _activeMusicSelectionService: ActiveMusicSelectionService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this._setupNavigatorButtons(
      this.nextButton,
      this.previousButton,
      this.CLICK_DELAY
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
  }

  // === Event handlers === //
  toggleDropdown(showPopup: boolean) {
    if (showPopup) {
      this._popupService.showPopup$.next({
        connector: this.copyButton,
        animationDuration: this.DROPDOWN_ANIMATION_DURATION,
        componentType: SingleDropdownComponent,
        popupPosition: PopupPosition.BOTTOM_CENTER,
        popupDirection: PopupDirection.DOWN,
      });

      this._popupService.currentInstance$
        .pipe(
          take(1),
          filter(
            (instance: PopupContent) =>
              instance && instance.connectedElementRef === this.copyButton
          )
        )
        .subscribe((instance: CopyDropdownPopupContent) => {
          instance.items = Day.DAYS.map(day => day.nameToTranslate);
          instance.header = this.DROPDOWN_HEADER_TEXT;
          instance.lastItem = this.ALL_DAYS_TEXT;
          instance.selectedIndex = this.currentIndex;

          instance.itemSelect$
          .pipe(
            takeUntil(this._popupService.hidePopup$)
          )
          .subscribe(index => {
            let targetDays = (index < Day.DAYS.length ?  [Day.DAYS[index]]: Day.DAYS);
            this._calendarService.copyDay(this._activeMusicSelectionService.selectedCalendar, Day.DAYS[this.currentIndex], targetDays);
            this._popupService.hidePopup$.next(this.copyButton);
          } );

        });
    } else {
      this._popupService.hidePopup$.next(this.copyButton);
    }
  }

  toggleTooltip(showPopup: boolean, connector: HTMLElement) {
    if (showPopup) {
      const connectedElementRef = new ElementRef(connector);
      this._popupService.showPopup$.next({
        connector: connectedElementRef,
        popupPosition: PopupPosition.TOP_RIGHT,
        componentType: TooltipComponent,
        popupDirection: PopupDirection.UP,
        showArrow: true,
      });

      this._popupService.currentInstance$
        .pipe(
          take(1),
          filter(
            (instance: PopupContent) =>
              instance && instance.connectedElementRef === connectedElementRef
          )
        )
        .subscribe((instance: TooltipPopupContent) => {
          instance.text = this.EMPTY_CALENDAR_TEXT;
          instance.alignment = TooltipAlignment.CENTER;
        });
    } else {
      this._popupService.hidePopup$.next(new ElementRef(connector));
    }
  }

  // === Private methods === //
  /** Listens to the next and previous buttons
   *
   * Accumulates clicks until a specified delay has been reached and then emits the amount of clicks
   * @param nextButton Clicking on the next button accumulates a positive number of clicks
   * @param previousButton Clicking on the previous button accumulates a negative number of clicks
   * @param clickDelay In milliseconds, if this delay has been reached after a click then the total amount of clicks
   * will be emitted and the amount will be reset
   */
  private _setupNavigatorButtons(
    nextButton: ElementRef,
    previousButton: ElementRef,
    clickDelay: number
  ) {
    const nextClick$ = fromEvent(nextButton.nativeElement, 'click').pipe(
      mapTo(1)
    );
    const previousClick$ = fromEvent(
      previousButton.nativeElement,
      'click'
    ).pipe(mapTo(-1));
    const reset$ = merge(nextClick$, previousClick$).pipe(
      switchMapTo(
        timer(clickDelay).pipe(takeUntil(merge(nextClick$, previousClick$)))
      ),
      mapTo(0)
    );
    merge(nextClick$, previousClick$, reset$)
      .pipe(
        takeUntil(this._destroy$),
        scan((clicks, newClick) => (newClick === 0 ? 0 : (clicks += newClick))),
        switchMap(clicks => of(clicks).pipe(delay(clickDelay)))
      )
      .subscribe(clicks => {
        this.daychange.emit(clicks);
        if (clicks > 0) {
          this._direction = 'next';
          this._cdRef.detectChanges();
        } else if (clicks < 0) {
          this._direction = 'prev';
          this._cdRef.detectChanges();
        }
      });
  }

  onClearDay(){
    let day:Day = Day.getDayForIndex(this.currentIndex);
    this._calendarService.clearDay(this._activeMusicSelectionService.selectedCalendar, day);
  }
}
