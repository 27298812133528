<div class="confirmPopup">

    <div mat-dialog-content class="content-container">
      <!-- header -->
      <tun-popup-header
        (close)="onCancelClick()"
        [isInMatDialog]="'true'">
          {{'settings.option.version' | translate}}
      </tun-popup-header>

        <div class="text-container">
          {{'popup.version.info' | translate}} {{tunifyVersion}}
        </div>

    </div>

  </div>

