import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tun-deejay-icon',
  templateUrl: './deejay-icon.component.html',
  styleUrls: ['./deejay-icon.component.scss']
})
export class DeejayIconComponent {

  @Input() color: string;

  constructor() { }

  

}
