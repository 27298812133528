import {
  Component,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';
import {
  AutocompleteResultPopupContent,
  PopupConfig,
  PopupContent
} from '@components/popups/popup/interfaces';
import { SearchService } from '@service/search.service';
import { PopupService } from '@service/popup.service';
import { takeUntil, switchMap, take, filter, tap, mapTo } from 'rxjs/operators';
import { Subject, of, fromEvent } from 'rxjs';
import { PopupPosition, PopupDirection } from '@components/popups/popup/enums';
import { AutocompleteResultComponent } from '@components/app-components/fields/autocomplete-result/autocomplete-result.component';
import { OnDestroy } from '@angular/core';
import {
  AutocompletionService,
  AutocompletionObject,
  AutocompletionResult
} from '@service/autocompletion.service';

@Component({
  selector: 'tun-top-bar-search-field',
  templateUrl: './top-bar-search-field.component.html',
  styleUrls: ['./top-bar-search-field.component.scss']
})
export class TopBarSearchFieldComponent implements OnDestroy {

  // === Props === //
  @Input() widthForSearchBar: number;

  // === ViewChildren === //
  @ViewChild('searchFieldContainer')
  searchFieldContainer: ElementRef;

  // === State === //
  private _openAutocompletionPopup: AutocompleteResultPopupContent;
  searchTerm: string;

  constructor(
    private searchService: SearchService,
    private autocompletionService: AutocompletionService,
    private popupService: PopupService
  ) {}

  private _destroyed$ = new Subject<void>();
  public ngOnDestroy(){
    this._destroyed$.next(null);
    this._destroyed$.complete();
    this._destroyed$ = null;
  }

  private get openAutocompletionPopup(): AutocompleteResultPopupContent {
    return this._openAutocompletionPopup;
  }

  private set openAutocompletionPopup(
    instance: AutocompleteResultPopupContent
  ) {
    this._openAutocompletionPopup = instance;

    if (instance) {
      instance.onSearch
        .pipe(takeUntil(this._destroyed$))
        .subscribe(autocomplete => this.onSearchWithAutocomplete(autocomplete));

      instance.selectedSuggestion
        .pipe(takeUntil(this._destroyed$))
        .subscribe(
          suggestion =>
            (this.searchTerm = suggestion && suggestion.autocompletionText)
        );
    }
  }

  onSearchAutocomplete(value: string) {
    if (value) {
      const observable = this.autocompletionService.autoComplete(value);
      if (observable) {
        observable.pipe(
          switchMap(result => {
            if (this.openAutocompletionPopup) {
              return of(result);
            } else {
              this.popupService.showPopup$.next(
                this.getAutocompletePopupConfig()
              );

              return this.popupService.currentInstance$.pipe(
                take(1),
                filter(
                  (instance: PopupContent) =>
                    instance &&
                    instance.connectedElementRef === this.searchFieldContainer
                ),
                tap(
                  (instance: AutocompleteResultPopupContent) =>
                    (this.openAutocompletionPopup = instance)
                ),
                mapTo(result)
              );
            }
          }),
          takeUntil(this._destroyed$)
        )
        .subscribe(result => this.fillInPropsOfPopupContent(result, value));
      }
    } else {
      this.openAutocompletionPopup = null;
      this.popupService.hidePopup$.next(this.searchFieldContainer);
    }
  }

  onSearchWithText(value: string) {
    this.closeAutocompletionPopup();

    // Start a search
    this.searchService.searchOnText(value);
  }

  onSearchWithAutocomplete(value: AutocompletionObject) {
    this.closeAutocompletionPopup();

    // Start a search
    this.searchService.searchOnAutocompletion(value);
  }

  onFocusSearch() {
    fromEvent(document, 'click')
      .pipe(
        filter(({ target }) => {
          const current = target as Node;
          while (current) {
            const classList = (current as Element).classList;
            if (
              classList.contains('search-field-container') ||
              classList.contains('autocomplete-list')
            ) {
              return false;
            }
            return true;
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe(() => this.closeAutocompletionPopup());
  }

  private getAutocompletePopupConfig(): PopupConfig {
    return {
      connector: this.searchFieldContainer,
      popupPosition: PopupPosition.BOTTOM_CENTER,
      componentType: AutocompleteResultComponent,
      showArrow: false,
      animationDuration: 400,
      dynamicPosition: true,
      popupDirection: PopupDirection.DOWN
    };
  }

  public closeAutocompletionPopup() {
    // Cancel autocomplete if it was running
    this.autocompletionService.cancelPrevious();

    // Close the popup
    this.openAutocompletionPopup = null;
    this.popupService.hidePopup$.next(this.searchFieldContainer);
  }

  private fillInPropsOfPopupContent(
    result: AutocompletionResult,
    searchTerm: string
  ) {
    this.openAutocompletionPopup.width = this.widthForSearchBar;
    this.openAutocompletionPopup.searchTerm = searchTerm;
    this.openAutocompletionPopup.autocompletionResult = result;
  }
}
