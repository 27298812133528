import { Directive, Input, ElementRef, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { PopupOnHoverDirective } from '@components/popups/popup-on-hover.directive';
import { PopupService } from '@service/popup.service';
import { fromEvent, merge } from 'rxjs';
import { takeUntil, filter, take } from 'rxjs/operators';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { TooltipComponent, TooltipAlignment } from '@components/popups/tooltip/tooltip.component';
import { PopupPosition, PopupDirection } from '@components/popups/popup/enums';
import { PopupContent, TooltipPopupContent } from '@components/popups/popup/interfaces';
import { ExternalLinksService } from '@service/external-links.service';
import { AccessRightsChecker, checkAccess } from './check-access-functions';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionsService } from '../../../services/data/subscriptions.service';

@Directive({
  selector: '[tunCheckAccess]'
})
export class CheckAccessDirective extends PopupOnHoverDirective implements AfterViewInit, OnDestroy, AccessRightsChecker{

   //check access to general tabs
   @Input() checkAccessToGreen: boolean;
   @Input() checkAccessToBlue: boolean;
   @Input() checkAccessToOrange: boolean;

   //check access to specific functions
   @Input() checkAccessToSearch: boolean;
   @Input() checkAccessToCustomPlaylists: boolean;
   @Input() checkAccessToAddToQueue: boolean;
   @Input() checkAccessToStartTrack: boolean;

   @Input() popupPosition = PopupPosition.BOTTOM_CENTER;
   @Input() popupDirection = PopupDirection.DOWN;
   @Input() darkThemed = false;

  // === Emitters === //
  @Output() public clickWithAccess = new EventEmitter<boolean>();
  @Output() public togglepopupWithAccess = new EventEmitter<boolean>();



  constructor(
    protected _elementRef: ElementRef,
    protected _popupService: PopupService,
    protected _zoneConfigurationService: ZoneConfigurationService,
    protected subscriptionsService: SubscriptionsService,
    protected _externalLinksService: ExternalLinksService,
    protected _translateService: TranslateService
  ) {
    super(_elementRef, _popupService)
  }

  ngAfterViewInit(){
    super.ngAfterViewInit();

    //when the user clicks the control -> perform access check and emit custom event if access is ok
    const click$ = fromEvent(this._elementRef.nativeElement, 'click');
    click$.pipe(
      takeUntil(this._destroy$)
    )
    .subscribe(
      () => {
        if (!this.checkAndShowAccessViolations()){
          this.clickWithAccess.emit(true);
        }
      }
    );
  }

  protected performTogglePopup(togglePopup){
    if (togglePopup){
      if (!this.checkAndShowAccessViolations()){
        //another kind of popup could be attached to the same control
        this.performTogglePopupWithAccess(togglePopup);
        //super.performTogglePopup(togglePopup);
      }
    }else{
      //if an access popup is open -> close it
      if (this.currentAccessPopup){
        this._popupService.hidePopup$.next(this._elementRef);
      }
      //no access popup open -> emit for other popups
      else{
        this.performTogglePopupWithAccess(togglePopup);
      }
    }
  }

  protected performTogglePopupWithAccess(togglePopup){
    this.togglepopupWithAccess.emit(togglePopup);
  }

  //return a boolean to indicate if there were access violations
  private checkAndShowAccessViolations():boolean{
    const accessRightViolations = checkAccess(this, this.subscriptionsService.accessRights);

    if (accessRightViolations.length > 0){
      const firstViolation = accessRightViolations[0];
      this.showTooltip(this._translateService.instant(firstViolation.message), this._translateService.instant(firstViolation.upgradeLink));
      return true;
    }
    return false;
  }

  private currentAccessPopup: TooltipPopupContent;
  showTooltip(text: string, linkText: string = null) {

    this._popupService.showPopup$.next({
      connector: this._elementRef,
      componentType: TooltipComponent,
      popupPosition: this.popupPosition,
      popupDirection: this.popupDirection,
      showArrow: true,
      darkThemed: this.darkThemed
    });


    this._popupService.currentInstance$
      .pipe(
        take(1),
        filter(
          (instance: PopupContent) =>
                instance &&
                instance.connectedElementRef === this._elementRef
        ),
        takeUntil(this._destroy$)
      )
      .subscribe((instance: TooltipPopupContent) => {
        instance.text = text;
        instance.alignment = TooltipAlignment.CENTER;
        instance.tooltipAction = linkText?{ action: this.upgradeClicked, text: linkText }: null;
        instance.darkThemed = this.darkThemed;

        this.currentAccessPopup = instance;

      });

    //listen when the popup will be closed
    merge(
      this._popupService.hideAllPopups$,
      this._popupService.hidePopup$.pipe(
        filter(
          ({ nativeElement }) => nativeElement === this._elementRef
        ),
        takeUntil(this._destroy$)
      )
    )
    .pipe(
      take(1),
      takeUntil(this._destroy$)
    )
    .subscribe(() => {
      this.currentAccessPopup = null;
    });
  }

    private upgradeClicked = () => {
      this._externalLinksService.openZoneUpgradePage();
    }

}
