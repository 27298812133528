<div fxFlex fxLayout="row">
  <div fxFlex></div>
  <div class="icons-container">
    <div class="icons right-icons">


      <tun-icon-button id="messagesButton" *ngIf="enableIntercomButton" #messagesButton data-intercom-target="Messages button" class="menuButton"
        [height]="heightForTopBar$ | async">
        <tun-messages-icon [matBadge]="unreadIntercomMessages$ |async" matBadgeColor="warn" [matBadgeHidden]="(unreadIntercomMessages$ |async)==0"></tun-messages-icon>
      </tun-icon-button>

    </div>
  </div>
</div>
