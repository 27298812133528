<div class="suggestion-popup">
  <tun-popup-header
    (close)="onCloseClick()">
    Verzoeknummer vragen aan de Tunify DJ's
  </tun-popup-header>

  <form [formGroup]="suggestionForm" (ngSubmit)="doSuggestion()">

    <div class="first-row">
      <div class="field">
        <label for="title">{{'popup.songRequest.songTitle' | translate}}</label>
        <input type="text" name="title" id="title" formControlName="title" [class.error]="titleFieldTouched && titleFieldHasError">
      </div>

      <div class="field">
        <label for="group">{{'popup.songRequest.songGroup' | translate}}</label>
        <input type="text" name="group" id="group" formControlName="group" [class.error]="groupFieldTouched && groupFieldHasError">
      </div>
    </div>
    
    <div class="large field">
      <label for="remarks">{{'popup.songRequest.songRemarks' | translate}}</label>
      <textarea name="remarks" id="remarks" rows="3" formControlName="remarks"></textarea>
    </div>

    <p class="error" *ngIf="showErrorMsg">
      {{'popup.songRequest.errorsOnForm' | translate}}
    </p>

    <div class="field submit-button-container">
      
      <tun-loader text="popup.songRequest.creating" *ngIf="creating"></tun-loader>
      
      <span *ngIf="created">
        {{'popup.songRequest.createOk' | translate}}
      </span>

      <span class="error" *ngIf="suggestionForm.touched && suggestionForm.hasError('generalError')">
        {{'popup.songRequest.createError' | translate}}
      </span>

      <button type="submit" [disabled]="!suggestionForm.valid || creating || created">
        {{'popup.songRequest.sendButton' | translate}}
      </button>
    </div>

    <!--
    <div class="error" *ngIf="suggestionForm.touched && suggestionForm.hasError('generalError')">
      {{'popup.songRequest.createError' | translate}} {{ suggestionForm.getError('generalError') }}
    </div>
    -->

  </form>
</div>
