<ng-container *ngIf="state$ | async as state">
  <div fxFlex fxLayout="column" class="flexBoxMinimumSizing">
    <div
      [style.height.px]="state.heightForHeader"
      fxLayout="row"
      class="flexBoxMinimumSizing"
    >
      <tun-orange-player-panel fxFlex class="flexBoxMinimumSizing blackRightBorder">
      </tun-orange-player-panel>

      <div
        fxLayout="column"
        [style.width.px]="state.widthForMenuPanel"
        class="lightPanelBackground blackRightBorder"
      >
        <tun-tunify-logo
          fxFlex
          [royaltyFree]="royaltyFree$ | async"
          [totalHeightForComponent]="state.heightForHeader"
        >
        </tun-tunify-logo>
      </div>
    </div>

    <div fxFlex fxLayout="row" class="flexBoxMinimumSizing">
      <tun-menu-orange-panel
        [style.width.px]="state.widthForMenuPanel+1"
        [widthForMenuPanel]="state.widthForMenuPanel"
        class="blackRightBorder"
      >
      </tun-menu-orange-panel>

      <div
        fxFlex
        fxLayout="row"
        #panelContainer
        class="flexBoxMinimumSizing orange-panel darkPanelBackground"
      >

        <!-- empty panel -->
        <div  *ngIf="!state.showSearch && !state.selectedMusicCollection"
              [style.width.px]="state.widthForContentPanel + 1"
              fxFlex fxLayout="column"
              class="blackRightBorder"
        >

          <!-- empty header -->
          <div
              [style.height.px]="state.heightPerItem - 1"
              class="headerBarBackground empty-header blackBottomBorder"
          ></div>

          <div fxFlex>
            <div
            *ngIf="
              state.editingPlaylist !== null &&
              !state.showSearch &&
              !state.selectedMusicCollection
            "
            fxFlex
            class="editmode-placeholder blackRightBorder"
          >
            <p translate>
              <tun-info-icon></tun-info-icon>
              orange.playlist.edit.info
            </p>

            <p translate>
              orange.playlist.edit.info.cancel.beforeLink
              <button
                class="cancel-edit"
                (click)="toggleEditMode(state.editingPlaylist)"
                translate
              >
                orange.playlist.edit.info.cancel.link
              </button>
              orange.playlist.edit.info.cancel.afterLink
            </p>
          </div>


        </div>
          </div>



        <!-- the search results -->
        <tun-search-results-playlist
          (close)="closeSearchResults()"
          (edit)="toggleEditMode()"
          [canAddSongs]="state.editingPlaylist !== null"
          [style.width.px]="state.widthForContentPanel"
          *ngIf="state.showSearch"
          [audioFiles]="state.searchResult"
          [tracksLoading]="searching"
          [tracksLoadingError]="searchError$ | async"
          [title]="state.searchTerm"
          class="search-results blackRightBorder"
          (trackEvent)="onTrackEvent($event)"
          (tracksEvent)="onTracksEvent($event)"
        ></tun-search-results-playlist>

        <!-- panel that represents a currently selected musicCollection -->
        <tun-music-collection
          *ngIf="state.selectedMusicCollection"
          [canAddSongs]="state.editingPlaylist !== null"
          [showClose]="true"
          [musicCollection]="state.selectedMusicCollection"
          [audioFiles]="musicCollectionTracks$ | async"
          [tracksLoading]="musicCollectionLoadingTracks"
          [tracksLoadingError]="musicCollectionLoadingTracksError$ | async"
          [style.width.px]="state.widthForContentPanel"
          [style.height.px]="heightForContentPanel"
          (tweak)="toggleTweakPanel()"
          (refresh)="onRefreshMusicCollectionResult()"
          (close)="closeMusicCollectionPanel()"
          (trackEvent)="onTrackEvent($event)"
          (tracksEvent)="onTracksEvent($event)"
          class="blackRightBorder"
        ></tun-music-collection>

        <div
          @slideIn
          *ngIf="state.isTweaking"
          [style.width.px]="state.widthForContentPanel"
          [style.height.px]="heightForContentPanel"
          [style.left.px]="state.widthForContentPanel"
          [style.z-index]="3"
          class="tweak-panel"
        >
          <!-- the currently tweaking musicCollection -->
          <tun-changeable-parameters
            [style.position]="'absolute'"
            [title]="state.selectedMusicCollection.title"
            [changeableParameters]="
              state.selectedMusicCollection.changeableParameters
            "
            [tweakInfo]="state.selectedMusicCollection.tweakInfo"
            [tweakInfoLoaded]="state.selectedMusicCollection.tweakInfoLoaded"
            [parametersLoading]="state.selectedMusicCollection.detailsLoading"
            [style.width.px]="state.widthForContentPanel"
            [style.height.px]="heightForContentPanel"
            (reset)="onResetParameters()"
            (parameterchange)="onParametersChange($event)"
            (close)="toggleTweakPanel()"
          ></tun-changeable-parameters>
        </div>

        <ng-container
          *ngFor="let playlist of state.playlistPanels; let index = index"
        >
          <div
            [@slide]="
              state.editingPlaylist?.id === playlist.id ? 'right' : 'left'
            "
            [style.width.px]="state.widthForContentPanel"
            [style.height.px]="heightForContentPanel"
            [style.left.px]="
              state.editingPlaylist?.id === playlist.id
                ? state.widthForContentPanel + 3
                : 3
            "
            [style.z-index]="
            state.editingPlaylist?.id === playlist.id
            ? (state.tweakPanelAnimating || state.isTweaking
              ? 2
              : 5)
            : 4
            "
            class="editing-playlist"
          >
            <tun-editable-playlist
              [inEditMode]="state.editingPlaylist?.id === playlist.id"
              (edit)="toggleEditMode(playlist)"
              [showClose]="true"
              [canAddSongs]="
                state.editingPlaylist !== null &&
                state.editingPlaylist?.id !== playlist.id
              "
              (close)="(state.editingPlaylist?.id === playlist.id)?closeEditPlaylist():closePlaylist()"
              (trackindex)="onTrackIndex($event)"
              [style.width.px]="state.widthForContentPanel"
              [style.height.%]="100"
              [title]="playlist.title"
              [audioFiles]="playlist.audioFiles"
              (trackEvent)="onPlaylistTrackEvent(playlist, $event)"
              (tracksEvent)="onTracksEvent($event)"
              [tracksLoading]="playlist.detailsLoading"
              [tracksLoadingError]="playlist.detailsLoadingError"
            ></tun-editable-playlist>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
