<div>

  <div class="innerTitle">
    <p>{{'popup.prelisten.title' | translate}}</p>
  </div>

  <div class="installStep" *ngIf="(prelistenDeviceDetection$ | async) == PrelistenDeviceStatus.noDeviceAvailable">
    <p>{{'popup.prelisten.install.step1' | translate}}</p>
    <p>{{'popup.prelisten.install.step2' | translate}}</p>
  </div>

  <div class="songInfo" *ngIf="(prelistenDeviceDetection$ | async) == PrelistenDeviceStatus.detected">
    <p class="title">{{track.title}}</p>
    <p class="group">{{track.group}}</p>
  </div>

  <div class="feedback">
    <tun-loader *ngIf="loading$ | async" text="popup.prelisten.status.busy"></tun-loader>
    <p *ngIf="(loading$ | async) === false">{{feedback | translate}}</p>
  </div>

  


  <div class="buttons">

    <!-- skip back button-->
    <button
      class="button popup-button"
      tunTooltipOnHover
      [tooltipText]="'popup.prelisten.tooltip.skipBack'"
      [tooltipDarkThemed]="true"
      [popupDirection]="PopupDirection.UP"
      [popupPosition]="PopupPosition.TOP_CENTER"
      [darkThemed]="true"
      (clickWithAccess)="onSkipBackClick()"
      [style.margin-left]="0"
      [disabled]="!enableButtons"
    >
      <tun-prelisten-back-icon></tun-prelisten-back-icon>
    </button>

    <!-- play / pause button-->
    <button 
      class="button"
      (click)="onTogglePlayClick()"
      [disabled]="!enableButtons && !((prelistenDeviceDetection$ | async) == PrelistenDeviceStatus.noDeviceAvailable)"
    >
      <tun-play-icon *ngIf="!playing"></tun-play-icon>
      <tun-pause-icon *ngIf="playing"></tun-pause-icon>
    </button>

    <!-- skip forward button-->
    <button
      class="button popup-button"
      tunTooltipOnHover
      [tooltipText]="'popup.prelisten.tooltip.skipForward'"
      [tooltipDarkThemed]="true"
      [popupDirection]="PopupDirection.UP"
      [popupPosition]="PopupPosition.TOP_CENTER"
      [darkThemed]="true"
      (clickWithAccess)="onSkipForwardClick()"
      [style.margin-right]="0"
      [disabled]="!enableButtons"
    >
      <tun-prelisten-forward-icon></tun-prelisten-forward-icon>
    </button>
  
    
  </div>

  

</div>