import { Injectable, ApplicationRef, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { LoggerService } from '@service/loggers/logger.service';
import { first, takeUntil } from 'rxjs/operators';
import { interval, concat, Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { version } from 'process';

@Injectable({
  providedIn: 'root'
})
export class UpdateService implements OnDestroy{

  private LOGGER_CLASSNAME = 'UpdateService';


  /**
     * update available property
     */
    private get _updateAvailable(): boolean {
        return this._updateAvailableSubject.value;
    }
    private set _updateAvailable(value: boolean) {
        if (this._updateAvailable !== value) {
            this._updateAvailableSubject.next(value);
        }
    }
    get updateAvailable(): boolean {
        return this._updateAvailable;
    }
    private _updateAvailableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public updateAvailable$: Observable<boolean> = this._updateAvailableSubject.asObservable();

    public availableUpdateInfo = "";



  constructor(
    private updates: SwUpdate,
    private appRef: ApplicationRef,
    private loggerService: LoggerService
  ) {

    //service workers are only enabled in production environment
    //we can only check for updates if service workers are enabled
    if (this.updates.isEnabled){


      this.updates.versionUpdates
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (versionEvent) => {
          loggerService.debug(this.LOGGER_CLASSNAME, 'versionUpdates triggers', 'versionEvent: ' + versionEvent.type);

          switch (versionEvent.type) {
            case 'VERSION_DETECTED':
              loggerService.debug(this.LOGGER_CLASSNAME, 'versionUpdates triggers', `Downloading new app version: ${versionEvent.version.hash}`);
                break;
            case 'VERSION_READY':
              loggerService.debug(this.LOGGER_CLASSNAME, 'versionUpdates triggers', `Current app version: ${versionEvent.currentVersion.hash}`);
              loggerService.debug(this.LOGGER_CLASSNAME, 'versionUpdates triggers',` New app version ready for use: ${versionEvent.latestVersion.hash}`);
              this._updateAvailable = true;
                break;
            case 'VERSION_INSTALLATION_FAILED':
              loggerService.error(this.LOGGER_CLASSNAME, 'versionUpdates triggers', `Failed to install app version '${versionEvent.version.hash}': ${versionEvent.error}`);
                break;
            }
        }
      )

      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));

      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      const everyMinute$ = interval (60*1000);
      const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);


      everySixHoursOnceAppIsStable$
        .subscribe(
          () => {
            updates.checkForUpdate();
        });
      /*
      everyMinuteOnceAppIsStable$
        .subscribe(
          () => {
            updates.checkForUpdate();
        });
      */
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, "constructor", "SwUpdate not enabled");
    }

    //Uncomment to test the ui
      //this._updateAvailable = true;
      //this._newInfo = "This is just mockup info";

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  public forceUpdate(){
    document.location.reload();
  }




}
