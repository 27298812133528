
<div fxFlex>
    <div    [style.width.px]="widthForMenu$ | async" 
            fxLayout="column"
            class=blackRightBorder
    >
  
        <div    [style.height.px]="heightForHeader$ | async" 
                fxLayout="column" class="lightPanelBackground"
        >
            <tun-tunify-logo    fxFlex
                                [totalHeightForComponent]="heightForHeader$ | async"
            ></tun-tunify-logo>
        </div>
                
              
        <div    class="tunifyWatermark "
                [style.height.px]="(heightPerItem$ | async) - 1"
        ></div>
              
        <div fxFlex fxLayout="column" class="lightPanelBackground">
        </div>  
    </div>

    <div fxFlex fxLayout="column" class="lightPanelBackground blackRightBorder">

        <div    [style.height.px]="heightForHeader$ | async" 
                fxLayout="column" class="lightPanelBackground blackBottomBorder"
        ></div>
            
          
        <div    class="tunifyWatermark "
                [style.height.px]="(heightPerItem$ | async) - 1"
        ></div>
          
        <div fxFlex fxLayout="column" class="feedback-container lightRasterBackground">
                <tun-loader *ngIf="loading$ | async" text="startup.loadingSettings"></tun-loader>
                <ng-container *ngIf="!(loading$ | async) && loadingError$ | async">
                        <p>{{loadingError$ | async | translate}}</p>
                        <button (click)="retryClick()" class="retryButton">
                                <p>
                                  {{'general.tryAgain' | translate }}
                                </p>
                        </button>
                        
                </ng-container>
        </div>        
    </div>

</div>




