/**
 * TEST environement (https)
 *
 * run locally: ng serve --configuration="test"
 *
 * build for deployment: npm run build-for-test
 */


const api_zuul_server = "https://docker1-test.tunify.com/zuul";

export const commonTESTEnv = {
  name: 'TEST',
  firebase: {
    projectId: 'tunify-player',
    appId: '1:443747435615:web:7bcecf05ef59f5f02747ea',
    databaseURL: 'https://tunify-player.firebaseio.com',
    storageBucket: 'tunify-player.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyADQms--hZnyYe137XwTRFZTk0Bmhnc1X0',
    authDomain: 'tunify-player.firebaseapp.com',
    messagingSenderId: '443747435615',
    measurementId: 'G-4NZCR7T4R6',
  },
  production: true,

  consoleLogLevel: "debug",
  webServiceLogLevel: "warning",

  use_redirect_location: false,
  build_version: "5",
  redirect_location_v5: "https://player-test.tunify.com",
  redirect_location_v4: "https://player-test-v4.tunify.com",

  default_version: "5",
  default_v4_allowed: false,
  default_v5_allowed: true,

  desktopAppGeneralLocation: 'https://dl.todesktop.com/2407298jgz83edk',
  desktopProtocol: 'test-tunify://',

  mockBackend: false,
  VERSION: require('../../package.json').version,
  mockQueue: false,
  mockRadioQueue: false,
  mockSearch: false,
  enableSoundSpectrum: true,
  enableXMLHttpRequest: false,
  useAudioTagForFastStart: true,
  logChangeDetection: false,


  allowMultipleLogin: true,
  loginWithoutSubscription: false,

  enableGreen: true,
  enableOrange: true,

  enableSearch: true,
  enableOnScreenKeyboard: true,
  enableSongRequests: true,
  enableRecommendations: true,

  enableDownloadApp: true,
  enableHome: true,

  testMultipleZoneConnections: false,

  previousVersionUrl: "https://odoo-test.tunify.com/tunify/open-player.html?v=3",

  otherVersionUrl: "https://odoo-test.tunify.com/tunify/html",

  mytunifyBaseUrl: "https://my.tunify.com",
  helpGreen: "https://www.tunify.com/open/demo-tunify-green",
  helpBlue: "https://www.tunify.com/open/demo-tunify-blue",
  helpOrange: "https://www.tunify.com/open/demo-tunify-orange",
  helpConnect: "https://www.tunify.com/open/player-code",
  mytunifyRegister: "https://my.tunify.com/register",

  api_authenticator: api_zuul_server, //zuul recognizes the endpoints and forwards to the authentication service
  api_md5_authenticator: api_zuul_server + "/t4-md5-authenticator",
  api_music_service: api_zuul_server + "/t4-music-service",
  api_logging_service: api_zuul_server + "/t4-logging-service",
  api_media_service: api_zuul_server + "/t4-media-service",
  api_subscriptions_service: api_zuul_server + "/t4-subscriptions-service",
  api_song_requests_service: api_zuul_server + "/t4-song-requests-service",
  api_zone_service: api_zuul_server + "/t4-zone-service",
  api_brands_service: api_zuul_server + "/t4-brands-service",
  api_recommender_service: api_zuul_server + "/t4-recommender-service",

  api_remoteControl: "https://comm-test.tunify.com:443/",

  api_SOAP_webservice: "https://reverb.tunify.com/blackbox2/services/tunify3/",
  api_SOAP_logging: "https://reverb.tunify.com/blackbox2/services/tunify_logging/",

  appId: '60',
  appPassword: 'secret',
  api_appPassword: 'tunHTML',

  enableIntercom: true,
  enableIntercomMessenger: true,
  intercomId: "pduo0g13",

  enableAbly: true,
  ablyKey: 'wf9LFw.UkgpDA:xqCcjbvhVYgo2bxV'
};

export const environment = commonTESTEnv;

