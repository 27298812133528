import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tun-pause-icon',
  templateUrl: './pause-icon.component.html'
})
export class PauseIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
