<div class="wrapper">

  <div class="titleWrapper">
    <div class="remainingSpace"></div>
    <div class="title">{{'settings.label.title' | translate }}</div>
    <div class="remainingSpace remainingSpace-right">{{version$ | async}}</div>
  </div>



  <div class="scrollContainer">

    <div class="content-container">
      <div class="small-title" translate>
        settings.zone.title
      </div>

      <tun-settings-zone-info class="zone-info">

      </tun-settings-zone-info>

      <ng-container *ngIf="enableDownloadApp && !isDesktopApp" >
        <div class="small-title" translate>
          settings.desktopapp.title
        </div>

        <div class="settings-button" (click)="onOpen(SETTINGS_VIEW.SETTINGS_VIEW_DESKTOP_APP)">
          <span class="button-title">{{ 'settings.desktopapp.description' | translate }}</span>
          <tun-angle-right-icon-v5 class="icon icon-scaled-svg-button"></tun-angle-right-icon-v5>
        </div>
      </ng-container>

      <div class="small-title" translate>
        settings.language.title
      </div>

      <div class="settings-button" (click)="onOpen(SETTINGS_VIEW.SETTINGS_VIEW_LANGUAGE)">
        <span class="button-title">{{ language$ | async | translate }}</span>
        <tun-angle-right-icon-v5 class="icon icon-scaled-svg-button"></tun-angle-right-icon-v5>
      </div>

      <div class="small-title" translate>
        settings.blockedSongs.title
      </div>

      <div class="settings-button" (click)="onOpen(SETTINGS_VIEW.SETTINGS_VIEW_BANLIST)">
        <span class="button-title">{{ 'settings.blockedSongs.description' | translate }}</span>
        <tun-angle-right-icon-v5 class="icon icon-scaled-svg-button"></tun-angle-right-icon-v5>
      </div>

      <div class="small-title" translate>
        settings.songrequest.title
      </div>

      <div class="settings-button" (click)="onOpen(SETTINGS_VIEW.SETTINGS_VIEW_SONGREQUEST)">
        <span class="button-title">{{ 'settings.songrequest.description' | translate }}</span>
        <tun-angle-right-icon-v5 class="icon icon-scaled-svg-button"></tun-angle-right-icon-v5>
      </div>

      <div class="small-title" translate>
        settings.musicRights.title
      </div>

      <div class="settings-button" (click)="onOpen(SETTINGS_VIEW.SETTINGS_VIEW_MUSICRIGHTS)">
        <span class="button-title">{{ 'settings.musicRights.description' | translate }}</span>
        <tun-angle-right-icon-v5 class="icon icon-scaled-svg-button"></tun-angle-right-icon-v5>
      </div>

    </div>

  </div>

</div>
