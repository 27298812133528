<div class="row-container" [class.selected]="selected">
  <div class="icon">
    <span [ngSwitch]="type">
      <tun-account-icon *ngSwitchCase="'artist'"></tun-account-icon>
      <tun-cd-icon *ngSwitchCase="'song'"></tun-cd-icon>
      <tun-search-icon *ngSwitchDefault></tun-search-icon>
    </span>
  </div>
  <div class="text">
    <p>
      {{ text }}
    </p>
  </div>
</div>
