import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  ChangeDetectorRef
} from '@angular/core';
import { AudioFileWithPlayInfo } from '@service/audioFileWithPlayInfo';
import { LoggerService } from '@service/loggers/logger.service';
import { Subscription, Subject, merge } from 'rxjs';
import { AudioTagWrapper } from '@service/audioTagWrapper';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { AudioFile } from '@model/audioFile';
import { PopupService } from '@service/popup.service';
import {
  filter,
  takeUntil,
  switchMap,
  finalize,
  take
} from 'rxjs/operators';
import {
  PopupContent,
  MyListsPopupContent,
  SongInfoPopupContent,
  AddToListSelection,
  ListOption
} from '@components/popups/popup/interfaces';
import { MyListsPopupComponent } from '@components/popups/my-lists-popup/my-lists-popup.component';
import { PopupPosition, PopupDirection } from '@components/popups/popup/enums';
import { PlaylistService } from '@service/playlist.service';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { SongInfoPopupComponent } from '@components/popups/song-info-popup/song-info-popup.component';
import { Song } from '@model/song';
import {
  AudioFileProperty,
  isTrackPropertySearchable,
  formatTrackProperty
} from '@model/enums/audioFileProperty';
import {
  TrackEvent,
  TrackAction,
  searchActionForTrackProperty,
  isSearchAction
} from '@model/events/trackEvent';
import { environment } from 'src/environments/environment';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { DragDropService } from '@service/drag-drop.service';
import { SubscriptionsService } from '../../../../services/data/subscriptions.service';
import { AudioProperties } from '../../../../model/audioFile';

@Component({
  selector: 'tun-audio-file-playback-info',
  templateUrl: './audio-file-playback-info.component.html',
  styleUrls: ['./audio-file-playback-info.component.scss']
})
export class AudioFilePlaybackInfoComponent implements OnDestroy, OnChanges {

  //TODO -> show more popup & tooltip

  // === Public enums for use in html === //
  public PopupPosition = PopupPosition;
  public PopupDirection = PopupDirection;

  // === ViewChildren === //
  @ViewChild('addToListButton') addToListButton: ElementRef;
  @ViewChild('moreButton') moreButton: ElementRef;
  @ViewChild('trackinfo') trackinfo: ElementRef;

  // === Props === //
  @Input() audioFile: AudioProperties;
  @Input() playing = false;
  @Input() buttonHeight = 40;
  @Input() tunifyColor = TunifyColor.BLUE;
  @Input() audioFileProperty: AudioFileProperty;

  @Output() trackEvent = new EventEmitter<TrackEvent>();
  @Output() draggingFromThisChanged = new EventEmitter<boolean>();

  public propertySearchable = false;
  public propertyText = '';
  public TrackAction = TrackAction; //make available in html
  public enableSoundSpectrum = environment.enableSoundSpectrum;

  get enabledSearch(): boolean {
    return (
      environment.enableSearch &&
      this.subscriptionsService.accessRights &&
      this.subscriptionsService.accessRights.search &&
      this.audioFile && this.audioFile.canPerformTrackActions
    );
  }

  get enabledTrackActions(): boolean {
    return this.audioFile && this.audioFile.canPerformTrackActions;
  }

  private _audioFileWithPlayInfo: AudioFileWithPlayInfo;

  get audioFileWithPlayInfo(): AudioFileWithPlayInfo {
    return this._audioFileWithPlayInfo;
  }

  get showingAddToListPopup() {
    return this.goingToShowAddToListPopup || this.addToListPopup != null;
  }

  get showingSongInfoPopup() {
    return this.goingToShowSongInfoPopup || this.songInfoPopup != null;
  }

  @Input()
  set audioFileWithPlayInfo(file: AudioFileWithPlayInfo) {
    this._loggerService.info(
      this.LOGGER_CLASSNAME,
      'ngOnChanges',
      'AudioFilePlaybackInfoComponent input changed'
    );
    this._audioFileWithPlayInfo = file;
    this.watchReadyToPlay();
  }

  private _addToListPopup: MyListsPopupContent;
  private addToListPopupChanged$ = new Subject<void>();
  private set addToListPopup(instance: MyListsPopupContent) {
    this.addToListPopupChanged$.next(null);

    this._addToListPopup = instance;

    if (instance) {
      instance.title = 'popup.addToPlaylist.title';
      instance.isSearchEnabled = false;
      instance.isAddToQueueEnabled = false;

      instance.onSelect$
        .pipe(
          switchMap(({ listOption, playlist }: AddToListSelection) => {
            if (this.audioFile instanceof AudioFile){
              switch (listOption) {
                case ListOption.QUEUE:
                  return this._musicManipulationService.addAudioFilesToQueue([
                    this.audioFile
                  ]);
                case ListOption.PLAYLIST:
                  return this._playlistService.addAudioFileToPlaylist(playlist, [
                    this.audioFile
                  ]);
                case ListOption.NEW_LIST:
                  playlist.audioFiles = [this.audioFile];
                  return this._playlistService.createPlaylist(playlist);
                case ListOption.FAVORITES:
                  return this._playlistService.addAudioFileToPlaylist(
                    this._playlistService.favorites,
                    [this.audioFile]
                  );
                default:
                  return this._playlistService.addAudioFileToPlaylist(
                    playlist,
                    [this.audioFile]
                  );
              }
            }else{
              return AsyncStatus.ERROR;
            }

          }),
          takeUntil(
            merge(
              this._destroy$,
              this.addToListPopupChanged$
            )
          ),
          finalize(() => (this._addToListPopup = undefined))
        )
        .subscribe(
          (status: AsyncStatus) => (this._addToListPopup.status = status)
        );
    }
  }

  private get addToListPopup(): MyListsPopupContent{
    return this._addToListPopup;
  }

  private _songInfoPopup: SongInfoPopupComponent;
  private set songInfoPopup(instance: SongInfoPopupComponent) {
    this._songInfoPopup = instance;

    if (instance) {
      instance.song = this.audioFile as Song;
      instance.showNavigation = false;
    }
  }
  private get songInfoPopup():SongInfoPopupComponent{
    return this._songInfoPopup;
  }

  // === State === //
  private _destroy$ = new Subject<void>();
  private LOGGER_CLASSNAME = 'AudioFilePlaybackInfoComponent';
  private isReadyToPlaySubscription: Subscription;

  showVisualiser = false;
  audioTagWrapper: AudioTagWrapper = null;

  constructor(
    private _popupService: PopupService,
    private _loggerService: LoggerService,
    private _playlistService: PlaylistService,
    private _zoneConfigurationService: ZoneConfigurationService,
    private subscriptionsService: SubscriptionsService,
    private _musicManipulationService: MusicManipulationService,
    private _dragDropService: DragDropService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this._popupService.currentInstance$
      .pipe(
        filter(
          (popupContent) =>
            popupContent != null && popupContent instanceof MyListsPopupComponent &&
            popupContent.connectedElementRef && popupContent.connectedElementRef === this.addToListButton
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(
        (instance: MyListsPopupContent) => {
          this.addToListPopup = instance;
          this.goingToShowAddToListPopup = false;
        }
      );

    this._popupService.hidePopup$
      .pipe(
        filter(
          (elementRef: ElementRef) =>
            elementRef && elementRef === this.addToListButton
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this.addToListPopup = null;
      });



    this._popupService.currentInstance$
      .pipe(
        filter(
          (popupContent) =>
            popupContent != null && popupContent instanceof SongInfoPopupComponent &&
            popupContent.connectedElementRef && popupContent.connectedElementRef === this.moreButton
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(
        (instance: SongInfoPopupComponent) => {
          this.songInfoPopup = instance;
          this.goingToShowSongInfoPopup = false;
        }
      );

    this._popupService.hidePopup$
      .pipe(
        filter(
          (elementRef: ElementRef) =>
            elementRef && elementRef === this.moreButton
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this.songInfoPopup = null;
      })
  }

  ngOnDestroy() {
    this.songInfoPopup = null;
    this.addToListPopup = null;

    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$ = null;

    this.addToListPopupChanged$.next();
    this.addToListPopupChanged$.complete();
    this.addToListPopupChanged$ = null;

    this.showVisualiser = false;
    this.audioTagWrapper = null;


  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['audioFile'] || simpleChanges['audioFileProperty']) {
      this.adjustTrackProperty();
    }
  }

  // === Event handlers === //
  onToggleAddToListPopup(showPopup: boolean) {
    if (showPopup) {
      this.showAddToListPopup();
    } else {
      this.closeAddToListPopup();
    }
  }


  private goingToShowAddToListPopup = false;
  private showAddToListPopup() {
    this.goingToShowAddToListPopup = true;
    this._changeDetectorRef.detectChanges();
    this._popupService.showPopup$.next({
      connector: this.addToListButton,
      componentType: MyListsPopupComponent,
      popupPosition: PopupPosition.BOTTOM_CENTER,
      showArrow: true,
      popupDirection: PopupDirection.DOWN,
      animationDuration: 300,
      dynamicPosition: true
    });
  }

  private closeAddToListPopup() {
    this._popupService.hidePopup$.next(this.addToListButton);
  }

  private goingToShowSongInfoPopup = false;
  onToggleSongInfoPopup(togglePopup: boolean) {
    if (togglePopup) {
      this.goingToShowSongInfoPopup = true;
      this._changeDetectorRef.detectChanges();
      this._popupService.showPopup$.next({
        connector: this.moreButton,
        popupPosition: PopupPosition.BOTTOM_CENTER,
        dynamicPosition: true,
        animationDuration: 400,
        showArrow: true,
        componentType: SongInfoPopupComponent
      });


    } else {
      if (this.showingSongInfoPopup){
        this.goingToShowAddToListPopup = false;
        this._popupService.hidePopup$.next(this.moreButton);
      }
    }
  }

  private watchReadyToPlay() {
    if (this.isReadyToPlaySubscription) {
      this.isReadyToPlaySubscription.unsubscribe();
      this.isReadyToPlaySubscription = null;
    }

    if (this.audioFileWithPlayInfo) {
      this.isReadyToPlaySubscription = this.audioFileWithPlayInfo.isReadyToPlay$
        .pipe(takeUntil(this._destroy$))
        .subscribe(value => {
          this._loggerService.info(
            this.LOGGER_CLASSNAME,
            'watchReadyToPlay',
            'adjusting showVisualiser to ' + value
          );
          if (value) {
            this.audioTagWrapper = this.audioFileWithPlayInfo.audioTagWrapper;
          }
          this.showVisualiser = value;
        });
    } else {
      this.showVisualiser = false;
      this.audioTagWrapper = null;
    }
  }

  private adjustTrackProperty() {
    if (this.audioFileProperty != null) {
      this.propertySearchable = (this.audioFile instanceof AudioFile) && isTrackPropertySearchable(
        this.audioFile,
        this.audioFileProperty
      );
      this.propertyText = (this.audioFile instanceof AudioFile) && formatTrackProperty(
        this.audioFile,
        this.audioFileProperty
      );
    } else {
      this.propertySearchable = false;
      this.propertyText = '';
    }
  }

  public onSearchSimilar() {
    if (this.audioFile instanceof AudioFile){
      this.trackEvent.emit(
        new TrackEvent(TrackAction.SEARCH_SIMILAR, this.audioFile)
      );
    }else{
      this._loggerService.error(this.LOGGER_CLASSNAME, 'onSearchSimilar', 'audioFile is not an AudioFile: ' + this.audioFile);
    }
  }

  public onPerformTrackAction(trackAction: TrackAction) {
    if (isSearchAction(trackAction)) {
      if (this.enabledSearch) {
        if (this.audioFile instanceof AudioFile){
          this.trackEvent.emit(new TrackEvent(trackAction, this.audioFile));
        }else{
          this._loggerService.error(this.LOGGER_CLASSNAME, 'onPerformTrackAction', 'Could not perform search ' + trackAction + '. audioFile is not an AudioFile: ' + this.audioFile);
        }
      }
    } else {
      if (this.audioFile instanceof AudioFile){
        this.trackEvent.emit(new TrackEvent(trackAction, this.audioFile));
      }else{
        this._loggerService.error(this.LOGGER_CLASSNAME, 'onPerformTrackAction', 'Could not perform ' + trackAction + '. audioFile is not an AudioFile: ' + this.audioFile);
      }
    }
  }

  public searchProperty() {
    if (this.enabledSearch) {
      if (this.audioFile instanceof AudioFile){
        this.trackEvent.emit(
          new TrackEvent(
            searchActionForTrackProperty(this.audioFileProperty),
            this.audioFile
          )
        );
      }else{
        this._loggerService.error(this.LOGGER_CLASSNAME, 'searchProperty', 'audioFile is not an AudioFile: ' + this.audioFile);
      }
    }
  }


  /**
   * Drag functions
   */

  get trackIsDraggable(): boolean {
    //we make tracks draggable if they can be added to the queue & track actions can be performed on them (not with BrandMessages for example)
    return (
      this.subscriptionsService.accessRights &&
      this.subscriptionsService.accessRights.addToQueue &&
      this.audioFile && this.audioFile instanceof AudioFile && this.audioFile.canPerformTrackActions
    );
  }

  onDragStart(event: DragEvent) {

    this.draggingFromThisChanged.emit(true);

    // Create ghost image, needed to fix bug in Safari and Firefox
    // caused by virtual scrolling.


    const crt = (event.target as HTMLElement).cloneNode(true) as HTMLElement;
    crt.style.width = `${this.trackinfo.nativeElement.offsetWidth}px`;
    crt.style.height = `${this.trackinfo.nativeElement.offsetHeight}px`;
    //crt.style.backgroundColor = 'transparent';
    crt.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
    //crt.style.position = "absolute";
    document.body.appendChild(crt);

    // Normally the ghost image would start at the position of the cursor
    // but we want it to look like the user dragges an AudioFile out of its place.
    const { x, y } = this.trackinfo.nativeElement.getBoundingClientRect();
    const xOffset = event.clientX - x;
    const yOffset = event.clientY - y;

    // Apply the ghost image to the drag event.
    event.dataTransfer.setDragImage(crt, xOffset, yOffset);



    // Needed for Firefox, otherwise drag isn't allowed.
    event.dataTransfer.setData('fire', 'fox');

    this._dragDropService.dragStartedFromQueue = false;
    this._dragDropService.isUserDragging = true;
    this._dragDropService.draggingAudioFile = this.audioFile as AudioFile;


  }

  // Called when the drag ends, whether it was succesful or not doesn't matter.
  // This happens in the component where the audiofile is dragged from.
  onDragEnd() {
    this._dragDropService.isUserDragging = false;
    this._dragDropService.isUserDraggingLong = false;
    this._dragDropService.draggingAudioFile = null;

    this.draggingFromThisChanged.emit(false);
  }

}
