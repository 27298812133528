import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import {PlayerRoutingModule} from './player-routing.module';
import {PlayerComponent} from './player.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TopBarComponent } from './top-bar/top-bar.component';

import { BottomBarComponent } from './bottom-bar/bottom-bar.component';

import { StreamViewModule } from './stream-view/stream-view.module';
import { OrangeViewModule } from './orange-view/orange-view.module';
import { IconsModule } from '@components/icons/icons.module';
import { TabSelectorComponent } from './top-bar/tab-selector/tab-selector.component';
import { TabButtonComponent } from './top-bar/tab-selector/tab-button/tab-button.component';
import { AppComponentsModule } from '@components/app-components/app-components.module';
import { SongSorter } from '@components/app-components/fields/song-grid/song-sorter.pipe';
import { RightStreamPanelComponent } from './stream-view/right-stream-panel/right-stream-panel.component';
import { PlayerComponentsModule } from '@components/player-components/player-components.module';
import { PlayerPanelComponent } from './stream-view/right-stream-panel/player-panel/player-panel.component';
import { RadioQueuePanelComponent } from './stream-view/right-stream-panel/radio-queue-panel/radio-queue-panel.component';
import { QueuePanelComponent } from './stream-view/right-stream-panel/queue-panel/queue-panel.component';
import { PopupsModule } from '@components/popups/popups.module';
import { TopBarSearchFieldComponent } from './top-bar/top-bar-search-field/top-bar-search-field.component';
import { LoadingViewComponent } from './loading-view/loading-view.component';
import { UtilsModule } from '@util/utils.module';
import { MessageCenterComponent } from './top-bar/message-center/message-center.component';
import { OnScreenMessageComponent } from './top-bar/message-center/on-screen-message/on-screen-message.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { ResizeModule } from '@components/resize/resize.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        PlayerRoutingModule,
        BrowserAnimationsModule,
        StreamViewModule,
        OrangeViewModule,
        IconsModule,
        PopupsModule,
        PlayerComponentsModule,
        AppComponentsModule,
        UtilsModule,
        TranslateModule,
        MatBadgeModule,
        ResizeModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        PlayerComponent,
        TopBarComponent,
        TabButtonComponent,
        BottomBarComponent,
        TabSelectorComponent,
        RightStreamPanelComponent,
        PlayerPanelComponent,
        QueuePanelComponent,
        RadioQueuePanelComponent,
        TopBarSearchFieldComponent,
        LoadingViewComponent,
        MessageCenterComponent,
        OnScreenMessageComponent,
    ],
    providers: [
        SongSorter
    ]
})
export class PlayerModule {}
