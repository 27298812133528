<div
  [style.height.px]="navigationItem.showAsInfoItem ? 2 * heightPerItem : heightPerItem"
  [style.minHeight.px]="navigationItem.showAsInfoItem ? 2 * heightPerItem : heightPerItem"
  class="flexBoxMinimumSizing navigationItem {{ colorStyleName }} {{(navigationItem.acceptTrackDrops && isBeingDraggedOver)? 'draggedOver':''}} {{navigationItem.showAsInfoItem ? 'infoItemContainer' : ''}}"
  fxLayout="row"
  (click)="handleClick($event)">
  <div class="{{ !navigationItem.isSelecteable ? 'nonSelecteableBar' : showSelection ? 'selectedSelectionBar' : 'selectionBar' }}"></div>

  <div class="cool" #navItemContainer>
    <!-- ngIf with then used so the container can be re-used -->
    <div *ngIf="editTitle; then editTitleField else body"></div>
  </div>
</div>

<ng-template #body>
  <ng-container *ngIf="!navigationItem.showAsInfoItem;else infoItem">
  <div class="item-container flexBoxMinimumSizing">
    <!-- the add new icon -->
    <div *ngIf="navigationItem.isNew"
      class="icon-container"
      [style.height.px]="iconHeight"
      [style.padding]="frontIconPadding">
      <tun-plus-icon color="#FFFFFF"></tun-plus-icon>
    </div>

    <!-- the favorite icon -->
    <div *ngIf="navigationItem.isFavorite"
      class="icon-container"
      [style.height.px]="iconHeight"
      [style.padding]="frontIconPadding">
      <tun-thumb-up-icon></tun-thumb-up-icon>
    </div>

    <div class="flexBoxMinimumSizing navigationItemText" tunScrollOverflow>
      <span *ngIf="navigationItem.translateableTitlePrefix != ''" class="prefixTitle">{{ navigationItem.translateableTitlePrefix | translate }}</span>
      <span class="testje {{navigationItem.showTitleAsLink ? 'underline' : ''}}">{{ navigationItem.title$ | async }}</span>
    </div>

    <!-- arrow for orange -->
    <div
      *ngIf="tunifyColor === 'Orange' && mainNavigationItemIndex !== 0"
      class="icon-container arrow-icon-container"
      [style.height.px]="iconHeight"
      [style.padding]="iconPadding"
    >
      <tun-back-arrow-icon></tun-back-arrow-icon>
    </div>

    <!-- arrow for link items-->
    <div
      *ngIf="navigationItem.showTitleAsLink"
      class="icon-container arrow-icon-container"
      [style.height.px]="iconHeight"
      [style.padding]="iconPadding"
    >
      <tun-back-arrow-icon></tun-back-arrow-icon>
    </div>


  </div>


  <div *ngIf="showEditAndDelete" class="icons">
    <!-- [style.flex-basis.px]="iconHeight" -->
    <div
      [style.height.px]="iconHeight"
      [style.width.px]="iconHeight"
      (click)="toggleEditTitle($event)">
      <tun-edit-icon></tun-edit-icon>
    </div>
    <div
      [style.height.px]="iconHeight"
      [style.width.px]="iconHeight"
      style.color="#FFFFFF"
      (click)="onDelete($event)">
      <tun-delete-icon></tun-delete-icon>
    </div>
  </div>
</ng-container>
</ng-template>

<ng-template #infoItem>
  <div class="item-container flexBoxMinimumSizing">

    <!-- the info icon -->
    <div
      class="icon-container info-icon"
      [style.height.px]="iconHeight"
      [style.padding]="frontIconPadding">
      <tun-info-icon color="#FFFFFF"></tun-info-icon>
    </div>

    <div class="flexBoxMinimumSizing navigationItemText infoText">
      <span>{{ navigationItem.translateableInfoText | translate }}</span>
    </div>

  </div>
</ng-template>

<ng-template #editTitleField>
  <!-- edit title mode -->
  <input *ngIf="editTitle" #titleInput class="titleLabel edit" [value]="(navigationItem.title$ | async)" (blur)="toggleEditTitle($event)" (focus)="onFocus()" (keyup.enter)="onPressEnter(titleInput.value)"/>
</ng-template>
