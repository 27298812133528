import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tun-copy-icon',
  templateUrl: './copy-icon.component.html',
  styleUrls: ['./copy-icon.component.scss']
})
export class CopyIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
