<div class="confirmPopup">

    <div mat-dialog-content class="content-container">

      <!-- header -->
      <tun-popup-header
        (close)="onCancelClick()"
        [isInMatDialog]="'true'">
          {{ 'popup.authorRights.title' | translate }}
      </tun-popup-header>

        <div
          class="text-container"
          translate>
            popup.authorRights.info
        </div>

        <div class="text-container">
            SABAM ID: {{sabamId$ | async}}
         </div>

    </div>

  </div>

