import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Renderer2,
  OnDestroy
} from '@angular/core';
import { ObservableComponent } from '@components/app-components/observable-component/observable-component';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';

@Directive({
  selector: '[tunThemed]'
})
export class ThemedDirective implements AfterViewInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  constructor(
    private _elementRef: ElementRef,
    private _activeMusicSelectionService: ActiveMusicSelectionService,
    private _renderer2: Renderer2,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this._activeMusicSelectionService.selectedPlayerView$
      .pipe(takeUntil(this._destroy$))
      .subscribe(selectedPlayerView => {
        if (this._elementRef && selectedPlayerView) {
          this._renderer2.addClass(
            this._elementRef.nativeElement,
            this._getThemeClass(selectedPlayerView)
          );
          this._changeDetectorRef.detectChanges();
        }
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
  }

  private _getThemeClass(tunifyColor: TunifyColor) {
    switch (tunifyColor) {
      case TunifyColor.BLUE:
        return 'theme-blue';
      case TunifyColor.GREEN:
        return 'theme-green';
      case TunifyColor.ORANGE:
        return 'theme-orange';
      default:
        return null;
    }
  }
}
