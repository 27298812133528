import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Playlist } from '@model/playlist';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { PlaylistService } from '@service/playlist.service';
import { tap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { AddToListSelection } from '@components/popups/popup/interfaces';
import { ListOption } from '@components/popups/popup/interfaces';

@Component({
  selector: 'tun-add-song-to-list',
  templateUrl: './add-song-to-list.component.html',
  styleUrls: ['./add-song-to-list.component.scss']
})
export class AddSongToListComponent {
  // === Props === //
  @Input() favorites: Playlist;
  @Input() playlists: Playlist[];
  @Input() status: AsyncStatus;

  // === Emitters === //
  @Output() public addtolist = new EventEmitter<AddToListSelection>();

  constructor() {}

  // === Events handlers === //
  onListOptionClick(index: number) {
    this.addtolist.emit({
      listOption: this.convertIndexToListOption(index)
    });
  }

  onSelectPlaylist(playlist: Playlist) {
    this.addtolist.emit({
      listOption: this.convertIndexToListOption(
        playlist.id === this.favorites.id ? 2 : 1
      ),
      playlist
    });
  }

  private convertIndexToListOption(index: number) {
    return ListOption[ListOption[index]];
  }

  onNewPlaylist(playlist: Playlist) {
    this.addtolist.emit({
      listOption: ListOption.NEW_LIST, playlist
    });
  }
}
