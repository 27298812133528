<tun-menu-stream-panel
  [heightForHeader]="heightForHeader$ |async"
  [heightPerItem]="heightPerItem$ |async"
  [style.width.px]="widthForMenuPanel$ |async"
  class="blackRightBorder"
>
</tun-menu-stream-panel>

<div fxFlex class="layerContainer flexBoxMinimumSizing">
  <tun-mid-stream-panel class="layer blackRightBorder"></tun-mid-stream-panel>

  <tun-track-list
    *ngIf="showTrackList"
    class="layer blackRightBorder"
    [showSuggestion]="showSuggestionInTrackList"
    [loading]="trackListLoading"
    [title]="trackListTitle"
    [trackList]="trackList"
    (close)="onCloseSearch()">
  </tun-track-list>
</div>
