import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { PopupPosition } from '@components/popups/popup/enums';
import {
  CalendarDropdownPopupContent,
  PopupContent
} from '@components/popups/popup/interfaces';
import { faCaretDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { CalendarItemDropEvent } from '@model/fieldModels/calendarItemDropEvent';
import { MusicChannel } from '@model/musicChannel';
import { MusicChannelGroup } from '@model/musicChannelGroup';
import { MusicCollection } from '@model/musicCollection';
import { AppService } from '@service/app.service';
import { DragDropService } from '@service/drag-drop.service';
import { MusicChannelService } from '@service/music-channel.service';
import { PopupService } from '@service/popup.service';
import { fromEvent, Subject } from 'rxjs';
import { filter, take, takeUntil, takeWhile } from 'rxjs/operators';
import {
  DropdownMenuComponent,
  ItemSelect,
  DropdownMenuItem
} from './dropdown-menu/dropdown-menu.component';
import { PlaylistService } from '@service/playlist.service';
import { TranslateService } from '@ngx-translate/core';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { musicChannelGroups } from '@service/mock/musicChannelGroups';
import { LoggerService } from '@service/loggers/logger.service';
import { SubscriptionsService } from '../../../../services/data/subscriptions.service';

export class CalendarDropDownSelectData {
  musicChannel: MusicChannel;
  musicCollection: MusicCollection;
}

export interface DropdownEvent {
  showDropdown: boolean;
  dropdown: ElementRef;
}

@Component({
  selector: 'tun-calendar-dropdown',
  templateUrl: './calendar-dropdown.component.html',
  styleUrls: ['./calendar-dropdown.component.scss']
})
export class CalendarDropdownComponent
  implements OnInit, OnDestroy, AfterViewInit {

  private readonly LOGGER_CLASSNAME = "CalendarDropdownComponent";

  // === Props === //
  /** Time indication on the dropdown */
  @Input() time = 23.5;
  /** Top offset of the dropdown field */
  @Input() top: number;
  @Input() position: number;

  // === State === //
  private _heightPerItem: number;
  private _showTime: boolean;
  private _isDraggingOver = false;

  private _firstLevelItems: DropdownMenuItem[] = [];
  private _secondLevelItems: DropdownMenuItem[] = [];
  private _thirdLevelItems: DropdownMenuItem[] = [];
  private _dropdownMenu: CalendarDropdownPopupContent;

  // === Emitters === //
  @Output() public dropdown = new EventEmitter<DropdownEvent>();

  // === ViewChild(ren) === //
  @ViewChild('dropdownField') dropdownField: ElementRef;

  // === Getters === //
  public get faCaretDown(): IconDefinition {
    return faCaretDown;
  }
  public get showTime(): boolean {
    return Number.isInteger(this.time) || this._showTime;
  }

  public get fieldWidth(): number {
    return this.dropdownField.nativeElement.offsetWidth;
  }
  public get heightPerItem(): number {
    return this._heightPerItem;
  }
  public get timeInMilliseconds(): number {
    return this.time * 3600000;
  }
  public get showDropdown(): boolean {
    return !!this._dropdownMenu;
  }
  public get isDraggingOver(): boolean {
    return this._isDraggingOver;
  }

  // === Event emitters === //
  @Output() public dropdownitemselect = new EventEmitter<
    CalendarDropDownSelectData
  >();
  @Output() calendarItemDrop = new EventEmitter<CalendarItemDropEvent>();

  // === Observables === //
  private _destroy$ = new Subject();
  public itemSelect$ = new Subject<ItemSelect>();

  constructor(
    private _musicChannelService: MusicChannelService,
    private _playlistService: PlaylistService,
    private subscriptionsService: SubscriptionsService,
    private _appService: AppService,
    private _ngZone: NgZone,
    private _dragDropService: DragDropService,
    private _cdRef: ChangeDetectorRef,
    private _popupService: PopupService,
    private _translateService:TranslateService,
    private _loggerService: LoggerService
  ) {
    this.itemSelect$
      .pipe(takeUntil(this._destroy$))
      .subscribe(({ menuItem, dropdownItemsIndex }) => {
        this.onItemSelect(menuItem, dropdownItemsIndex);
        if (this._dropdownMenu) {
          this._dropdownMenu.menuItems = [
            this._firstLevelItems,
            this._secondLevelItems,
            this._thirdLevelItems
          ];
        }
      });
  }

  private myListsDropdownMenuItem: DropdownMenuItem;
  ngOnInit() {

    this.myListsDropdownMenuItem = new DropdownMenuItem();
    this.myListsDropdownMenuItem.item = {};
    this.myListsDropdownMenuItem.hasSubMenu = true;
    this._translateService.get("customPlaylists.title")
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        value => this.myListsDropdownMenuItem.title = value
      );

    this._musicChannelService.musicChannelGroups$
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => this.createBaseItems()
      );

    this.subscriptionsService.accessRights$
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => this.createBaseItems()
      );

    this._playlistService.favorites$
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          if (this.selectedFirstLevelItem === this.myListsDropdownMenuItem){
            this.createSecondLevelItems();
          }
        }
      );

    this._playlistService.playlists$
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          if (this.selectedFirstLevelItem === this.myListsDropdownMenuItem){
            this.createSecondLevelItems();
          }
        }
      );

    this._appService.heightPerItem$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this._heightPerItem = value));
  }

  private createBaseItems(){

    let dropdownMenuItems = (this._musicChannelService.musicChannelGroups || []).map(
      musicChannelGroup => {
        let dropdownMenuItem = new DropdownMenuItem();
        dropdownMenuItem.item = musicChannelGroup;
        dropdownMenuItem.title = musicChannelGroup.name;
        dropdownMenuItem.hasSubMenu = true;
        return dropdownMenuItem;
      }
    );

    //add 'my lists'
    if (this.subscriptionsService.accessRights && this.subscriptionsService.accessRights.customPlaylists){
      dropdownMenuItems.push(this.myListsDropdownMenuItem);
    }
    this._firstLevelItems = dropdownMenuItems;
  }

  ngAfterViewInit() {
    this._ngZone.runOutsideAngular(() => {
      this.dropdownField.nativeElement.addEventListener(
        'dragenter',
        this.onDragEnter
      );
      this.dropdownField.nativeElement.addEventListener(
        'dragleave',
        this.onDragLeave
      );
      this.dropdownField.nativeElement.addEventListener('drop', this.onDrop);
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
    this._closeDropdown();

    this._ngZone.runOutsideAngular(() => {
      this.dropdownField.nativeElement.removeEventListener(
        'dragenter',
        this.onDragEnter
      );
      this.dropdownField.nativeElement.removeEventListener(
        'dragleave',
        this.onDragLeave
      );
      this.dropdownField.nativeElement.removeEventListener('drop', this.onDrop);
    });
  }

  public toggleDropdown(showDropdown: boolean): void {
    this.dropdown.emit({ showDropdown, dropdown: this.dropdownField });
    if (showDropdown) {
      this._popupService.showPopup$.next({
        connector: this.dropdownField,
        componentType: DropdownMenuComponent,
        popupPosition: PopupPosition.BOTTOM_CENTER_THEN_TOP,
        animationDuration: 300,
        dynamicPosition: true
      });

      this._popupService.currentInstance$
        .pipe(
          take(1),
          filter(
            (instance: PopupContent) =>
              instance && instance.connectedElementRef === this.dropdownField
          ),
          takeUntil(this._destroy$)
        )
        .subscribe((instance: CalendarDropdownPopupContent) => {
          instance.width = this.fieldWidth;
          instance.menuItems = [
            this._firstLevelItems,
            this._secondLevelItems,
            this._thirdLevelItems
          ];
          instance.itemSelect$ = this.itemSelect$;
          this._dropdownMenu = instance;
          fromEvent(document, 'click')
            .pipe(
              filter(
                ({ target }) =>
                  !this.dropdownField.nativeElement.contains(target as Node) &&
                  !this._dropdownMenu.containsNode(target as Node)
              ),
              takeWhile(() => this._dropdownMenu !== null),
              takeUntil(this._destroy$),
            )
            .subscribe(() => {
              this._closeDropdown();
            });
        });
    } else {
      this._closeDropdown();
    }
  }

  // === Event Handlers === //
  public onFieldHover(): void {
    this._showTime = true;
  }

  public onFieldLeaveHover(): void {
    this._showTime = false;
  }
  private selectedFirstLevelItem: DropdownMenuItem;
  private selectedMusicChannel: MusicChannel;
  public onItemSelect(dropdownMenuItem: DropdownMenuItem, level:number): void {
    if (dropdownMenuItem.item instanceof MusicChannelGroup){ //musicChannelGroups are always first level
      this.selectedFirstLevelItem = dropdownMenuItem;
      this.createSecondLevelItems();
      this._thirdLevelItems = [];
      this.selectedMusicChannel = null;
    }else if (dropdownMenuItem.item instanceof MusicChannel){ //musicChannels are always second level
      this._thirdLevelItems = dropdownMenuItem.item.musicCollections.map(
        musicCollection =>  this.createDropdownMenuItemForMusicCollection(musicCollection)
      );
      this.selectedMusicChannel = dropdownMenuItem.item;
    }else if (dropdownMenuItem === this.myListsDropdownMenuItem){ //my lists item is always first level
      this.selectedFirstLevelItem = dropdownMenuItem;
      this.createSecondLevelItems();
      this._thirdLevelItems = [];
      this.selectedMusicChannel = null;
    }else if (dropdownMenuItem.item instanceof MusicCollection){ //always last level (for playlist: second level but still last / for musicChannelGroups: third level and last)
      let calendarDropDownSelectData = new CalendarDropDownSelectData();
      calendarDropDownSelectData.musicChannel = this.selectedMusicChannel;
      calendarDropDownSelectData.musicCollection = dropdownMenuItem.item;
      this.dropdownitemselect.emit(calendarDropDownSelectData);
      this._popupService.hidePopup$.next(this.dropdownField);
    }
  }

  private createDropdownMenuItemForMusicCollection(musicCollection: MusicCollection): DropdownMenuItem{
    let dropdownMenuItem = new DropdownMenuItem();
    dropdownMenuItem.item = musicCollection;
    dropdownMenuItem.title = musicCollection.title;
    dropdownMenuItem.hasSubMenu = false;
    return dropdownMenuItem;
  }

  //called when selecting a first level item OR when the first level item is my lists and the service emits new playlists / favorites
  private createSecondLevelItems(){
    if (this.selectedFirstLevelItem){
      if (this.selectedFirstLevelItem.item instanceof MusicChannelGroup){
        this._secondLevelItems = this.selectedFirstLevelItem.item.musicChannels.map(
          musicChannel =>  {
            let dropdownMenuItem = new DropdownMenuItem();
            dropdownMenuItem.item = musicChannel;
            dropdownMenuItem.title = musicChannel.name;
            dropdownMenuItem.hasSubMenu = true;
            return dropdownMenuItem;
          });
      }else if (this.selectedFirstLevelItem == this.myListsDropdownMenuItem){
        this._secondLevelItems = (this._playlistService.playlists || []).map(
          playlist =>  this.createDropdownMenuItemForMusicCollection(playlist)
        );
        if (this._playlistService.favorites){
          let favItem = this.createDropdownMenuItemForMusicCollection(this._playlistService.favorites);
          favItem.isFavorite = true;
          this._secondLevelItems.unshift(favItem);
        }
      }else{
        this._loggerService.error(this.LOGGER_CLASSNAME, "createSecondLevelItems", "selected firstlevel item not recognized");
        this._secondLevelItems = [];
      }
    }else{
      this._secondLevelItems = [];
    }
  }

  onDragEnter = (event: DragEvent) => {
    event.stopPropagation();
    if (this._dragDropService.draggingCalendarItem === null) {
      return;
    }

    this._isDraggingOver = true;
    this._cdRef.detectChanges();
  };

  onDragLeave = (event: DragEvent) => {
    event.stopPropagation();
    if (this._dragDropService.draggingCalendarItem === null) {
      return;
    }

    this._isDraggingOver = false;
    this._cdRef.detectChanges();
  };

  onDrop = (event: DragEvent) => {
    event.stopPropagation();
    if (this._dragDropService.draggingCalendarItem === null) {
      return;
    }
    event.preventDefault();

    this._isDraggingOver = false;

    const dragDropEvent = new CalendarItemDropEvent();
    dragDropEvent.setCalendarItem(this._dragDropService.draggingCalendarItem);
    dragDropEvent.setHour((Math.floor(this.time) + 1) % 24);
    dragDropEvent.setMinutes(Number.isInteger(this.time) ? 0 : 30);
    dragDropEvent.setPosition(this.position);
    this.calendarItemDrop.emit(dragDropEvent);

    this._cdRef.detectChanges();
  };

  private _closeDropdown() {
    this._popupService.hidePopup$.next(this.dropdownField);
    if (this._dropdownMenu) {
      this._dropdownMenu.hideSubmenu();
    }
    this._dropdownMenu = null;
  }
}
