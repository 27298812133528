import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import {
  PopupContent,
  TooltipPopupContent
} from '@components/popups/popup/interfaces';
import {
  TooltipAlignment,
  TooltipComponent
} from '@components/popups/tooltip/tooltip.component';
import { CalendarService } from '@service/calendar.service';
import { PopupService } from '@service/popup.service';
import { SearchService } from '@service/search.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { BehaviorSubject, Subject, merge } from 'rxjs';
import {
  filter,
  take,
  takeUntil,
  tap,
  mapTo,
  distinctUntilChanged
} from 'rxjs/operators';
import { ObservableComponent } from '@components/app-components/observable-component/observable-component';
import { Day } from '@model/day';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { Calendar } from '@model/calendar';
import { LoggerService } from '@service/loggers/logger.service';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';

enum CalendarTooltipState{
  None,
  StartEdit,
  NoEdit,
  CloseEdit
}

@Component({
  selector: 'tun-calendar-content-header',
  templateUrl: './calendar-content-header.component.html',
  styleUrls: ['./calendar-content-header.component.scss']
})
export class CalendarContentHeaderComponent extends ObservableComponent
  implements OnInit, OnDestroy {
  // === Constants === //
  private readonly EDIT_CALENDAR_TEXT = 'calendar.header.edit.tooltip';
  private readonly CLOSE_EDIT_CALENDAR_TEXT = 'calendar.header.closeEdit.tooltip';
  private readonly CANNOT_EDIT_CALENDAR_TEXT = 'calendar.header.notEditable.tooltip';
  private readonly MAKE_COPY_TEXT = 'calendar.header.notEditable.tooltipAction';

  private LOGGER_CLASSNAME = 'CalendarContentHeaderComponent';

  // === Props === //
  @Input() hoverColor = 'grey-8';
  @Input() currentIndex: number;
  @Input() selectedCalendar: Calendar;
  @Input() calendarLoaded: boolean;
  @Input() inEditMode: boolean;
  @Input() isCalendarEditable: boolean;
  @Input() set heightPerItem(value: number) {
    this._heightPerItem = value;
    this._iconHeight = value / 2 - 1;
    this._iconPadding = this._sanitizer.bypassSecurityTrustStyle(
      `calc(${value}px / 4) 0.75rem`
    );
    this._clockIconHeight = Math.floor(value * 0.4) - 1;
    const paddingForClock = (value - this._clockIconHeight) / 2;
    this._clockIconPadding = this._sanitizer.bypassSecurityTrustStyle(
      `${paddingForClock}px 0.75rem`
    );
  }

  // === State === //
  private _heightPerItem = 40;
  private _iconHeight: number;
  private _iconPadding: SafeStyle;
  // clock is a large icon, we need to make it a bit smaller than other icons
  private _clockIconHeight: number;
  private _clockIconPadding: SafeStyle;

  private _calendarTooltipState = CalendarTooltipState.None;
  private set calendarTooltipState(state: CalendarTooltipState){
    this._calendarTooltipState = state;
    this.adjustTooltip();
  }
  private get calendarTooltipState(){
    return this._calendarTooltipState;
  }

  // === Emitters === //
  @Output() editmode = new EventEmitter<boolean>();
  @Output() scrollToCurrentHour = new EventEmitter();
  @Output() daychange = new EventEmitter<number>();

  // === Getters === //
  get heightPerItem(): number {
    return this._heightPerItem;
  }
  get iconHeight(): number {
    return this._iconHeight;
  }
  get iconPadding(): SafeStyle {
    return this._iconPadding;
  }
  get clockIconHeight(): number {
    return this._clockIconHeight;
  }
  get clockIconPadding(): SafeStyle {
    return this._clockIconPadding;
  }

  get editButtonTooltipPositions(): ConnectedPosition[] {
    return [
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top'
      }
    ];
  }

  // === ViewChildren === //
  @ViewChild('editButtonElement')
  editButtonElement: ElementRef;

  constructor(
    private _sanitizer: DomSanitizer,
    private _popupService: PopupService,
    private _activeMusicSelectionService: ActiveMusicSelectionService,
    private _calendarService: CalendarService,
    private _searchService: SearchService,
    private _loggerService: LoggerService
  ) {
    super();
  }

  ngOnInit() {
    this._activeMusicSelectionService.selectedCalendar$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.editmode.emit(false);
      });

    this._popupService.currentInstance$
      .pipe(
        takeUntil(this.destroy$),
        filter(
          (instance: PopupContent) =>
            instance && this.connectedElementRef != null && instance.connectedElementRef === this.connectedElementRef
        )
      )
      .subscribe((instance: TooltipPopupContent) => {
        instance.text = this.toolTipText;
        instance.alignment = this.tooltipAlignment;
        instance.tooltipAction = this.tooltipAction;
      });


    merge(
      this._searchService.searchStarts$.pipe(mapTo(true)),
      this._searchService.closeSearch$.pipe(mapTo(false))
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(startSearch => {
        if (this.inEditMode) {
          if (startSearch) {
            this.calendarTooltipState = CalendarTooltipState.None;
          } else {
            this.calendarTooltipState = CalendarTooltipState.CloseEdit;
          }
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.calendarTooltipState = CalendarTooltipState.None;
    
  }

  // If the clock icon has been clicked, everything needs to be reset. It also calls a function that makes the view scroll
  // to the current hour of the day.
  goBackToCurrentDayAndHour() {
    this.scrollToCurrentHour.emit(null);
  }

  toggleEditMode() {
    if (this.isCalendarEditable) {
      if (!this.inEditMode) {
        this.calendarTooltipState = CalendarTooltipState.CloseEdit;
      } else {
        this.calendarTooltipState = CalendarTooltipState.None;
      }
      this.editmode.emit(!this.inEditMode);
    } else {
      this.toggleTooltip(true);
    }
  }

  toggleTooltip(showTooltip: boolean) {
    if (!this.inEditMode) {
      if (showTooltip) {

        if (this.isCalendarEditable){
          this.calendarTooltipState = CalendarTooltipState.StartEdit;
        }else{
          this.calendarTooltipState = CalendarTooltipState.NoEdit;
        }
      } else {
        this.calendarTooltipState = CalendarTooltipState.None;
      }
    }
  }

  makeEditableCopy = () => {
    //the calendar reference we need to copy
    let calendarToCopy = this._activeMusicSelectionService.selectedCalendar;

    this._calendarService.copyCalendar(
      calendarToCopy
    ).pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      (asyncStatusWithData) => {
        if (asyncStatusWithData){
          if  (asyncStatusWithData.asyncStatus == AsyncStatus.COMPLETED){
            if (asyncStatusWithData.data && asyncStatusWithData.data instanceof Calendar){
              //when a calendar is created, we also select it
              this._activeMusicSelectionService.changeActiveCalendar(asyncStatusWithData.data);
            }else{
              this._loggerService.error(this.LOGGER_CLASSNAME, "makeEditableCopy", "Successfully created a copy of a calendar, but the created object could not be found -> not going to select in the menu");
            }
          }
        }
      }
    )
  };

  onDayChange(days: number) {
    this.daychange.emit(days);
  }

  private showCloseEditTooltip() {
    this.calendarTooltipState = CalendarTooltipState.CloseEdit;
  }


  //keep track of the connectedElementRef so we can correctly close & check if the tooltip is currently open
  private connectedElementRef: ElementRef = null;
  private currentShowingCalendarTooltipState: CalendarTooltipState = CalendarTooltipState.None;
  private adjustTooltip(){
    if (this.calendarTooltipState != this.currentShowingCalendarTooltipState){
      
      //hide the current popup if one is showing
      if (this.connectedElementRef){
        this._popupService.hidePopup$.next(this.connectedElementRef);
        this.connectedElementRef = null;
      }
      this.currentShowingCalendarTooltipState = CalendarTooltipState.None;
      
      //show a new tooltip if we need to show one
      if (this.calendarTooltipState != CalendarTooltipState.None){

        if (this.editButtonElement != null){

          this.currentShowingCalendarTooltipState = this.calendarTooltipState;
          this.connectedElementRef = this.editButtonElement;

          this._popupService.showPopup$.next({
            connector: this.editButtonElement,
            componentType: TooltipComponent,
            popupPosition: this.popupPosition,
            popupDirection: this.popupDirection,
            showArrow: true
          });
        }else{
          //todo -> view not ready to show a tooltip!
        }
      }
    }
  }

  private get popupPosition(){
    if (this.calendarTooltipState == CalendarTooltipState.StartEdit){
      return PopupPosition.BOTTOM_RIGHT;
    }else if (this.calendarTooltipState == CalendarTooltipState.NoEdit){
      return PopupPosition.RIGHT;
    }else if (this.calendarTooltipState == CalendarTooltipState.CloseEdit){
      return PopupPosition.TOP_RIGHT
    }
    
    this._loggerService.error(this.LOGGER_CLASSNAME, "popupPosition", "tooltipState not recognized -> going for default position");
    return PopupPosition.BOTTOM_RIGHT;
  }

  private get popupDirection(){
    if (this.calendarTooltipState == CalendarTooltipState.StartEdit){
      return PopupDirection.DOWN;
    }else if (this.calendarTooltipState == CalendarTooltipState.NoEdit){
      return PopupDirection.RIGHT;
    }else if (this.calendarTooltipState == CalendarTooltipState.CloseEdit){
      return PopupDirection.UP;
    }
    
    this._loggerService.error(this.LOGGER_CLASSNAME, "popupDirection", "tooltipState not recognized -> going for default direction");
    return PopupDirection.DOWN;
  }

  private get toolTipText(){
    if (this.calendarTooltipState == CalendarTooltipState.StartEdit){
      return this.EDIT_CALENDAR_TEXT;
    }else if (this.calendarTooltipState == CalendarTooltipState.NoEdit){
      return this.CANNOT_EDIT_CALENDAR_TEXT;
    }else if (this.calendarTooltipState == CalendarTooltipState.CloseEdit){
      return this.CLOSE_EDIT_CALENDAR_TEXT
    }
    
    this._loggerService.error(this.LOGGER_CLASSNAME, "toolTipText", "tooltipState not recognized -> no text");
    return "";
  }

  private get tooltipAlignment(){
    if (this.calendarTooltipState == CalendarTooltipState.StartEdit){
      return TooltipAlignment.CENTER;
    }else if (this.calendarTooltipState == CalendarTooltipState.NoEdit){
      return TooltipAlignment.LEFT;
    }else if (this.calendarTooltipState == CalendarTooltipState.CloseEdit){
      return TooltipAlignment.CENTER;
    }
    
    this._loggerService.error(this.LOGGER_CLASSNAME, "tooltipAlignment", "tooltipState not recognized -> default alignment");
    return TooltipAlignment.LEFT;
  }

  private get tooltipAction(){
    if (this.calendarTooltipState == CalendarTooltipState.NoEdit){
      return { action: this.makeEditableCopy, text: this.MAKE_COPY_TEXT };
    }
    return null;
  }

  



}
