<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M15.589,13.128l-2.183-2.174c0.585-0.907,0.929-1.985,0.929-3.144c0-3.209-2.601-5.81-5.81-5.81
	c-3.209,0-5.81,2.601-5.81,5.81c0,3.209,2.601,5.81,5.81,5.81c1.039,0,2.011-0.276,2.855-0.753l2.245,2.235
	c0.541,0.539,1.424,0.543,1.97-0.005C16.137,14.553,16.135,13.671,15.589,13.128z M13.335,7.81c0,2.652-2.158,4.81-4.81,4.81
	s-4.81-2.158-4.81-4.81S5.872,3,8.524,3S13.335,5.158,13.335,7.81z"
  />
</svg>
