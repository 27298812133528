import { Subject, BehaviorSubject } from 'rxjs';
import { EntityInterface } from '@rebolon/json-reviver';
import { ExternalApplicationInfo } from '../services/data/vo/remote/remote-objects';

//external application info
export class ExternalApplicationsInfo{

  private _externalApplicationsInfoSubject = new BehaviorSubject<ExternalApplicationInfo[]>(null);
  externalApplicationsInfo$ = this._externalApplicationsInfoSubject.asObservable();
  set externalApplicationsInfo(value: ExternalApplicationInfo[]){
    this._externalApplicationsInfoSubject.next(value);
  }
  get externalApplicationsInfo():ExternalApplicationInfo[]{
    return this._externalApplicationsInfoSubject.value;
  }
}

export class ZoneConnection implements EntityInterface{

  //information on the external connected applications -> filled in by the zonePresenceService
  public externalApplicationsInfo = new ExternalApplicationsInfo();

  //events when loading an accessToken is done, with a boolean indicating a success (true = success, false = failure)
  private loadAccessTokenDoneSource = new Subject<boolean>();
  loadAccessTokenDone$ = this.loadAccessTokenDoneSource.asObservable();
  public emitLoadAccessTokenDone(value: boolean) {
    this.loadAccessTokenDoneSource.next(value);
  }

  //events when loading the zone info is done, with a boolean indicating a success (true = success, false = failure)
  private loadZoneInfoDoneSource = new Subject<boolean>();
  loadZoneInfoDone$ = this.loadZoneInfoDoneSource.asObservable();
  public emitLoadZoneInfoDone(value: boolean) {
    this.loadZoneInfoDoneSource.next(value);
  }

  //loading states
  private _loadingAccessTokenSubject = new BehaviorSubject<boolean>(false);
  loadingAccessToken$ = this._loadingAccessTokenSubject.asObservable();
  set loadingAccessToken(value: boolean){
    this._loadingAccessTokenSubject.next(value);
  }
  get loadingAccessToken():boolean{
    return this._loadingAccessTokenSubject.value;
  }

  private _loadingZoneInfoSubject = new BehaviorSubject<boolean>(false);
  loadingZoneInfo$ = this._loadingZoneInfoSubject.asObservable();
  set loadingZoneInfo(value: boolean){
    this._loadingZoneInfoSubject.next(value);
  }
  get loadingZoneInfo():boolean{
    return this._loadingZoneInfoSubject.value;
  }

  private _showSubscriptionLinkSubject = new BehaviorSubject<boolean>(false);
  showSubscriptionLink$ = this._showSubscriptionLinkSubject.asObservable();
  set showSubscriptionLink(value: boolean){
    this._showSubscriptionLinkSubject.next(value);
  }
  get showSubscriptionLink():boolean{
    return this._showSubscriptionLinkSubject.value;
  }


  //basic info -> when a connection is made, these settings are first loaded
  zoneId: number;
  refreshToken: string;

  //error info on our current session -> this is not saved. It is used so we can show the correct message with the correct zone
  private _errorMessageSubject = new BehaviorSubject<string>(null);
  errorMessage$ = this._errorMessageSubject.asObservable();
  public set errorMessage(value:string){
    this._errorMessageSubject.next(value);
  }
  public get errorMessage():string{
    return this._errorMessageSubject.value;
  }

  //error info on our current session -> this is not saved. It is used so we can show the correct message with the correct zone
  private _errorMessageColorPartSubject = new BehaviorSubject<string>(null);
  errorMessageColorPart$ = this._errorMessageColorPartSubject.asObservable();
  public set errorMessageColorPart(value:string){
    this._errorMessageColorPartSubject.next(value);
  }
  public get errorMessageColorPart():string{
    return this._errorMessageColorPartSubject.value;
  }

  //this is fetched once we have a an accessToken
  name: string;
  locationName: string;
  appFamId: number;
  externalZoneId: string;

  //we temp store these settings until we use zoneCodes to connect
  username: string;
  password: string;

  //Valid connection flag
  //A connection is set to invalid when its refreshToken is not longer valid any longer.
  private _validSubject = new BehaviorSubject<boolean>(true);
  valid$ = this._validSubject.asObservable();
  set valid(value: boolean){
    this._validSubject.next(value);
  }
  get valid():boolean{
    return this._validSubject.value;
  }



  //a setting to know if our validity has been rechecked this session
  //validRecheckedThisSession: boolean = false;
  //last time the validity was checked
  validCheckedDate: Date;
  public needsValidityCheck(): boolean{
    if (this.validCheckedDate == null){
      return true;
    }
    var differenceInTime = new Date().getTime() - this.validCheckedDate.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);
    //check every 3 days
    return differenceInDays > 2;
  }

  //flag to indicate we need to reload the zoneInfo once there is an accessToken.
  needToReloadZoneInfo: Boolean = false;

  //the amount of failed attempts to load a new accessToken. As soon as we reach to much failed attemps the zoneConnection becomes invalid.
  accessTokenRefreshFails = 0;

  //the current active accessToken for this connection
  //this is not stored on disk
  //accessToken: string = null;
  private _accessTokenSubject = new BehaviorSubject<string>(null);
  accessToken$ = this._accessTokenSubject.asObservable();
  set accessToken(value: string){
    this._accessTokenSubject.next(value);
  }
  get accessToken():string{
    return this._accessTokenSubject.value;
  }

  static persistentProperties = [
    "zoneId",
    "refreshToken",
    "name",
    "locationName",
    "appFamId",
    "externalZoneId",
    "username",
    "password",
    "valid",
    "validCheckedDate"
  ];

  // Method that can deserialize JSON into an instance
  static revive = function(data) {

    let zoneConnection = new ZoneConnection();
    zoneConnection.zoneId = data.zoneId;


  return zoneConnection;
};

}
