import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from '@service/app.service';
import { SearchService, SearchData, translatedDescriptionForSearchData } from '@service/search.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { AudioFile } from '@model/audioFile';
import { PlaylistService } from '@service/playlist.service';
import { Playlist } from '@model/playlist';
import { SearchType, SuggestionsType } from '@service/api/search-api.service';
import { Song } from '@model/song';
import { formatTrackProperty, AudioFileProperty } from '@model/enums/audioFileProperty';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../../services/loggers/logger.service';

@Component({
  selector: 'tun-stream-view',
  templateUrl: './stream-view.component.html',
  styleUrls: ['./stream-view.component.scss']
})
export class StreamViewComponent implements OnInit, OnDestroy {

  private LOGGER_CLASSNAME = 'StreamViewComponent';

  // === State === //
  private componentDestroyed$ = new Subject<void>();

  public get heightForHeader$(){
    return this.appService.heightForHeader$;
  }
  public get heightPerItem$(){
    return this.appService.heightPerItem$;
  }
  public get widthForMenuPanel$(){
    return this.appService.widthForMenuPanel$;
  }

  showTrackList = false;
  showSuggestionInTrackList = true;
  trackListLoading = false;
  trackListTitle = '';
  trackList: AudioFile[];

  // how our view is filled with data -> playlist or search
  // the correct subscriptions are active while the view is open

  // the playlist currently in the view
  private _playlist: Playlist;
  private _cancelPlaylistSubscriptions$ = new Subject<void>();
  private set playlist(playlist: Playlist) {
    this._playlist = playlist;

    this._cancelPlaylistSubscriptions$.next(null);
    if (playlist != null) {
      this._showSearch = false; // hide search - cancel subscriptions

      this.trackListTitle = playlist.title;

      // add playlist subscriptions
      playlist.detailsLoading$
        .pipe(
          takeUntil(merge(this.componentDestroyed$, this._cancelPlaylistSubscriptions$))
        )
        .subscribe(
          (loading) => {
            this.trackListLoading = loading;
          }
        );

      playlist.audioFiles$
        .pipe(
          takeUntil(merge(this.componentDestroyed$, this._cancelPlaylistSubscriptions$))
        )
        .subscribe(
          (audioFiles) => {
            this.trackList = audioFiles;
          }
        );

      this.showSuggestionInTrackList = false;
      this.showTrackList = true;
    }
  }

  // show the search result
  private _showSearch = false;
  private _cancelSearchSubscriptions$ = new Subject<void>();
  private set showSearch(value) {
    this._showSearch = value;

    this._cancelSearchSubscriptions$.next(null);
    if (value) {
      this.playlist = null; // hide playlist - cancel subscriptions

      // add search subscriptions
      this.searchService.lastSearch$
        .pipe(
          takeUntil(merge(this.componentDestroyed$, this._cancelPlaylistSubscriptions$))
        )
        .subscribe(
          (data: SearchData) => {
            this.trackListTitle = translatedDescriptionForSearchData(this.translateService, data);
          }
        );

      this.searchService.searchResult$
        .pipe(
          takeUntil(merge(this.componentDestroyed$, this._cancelPlaylistSubscriptions$))
        )
        .subscribe(
          (list: AudioFile[]) => {
            this.trackList = list;
          }
        );

      this.searchService.searching$
        .pipe(
          takeUntil(merge(this.componentDestroyed$, this._cancelPlaylistSubscriptions$))
        )
        .subscribe(
          (searching) => {
            this.trackListLoading = searching;
          }
        );

      this.showSuggestionInTrackList = true;
      this.showTrackList = true;
    }
  }

  constructor(
    private appService: AppService,
    private searchService: SearchService,
    private playlistService: PlaylistService,
    private translateService: TranslateService,
    private loggerService: LoggerService
  ) { }

  ngOnInit() {
    this.searchService.searchStarts$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: SearchData) => {
        this.showSearch = true;
      });

    this.playlistService.showPlaylist$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((playlist: Playlist) => {
        if (playlist) {
          this.playlist = playlist;
        }
      });

    this.searchService.closeSearch$
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        this.showSearch = false;
        this.playlist = null;

        this.showTrackList = false;
        this.trackListTitle = '';
        this.trackList = null;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.componentDestroyed$ = null;

    this._cancelSearchSubscriptions$.complete();
    this._cancelSearchSubscriptions$ = null;

    this._cancelPlaylistSubscriptions$.complete();
    this._cancelPlaylistSubscriptions$ = null;
  }

  onCloseSearch() {
    this.searchService.closeSearch();
  }
}
