import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Calendar } from '@model/calendar';
import { CalendarItem } from '@model/calendarItem';
import { AudioFileOriginType } from '@model/enums/audioFileOriginType';
import { CalendarType } from '@model/enums/calendarType';
import { PlayState } from '@model/enums/playState.enum';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { CalendarItemDropEvent } from '@model/fieldModels/calendarItemDropEvent';
import { CheckBoxColor } from '@model/fieldModels/checkboxColor';
import { MusicCategoryBlockColors } from '@model/fieldModels/musicCategoryBlockColors';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { MusicCollection } from '@model/musicCollection';
import { AudioTagService } from '@service/audio.tag.service';
import { CalendarService } from '@service/calendar.service';
import { DragDropService } from '@service/drag-drop.service';
import { MusicCollectionService } from '@service/music-collection.service';
import { MusicPlayerService } from '@service/music-player.service';
import { PopupService } from '@service/popup.service';
import {
  calculateMaximumDuration,
  calculateMaxStartHour
} from '@service/util/calendarUtil';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import {
  carouselAnimation,
  openCloseCalendarItemTweakPanel
} from '@util/animations';
import {
  fromEvent,
  merge,
  Observable,
  of,
  Subject,
  timer,
  Subscription
} from 'rxjs';
import { delay, switchMap, takeUntil, throttleTime, tap } from 'rxjs/operators';
import { SassHelperComponent } from 'src/providers/sass-helper/sass-helper.component';
import { DayNavigatorComponent } from '../calendar-content-header/day-navigator/day-navigator.component';
import {
  CalendarDropDownSelectData,
  DropdownEvent
} from '@components/app-components/fields/calendar-dropdown/calendar-dropdown.component';
import { getCronStringForDayAndTime } from '@service/util/cronstringConverter';
import { Day } from '@model/day';
import { Context } from '@model/context';
import { createClassObjectForChangeableParameter } from '@service/util/changeableParameterUtil';
import { Playlist } from '@model/playlist';
import {TooltipEvent} from '@components/app-components/fields/calendar-item/calendar-item.component';
import { MusicCollectionEvent, MusicCollectionAction } from '@model/events/musicCollectionEvent';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BulkActionFeedbackBottomSheetComponent } from './bulk-action-feedback-bottom-sheet/bulk-action-feedback-bottom-sheet.component';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { AudioFile } from '../../../../../model/audioFile';
import { ApplicationMode, ZoneConnectionsService } from '../../../../../services/authentication/zone-connections.service';
import { RemotePlayStateService } from '../../../../../services/audio/remote-play-state.service';
import { LoggerService } from '../../../../../services/loggers/logger.service';
import { PlayModeForRemoteSharing } from '../../../../../services/data/vo/remote/remote-objects';

export enum CalendarState{
  normal,
  creating,
  creatingCopy,
  deleting,
  deleted,
  undoingDelete,
  clearingCurrentDay,
  copyingDay,
  loading,
  loadError,
  none
}

@Component({
  selector: 'tun-calendar-content-panel',
  templateUrl: './calendar-content-panel.component.html',
  styleUrls: ['./calendar-content-panel.component.scss'],
  animations: [carouselAnimation, openCloseCalendarItemTweakPanel]
})
export class CalendarContentPanelComponent
  implements OnInit, AfterViewInit, OnDestroy {
  // === Constants === //
  readonly MIN_DURATION = 30;
  readonly CAROUSEL_DURATION =
    DayNavigatorComponent.CAROUSEL_ANIMATION_DURATION;

  private LOGGER_CLASSNAME = 'CalendarContentPanelComponent';

  // === Props === //
  @Input() heightPerItem: number;

  // === State === //
  private _selectedCalendar: Calendar = null;
  private _inEditMode = false;
  private _tweakPanelWidth = 0;
  private _tweakPanelHeight = 0;
  private _showTweakPanel = false;
  private _currentAudioFileFromCalendarItemWithId = 0;
  private _playing: boolean;
  private _direction: 'next' | 'prev';

  // === Observables === //
  private _destroy$ = new Subject();
  public outsideCurrentCalendarItemClick$: Observable<boolean>;

  private dropdownSubscription: Subscription;
  private tooltipSubscription: Subscription;

  // === Emitters === //
  @Output() editmodetoggle = new EventEmitter();

  // === Getters === //
  get tweakPanelWidth(): number {
    return this._tweakPanelWidth;
  }
  get tweakPanelHeight(): number {
    return this._tweakPanelHeight;
  }
  get showTweakPanel(): boolean {
    return this._showTweakPanel;
  }
  get currentAudioFileFromCalendarItemWithId(): number {
    return this._currentAudioFileFromCalendarItemWithId;
  }

  get playing(): boolean {
    return this._playing;
  }
  get inEditMode(): boolean {
    return this._inEditMode;
  }
  get calendarSelected(): boolean {
    return !!this._selectedCalendar;
  }
  get calendarLoaded(): boolean {
    return (
      this._selectedCalendar != undefined &&
      this._selectedCalendar.calendarItemsLoaded
    );
  }
  get daysOfWeek(): Day[] {
    return Day.DAYS;
  }
  get direction(): string {
    return this._direction;
  }
  get isCalendarEditable(): boolean {
    return (
      this.selectedCalendar &&
      this.selectedCalendar.type !== CalendarType.TUNIFY
    );
  }
  get isMouseDown(): boolean {
    return this._dragDropService.isMouseDown;
  }

  get clearingCurrentDay():boolean{
    return this.selectedCalendar && this.selectedCalendar.clearingDay(Day.DAYS[this.currentIndex]);
  }

  //make the enum available in the html template
  public CalendarState = CalendarState;

  get calendarState(): CalendarState{
    if (this.selectedCalendar){
      if (this.selectedCalendar.creating){
        return CalendarState.creating;
      }else if (this.selectedCalendar.creatingCopy){
        return CalendarState.creatingCopy;
      }else if (this.selectedCalendar.deleting){
        return CalendarState.deleting;
      }else if (this.selectedCalendar.undoingDelete){ //must be before deleted
        return CalendarState.undoingDelete;
      }else if (this.selectedCalendar.deleted){
        return CalendarState.deleted;
      }else if (this.clearingCurrentDay){
        return CalendarState.clearingCurrentDay;
      }else if (this.selectedCalendar.copyingDay != null){
        return CalendarState.copyingDay;
      }else if (this.selectedCalendar.calendarItemsLoading){
        return CalendarState.loading;
      }else if (this.selectedCalendar.calendarItemsLoadingError != null){
        return CalendarState.loadError;
      }else if (this.selectedCalendar.calendarItems == null){
        return CalendarState.none;
      }
    }
    return CalendarState.normal;
  }

  // === ViewChildren === //
  @ViewChild(SassHelperComponent, { static: true })
  private sassHelper: SassHelperComponent;
  @ViewChild('calendarpanel')
  private calendarPanel: ElementRef;

  /*
  //Does not work!

  private _calendarPanel: ElementRef;
  @ViewChild('calendarpanel', { static: false })
  set calendarPanel(elRef: ElementRef){
    if (elRef != this._calendarPanel){
      this._calendarPanel = elRef;
      this._scrollToCurrentDate();
    }
  }
  get calendarPanel(): ElementRef{
    return this._calendarPanel;
  }
  */

  hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];
  hoursForDropdown: number[];
  currentIndex: number = null;
  animationIndex = 0;
  currentDayIndex = 0;
  currentHour = 0;
  currentMinutes = 0;
  currentTimeMultiplier = 0;


  tunifyColor: TunifyColor = TunifyColor.GREEN;
  musicPlayAnimationColors: MusicPlayAnimationColors = new MusicPlayAnimationColors();
  selectedMusicCategoryBlockColors: MusicCategoryBlockColors = new MusicCategoryBlockColors();
  unselectedMusicCategoryBlockColors: MusicCategoryBlockColors = new MusicCategoryBlockColors();

  resizeTrigger = false;
  resizeTimeout: NodeJS.Timer;

  selectedCalendarItem: CalendarItem;
  controlsBackgroundColorBefore: String;
  controlsBackgroundColorAfter: String;
  calendarItemHeight: number;
  animationWidth: number;
  animationTop: number;
  animationLeft: number;
  animationDuration = 400;
  maxScrollbarHeight: number;

  checkboxColor: CheckBoxColor = CheckBoxColor.GREEN;

  get pointerEvents(): string {
    return this._dragDropService.isMouseDown ? 'none' : 'all';
  }

  constructor(
    private _zoneConfigurationService: ZoneConfigurationService,
    private _activeMusicSelectionService: ActiveMusicSelectionService,
    private _calendarService: CalendarService,
    private _musicPlayerService: MusicPlayerService,
    private _audioTagService: AudioTagService,
    private _musicCollectionService: MusicCollectionService,
    private _cdRef: ChangeDetectorRef,
    private _dragDropService: DragDropService,
    private _ngZone: NgZone,
    private _popupService: PopupService,
    private _bottomSheet: MatBottomSheet,
    private _viewContainerRef: ViewContainerRef,
    private _zoneConnectionsService: ZoneConnectionsService,
    private _remotePlayStateService: RemotePlayStateService,
    private _loggerService: LoggerService
  ) {
    timer(0, 60000)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.calculateTimeMultiplier();
        if (this.currentIndex === null) {
          this.currentIndex = this.currentDayIndex;
        }
      });


    const hoursWithout23 = this.hours
      .slice(0, this.hours.length - 1)
      .map(hourString => parseInt(hourString, 10));
    this.hoursForDropdown = [
      parseInt(this.hours[this.hours.length - 1], 10),
      ...hoursWithout23
    ];

    this.adjustAutoScroller();

    fromEvent(window, 'resize')
    .pipe(
      takeUntil(this._destroy$)
    )
    .subscribe( () => this.onWindowResize());
  }

  private _autoScrollInterval: NodeJS.Timer;
  private adjustAutoScroller(){
    if (this._autoScrollInterval){
      clearInterval(this._autoScrollInterval);
    }

    this._autoScrollInterval = !this._inEditMode
      ? setInterval(this._scrollToCurrentDate, 60000)
      : null;
  }

  private onWindowResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(
      (() => {
        this.resizeTrigger = !this.resizeTrigger;
        this.maxScrollbarHeight = this.calendarPanel.nativeElement.offsetHeight;
        this.positionTweakPanel();
      }).bind(this),
      100
    );
  }

  @HostListener('click')
  resetAutoScrollInterval() {
    this.adjustAutoScroller();
  }

  ngOnInit() {
    this._activeMusicSelectionService.selectedCalendar$
      .pipe(takeUntil(this._destroy$))
      .subscribe(calendar => {
        this.selectedCalendar = calendar;
      });

    this._musicPlayerService.currentActiveAudioFileWithPlayInfo$
      .pipe(takeUntil(this._destroy$))
      .subscribe(audioFileWithPlayInfo => {
        this.updateCurrentPlayingId();
      });

      this._remotePlayStateService.remoteTrackObject$
    .pipe(
      takeUntil(this._destroy$)
    )
    .subscribe(
      () => {
        this.updateCurrentPlayingId();
      }
    );

    this._zoneConnectionsService.applicationMode$
    .pipe(
      takeUntil(this._destroy$)
    )
    .subscribe(
      () => {
        this.updateCurrentPlayingId();
        this.updatePlaying();
      }
    );

    merge(
      this._musicPlayerService.playerState$,
      this._audioTagService.needClickToStartAudio$
    )
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.updatePlaying());

      this._remotePlayStateService.playMode$
    .pipe(
      takeUntil(this._destroy$)
    )
    .subscribe(
      () => {
        this.updatePlaying();
      }
    );
  }

  private updateCurrentPlayingId() {

    let track = null;

    if (this._zoneConnectionsService.applicationMode == ApplicationMode.playerMode ){
      if (this._musicPlayerService.currentActiveAudioFileWithPlayInfo != null){
        track = this._musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile;
      }
    }else if (this._zoneConnectionsService.applicationMode == ApplicationMode.remoteMode ){
      track = this._remotePlayStateService.remoteTrackObject;
    }

    if (
      track != null &&
      track instanceof AudioFile &&
      track.origin != null &&
      track.origin.type ==
        AudioFileOriginType.CALENDAR_ITEM
    ) {
      const idValue = parseInt(track.origin.data, 10);

      /*
      const dataAsNumber = Number(
        audioFileWithPlayInfo.audioFile.origin.data
      );
      */
      if ( !isNaN(idValue)) {
        this._currentAudioFileFromCalendarItemWithId = idValue;
      } else {
        this._currentAudioFileFromCalendarItemWithId = -1;
        this._loggerService.error(
          this.LOGGER_CLASSNAME,
          'currentAudioFileChanged',
          'Could not convert data to number altough we expect it to be a number: ' + track.origin.data
        );
      }
    } else {
      this._currentAudioFileFromCalendarItemWithId = -1;
    }

    this._cdRef.detectChanges();

  }

  ngAfterViewInit() {
    this.fetchColors();
    this.maxScrollbarHeight = this.calendarPanel.nativeElement.offsetHeight;
    this.calculateTimeMultiplier();
    this._scrollToCurrentDate();
    this._cdRef.detectChanges();

    this._ngZone.runOutsideAngular(() => {
      this.calendarPanel.nativeElement.addEventListener(
        'mouseup',
        this.onMouseUp
      );
      this.calendarPanel.nativeElement.addEventListener(
        'mousemove',
        this.onMouseMove
      );
      document.body.addEventListener('mouseleave', this.onMouseUp);
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$ = null;

    if (this.dropdownSubscription) {
      this.dropdownSubscription.unsubscribe();
      this.dropdownSubscription = null;
    }

    if (this._autoScrollInterval){
      clearInterval(this._autoScrollInterval);
      this._autoScrollInterval = null;
    }


    this._ngZone.runOutsideAngular(() => {
      this.calendarPanel.nativeElement.removeEventListener(
        'mouseup',
        this.onMouseUp
      );
      this.calendarPanel.nativeElement.removeEventListener(
        'mousemove',
        this.onMouseMove
      );
      document.body.removeEventListener('mouseleave', this.onMouseUp);
    });
  }


  private updatePlaying() {
    if (this._zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
      this._playing =
      (this._musicPlayerService.playState === PlayState.PLAYING ||
        this._musicPlayerService.playState === PlayState.STARTING_TO_PLAY) &&
      !this._audioTagService.needClickToStartAudio;
    }else if (this._zoneConnectionsService.applicationMode == ApplicationMode.remoteMode){
      this._playing = this._remotePlayStateService.playMode == PlayModeForRemoteSharing.playing || this._remotePlayStateService.playMode == PlayModeForRemoteSharing.startingPlay;
    }else{
      this._playing = false;
    }
    this._cdRef.detectChanges();
  }



  // If a calendar is selected, all the popups that were open should be closed and the day needs to be reset as well.
  public set selectedCalendar(calendar: Calendar) {
    this._selectedCalendar = calendar;

    if (this.selectedCalendar) {
      // load the calendarItems if they are not yet loading or loaded
      if (
        !this.selectedCalendar.calendarItemsLoaded &&
        !this.selectedCalendar.calendarItemsLoading
      ) {
        this._calendarService.loadCalendarItems(this.selectedCalendar);
      }
    }
  }
  public get selectedCalendar(): Calendar {
    return this._selectedCalendar;
  }

  getCalendarClass(index: number) {
    switch (index) {
      case 0:
        return 'previous';
      case 1:
        return 'current';
      case 2:
        return 'next';
    }
  }

  changeEditMode(isEditMode: boolean) {
    this._inEditMode = isEditMode;
    this.closeTweakPanel();
  }

  calculateTimeMultiplier = () => {
    const now = this._zoneConfigurationService.currentDateTimeForZone;
    this.currentDayIndex = now.getDay() == 0 ? 6 : now.getDay() - 1;
    this.currentHour = now.getHours();
    this.currentMinutes = now.getMinutes();
    this.currentTimeMultiplier = this.currentHour + this.currentMinutes / 60;
  };

  public _scrollToCurrentDate = () => {
    this.currentIndex = this.currentDayIndex;
    this.calendarPanel.nativeElement.scrollTop =
      this.currentTimeMultiplier * this.heightPerItem -
      this.heightPerItem / 2 -
      0.25 * this.calendarPanel.nativeElement.clientHeight;
    // this._popupService.hideAllPopups$.next();
  };

  fetchColors = () => {
    const unselectedCalendarItemColors = new MusicCategoryBlockColors()
      .labelBackgroundGradientStartColor(
        this.sassHelper.readProperty('grey-label-gradient-start')
      )
      .labelBackgroundGradientEndColor(
        this.sassHelper.readProperty('grey-label-gradient-end')
      )
      .labelBackgroundGradientHoverStartColor(
        this.sassHelper.readProperty('grey-label-gradient-start-hover')
      )
      .labelBackgroundGradientHoverEndColor(
        this.sassHelper.readProperty('grey-label-gradient-end-hover')
      )
      .labelBorderColor(this.sassHelper.readProperty('grey-3'))
      .labelBorderRightColor(this.sassHelper.readProperty('grey-11'))
      .iconBackgroundColor(this.sassHelper.readProperty('grey-11'))
      .iconBackgroundHoverColor(this.sassHelper.readProperty('grey-icon-hover'))
      .iconBorderColor(this.sassHelper.readProperty('grey-10'))
      .iconColor('#575B5D')
      .textColor('#AAAAAA');
    this.unselectedMusicCategoryBlockColors = unselectedCalendarItemColors;

    switch (this.tunifyColor) {
      case TunifyColor.GREEN:
        const greenMusicPlayAnimationColors = new MusicPlayAnimationColors()
          .selectedColor('#2C7709')
          .unselectedColor(this.sassHelper.readProperty('grey-8'));
        this.musicPlayAnimationColors = greenMusicPlayAnimationColors;

        const greenSelectedMusicCategoryBlockColors = new MusicCategoryBlockColors()
          .labelBackgroundGradientStartColor('#62C362')
          .labelBackgroundGradientEndColor('#59B259')
          .labelBackgroundGradientHoverStartColor(
            this.sassHelper.readProperty('#6DC76D')
          )
          .labelBackgroundGradientHoverEndColor(
            this.sassHelper.readProperty('#63B764')
          )
          .labelBorderColor('#71C971')
          .labelBorderRightColor('#328517')
          .iconBackgroundColor('#2C7707')
          .iconBackgroundHoverColor('#377E16')
          .iconBorderColor('#64B554')
          .iconColor('#62C362')
          .textColor('#003A00');
        this.selectedMusicCategoryBlockColors = greenSelectedMusicCategoryBlockColors;
        break;
      default:
        const musicPlayAnimationColors = new MusicPlayAnimationColors()
          .selectedColor(this.sassHelper.readProperty('blue-3'))
          .unselectedColor(this.sassHelper.readProperty('grey-8'));
        this.musicPlayAnimationColors = musicPlayAnimationColors;

        const defaultSelectedMusicCategoryBlockColors = new MusicCategoryBlockColors()
          .labelBackgroundGradientStartColor(
            this.sassHelper.readProperty('blue-label-gradient-start')
          )
          .labelBackgroundGradientEndColor(
            this.sassHelper.readProperty('blue-label-gradient-end')
          )
          .labelBackgroundGradientHoverStartColor(
            this.sassHelper.readProperty('blue-label-gradient-start-hover')
          )
          .labelBackgroundGradientHoverEndColor(
            this.sassHelper.readProperty('blue-label-gradient-end-hover')
          )
          .labelBorderColor(this.sassHelper.readProperty('blue-13'))
          .labelBorderRightColor(this.sassHelper.readProperty('blue-12'))
          .iconBackgroundColor(this.sassHelper.readProperty('blue-8'))
          .iconBackgroundHoverColor(
            this.sassHelper.readProperty('blue-icon-hover')
          )
          .iconBorderColor(this.sassHelper.readProperty('blue-10'))
          .iconColor(this.sassHelper.readProperty('blue-11'))
          .textColor(this.sassHelper.readProperty('blue-8'));
        this.selectedMusicCategoryBlockColors = defaultSelectedMusicCategoryBlockColors;
        break;
    }
  };

  positionTweakPanel = () => {
    this._tweakPanelWidth = this.calendarPanel.nativeElement.clientWidth;
    this._tweakPanelHeight = this.calendarPanel.nativeElement.clientHeight;
  };

  openCalendarItemTweakPanel = (item: CalendarItem) => {
    this._musicCollectionService.loadTweakInfo(item);
    this._calendarService.loadCalendarItemDetails(item);
    this.pickConfigurationPanelColors(true);
    this.calculateAnimationProps();
    this.selectedCalendarItem = item;
    this._showTweakPanel = true;
    setTimeout(() => this.positionTweakPanel(), 0);
  };

  onDeleteCalendarItem(calendarItem: CalendarItem){
    this._calendarService.removeCalendarItemFromCalendar(this.selectedCalendar, calendarItem);
  }

  pickConfigurationPanelColors = (isSelected: boolean) => {
    this.controlsBackgroundColorBefore = isSelected ? '#62C362' : '#2A3233';
    this.controlsBackgroundColorAfter = '#62C362';
  };

  closeTweakPanel = () => {
    this._showTweakPanel = false;
    this.selectedCalendarItem = null;
  };

  public onMusicCollectionEvent(musicCollectionEvent: MusicCollectionEvent, calendarItem: CalendarItem) {
    if (musicCollectionEvent.action === MusicCollectionAction.PARAMETERS_CHANGED){
      this._calendarService.adjustParametersForCalendarItem(this.selectedCalendar, calendarItem);
    }else if (musicCollectionEvent.action === MusicCollectionAction.RESET_PARAMETERS){
      this._calendarService.resetParameters(this.selectedCalendar, calendarItem);
    }
  }

  public onShuffleChanged(calendarItem: CalendarItem){
   this._calendarService.saveCalendarItem(this.selectedCalendar, calendarItem);
  }

  calculateAnimationProps = () => {
    const { x, y } = this.calendarPanel.nativeElement.getBoundingClientRect();

    this.calendarItemHeight = 0;
    this.animationWidth = 0;
    this.animationTop = (event as MouseEvent).y - y;
    this.animationLeft = (event as MouseEvent).x - x;
  };

  onCalendarItemDrop = (event: CalendarItemDropEvent) => {
    this._calendarService.moveCalenderItem(
      this.selectedCalendar,
      event.calendarItem,
      event.hour,
      event.minutes,
      event.position
    );
    this._cdRef.detectChanges();
  };

  onMouseUp = () => {
    const wasMouseDown = this._dragDropService.isMouseDown;
    const calendarItemToUpdate =  this._dragDropService.resizingCalendarItem;

    //clean up view states before triggering the service (avoid hanging UI on errors)
    this._dragDropService.isMouseDown = false;
    this._dragDropService.resizingCalendarItem = null;
    this._dragDropService.resizingCalendarItemYcoordinate = 0;
    this._dragDropService.resizingCalendarItemDuration = 0;
    this._dragDropService.resizingCalendarItemStartTime = 0;
    this._dragDropService.resizeFromTop = false;

     //trigger save if we just resized a calendarItem
     if (wasMouseDown && calendarItemToUpdate){
      this._calendarService.adjustCalendarItem(this.selectedCalendar, calendarItemToUpdate);
    }
  };

  onMouseMove = () => {
    if (!this._dragDropService.isMouseDown) {
      return;
    }

    const elementYcoord = this._dragDropService.resizingCalendarItemYcoordinate;
    const eventYcoord = (event as MouseEvent).y;

    if (this._dragDropService.resizeFromTop) {
      const MAX_START_HOUR = calculateMaxStartHour(
        this.selectedCalendar,
        this._dragDropService.resizingCalendarItem
      );

      let preferredStartTime = 0;

      if (eventYcoord <= elementYcoord) {
        preferredStartTime =
          this._dragDropService.resizingCalendarItemStartTime -
          Math.round((elementYcoord - eventYcoord) / (this.heightPerItem / 2)) *
            0.5;
      } else {
        preferredStartTime =
          this._dragDropService.resizingCalendarItemStartTime +
          Math.round((eventYcoord - elementYcoord) / (this.heightPerItem / 2)) *
            0.5;
      }

      const realStartTime = Math.min(
        Math.max(preferredStartTime, MAX_START_HOUR),
        this._dragDropService.resizingCalendarItemStartTime +
          this._dragDropService.resizingCalendarItemDuration / 60 -
          0.5
      );

      const startHour = Math.floor(realStartTime);
      const startMinutes = Number.isInteger(realStartTime) ? 0 : 30;
      const duration =
        (this._dragDropService.resizingCalendarItemStartTime - realStartTime) *
          60 +
        this._dragDropService.resizingCalendarItemDuration;

      this._dragDropService.resizingCalendarItem.startHour = startHour;
      this._dragDropService.resizingCalendarItem.startMinutes = startMinutes;
      this._dragDropService.resizingCalendarItem.duration = duration;

      this._cdRef.detectChanges();
    } else {
      const MAX_DURATION = calculateMaximumDuration(
        this.selectedCalendar,
        this._dragDropService.resizingCalendarItem
      );

      let preferredDuration = 0;

      if (eventYcoord >= elementYcoord) {
        preferredDuration =
          this._dragDropService.resizingCalendarItemDuration +
          Math.round((eventYcoord - elementYcoord) / (this.heightPerItem / 2)) *
            30;
      } else {
        preferredDuration =
          this._dragDropService.resizingCalendarItemDuration -
          Math.round((elementYcoord - eventYcoord) / (this.heightPerItem / 2)) *
            30;
      }

      const realDuration = Math.min(
        Math.max(preferredDuration, this.MIN_DURATION),
        MAX_DURATION
      );

      this._dragDropService.resizingCalendarItem.duration = realDuration;

      this._cdRef.detectChanges();
    }
  };

  onDropdownItemSelect(
    item: CalendarDropDownSelectData,
    hours: number,
    minutes: number,
    position: number
  ) {

    this._calendarService.createCalendarItem(
      this.selectedCalendar,
      item.musicChannel,
      item.musicCollection,
      Day.getDayForIndex(this.currentIndex),
      hours,
      minutes,
      30,
      position
    )
  }

  onDayChange(days: number) {
    if (days > 0) {
      this._direction = 'next';
      this._cdRef.detectChanges();
      this.currentIndex = (this.currentIndex + days) % this.daysOfWeek.length;
    } else if (days < 0) {
      this._direction = 'prev';
      this._cdRef.detectChanges();
      this.currentIndex =
        (this.currentIndex + this.daysOfWeek.length + days) %
        this.daysOfWeek.length;
    }
  }

  onTooltip({ showTooltip, calendarItem }: TooltipEvent) {
    if (showTooltip) {
      if (this.tooltipSubscription == null){
        this.tooltipSubscription = fromEvent(this.calendarPanel.nativeElement, 'scroll').pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this._popupService.hidePopup$.next(calendarItem);
        });
      }

    } else {
      if (this.tooltipSubscription){
        this.tooltipSubscription.unsubscribe();
        this.tooltipSubscription = null;
      }

    }
  }

  onDropdown({ showDropdown, dropdown }: DropdownEvent) {
    if (showDropdown) {
      if (this.dropdownSubscription == null){
        this.dropdownSubscription = fromEvent(this.calendarPanel.nativeElement, 'scroll')
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this._popupService.hidePopup$.next(dropdown);
        });
      }

    } else {
      if (this.dropdownSubscription){
        this.dropdownSubscription.unsubscribe();
        this.dropdownSubscription = null;
      }

    }
  }

  toggleEditMode(value: boolean) {
    this._inEditMode = value;
    if (!this._inEditMode) {
      this._showTweakPanel = false;
    }
  }

  retryClick() {
    if (this.selectedCalendar != null) {
      this._calendarService.loadCalendarItems(this.selectedCalendar);
    }
  }

  private openBottomSheet(): void {
    this._bottomSheet.open(BulkActionFeedbackBottomSheetComponent);
  }


  public undoDelete(){
    if (this.selectedCalendar && this.selectedCalendar.deleted){
      this._calendarService.undoDelete(this.selectedCalendar);
    }
  }
}
