import {Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation, AfterViewInit} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { timer, Subject} from 'rxjs';
import {PlatformLocation} from '@angular/common';
import { AuthenticationService } from '@service/authentication.service';
import { MusicChannelService } from '@service/music-channel.service';
import { LoggerService } from '@service/loggers/logger.service';
import { PlayTokenService } from '@service/play-token.service';
import { BufferService } from '@service/buffer.service';
import { QueueService } from '@service/queue.service';
import { PlaylistService } from '@service/playlist.service';
import { CalendarGroupService } from '@service/calendar-group.service';
import { RemoteService } from '@service/remote.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, take } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppService } from '@service/app.service';
import { IntercomService } from '@service/intercom.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { stringToLogLevel } from '@service/loggers/logTarget';
//import { ElementQueries } from 'css-element-queries/src/ElementQueries';
import { ApplicationMode, ZoneConnectionsService } from './services/authentication/zone-connections.service';
import { MessageBlocksService } from './services/data/message-blocks.service';
import { BrandMessageBufferService } from './services/audio/brand-message-buffer.service';
import { DataUpdateService } from './services/realTimeCommunication/data-update.service';
import { ZonePresenceService } from './services/realTimeCommunication/zone-presence.service';
import { RealUserMonitorService } from '@service/real-user-monitor.service';
import { AppVersionService, MAJOR_VERSION } from '@service/app-version.service';
import { DeviceService } from '@service/device.service';
import { UpdateService } from '@service/update.service';
import { ShareZoneConnectionToOtherAppService } from '@service/app-v5/share-zone-connection-to-other-app.service';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit{

  private LOGGER_CLASSNAME = 'view.AppComponent';

  constructor(
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService:AuthenticationService,
    private musicChannelService: MusicChannelService,
    private calendarGroupService: CalendarGroupService,
    private bufferService: BufferService,
    private renderer: Renderer2,
    private logger: LoggerService,
    private queueService: QueueService,
    private playTokenService: PlayTokenService,
    private playlistService: PlaylistService,
    private remoteService: RemoteService,
    private intercomService: IntercomService,
    private deviceDetectorService: DeviceDetectorService,
    private appService: AppService,
    private zoneConfiguration: ZoneConfigurationService,
    private locationStrategy: PlatformLocation,
    private messageBlocksService:  MessageBlocksService,
    private zoneConnectionsService: ZoneConnectionsService,
    private brandMessageBufferService: BrandMessageBufferService,
    private dataUpdateService: DataUpdateService,
    private zonePresenceService: ZonePresenceService,
    private firebaseService: RealUserMonitorService,
    private appVersionService: AppVersionService,
    private deviceService: DeviceService,
    private updateService: UpdateService,
    private shareDesktopAppService: ShareZoneConnectionToOtherAppService) {

      shareDesktopAppService.bootService();
      dataUpdateService.bootService();
      intercomService.bootService();
      firebaseService.bootService();

      // this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang('en');

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      //this can be overwritten by a language from the zoneConfiguration once it is loaded
      translate.use(translate.getBrowserLang());

      //test with FR so we can check what is not yet translated
      //translate.use('de');

      /*
      this.router.events
			.pipe(
        filter(
					( event: NavigationEvent ) => {

            return( event instanceof NavigationStart );

					}
        ),
        takeUntil(
          this.destroyed$
        )
      )
      .subscribe(
				( event: NavigationStart ) => {

          console.group( "NavigationStart Event" );
          console.log( "navigation id:", event.id );
          console.log( "route:", event.url );
          console.log( "trigger:", event.navigationTrigger );

          if ( event.restoredState ) {

						console.warn(
							"restoring navigation id:",
							event.restoredState.navigationId
						);

					}

					console.groupEnd();

        }
      );
      */

        /*
      locationStrategy.onPopState(() => {

        let state = history.state;

        // Confirm if we are currently logged in
        if (this.authenticationService.loggedIn){
          var r = confirm('You pressed a Back button! Are you sure?!');
          if (r === true) {
            // Call Back button programmatically as per user confirmation.
            // history.back();
            // Uncomment below line to redirect to the previous page instead.
            // window.location = document.referrer // Note: IE11 is not supporting this.
            //history.pushState(null, null, "/login");
            //this.router.navigate(["/login"]);
            this.authenticationService.logout(false, "You pressed the back button. Login again to start Tunify.");
          } else {
            // Stay on the current page.
            //history.pushState(null, null, "/player");
            //this.router.navigate(["/player"]);
          }
        }





      // history.pushState(null, null, window.location.pathname);
    });
    */
  }

  private zoneCodeFromQueryParams:string = null;
  private modeFromQueryParams:ApplicationMode = null;
  private externalZoneIdFromQueryParams:string = null;
  private appFamilyIdFromQueryParams:number = null;
  private appVersionFromQueryParams:string = null;
  ngOnInit() {


    this.logger.debug(this.LOGGER_CLASSNAME, 'init', 'Going to read query params');
    this.activatedRoute.queryParamMap
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(queryParams => {
      queryParams.keys.forEach((key: string) => {
        this.logger.debug(this.LOGGER_CLASSNAME, 'queryParamMap.subscription', 'Going to read ' + key + ': ' + queryParams.get(key));
        if (key.toLowerCase() == 'zonecode'){
          this.zoneCodeFromQueryParams = queryParams.get(key);
        }else  if (key.toLowerCase() == 'zoneid'){
          this.externalZoneIdFromQueryParams = queryParams.get(key);
        }else  if (key.toLowerCase() == 'appfamilyid'){
          let appFamilyIdString = queryParams.get(key);
          this.appFamilyIdFromQueryParams = parseInt(appFamilyIdString);
        }
        else  if (key.toLowerCase() == 'mode'){
          const modeString = queryParams.get(key);
          if (modeString != null){
            if (modeString.toLowerCase() == 'player'){
              this.modeFromQueryParams = ApplicationMode.playerMode;
            }else if (modeString.toLowerCase() == 'remote'){
              this.modeFromQueryParams = ApplicationMode.remoteMode;
            }else{
              this.logger.error(this.LOGGER_CLASSNAME, 'queryParamMap.subscription', 'application mode not recognized: ' + modeString);
            }
          }

        }
        else if (key.toLowerCase() == 'loglevel'){
          let logLevelString = queryParams.get(key);
          let logLevel = stringToLogLevel(logLevelString);
          if (logLevel){
            this.logger.debug(this.LOGGER_CLASSNAME, 'queryParamMap.subscription', 'Going to set log level to ' + logLevelString);
            this.logger.forceLogLevel = logLevel;
            this.logger.debug(this.LOGGER_CLASSNAME, 'queryParamMap.subscription', 'Changed log level to ' + logLevelString);
          }else{
            this.logger.error(this.LOGGER_CLASSNAME, 'queryParamMap.subscription', 'logLevel value ' + logLevelString + ' not recognized. Possible value: info, debug, warn, error, off');
          }
        }else if (key.toLowerCase() == 'lang'){
          let forcedLang = queryParams.get(key);
          if (this.zoneConfiguration.ALLOWED_LANGUAGES.indexOf(forcedLang) >= 0){
            this.translate.use(forcedLang);
            this.zoneConfiguration.forcedLanguage = forcedLang;
            this.logger.warn(this.LOGGER_CLASSNAME, 'queryParamMap.subscription', 'forced language: ' + forcedLang);
          }else{
            this.logger.error(this.LOGGER_CLASSNAME, 'queryParamMap.subscription', 'forced lanugae ' + forcedLang + ' not recognized. Possible value: ' + this.zoneConfiguration.ALLOWED_LANGUAGES.toString());
          }
        }else if (key.toLowerCase() == 'device'){
          this.deviceService.forcedDevice = queryParams.get(key);

        }else if (key.toLowerCase() == 'version'){
          this.appVersionFromQueryParams = queryParams.get(key);
          let versionToUse: MAJOR_VERSION = null;
          if (this.appVersionFromQueryParams && MAJOR_VERSION.V5 == this.appVersionFromQueryParams){
            versionToUse = MAJOR_VERSION.V5;
          }else if (this.appVersionFromQueryParams && MAJOR_VERSION.V4 == this.appVersionFromQueryParams){
            versionToUse = MAJOR_VERSION.V4;
          }

          if (versionToUse){
            //Registeren this version to use, also writes to local storage for next runs
            this.appVersionService.changeVersionToUse(versionToUse);
            if (environment.use_redirect_location && environment.build_version != versionToUse){
              //We are in the wrong version on startup -> redirect right away to the correct version, with same parameters
              this.redirectToCorrectVersion(versionToUse, queryParams);
            }
          }else{
            this.logger.error(this.LOGGER_CLASSNAME, 'queryParamMap.subscription', 'app version ' + this.appVersionFromQueryParams + ' not recognized. Possible value: ' + MAJOR_VERSION.V4 + ' or ' + MAJOR_VERSION.V5);
          }
        }
        else{
          console.warn('query param ' + key + ' not recognized. Possible keys: logLevel - lang - device');
        }
      });
    });




    //Initialize css-element-queries so we can use ResiseSensor in our components
    //ElementQueries.listen();
    //ElementQueries.init();

    //the bufferService needs the renderer to create AudioFileWithPlayInfo objects
    this.bufferService.bootstrapRenderer(this.renderer);
    this.brandMessageBufferService.bootstrapRenderer(this.renderer);


    this.authenticationService.loggedIn$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (value) => {
        this.logger.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'adjusting to logged in state: ' + value ? 'true' : 'false')
        if (value){
          this.logDeviceInfo();
        }
      });
  }

  private logDeviceInfo(){
    this.logger.debug(this.LOGGER_CLASSNAME, 'logDeviceInfo', 'Useragent: ' + this.deviceDetectorService.userAgent);
    this.logger.debug(this.LOGGER_CLASSNAME, 'logDeviceInfo',
      'device: ' + this.deviceDetectorService.device +
      ' - browser: ' + this.deviceDetectorService.browser + '(' + this.deviceDetectorService.browser_version +
      ') OS: ' + this.deviceDetectorService.os + '(' + this.deviceDetectorService.os_version + ')'
    );
  }

  private redirectToCorrectVersion(version: MAJOR_VERSION, queryParams: ParamMap){
    let url = environment.redirect_location_v5;
    if (version == MAJOR_VERSION.V4){
      url = environment.redirect_location_v4;
    }

    //add the query params to the url
    let httpParams = new HttpParams();

    // Iterate over all keys in the ParamMap
    queryParams.keys.forEach(key => {
      // Check if there are multiple values for the key
      const values = queryParams.getAll(key);
      if (values) {
        // Append each value to the HttpParams
        values.forEach(value => {
          httpParams = httpParams.append(key, value);
        });
      }
    });

    url = url + '?' + httpParams.toString();

    this.logger.info(this.LOGGER_CLASSNAME, 'redirectToCorrectVersion', 'redirecting to ' + url);
    window.open(url, "_self");
  }

  ngAfterViewInit(){

    //Bugfix: we need a 0 timer to make sure our query param map is build by angular
    timer(0)
      .pipe(take(1))
      .subscribe(
        () => {

          this.appVersionService.fetchPreviousUsedVersion();

           //startup with a known zoneConnection when we have an external zoneId
          if (this.externalZoneIdFromQueryParams){
            //when we don't have an app family id, we suppose it is 1
            let appFamilyId = 1;
            if (this.appFamilyIdFromQueryParams != null && !isNaN(this.appFamilyIdFromQueryParams)){
              appFamilyId = this.appFamilyIdFromQueryParams;
            }

            let zoneConnection = this.zoneConnectionsService.findConnectionForExternalZoneId(this.externalZoneIdFromQueryParams, appFamilyId);
            if (zoneConnection != null && zoneConnection.valid){
              //start the connection, if an applicationMode (player / remote) was set, use it (null otherwise)
              this.zoneConnectionsService.activateZoneConnection(zoneConnection, this.modeFromQueryParams);
            }
          }

          //startup with a zoneCode from the query params
          if (this.zoneConnectionsService.activeZoneConnection == null && !this.zoneConnectionsService.creatingZoneConnection && this.zoneConnectionsService.connectingZoneConnection == null){
            if (this.zoneCodeFromQueryParams){
              this.zoneConnectionsService.connectZoneForZoneCode(this.zoneCodeFromQueryParams, true, this.modeFromQueryParams);
            }
          }

          //startup with previous zone
          if (this.zoneConnectionsService.activeZoneConnection == null && !this.zoneConnectionsService.creatingZoneConnection && this.zoneConnectionsService.connectingZoneConnection == null){
            this.zoneConnectionsService.loadActiveZoneConnection(this.modeFromQueryParams);
          }


          //before we start navigating, add the entry point as the login page
          history.pushState(null, null, '/login');

          this.zoneConnectionsService.activeZoneConnection$
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe(
            () => {
              this.adjustViewToLoggedInState();
            }
          );

          this.appVersionService.appVersionToUse$
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe(
            () => {
              this.adjustViewToLoggedInState();
            }
          );
        }
      );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private playerPageAdded = false;
  private adjustViewToLoggedInState() {
    if (this.zoneConnectionsService.activeZoneConnection == null) {
      this.router.navigate(['/login']);

      /*
      if (this.playerPageAdded){
        history.back();
        this.playerPageAdded = false;
      }else{
        this.router.navigate(['/login'], {skipLocationChange: true});
      }
      */

    }else {
      if (this.appVersionService.appVersionToUse == MAJOR_VERSION.V4){
        this.router.navigate(['/player']);
      }else if (this.appVersionService.appVersionToUse == MAJOR_VERSION.V5){
        this.router.navigate(['/player-v5']);
      }else{
        if (this.appVersionService.appVersionToUse != MAJOR_VERSION.STARTING){
          this.logger.error(this.LOGGER_CLASSNAME, 'adjustViewToLoggedInState', 'appVersionToUse not recognized: ' + this.appVersionService.appVersionToUse);
        }

        if (AppVersionService.DEFAULT_VERSION == MAJOR_VERSION.V4){
          this.router.navigate(['/player']);
        }else{
          this.router.navigate(['/player-v5']);
        }

      }

      /*
      this.router.navigate(['/player']); //if we would need to preserve the query params -> {queryParamsHandling: "preserve"}
      this.playerPageAdded = true;
      */
    }

  }

}
