import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CalendarItem } from '@model/calendarItem';
import { MusicCategoryBlockColors } from '@model/fieldModels/musicCategoryBlockColors';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { DragDropService } from '@service/drag-drop.service';
import { MusicChannelService } from '@service/music-channel.service';
import { PopupService } from '@service/popup.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';
import { PopupPosition, PopupDirection } from '@components/popups/popup/enums';
import {
  TooltipPopupContent,
  PopupContent
} from '@components/popups/popup/interfaces';
import {
  TooltipAlignment,
  TooltipComponent
} from '@components/popups/tooltip/tooltip.component';
import { TranslateService } from '@ngx-translate/core';

export class EmittedCalendarItem {
  constructor(public item: CalendarItem, public showTooltip: boolean) {}
}

export interface TooltipEvent {
	showTooltip: boolean;
	calendarItem: ElementRef;
}

@Component({
  selector: 'tun-calendar-item',
  templateUrl: './calendar-item.component.html',
  styleUrls: ['./calendar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarItemComponent implements AfterViewInit, OnDestroy, OnInit {
  // === State === //
  private _musicChannelName: string;

  // === Observables === //
  private _destroy$ = new Subject();

	// === Emitters === //
	@Output() tooltip = new EventEmitter<TooltipEvent>();

  // === Getters === //
  public get resizingOtherCalendarItem(): boolean {
    return (
      this._dragDropService.resizingCalendarItem &&
      this._dragDropService.resizingCalendarItem !== this.calendarItem
    );
  }

  @ViewChild('calendaritem') calendarItemElement: ElementRef;
  @ViewChild('calendaritemtitle')
  calendarItemTitle: ElementRef;
  @ViewChild('calendarItemRef') calendarItemRef: ElementRef;
  @ViewChild('animation') animationRef: ElementRef;

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  // Save a boolean indicating whether the tooltip is opened or not
  // This makes scrolling a bit faster
  // If not used, the popupservice gets fired with requests to close tooltips of all items
  // this leads to O(n) calls -> too much requests for nothing.
  // If this booleans is used, the execution time is at most O(n) which will never happen.
  isTooltipOpened = false;
  public currentlyActive = false;

  get isMouseDown(): boolean {
    return this._dragDropService.isMouseDown;
  }

  private _selectedMusicCategoryBlockColors: MusicCategoryBlockColors;
  @Input() set selectedMusicCategoryBlockColors(
    value: MusicCategoryBlockColors
  ) {
    this._selectedMusicCategoryBlockColors = value;
    this.setColors();
    this.setSelected();
  }
  get selectedMusicCategoryBlockColors(): MusicCategoryBlockColors {
    return this._selectedMusicCategoryBlockColors;
  }

  private _unselectedMusicCategoryBlockColors: MusicCategoryBlockColors;
  @Input() set unselectedMusicCategoryBlockColors(
    value: MusicCategoryBlockColors
  ) {
    this._unselectedMusicCategoryBlockColors = value;
    this.setColors();
    this.setSelected();
  }
  get unselectedMusicCategoryBlockColors(): MusicCategoryBlockColors {
    return this._unselectedMusicCategoryBlockColors;
  }

  @Input() set resizeTrigger(value: boolean) {
    this.resetLabel();
    this.setSelected();
  }

  private _calendarItem: CalendarItem;
  @Input() set calendarItem(value: CalendarItem) {
    this._calendarItem = value;
    this.setSelected();
    this.syncCurrentlyActive();
    let musicChannel = null;
    if (value) {
      musicChannel = this._musicChannelService.findMusicChannelById(
        this._calendarItem.musicChannelId
      );
    }
    if (musicChannel) {
      this._musicChannelName = musicChannel.name;
    } else {
      this._musicChannelName = '';
    }
  }
  get calendarItem(): CalendarItem {
    return this._calendarItem;
  }

  private _currentAudioFileFromCalendarItemWithId: number;
  @Input() set currentAudioFileFromCalendarItemWithId(value: number) {
    this._currentAudioFileFromCalendarItemWithId = value;
    this.syncCurrentlyActive();
  }
  get currentAudioFileFromCalendarItemWithId(): number {
    return this._currentAudioFileFromCalendarItemWithId;
  }

  private _isCurrentDay: boolean;
  @Input() set isCurrentDay(value: boolean) {
    this._isCurrentDay = value;
    this.setSelected();
  }
  get isCurrentDay(): boolean {
    return this._isCurrentDay;
  }

  private _currentTimeMultiplier: number;
  @Input() set currentTimeMultiplier(value: number) {
    this._currentTimeMultiplier = value;
    this.setSelected();
  }
  get currentTimeMultiplier(): number {
    return this._currentTimeMultiplier;
  }

  @Input() musicPlayAnimationColors: MusicPlayAnimationColors;
  @Input() isSelected: boolean;
  @Input() isPlaying: boolean;
  @Input() heightPerItem: number;
  @Input() inEditMode: boolean;

  @Output() selectItem = new EventEmitter<EmittedCalendarItem>();
  @Output() openTweakPanel = new EventEmitter<CalendarItem>();
  @Output() deleteItem = new EventEmitter<CalendarItem>();

  hoverTimeout: NodeJS.Timer;
  marginLeft: SafeStyle = '0px';
  offsetWidth = 0;
  scrollWidth = 0;
  transitionSpeed = 0;
  transition: SafeStyle = 'margin 0ms linear 0s';

  selected: boolean;
  hoverOverLabel: boolean;
  hoverOverDeleteIcon: boolean;
  hoverOverTweakIcon: boolean;

  hoverOverTopExtender: boolean;
  hoverOverBottomExtender: boolean;

  selectedLabelBackgroundGradient: SafeStyle;
  unselectedLabelBackgroundGradient: SafeStyle;

  isMouseDownSubscription: Subscription;
  calendarItemSubscription: Subscription;
  bottomExtenderMouseDown = false;

  get tooltipText(): string {
    const startHourText = `${this.calendarItem.startHour}${this._translateService.instant("calendar.popup.duration.hour.short")}${
      this.calendarItem.startMinutes > 0 ? this.calendarItem.startMinutes : '00'
    }`;

    let hours = Math.floor(this.calendarItem.duration / 60);
    let hourLabel = this._translateService.instant("calendar.popup.duration.hour");
    let hoursLabel = this._translateService.instant("calendar.popup.duration.hours");
    

    const durationText = `${
      hours > 0 
        ? `${hours} ${hours > 1 ? hoursLabel:hourLabel}` 
        : ''
    } ${
      this.calendarItem.duration % 60 === 0
        ? ''
        : `${this.calendarItem.duration % 60} minuten`
    } `;

    return `${this.calendarItem.title} - ${this._musicChannelName}

    ${this._translateService.instant("calendar.popup.start.label")} ${startHourText}
    ${this._translateService.instant("calendar.popup.duration.label")} ${durationText}`;
  }

  get timeMultiplier(): number {
    const multiplier =
      this.calendarItem.startHour + this.calendarItem.startMinutes ? 0.5 : 0;
    return multiplier;
  }

  get calendarItemTooltipPositions(): ConnectedPosition[] {
    return;
  }

  get showAnimation(): boolean {
    return !this.inEditMode && this.currentlyActive;
  }

  get performingActionOnItem(): boolean{
    return this.calendarItem &&
            (
              this.calendarItem.creating ||
              this.calendarItem.saving ||
              this.calendarItem.removing
            );
  }

  constructor(
    private _ngZone: NgZone,
    private _cdRef: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
    private _dragDropService: DragDropService,
    private _musicChannelService: MusicChannelService,
    private _popupService: PopupService,
    private _renderer: Renderer2,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this.calendarItem.timingForItemChanged$
      .pipe(takeUntil(this._destroy$))
      .subscribe(_ => {
        this.setSelected();
        this._cdRef.detectChanges();
      });

    this.calendarItem.performingActionChanged$
    .pipe(takeUntil(this._destroy$))
    .subscribe(_ => {
      this._cdRef.detectChanges();
    });
  }

  ngAfterViewInit() {
    this._ngZone.runOutsideAngular(() => {
      this.calendarItemElement.nativeElement.addEventListener(
        'mouseenter',
        this.onCalendarItemHover
      );
      this.calendarItemElement.nativeElement.addEventListener(
        'mouseleave',
        this.onCalendarItemStopHover
      );
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
    this._ngZone.runOutsideAngular(() => {
      this.calendarItemElement.nativeElement.removeEventListener(
        'mouseenter',
        this.onCalendarItemHover
      );
      this.calendarItemElement.nativeElement.removeEventListener(
        'mouseleave',
        this.onCalendarItemStopHover
      );
    });
  }

  private syncCurrentlyActive() {
    this.currentlyActive =
      this.calendarItem != null &&
      this._currentAudioFileFromCalendarItemWithId !== undefined &&
      this._currentAudioFileFromCalendarItemWithId !== null &&
      this.calendarItem.id === this._currentAudioFileFromCalendarItemWithId;
  }

  onCalendarItemHover = () => {
    clearTimeout(this.hoverTimeout);

    if (this.offsetWidth === 0) {
      this.offsetWidth = this.calendarItemTitle.nativeElement.offsetWidth;
    }

    if (this.scrollWidth === 0) {
      this.scrollWidth = this.calendarItemTitle.nativeElement.scrollWidth;
    }

    if (this.offsetWidth < this.scrollWidth) {
      this.transitionSpeed =
        (((this.scrollWidth - this.offsetWidth) * 50) / 11) * 3;
      this.transition = `margin ${this.transitionSpeed}ms linear 0s`;
      this.marginLeft = this._sanitizer.bypassSecurityTrustStyle(
        `-${this.scrollWidth - this.offsetWidth}px`
      );
    }
    this._cdRef.detectChanges();
  }

  onCalendarItemStopHover = () => {
    if (this.marginLeft !== ('0px' as SafeStyle)) {
      this.marginLeft = '0px';
      this.transitionSpeed = ((this.scrollWidth - this.offsetWidth) * 50) / 11;
      this.transition = `margin ${this.transitionSpeed}ms linear 0s`;

      this.hoverTimeout = setTimeout(() => {
        this.transitionSpeed = 0;
        this.transition = 'margin 0ms linear 0s';
      }, this.transitionSpeed);
    }
    this._cdRef.detectChanges();
  }

  resetLabel = () => {
    this.offsetWidth = 0;
    this.scrollWidth = 0;
    this.transitionSpeed = 0;
    this.transition = 'margin 0ms linear 0s';
    this.marginLeft = '0px';
  }

  toggleTooltip(showTooltip: boolean) {
		this.tooltip.emit({showTooltip, calendarItem: this.calendarItemRef });
    if (!this.inEditMode) {
      if (showTooltip) {
        // get my boundaries and the boundaries of the container around the content panel (without the header)
        const me = this.calendarItemRef.nativeElement.getBoundingClientRect();
        const secondLevelParent = this._renderer
          .selectRootElement('#calendarContentPanel', true)
          .getBoundingClientRect();

        // Check if I am under the header and if so move the popup to the bottom of the item.
        const isUnderHeader = secondLevelParent.top >= me.top;
        const popupPosition = isUnderHeader
          ? PopupPosition.BOTTOM_CENTER
          : PopupPosition.TOP_CENTER;
        const popupDirection = isUnderHeader
          ? PopupDirection.DOWN
          : PopupDirection.UP;

        this._popupService.showPopup$.next({
          connector: this.calendarItemRef,
          popupPosition,
          componentType: TooltipComponent,
          showArrow: true,
          popupDirection
        });

        this.isTooltipOpened = true;
        this._popupService.currentInstance$
          .pipe(
            take(1),
            filter(
              (instance: PopupContent) =>
                instance &&
                instance.connectedElementRef === this.calendarItemRef
            )
          )
          .subscribe((instance: TooltipPopupContent) => {
            instance.text = this.tooltipText;
            instance.alignment = TooltipAlignment.LEFT;
          });
      } else {
        this.isTooltipOpened = false;
        this._popupService.hidePopup$.next(this.calendarItemRef);
      }
    }
  }

  setColors = () => {
    if (
      this.selectedMusicCategoryBlockColors &&
      this.unselectedMusicCategoryBlockColors
    ) {
      this.selectedLabelBackgroundGradient = `linear-gradient(${this.selectedMusicCategoryBlockColors._labelBackgroundGradientStartColor}, ${this.selectedMusicCategoryBlockColors._labelBackgroundGradientEndColor})`;
      this.unselectedLabelBackgroundGradient = `linear-gradient(${this.unselectedMusicCategoryBlockColors._labelBackgroundGradientStartColor}, ${this.unselectedMusicCategoryBlockColors._labelBackgroundGradientEndColor})`;
    }
  }

  setSelected = () => {
    if (this.calendarItem) {
      this.selected =
        this.isCurrentDay &&
        this.currentTimeMultiplier >=
          this.calendarItem.startHour + this.calendarItem.startMinutes / 60 &&
        this.currentTimeMultiplier <
          this.calendarItem.startHour +
            this.calendarItem.startMinutes / 60 +
            this.calendarItem.duration / 60;
    }
  }

  openCalendarItemTweakPanel = () => {
    this.openTweakPanel.emit(this.calendarItem);
  }

  onDeleteCalendarItem() {
    this.deleteItem.emit(this.calendarItem);
  }

  onDragStart = () => {
    // Needed for Firefox, otherwise drag isn't allowed.
    (event as DragEvent).dataTransfer.setData('fire', 'fox');

    this._dragDropService.draggingCalendarItem = this.calendarItem;
  }

  onDragEnd = () => {
    this._dragDropService.draggingCalendarItem = null;
    this.setSelected();
    this._cdRef.detectChanges();
  }

  onExtenderMouseDown = (event: MouseEvent, isTop: boolean) => {
    const {
      y,
      top
    } = (event.target as HTMLElement).getBoundingClientRect() as DOMRect;

    this._dragDropService.isMouseDown = true;
    this._dragDropService.resizingCalendarItem = this.calendarItem;
    this._dragDropService.resizingCalendarItemYcoordinate = y || top;
    this._dragDropService.resizingCalendarItemDuration = this.calendarItem.duration;
    this._dragDropService.resizingCalendarItemStartTime =
      this.calendarItem.startHour +
      (this.calendarItem.startMinutes === 0 ? 0 : 0.5);
    this._dragDropService.resizeFromTop = isTop;
  }
}
