import { Pipe, PipeTransform } from "@angular/core";
import { CalendarItem } from "@model/calendarItem";
import { Day } from "@model/day";

@Pipe({ name: "filterCalendarItems", pure: false })
export class FilterCalendarItems implements PipeTransform {
  transform(
    calendarItems: CalendarItem[],
    selectedDayIndex: number,
    position: number
  ): CalendarItem[] {
    return calendarItems.filter(
      i =>
        i.day.name === Day.DAYS[selectedDayIndex].name &&
        i.position === position
    );
  }
}
