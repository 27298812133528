import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { PopupContent } from '@components/popups/popup/interfaces';

@Component({
  selector: 'tun-single-dropdown',
  templateUrl: './single-dropdown.component.html',
  styleUrls: ['./single-dropdown.component.scss'],
})
export class SingleDropdownComponent implements OnInit, PopupContent {
  // === Props === //
  @Input() public connectedElementRef: ElementRef;
  @Input() public items: string[] = [];
  @Input() public header: string;
  /** Last item gets a border on top */
  @Input() lastItem: string;
  @Input() selectedIndex: number;

  // === Observables === //
  public itemSelect$ = new Subject<number>();

  constructor(public elementRef: ElementRef) {}

  ngOnInit() {}

  // === Event handlers === //
  onItemClick(index: number) {
    this.itemSelect$.next(index);
  }
}
