<div fxFlex="none" fxLayout="column">

  <div
    class="tunifyWatermark orange"
    [style.height.px]="(heightPerItem$ | async) - 1"
    [style.width.px]="widthForMenuPanel"
  >
    <ng-container *ngIf="!(newVersionAvailable$ | async)">
      Tunify Orange
    </ng-container>

    <ng-container *ngIf="(newVersionAvailable$ | async)">
      <button
        class="upgrade-version-button"
        [style.height.px]="(heightPerItem$ | async) * 0.6"
        [style.border-radius.px]="(heightPerItem$ | async) * 0.6"
        (click)="onUpgradeVersion()"
      >
        {{'version.upgrade.button.label' | translate}}
      </button>
    </ng-container>

  </div>

  <div class="lightPanelBackground">
    <tun-orange-menu
      fxFlex
      [widthForMenuPanel]="widthForMenuPanel"
      [heightPerItem]="heightPerItem$ | async"
    >
    </tun-orange-menu>
  </div>
</div>

