<div
  class="container"
  [style.border-color]="
    selected
      ? selectedMusicCategoryBlockColors._containerBorderColor
      : unselectedMusicCategoryBlockColors._containerBorderColor
  "
  [style.border-top]="containerBorderTop"
  [style.border-bottom]="containerBorderBottom"
  [style.height.px]="componentHeight"
>
  <div
    #labelContainer
    (tunResize)="onResizeLabel($event)"
    class="label-container"
    (click)="musicCollection ? toggleSelected() : null"
    [style.background-image]="
      labelHover ? labelBackgroundGradientHover : labelBackgroundGradient
    "
    [style.border-color]="
      selected
        ? selectedMusicCategoryBlockColors._labelBorderColor
        : unselectedMusicCategoryBlockColors._labelBorderColor
    "
    [style.border-right]="labelBorderRight"
    [style.justify-content]="labelContentJustification"
  >
    <div
      #label
      class="label {{(musicCollection && musicCollection.isRecommendation) ? 'recommendationLabel' : ''}}"
      [style.color]="
      (musicCollection && musicCollection.isRecommendation) ? '#FFFFFF' :
        selected
          ? selectedMusicCategoryBlockColors._textColor
          : unselectedMusicCategoryBlockColors._textColor
      "
      [style.font-weight]="selected ? 700 : 400"
      [style.margin-left]="labelMarginLeft"
      [style.-webkit-transition.ms]="transitionSpeed"
      [style.-moz-transition.ms]="transitionSpeed"
      [style.transition.ms]="transitionSpeed"
    >
      {{musicCollection && musicCollection.isRecommendation ? 'TIP : ' : ''}}{{ musicCollection ? musicCollection.title : '' }}
    </div>

    <div class="animation" *ngIf="musicCollection">
      <tun-music-play-animation
        *ngIf="showPlayAnimationForMusicCollectionId == musicCollection.id"
        [playing]="animatePlayAnimation"
        [selected]="selected"
        [musicPlayAnimationColors]="musicPlayAnimationColors"
      ></tun-music-play-animation>
    </div>
  </div>

  <div
    #iconContainer
    class="music-category-block-icon-container"
    (click)="musicCollection ? onIconClick() : null"
    [class.active]="selected"
    [style.min-width.px]="componentHeight"
    [style.width.px]="componentHeight"
    [style.background-color]="
      selected
        ? iconHover
          ? selectedMusicCategoryBlockColors._iconBackgroundHoverColor
          : selectedMusicCategoryBlockColors._iconBackgroundColor
        : iconHover
        ? unselectedMusicCategoryBlockColors._iconBackgroundHoverColor
        : unselectedMusicCategoryBlockColors._iconBackgroundColor
    "
    [style.border-color]="
      selected
        ? selectedMusicCategoryBlockColors._iconBorderColor
        : unselectedMusicCategoryBlockColors._iconBorderColor
    "
  >
    <ng-container *ngIf="musicCollection">
      <tun-list-icon *ngIf="isListIcon()"></tun-list-icon>
      <tun-settings-icon *ngIf="!isListIcon()"></tun-settings-icon>
    </ng-container>
  </div>
</div>
