import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'tun-tab-button',
  templateUrl: './tab-button.component.html',
  styleUrls: ['./tab-button.component.scss']
})

export class TabButtonComponent implements OnInit {

  iconPaddingTop = 0;

  @Input() selected = false;

  @Input() heightForIcon:number = 0;

  @Input() rightBorder = false;
  
  constructor( private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  /*
  ngOnChanges(simpleChanges: SimpleChanges){
    if (simpleChanges.heightForButton){
      this.calcSizes();
    }
  }

  private calcSizes(){
    let heightForIcon = 18;
    let restHeigth = this.heightForButton - heightForIcon;
    let paddingForIconTop = Math.floor(restHeigth / 2);

    this.iconPaddingTop = this.sanitizer.bypassSecurityTrustStyle(
      `${paddingForIconTop}px`
    );
  }
  */

}
