import { Directive, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { PopupOnHoverDirective } from '../popup-on-hover.directive';
import { PopupService } from '@service/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { PopupPosition, PopupDirection } from '../popup/enums';
import { takeUntil, filter, finalize } from 'rxjs/operators';
import { PopupContent, TooltipPopupContent } from '../popup/interfaces';
import { CheckAccessDirective } from '../access-checks/check-access.directive';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { ExternalLinksService } from '@service/external-links.service';
import { SubscriptionsService } from '../../../services/data/subscriptions.service';

@Directive({
  selector: '[tunTooltipOnHover]'
})
export class TooltipOnHoverDirective extends CheckAccessDirective implements OnChanges {

  @Input() tooltipText: string;
  @Input() tooltipPosition: PopupPosition = PopupPosition.TOP_CENTER;
  @Input() tooltipDirection: PopupDirection = PopupDirection.UP;
  @Input() tooltipEnabled: boolean = true; //the tooltip can be disabled, for example when an other popup is showing for the element
  @Input() tooltipDarkThemed: boolean = false;

  constructor(
    protected _elementRef: ElementRef,
    protected _popupService: PopupService,
    protected _zoneConfigurationService: ZoneConfigurationService,
    protected subscriptionsService: SubscriptionsService,
    protected _externalLinksService: ExternalLinksService,
    protected _translateService: TranslateService
  ) {
    super(_elementRef, _popupService, _zoneConfigurationService, subscriptionsService, _externalLinksService, _translateService);

    this._popupService.currentInstance$
      .pipe(
        filter(
          (instance: PopupContent) =>
            instance && this._elementRef != null && instance.connectedElementRef === this._elementRef
        ),
        takeUntil(this._destroy$)
      )
      .subscribe((instance: TooltipPopupContent) => {
        instance.text = this.tooltipText;
        instance.darkThemed = this.tooltipDarkThemed;
        //instance.alignment = this.tooltipAlignment;
        //instance.tooltipAction = this.tooltipAction;
      });
  }

  public ngOnChanges(simpleChanges: SimpleChanges){
    if (simpleChanges.tooltipEnabled != null){
      if (!simpleChanges.tooltipEnabled.currentValue){
        this.performTogglePopup(false);
      }

    }
  }

  private showingTooltip = false;
  protected performTogglePopupWithAccess(togglePopup) {
    if (togglePopup && this.tooltipEnabled == true) {
      if (!this.showingTooltip) {
        this._popupService.showPopup$.next({
          connector: this._elementRef,
          componentType: TooltipComponent,
          popupPosition: this.tooltipPosition,
          popupDirection: this.tooltipDirection,
          showArrow: true,
          darkThemed: this.tooltipDarkThemed
        });
        this.showingTooltip = true;
      }
    } else {
      //hide the current popup if one is showing
      if (this.showingTooltip) {
        this._popupService.hidePopup$.next(this._elementRef);
        this.showingTooltip = false;
      }
    }
  }

}
