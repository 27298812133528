import { Component, OnInit, Input } from '@angular/core';

export class OnScreenMessage{
  message: string;
  link: string;
  linkAction: () => void;
}

@Component({
  selector: 'tun-on-screen-message',
  templateUrl: './on-screen-message.component.html',
  styleUrls: ['./on-screen-message.component.scss']
})
export class OnScreenMessageComponent implements OnInit {

  @Input() message: OnScreenMessage;
  
  get showMessage():boolean{
    return this.message != null && this.message.message != null;
  }

  get showLink():boolean{
    return this.message != null && this.message.link != null;
  }

  constructor() { }

  ngOnInit() {
  }

  onActionClick() {
    this.message.linkAction();
  }

}
