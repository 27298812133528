import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  Output,
  OnDestroy,
  Input
} from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { takeUntil, take, filter } from 'rxjs/operators';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { environment } from 'src/environments/environment';
import { PopupService } from '@service/popup.service';
import { PopupPosition, PopupDirection } from '@components/popups/popup/enums';
import { SubscriptionsService } from '../../../../services/data/subscriptions.service';
import {
  PopupContent,
  TooltipPopupContent
} from '@components/popups/popup/interfaces';
import {
  TooltipAlignment,
  TooltipComponent
} from '@components/popups/tooltip/tooltip.component';

@Component({
  selector: 'tun-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit, OnDestroy {
  private componentDestroyed$ = new Subject<boolean>();

  // === ViewChildren === //
  @ViewChild('searchInput')
  searchInput: ElementRef;

  // === State === //
  private _searchTermBeforePreview: string;
  searchForm: UntypedFormGroup;

  // === Props === //
  private _previewValue?: string;

  get previewValue(): string {
    return this._previewValue;
  }

  @Input()
  set previewValue(value: string) {
    this._previewValue = value;

    // Backup the old value
    if (!this._searchTermBeforePreview && this.searchForm) {
      this._searchTermBeforePreview = this.searchForm.get('searchTerm').value;
    }

    // Set the new one without emitting the change
    if (this.searchForm) {
      // if there is a preview value, show it
      // otherwise reset the previous search term
      this.searchForm.patchValue(
        {
          searchTerm: value || this._searchTermBeforePreview
        },
        {
          emitEvent: false
        }
      );

      this._searchTermBeforePreview = value
        ? this._searchTermBeforePreview
        : null;
    }
  }

  // === Emitters === //
  // will emit when the user hits Enter.
  @Output()
  search = new EventEmitter<string>();

  // will emit every time the user hits a key different from Enter.
  @Output()
  autocomplete = new EventEmitter<string>();

  @Output() searchfocus = new EventEmitter<void>();
  @Output() searchescape = new EventEmitter<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private subscriptionsService: SubscriptionsService
  ) {}

  private get searchTerm() {
    return this.searchForm.get('searchTerm').value;
  }

  ngOnInit() {
    this.buildForm();

    //special case with access rights -> also enable/disable the textInput
    this.subscriptionsService.accessRights$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(accessRights => {
        if (environment.enableSearch && accessRights && accessRights.search) {
          this.searchForm.get('searchTerm').enable();
        } else {
          this.searchForm.get('searchTerm').disable();
        }
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$ = null;
  }

  onSearchIconClicked() {
    // Request focus on input field when the icon is clicked.
    if (this.searchInput) {
      this.searchInput.nativeElement.focus();
    }

    // if we have a previous search term, emit it.
    if (this.searchTerm && this.searchTerm.length) {
      this.onSearch(this.searchTerm);
    }
  }

  onSearch(value: string) {
    const trimmed = value.trim();
    if (trimmed) {
      this.search.next(trimmed);
    }
  }

  onEscapeSearch() {
    this.searchInput.nativeElement.blur();
    this.searchescape.emit();
  }

  private buildForm() {
    this.searchForm = this.fb.group({
      searchTerm: [this._previewValue || '']
    });

    // listen to value changes of the search term and emit an autocomplete event.
    this.searchForm
      .get('searchTerm')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        const trimmed = value.trim();
        if (trimmed) {
          this.autocomplete.emit(trimmed);
        }

        // close the preview
        this._previewValue = null;
        this._searchTermBeforePreview = null;
      });
  }
}
