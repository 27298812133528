<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 18 18" enable-background="new 0 0 18 18" xml:space="preserve">
<g>
	<rect x="6" y="13" [attr.fill]="color" [style.fill]="color" width="10" height="2"/>
	<rect x="6" y="8" [attr.fill]="color" [style.fill]="color" width="10" height="2"/>
	<rect x="6" y="3" [attr.fill]="color" [style.fill]="color" width="10" height="2"/>
	<rect x="2" y="13" [attr.fill]="color" [style.fill]="color" width="2" height="2"/>
	<rect x="2" y="8" [attr.fill]="color" [style.fill]="color" width="2" height="2"/>
	<rect x="2" y="3" [attr.fill]="color" [style.fill]="color" width="2" height="2"/>
</g>
</svg>
