import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExternalLinksService } from '@service/external-links.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import customProtocolCheck from 'custom-protocol-check';
import { combineLatest, timer } from 'rxjs';
import { ShareZoneConnectionToOtherAppService } from '../../../../../services/app-v5/share-zone-connection-to-other-app.service';

@Component({
  selector: 'tun-settings-desktop-app-panel',
  templateUrl: './settings-desktop-app-panel.component.html',
  styleUrls: ['./settings-desktop-app-panel.component.scss']
})
export class SettingsDesktopAppPanelComponent {

  @Output() back = new EventEmitter<void>();



  constructor(
    private translateService: TranslateService,
    private zoneConfigurationService: ZoneConfigurationService,
    private externalLinksService: ExternalLinksService,
    private shareDesktopAppService: ShareZoneConnectionToOtherAppService
  ) {

  }

  onBack(){
    this.back.emit();
  }

  onDownload(){
    this.externalLinksService.downloadDesktopApp();
  }


  public get playerNotFound(){
    return this.shareDesktopAppService.playerNotFound;
  }

  public onStartDesktop(){
    this.shareDesktopAppService.openZoneInDesktopApp();


  }

  public onConnectZone(){

  }
}
