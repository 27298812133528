<div
  class="searchTitleBox"
  fxFlex
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxFlexAlign="center"
  [style.height.px]="height$ | async"
  [style.width.px]="width$ | async"
>
  <div
    class="titleLabel label"
    tunFitSize
    minFontSize="1"
    maxFontSize="2"
    fontUnit="rem"
    [textToFit]="title"
  ></div>
  <div class="close-icon" (click)="onCloseIconClicked()">
    <tun-close-icon></tun-close-icon>
  </div>
</div>
