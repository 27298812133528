import {Component, OnDestroy, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, NgZone, DoCheck} from '@angular/core';
import { AppService } from '@service/app.service';
import { LoggerService } from '@service/loggers/logger.service';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import {SearchService} from '@service/search.service';
import { takeUntil } from 'rxjs/operators';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { SubscriptionsService } from '../../services/data/subscriptions.service';
import { Subject } from 'rxjs';
import { ResizedEvent } from '@components/resize/resize.directive';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'tun-player',
    templateUrl: 'player.component.html',
    styleUrls: ['./player.component.scss'],
    host: {
      'class': 'router-flex'
    },
})

export class PlayerComponent implements OnInit, OnDestroy, DoCheck {

  private LOGGER_CLASSNAME = 'PlayerComponent';

  public get useTunifyV4(){
    return !environment.use_redirect_location || environment.build_version == '4';
  }

  @ViewChild('playerView', {static: true}) playerView:any;

  public selectTunifyColor: TunifyColor;

  public showLoading = true;
  public showDjScreen = false;

  public heightForTopBar = 0;
  public heightForBottomBar = 0;
  public widthForContentPanel = 0;
  public sizeCalculated = false;

  get heightPerItem$(){
    return this.appService.heightPerItem$;
  }

	// === Getters === //
	get searchResults$() {
		return this._searchService.searchResult$;
  }

  // === Observables === //
  private _destroy$ = new Subject<void>();

    constructor(
        private activeMusicSelectionService: ActiveMusicSelectionService,
        private subscriptionsService: SubscriptionsService,
        private appService:AppService,
        private loggerService:LoggerService,
        private _searchService: SearchService,
    ) {

  }

  ngDoCheck(){
    //this.loggerService.debug(this.LOGGER_CLASSNAME, "ngDoCheck", "Change detection runs");
  }

  ngOnInit() {

    this.activeMusicSelectionService.selectedPlayerView$
    .pipe(
      takeUntil(this._destroy$)
    )
    .subscribe(
      (playerView) => {
        this.selectTunifyColor = playerView;
        this.showDjScreen = playerView === TunifyColor.ORANGE;
      }
    );

    this.appService.heightForTopBar$
    .pipe(
      takeUntil(this._destroy$)
    )
    .subscribe(
        (heightForTopBar) => {
          this.heightForTopBar = heightForTopBar;
        }
    );

    this.appService.heightForBottomBar$
    .pipe(
      takeUntil(this._destroy$)
    )
    .subscribe(
      (heightForBottomBar) => {
        this.heightForBottomBar = heightForBottomBar;
      }
    );

    this.appService.widthForContentPanel$
    .pipe(
      takeUntil(this._destroy$)
    )
    .subscribe(
      (widthForContentPanel) => {
        this.widthForContentPanel = widthForContentPanel;
      }
    )

    this.subscriptionsService.accessRights$
    .pipe(
      takeUntil(this._destroy$)
    )
    .subscribe(
      (accessRights) => {
        this.updateLoading();
      }
    );


    this.playerSizeCalculation();
  }

  private updateLoading(){
    //todo -> check subscription
    this.showLoading = this.subscriptionsService.accessRights == null;
  }

  public onResize(event: ResizedEvent){
    //this.loggerService.debug(this.LOGGER_CLASSNAME, "onResize", "triggering resize");
    this.playerSizeCalculation();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$ = null;

  }

  private playerSizeCalculation() {
    const playerViewRect = this.playerView.nativeElement.getBoundingClientRect();

    if (playerViewRect.height > 0){
      this.appService.adjustPlayerSizeToHeight(playerViewRect.height);
      this.appService.adjustPlayerSizeToWidth(playerViewRect.width);

      this.sizeCalculated = true;
    }

    this.loggerService.debug(this.LOGGER_CLASSNAME, "playerSizeCalculation", "width: " + playerViewRect.width + " - height: " + playerViewRect.height);
  }

}

