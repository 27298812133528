<div [class.centered-message]="status" [ngSwitch]="status">
  <!-- RUNNING -->
  <tun-loader
    *ngSwitchCase="AsyncStatus.RUNNING"
    text="{{ 'popup.addToPlaylist.busy' | translate }}"
    textColor="black"
  >
  </tun-loader>

  <div *ngSwitchCase="AsyncStatus.COMPLETED" translate>
    <!-- TODO: icoon toevoegen -->
    popup.addToPlaylist.success
  </div>

  <div *ngSwitchCase="AsyncStatus.ERROR" class="centered-message" translate>
    popup.addToPlaylist.failed
  </div>

  <div *ngSwitchCase="AsyncStatus.CANCELLED" class="centered-message" translate>
    popup.addToPlaylist.cancelled
  </div>

  <div *ngSwitchCase="AsyncStatus.INVALID_ACTION" class="centered-message" translate>
    popup.addToPlaylist.noTracksToAdd
  </div>

  <!-- NO STATUS -->
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="!isAddingToNewList; else newList">
      <ng-content select="tun-my-lists-popup-row .top"></ng-content>
      <!-- My favorites -->
      <tun-my-lists-popup-row
        *ngIf="favorites"
        [title]="favorites.title"
        [isSearchEnabled]="isSearchEnabled"
        [isAddToQueueEnabled]="isAddToQueueEnabled"
        (search)="onSearch(favorites)"
        (select)="onSelectPlaylist(favorites)"
      >
        <tun-thumb-up-icon class="icon"></tun-thumb-up-icon>
      </tun-my-lists-popup-row>

      <!-- My own playlists -->
      <tun-my-lists-popup-row
        *ngFor="let playlist of playlists"
        [title]="playlist.title"
        [isSearchEnabled]="isSearchEnabled"
        [isAddToQueueEnabled]="isAddToQueueEnabled"
        (search)="onSearch(playlist)"
        (select)="onSelectPlaylist(playlist)"
      >
      </tun-my-lists-popup-row>

      <tun-my-lists-popup-row
        *ngIf="!status && !isAddToQueueEnabled"
        [title]="'customPlaylists.new' | translate"
        (select)="toggleAddToNewList()"
      >
        <tun-plus-icon class="icon"></tun-plus-icon>
      </tun-my-lists-popup-row>
      <ng-content select="tun-my-lists-popup-row .bottom"></ng-content>
    </ng-container>
    <ng-template #newList>
      <tun-add-to-new-list
        (save)="onAddToNewList($event)"
        (cancel)="toggleAddToNewList()"
      ></tun-add-to-new-list>
    </ng-template>
  </ng-container>
</div>
