import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Option } from '@model/option';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { BaseSliderComponent } from '../base-slider/base-slider.component';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';

@Component({
  selector: 'tun-text-slider',
  templateUrl: './text-slider.component.html',
  styleUrls: ['./text-slider.component.scss'],
})
export class TextSliderComponent extends BaseSliderComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  // Reference to all elements with id column.
  @ViewChildren('column') columns: QueryList<ElementRef>;
  @ViewChild('verticalLine') verticalLine: ElementRef;
  @ViewChild('leftSliderBox') leftSliderBox: ElementRef;
  @ViewChild('rightSliderBox') rightSliderBox: ElementRef;

  // An Option object has a value and a label.
  // The Option objects are rendered in the given order.
  @Input() options: Array<Option>;

  // Indicates whether or not this is a slider with a single label.
  @Input() isSingleLabel = false;

  // Input for the lowest number;
  @Input() selectedMinValue = 0;

  // Input for the highest number;
  @Input() selectedMaxValue = 0;

  // List of breakpoints in percentage a slider can snap to.
  percentages: number[] = [];

  // Left attribute of the selected line, used for positioning.
  selectedLineLeft: SafeStyle = '0%';

  // Right attribute of the selected line, used for positioning.
  selectedLineRight: SafeStyle = '0%';

  // Index of the minimal value.
  minValueIndex = 0;

  // Index of the maximum value.
  maxValueIndex = 0;

  constructor(
    private renderer: Renderer2,
    protected sanitizer: DomSanitizer,
    protected ngZone: NgZone,
    protected cdRef: ChangeDetectorRef,
    protected activeMusicSelectionService: ActiveMusicSelectionService
  ) {
    super(sanitizer, cdRef, activeMusicSelectionService);
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.options) {
      this.percentages = this.getPercentages();
    }
    if (simpleChanges.minValue || simpleChanges.options) {
      this.minValueIndex = this.indexOfValue(this.selectedMinValue);
      this.leftSliderLeftPercent = this.percentages[this.minValueIndex];
      this.adjustViewToLeftPercentage();
    }
    if (simpleChanges.maxValue || simpleChanges.options) {
      this.maxValueIndex = this.indexOfValue(this.selectedMaxValue) + 1;
      this.rightSliderRightPercent = 100 - this.percentages[this.maxValueIndex];
      this.adjustViewToRightPercentage();
    }
    this.updateSelectedLine();
    this.updateLabelColors(false);
  }

  private indexOfValue(val: number): number {
    let index = 0;
    if (this.options) {
      this.options.forEach((option: Option, i: number) => {
        if (option.value === val) {
          index = i;
        }
      });
    }
    return index;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (!this.isSingleLabel) {
      this.updateLabelColors(false);
    }
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('mouseup', this.onMouseUp);
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseleave', this.onMouseLeave);
      window.addEventListener('touchmove', this.onTouchMove);
      window.addEventListener('touchend', this.onTouchEnd);
      window.addEventListener('touchcancel', this.onTouchCancel);
      window.addEventListener('resize', this.onResize);

      this.leftSliderBox.nativeElement.addEventListener(
        'mousedown',
        this.leftSliderMouseDown
      );
      this.leftSliderBox.nativeElement.addEventListener(
        'touchstart',
        this.leftSliderMouseDown
      );
      this.rightSliderBox.nativeElement.addEventListener(
        'mousedown',
        this.rightSliderMouseDown
      );
      this.rightSliderBox.nativeElement.addEventListener(
        'touchstart',
        this.rightSliderMouseDown
      );

      this.verticalLine.nativeElement.addEventListener(
        'click',
        this.lineClicked
      );
      this.columns.forEach(child =>
        child.nativeElement.addEventListener('click', this.lineClicked)
      );
    });
  }

  ngOnDestroy() {
    this.ngZone.runOutsideAngular(() => {
      window.removeEventListener('mouseup', this.onMouseUp);
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseleave', this.onMouseLeave);
      window.removeEventListener('touchmove', this.onTouchMove);
      window.removeEventListener('touchend', this.onTouchEnd);
      window.removeEventListener('touchcancel', this.onTouchCancel);
      window.removeEventListener('resize', this.onResize);

      this.leftSliderBox.nativeElement.removeEventListener(
        'mousedown',
        this.leftSliderMouseDown
      );
      this.leftSliderBox.nativeElement.removeEventListener(
        'touchstart',
        this.leftSliderMouseDown
      );
      this.rightSliderBox.nativeElement.removeEventListener(
        'mousedown',
        this.rightSliderMouseDown
      );
      this.rightSliderBox.nativeElement.removeEventListener(
        'touchstart',
        this.rightSliderMouseDown
      );
    });

    this.verticalLine.nativeElement.removeEventListener(
      'click',
      this.lineClicked
    );
    this.columns.forEach(child =>
      child.nativeElement.removeEventListener('click', this.lineClicked)
    );
  }

  // Called when the user clicks the line to move a slider.
  lineClicked = (e: MouseEvent) => {
    super.lineClicked(e);

    const width = this.unselectedLine.nativeElement.offsetWidth;
    const percentage = ((e.clientX - this.unselectedLineClientX) / width) * 100;

    if (
      Math.max(percentage, this.leftSliderLeftPercent) -
        Math.min(percentage, this.leftSliderLeftPercent) <=
      Math.max(percentage, 100 - this.rightSliderRightPercent) -
        Math.min(percentage, 100 - this.rightSliderRightPercent)
    ) {
      this.leftSliderSnap(percentage);
    } else {
      this.rightSliderSnap(100 - percentage);
    }

    this.resetTransitionSpeed();
  }

  // Calculates the amount of options.
  getAmountOfOptions(): number {
    return this.options.length;
  }

  // Gets the label of the first option.
  getFirstOptionLabel(): string {
    return this.options[0].label;
  }

  // Gets the label of the last option.
  getLastOptionLabel(): string {
    return this.options[this.getAmountOfOptions() - 1].label;
  }

  // Get an array of percentages used for snapping.
  getPercentages() {
    const length = this.getAmountOfOptions();
    return new Array(length + 1).fill(0).map((e, i) => (1 / length) * i * 100);
  }

  // Updates the left and right attributes of the selected line to match the sliders.
  updateSelectedLine() {
    this.selectedLineLeft = this.leftSliderLeft;
    this.selectedLineRight = this.rightSliderRight;
  }

  // Updates the colors of the labels with the selected text color and unselected text color.
  updateLabelColors(left: boolean) {
    if (this.columns && this.columns.length > 0) {
      // All labels are given the unselected text color.
      this.columns.forEach(c =>
        this.renderer.setStyle(
          c.nativeElement,
          'color',
          this.sliderColors._unselectedTextColor
        )
      );

      if (left) {
        // The left slider might not be on a breakpoint, so the closest breakpoint is calculated.
        const closestToLeft = this.calculateClosest(this.leftSliderLeftPercent);

        // The amount of options that are currently selected by the sliders.
        const amountOfSelectedOptions = Math.round(
          (100 - this.rightSliderRightPercent - closestToLeft) /
            this.percentages[1]
        );

        // The index of current value of the right slider in the array of percentages.
        let rightIndex = this.percentages.indexOf(
          this.calculateClosest(100 - this.rightSliderRightPercent)
        );

        // Selected labels are given the selected text color.
        for (let i = 0; i < amountOfSelectedOptions; i++) {
          rightIndex--;
          this.renderer.setStyle(
            this.columns.toArray()[rightIndex].nativeElement,
            'color',
            this.sliderColors._selectedColor
          );
        }
      } else {
        // The right slider might not be on a breakpoint, so the closest breakpoint is calculated.
        const closestToRight = this.calculateClosest(
          100 - this.rightSliderRightPercent
        );

        // The amount of options that are currently selected by the sliders.
        const amountOfSelectedOptions = Math.round(
          (closestToRight - this.leftSliderLeftPercent) / this.percentages[1]
        );

        // Selected labels are given the selected text color.
        let leftIndex = this.percentages.indexOf(
          this.calculateClosest(this.leftSliderLeftPercent)
        );

        // Selected labels are given the selected text color.
        for (let i = 0; i < amountOfSelectedOptions; i++) {
          this.renderer.setStyle(
            this.columns.toArray()[leftIndex].nativeElement,
            'color',
            this.sliderColors._selectedColor
          );
          leftIndex++;
        }
      }
    }
  }

  // If a slider is clicked, it is released and a snap is performed.
  releaseSliderClicks() {
    if (this.leftSliderIsClicked) {
      this.leftSliderIsClicked = false;
      this.leftSliderSnap(this.leftSliderLeftPercent);
    } else if (this.rightSliderIsClicked) {
      this.rightSliderIsClicked = false;
      this.rightSliderSnap(this.rightSliderRightPercent);
    }
  }

  // Registration that the user is moving the left slider.
  leftSliderMouseMove(eventClientX: number) {
    super.leftSliderMouseMove(eventClientX);

    // Update the value of the left property of the left sliderbox.
    this.leftSliderBoxLeft = this.sanitizer.bypassSecurityTrustStyle(
      `calc(${this.leftSliderLeftPercent}% - 0.8rem)`
    );

    // Update the size of the selected line.
    this.updateSelectedLine();

    if (!this.isSingleLabel) {
      // Update the colors of the labels.
      this.updateLabelColors(true);
    }
  }

  // Registration that the user is moving the right slider.
  rightSliderMouseMove(eventClientX: number) {
    super.rightSliderMouseMove(eventClientX);

    // Update the value of the right property of the right sliderbox.
    this.rightSliderBoxRight = this.sanitizer.bypassSecurityTrustStyle(
      `calc(${this.rightSliderRightPercent}% - 0.8rem)`
    );

    // Update the size of the selected line.
    this.updateSelectedLine();

    if (!this.isSingleLabel) {
      // Update the colors of the labels.
      this.updateLabelColors(true);
    }
  }

  // Snap for left slider.
  leftSliderSnap(percentage: number) {
    // Place the left slider on the closest breakpoint.
    this.leftSliderLeftPercent = this.calculateClosest(percentage);

    // If the left and right slider are now on the same breakpoint,
    // the left cursor should be moved to the previous breakpoint.
    if (this.detectCollision()) {
      this.leftSliderLeftPercent = this.calculateBetterBreakpoint(
        this.leftSliderLeftPercent
      );
    }

    this.adjustViewToLeftPercentage();

    // Setting the index of the minimum value.
    this.minValueIndex = this.percentages.indexOf(this.leftSliderLeftPercent);

    // Reset the leftSliderClientX;
    this.leftSliderClientX = 0;

    // Update the size of the selected line.
    this.updateSelectedLine();

    if (!this.isSingleLabel) {
      // Update the colors of the labels.
      this.updateLabelColors(true);
    }

    // Emit the minValue and maxValue of the slider.
    this.emitOutput(
      this.options[this.minValueIndex].value,
      this.options[this.maxValueIndex - 1].value
    );
  }

  private adjustViewToLeftPercentage() {
    // If the left slider is placed on a vertical line, it should move 1 pixel to the right.
    if (this.leftSliderLeftPercent > 0 && this.leftSliderLeftPercent < 100) {
      this.leftSliderLeft = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.leftSliderLeftPercent}% + 1px)`
      );
    } else {
      this.leftSliderLeft = `${this.leftSliderLeftPercent}%`;
    }

    // Update the value of the left property of the left sliderbox.
    this.leftSliderBoxLeft = this.sanitizer.bypassSecurityTrustStyle(
      `calc(${this.leftSliderLeftPercent}% - 0.8rem)`
    );
  }

  // Snap for right slider
  rightSliderSnap(percentage: number) {
    // Place the right slider on the closest breakpoint.
    this.rightSliderRightPercent = this.calculateClosest(percentage);

    // If the left and right slider are now on the same breakpoint,
    // the right cursor should be moved to the next breakpoint.
    if (this.detectCollision()) {
      this.rightSliderRightPercent = this.calculateBetterBreakpoint(
        this.rightSliderRightPercent
      );
    }

    this.adjustViewToRightPercentage();

    // Setting the index of the maximum value.
    this.maxValueIndex =
      this.getAmountOfOptions() -
      this.percentages.indexOf(this.rightSliderRightPercent);

    // Reset the rightSliderClientX;
    this.rightSliderClientX = 0;

    // Update the size of the selected line.
    this.updateSelectedLine();

    if (!this.isSingleLabel) {
      // Update the colors of the labels.
      this.updateLabelColors(true);
    }

    // Emit the minValue and maxValue of the slider.
    this.emitOutput(
      this.options[this.minValueIndex].value,
      this.options[this.maxValueIndex - 1].value
    );
  }

  private adjustViewToRightPercentage() {
    // If the right slider is placed on a vertical line, it should move 1 pixel to the left.
    if (
      this.rightSliderRightPercent > 0 &&
      this.rightSliderRightPercent < 100
    ) {
      this.rightSliderRight = `calc(${this.rightSliderRightPercent}% - 1px)`;
    } else {
      this.rightSliderRight = `${this.rightSliderRightPercent}%`;
    }

    // Update the value of the right property of the right sliderbox.
    this.rightSliderBoxRight = this.sanitizer.bypassSecurityTrustStyle(
      `calc(${this.rightSliderRightPercent}% - 0.8rem)`
    );
  }

  // Calculates the percentage closest to the current location of a slider.
  calculateClosest(goal: number): number {
    return this.percentages.reduce(function(prev, curr) {
      return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
    });
  }

  // Called when the two sliders collided and one of them has to
  // be placed on another breakpoint.
  calculateBetterBreakpoint(closest: number): number {
    let index = this.percentages.indexOf(closest);
    return this.percentages[--index];
  }

  // Change the colors of the slider.
  changeSliderColors() {
    super.changeSliderColors();
    if (!this.isSingleLabel) {
      this.updateLabelColors(false);
    }
  }
}
