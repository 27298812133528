import {
  Component,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
  OnDestroy,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { NavigationItem } from '../model/NavigationItem';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { Subscription, Subject } from 'rxjs';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { PopupService } from '@service/popup.service';
import { NewNavigationItemPopupComponent } from '../new-navigation-item-popup/new-navigation-item-popup.component';
import { PopupPosition, PopupDirection } from '@components/popups/popup/enums';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-navigation-item-view',
  templateUrl: './navigation-item-view.component.html',
  styleUrls: ['./navigation-item-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemViewComponent implements OnDestroy, OnChanges {

  // === ViewChildren === //
  @ViewChild('navItemContainer') navItemContainer: ElementRef;
  @ViewChild('titleInput') titleInput: ElementRef;

  // === Props === //
  @Input() navigationItem: NavigationItem;
  @Input() tunifyColor: TunifyColor = null;
  @Input() heightPerItem = 0;
  @Input() mainNavigationItemIndex: number;
  @Input() isBeingDraggedOver = false;

  // === State === //
  private selectedSubscription: Subscription;
  private componentDestroyed$ = new Subject<boolean>();
  private firstTimeSelection = true;

  showSelection = false;
  showEditAndDelete = false;
  colorStyleName = '';

  titleHasFocus = false;
  title = '';
  iconHeight = 0;
  frontIconPadding: SafeStyle;
  iconPadding: SafeStyle;
  textWidth: SafeStyle;


  private _editTitle = false;

  get editTitle(): boolean {
    return this._editTitle;
  }

  set editTitle(value: boolean) {
    this._editTitle = value;

    if (this._editTitle) {
      // Request focus when editing is started
      setTimeout(() => this.titleInput.nativeElement.focus(), 0);

      // Open the hint popup
      this.openNewItemPopup();
    } else {
      // Close the hint popup
      this.closeNewItemPopup();
    }
  }

  // === Emitters === //
  @Output() selectItem = new EventEmitter<NavigationItem>();
  @Output() deleteItem = new EventEmitter<NavigationItem>();
  @Output() createItemWithTitle = new EventEmitter<string>();
  @Output() changeItemTitle = new EventEmitter<NavigationItem>();
  @Output() itemNeedsToBeVisible = new EventEmitter<boolean>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private _popupService: PopupService
  ) { }

  ngOnChanges(inputChanges: SimpleChanges) {
    if (inputChanges.tunifyColor) {
      this.adjustStyleToColor();
    }
    if (inputChanges.navigationItem) {
      if (this.navigationItem && !this.navigationItem.isNew) {
        this.title = this.navigationItem.title;
      } else {
        this.title = '';
      }

      this.watchNavigationItem();
    }
    if (inputChanges.heightPerItem) {
      this.calcIconValues();
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.componentDestroyed$ = null;

    this.selectItem.complete();
  }

  // === Event handlers === //
  handleClick(event: MouseEvent) {
    if (this.navigationItem) {
      if (this.navigationItem.isNew) {
        this.toggleEditTitle(event);
      } else {
        this.selectItem.emit(this.navigationItem);
      }
    }
  }

  /**
   * Add a new item or save an existing one.
   *
   * @param {string} title - The new title for the navigation item's originalData.
   */
  onPressEnter(title: string) {
    if (this.navigationItem.isNew) {
      this.createItemWithTitle.emit(title);
    } else {
      this.commitChanges(title);
    }
    this.editTitle = false;
  }

  /**
   * Save edited title to the API.
   *
   * @param {string} title - The new title for the navigation item's originalData.
   */
  commitChanges(title: string) {
    this.title = title;
    this.navigationItem.title = title;
    this.changeItemTitle.emit(this.navigationItem);
  }

  /**
   * Toggle edit mode of title if permitted.
   */
  toggleEditTitle(event: MouseEvent) {
    this.editTitle = this.navigationItem.isEditable || this.navigationItem.isNew ? !this.editTitle : false;
  }

  onDelete(event: MouseEvent) {
    this.deleteItem.emit(this.navigationItem);
  }

  onFocus() {
    this.titleHasFocus = true;
  }

  // === Private methods === //
  private calcIconValues() {
    this.iconHeight = Math.floor(this.heightPerItem / 2) + 2;
    const restHeight = this.heightPerItem - this.iconHeight;
    this.frontIconPadding = this.sanitizer.bypassSecurityTrustStyle(
      `calc(${restHeight}px / 2) 0.5rem calc(${restHeight}px / 2) 0`
    );
    this.iconPadding = this.sanitizer.bypassSecurityTrustStyle(
      `calc(${restHeight}px / 2) 0.5rem calc(${restHeight}px / 2) 0.5rem`
    );
    this.changeDetectorRef.detectChanges();
  }

  private watchNavigationItem() {
    this.cleanupSelectedSubscription();
    if (this.navigationItem) {
      this.selectedSubscription = this.navigationItem.selected$
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (value) => {
          this.adjustToSelected();
          if (this.firstTimeSelection) {
            this.firstTimeSelection = false;
          } else {
            if (value) {
              this.itemNeedsToBeVisible.emit(true);
            }
          }
          this.changeDetectorRef.detectChanges();
        }
      );
    }
  }

  private cleanupSelectedSubscription() {
    if (this.selectedSubscription) {
      this.selectedSubscription.unsubscribe();
      this.selectedSubscription = null;
    }
  }

  private adjustStyleToColor() {
    if (this.tunifyColor && this.tunifyColor === TunifyColor.BLUE) {
      this.colorStyleName = 'blueNavigationItem';
    } else if (this.tunifyColor && this.tunifyColor === TunifyColor.GREEN) {
      this.colorStyleName = 'greenNavigationItem';
    } else if (this.tunifyColor && this.tunifyColor === TunifyColor.ORANGE) {
      this.colorStyleName = 'orangeNavigationItem';
    } else {
      this.colorStyleName = '';
    }
  }

  private adjustToSelected() {
    this.showSelection = this.navigationItem && this.navigationItem.selected;
    this.showEditAndDelete = this.showSelection && this.navigationItem.isEditable;
  }

  private openNewItemPopup() {
    this._popupService.showPopup$.next({
      connector: this.navItemContainer,
      componentType: NewNavigationItemPopupComponent,
      showArrow: true,
      popupPosition: PopupPosition.RIGHT_CENTER,
      popupDirection: PopupDirection.RIGHT,
    });
  }

  private closeNewItemPopup() {
    this._popupService.hidePopup$.next(this.navItemContainer);
  }
}
