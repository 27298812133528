import {
  Component,
  Input,
  OnInit,
  Output,
  OnDestroy,
  ElementRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import {
  faAngleRight,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { fromEvent, race, Subject, timer } from 'rxjs';
import { first, mapTo, take, filter } from 'rxjs/operators';
import {
  CalendarDropdownPopupContent,
  PopupContent
} from '@components/popups/popup/interfaces';
import { PopupService } from '@service/popup.service';
import { PopupPosition } from '@components/popups/popup/enums';

export class DropdownMenuItem{
  item: any;
  title: string;
  hasSubMenu: boolean;
  isFavorite: boolean = false;
}

export interface ItemSelect {
  menuItem: DropdownMenuItem;
  dropdownItemsIndex: number;
}

@Component({
  selector: 'tun-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent
  implements OnInit, CalendarDropdownPopupContent, OnDestroy {
  // === Props === //
  @Input() connectedElementRef: ElementRef;
  @Input() public width: number;
  @Input() public dropdownName: string;
  @Input() public set menuItems(value: DropdownMenuItem[][]) {
    this._menuItems = value;
    if (this._subMenu) {
      this._subMenu.menuItems = this._menuItems.slice(1);
    }
  }
  @Input() public itemSelect$: Subject<ItemSelect>;

  // === State === //
  private _selectedItemIndex: number;
  private _menuItems: DropdownMenuItem[][];
  private _subMenu: CalendarDropdownPopupContent;

  // === ViewChildren === //
  @ViewChildren('dropdownItemRef') dropdownItemList: QueryList<ElementRef>;

  // === Getters === //
  public get selectedItemIndex(): number {
    return this._selectedItemIndex;
  }
  public get dropdownItems(): DropdownMenuItem[] {
    return this._menuItems[0];
  }
  public get faAngleRight(): IconDefinition {
    return faAngleRight;
  }

  constructor(
    public elementRef: ElementRef,
    private _popupService: PopupService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.hideSubmenu();
  }

  containsNode(node: Node) {
    return (
      this.elementRef.nativeElement.contains(node) ||
      (this._subMenu && this._subMenu.containsNode(node))
    );
  }

  hideSubmenu() {
    if (this._subMenu) {
      this._subMenu.hideSubmenu();
      this._popupService.hidePopup$.next(this._subMenu.connectedElementRef);
    }
  }

  // === Event Handlers === //
  onItemSelect(menuItem: DropdownMenuItem, index: number): void {
    this.hideSubmenu();
    this.itemSelect$.next({
      menuItem,
      dropdownItemsIndex: this._menuItems.length
    });
    this._selectedItemIndex = index;
    if (menuItem.hasSubMenu) {
      this._popupService.showPopup$.next({
        connector: this.dropdownItemList.toArray()[index],
        componentType: DropdownMenuComponent,
        popupPosition: PopupPosition.RIGHT
      });

      this._popupService.currentInstance$
        .pipe(
          take(1),
          filter(
            (instance: PopupContent) =>
              instance &&
              instance.connectedElementRef ===
                this.dropdownItemList.toArray()[index]
          )
        )
        .subscribe((instance: CalendarDropdownPopupContent) => {
          instance.menuItems = this._menuItems.slice(1);
          instance.width = this.width;
          instance.itemSelect$ = this.itemSelect$;
          this._subMenu = instance;
        });
    }
  }

  onItemHover(menuItem: DropdownMenuItem, index: number, { target: item }: Event): void {
    if (menuItem.hasSubMenu) {
      const stop$ = race(
        timer(500),
        fromEvent(item, 'mouseleave').pipe(
          mapTo(-1),
          first()
        )
      );

      stop$.subscribe((val: number) => {
        if (val !== -1) {
          this.onItemSelect(menuItem, index);
        }
      });
    }
  }
}
