import { Component, OnInit, Input, OnDestroy, SimpleChange, ViewChild, OnChanges, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import { Subject, Subscription, BehaviorSubject, pipe, combineLatest, timer } from 'rxjs';
import { PlayTokenService } from '@service/play-token.service';
import { RemotePlayStateService } from '@service/remote-play-state.service';
import { TrackForRemoteSharing, PlayModeForRemoteSharing, ConnectionType } from '@service/vo/remote/remote-objects';
import { faPlay, faPause, faStepForward } from '@fortawesome/free-solid-svg-icons';
import { RemoteActionsService } from '@service/remote-actions.service';
import { DataUpdateService } from '../../../services/realTimeCommunication/data-update.service';
import { map, takeUntil, first, mapTo, filter, delay } from 'rxjs/operators';
import { canPerformTrackActionsOnRemoteAudioType } from '../../../services/data/vo/remote/remote-objects';
import { AppService } from '../../../services/app/app.service';
import { TunifyColor } from '../../../model/enums/tunifyColor.enum';
import { SassHelperComponent } from '../../../../providers/sass-helper/sass-helper.component';
import { LoggerService } from '../../../services/loggers/logger.service';
import { TrackFavoriteBannedService } from '../../../services/app/track-favorite-banned-info.service';
import { ZoneConnectionsService } from '../../../services/authentication/zone-connections.service';
import { isSearchAction } from '../../../model/events/trackEvent';
import { TrackEvent } from '@model/events/trackEvent';
import { SearchService } from '../../../services/data/search.service';
import { PopupService } from '../../../services/app/popup.service';
import { OptionsMenuComponent } from '../../popups/options-menu/options-menu.component';
import { PopupDirection, PopupPosition } from '../../popups/popup/enums';
import { RemotePopupComponent } from '../../popups/remote-popup/remote-popup.component';

@Component({
  selector: 'tun-remote-track-controller',
  templateUrl: './remote-track-controller.component.html',
  styleUrls: ['./remote-track-controller.component.scss']
})
export class RemoteTrackControllerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  private LOGGER_CLASSNAME = 'RemoteTrackControllerComponent';

  @ViewChild(SassHelperComponent, { static: true }) private sassHelper: SassHelperComponent;
  @ViewChild('remoteButton') remoteButton: ElementRef;

  @Input() tunifyColor: TunifyColor;
  @Input() openRemoteInfoOnNoPlayer: boolean = true; //we currently have only 1 in our view, so always true for now

  faPlay = faPlay;
  faPause = faPause;
  faStepForward = faStepForward;

  public get remotePlayerActive$(){
    return this.zoneConnectionsService.currentPlayerApplicationInfo$
    .pipe(
      map(remoteApplicationInfo => remoteApplicationInfo != null)
    )
  }

  public get remoteTrackActionsAllowed$(){
    return combineLatest([this.currentRemotePlayerNotReceivingActions$, this.remoteTrack$])
    .pipe(
      map(
        ([notReceivingActions, track]) => {
          return !notReceivingActions || track != null
        }
      )
    )
  }

  public get currentRemotePlayerNotReceivingActions$(){
    return this.zoneConnectionsService.currentPlayerApplicationInfo$
    .pipe(
      map(playerApplication => {
        if (playerApplication != null){
          return !playerApplication.listensToRemoteCommands;
        }
        return false;
      })
    )
  }

  public get remoteActionSending$(){
    return this.remoteActionsService.startingTrack$
      .pipe(map(audioFile => audioFile != null));
  }

  public get remotePlaying$(){
    return this.remotePlayStateService.playMode$.pipe(map(x => x == PlayModeForRemoteSharing.playing || x == PlayModeForRemoteSharing.startingPlay));
  }

  public get remoteTrack$(){
    return combineLatest([this.remoteActionsService.startingTrack$, this.remotePlayStateService.currentTrack$, this.remotePlayStateService.remoteTrackObject$])
      .pipe(
        map(([startingTrack, remoteTrack, remoteTrackObject]) => startingTrack != null ? startingTrack : (remoteTrackObject != null ? remoteTrackObject : remoteTrack))
      );
    //return this.remotePlayStateService.currentTrack$;
  }

  public get trackActionsAllowed$(){
    return this.remotePlayStateService.currentTrack$.pipe(map(x => x != null && canPerformTrackActionsOnRemoteAudioType(x.type)));
  }

  public get buttonHeight$(){
    return this.appService.heightPerItem$;
  }

  //keep this in local var for faster feedback on clicks
  private isFavorite = false;
  private isBanned = false;
  public favoriteIconColor = "";
  public bannedIconColor = "";


  constructor(
    private playTokenService:PlayTokenService,
    private remotePlayStateService:RemotePlayStateService,
    private remoteActionsService:RemoteActionsService,
    private dataUpdateService: DataUpdateService,
    private appService: AppService,
    private loggerService: LoggerService,
    private trackFavoriteBannedService: TrackFavoriteBannedService,
    private changeDetectorRef: ChangeDetectorRef,
    private zoneConnectionsService: ZoneConnectionsService,
    private searchService: SearchService,
    private popupService: PopupService
  ) {
  }

  ngOnInit() {
    this.trackFavoriteBannedService.currentRemoteTrackIsFavorite$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (value) =>{
        this.isFavorite = value;
        this.adjustFavoriteIconColor();
      }
    );

    this.trackFavoriteBannedService.currentRemoteTrackIsBanned$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (value) =>{
        this.isBanned = value;
        this.adjustBannedIconColor();
      }
    )

    this.remotePlaying$
      .pipe(
        delay(1),
        takeUntil(this.destroyed$)
      )
      .subscribe(
      ()=>{
        this.changeDetectorRef.detectChanges();
      }
    )

    this.zoneConnectionsService.currentPlayerApplicationInfo$
    .pipe(
      delay(50),
      map(remoteApplicationInfo => remoteApplicationInfo != null),
      filter(v => v == false),
      takeUntil(this.destroyed$)
    )
    .subscribe(
      ()=>{
        //open the remote info popup as soon as no player is active any longer
        this.openRemoteInfo(true);
      }
    )

    this.remoteTrack$
    .pipe(
      delay(1),
      takeUntil(this.destroyed$)
    )
    .subscribe(
      ()=>{
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  private initDoneForTooltips = false;
  ngAfterViewInit() {
    //wait a bit before showing any tooltips
    timer(200)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.initDoneForTooltips = true;
        this.openRemoteInfo(true);
      });
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  ngOnChanges(inputChanges: { [propName: string]: SimpleChange }) {
    if (inputChanges['tunifyColor']) {
      this.adjustsStyle();
    }
  }

  private normalColor = "white-1";
  private focusColor = "white-1";
  private adjustsStyle(){
    if (this.tunifyColor == TunifyColor.BLUE) {
      this.focusColor = "blue-player-button";
    }else if (this.tunifyColor == TunifyColor.GREEN) {
      this.focusColor = "green-player-button";
    } else if (this.tunifyColor == TunifyColor.ORANGE) {
      this.focusColor = "orange-player-button";
    } else {
      this.loggerService.error(this.LOGGER_CLASSNAME, "adjustsStyle", "tunifyColor not recognized");
      this.focusColor = "blue-player-button";
    }
    this.adjustFavoriteIconColor();
    this.adjustBannedIconColor();
  }

  private adjustFavoriteIconColor() {
    this.favoriteIconColor = this.sassHelper.readProperty(this.isFavorite ? this.focusColor : this.normalColor);
    this.changeDetectorRef.detectChanges();
  }

  private adjustBannedIconColor() {
    this.bannedIconColor = this.sassHelper.readProperty(this.isBanned ? this.focusColor : this.normalColor);
    this.changeDetectorRef.detectChanges();
  }


  public onTrackEvent(trackEvent: TrackEvent) {
    if (isSearchAction(trackEvent.action)) {
      this.searchService.handleSearchOnTrack(trackEvent);
    } else {
      this.loggerService.error(this.LOGGER_CLASSNAME, "onTrackEvent", "we need to handle action " + trackEvent.action);
    }
  }

  public onTogglePlay(){
    if (this.remotePlayStateService.currentTrack){
      if (this.remotePlayStateService.playMode != PlayModeForRemoteSharing.playing && this.remotePlayStateService.playMode != PlayModeForRemoteSharing.startingPlay){
        this.remoteActionsService.remotePlay();
      }else{
        this.remoteActionsService.remotePause();
      }
    }else{
      this.loggerService.warn(this.LOGGER_CLASSNAME, "onTogglePlay", "no remote track known -> not going to play");
    }

  }

  public onNext(){
    this.remoteActionsService.remoteNext();
  }

  public onSeekToTime(time:number){
    //todo
  }

  public onToggleFavorite() {
    //already adjust internal values, for faster user experience
    this.isFavorite = !this.isFavorite;
    this.adjustFavoriteIconColor();
    if (this.isFavorite) {
      this.isBanned = false;
      this.adjustBannedIconColor();
    }

    this.remoteActionsService.remoteAdjustFavorite(!this.trackFavoriteBannedService.currentRemoteTrackIsFavorite);
  }

  public onToggleBanned() {
    //already adjust internal values, for faster user experience
    this.isBanned = !this.isBanned;
    this.adjustBannedIconColor();
    if (this.isBanned) {
      this.isFavorite = false;
      this.adjustFavoriteIconColor();
    }

    this.remoteActionsService.remoteAdjustBanned(!this.trackFavoriteBannedService.currentRemoteTrackIsBanned);
  }

  openRemoteInfo(showPopup: boolean) {
    if (showPopup) {
      this.popupService.showPopup$.next({
        connector: this.remoteButton,
        componentType: RemotePopupComponent,
        animationDuration: 300,
        popupDirection: PopupDirection.DOWN,
        showArrow: true,
        popupPosition: PopupPosition.BOTTOM_CENTER,
      });
    } else {
      this.popupService.hidePopup$.next(this.remoteButton);
    }
  }


}
