import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule }    from '@angular/forms';
import {LoginRoutingModule} from "./login-routing.module";
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconsModule } from '@components/icons/icons.module';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ZoneConnectionsComponent } from './zone-connections/zone-connections.component';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { ZoneConnectionViewComponent } from './zone-connections/zone-connection-view/zone-connection-view.component';
import { ConnectZoneViewComponent } from './zone-connections/connect-zone-view/connect-zone-view.component';
import { UtilsModule } from '@util/utils.module';
import { ScrollbarModule } from 'src/app/scrollbar/scrollbar.module';
import { NgOtpInputModule } from  'ng-otp-input';
import { NgxSplitInputModule } from 'ngx-splitinput';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { ComponentsV5Module } from '@components/components-v5/components-v5.module';
import { ResizeModule } from '@components/resize/resize.module';


@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        LoginRoutingModule,
        FlexLayoutModule,
        IconsModule,
        BrowserModule,
        FontAwesomeModule,
        MatGridListModule,
        UtilsModule,
        ScrollbarModule,
        NgOtpInputModule,
        NgxSplitInputModule,
        DigitOnlyModule,
        ComponentsV5Module,
        ResizeModule
    ],
    declarations: [
        ZoneConnectionsComponent,
        ZoneConnectionViewComponent,
        ConnectZoneViewComponent
    ]
})
export class LoginModule {}
