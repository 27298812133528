<tun-navigation-menu  
  #menuComponent
  fxFlex fxLayout="column"
  [mainNavigationItems]="mainNavigationItems"
  [heightPerItem]="heightPerItem" 
  [helpMessage]="helpMessage"
  [loading]="loadingMenuContent$ | async"
  [showLoadError]="loadingError$ | async"
  (selectMainNavigationItem)="onSelectMainNavigationItem($event)"
  (selectNavigationItem)="onSelectNavigationItem($event)"
  (reloadMenuContent)="onReloadMenuContent()"
>

</tun-navigation-menu>