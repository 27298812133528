import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class ObservableComponent implements OnDestroy {
  // === Observables === //
  private _destroy$ = new Subject();
  protected destroy$ = this._destroy$.asObservable();

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$ = null;
  }
}
