import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MusicPlayerService } from '@service/music-player.service';
import { LoggerService } from '@service/loggers/logger.service';
import { AudioFileWithPlayInfo } from '@service/audioFileWithPlayInfo';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { PlayStateService, ActivePlayer } from '@service/play-state.service';
import { AudioFile } from '@model/audioFile';
import { QueueService } from '@service/queue.service';
import { AppService } from '@service/app.service';
import { TrackFavoriteBannedService } from '@service/track-favorite-banned-info.service';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { PlayableAudio } from '../../../../../model/audioFile';
import { ZoneConnectionsService } from '../../../../../services/authentication/zone-connections.service';
import { ApplicationMode } from '@service/authentication/zone-connections.service';
import { map } from 'rxjs/operators';
import { TrackManipulationControllerService } from '../../../../../services/audio/track-manipulation-controller.service';

@Component({
  selector: 'tun-player-panel',
  templateUrl: './player-panel.component.html',
  styleUrls: ['./player-panel.component.scss']
})
export class PlayerPanelComponent implements OnInit, OnDestroy {

  private LOGGER_CLASSNAME = 'PlayerPanelComponent';

  public playing = false;
  public showNext = true;
  public audioFile: PlayableAudio = null;
  public audioFileWithPlayInfo: AudioFileWithPlayInfo = null;
  public heightPerItem = 0;

  //we need to make this public to use it in our html page
  public ApplicationMode = ApplicationMode;

  public get applicationMode$(){
    return this.zoneConnectionsService.applicationMode$;
  }

  public get isActivePlayer$(){
    return this.playStateService.activePlayer$
      .pipe(map(activePlayer => activePlayer == ActivePlayer.activePlayerRight))
  }

  @Input() public tunifyColor: TunifyColor;

  constructor(private musicPlayerService: MusicPlayerService,
              private musicManipulationService: MusicManipulationService,
              private trackManipulationControllerService: TrackManipulationControllerService,
              private queueService: QueueService,
              private zoneConnectionsService: ZoneConnectionsService,
              private playStateService: PlayStateService,
              private trackFavoriteBannedService:TrackFavoriteBannedService,
              private appService:AppService,
              private loggerService: LoggerService,
              private activeMusicSelectionService: ActiveMusicSelectionService) { }

  private currentAudioFileWithPlayInfoSubscription: Subscription;
  private nextAudioFileSubscription: Subscription;
  private activePlayerSubscription: Subscription;
  private selectedPlayerViewSubscription: Subscription;
  private heightPerItemSubscription: Subscription;
  private anyFavoriteBannedValueChangedSubscription:Subscription;
  ngOnInit() {

    this.currentAudioFileWithPlayInfoSubscription = this.musicPlayerService.currentActiveAudioFileWithPlayInfo$.subscribe(
      audioFileWithPlayInfo => {
        this.currentAudioFileWithPlayInfo = audioFileWithPlayInfo;
        this.syncAudioFileForView();
      }
    );

    this.nextAudioFileSubscription = this.playStateService.nextPlayableAudio$.subscribe(
      audioFile =>{
        this.nextAudioFile = audioFile;
        this.syncAudioFileForView();
      }
    )

    this.activePlayerSubscription = this.playStateService.activePlayer$.subscribe(
      (activePlayer) => {
        this.activePlayer = activePlayer;
        this.syncAudioFileForView();
      }
    );

    this.selectedPlayerViewSubscription = this.activeMusicSelectionService.selectedPlayerView$.subscribe(
      (playerView) => {
        this.showNext  =  playerView !== TunifyColor.ORANGE;
      }
    );

    this.heightPerItemSubscription = this.appService.heightPerItem$.subscribe(
      height => {
        this.heightPerItem = height;
      }
    );

    this.anyFavoriteBannedValueChangedSubscription = this.trackFavoriteBannedService.anyValueChanged$.subscribe(
      value => {
        this.syncFavoriteBanned();
      }
    );
    //we also need to sync the values on startup (emitter will only fire on changes, not on startup)
    this.syncFavoriteBanned();

  }

  private activePlayer: ActivePlayer = null;
  private currentAudioFileWithPlayInfo: AudioFileWithPlayInfo;
  private nextAudioFile: PlayableAudio;

  private syncAudioFileForView(){
    if (this.isActivePlayer){
      if (this.currentAudioFileWithPlayInfo){
        this.audioFile = this.currentAudioFileWithPlayInfo.audioFile;
      }else{
        this.audioFile = null;
      }
      this.audioFileWithPlayInfo = this.currentAudioFileWithPlayInfo;
    }else{
      this.audioFile = this.nextAudioFile;
      this.audioFileWithPlayInfo = null;
    }
  }

  private get isActivePlayer(){
    return this.activePlayer === ActivePlayer.activePlayerRight;
  }

  public isFavorite: boolean = false;
  public isBanned: boolean = false;
  private syncFavoriteBanned(){
    if (this.isActivePlayer){
      this.isFavorite = this.trackFavoriteBannedService.currentTrackIsFavorite;
      this.isBanned = this.trackFavoriteBannedService.currentTrackIsBanned;
    }else{
      this.isFavorite = this.trackFavoriteBannedService.nextTrackIsFavorite;
      this.isBanned = this.trackFavoriteBannedService.nextTrackIsBanned;
    }
  }

  ngOnDestroy(){
    this.currentAudioFileWithPlayInfoSubscription.unsubscribe();
    this.currentAudioFileWithPlayInfoSubscription = null;
    this.nextAudioFileSubscription.unsubscribe();
    this.nextAudioFileSubscription = null;
    this.activePlayerSubscription.unsubscribe();
    this.activePlayerSubscription = null;
    this.selectedPlayerViewSubscription.unsubscribe();
    this.selectedPlayerViewSubscription = null;
    this.heightPerItemSubscription.unsubscribe();
    this.heightPerItemSubscription = null;
    this.anyFavoriteBannedValueChangedSubscription.unsubscribe();
    this.anyFavoriteBannedValueChangedSubscription = null;
  }

  public onTogglePlay(toValue: boolean) {
    if (this.activePlayer === ActivePlayer.activePlayerRight){
      if (toValue) {
        this.loggerService.debug(this.LOGGER_CLASSNAME, "onTogglePlay", "User pressed play");
        this.musicManipulationService.play();
      } else {
        this.loggerService.debug(this.LOGGER_CLASSNAME, "onTogglePlay", "User pressed pause");
        this.musicManipulationService.pause();
      }
    }else{
      if (toValue) {
        if (this.audioFile instanceof AudioFile){
          this.trackManipulationControllerService.playAudioFile(this.audioFile);
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, "onTogglePlay", "We can not start this kind of PlayableAudio: " + this.audioFile);
        }
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, "onTogglePlay", "We should not need to pause the inactive player");
      }
    }
  }

  public onToggleFavorite(toValue: boolean){
    if (this.audioFile != null && this.audioFile instanceof AudioFile){
      this.trackFavoriteBannedService.adjustIsFavorite(this.audioFile, toValue);
    }
  }

  public onToggleBanned(toValue: boolean){
    if (this.audioFile != null && this.audioFile instanceof AudioFile){
      this.trackFavoriteBannedService.adjustIsBanned(this.audioFile, toValue);
    }
  }

  public onNext() {
    this.musicManipulationService.next();
  }

  public onSeekToTime(time: number) {
    this.musicManipulationService.seekToTime(time);
  }

  public onDropTrack(track: AudioFile){
    if (this.isActivePlayer){
      this.musicManipulationService.prepareAsCurrentTrack(track);
    }else{
      this.musicManipulationService.prepareAsNextTrack(track);
    }
  }

}
