<div #menuPanel (tunResize)="onResize($event)" fxFlex class="flexBoxMinimumSizing lightPanelBackground menuPanel layerContainer">

  <div class="layer">
    <div #helpMessageConnector [style.height.px]="5*heightPerItem" [style.width.percentage]="100">
    </div>
  </div>

  <!-- loading state -->
  <div *ngIf="loading" class="layer feedback-container" fxLayout="column">
    <tun-loader *ngIf="loading" text="general.loading"></tun-loader>
  </div>

  <!-- loading error state -->
  <div *ngIf="!loading && showLoadError" class="layer feedback-container" fxLayout="column">
    <p>{{'general.loadError' | translate}}</p>
    <button (click)="onReloadMenuContent()" class="retryButton">
      <p>
        {{'general.tryAgain' | translate }}
      </p>
    </button>
  </div>

  <!-- normal menu content state -->
  <!-- only show the actual menu once the size is ok to avoid weird drawings when component is first rendered-->
  <div *ngIf="sizeIsOk && !loading && !showLoadError" class="layer">

    <!--
      <tun-scrollbar
        #scroller
        [style.height.%]="100"
      >
      -->

    <tun-main-navigation-item-view *ngFor="let mainNavigationItem of mainNavigationItems; let i = index;"
      class="blueMainNavigationItem" [mainNavigationItem]="mainNavigationItem" [mainNavigationItemIndex]="i"
      [heightPerItem]="heightPerItem" [maxHeightForContent]="maxHeightForContent"
      (selectMainNavigationItem)="onSelectMainNavigationItem($event)"
      (selectNavigationItem)="onSelectNavigationItem($event)" (deleteNavigationItem)="onDeleteNavigationItem($event)"
      (createItemWithTitle)="onCreateItemWithTitle($event)"
      (changeNavigationItemTitle)="onChangeNavigationItemTitle($event)" (dropTrack)=onDropTrack($event)>
    </tun-main-navigation-item-view>

    <!--
      </tun-scrollbar>
      -->
  </div>
</div>
