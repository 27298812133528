import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { AppService } from "@service/app.service";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { ObservableComponent } from "@components/app-components/observable-component/observable-component";
import { AppVersionService, MAJOR_VERSION } from "@service/app-version.service";
import { MatDialog } from "@angular/material/dialog";
import { UpgradeVersionPopupComponent } from "@components/popups-v5/upgrade-version-popup/upgrade-version-popup.component";

@Component({
  selector: "tun-menu-orange-panel",
  templateUrl: "./menu-orange-panel.component.html",
  styleUrls: ["./menu-orange-panel.component.scss"]
})
export class MenuOrangePanelComponent extends ObservableComponent
  implements OnInit {

    get newVersionAvailable$(){
      return this.appVersionService.allowedVersionsSubject$
        .pipe(
          map(
            () => {
              return this.appVersionService.showLinkForVersion(MAJOR_VERSION.V5)
            }
          )
        )
    }
    // === Props === //
  @Input() widthForMenuPanel = 0;

  // === Getters === //
  get heightPerItem$() {
    return this._appService.heightPerItem$;
  }

  constructor(
    private _appService: AppService,
    private appVersionService: AppVersionService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {}


  onUpgradeVersion(){
    const dialogRef = this.dialog.open(UpgradeVersionPopupComponent, {
      width: UpgradeVersionPopupComponent.widthForPopup,
      hasBackdrop: true,
      backdropClass: 'dark-blurry-backdrop',
      panelClass: 'custom-popup-container',
      data: {confirm: false}
    });

    dialogRef.afterClosed()
    .pipe(
      takeUntil(
        this.destroy$
      )
    )
    .subscribe(result => {
      if (result && result.confirm){
        this.appVersionService.changeVersionToUse(MAJOR_VERSION.V5);
      }
    });
  }
}

