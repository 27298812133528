import { Component, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { environment } from 'src/environments/environment';
import { fromEvent } from 'rxjs';
import { map, first } from 'rxjs/operators';

@Component({
  selector: 'tun-title-box',
  templateUrl: './title-box.component.html',
  styleUrls: ['./title-box.component.scss']
})
export class TitleBoxComponent {


  //expose the enum so it can be used in the view
  public TunifyColor = TunifyColor;

  //todo: support showing a time depending image (christmas, soccer, carnival, ..)
  public showImage = false;

  constructor() { }

  @Input() tunifyColor: TunifyColor;
  @Input() title: string;
  @Input() heightPerItem = 0;


  logChangeDetection(){
    if (environment.logChangeDetection){
      let now = new Date();
      console.log(now.getMinutes() + ":" + now.getSeconds() + "." + now.getMilliseconds() + " -> change detection occured");
    }
  }
}
