<sass-helper></sass-helper>
<div #container class="container">
  <div *ngIf="isQueue" class="watermark-container">
    <div class="{{smallFontForQueue?'watermarkSmall':'watermarkNormal'}} {{isRemote ? 'watermarkToBottom':''}}">
      <div *ngIf="isRemote" class="watermark-remote-icon-container">
        <tun-remote-icon class="watermark-remote-icon"></tun-remote-icon>
      </div>
      <div>{{'general.queue' | translate}}</div>
    </div>
  </div>

  <div #table class="audio-files-table">
    <div
      class="audio-files-table-vertical-lines"
      *ngIf="isQueue && disableScrollbar"
    >
      <div
        [style.width]="firstColWidth"
        [style.border-right-color]="borderColor"
      ></div>
      <div
        [style.width]="secondColWidth"
        [style.border-right-color]="borderColor"
      ></div>
    </div>

    <cdk-virtual-scroll-viewport
      #scrollViewport
      [class.disabled]="isUserDragging"
      (scroll)="onScroll($event)"
      [style.height]="isQueue ? '100%' : scrollbarHeightStyle"
      track-index="-1"
      [itemSize]="heightPerItem"
      [minBufferPx]="heightPerItem * 10"
      [maxBufferPx]="heightPerItem * 20"
    >
      <ng-container
        *cdkVirtualFor="
          let song of playlist | songSorter: songSortMode:audioFileProperty;
          let i = index;
          trackBy: trackByFn;
          templateCacheSize: 20
        "
      >

        <tun-song-grid-row
          [attr.track-index]="i"
          [draggable]="draggable"
          [index]="i"
          [showPopupDelete]="showPopupDelete"
          [showFrontDelete]="showFrontDelete"
          [showAddArrow]="showAddArrow"
          [tableRowHoverId]="tableRowHoverId"
          [isBeingDraggedOver]="tableRowHoverId - 1 === i"
          [dragLineBorderColor]="dragLineBorderColor"
          [borderColor]="borderColor"
          [songGridColors]="localSongGridColors"
          [songSortMode]="songSortMode"
          [song]="song"
          [isQueue]="isQueue || isEditable"
          [isUserDragging]="isUserDragging"
          [firstColWidth]="firstColWidth"
          [secondColWidth]="secondColWidth"
          [thirdColWidth]="thirdColWidth"
          (trackEvent)="onTrackEvent($event)"
          [audioFileProperty]="audioFileProperty"
        >
        </tun-song-grid-row>

      </ng-container>
    </cdk-virtual-scroll-viewport>

  </div>
</div>
