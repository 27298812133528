import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AudioFileProperty,
  formatTrackProperty,
  isTrackPropertySearchable,
  getPropertyLabel,
} from '@model/enums/audioFileProperty';
import { Song } from '@model/song';
import { TrackEvent, TrackAction, searchActionForTrackProperty } from '@model/events/trackEvent';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionsService } from '../../../../services/data/subscriptions.service';

interface SongDetail {
  label: string;
  value: string | number;
  searchable: boolean;
  property: AudioFileProperty;
}

@Component({
  selector: 'tun-song-details',
  templateUrl: './song-details.component.html',
  styleUrls: ['./song-details.component.scss'],
})
export class SongDetailsComponent implements OnInit {
  // === Constants === //
  readonly SONG_DETAILS = [
    AudioFileProperty.YEAR,
    AudioFileProperty.BPM,
    AudioFileProperty.MUSIC_STYLE,
    AudioFileProperty.MOOD,
    AudioFileProperty.DANCING_STYLE,
    AudioFileProperty.DURATION,
  ];

  // === Props === //
  @Input() showNavigation: boolean;
  @Input() showDelete: boolean;
  @Input() audioFileProperty: AudioFileProperty;
  @Input() set song(value: Song) {
    this._song = value;
    this._configureSongDetails(value);
  }

  // === State === //
  private _song: Song = new Song();
  private _songDetails: SongDetail[] = [];
  private _activeLabelIndex = 0;

  // === Emitters === //
  @Output() public propertyselect = new EventEmitter<AudioFileProperty>();
  @Output() public trackEvent = new EventEmitter<TrackEvent>();
  @Output() public navigate = new EventEmitter<number>();

  // === Getters === //
  get songDetails(): SongDetail[] {
    return this._songDetails;
  }

  get title(): string {
    return this._song.title;
  }

  get artist(): string {
    return this._song.group;
  }

  get activeLabelIndex(): number {
    return this._activeLabelIndex;
  }

  get searchEnabled(): boolean{
    return this.subscriptionsService.accessRights != null && this.subscriptionsService.accessRights.search && environment.enableSearch;
  }

  //make enum available in html
  public TrackAction = TrackAction;

  constructor(private _datePipe: DatePipe,
              private _translateService: TranslateService,
              private subscriptionsService: SubscriptionsService) {}

  ngOnInit() {}

  // === Event handlers === //
  onClickLabel(songDetail: SongDetail, labelIndex: number) {
    this._activeLabelIndex = labelIndex;
    this.propertyselect.emit(songDetail.property);
  }

  onNavigate(index: number) {
    this.navigate.emit(index);
  }

  onClickSearch(songDetail: SongDetail, labelIndex: number){
    this.trackEvent.emit(new TrackEvent(searchActionForTrackProperty(songDetail.property), this._song));
  }

  onSearchAction(trackAction: TrackAction){
    this.trackEvent.emit(new TrackEvent(trackAction, this._song));
  }

  // === Private methods === //
  private _configureSongDetails(song: Song) {

    this.SONG_DETAILS.forEach((audioFileProperty, index) => {
      const detail: SongDetail = {
        label: getPropertyLabel(this._translateService, audioFileProperty),
        value: formatTrackProperty(song, audioFileProperty),
        searchable: this.searchEnabled && isTrackPropertySearchable(
          song,
          audioFileProperty
        ),
        property: audioFileProperty
      };
      this._songDetails[index] = detail;
    });
  }
}
