<div
  (click)="clickMainItem(mainNavigationItem)"
  class="mainNavigationItem {{ mainItemColorStyle }}"
  [style.height.px]="heightPerItem"
>
  <div fxFlex class="flexBoxMinimumSizing mainNavigationItemTitle {{ mainItemStyle }}">
    <span fxFlex>{{ mainNavigationItem.titleNeedsTranslation ? (mainNavigationItem.title | translate) : mainNavigationItem.title}}</span>
    <span *ngIf="mainNavigationItem.showAmountItems && mainNavigationItem.items != null" class="amountItemsLabel">{{amountNormalItems()}}</span>
  </div>
  <!--
  <div *ngIf="mainNavigationItem.showAmountItems" class="amountItemsContainer">
    <span class="amountItemsLabel">3</span>
  </div>
  -->
</div>
<div
  class="flexBoxMinimumSizing expandable mainNavigationItemContent"
  #expandableContent
  fxLayout="column"
>
  <tun-scrollbar
    #scroller
    [style.height.%]="100"
    [iconColor]="scrollbarColor"
    [disabled]="!showVScrollBarWhenOpen"
    [tunScrollBarVisible]="showVScrollBarWhenOpen"
  >
    <tun-navigation-item-view
      *ngFor="let navigationItem of mainNavigationItem.items; let i = index"
      [attr.item-index]="i"
      (selectItem)="onSelectItem($event)"
      (deleteItem)="onDeleteItem($event)"
      (createItemWithTitle)="onCreateItemWithTitle($event)"
      (changeItemTitle)="onChangeItemTitle($event)"
      [heightPerItem]="heightPerItem"
      [mainNavigationItemIndex]="mainNavigationItemIndex"
      [navigationItem]="navigationItem"
      [tunifyColor]="mainNavigationItem.tunifyColor"
      (itemNeedsToBeVisible)="makeItemVisible(i)"
      [isBeingDraggedOver]="dragOverItemIndex === i"
    >
    </tun-navigation-item-view>
  </tun-scrollbar>
</div>
