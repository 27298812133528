import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  AfterViewChecked
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ObservableComponent } from '@components/app-components/observable-component/observable-component';
import { AudioFile } from '@model/audioFile';
import { AudioFileProperty } from '@model/enums/audioFileProperty';
import { SongSortMode } from '@model/enums/songSortMode';
import {
  isSearchAction,
  TrackAction,
  TrackEvent
} from '@model/events/trackEvent';
import {
  TrackIndexAction,
  TrackIndexEvent
} from '@model/events/trackIndexEvent';
import { SongGridColors } from '@model/fieldModels/songGridColors';
import { AppService } from '@service/app.service';
import { DragDropService } from '@service/drag-drop.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { SearchService } from '@service/search.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { merge, Subject, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SassHelperComponent } from 'src/providers/sass-helper/sass-helper.component';
import { SongGridRowComponent } from '../song-grid-row/song-grid-row.component';
import { TranslateService } from '@ngx-translate/core';
import { TrackManipulationControllerService } from '../../../../services/audio/track-manipulation-controller.service';

@Component({
  selector: 'tun-song-grid',
  templateUrl: './song-grid.component.html',
  styleUrls: ['./song-grid.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter, :leave', animate('300ms ease-in-out'))
    ])
  ]
})
export class SongGridComponent extends ObservableComponent
  implements OnInit, AfterViewChecked, OnDestroy, OnChanges {

  @HostBinding('@fadeIn')
  // === Constants === //
  readonly firstColPercentage = 0.39;
  readonly secondColPercentage = 0.32;
  readonly thirdColPercentage = 0.29;

  // === Props === //
  @Input() showAddArrow: boolean;
  @Input() songGridColors: SongGridColors;
  @Input() songSortMode: SongSortMode;
  @Input() draggable = false;
  @Input() playlist: AudioFile[];
  @Input() isQueue = false;
  @Input() isRemote = false;
  @Input() isEditable = false;
  @Input() disableScrollbar = false;
  @Input() showPopupDelete;
  @Input() showFrontDelete = false;
  @Input() set borderColor(value: string) {
    this._borderColor = value || '#373f41';
  }

  @Input()
  set maxScrollbarHeight(value: number) {
    this.scrollbarHeightStyle = `${value}px`;
  }

  // === ViewChildren === //
  @ViewChild(SassHelperComponent, { static: true })
  private sassHelper: SassHelperComponent;
  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('table', { static: true }) table: ElementRef;
  @ViewChildren(SongGridRowComponent) rows: QueryList<SongGridRowComponent>;


  // === State === //
  private _scrollable: boolean;
  private _scrollWrapper: HTMLElement;
  private recalcAboveY = undefined;
  private recalcUnderY = undefined;
  private draggingTimeout: NodeJS.Timer;
  private hasEntered = false;
  private _heightPerItem: number;
  private _scrollPanel$ = new Subject<Event>();
  private _borderColor = '#373f41';

  isUserDragging = false;
  tableRowHoverId = -1;
  dragLineBorderColor = '#FFFFFF';
  scrollbarHeightStyle: SafeStyle = '';
  firstColWidth: SafeStyle;
  secondColWidth: SafeStyle;
  thirdColWidth: SafeStyle;
  audioFileProperty: AudioFileProperty;
  closeAllPopupTrigger$ = new Subject<void>();
  localSongGridColors; //use a local state of the songGridColors -> if the same object is used into multiple instances, the translations are made on the original object (that is not altered)

  // === Emitters === //
  @Output() trackIndexEvent = new EventEmitter<TrackIndexEvent>();
  @Output() trackEvent = new EventEmitter<TrackEvent>();

  // === Getters === //
  get scrollPanel$() {
    return this._scrollPanel$ && this._scrollPanel$.asObservable();
  }
  get heightPerItem() {
    return this._heightPerItem;
  }

  get borderColor() {
    return this._borderColor;
  }

  get smallFontForQueue(){
    return this._zoneConfigurationService.usedLanguage == "de";
  }

  constructor(
    private ngZone: NgZone,
    private dragDropService: DragDropService,
    private cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private appService: AppService,
    private _zoneConfigurationService: ZoneConfigurationService,
    private _searchService: SearchService,
    private _musicManipulationService: MusicManipulationService,
    private _trackManipulationControllerService: TrackManipulationControllerService,
    private _renderer2: Renderer2
  ) {
    super();
  }

  ngOnInit() {
    this.dragDropService.isUserDragging$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isUserDragging => {
        this.isUserDragging = isUserDragging;
        this.cdRef.detectChanges(); // trigger the bindings so that the new value is passed through to the row Components
      });

    this.appService.heightPerItem$
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this._heightPerItem = val;
        this.cdRef.detectChanges();
      });

    this._zoneConfigurationService.audioFileProperty$
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.audioFileProperty = val;
        this.cdRef.detectChanges();
      });

    this.calculateColumnWidths();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.calculateColumnWidths();
    if (simpleChanges.songGridColors) {
      if (this.sassHelper) {
        this.localSongGridColors = this.fetchColors(this.songGridColors);
        this.borderColor = this.localSongGridColors._borderColor;
        this.dragLineBorderColor = this.localSongGridColors._dragLineBorderColor;
      }
    }
    if (simpleChanges.playlist) {
      this.closeAllPopupTrigger$.next();
    }
  }

  private dragHandlersAdded = false;
  ngAfterViewChecked() {
    this.calculateColumnWidths();
    if (this.draggable && !this.dragHandlersAdded) {
      this.ngZone.runOutsideAngular(() => {
        this.table.nativeElement.addEventListener('dragover', this.onDragOver);
        this.table.nativeElement.addEventListener(
          'dragenter',
          this.onDragEnter
        );
        this.table.nativeElement.addEventListener(
          'dragleave',
          this.onDragLeave
        );
        this.table.nativeElement.addEventListener('drop', this.onDrop);
      });
      this.dragHandlersAdded = true;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.dragHandlersAdded) {
      this.ngZone.runOutsideAngular(() => {
        this.table.nativeElement.removeEventListener(
          'dragover',
          this.onDragOver
        );
        this.table.nativeElement.removeEventListener(
          'dragenter',
          this.onDragEnter
        );
        this.table.nativeElement.removeEventListener(
          'dragleave',
          this.onDragLeave
        );
        this.table.nativeElement.removeEventListener('drop', this.onDrop);
      });
      this.dragHandlersAdded = false;
    }
  }

  private hoveredTrackIndex(event: DragEvent): number {
    const composedPath = event.composedPath();

    for (let i = 0; i < composedPath.length; i++) {
      const element = composedPath[i];
      if (
        element instanceof HTMLElement &&
        element.hasAttribute('track-index')
      ) {
        return Number.parseInt(element.getAttribute('track-index'), 10);
      }
    }

    return -1;
  }

  calculateDropIndex = (event: DragEvent) => {
    let dropIndex = this.hoveredTrackIndex(event);
    if (dropIndex > -1) {
      // here we could keep track of the offset and only calculate the dropIndex when a certain threshold is reached
      if (event.offsetY > this._heightPerItem / 2) {
        dropIndex++;
      }
      return dropIndex;
    }
    return this.playlist.length;
  };

  onDragEnter = (event: DragEvent) => {
    if (this.dragDropService.draggingAudioFile === null) {
      return;
    }

    event.stopPropagation();

    this.recalcAboveY = undefined;
    this.recalcUnderY = undefined;

    timer(2000)
      .pipe(
        takeUntil(
          merge(
            this.dragDropService.isUserDragging$.pipe(
              filter(isUserDragging => !isUserDragging)
            ),
            this.destroy$
          )
        )
      )
      .subscribe(() => {
        this.dragDropService.isUserDraggingLong = true;
      });
  };

  onDragOver = (event: DragEvent) => {
    if (this.dragDropService.draggingAudioFile === null) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();

    if (
      (this.dragDropService.isUserDraggingLong ||
        this.dragDropService.dragStartedFromQueue) &&
      (this.recalcAboveY === undefined ||
        this.recalcUnderY === undefined ||
        event.y > this.recalcAboveY ||
        event.y < this.recalcUnderY)
    ) {
      const tableRowHoverId = this.calculateDropIndex(event);
      if (this.tableRowHoverId !== tableRowHoverId) {
        this.tableRowHoverId = tableRowHoverId;
        this.cdRef.detectChanges();
      }

      const yOffCurrentTarget = event.y - event.offsetY;
      if (event.offsetY > this._heightPerItem / 2) {
        this.recalcUnderY = yOffCurrentTarget + this._heightPerItem / 2;
        this.recalcAboveY = yOffCurrentTarget + this._heightPerItem;
      } else {
        this.recalcUnderY = yOffCurrentTarget;
        this.recalcAboveY = yOffCurrentTarget + this._heightPerItem / 2;
      }
    }
  };

  onDragLeave = (event: DragEvent) => {
    if (this.dragDropService.draggingAudioFile === null) {
      return;
    }
    if (this.draggingTimeout) {
      clearTimeout(this.draggingTimeout);
    }
    event.stopPropagation();
    const x = event.x;
    const y = event.y;
    const offsetLeft = this.container.nativeElement.offsetLeft;
    const offsetHeight = this.container.nativeElement.offsetHeight;
    const offsetTop = this.container.nativeElement.offsetTop;
    const offsetWidth = this.container.nativeElement.offsetWidth;
    if (
      x < offsetLeft ||
      x > offsetLeft + offsetWidth - 1 ||
      y < offsetTop ||
      y > offsetTop + offsetHeight - 1
    ) {
      if (this.draggingTimeout) {
        clearTimeout(this.draggingTimeout);
      }

      this.tableRowHoverId = -1;
      this.cdRef.detectChanges();
    }
  };

  // Called when an audiofile is about to be dropped.
  // This happens in the component where the audiofile will be added to the playlist.
  onDrop = (event: DragEvent) => {
    if (this.dragDropService.draggingAudioFile === null) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();

    if (this.draggingTimeout) {
      clearTimeout(this.draggingTimeout);
    }

    this.tableRowHoverId = -1;

    const audioFile: AudioFile = this.dragDropService.draggingAudioFile;

    let index =
      this.dragDropService.isUserDraggingLong ||
      this.dragDropService.dragStartedFromQueue
        ? this.calculateDropIndex(event)
        : this.playlist.length;

    let action: TrackIndexAction;
    if (
      this.dragDropService.dragStartedFromQueue &&
      (this.isEditable || this.isQueue)
    ) {
      action = TrackIndexAction.MOVE_TO_INDEX;
      const indexOfTrack = this.playlist.indexOf(audioFile);
      if (indexOfTrack >= 0 && indexOfTrack < index){
        index--;
      }
    } else {
      action = TrackIndexAction.ADD_AT_INDEX;
    }
    this.dragDropService.isUserDraggingLong = false;

    const trackIndexEvent: TrackIndexEvent = new TrackIndexEvent(
      action,
      audioFile,
      index
    );

    this.ngZone.run(() => {
      this.trackIndexEvent.emit(trackIndexEvent);
    });
  };

  trackByFn(index, song: AudioFile) {
    return song.id;
  }

  fetchColors = (colors: SongGridColors): SongGridColors => {
    if (colors) {
      return new SongGridColors()
        .borderColor(this.sassHelper.readProperty(colors._borderColor))
        .dragLineBorderColor(
          this.sassHelper.readProperty(colors._dragLineBorderColor)
        )
        .backgroundHoverColor(
          this.sassHelper.readProperty(colors._backgroundHoverColor)
        )
        .fieldBackgroundHoverColor(
          this.sassHelper.readProperty(colors._fieldBackgroundHoverColor)
        )
        .iconColor(this.sassHelper.readProperty(colors._iconColor))
        .queueIconHoverColor(
          this.sassHelper.readProperty(colors._queueIconHoverColor)
        )
        .moreIconHoverColor(
          this.sassHelper.readProperty(colors._moreIconHoverColor)
        );
    }
    return null;
  };

  onTrackEvent(trackEvent: TrackEvent) {
    // actions that are the same across all track lists are handled here
    if (isSearchAction(trackEvent.action)) {
      this._searchService.handleSearchOnTrack(trackEvent);
    } else if (trackEvent.action == TrackAction.PLAY) {
      this._trackManipulationControllerService.playAudioFile(trackEvent.track);
    } else if (trackEvent.action === TrackAction.ADD_TO_QUEUE) {
      this._musicManipulationService.addAudioFilesToQueue([trackEvent.track]);
    } else {
      // custom actions (remove track, ..) are handled differently for each track list
      this.trackEvent.emit(trackEvent);
    }
  }

  calculateColumnWidths = () => {
    // todo -> check why this is not working. Sizes should be recalculated each time the clientHeight of any of those 2 components changes (do this with a resizeTrigger?)

    /*

    const scrollWrapper = this._renderer2.selectRootElement(
      '.cdk-virtual-scroll-content-wrapper',
      true
    );
    const scrollable =
      this.scrollViewport.elementRef.nativeElement.clientHeight -
        scrollWrapper.clientHeight <
      0;

    console.log("scrollViewport h: " + this.scrollViewport.elementRef.nativeElement.clientHeight + " --- scrollwrapper h: " + scrollWrapper.clientHeight);
    if (scrollable && !this.disableScrollbar) {
      console.log('using smaller sizes');
    }
    */

    if (this.playlist.length > 9) {
      this.firstColWidth = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.firstColPercentage} * calc(100% + 1.5em ))`
      );
      this.secondColWidth = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.secondColPercentage} * calc(100% + 1.5em))`
      );
      this.thirdColWidth = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.thirdColPercentage} * calc(100% - 2em))`
      );
    } else {
      this.firstColWidth = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.firstColPercentage} * 100%)`
      );
      this.secondColWidth = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.secondColPercentage} * 100%)`
      );
      this.thirdColWidth = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.thirdColPercentage} * 100%)`
      );
    }
    this.cdRef.detectChanges();
  };

  onScroll(event: Event) {
    this._scrollPanel$.next(event);
  }
}
