import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnChanges, OnDestroy, NgZone, ChangeDetectorRef, SimpleChanges, ElementRef } from '@angular/core';
import { NavigationItem } from './model/NavigationItem';
import { MainNavigationItem } from './model/MainNavigationItem';
import { PopupService } from '@service/popup.service';
import { TooltipComponent, TooltipAlignment } from '@components/popups/tooltip/tooltip.component';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PopupContent, TooltipPopupContent } from '@components/popups/popup/interfaces';
import { NavigationItemTrackEvent } from './main-navigation-item-view/main-navigation-item-view.component';
import { ResizedEvent } from '@components/resize/resize.directive';

@Component({
  selector: 'tun-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  host: {
    'fxLayout': 'column'
  }
})

/*

A navigation menu is build from mainNavigationItems and their navigationItems:

- MainNavigationItem 1
-- NavigationItem 1A
-- NavigationItem 1B
-- NavigationItem 1C
-- ..
- MainNavigationItem 2
-- NavigationItem 2A
-- NavigationItem 2B
-- NavigationItem 2C
-- ..
- ..

*/

export class NavigationMenuComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public heightPerItem: number = 0;
  @Input() public numberExtraItems: number = 0; //The open mainItem can use extra space equal to this amount of items. Setting a value larger than 0 will result in an extra scrollbar.
  @Input() public mainNavigationItems: MainNavigationItem[] = [];
  @Input() public helpMessage: string = null;
  @Input() public loading: boolean = false;
  @Input() public showLoadError: boolean = false;

  @Output() selectMainNavigationItem = new EventEmitter<MainNavigationItem>();
  @Output() selectNavigationItem = new EventEmitter<NavigationItem>();
  @Output() deleteNavigationItem = new EventEmitter<NavigationItem>();
  @Output() createItemWithTitle = new EventEmitter<string>();
  @Output() changeNavigationItemTitle = new EventEmitter<NavigationItem>();
  @Output() dropTrackOnNavigationItem = new EventEmitter<NavigationItemTrackEvent>();
  @Output() reloadMenuContent = new EventEmitter<void>();

  @ViewChild('menuPanel', {static: true}) menuPanel:any;
  @ViewChild('helpMessageConnector', {static: true}) helpMessageConnector:ElementRef;

  public maxHeightForContent: number = 0;
  public sizeIsOk = false;

  constructor(private ngZone: NgZone,
              private changeDetectorRef: ChangeDetectorRef,
              private _popupService: PopupService) { }

  ngOnInit() {
    this._popupService.currentInstance$
      .pipe(
        filter(
          (instance: PopupContent) =>
            instance && instance.connectedElementRef === this.helpMessageConnector
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((instance: TooltipPopupContent) => {
        instance.text = this.helpMessage;
        instance.alignment = TooltipAlignment.CENTER;
      });
  }

  ngOnChanges(inputChanges: SimpleChanges) {

    // detect
    if (inputChanges.mainNavigationItems || inputChanges.heightPerItem || inputChanges.numberExtraItems) {
      this.calculateSizing();
    }

    if (inputChanges.helpMessage){
      this.adjustHelpPopup();
    }
  }


  public onSelectMainNavigationItem(mainNavigationItemToSelect: MainNavigationItem){
    //we can keep this event out of angular zone -> no data will change in our model, only in this view
    //if we would store the open mainNavigation item in our model, then we would have to run it inside angular zone
    this.selectMainNavigationItem.emit(mainNavigationItemToSelect);
  }

  public onSelectNavigationItem(navigationItem:NavigationItem){
    //we should run this event in the angular zone
    this.ngZone.run(()=>{
      this.selectNavigationItem.emit(navigationItem);
    });
  }

  public onDeleteNavigationItem(navigationItem:NavigationItem){
    this.deleteNavigationItem.emit(navigationItem);
  }

  public onCreateItemWithTitle(title: string){
    this.createItemWithTitle.emit(title);
  }

  public onChangeNavigationItemTitle(navigationItem:NavigationItem){
    this.changeNavigationItemTitle.emit(navigationItem);
  }

  public onResize(event: ResizedEvent){
    this.calculateSizing();
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.showOrHideHelpPopup(false);

    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private calculateSizing(){
    var menuPanelRect = this.menuPanel.nativeElement.getBoundingClientRect();
    let restHeight = menuPanelRect.height;

    if (this.mainNavigationItems){
      restHeight = restHeight - this.mainNavigationItems.length * this.heightPerItem;
      restHeight = restHeight + this.numberExtraItems * this.heightPerItem;
    }


    //console.debug("height for menu content: " + restHeight);

    this.sizeIsOk = restHeight > 0;
    this.adjustHelpPopup();
    this.maxHeightForContent = Math.max(0, restHeight);
  }


  private adjustHelpPopup(){
    if (this.helpMessage && this.sizeIsOk){
      this.showOrHideHelpPopup(true);
    }else{
      this.showOrHideHelpPopup(false);
    }
  }

  private connectedElement: ElementRef = null;
  private showOrHideHelpPopup(show: boolean){
    if (show){
      if (this.connectedElement == null){
        if (this.helpMessageConnector != null){
          this._popupService.showPopup$.next({
            connector: this.helpMessageConnector,
            componentType: TooltipComponent,
            popupDirection: PopupDirection.RIGHT,
            popupPosition: PopupPosition.RIGHT_CENTER,
            showArrow: true
          });
          this.connectedElement = this.helpMessageConnector;
        }
      }else{
        //already showing
      }
    }else{
      if (this.connectedElement != null){
        this._popupService.hidePopup$.next(this.connectedElement);
        this.connectedElement = null;
      }else{
        //already hidden
      }
    }
  }

  public onDropTrack(navigationItemTrackEvent: NavigationItemTrackEvent){
    this.dropTrackOnNavigationItem.emit(navigationItemTrackEvent);
  }

  public onReloadMenuContent(){
    this.reloadMenuContent.emit(null);
  }

}
