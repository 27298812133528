<div [class]="zoneConnection.valid?'container':'error-container'">
  <div class="infoContainer">
    <div class="location-container icon-container">
      <!--
      <tun-icon-button
          [height]="(heightPerItem$ | async) - 1"
        >
        <tun-location-icon></tun-location-icon>
        </tun-icon-button>
      -->
      <tun-location-icon></tun-location-icon>
    </div>
    <div *ngIf="!(loadingZoneInfo$ | async)" class="zone-container">
      <tun-scrollable-text class="name-label" [text]="nameForView"></tun-scrollable-text>
      <tun-scrollable-text *ngIf="zoneConnection.locationName != null" class="location-label" text="{{zoneConnection.locationName}}"></tun-scrollable-text>
    </div>
    <div *ngIf="loadingZoneInfo$ | async" class="status">
        <tun-loader text="general.loading"></tun-loader>
    </div>
  </div>

  <div class="button-container" fxLayout="row">
    <button type="submit" fxFlex *ngIf="(enablePlayer$ | async)"
      (click)="connectAsPlayer()" class="button button-player" translate
    >
      zoneConnection.start
    </button>

    <button fxFlex *ngIf="!(enablePlayer$ | async)" fxFlex fxLayout="row"
    (click)="showNotConnectableAsPlayerInfo()" class="button button-no-player" translate
    >
    <tun-info-icon class="no-player-icon"></tun-info-icon> zoneConnection.start
  </button>
  </div>


  <div class="button-container" fxLayout="row">
    <button type="submit" fxFlex *ngIf="(enableConnect$ | async)" fxLayout="row"
      (click)="connectAsRemote()" class="button button-connect"
    >
        <tun-remote-icon class="remote-icon"></tun-remote-icon>Tunify Connect
    </button>

    <button type="submit" fxFlex *ngIf="!(enableConnect$ | async)" fxLayout="row"
      (click)="showNotConnectableAsRemoteInfo()" class="button button-no-connect"
      [disabled]="!(playerActive$ | async)"
    >
        <tun-remote-icon class="remote-icon-disabled"></tun-remote-icon>Tunify Connect
    </button>
  </div>
  <!-- remote player active: 'Tunfiy Connect' button is clickable, but has enabled / disabled view-->

</div>
