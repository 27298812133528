import { Injectable, OnDestroy } from '@angular/core';
import { faV } from '@fortawesome/free-solid-svg-icons';
import { AudioFile, AudioProperties } from '@model/audioFile';
import { Song } from '@model/song';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicCollectionService } from '@service/music-collection.service';
import { PlaylistService } from '@service/playlist.service';
import { BehaviorSubject, Subject, merge, pipe } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

export class FavoriteBannedTrack{

    /**
    *  TRACK IS FAVORITE
    */

    //public favorite property
    private get _trackIsFavorite(): boolean {
      return this._trackIsFavoriteSubject.value;
    }

    private set _trackIsFavorite(value: boolean) {
      if (this._trackIsFavorite !== value) {
          this._trackIsFavoriteSubject.next(value);
          this._anyValueChangedSubject.next(true);
      }
    }
    get trackIsFavorite(): boolean {
      return this._trackIsFavorite;
    }
    private _trackIsFavoriteSubject = new BehaviorSubject<boolean>(false);
    public trackIsFavorite$ = this._trackIsFavoriteSubject.asObservable();

    //internal favorite value (from model)
    private internalIsFavorite = false
    public syncFavoriteFromModel(isFavorite: boolean){
      this.internalIsFavorite = isFavorite;
      this._trackIsFavoriteSubject.next(this.internalIsFavorite);
    }

    //timeout to save changes to model
    private favoriteSaveIntervalId: NodeJS.Timeout;
    private _isFavoriteShouldBeSaved = false;
    private set isFavoriteShouldBeSaved(value: boolean){
      this._isFavoriteShouldBeSaved = value;
      if (this.favoriteSaveIntervalId != null){
        clearInterval(this.favoriteSaveIntervalId);
        this.favoriteSaveIntervalId = null;
      }
      if (this.isFavoriteShouldBeSaved){
        this.favoriteSaveIntervalId = setInterval(() => {this.syncFavoriteToModel()}, 1500);
      }
    }
    public get isFavoriteShouldBeSaved(): boolean{
      return this._isFavoriteShouldBeSaved;
    }


    //public function to toggle favorite
    public toggleIsFavorite(){
      if (this.favoritesLoaded && this.isAdjustable()){
        this._trackIsFavorite = !this.trackIsFavorite;
        if (this.trackIsFavorite){
          if (this.trackIsBanned){
            this._trackIsBanned = false
            this.isBannedShouldBeSaved = true;
          }
        }
        this.isFavoriteShouldBeSaved = this.trackIsFavorite != this.internalIsFavorite;
      }
    }

    //event emitter to trigger actual save
    public syncFavoriteToModelEvent = new Subject<void>();
    private syncFavoriteToModel(){
      this.isFavoriteShouldBeSaved = false;
      this.syncFavoriteToModelEvent.next();
    }


    /**
    * TRACK IS BANNED
    */
   private get _trackIsBanned(): boolean {
    return this._trackIsBannedSubject.value;
  }

  private set _trackIsBanned(value: boolean) {
    if (this._trackIsBanned !== value) {
        this._trackIsBannedSubject.next(value);
        this._anyValueChangedSubject.next(true);
    }
  }
  get trackIsBanned(): boolean {
    return this._trackIsBanned;
  }
  private _trackIsBannedSubject = new BehaviorSubject<boolean>(false);
  public trackIsBanned$ = this._trackIsBannedSubject.asObservable();

  //internal banned value (from model)
  private internalIsBanned = false
  public syncBannedFromModel(isBanned: boolean){
    this.internalIsBanned = isBanned;
    this._trackIsBannedSubject.next(this.internalIsBanned);
  }

  //timeout to save changes to model
  private bannedSaveIntervalId: NodeJS.Timeout;
  private _isBannedShouldBeSaved = false;
  private set isBannedShouldBeSaved(value: boolean){
    this._isBannedShouldBeSaved = value;
    if (this.bannedSaveIntervalId != null){
      clearInterval(this.bannedSaveIntervalId);
      this.bannedSaveIntervalId = null;
    }
    if (this.isBannedShouldBeSaved){
      this.bannedSaveIntervalId = setInterval(() => {this.syncBannedToModel()}, 1500);
    }
  }

  public get isBannedShouldBeSaved(): boolean{
    return this._isBannedShouldBeSaved;
  }

  //public function to toggle banned
  public toggleIsBanned(){
    if (this.banlistLoaded && this.isAdjustable()){
      this._trackIsBanned = !this.trackIsBanned;
      if (this.trackIsBanned){
        if (this.trackIsFavorite){
          this._trackIsFavorite = false
          this.isFavoriteShouldBeSaved = true;
        }
      }
      this.isBannedShouldBeSaved = true;
    }
  }

  //event emitter to trigger actual save
  public syncBannedToModelEvent = new Subject<void>();
  private syncBannedToModel(){
    this.isBannedShouldBeSaved = false;
    this.syncBannedToModelEvent.next();
  }



   /**
   * an observable that fires whenever one of the values changes - always emits true
   */
   private _anyValueChangedSubject = new Subject<boolean>();
   public anyValueChanged$ = this._anyValueChangedSubject.asObservable();


  private __audio: AudioProperties;
  private set _audio(audio: AudioProperties){
    this.__audio = audio;
  }
  private get _audio(): AudioProperties{
    return this.__audio;
  }
  public get audio(): AudioProperties{
    return this._audio;
  }

  constructor(
    audio: AudioProperties
    ){
    this._audio = audio;
  }

  public favoritesLoaded = false;
  public banlistLoaded = false;

  public isAdjustable():boolean{
    return this.audio instanceof Song;
  }

}

@Injectable({
  providedIn: 'root'
})
export class FavoriteBannedTrackService implements OnDestroy {

  private LOGGER_CLASSNAME = 'FavoriteBannedTrackService';

  private favoriteBannedTracks: FavoriteBannedTrack[] = [];
  public registerFavoriteBannedTrack(favBanTrack: FavoriteBannedTrack){
    this.favoriteBannedTracks.push(favBanTrack);

    this.syncFavoriteFromModelForTrack(favBanTrack);
    this.syncBannedFromModelForTrack(favBanTrack);

    favBanTrack.syncFavoriteToModelEvent
    .pipe(takeUntil(
      merge(
        this.cleanupFavoriteBannedTrackSubject.pipe(filter(favBannedTrack => favBannedTrack == favBanTrack)),
        this.destroyed$
      )
    ))
    .subscribe(()=>{
      this.syncFavoriteToModelForTrack(favBanTrack);
    })

    if (!this.playlistService.favoritesLoading && this.playlistService.favorites == null){
      this.playlistService.loadFavorites();
    }

    favBanTrack.syncBannedToModelEvent
    .pipe(takeUntil(
      merge(
        this.cleanupFavoriteBannedTrackSubject.pipe(filter(favBannedTrack => favBannedTrack == favBanTrack)),
        this.destroyed$
      )
    ))
    .subscribe(()=>{
      this.syncBannedToModelForTrack(favBanTrack);
    })

    if (!this.playlistService.banlistLoading && this.playlistService.banlist == null){
      this.playlistService.loadBanlist();
    }

  }
  private cleanupFavoriteBannedTrackSubject = new Subject<FavoriteBannedTrack>();
  public cleanupFavoriteBannedTrack(favBanTrack: FavoriteBannedTrack){
    this.cleanupFavoriteBannedTrackSubject.next(favBanTrack);
    this.favoriteBannedTracks = this.favoriteBannedTracks.filter($0 => $0 !== favBanTrack)

    if (favBanTrack.isFavoriteShouldBeSaved){
      this.syncFavoriteToModelForTrack(favBanTrack);
    }
    if (favBanTrack.isBannedShouldBeSaved){
      this.syncBannedToModelForTrack(favBanTrack);
    }
  }


  constructor(
    private playlistService: PlaylistService,
    private musicCollectionService: MusicCollectionService,
    private loggerService: LoggerService
  ) {
    this.playlistService.favorites$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.watchAudioFilesOfFavorites();
        this.syncFavoriteFromModel();
      }
    );

    this.playlistService.banlist$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.watchAudioFilesOfBanlist();
        this.syncBannedFromModel();
      }
    );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private stopWatchingFavorites$ = new Subject<void>();
  private watchAudioFilesOfFavorites(){
    this.stopWatchingFavorites$.next();
    if (this.playlistService.favorites != null){
      this.playlistService.favorites.audioFilesHaveChanged
      .pipe(
        takeUntil(
          merge(
            this.stopWatchingFavorites$,
            this.destroyed$
          )
        )
      )
      .subscribe(
        () => {
          this.syncFavoriteFromModel();
        }
      )

      if (!this.playlistService.favorites.detailsLoaded && !this.playlistService.favorites.detailsLoading){
        this.musicCollectionService.loadMusicCollectionDetails(this.playlistService.favorites);
      }

    }
  }


  private stopWatchingBanlist$ = new Subject<void>();
  private watchAudioFilesOfBanlist(){
    this.stopWatchingBanlist$.next();
    if (this.playlistService.banlist != null){
      this.playlistService.banlist.audioFilesHaveChanged
      .pipe(
        takeUntil(
          merge(
            this.stopWatchingBanlist$,
            this.destroyed$
          )
        )
      )
      .subscribe(
        () => {
          this.syncBannedFromModel();
        }
      )
    }
  }

  //Favorite syncing
  private syncFavoriteFromModel(){
    this.favoriteBannedTracks.forEach(favBannedTrack => {
      this.syncFavoriteFromModelForTrack(favBannedTrack);
    });
  }

  private syncFavoriteFromModelForTrack(favBannedTrack: FavoriteBannedTrack){
    if (this.playlistService.favorites && this.playlistService.favorites.audioFiles){
      favBannedTrack.favoritesLoaded = true;
      favBannedTrack.syncFavoriteFromModel(this.playlistService.favorites.audioFiles.filter(audioFile => audioFile instanceof Song && audioFile.id == favBannedTrack.audio.id).length > 0);
    }else{
      favBannedTrack.favoritesLoaded = false;
      favBannedTrack.syncFavoriteFromModel(false);
    }
  }

  private syncFavoriteToModelForTrack(favBannedTrack: FavoriteBannedTrack){
    if (this.playlistService.favorites && this.playlistService.favorites.audioFiles){
      if (favBannedTrack.trackIsFavorite && favBannedTrack.audio instanceof AudioFile){
        if (this.playlistService.favorites.audioFiles.filter(audioFile => audioFile instanceof Song && audioFile.id == favBannedTrack.audio.id).length == 0){
          this.playlistService.addAudioFileToPlaylist(this.playlistService.favorites, [favBannedTrack.audio]);
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, "syncFavoriteToModelForTrack", "Should add track as favorite but already in favorite list");
        }
      }else{
        const tracksToDelete = this.playlistService.favorites.audioFiles.filter(audioFile => audioFile.id == favBannedTrack.audio.id);
        this.playlistService.deleteAudioFileInPlaylist(this.playlistService.favorites, tracksToDelete);
      }
    }
  }

   //Banlist syncing
   private syncBannedFromModel(){
    this.favoriteBannedTracks.forEach(favBannedTrack => {
      this.syncBannedFromModelForTrack(favBannedTrack);
    });
  }

  private syncBannedFromModelForTrack(favBannedTrack: FavoriteBannedTrack){
    if (this.playlistService.banlist && this.playlistService.banlist.audioFiles){
      favBannedTrack.banlistLoaded = true;
      favBannedTrack.syncBannedFromModel(this.playlistService.banlist.audioFiles.filter(audioFile => audioFile instanceof Song && audioFile.id == favBannedTrack.audio.id).length > 0);
    }else{
      favBannedTrack.banlistLoaded = false;
      favBannedTrack.syncBannedFromModel(false);
    }
  }

  private syncBannedToModelForTrack(favBannedTrack: FavoriteBannedTrack){
    if (this.playlistService.banlist && this.playlistService.banlist.audioFiles){
      if (favBannedTrack.trackIsBanned && favBannedTrack.audio instanceof AudioFile){
        if (this.playlistService.banlist.audioFiles.filter(audioFile => audioFile instanceof Song && audioFile.id == favBannedTrack.audio.id).length == 0){
          this.playlistService.addAudioFileToPlaylist(this.playlistService.banlist, [favBannedTrack.audio]);
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, "syncBannedToModelForTrack", "Should add track as banned but already in banlist");
        }
      }else{
        const tracksToDelete = this.playlistService.banlist.audioFiles.filter(audioFile => audioFile.id == favBannedTrack.audio.id);
        this.playlistService.deleteAudioFileInPlaylist(this.playlistService.banlist, tracksToDelete);
      }
    }
  }

}
