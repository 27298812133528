import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppVersionService, MAJOR_VERSION } from '@service/app-version.service';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerV5Guard implements CanActivate, CanDeactivate<unknown> {

  constructor(
    private router: Router,
    private zoneConnectionsService: ZoneConnectionsService,
    private appVersionService: AppVersionService
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.zoneConnectionsService.activeZoneConnection != null, this.appVersionService.isVersionAllowed(MAJOR_VERSION.V5)) {
        return true;
      }
      return false;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

}
