import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tun-thumb-down-icon',
  templateUrl: './thumb-down-icon.component.html',
  styleUrls: ['./thumb-down-icon.component.scss']
})
export class ThumbDownIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
