import { Pipe, PipeTransform } from "@angular/core";
import { MusicCollection } from "@model/musicCollection";

@Pipe({ name: "musicCollectionSorter" })
export class MusicCollectionSorter implements PipeTransform {
  transform(
    musicCollections: Array<MusicCollection>,
    i: number,
    j: number
  ): MusicCollection {
    var initialIndex = i * 4 + j;
    var actualIndex = 0;

    switch (initialIndex) {
      case 1:
      case 2:
      case 5:
      case 6:
      case 9:
      case 10:
      case 13:
      case 14:
        if (initialIndex % 2 === 0) {
          actualIndex = --initialIndex;
        } else {
          actualIndex = ++initialIndex;
        }
        break;
      default:
        actualIndex = initialIndex;
        break;
    }

    return musicCollections[actualIndex];
  }
}
