import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { StreamMode } from '@model/enums/streamMode';
import { AudioFile } from '@model/audioFile';
import { PlayStateService } from '@service/play-state.service';
import { QueueService } from '@service/queue.service';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Renderer2,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { SongGridColors } from '@model/fieldModels/songGridColors';
import { AudioFileProperty } from '@model/enums/audioFileProperty';
import { TrackEvent, TrackAction, isSearchAction } from '@model/events/trackEvent';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { SearchService } from '@service/search.service';
import { SearchType } from '@service/api/search-api.service';
import { LoggerService } from '@service/loggers/logger.service';
import { ObservableComponent } from '@components/app-components/observable-component/observable-component';
import { takeUntil } from 'rxjs/operators';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { trigger } from '@angular/animations';
import { TrackManipulationControllerService } from '../../../../../services/audio/track-manipulation-controller.service';

@Component({
  selector: 'tun-radio-queue-panel',
  templateUrl: './radio-queue-panel.component.html',
  styleUrls: ['./radio-queue-panel.component.scss'],
  // memory leak work-around: add no-op animation in parent component of each songGrid. Otherwise, the @HostBinding('@fadeIn') animation causes the component to stay in memory.
  animations: [trigger("noop", [])]
})
export class RadioQueuePanelComponent extends ObservableComponent
  implements OnInit, OnChanges {


  private LOGGER_CLASSNAME = 'RadioQueuePanelComponent';

  public streamMode: StreamMode;
  public radioQueuedAudioFiles: AudioFile[];
  public audioFileProperty: AudioFileProperty;

  //make the enum available in the html file
  public StreamMode = StreamMode;

  get radioQueueLoading$(){
    return this.queueService.loadingRadioQueue$;
  }

  iconHeight: number = 0;
  iconPadding: SafeStyle = '';

  private _heightPerItem: number = 40;
  @Input() set heightPerItem(value: number) {
    this._heightPerItem = value;
    this.iconHeight = value / 2 - 1;
    this.iconPadding = this.sanitizer.bypassSecurityTrustStyle(
      `calc(${value}px / 4) 0.75rem`
    );
  }
  get heightPerItem(): number {
    return this._heightPerItem;
  }

  @Input() public tunifyColor: TunifyColor;

  disableScrollbar: boolean = true;
  refreshIconColor: string = '#C8C8C8';

  songGridColors: SongGridColors = new SongGridColors();

  constructor(
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private zoneConfigurationService: ZoneConfigurationService,
    private playStateService: PlayStateService,
    private queueService: QueueService,
    private musicManipulationService: MusicManipulationService,
    private trackManipulationControllerService: TrackManipulationControllerService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private searchService: SearchService,
    private loggerService: LoggerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(simpleChanges:SimpleChanges){
    if (simpleChanges.tunifyColor){
      this.fetchColors();
    }
  }

  ngOnInit() {
    this.activeMusicSelectionService.selectedStreamMode$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      streamMode => {
        this.streamMode = streamMode;
      }
    );

    this.queueService.radioQueue$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      audioFiles => {
        if (audioFiles) {
          this.radioQueuedAudioFiles = audioFiles.slice(0, 5);
        } else {
          this.radioQueuedAudioFiles = audioFiles;
        }
        this.changeDetectorRef.detectChanges();
      }
    );


    this.queueService.loadingRadioQueue$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      value => {
        //when loading state is not shown -> add a manual trigger when subscription fires
        this.changeDetectorRef.detectChanges();
      }
    );


    this.zoneConfigurationService.audioFileProperty$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      audioFileProperty => {
        this.audioFileProperty = audioFileProperty;
      }
    );


  }


  public play(audioFile: AudioFile) {
    this.trackManipulationControllerService.playAudioFile(audioFile);
  }

  public removeAudioFileFromRadioQueue(audioFile: AudioFile) {
    this.musicManipulationService.removeAudioFileFromRadioQueue(audioFile);
  }

  onRefreshIconHover = (event: MouseEvent, isHovering: boolean) => {
    const element = event.target as HTMLElement;
    this.renderer.setStyle(
      element,
      'background-color',
      isHovering ? this.tunifyColor === TunifyColor.GREEN?'#4D674D':'#40575F' : 'transparent'
    );
    this.refreshIconColor = isHovering ? '#FFFFFF' : '#C8C8C8';
  };

  // Called when the Refresh Icon is clicked.
  refreshQueue = () => {
    this.musicManipulationService.refreshRadioQueue();
  };

  fetchColors = () => {
    switch (this.tunifyColor) {
      case TunifyColor.BLUE:
        let songGridColorsBlue = new SongGridColors()
          .borderColor('blue-17')
          .dragLineBorderColor('grey-song-grid-drag-border')
          .backgroundHoverColor('blue-15')
          .fieldBackgroundHoverColor('blue-18')
          .iconColor('grey-16')
          .queueIconHoverColor('blue-queue-icon-hover')
          .moreIconHoverColor('white-1');
        this.songGridColors = songGridColorsBlue;
        break;
      case TunifyColor.GREEN:
        let songGridColorsGreen = new SongGridColors()
          .borderColor('green-4')
          .dragLineBorderColor('grey-song-grid-drag-border')
          .backgroundHoverColor('blue-15')
          .fieldBackgroundHoverColor('blue-18')
          .iconColor('grey-16')
          .queueIconHoverColor('blue-queue-icon-hover')
          .moreIconHoverColor('white-1');
        this.songGridColors = songGridColorsGreen;
        break;
      default:
        let songGridColors = new SongGridColors()
          .borderColor('blue-17')
          .dragLineBorderColor('grey-song-grid-drag-border')
          .backgroundHoverColor('blue-15')
          .fieldBackgroundHoverColor('blue-18')
          .iconColor('grey-16')
          .queueIconHoverColor('blue-queue-icon-hover')
          .moreIconHoverColor('white-1');
        this.songGridColors = songGridColors;
        break;
    }
  };

  public onTrackEvent(trackEvent: TrackEvent) {

    if (trackEvent.action === TrackAction.REMOVE) {
      this.musicManipulationService.removeAudioFileFromRadioQueue(
        trackEvent.track
      );
    }else {
      this.loggerService.error(this.LOGGER_CLASSNAME, 'onTrackEvent', 'Custom track action ' + trackEvent.action + ' not implemented');
    }
  }
}
