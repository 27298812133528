export class SongGridColors {
  _borderColor: string;
  _dragLineBorderColor: string;
  _backgroundHoverColor: string;
  _fieldBackgroundHoverColor: string;
  _iconColor: string;
  _queueIconHoverColor: string;
  _moreIconHoverColor: string;

  borderColor = (color: string): SongGridColors => {
    this._borderColor = color;
    return this;
  };

  dragLineBorderColor = (color: string): SongGridColors => {
    this._dragLineBorderColor = color;
    return this;
  };

  backgroundHoverColor = (color: string): SongGridColors => {
    this._backgroundHoverColor = color;
    return this;
  };

  fieldBackgroundHoverColor = (color: string): SongGridColors => {
    this._fieldBackgroundHoverColor = color;
    return this;
  };

  iconColor = (color: string): SongGridColors => {
    this._iconColor = color;
    return this;
  };

  queueIconHoverColor = (color: string): SongGridColors => {
    this._queueIconHoverColor = color;
    return this;
  };

  moreIconHoverColor = (color: string): SongGridColors => {
    this._moreIconHoverColor = color;
    return this;
  };
}
