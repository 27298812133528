import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  AfterViewInit,
  NgZone
} from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { CalendarItem } from '@model/calendarItem';
import { ChangeableParameter } from '@model/changeableParameter';
import { Context } from '@model/context';
import { AudioFileProperty } from '@model/enums/audioFileProperty';
import { SongSortMode } from '@model/enums/songSortMode';
import {
  MusicCollectionAction,
  MusicCollectionEvent
} from '@model/events/musicCollectionEvent';
import { TracksAction, TracksEvent } from '@model/events/tracksEvent';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { SongGridColors } from '@model/fieldModels/songGridColors';
import { MusicCollection } from '@model/musicCollection';
import { Playlist } from '@model/playlist';
import { MusicPlayerService } from '@service/music-player.service';
import { timer, Subject, merge } from 'rxjs';
import { SongSorter } from '../song-grid/song-sorter.pipe';
import { takeUntil } from 'rxjs/operators';
import { AppService } from '@service/app.service';

@Component({
  selector: 'tun-music-collection-configuration-panel',
  templateUrl: './music-collection-configuration-panel.component.html',
  styleUrls: ['./music-collection-configuration-panel.component.scss']
})
export class MusicCollectionConfigurationPanelComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  // === Getters === //
  get audioFiles(): AudioFile[] {
    return this.musicCollection instanceof Playlist
      ? this.musicCollection.audioFiles
      : null;
  }

  get tracksLoading(): boolean {
    return this.musicCollection instanceof Playlist
      ? this.musicCollection.detailsLoading
      : false;
  }

  get isConfigurationForChangeableParameters(): boolean {
    return (
      !!this.calendarItem ||
      (this.musicCollection && this.musicCollection instanceof Context)
    );
  }

  get changeableParameters(): ChangeableParameter[] {
    if (this.calendarItem) {
      return this.calendarItem.changeableParameter;
    } else if (
      this.musicCollection &&
      this.musicCollection instanceof Context
    ) {
      return (<Context>this.musicCollection).changeableParameter;
    }
    return null;
  }

  get tweakInfo() {
    if (this.calendarItem) {
      return this.calendarItem.tweakInfo;
    } else if (
      this.musicCollection &&
      this.musicCollection instanceof Context
    ) {
      return (<Context>this.musicCollection).tweakInfo;
    }
    return null;
  }

  get isContext(): boolean {
    return this.musicCollection && this.musicCollection instanceof Context;
  }

  get title() {
    if (this.calendarItem) {
      return this.calendarItem.title;
    }
    return this.musicCollection.title;
  }

  get tweakInfoLoaded(): boolean {
    if (this.calendarItem) {
      return this.calendarItem.tweakInfoLoaded;
    }
    return (<Context>this.musicCollection).tweakInfoLoaded;
  }

  get parametersLoading(): boolean {
    if (this.calendarItem) {
      return this.calendarItem.changeableParametersLoading;
    }
    return (<Context>this.musicCollection).detailsLoading;
  }

  get isCurrentMusicCollection(): boolean {

    let idValue = NaN;
    if (
      this.musicPlayerService.currentActiveAudioFileWithPlayInfo != null &&
      this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile != null &&
      this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile instanceof AudioFile &&
      this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile.origin != null &&
      this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile.origin.data != null
    ) {
      idValue = parseInt(this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile.origin.data, 10);
    }

    if (this.calendarItem) {
      return this.calendarItem.id === idValue;
    }

    return this.musicCollection.id === idValue;
  }

  get heightPerItem():number{
    return this.appService.heightPerItem;
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private _ngZone: NgZone,
    private songSorter: SongSorter,
    private musicPlayerService: MusicPlayerService,
    private appService: AppService
  ) {}

  // Helper functions to get the selected music
  // collection as one of its subtypes.
  get musicCollectionAsContext(): Context {
    return this.musicCollection as Context;
  }
  get musicCollectionAsPlaylist(): Playlist {
    return this.musicCollection as Playlist;
  }
  // === Props === //
  @Input() calendarItem: CalendarItem;
  @Input() musicCollection: MusicCollection;
  @Input() maxScrollbarHeight: number;

  @Input() animatePlayAnimation = false;
  @Input() showPlayAnimation = false;
  @Input() musicPlayAnimationColors: MusicPlayAnimationColors;
  @Input() audioFileProperty: AudioFileProperty;

  // Emitter that gives an output when the user wants to close the configuration panel.
  @Output() closeConfiguration: EventEmitter<null> = new EventEmitter<null>();
  @Output() trackEvent = new EventEmitter<TrackEvent>();
  @Output() tracksEvent = new EventEmitter<TracksEvent>();

  @Output() musicCollectionEvent = new EventEmitter<MusicCollectionEvent>();
  @Output() shuffleChanged = new EventEmitter<CalendarItem>();

  animating = true;

  songGridColors: SongGridColors = new SongGridColors();

  ngAfterViewInit() {
    timer(400)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this.animating = false;
      });
  }

  private _destroyed$ = new Subject();
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$ = null;
  }

  private musicCollectionChanged$ = new Subject();
  private calendarItemChanged$ = new Subject();
  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['musicCollection']) {
      this.musicCollectionChanged$.next(null);
      if (this.musicCollection) {

        this.musicCollection.detailsLoading$
          .pipe(
            takeUntil(merge(this.musicCollectionChanged$, this._destroyed$))
          )
          .subscribe(() => {
            //when the loading state changes -> we need to update the bindings IN THE ANGULAR ZONE or checkboxes will not work
            this._ngZone.run(() => {
              this.cdRef.detectChanges();
            });
          });

        if (this.musicCollection instanceof Context) {
          this.musicCollection.tweakInfoLoading$
            .pipe(
              takeUntil(merge(this.musicCollectionChanged$, this._destroyed$))
            )
            .subscribe(() => {
              //when the loading state changes -> we need to update the bindings IN THE ANGULAR ZONE or checkboxes will not work
              this._ngZone.run(() => {
                this.cdRef.detectChanges();
              });
            });
        }
      }
    }

    if (simpleChanges['calendarItem']) {
      this.calendarItemChanged$.next(null);

      this.calendarItem.changeableParametersLoading$
          .pipe(
            takeUntil(merge(this.calendarItemChanged$, this._destroyed$))
          )
          .subscribe(() => {
            //when the loading state changes -> we need to update the bindings IN THE ANGULAR ZONE or checkboxes will not work
            this._ngZone.run(() => {
              this.cdRef.detectChanges();
            });
          });


      if (this.calendarItem){
        this.calendarItem.tweakInfoLoading$
        .pipe(
          takeUntil(merge(this.calendarItemChanged$, this._destroyed$))
        )
        .subscribe(() => {
          //when the loading state changes -> we need to update the bindings IN THE ANGULAR ZONE or checkboxes will not work
          this._ngZone.run(() => {
            this.cdRef.detectChanges();
          });
        });
      }

    }
  }

  doFactoryReset = () => {
    this.musicCollectionEvent.emit(
      new MusicCollectionEvent(
        MusicCollectionAction.RESET_PARAMETERS,
        this.musicCollection
      )
    );
  };

  closeConfigurationPanel() {
    this.animating = true;
    this.cdRef.detectChanges();
    this.closeConfiguration.emit();
    timer(400).subscribe(() => {
      this.animating = false;
    });
  }

  isTablet() {
    return window.innerWidth < 768;
  }

  fetchColors = () => {
    const songGridColors = new SongGridColors()
      .borderColor('grey-song-grid-border')
      .dragLineBorderColor('grey-song-grid-drag-border')
      .backgroundHoverColor('blue-15')
      .fieldBackgroundHoverColor('grey-song-field-hover')
      .iconColor('grey-13')
      .queueIconHoverColor('blue-queue-icon-hover')
      .moreIconHoverColor('white-1');
    this.songGridColors = songGridColors;
  };

  onTrackEvent(trackEvent: TrackEvent) {
    this.trackEvent.emit(trackEvent);
  }

  onTracksEvent(tracksEvent: TracksEvent) {
    this.tracksEvent.emit(tracksEvent);
  }

  onParameterChange(changeableParameters: ChangeableParameter[]) {
    if (this.musicCollection) {
      // 'Clone' the object so ChangeableParametersComponent does not get rerendered
      (this.musicCollection as Context).changeableParameter = changeableParameters;
      this.musicCollectionEvent.emit(
        new MusicCollectionEvent(
          MusicCollectionAction.PARAMETERS_CHANGED,
					this.musicCollection
        )
      );
    }else if (this.calendarItem){
      this.calendarItem.changeableParameter = changeableParameters;
      this.musicCollectionEvent.emit(
        new MusicCollectionEvent(
          MusicCollectionAction.PARAMETERS_CHANGED,
					null
        )
      );
    }
  }

  onShufflePlaylistChange(newValue: boolean){
    if (this.calendarItem){
      this.calendarItem.shufflePlaylist = newValue;
      this.shuffleChanged.emit(this.calendarItem);

    }
  }
}
