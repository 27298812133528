<form class="search-field-container" 
  [formGroup]="searchForm" 
  (ngSubmit)="onSearch(searchInput.value)">

  <div 
    #searchButton
    tunCheckAccess
    [checkAccessToSearch]="true"
    (clickWithAccess)="onSearchIconClicked()"
    class="icon-container" 
  >
    <tun-search-icon></tun-search-icon>
  </div>
  
  <div 
    class="search-input-container"
    tunCheckAccess
    [checkAccessToSearch]="true"
  >
    <input #searchInput type="text" placeholder="{{'search.input.placeholder' | translate}}"
		formControlName="searchTerm" (focus)="searchfocus.emit()"
		(keydown.escape)="onEscapeSearch()">
  </div>
</form>
