import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { AudioFile } from '@model/audioFile';
import { Calendar } from '@model/calendar';
import { Context } from '@model/context';
import { MusicChannel } from '@model/musicChannel';
import { MusicCollection } from '@model/musicCollection';
import { Playlist } from '@model/playlist';
import { MusicCollectionApiService, MusicCollectionPackage } from '@service/api/music-collection-api.service';
import { CalendarService } from '@service/calendar.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicCollectionService } from '@service/music-collection.service';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { BehaviorSubject, Subject, Observable, merge } from 'rxjs';
import { finalize, take, takeUntil, filter } from 'rxjs/operators';
import { SearchTextV5Service } from './search-text-v5.service';
import { ZoneConnectionService } from '../authentication/zone-connection.service';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { MusicSelectionService } from '@service/music-selection.service';
import { DTO_ActiveMode } from '@service/api/zone-configuration-api.service';
import { AsyncStatus, AsyncStatusWithData } from '@service/vo/asyncStatus';
import { environment } from 'src/environments/environment';
import { LastPlayedItemsService } from '@service/last-played-items.service';

export enum TunifyV5Tab {
  HOME = "home",
  CALENDAR = "calendar",
  MUSIC_CHANNEL = "musicChannel",
  PLAYLIST = "playlist",
  SEARCH = "search",
  SETTINGS = "settings",
}

@Injectable({
  providedIn: 'root'
})
export class AppV5StateService implements OnDestroy{

  private LOGGER_CLASSNAME = AppV5StateService.name;

  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private musicSelectionService: MusicSelectionService,
    private calendarService: CalendarService,
    private musicCollectionService: MusicCollectionService,
    private musicCollectionApiService: MusicCollectionApiService,
    private loggerService: LoggerService,
    private searchTextV5Service: SearchTextV5Service,
    private lastPlayedItemsService: LastPlayedItemsService
  ) {
    this.zoneConnectionsService.activeZoneConnection$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.clearData();
      }
    )

    this.musicSelectionService.musicSelection$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (musicSelection) => {
        if (!environment.enableHome && this.selectedTab == TunifyV5Tab.HOME && musicSelection != null){
          if (musicSelection.active == DTO_ActiveMode.Calendar){
            this.selectTunifyTab(TunifyV5Tab.CALENDAR);
          }else if (musicSelection.active == DTO_ActiveMode.MusicChannel){
            this.selectTunifyTab(TunifyV5Tab.MUSIC_CHANNEL);
          }
        }
      }
    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
  }

  private clearData(){
    this.selectTunifyTab(TunifyV5Tab.HOME);
  }

  //Base rem to pixels converter
  private get _pixelsFor1Rem(): number {
    return this._pixelsFor1RemSubject.value;
  }
  private set _pixelsFor1Rem(value: number) {
    if (this._pixelsFor1Rem !== value) {
      this._pixelsFor1RemSubject.next(value);
    }
  }
  public get pixelsFor1Rem(): number {
    return this._pixelsFor1Rem;
  }
  private _pixelsFor1RemSubject = new BehaviorSubject<number>(16);
  public pixelsFor1Rem$ = this._pixelsFor1RemSubject.asObservable();

  public adjustPixelsFor1Rem(value: number){
    this._pixelsFor1Rem = value;
  }

  //selected Tunify tab
  private get _selectedTab(): TunifyV5Tab {
    return this._selectedTabSubject.value;
  }
  private set _selectedTab(value: TunifyV5Tab) {
    this._selectedTabSubject.next(value);

    //Keep the last played items up to date when the home tab is selected
    this.lastPlayedItemsService.keepLastUsedItemsUpToDate = value == TunifyV5Tab.HOME;
  }
  public get selectedTab(): TunifyV5Tab {
    return this._selectedTab;
  }
  private _selectedTabSubject = new BehaviorSubject<TunifyV5Tab>(TunifyV5Tab.HOME);
  public selectedTab$ = this._selectedTabSubject.asObservable();

  public selectTunifyTab(tab: TunifyV5Tab){
    if (this._selectedTab == tab){
      //when the tab is already selected -> go to main
      if (tab == TunifyV5Tab.CALENDAR){
        this._showDetailsForCalendar = null;
        this.editingCurrentCalendar = false;
        this.showCreateCalendar = false;
      }else if (tab == TunifyV5Tab.MUSIC_CHANNEL){
        this._showDetailsForMusicChannel = null;
      }else if (tab == TunifyV5Tab.PLAYLIST){
        this._showDetailsForPlaylist = null;
        this.showCreatePlaylist = false;
        this.showChangeNameForPlaylist = null;
      }else if (tab == TunifyV5Tab.SEARCH){
        this._showSearchResults = false;
        this._showTextSearch = false;
        this._showDetailsForSearchMusicChannel = null;
        this._showDetailsForSearchMusicCollection = null;

      }
    }
    this._selectedTab = tab;
  }


   //showing details of musicChannel (in blue)
   private get _showDetailsForMusicChannel(): MusicChannel {
    return this._showDetailsForMusicChannelSubject.value;
  }
  private set _showDetailsForMusicChannel(value: MusicChannel) {
    if (this._showDetailsForMusicChannel !== value) {
      this._showDetailsForMusicChannelSubject.next(value);
    }
  }
  public get showDetailsForMusicChannel(): MusicChannel {
    return this._showDetailsForMusicChannel;
  }
  private _showDetailsForMusicChannelSubject = new BehaviorSubject<MusicChannel>(null);
  public showDetailsForMusicChannel$ = this._showDetailsForMusicChannelSubject.asObservable();

  public showMusicChannelInTunifyBlue(musicChannel: MusicChannel){
    this._showDetailsForMusicChannel = musicChannel;
    this._selectedTab = TunifyV5Tab.MUSIC_CHANNEL;
  }


  //creating calendar
  public set showCreateCalendar(value: boolean){
    this._showCreateCalendarSubject.next(value);
  }
  public get showCreateCalendar(){
    return this._showCreateCalendarSubject.value;
  }

  private _showCreateCalendarSubject = new BehaviorSubject<boolean>(false);
  public showCreateCalendar$ = this._showCreateCalendarSubject.asObservable();

  //change name for calendar
  public set showChangeNameForCalendar(calendar: Calendar){
    this._showChangeNameForCalendarSubject.next(calendar);
  }
  public get showChangeNameForCalendar(){
    return this._showChangeNameForCalendarSubject.value;
  }

  private _showChangeNameForCalendarSubject = new BehaviorSubject<Calendar>(null);
  public showChangeNameForCalendar$ = this._showChangeNameForCalendarSubject.asObservable();

  //showing details of calendar (in green)
  private get _showDetailsForCalendar(): Calendar {
    return this._showDetailsForCalendarSubject.value;
  }
  private set _showDetailsForCalendar(value: Calendar) {
    if (this._showDetailsForCalendar !== value) {
      this._showDetailsForCalendarSubject.next(value);

      if (this._showDetailsForCalendar && !this._showDetailsForCalendar.calendarItemsLoaded && !this._showDetailsForCalendar.calendarItemsLoading){
        this.calendarService.loadCalendarItems(this._showDetailsForCalendar);
      }
    }
  }
  public get showDetailsForCalendar(): Calendar {
    return this._showDetailsForCalendar;
  }
  private _showDetailsForCalendarSubject = new BehaviorSubject<Calendar>(null);
  public showDetailsForCalendar$ = this._showDetailsForCalendarSubject.asObservable();

  public showCalendarInTunifyGreen(calendar: Calendar){
    this._showDetailsForCalendar = calendar;
    this.editingCurrentCalendar = false;
    this.showCreateCalendar = false;
    this.showChangeNameForCalendar = null;
    this._selectedTab = TunifyV5Tab.CALENDAR;
  }

  public selectCreatedCalendarAfterCreation(creatingCalendar: Calendar, creatingCalendarObservable: Observable<AsyncStatusWithData>){
    creatingCalendarObservable
    .pipe(
      takeUntil(
        merge(
          this.showDetailsForCalendar$.pipe(filter(c => c != creatingCalendar)),
          this.destroyed$
        )
      )
    )
    .subscribe(
      (asyncStatusWithData) => {
        if (asyncStatusWithData && asyncStatusWithData.asyncStatus == AsyncStatus.COMPLETED){
          if (asyncStatusWithData.data && asyncStatusWithData.data instanceof Calendar){
            //when a calendar is created and the 'creating' calendar is still selected, select the actual calendar
            this._showDetailsForCalendar = asyncStatusWithData.data;
            this.editingCurrentCalendar = true;
          }else{
            this.loggerService.error(this.LOGGER_CLASSNAME, "showCreatingCalendar", "Successfully created a calendar, but the created object could not be found -> not going to select");
          }
        }else if (asyncStatusWithData && asyncStatusWithData.asyncStatus == AsyncStatus.ERROR){
          //unselect the 'creating' calendar
          this._showDetailsForCalendar = null;
        }
      }
    );
  }

  //edit mode (current Calendar)
  public set editingCurrentCalendar(value: boolean){
    this._editingCurrentCalendarSubject.next(value);
  }
  public get editingCurrentCalendar(){
    return this._editingCurrentCalendarSubject.value;
  }

  private _editingCurrentCalendarSubject = new BehaviorSubject<boolean>(false);
  public editingCurrentCalendar$ = this._editingCurrentCalendarSubject.asObservable();


  //an event emitter to scroll to the current time
  public scrollToCurrentTime$ = new Subject<void>();

  //showing details of playlist (in orange - playlists)
  private get _showDetailsForPlaylist(): Playlist {
    return this._showDetailsForPlaylistSubject.value;
  }
  private set _showDetailsForPlaylist(value: Playlist) {
    if (this._showDetailsForPlaylist !== value) {
      this._showDetailsForPlaylistSubject.next(value);

      if (this._showDetailsForPlaylist && !this._showDetailsForPlaylist.detailsLoaded && !this._showDetailsForPlaylist.detailsLoading){
        this.musicCollectionService.loadMusicCollectionDetails(this._showDetailsForPlaylist);
      }
    }
  }
  public get showDetailsForPlaylist(): Playlist {
    return this._showDetailsForPlaylist;
  }
  private _showDetailsForPlaylistSubject = new BehaviorSubject<Playlist>(null);
  public showDetailsForPlaylist$ = this._showDetailsForPlaylistSubject.asObservable();

  public showPlaylistInTunifyOrange(playlist: Playlist){
    this._showDetailsForPlaylist = playlist;
    this.showCreatePlaylist = false;
    this.showChangeNameForPlaylist = null;
    this._selectedTab = TunifyV5Tab.PLAYLIST;
  }

  //creating playlist
  public set showCreatePlaylist(value: boolean){
    this._showCreatePlaylistSubject.next(value);
  }
  public get showCreatePlaylist(){
    return this._showCreatePlaylistSubject.value;
  }

  private _showCreatePlaylistSubject = new BehaviorSubject<boolean>(false);
  public showCreatePlaylist$ = this._showCreatePlaylistSubject.asObservable();


  //change name for playlist
  public set showChangeNameForPlaylist(playlist: Playlist){
    this._showChangeNameForPlaylistSubject.next(playlist);
  }
  public get showChangeNameForPlaylist(){
    return this._showChangeNameForPlaylistSubject.value;
  }

  private _showChangeNameForPlaylistSubject = new BehaviorSubject<Playlist>(null);
  public showChangeNameForPlaylist$ = this._showChangeNameForPlaylistSubject.asObservable();

    //search for playlist
    public set showSearchForPlaylist(playlist: Playlist){
      this._showSearchForPlaylistSubject.next(playlist);
    }
    public get showSearchForPlaylist(){
      return this._showSearchForPlaylistSubject.value;
    }

    private _showSearchForPlaylistSubject = new BehaviorSubject<Playlist>(null);
    public showSearchForPlaylist$ = this._showSearchForPlaylistSubject.asObservable();



  //showing details of searchMusicChannel (in orange - search)
  private get _showDetailsForSearchMusicChannel(): MusicChannel {
    return this._showDetailsForSearchMusicChannelSubject.value;
  }
  private set _showDetailsForSearchMusicChannel(value: MusicChannel) {
    if (this._showDetailsForSearchMusicChannel !== value) {
      this._showDetailsForSearchMusicChannelSubject.next(value);


    }
  }
  public get showDetailsForSearchMusicChannel(): MusicChannel {
    return this._showDetailsForSearchMusicChannel;
  }
  private _showDetailsForSearchMusicChannelSubject = new BehaviorSubject<MusicChannel>(null);
  public showDetailsForSearchMusicChannel$ = this._showDetailsForSearchMusicChannelSubject.asObservable();

  public showSearchMusicChannelInTunifyOrange(searchMusicChannel: MusicChannel){
    this._showDetailsForSearchMusicChannel = searchMusicChannel;
    this._selectedTab = TunifyV5Tab.SEARCH;
  }


  //showing details of searchMusicCollection (in orange - search)
  private get _showDetailsForSearchMusicCollection(): MusicCollection {
    return this._showDetailsForSearchMusicCollectionSubject.value;
  }
  private set _showDetailsForSearchMusicCollection(value: MusicCollection) {
    if (this._showDetailsForSearchMusicCollection !== value) {
      this._showDetailsForSearchMusicCollectionSubject.next(value);


    }
  }
  public get showDetailsForSearchMusicCollection(): MusicCollection {
    return this._showDetailsForSearchMusicCollection;
  }
  private _showDetailsForSearchMusicCollectionSubject = new BehaviorSubject<MusicCollection>(null);
  public showDetailsForSearchMusicCollection$ = this._showDetailsForSearchMusicCollectionSubject.asObservable();

  public showSearchMusicCollectionInTunifyOrange(searchMusicCollection: MusicCollection){
    this._showDetailsForSearchMusicCollection = searchMusicCollection;
    this._selectedTab = TunifyV5Tab.SEARCH;
    this.loadTracksForCurrentMusicCollection();
  }

  /* SEARCH: Tracks for musicCollection - musicChannel */

   /**
   * loading tracks for the selected musicCollection / MusicChannel
   */
   private get _loadingTracksForMusicCollection(): boolean {
    return this._loadingTracksForMusicCollectionSubject.value;
  }
  private set _loadingTracksForMusicCollection(value: boolean) {
    if (this._loadingTracksForMusicCollection !== value) {
      this._loadingTracksForMusicCollectionSubject.next(value);
    }
  }
  get loadingTracksForMusicCollection(): boolean {
    return this._loadingTracksForMusicCollection;
  }
  private _loadingTracksForMusicCollectionSubject = new BehaviorSubject<boolean>(false);
  public loadingTracksForMusicCollection$ = this._loadingTracksForMusicCollectionSubject.asObservable();



  /**
   * Error emitter for retrieving the tracks for the selected musicCollection / MusicChannel
   */
  private get _loadingTracksForMusicCollectionError(): boolean {
    return this._loadingTracksForMusicCollectionErrorSubject.value;
  }
  private set _loadingTracksForMusicCollectionError(value: boolean) {
    if (this._loadingTracksForMusicCollectionError !== value) {
      this._loadingTracksForMusicCollectionErrorSubject.next(value);
    }
  }
  get loadingTracksForMusicCollectionError(): boolean {
    return this._loadingTracksForMusicCollectionError;
  }

  private _loadingTracksForMusicCollectionErrorSubject = new BehaviorSubject<boolean>(false);
  public loadingTracksForMusicCollectionError$ = this._loadingTracksForMusicCollectionErrorSubject.asObservable();



  /**
   * tracks for the selected musicCollection / MusicChannel
   */
  private get _tracksForMusicCollection(): AudioFile[] {
    return this._tracksForMusicCollectionSubject.value;
  }
  private set _tracksForMusicCollection(value: AudioFile[]) {
    if (this._tracksForMusicCollection !== value) {
      this._tracksForMusicCollectionSubject.next(value);
    }
  }
  get tracksForMusicCollection(): AudioFile[] {
    return this._tracksForMusicCollection;
  }

  private _tracksForMusicCollectionSubject = new BehaviorSubject<AudioFile[]>(null);
  public tracksForMusicCollection$ = this._tracksForMusicCollectionSubject.asObservable();


  private cancelPendingSearch = new Subject<void>();
  public loadTracksForCurrentMusicCollection() {
    if (this.showDetailsForSearchMusicCollection != null && this.showDetailsForSearchMusicChannel != null){
      const musicCollectionPackages: MusicCollectionPackage[] = [];
      const musicCollectionPackage = new MusicCollectionPackage();
      if (this.showDetailsForSearchMusicCollection instanceof Context) {
        musicCollectionPackage.changeableParameters = this.showDetailsForSearchMusicCollection.changeableParameters;
      }
      musicCollectionPackage.mcId = this.showDetailsForSearchMusicCollection.id;
      musicCollectionPackage.musicChannelId = this.showDetailsForSearchMusicChannel.id;
      musicCollectionPackage.weight = 1;
      musicCollectionPackages.push(musicCollectionPackage);

      const searchObservable = this.musicCollectionApiService.loadTracksForMusicCollectionPackages(musicCollectionPackages);
      if (searchObservable) {
        this.cancelPendingSearch.next(null);
        this._loadingTracksForMusicCollectionError = false;
        this._loadingTracksForMusicCollection = true;
        this._tracksForMusicCollection = null;

        searchObservable
          .pipe(
            finalize(() => {
              this.loggerService.debug(
                this.LOGGER_CLASSNAME,
                'loadTracksForCurrentMusicCollection finalize',
                'finalize loading: ' + this._loadingTracksForMusicCollection
              );
              if (!this._loadingTracksForMusicCollection) {
                this.loggerService.error(
                  this.LOGGER_CLASSNAME,
                  'loadTracksForCurrentMusicCollection finalize',
                  'finalize but we are not loading!'
                );
              }
              this._loadingTracksForMusicCollection = false;
            }),
            takeUntil(this.cancelPendingSearch)
          )
          .subscribe(
            data => {
              const realObjects = data.map(res => createClassObjectForAudioFile(res));
              this._tracksForMusicCollection = realObjects;
            },
            (error : unknown) => {
              let errMsg = 'Error';
              if (error instanceof HttpErrorResponse){
                errMsg = (error.message) ? error.message :
                error.status ? `${error.status} - ${error.statusText}` : 'Server error';
              }
              this.loggerService.error(this.LOGGER_CLASSNAME, 'handleSearchObservable error', errMsg);
              this._loadingTracksForMusicCollectionError = true;
            });
      } else {
        // nothing to load
      }
    }



  }

  /**
   * show test search
   */
  private get _showTextSearch(): Boolean {
    return this._showTextSearchSubject.value;
  }
  private set _showTextSearch(value: Boolean) {
    if (this._showTextSearch !== value) {
      this._showTextSearchSubject.next(value);
    }
  }
  get showTextSearch(): Boolean {
    return this._showTextSearch;
  }

  private _showTextSearchSubject = new BehaviorSubject<Boolean>(false);
  public showTextSearch$ = this._showTextSearchSubject.asObservable();

  //public focusOnTextField = false;

  public openTextSearch(focusOnTextField: boolean = false){
    //this.focusOnTextField = focusOnTextField;

    this.searchTextV5Service.clearLastSearch();

    this._showTextSearch = true;

    this._showDetailsForSearchMusicChannel = null;
    this._showDetailsForSearchMusicCollection = null;

    this._selectedTab = TunifyV5Tab.SEARCH;
  }

  public closeTextSearch(){
    this._showTextSearch = false;
  }

    /**
   * show search result
   */
    private get _showSearchResults(): Boolean {
      return this._showSearchResultsSubject.value;
    }
    private set _showSearchResults(value: Boolean) {
      if (this._showSearchResults !== value) {
        this._showSearchResultsSubject.next(value);
      }
    }
    get showSearchResults(): Boolean {
      return this._showSearchResults;
    }

    private _showSearchResultsSubject = new BehaviorSubject<Boolean>(false);
    public showSearchResults$ = this._showSearchResultsSubject.asObservable();

    public openSearchResults(){

      this._showSearchResults = true;

      if (this.selectedTab != TunifyV5Tab.SEARCH){
        this._showTextSearch = false;
        this._showDetailsForSearchMusicChannel = null;
        this._showDetailsForSearchMusicCollection = null;

        this._selectedTab = TunifyV5Tab.SEARCH;
      }
    }

    public closeSearchResults(){
      this._showSearchResults = false;
    }






}
