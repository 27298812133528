import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MainNavigationItem } from '@components/app-components/menu/navigation-menu/model/MainNavigationItem';
import { LoggerService } from '@service/loggers/logger.service';
import { CalendarGroupService } from '@service/calendar-group.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { CalendarGroup } from '@model/calendarGroup';
import { Calendar, isCalendarEditable } from '@model/calendar';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { NavigationItem } from '@components/app-components/menu/navigation-menu/model/NavigationItem';
import { Subject, merge } from 'rxjs';
import { CalendarService } from '@service/calendar.service';
import { takeUntil, map } from 'rxjs/operators';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { TranslateService } from '@ngx-translate/core';
import { SearchService } from '@service/search.service';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { SubscriptionsService } from '../../../../../../services/data/subscriptions.service';

@Component({
  selector: 'tun-calendar-menu',
  templateUrl: './calendar-menu.component.html',
  styleUrls: ['./calendar-menu.component.scss']
})
export class CalendarMenuComponent implements OnInit, OnDestroy {

  public helpMessage:string = null;

  get heightForMenuItem(): number{
    return Math.floor((this.heightPerItem / 20) * 16);
  }

  get loading$(){
    return this.calendarGroupService.loading$;
  }

  //we will show an error when the tunify calendars are not loaded
  get loadingError$(){
    return this.calendarGroupService.loadingError$.pipe(map(error => error != null));
  }

  // === Props === //
  @Input() heightPerItem = 0;

  // === State === //
  private destroyed$ = new Subject<boolean>();
  private LOGGER_CLASSNAME = 'CalendarMenuComponent';
  private oneMainNavigationIsOpen = false;

  mainNavigationItems: MainNavigationItem[] = [];

  private _calendarGroups: CalendarGroup[];

  private get calendarGroups(): CalendarGroup[] {
    return this._calendarGroups;
  }

  private set calendarGroups(value: CalendarGroup[]) {
    this._calendarGroups = value;
    this.createMainNavigationItems();
  }

  private _customCalendars: Calendar[] = null;

  private get customCalendars(): Calendar[] {
    return this._customCalendars;
  }

  private set customCalendars(value: Calendar[]) {
    this._customCalendars = value;
    this.createMainNavigationItems();
  }

  private _selectedCalendar: Calendar;

  private get selectedCalendar(): Calendar {
    return this._selectedCalendar;
  }

  private set selectedCalendar(value: Calendar) {
    this._selectedCalendar = value;
    this.adjustSelectedCalendar();
    this.adjustInfoPopup();
  }

  //if no item is selected and we are fully loaded => show popup with info
  private adjustInfoPopup(){
    if (this.activeMusicSelectionService.selectedCalendar == null && !this.activeMusicSelectionService.selectedCalendarLoading){
      this.helpMessage = "menuPanel.calendar.helpPopup.select";
    }else{
      this.helpMessage = null;
    }
  }

  constructor(
    private loggerService: LoggerService,
    private calendarGroupService: CalendarGroupService,
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private subscriptionsService: SubscriptionsService,
    private calendarService: CalendarService,
    private translateService: TranslateService,
    private searchService: SearchService
  ) { }

  ngOnInit() {

    //when we show the menu -> check if our data needs to be reloaded for error
    this.onReloadMenuContent();

    // MUST BE BEFORE SUBSCRIBE TO CALENDARGROUPS
    // listen for the selected calendar first so we work with the correct value once we create the components
    this.activeMusicSelectionService.selectedCalendar$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (calendar) => {
          this.selectedCalendar = calendar;
        }
      );

    this.calendarGroupService.calendarGroups$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (calendarGroups) => {
          this.calendarGroups = calendarGroups;
        }
      );

    this.calendarService.customCalendars$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (calendars) => {
          this.customCalendars = calendars;
        }
      );

    this.activeMusicSelectionService.selectedCalendarLoading$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => {
          this.adjustInfoPopup();
        }
      );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$ = null;
  }

  onSelectMainNavigationItem(mainNavigationItemToSelect: MainNavigationItem) {
    this.mainNavigationItems.forEach(mainNavigationItem => {
      mainNavigationItem.open = mainNavigationItem === mainNavigationItemToSelect;
    });
    this.oneMainNavigationIsOpen = true;
  }

  onSelectNavigationItem(navigationItem: NavigationItem) {
    // select it as the current Playing item
    // let origin:MusicChannel = navigationItem.originalData as MusicChannel;
    // let test2:MusicChannel = new MusicChannel();

    // this.loggerService.debug(this.LOGGER_CLASSNAME, "onSelectNavigationItem",
    //   "typeOf origin: " + (origin instanceof MusicChannel?"true":"false") +
    //   " -- typeof test2: " + (test2 instanceof MusicChannel?"true":"false"));

    if (navigationItem.originalData instanceof Calendar) {
      this.activeMusicSelectionService.changeActiveCalendar(navigationItem.originalData);
      this.searchService.closeSearch();

      // this.testCreateCalendarItem(navigationItem.originalData);
    }
  }

  onDeleteNavigationItem(navigationItem: NavigationItem){
    if (navigationItem.originalData instanceof Calendar){
      this.calendarService.deleteCalendar(navigationItem.originalData);
    }

  }

  onCreateItemWithTitle(title: string) {
    let calendar = new Calendar();
    calendar.name = title;
    calendar.calendarItems = [];
    calendar.calendarItemsLoaded = true;
    this.activeMusicSelectionService.changeActiveCalendar(calendar);
    this.calendarService.createCustomCalendar(calendar)
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      (asyncStatusWithData) => {
        if (asyncStatusWithData && asyncStatusWithData.asyncStatus == AsyncStatus.COMPLETED){
          if (asyncStatusWithData.data && asyncStatusWithData.data instanceof Calendar){
            //when a calendar is created, we also select it
            this.activeMusicSelectionService.changeActiveCalendar(asyncStatusWithData.data);
          }else{
            this.loggerService.error(this.LOGGER_CLASSNAME, "onCreateItemWithTitle", "Successfully created a calendar, but the created object could not be found -> not going to select in the menu");
          }

        }
      }
    );
  }

  onChangeItemTitle(item: NavigationItem) {
    if (item.originalData instanceof Calendar){
      let calendar = item.originalData;
      if (isCalendarEditable(calendar)){
        calendar.name = item.title;
        this.calendarService.saveCalendar(calendar);
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, "onChangeItemTitle", "The calendar " + calendar.name + "(" + calendar.calendarId + ") is not editable");
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "onChangeItemTitle", "The data to change is not a calendar");
    }
  }


  private _customCalendarsMainNavigationItem : MainNavigationItem;
  private get customCalendarsMainNavigationItem() : MainNavigationItem{
    if (this._customCalendarsMainNavigationItem == null){
      this._customCalendarsMainNavigationItem = new MainNavigationItem();
      this._customCalendarsMainNavigationItem.tunifyColor = TunifyColor.GREEN;
      this._customCalendarsMainNavigationItem.title = "calendar.menu.header.customCalendars";
      this._customCalendarsMainNavigationItem.titleNeedsTranslation = true;
    }
    return this._customCalendarsMainNavigationItem;
  }

  // create mainNavigationItems and navigationItems based on the calendarGroups model
  private menuDestroyed$ = new Subject<void>();
  private createMainNavigationItems() {
    const newMainNavigationItems = [];
    //clean up previous subscriptions for translations
    this.menuDestroyed$.next(null);

    this.oneMainNavigationIsOpen = false;
    if (this.calendarGroups) {

       //custom calendars main navigation
      if  (this.subscriptionsService.accessRights != null && this.subscriptionsService.accessRights.customCalendars){

        this.customCalendarsMainNavigationItem.items = [];

         //new calendar item
         const navigationItem = new NavigationItem();
         navigationItem.isNew = true;
         this.translateService.get("calendar.menu.item.newCalendar")
          .pipe(
            takeUntil(
              merge(this.destroyed$, this.menuDestroyed$)
            )
          )
          .subscribe(
            (value) => {
              navigationItem.title = value;
            }
          );
          this.customCalendarsMainNavigationItem.items.push(navigationItem);

         if (this.customCalendars != null) {
          this.customCalendars.forEach(calendar => {
            const navigationItem = new NavigationItem();
            navigationItem.originalData = calendar;
            navigationItem.title = calendar.name;
            navigationItem.isEditable = true;
            this.customCalendarsMainNavigationItem.items.push(navigationItem);
          });
          newMainNavigationItems.push(this.customCalendarsMainNavigationItem);
        }

      }

      this.calendarGroups.forEach(calendarGroup => {
        const mainNavigationItem = new MainNavigationItem();
        mainNavigationItem.tunifyColor = TunifyColor.GREEN;
        mainNavigationItem.title = calendarGroup.name;

        mainNavigationItem.items = [];
        calendarGroup.calendars.forEach(calendar => {
          const navigationItem = new NavigationItem();
          navigationItem.originalData = calendar;
          navigationItem.title = calendar.name;
          mainNavigationItem.items.push(navigationItem);
        });
        newMainNavigationItems.push(mainNavigationItem);
      });
    }

    this.mainNavigationItems = newMainNavigationItems;

    this.adjustSelectedCalendar();
  }

  // adjust the open mainNavigationItem and the selected navigationItems to the selected calendar
  private adjustSelectedCalendar() {
    if (this.mainNavigationItems && this.mainNavigationItems.length > 0) {
      this.mainNavigationItems.forEach(mainNavigationItem => {
        mainNavigationItem.items.forEach(navigationItem => {
          if (navigationItem.originalData instanceof Calendar) {
            navigationItem.selected = this.selectedCalendar && navigationItem.originalData.calendarId === this.selectedCalendar.calendarId;

            // open the main navigation item of the selected item if no mainNavigationItem is currenctly open
            /*if (navigationItem.selected && !this.oneMainNavigationIsOpen) {
              this.onSelectMainNavigationItem(mainNavigationItem);
            }*/

            //always open the main navigation item of the selected item
            if (navigationItem.selected) {
              this.onSelectMainNavigationItem(mainNavigationItem);
            }

          } else if (navigationItem.isNew){
            //the new calendar item has no originalData -> ok
          } else {
            this.loggerService.error(this.LOGGER_CLASSNAME, 'adjustSelectedCalendar', 'item not recognized: ' + navigationItem);
          }
        });
      });

      // if no items are open -> open the first one
      if (!this.oneMainNavigationIsOpen) {
        let indexToOpen = 0;
        while (indexToOpen < this.mainNavigationItems.length && this.mainNavigationItems[indexToOpen] == this.customCalendarsMainNavigationItem){
          indexToOpen++;
        }
        if (indexToOpen < this.mainNavigationItems.length){
          this.onSelectMainNavigationItem(this.mainNavigationItems[indexToOpen]);
        }
      }
    }
  }

  public onReloadMenuContent(){
    if (this.calendarGroupService.loadingError != null){
      this.calendarGroupService.loadCalendarGroups();
    }
    if (this.calendarService.loadingError != null){
      this.calendarService.loadCustomCalendarsIfNeeded();
    }
  }
}
