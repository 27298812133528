<div class="day-navigator-container" [style.height.px]="heightPerItem - 1">
  <!-- Previous Day -->
  <div
    #previousButton
    class="icon-container"
    [style.height.px]="iconHeight"
    [style.padding]="iconPadding"
  >
    <tun-chevron-left-icon></tun-chevron-left-icon>
  </div>

  <!-- Copy (only shown in edit mode-)-->
  <div
    #copyButton
    class="icon-container light-background-hover copy-icon"
    [style.height.px]="iconHeight"
    [style.padding]="iconPadding"
    [style.visibility]="inEditMode ? 'visible' : 'hidden'"
    tunPopupOnClick
    [closeOnPopupClick]="false"
    [closePopupAfterMouseLeave]="false"
    (togglepopup)="toggleDropdown($event)"
    [clickDelay]="100"
  >
    <tun-copy-icon></tun-copy-icon>
  </div>

  <!-- Current day -->
  <div class="current-day-container">
    <ng-container *ngFor="let day of daysOfWeek; let dayIndex = index">
      <span
        *ngIf="dayIndex === currentIndex"
        class="day"
        [@carousel]="{
          value: direction,
          params: {
            duration: CAROUSEL_ANIMATION_DURATION
          }
        }"
        translate>{{ day.nameToTranslate }}</span
      >
    </ng-container>
  </div>

  <!-- Delete (only shown in edit mode)-->
  <div
    #deleteButton
    class="icon-container light-background-hover delete-icon"
    [style.height.px]="iconHeight"
    [style.padding]="iconPadding"
    [style.visibility]="inEditMode ? 'visible' : 'hidden'"
    style.color="#FFFFFF"
    tunPopupOnHover
    (click)="onClearDay()"
    (togglepopup)="toggleTooltip($event, deleteButton)"
  >
    <tun-delete-icon></tun-delete-icon>
  </div>

  <!-- Next Day-->
  <div
    #nextButton
    class="icon-container"
    [style.height.px]="iconHeight"
    [style.padding]="iconPadding"
  >
    <tun-chevron-right-icon></tun-chevron-right-icon>
  </div>
</div>
