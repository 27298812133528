<div fxFlex fxLayout="column">

  <div [style.height.px]="heightForHeader" fxLayout="column" class="lightPanelBackground">
    <tun-tunify-logo
      fxFlex
      [royaltyFree]="royaltyFree$ | async"
      [totalHeightForComponent]="heightForHeader"
    >
    </tun-tunify-logo>
  </div>


  <div
    class="tunifyWatermark {{colorClass$ | async}}"
    [style.height.px]="heightPerItem-1"
  >
    <ng-container *ngIf="!(newVersionAvailable$ | async)">
      {{productName$ | async}}
    </ng-container>

    <ng-container *ngIf="(newVersionAvailable$ | async)">
      <button
        class="upgrade-version-button"
        [style.height.px]="heightPerItem * 0.6"
        [style.border-radius.px]="heightPerItem * 0.6"
        (click)="onUpgradeVersion()"
      >
        {{'version.upgrade.button.label' | translate}}
      </button>
    </ng-container>

  </div>





  <div fxFlex fxLayout="column" class="lightPanelBackground">

    <tun-music-channel-menu
      *ngIf="(selectedPlayerView$ | async)===TunifyColor.BLUE"
      fxFlex
      [heightPerItem]="heightPerItem"
    >
    </tun-music-channel-menu>

    <tun-calendar-menu
      *ngIf="(selectedPlayerView$ | async)===TunifyColor.GREEN"
      fxFlex
      [heightPerItem]="heightPerItem"
    >
    </tun-calendar-menu>

  </div>



</div>


