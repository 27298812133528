import { HttpClientModule, HttpClient, HttpBackend } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { PopupsModule } from "@components/popups/popups.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ActiveStreamServiceService } from "@service/active-stream-service.service";
import { AuthenticationApiService } from "@service/api/authentication-api.service";
import { AppService } from "@service/app.service";
import { AudioTagService } from "@service/audio.tag.service";
import { AudiofileUrlService } from "@service/audiofile-url.service";
import { AuthenticationService } from "@service/authentication.service";
import { DragDropService } from "@service/drag-drop.service";
import { InMemoryDataService } from "@service/in-memory-data.service";
import { httpInterceptorProviders } from "@service/interceptors/interceptors";
import { LoggerService } from "@service/loggers/logger.service";
import { MusicChannelService } from "@service/music-channel.service";
import { MusicCollectionService } from "@service/music-collection.service";
import { MusicPlayerService } from "@service/music-player.service";
import { PlayTokenService } from "@service/play-token.service";
import { PlaylistService } from "@service/playlist.service";
import { UpdateService } from "@service/update.service";
import { LoginModule } from "@view/login/login.module";
import { PlayerModule } from "@view/player/player.module";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { QueueService } from "@service/queue.service"; // we need this or the queueService is null in our interceptors??
import { StorageServiceModule } from "ngx-webstorage-service";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { IntercomService } from '@service/intercom.service';
import { FirebaseAppModule, initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics,getAnalytics,ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';
import { getAuth, provideAuth } from "@angular/fire/auth";
import { PlayerV5Module } from "@view/player-v5/player-v5.module";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { Intercom, IntercomModule } from "@supy-io/ngx-intercom";
import { ResizeModule } from "@components/resize/resize.module";

// const config: SocketIoConfig = { url: 'http://localhost:3000/socket.io/tunify-remote', options: {} };
// const config: SocketIoConfig = { url: 'http://comm-test.tunify.com:3000/socket.io/tunify-remote', options: {} };
// const config: SocketIoConfig = { url: 'http://comm.tunify.com:3000/socket.io/tunify-remote', options: {} };

// AoT requires an exported function for factories
/*export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
*/
export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend,
    [
      './assets/i18n/',
      './assets/i18n/v5/',
      './assets/i18n/v5/accessRights/',
      './assets/i18n/v5/calendars/',
      './assets/i18n/v5/connect/',
      './assets/i18n/v5/duplicateTracks/',
      './assets/i18n/v5/general/',
      './assets/i18n/v5/start/',
      './assets/i18n/v5/login/',
      './assets/i18n/v5/musicChannels/',
      './assets/i18n/v5/player/',
      './assets/i18n/v5/playlists/',
      './assets/i18n/v5/search/',
      './assets/i18n/v5/settings/',
      './assets/i18n/v5/trackInfo/',
      './assets/i18n/v5/trackOptions/',
      './assets/i18n/v5/version/',
    ]
  );
}


@NgModule({
  declarations: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    PopupsModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    LoginModule,
    PlayerModule,
    PlayerV5Module,
    HttpClientModule,
    true || environment.mockBackend
      ? HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
          delay: 2000,
          passThruUnknownUrl: true
        })
      : [],
    AppRoutingModule,
    FontAwesomeModule,
    // there is a bug in the service worker module from angular:
    // https://github.com/angular/angular-cli/issues/8515
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production
    }),
    StorageServiceModule,
    IntercomModule.forRoot({
      appId: environment.intercomId,
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics())
  ],
  providers: [
    LoggerService,
    AuthenticationService,
    httpInterceptorProviders,
    QueueService,
    ActiveStreamServiceService,
    AppService,
    MusicChannelService,
    MusicCollectionService,
    AudioTagService,
    PlayTokenService,
    AudiofileUrlService,
    MusicPlayerService,
    UpdateService,
    PlaylistService,
    AuthenticationApiService,
    DragDropService,
    Intercom,
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
