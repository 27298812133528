<sass-helper></sass-helper>
<div class="container" #container>
  <tun-playlist
    *ngIf="!isConfigurationForChangeableParameters"
    (close)="closeConfigurationPanel()"
    [isCurrentMusicCollection]="isCurrentMusicCollection"
    [isContainerAnimating]="animating"
    [title]="title"
    [audioFiles]="audioFiles"
    [tracksLoading]="tracksLoading"
    [showPopupDelete]="false"
    (trackevent)="onTrackEvent($event)"
    (tracksEvent)="onTracksEvent($event)"
  ></tun-playlist>

  <ng-container *ngIf="calendarItem || musicCollection">
    <tun-changeable-parameters
      *ngIf="isConfigurationForChangeableParameters"
      [maxScrollbarHeight]="maxScrollbarHeight - heightPerItem + 10"
      [changeableParameters]="changeableParameters"
      [title]="title"
      [tweakInfo]="tweakInfo"
      [tweakInfoLoaded]="tweakInfoLoaded"
      [parametersLoading]="parametersLoading"
      [shufflePlaylist]="calendarItem && calendarItem.changeableParametersLoaded && !calendarItem.changeableParameter"
      [shufflePlaylistValue]="calendarItem && calendarItem.shufflePlaylist"
      [isContainerAnimating]="animating"
      [isCurrentMusicCollection]="isCurrentMusicCollection"
      (close)="closeConfigurationPanel()"
      (reset)="doFactoryReset()"
      (parameterchange)="onParameterChange($event)"
      (shufflePlaylistChange)="onShufflePlaylistChange($event)"
    ></tun-changeable-parameters>
  </ng-container>
</div>
