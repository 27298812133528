import { NavigationItem } from './NavigationItem';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

export class MainNavigationItem {
    public title: String;
    public titleNeedsTranslation: boolean;
    public tunifyColor: TunifyColor;
    public itemNumber: number;
    public originalData: object;
    public items: NavigationItem[];

    public itemsCanAcceptsTrackDrops: boolean;

    public showAmountItems: boolean = false;
    /**
    * open property - available as subscription
    */
    public get open(): boolean  {
        return this._openSubject.value;
    }
    public set open(value: boolean) {
        if (this.open !== value) {
            this._openSubject.next(value);
        }
    }
    private _openSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public open$: Observable<boolean> = this._openSubject.asObservable();
  }
