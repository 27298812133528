import { Injectable, OnDestroy } from '@angular/core';
import { RemoteService } from './remote.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AudioFile } from '@model/audioFile';
import { LoggerService } from '../loggers/logger.service';
import { PrelistenAction, PrelistenCommand } from './vo/remote/remote-objects';
import { takeUntil } from 'rxjs/operators';

export enum PrelistenDeviceStatus{
  detecting,
  detected,
  noDeviceAvailable
}

@Injectable({
  providedIn: 'root'
})
export class PrelistenService implements OnDestroy {

  private readonly LOGGER_CLASSNAME = 'PrelistenService';

  /**
   * sending to prelisten api property
   */
  /*
  private get _sending(): boolean {
    return this._sendingSubject.value;
  }
  private set _sending(value: boolean) {
    if (this._sending !== value) {
        this._sendingSubject.next(value);
    }
  }
  get sending(): boolean {
    return this._sending;
  }
  private _sendingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public sending$: Observable<boolean> = this._sendingSubject.asObservable();
  */
  public prelistenFeedback$ = this.remoteService.prelistenFeedback$;


  /**
   * Device detection property
   */
  private get _prelistenDeviceDetection(): PrelistenDeviceStatus {
    return this._prelistenDeviceDetectionSubject.value;
  }
  private set _prelistenDeviceDetection(value: PrelistenDeviceStatus) {
    if (this._prelistenDeviceDetection !== value) {
        this._prelistenDeviceDetectionSubject.next(value);
    }
  }
  get prelistenDeviceDetection(): PrelistenDeviceStatus {
    return this._prelistenDeviceDetection;
  }
  private _prelistenDeviceDetectionSubject: BehaviorSubject<PrelistenDeviceStatus> = new BehaviorSubject<PrelistenDeviceStatus>(PrelistenDeviceStatus.detecting);
  public prelistenDeviceDetection$: Observable<PrelistenDeviceStatus> = this._prelistenDeviceDetectionSubject.asObservable();


  



  constructor(
    private remoteService: RemoteService,
    private loggerService: LoggerService
  ) { 
    this.remoteService.prelistenFeedback$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (succes) => {
        
        if (succes){
          this._prelistenDeviceDetection = PrelistenDeviceStatus.detected;
        }else{
          this._prelistenDeviceDetection = PrelistenDeviceStatus.noDeviceAvailable;
        }
      }
    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  /*
  public startPrelistening(track: AudioFile){
    this.performPrelistenAction(track, PrelistenCommand.play);
  }

  public stopPrelistening(track: AudioFile){
    this.performPrelistenAction(track, PrelistenCommand.pause);
  }

  public skipBackPrelistening(track: AudioFile){
    this.performPrelistenAction(track, PrelistenCommand.skipBack);
  }

  public skipForwardPrelistening(track: AudioFile){
    this.performPrelistenAction(track, PrelistenCommand.skipForward);
  }
  */

  public performPrelistenAction(track: AudioFile, command: PrelistenCommand){
    const prelistenAction = new PrelistenAction();
    prelistenAction.trackId = track.id + "";
    prelistenAction.prelistenCommand = command;
    
    this.loggerService.debug(this.LOGGER_CLASSNAME, "performPrelistenAction", "Going to send " + command + " command");
    this.remoteService.sendPrelistenAction(prelistenAction);

  }

}
