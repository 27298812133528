import { Input, Output, EventEmitter, Directive } from "@angular/core";
import { ObservableComponent } from "@components/app-components/observable-component/observable-component";

@Directive()
export abstract class PanelComponent extends ObservableComponent {
  
  _title: string;

  // === Props === //
  @Input() 
  set title(value: string){
    this._title = value;
  }
  get title():string{
    return this._title;
  }
  
  @Input() isContainerAnimating: boolean;
  @Input() isCurrentMusicCollection: boolean;

  // === Emitters === //
  @Output() close = new EventEmitter();

  onCloseClick() {
    this.close.emit();
  }
}
