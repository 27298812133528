<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M16,8.931c0-1.712-0.667-3.266-1.75-4.427L15.755,3H11v4.755l1.928-1.928
	c0.747,0.824,1.215,1.907,1.215,3.104c0,2.304-1.691,4.206-3.894,4.567v1.886C13.485,15.013,16,12.268,16,8.931z"
  />
  <path
    fill="currentColor"
    d="M8,15v-4.755l-1.866,1.866c-0.786-0.832-1.277-1.947-1.277-3.18c0-2.304,1.691-4.206,3.894-4.567V2.477
	C5.515,2.849,3,5.595,3,8.931c0,1.748,0.693,3.331,1.815,4.499L3.245,15H8z"
  />
</svg>
