import { Component, OnInit, Input, SimpleChange, OnChanges, Output, EventEmitter, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, NgZone } from '@angular/core';
import { AudioFileWithPlayInfo } from '@service/audioFileWithPlayInfo';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { faPlay, faPause, faStepForward } from '@fortawesome/free-solid-svg-icons';
import { LoggerService } from '@service/loggers/logger.service';
import { AudioTagService } from '@service/audio.tag.service';
import { Subscription, Subject, timer, merge } from 'rxjs';
import { AudioFile } from '@model/audioFile';
import { PlayState } from '@model/enums/playState.enum';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SassHelperComponent } from 'src/providers/sass-helper/sass-helper.component';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { takeUntil, take, filter, map, delay } from 'rxjs/operators';
import { AudioFileProperty } from '@model/enums/audioFileProperty';
import { TrackEvent, isSearchAction } from '@model/events/trackEvent';
import { SearchService } from '@service/search.service';
import { PopupService } from '@service/popup.service';
import { TooltipComponent, TooltipAlignment } from '@components/popups/tooltip/tooltip.component';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import { PopupContent, TooltipPopupContent } from '@components/popups/popup/interfaces';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ExternalLinksService } from '@service/external-links.service';
import { DragDropService } from '@service/drag-drop.service';
import { PlayableAudio } from '../../../model/audioFile';
import { ZoneConnectionsService } from '../../../services/authentication/zone-connections.service';
import { RemotePopupComponent } from '../../popups/remote-popup/remote-popup.component';
import { ZonePresenceChecker, ZonePresenceService } from '../../../services/realTimeCommunication/zone-presence.service';

@Component({
  selector: 'tun-audio-file-controller',
  templateUrl: './audio-file-controller.component.html',
  styleUrls: ['./audio-file-controller.component.scss']
})
export class AudioFileControllerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  private LOGGER_CLASSNAME = 'AudioFileControllerComponent';

  @ViewChild(SassHelperComponent, { static: true }) private sassHelper: SassHelperComponent;
  @ViewChild('playerComponent') playerComponent: ElementRef;
  @ViewChild('togglePlayButton') togglePlayButton: ElementRef;
  @ViewChild('remoteButton') remoteButton: ElementRef;


  private _componentDestroyed$ = new Subject<void>();

  faPlay = faPlay;
  faPause = faPause;
  faStepForward = faStepForward;

  iconHeight = 0;
  iconPadding: SafeStyle;

  public pauseIconClass = "blueIcon";

  public favoriteIconColor = "";
  public bannedIconColor = "";

  public playing = false;
  public audioFileProperty: AudioFileProperty;

  get enabledTrackActions(): boolean {
    return this.audioFile && this.audioFile.canPerformTrackActions;
  }

  constructor(private loggerService: LoggerService,
    private audioTagService: AudioTagService,
    private searchService: SearchService,
    private changeDetectorRef: ChangeDetectorRef,
    private zoneConfigurationService: ZoneConfigurationService,
    private sanitizer: DomSanitizer,
    private _popupService: PopupService,
    private _ngZone: NgZone,
    private dragDropService: DragDropService,
    private deviceDetectorService: DeviceDetectorService,
    private externalLinksService: ExternalLinksService,
    private zoneConnectionsService: ZoneConnectionsService,
    private zonePresenceService: ZonePresenceService
    ) {
  }

  @Input() showNext = true;


  @Input() audioFile: PlayableAudio = null;
  @Input() audioFileWithPlayInfo: AudioFileWithPlayInfo = null;
  @Input() isFavorite = false;
  @Input() isBanned = false;
  @Input() tunifyColor: TunifyColor;
  @Input() buttonHeight: number;
  @Input() openRemoteInfoOnNewRemote: boolean = false;

  @Output() togglePlay = new EventEmitter<boolean>();
  @Output() next = new EventEmitter();
  @Output() seekToTime = new EventEmitter<number>();
  @Output() toggleFavorite = new EventEmitter<boolean>();
  @Output() toggleBanned = new EventEmitter<boolean>();
  @Output() trackEvent = new EventEmitter<TrackEvent>();

  @Output() dropTrack = new EventEmitter<AudioFile>();

  ngOnInit() {
    this.calcButtonValues();
    this.adjustsStyle();

    this.zonePresenceService.remoteJoins$
    .pipe(
      delay(50),
      takeUntil(this._componentDestroyed$)
    )
    .subscribe(
      () => {
        if (this.openRemoteInfoOnNewRemote){
          this.openRemoteInfo(true);
        }
      }
    )

    this.audioTagService.needClickToStartAudio$
      .pipe(
        takeUntil(this._componentDestroyed$)
      )
      .subscribe(
        (needClick) => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, "needClickToStartAudio subscription", "value: " + needClick);
          this.changeDetectorRef.detectChanges();

          this._ngZone.run(() => {
            this.showOrHideNeedClickTooltip();
          });
        }
      );

    this.zoneConfigurationService.audioFileProperty$
      .pipe(
        takeUntil(this._componentDestroyed$)
      )
      .subscribe(
        (audioFileProperty) => {
          this.audioFileProperty = audioFileProperty;
        }
      )


  }

  private initDoneForTooltips = false;
  ngAfterViewInit() {
    //wait a bit before showing any tooltips
    timer(200)
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(() => {
        this.initDoneForTooltips = true;
        this.showOrHideNeedClickTooltip();
      });

      this._ngZone.runOutsideAngular(() => {
        this.playerComponent.nativeElement.addEventListener('dragover', this.onDragOver);
        this.playerComponent.nativeElement.addEventListener(
          'dragenter',
          this.onDragEnter
        );
        this.playerComponent.nativeElement.addEventListener(
          'dragleave',
          this.onDragLeave
        );
        this.playerComponent.nativeElement.addEventListener('drop', this.onDrop);
      });

  }


  private playStateSubscription: Subscription;
  private needClickAudioFileSubscription: Subscription;
  private watchAudioFileProperties() {
    this.cleanupAudioFileSubscriptions();

    if (this.audioFileWithPlayInfo) {
      this.playStateSubscription = this.audioFileWithPlayInfo.playerState$
        .pipe(
          takeUntil(this._componentDestroyed$)
        )
        .subscribe(
          (playState) => {
            this.playing = playState === PlayState.PLAYING || playState === PlayState.STARTING_TO_PLAY;
            this.showOrHideNeedClickTooltip();
            this.changeDetectorRef.detectChanges();
          }
        );

      this.needClickAudioFileSubscription = this.audioFileWithPlayInfo.needClickToUseAudioTag$
        .pipe(
          takeUntil(this._componentDestroyed$)
        )
        .subscribe(
          (value) => {
            this._ngZone.run(() => {
              this.showOrHideNeedClickTooltip();
            });
            //this.changeDetectorRef.detectChanges();
          }
        );
    } else {
      this.playing = false;
      this.showOrHideNeedClickTooltip();
    }

  }

  private cleanupAudioFileSubscriptions() {
    if (this.playStateSubscription) {
      this.playStateSubscription.unsubscribe();
      this.playStateSubscription = null;
    }
    if (this.needClickAudioFileSubscription) {
      this.needClickAudioFileSubscription.unsubscribe();
      this.needClickAudioFileSubscription = null;
    }
  }

  ngOnDestroy() {
    this.hideNeedClickTooltip();
    this._componentDestroyed$.next(null);
    this._componentDestroyed$.complete();

    //clean up drop handlers
    this._ngZone.runOutsideAngular(() => {

      this.playerComponent.nativeElement.removeEventListener(
        'dragover',
        this.onDragOver
      );

      this.playerComponent.nativeElement.removeEventListener(
        'dragenter',
        this.onDragEnter
      );
      this.playerComponent.nativeElement.removeEventListener(
        'dragleave',
        this.onDragLeave
      );
      this.playerComponent.nativeElement.removeEventListener('drop', this.onDrop);
    });
  }



  ngOnChanges(inputChanges: { [propName: string]: SimpleChange }) {
    if (inputChanges['tunifyColor']) {
      this.adjustsStyle();
    }
    if (inputChanges['audioFileWithPlayInfo']) {
      this.watchAudioFileProperties();
    }
    if (inputChanges['buttonHeight']) {
      this.calcButtonValues();
    }
    if (inputChanges['isFavorite']) {
      this.adjustFavoriteIconColor();
    }
    if (inputChanges['isBanned']) {
      this.adjustBannedIconColor();
    }
  }

  private calcButtonValues() {

    this.iconHeight = Math.floor(this.buttonHeight / 2);
    let restHeight = this.buttonHeight - this.iconHeight;
    this.iconPadding = this.sanitizer.bypassSecurityTrustStyle(
      `calc(${restHeight}px / 2) 0.75rem`
    );
  }

  private normalColor = "white-1";
  private focusColor = "white-1";
  private adjustsStyle() {
    if (this.tunifyColor == TunifyColor.BLUE) {
      this.pauseIconClass = "blueIcon";
      this.focusColor = "blue-player-button";
    } else if (this.tunifyColor == TunifyColor.GREEN) {
      this.pauseIconClass = "greenIcon";
      this.focusColor = "green-player-button";
    } else if (this.tunifyColor == TunifyColor.ORANGE) {
      this.pauseIconClass = "orangeIcon";
      this.focusColor = "orange-player-button";
    } else {
      if (this.tunifyColor != null){ //null -> starting up
        this.loggerService.error(this.LOGGER_CLASSNAME, "adjustsStyle", "tunifyColor not recognized");
      }
      this.pauseIconClass = "blueIcon";
      this.focusColor = "blue-player-button";
    }
    this.adjustFavoriteIconColor();
    this.adjustBannedIconColor();
  }

  private adjustFavoriteIconColor() {
    this.favoriteIconColor = this.sassHelper.readProperty(this.isFavorite ? this.focusColor : this.normalColor);
    this.changeDetectorRef.detectChanges();
  }

  private adjustBannedIconColor() {
    this.bannedIconColor = this.sassHelper.readProperty(this.isBanned ? this.focusColor : this.normalColor);
    this.changeDetectorRef.detectChanges();
  }



  public onTogglePlay() {
    //when the user clicks the play button, we try to resume our audioContext if it is currently suspended
    if (this.audioTagService.needClickToStartAudio) {

      this.audioTagService.enableAudioAfterClick();
      if (this.audioFileWithPlayInfo){
        this.audioFileWithPlayInfo.startForAudioContextResume();
      }


      this.loggerService.debug(this.LOGGER_CLASSNAME, "onTogglePlay", "starting audio -> " + (NgZone.isInAngularZone() ? "" : "NOT ") + "in angular zone");

      if (!this.playing) {
        this.togglePlay.emit(true);
      }
    } else if (this.audioFileWithPlayInfo && this.audioFileWithPlayInfo.needClickToUseAudioTag) {
      //firefox -> resume?
      this.audioFileWithPlayInfo.startForAudioContextResume();
    } else {
      this.togglePlay.emit(!this.playing);
    }

  }

  public onNext() {
    this.next.emit();
  }

  public onSeekToTime(time: number) {
    this.seekToTime.emit(time);
  }

  public onToggleFavorite() {
    //already adjust internal values, for faster user experience
    this.isFavorite = !this.isFavorite;
    this.adjustFavoriteIconColor();
    if (this.isFavorite) {
      this.isBanned = false;
      this.adjustBannedIconColor();
    }

    this.toggleFavorite.emit(this.isFavorite);
  }

  public onToggleBanned() {
    //already adjust internal values, for faster user experience
    this.isBanned = !this.isBanned;
    this.adjustBannedIconColor();
    if (this.isBanned) {
      this.isFavorite = false;
      this.adjustFavoriteIconColor();
    }

    this.toggleBanned.emit(this.isBanned);
  }

  public onTrackEvent(trackEvent: TrackEvent) {
    if (isSearchAction(trackEvent.action)) {
      this.searchService.handleSearchOnTrack(trackEvent);
    } else {
      this.loggerService.error(this.LOGGER_CLASSNAME, "onTrackEvent", "we need to handle action " + trackEvent.action);
    }
  }

  /** TOOLTIP -  Need a click to start */

  public get needClickToStartAudio() {
    return this.audioTagService.needClickToStartAudio || (this.audioFileWithPlayInfo && this.audioFileWithPlayInfo.needClickToUseAudioTag);
  }

  private tooltipContent: TooltipPopupContent;
  private showOrHideNeedClickTooltip() {
    if (this.needClickToStartAudio && this.playing && this.togglePlayButton != null && this.initDoneForTooltips) {
      if (!this.tooltipContent) {
        this.showNeedClickTooltip();
      } else {
        this.showNeedClickText();
      }
    } else if (!this.needClickToStartAudio && this.tooltipContent) {
      //we don't need a click any more
      this.tooltipContent.text = "player.autostart.confirm";
      this.tooltipContent.tooltipAction = null;
      timer(2000)
        .pipe(
          takeUntil(
            merge(
              this._componentDestroyed$,
              this.audioTagService.needClickToStartAudio$.pipe(
                filter(value => value == true),
                takeUntil(this._componentDestroyed$)
              )
            )
          )
        )
        .subscribe(() => {
          this._ngZone.run(() => {
            this.hideNeedClickTooltip();
          });
        });
    } else if (!this.playing && this.tooltipContent) {
      //we are not in the playing state -> just remove the we need a click tooltip
      this.hideNeedClickTooltip();
    }
  }

  private showNeedClickTooltip() {
    this._popupService.showPopup$.next({
      connector: this.togglePlayButton,
      componentType: TooltipComponent,
      popupDirection: PopupDirection.DOWN,
      popupPosition: PopupPosition.BOTTOM_CENTER,
      dynamicPosition: true,
      animationDuration: 200,
      showArrow: true,
    });

    this._popupService.currentInstance$
      .pipe(
        filter(
          (instance: PopupContent) =>
            instance &&
            instance.connectedElementRef === this.togglePlayButton
        ),
        take(1),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe((instance: TooltipPopupContent) => {
        this.tooltipContent = instance;
        this.showNeedClickText();
      });

    //listen when the popup will be closed
    merge(
      this._popupService.hideAllPopups$,
      this._popupService.hidePopup$.pipe(
        filter(
          ({ nativeElement }) =>
            nativeElement === this.togglePlayButton.nativeElement

        ),
        takeUntil(this._componentDestroyed$)
      )
    )
      .pipe(
        take(1),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe(() => {
        this.tooltipContent = null;
      });
  }

  private showNeedClickText() {
    let browser = this.deviceDetectorService.browser;
    let isFirefox = browser != null && browser.toLowerCase() == "firefox";
    //special message for firefox
    if (isFirefox) {
      this.tooltipContent.text = "player.autostart.firefox.needClick";
      this.tooltipContent.alignment = TooltipAlignment.LEFT;
      this.tooltipContent.tooltipAction = { action: this.openFirefoxLink, text: "player.autostart.firefox.clickAction" }
    } else {
      this.tooltipContent.text = "player.autostart.needClick";
      this.tooltipContent.alignment = TooltipAlignment.CENTER;
      this.tooltipContent.tooltipAction = { action: this.play, text: "player.autostart.clickAction" }
    }

  }

  private hideNeedClickTooltip() {
    if (this.tooltipContent) {
      this._popupService.hidePopup$.next(this.togglePlayButton);
    }

  }

  private play = () => {
    if (this.audioTagService.needClickToStartAudio) {
      this.audioTagService.enableAudioAfterClick();
      this.audioFileWithPlayInfo.startForAudioContextResume();
      if (!this.playing) {
        this.togglePlay.emit(true);
      }
    }
  }

  private openFirefoxLink = () => {
    this.externalLinksService.openFirefoxAutoPlayHelp();
  }

  /**
   * DROP HANDLERS
   */

  onDragEnter = (event: DragEvent) => {
    if (this.dragDropService.draggingAudioFile === null || this.draggingFromThisComponent) {
      return;
    }

    event.dataTransfer.dropEffect = "copy";

    event.stopPropagation();

  };


  onDragOver = (event: DragEvent) => {
    if (this.dragDropService.draggingAudioFile === null || this.draggingFromThisComponent) {
      return;
    }

    event.dataTransfer.dropEffect = "copy";

    event.stopPropagation();
    event.preventDefault();

   //improvement: show drop feedback in UI

  };

  onDragLeave = (event: DragEvent) => {
    if (this.dragDropService.draggingAudioFile === null || this.draggingFromThisComponent) {
      return;
    }

    event.stopPropagation();

    //improve: hide drop feedback
  };


  onDrop = (event: DragEvent) => {
    if (this.dragDropService.draggingAudioFile === null || this.draggingFromThisComponent) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();

    //improve: hide drop feedback

    const audioFile: AudioFile = this.dragDropService.draggingAudioFile;

    this._ngZone.run(() => {
      this.dropTrack.emit(audioFile);
    });
  };

  private draggingFromThisComponent = false;
  public onDraggingFromThisChanged(value: boolean){
    this.draggingFromThisComponent = value;
  }

  /**
   * Remote popup
   */

   get remoteRemoteActive$(){
    return this.zoneConnectionsService.currentRemoteApplicationInfos$
    .pipe(
      map(remoteApplicationInfos => remoteApplicationInfos != null && remoteApplicationInfos.length > 0)
    )
  }

  openRemoteInfo(showPopup: boolean) {
    if (this.remoteButton != null){
      if (showPopup) {
        this._popupService.showPopup$.next({
          connector: this.remoteButton,
          componentType: RemotePopupComponent,
          animationDuration: 300,
          popupDirection: PopupDirection.DOWN,
          showArrow: true,
          popupPosition: PopupPosition.BOTTOM_CENTER,
        });
      } else {
        this._popupService.hidePopup$.next(this.remoteButton);
      }
    }

  }
}
