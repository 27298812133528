import { NavigationItem } from '@components/app-components/menu/navigation-menu/model/NavigationItem';

/**
 * Extension of the basic NavigationItem for Tunify Orange.
 * These items contain an extra second level.
 */
export class OrangeNavigationItem extends NavigationItem {

  items: NavigationItem[] = [];

  deselectSelectedItems() {
    this.items.forEach((item) => item.selected = false);
  }
}
