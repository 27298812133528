import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, Subscription, pipe } from 'rxjs';
import { AppService } from '@service/app.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { map, takeUntil } from 'rxjs/operators';
import { AppVersionService, MAJOR_VERSION } from '@service/app-version.service';
import { MatDialog } from '@angular/material/dialog';
import { UpgradeVersionPopupComponent } from '@components/popups-v5/upgrade-version-popup/upgrade-version-popup.component';

@Component({
  selector: 'tun-menu-stream-panel',
  templateUrl: './menu-stream-panel.component.html',
  styleUrls: ['./menu-stream-panel.component.scss'],
  host: {
    'fxLayout': 'column'
  }
})
export class MenuStreamPanelComponent implements OnDestroy{

  @Input() public heightForHeader = 100;
  @Input() public heightPerItem = 40;

  get newVersionAvailable$(){
    return this.appVersionService.allowedVersionsSubject$
      .pipe(
        map(
          () => {
            return this.appVersionService.showLinkForVersion(MAJOR_VERSION.V5)
          }
        )
      )
  }

  get royaltyFree$(){
    return this.zoneConfigurationService.royaltyFree$;
  }

  public get selectedPlayerView$(){
    return this.activeMusicSelectionService.selectedPlayerView$;
  }

  public get colorClass$(){
    return this.activeMusicSelectionService.selectedPlayerView$.pipe(map(selectedPlayerView => this.getColorClass(selectedPlayerView)));
  }

  private getColorClass(tunifyColor: TunifyColor): string{
    if (tunifyColor === TunifyColor.BLUE){
      return 'blue';
    }else if (tunifyColor === TunifyColor.GREEN){
      return 'green';
    }return '';
  }

  public get productName$(){
    return this.activeMusicSelectionService.selectedPlayerView$.pipe(map(selectedPlayerView => this.getProductName(selectedPlayerView)));
  }

  private getProductName(tunifyColor: TunifyColor): string{
    if (tunifyColor === TunifyColor.BLUE){
      return 'Tunify Blue';
    }else if (tunifyColor === TunifyColor.GREEN){
      return 'Tunify Green';
    }
    return '';
  }

  //we need to make the enum public so we can use it in our html file
  public TunifyColor = TunifyColor;

  constructor(
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private zoneConfigurationService: ZoneConfigurationService,
    private appVersionService:AppVersionService,
    private dialog: MatDialog,
  ) {

  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }

  onUpgradeVersion(){
    const dialogRef = this.dialog.open(UpgradeVersionPopupComponent, {
      width: UpgradeVersionPopupComponent.widthForPopup,
      hasBackdrop: true,
      backdropClass: 'dark-blurry-backdrop',
      panelClass: 'custom-popup-container',
      data: {confirm: false}
    });

    dialogRef.afterClosed()
    .pipe(
      takeUntil(
        this.destroy$
      )
    )
    .subscribe(result => {
      if (result && result.confirm){
        this.appVersionService.changeVersionToUse(MAJOR_VERSION.V5);
      }
    });
  }

}
