<tun-panel-header
  tunThemed
  [title]="title"
  [showClose]="showClose"
  (close)="onCloseClick()"
>
  <div tunThemed class="left-header-buttons">
    <tun-icon-button
      tunTooltipOnHover
      [tooltipText]="'tooltip.trackList.addAllToQueue'"
      [height]="heigthForTitle$ | async"
      (click)="onAddToQueueClick()"
    >
      <tun-add-to-queue-icon></tun-add-to-queue-icon>
    </tun-icon-button>
  </div>

  <div class="right-header-buttons" [style.justify-content]="'space-between'">
    <div>
      <tun-icon-button
        tunTooltipOnHover
        [tooltipText]="'tooltip.search.refresh'"
        [height]="heigthForTitle$ | async"
        (click)="onRefreshClicked()"
      >
        <tun-refresh-icon></tun-refresh-icon>
      </tun-icon-button>
      <tun-icon-button
        [style.visibility]="isContext ? 'visible' : 'hidden'"
        [height]="heigthForTitle$ | async"
        (click)="onTweakClicked()"
      >
        <tun-settings-icon></tun-settings-icon>
      </tun-icon-button>
    </div>
    <div>
      <tun-icon-button
        tunTooltipOnHover
        [tooltipText]="'tooltip.trackList.sort'"
        tunSongSortOnClick
        [height]="heigthForTitle$ | async"
        (songSortModeChange)="onSongSortModeChange($event)"
      >
        <tun-sort-icon></tun-sort-icon>
      </tun-icon-button>

      <tun-icon-button
        tunTooltipOnHover
        [tooltipText]="'tooltip.trackList.addAllToCustomList'"
        [tooltipEnabled]="!showingAddToListPopup"
        [height]="heigthForTitle$ | async"
        #addToListButton
        tunPopupOnClick
        (togglepopup)="onToggleAddToListPopup($event)"
        [closeOnPopupClick]="false"
        [closeOnOutsideElementClick]="true"
      >
        <tun-add-to-list-icon></tun-add-to-list-icon>
      </tun-icon-button>
    </div>
  </div>
</tun-panel-header>

<ng-container [ngSwitch]="playlistState">
  <tun-song-grid
    *ngSwitchCase="PlaylistState.tracks"
    [playlist]="audioFiles"
    [showAddArrow]="canAddSongs"
    [songSortMode]="songSortMode"
    (trackEvent)="onTrackEvent($event)"
    borderColor="#171C1D"
    [maxScrollbarHeight]="
      (availableHeightForPlayer$ | async) -
      (heightPerItem$ | async) -
      (heightForHeader$ | async) -
      (heightForTopBar$ | async) * 2
    "
  ></tun-song-grid>

  <ng-container *ngSwitchCase="PlaylistState.loading">
    <tun-loader></tun-loader>
  </ng-container>

  <div *ngSwitchCase="PlaylistState.error"
        class="feedback-container">
    <div class="error-feedback-container">
      <p translate>
        orange.musicCollection.contentPanel.load.error
      </p>
      <button (click)="onRefreshClicked()" class="retryButton">
        <p translate>
          general.tryAgain
        </p>
      </button>
    </div>
  </div>

</ng-container>
