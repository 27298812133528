<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 18 18"
  height="100%"
  width="100%"
>
  <path
    fill="currentColor"
    d="M18 9c0 .4-.1.7-.4.9l-6.5 6.5c-.3.2-.6.4-.9.4-.3 0-.6-.1-.9-.4l-.8-.8c-.3-.3-.4-.6-.4-.9s.1-.7.4-.9l2.9-2.9H1.2c-.3 0-.6-.1-.8-.4-.3-.2-.4-.5-.4-.9V8.4c0-.4.1-.7.3-.9s.5-.4.8-.4h10.3l-2.8-3c-.3-.2-.4-.5-.4-.9s.1-.7.4-.9l.8-.8c.3-.3.6-.4.9-.4.4 0 .7.1.9.4L17.7 8c.2.3.3.6.3 1z"
  />
</svg>
