import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { MyListsPopupComponent } from '@components/popups/my-lists-popup/my-lists-popup.component';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import {
  AddToListSelection,
  MyListsPopupContent,
  PopupContent
} from '@components/popups/popup/interfaces';
import { AudioFile } from '@model/audioFile';
import { StreamMode } from '@model/enums/streamMode';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { TrackAction, TrackEvent } from '@model/events/trackEvent';
import {
  TrackIndexAction,
  TrackIndexEvent
} from '@model/events/trackIndexEvent';
import { SongGridColors } from '@model/fieldModels/songGridColors';
import { Playlist } from '@model/playlist';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { OrangeStateService } from '@service/orange-state.service';
import { PlaylistService } from '@service/playlist.service';
import { PopupService } from '@service/popup.service';
import { QueueService } from '@service/queue.service';
import { SearchService } from '@service/search.service';
import { AsyncStatus, isFinal } from '@service/vo/asyncStatus';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Subject, BehaviorSubject, merge, combineLatest } from 'rxjs';
import { filter, finalize, switchMap, takeUntil, map, delay, mergeMap } from 'rxjs/operators';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { Util } from '../../../../../utils/util';
import { trigger } from '@angular/animations';
import { TrackManipulationControllerService } from '../../../../../services/audio/track-manipulation-controller.service';
import { RemoteActionsService } from '../../../../../services/audio/remote-actions.service';
import { RemotePlayStateService } from '@service/remote-play-state.service';
import { ZoneConnectionsService, ApplicationMode } from '../../../../../services/authentication/zone-connections.service';

@Component({
  selector: 'tun-queue-panel',
  templateUrl: './queue-panel.component.html',
  styleUrls: ['./queue-panel.component.scss'],
  // memory leak work-around: add no-op animation in parent component of each songGrid. Otherwise, the @HostBinding('@fadeIn') animation causes the component to stay in memory.
  animations: [trigger("noop", [])]
})
export class QueuePanelComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroyed$ = new Subject<boolean>();
  private LOGGER_CLASSNAME = 'QueuePanelComponent';

  // === Props === //
  private _heightPerItem = 40;
  get heightPerItem(): number {
    return this._heightPerItem;
  }

  get isRemote$(){
    return this.zoneConnectionsService.applicationMode$
      .pipe(map(applicationMode => applicationMode == ApplicationMode.remoteMode))
  }

  @Input()
  set heightPerItem(value: number) {
    this._heightPerItem = value;
    this.iconHeight = value / 2 - 1;
    this.iconPadding = this.sanitizer.bypassSecurityTrustStyle(
      `calc(${value}px / 4) 0.75rem`
    );
  }

  // === State === //

  get busyText$(){
    return combineLatest([this.remoteActionSending$, this.loading$, this.shuffling$])
      .pipe(
        map(([remoteActionSending, loading, shuffling]) =>
          remoteActionSending ? "connect.sync.action" :
            (shuffling ? "waitingQueue.shuffle.busy" :
              (loading ? "general.loading" : "")
            )
        )
      )
  }

  get busy$(){
    return combineLatest([this.remoteActionSending$, this.loading$, this.shuffling$])
      .pipe(
        map(([remoteActionSending, loading, shuffling]) => remoteActionSending || loading || shuffling)
      )
  }

  get loading$(){
    return this.queueService.loadingQueue$;
  }

  public get remoteActionSending$(){
    return combineLatest([this.startingTrack$, this.waitingForNextTrack$])
      .pipe(
        map(([startingTrack, waitingForNextTrack]) => startingTrack || waitingForNextTrack)
      )
  }

  public get startingTrack$(){
    return this.remoteActionsService.startingTrack$
      .pipe(map(audioFile => audioFile != null));
  }

  public get waitingForNextTrack$(){
    return this.remotePlayStateService.waitingForTrackInfoAfterNext$;
  }

  public formatSeconds(secondsToFormat: number): string{
    return Util.formatSeconds(secondsToFormat);
  }

  public get queuedTracks$(){
    return this.queueService.queue$;
  }

  public get queuedTracksAvailable$(){
    return this.queueService.queue$.pipe(map(tracks => tracks != null && tracks.length > 0));
  }

  public get totalDuration$(){
    return this.queueService.queue$.pipe(map(tracks => {
      if (tracks != null && tracks.length > 0){
        return tracks.reduce((sum, track) => sum + track.duration / 1000, 0);
      }
      return 0;
    }));
  }

  public shuffling$ = new BehaviorSubject(false);


  get activeCalendar$() {
    if (this.activeMusicSelectionService.selectedStreamMode == StreamMode.CALENDAR) {
      return this.activeMusicSelectionService.selectedCalendar$;
    }
    return null;
  }
  get activeMusicChannel$() {
    if (this.activeMusicSelectionService.selectedStreamMode == StreamMode.MUSIC_CHANNEL) {
      return this.activeMusicSelectionService.selectedMusicChannel$;
    }
    return null;
  }

  get selectedPlayerView$() {
    return this.activeMusicSelectionService.selectedPlayerView$;
  }

  public get showPlaylistButton$(){
    return this.activeMusicSelectionService.selectedPlayerView$.pipe(map(playerView => playerView !== TunifyColor.ORANGE))
  }

  //make the enum available in the html page
  TunifyColor = TunifyColor;

  private _myListsPopup: MyListsPopupContent = null;

  private get myListsPopup(): MyListsPopupContent {
    return this._myListsPopup;
  }

  private changeMyListsPopup$ = new Subject<void>();
  private set myListsPopup(instance: MyListsPopupContent) {
    this.changeMyListsPopup$.next(null);
    this._myListsPopup = instance;
    this.changeDetectorRef.detectChanges();

    if (this.myListsPopup){
      this.myListsPopup.onSearch$
      .pipe(takeUntil(this.changeMyListsPopup$))
      .subscribe((playlist: Playlist) => {
        // show the playlist
        if (
          this.activeMusicSelectionService.selectedPlayerView ===
          TunifyColor.ORANGE
        ) {
          this.orangeStateService.showPlaylistFromSearch(playlist);
        } else {
          this.playlistService.showPlaylist(playlist);
        }

        // close the popup
        this.toggleMyListsPopup(false);
      });

    this.myListsPopup.onSelect$
      .pipe(
        switchMap(({ playlist }: AddToListSelection) =>
          this.musicManipulationService.addPlaylistToQueue(playlist)
        ),
        takeUntil(this.changeMyListsPopup$),
        finalize(() => {
          this._myListsPopup = undefined;
        })
      )
      .subscribe((status: AsyncStatus) => (this._myListsPopup.status = status));
    }
  }

  // make the enum available in the html file
  StreamMode = StreamMode;

  get showingMyListsPopup(){
    return this.goingToShowMyListsPopup || this.myListsPopup != null;
  }

  // === Styling state === //
  iconHeight = 0;
  iconPadding: SafeStyle = '';
  playlistsIconColor = '#C8C8C8';
  shuffleIconColor = '#C8C8C8';
  deleteIconColor = '#C8C8C8';
  songGridColors: SongGridColors = new SongGridColors();

  // === ViewChildren === //
  @ViewChild('myListsButton') myListsButton: ElementRef;

  constructor(
    private queueService: QueueService,
    private musicManipulationService: MusicManipulationService,
    private trackManipulationControllerService: TrackManipulationControllerService,
    private sanitizer: DomSanitizer,
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private zoneConfigurationService: ZoneConfigurationService,
    private loggerService: LoggerService,
    private _popupService: PopupService,
    private searchService: SearchService,
    private playlistService: PlaylistService,
    private orangeStateService: OrangeStateService,
    private changeDetectorRef: ChangeDetectorRef,
    private remoteActionsService: RemoteActionsService,
    private remotePlayStateService: RemotePlayStateService,
    private zoneConnectionsService: ZoneConnectionsService
  ) { }

  ngOnInit() {

    this._popupService.currentInstance$
      .pipe(
        filter(
          (popup : PopupContent) =>
            popup.connectedElementRef && popup.connectedElementRef === this.myListsButton &&
            popup && (popup instanceof MyListsPopupComponent)

        ),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (instance: MyListsPopupContent) => {
          this.myListsPopup = instance;
          this.goingToShowMyListsPopup = false;
        }

      );


    this._popupService.hidePopup$
      .pipe(
        filter(
          (elementRef: ElementRef) =>
            elementRef && elementRef === this.myListsButton
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => {
          this.myListsPopup = null;
        }
      );

      //we need to run change detection in case we have updates from our real time platform
      this.queuedTracks$
        .pipe(
          delay(1),
          takeUntil(this.destroyed$)
        )
        .subscribe(
          () => {
            this.changeDetectorRef.detectChanges();
          }
        );

      this.busy$
      .pipe(
        delay(1),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => {
          this.changeDetectorRef.detectChanges();
        }
      );


    /*this.zoneConfigurationService.selectedStreamMode$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(()=>this.changeDetectorRef.detectChanges());
      */
  }

  ngAfterViewInit() {
    this.fetchColors();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$ = null;
  }

  play(audioFile: AudioFile) {
    this.trackManipulationControllerService.playAudioFile(audioFile);
  }

  removeAudioFileFromQueue(audioFile: AudioFile) {
    this.musicManipulationService.removeAudioFileFromQueue(audioFile);
  }

  onIconHover = (isHovering: boolean, icon: string) => {
    switch (icon) {
      case 'playlists':
        this.playlistsIconColor = isHovering ? '#FFFFFF' : '#C8C8C8';
        break;
      case 'shuffle':
        this.shuffleIconColor = isHovering ? '#FFFFFF' : '#C8C8C8';
        break;
      case 'delete':
        this.deleteIconColor = isHovering ? '#FFFFFF' : '#C8C8C8';
        break;
      default:
        break;
    }
  };

  deletePlaylist = () => {
    this.musicManipulationService.clearQueue();
  };

  shufflePlaylist = () => {
    this.musicManipulationService.shuffleQueue()
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (asyncStatus) => {
        this.shuffling$.next(!isFinal(asyncStatus));
        console.warn(asyncStatus);
      }
    )
  };

  // Called when a drop event happens, object has index and audioFile props.
  onTrackIndexEvent = (trackIndexEvent: TrackIndexEvent) => {
    console.warn(trackIndexEvent);

    if (trackIndexEvent.action === TrackIndexAction.MOVE_TO_INDEX) {
      this.musicManipulationService.moveAudioFileInQueue(
        trackIndexEvent.track,
        trackIndexEvent.index
      );
    } else if (trackIndexEvent.action === TrackIndexAction.ADD_AT_INDEX) {
      this.musicManipulationService.addAudioFilesToQueue(
        [trackIndexEvent.track],
        trackIndexEvent.index
      );
    } else {
      this.loggerService.error(
        this.LOGGER_CLASSNAME,
        'onTrackIndexEvent',
        'action not implemented'
      );
    }
  };

  onTrackEvent = (trackEvent: TrackEvent) => {
    if (trackEvent.action === TrackAction.REMOVE) {
      this.musicManipulationService.removeAudioFileFromQueue(trackEvent.track);
    } else {
      this.loggerService.error(
        this.LOGGER_CLASSNAME,
        'onTrackEvent',
        'Custom track action ' + trackEvent.action + ' not implemented'
      );
    }
  };

  fetchColors = () => {
    const songGridColors = new SongGridColors()
      .borderColor('grey-14')
      .dragLineBorderColor('grey-song-grid-drag-border')
      .backgroundHoverColor('blue-15')
      .fieldBackgroundHoverColor('grey-15')
      .iconColor('grey-13')
      .queueIconHoverColor('blue-queue-icon-hover')
      .moreIconHoverColor('white-1');
    this.songGridColors = songGridColors;
  };

  private goingToShowMyListsPopup = false;
  toggleMyListsPopup(showPopup: boolean) {
    if (showPopup) {
      this.goingToShowMyListsPopup = true; //we need to hide the tooltip BEFORE we show the other popup (to avoid conflicts in the popupService)
      this.changeDetectorRef.detectChanges();
      this._popupService.showPopup$.next({
        connector: this.myListsButton,
        popupPosition: PopupPosition.BOTTOM_CENTER,
        componentType: MyListsPopupComponent,
        showArrow: true,
        animationDuration: 300,
        dynamicPosition: true,
        popupDirection: PopupDirection.DOWN
      });
    } else {
      this._popupService.hidePopup$.next(this.myListsButton);
      //this.myListsPopup = null;
    }
  }

  openStreamTab() {
    if (this.activeMusicSelectionService.selectedStreamMode == StreamMode.CALENDAR) {
      this.activeMusicSelectionService.selectedPlayerView = TunifyColor.GREEN;
    } else {
      this.activeMusicSelectionService.selectedPlayerView = TunifyColor.BLUE;
    }
  }
}
