<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
  >
  <path 
    fill="currentColor"
    d="M8.787,8.525c0,0.253-0.136,0.488-0.355,0.615l-7.367,6.146c-0.22,0.127-0.491,0.127-0.709,0
			c-0.22-0.127-0.355-0.361-0.355-0.615V2.376c0-0.253,0.135-0.488,0.355-0.615c0.109-0.063,0.232-0.095,0.354-0.095
			c0.123,0,0.246,0.031,0.355,0.095l7.368,6.148C8.651,8.036,8.787,8.27,8.787,8.525z M17.59,7.909l-7.367-6.148
			c-0.11-0.063-0.232-0.095-0.355-0.095c-0.123,0-0.245,0.031-0.355,0.095c-0.22,0.127-0.355,0.362-0.355,0.615v12.295
			c0,0.254,0.135,0.488,0.355,0.615c0.219,0.127,0.49,0.127,0.71,0L17.59,9.14c0.218-0.127,0.355-0.362,0.355-0.615
			C17.945,8.271,17.808,8.036,17.59,7.909z"
  />
</svg>