import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import { RemotePopupV5Component } from '@components/popups/remote-popup-v5/remote-popup-v5.component';
import { RemotePopupComponent } from '@components/popups/remote-popup/remote-popup.component';
import { ApplicationMode, ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { PopupService } from '@service/popup.service';
import { ZonePresenceService } from '@service/realTimeCommunication/zone-presence.service';
import { Subject, combineLatest } from 'rxjs';
import { delay, filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-remote-info-buttons-view',
  templateUrl: './remote-info-buttons-view.component.html',
  styleUrls: ['./remote-info-buttons-view.component.scss']
})
export class RemoteInfoButtonsViewComponent {

  @ViewChild('remoteButton') remoteButton: ElementRef;

  private _canShowTooltips = false;
  @Input()
  set canShowTooltips(value: boolean){
    this._canShowTooltips = value;
    if (!this.canShowTooltips){
      this.openRemoteInfo(false);
    }
  }
  get canShowTooltips():boolean{
    return this._canShowTooltips;
  }



  constructor(
    private zonePresenceService: ZonePresenceService,
    private zoneConnectionsService: ZoneConnectionsService,
    private popupService: PopupService,
    ) {

      this.zonePresenceService.remoteJoins$
      .pipe(
        delay(50),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => {
          if (this.canShowTooltips){
            this.openRemoteInfo(true);
          }
        }
      )

      this.zoneConnectionsService.currentPlayerApplicationInfo$
      .pipe(
        delay(50),
        map(remoteApplicationInfo => remoteApplicationInfo != null),
        filter(v => v == false),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        ()=>{
          if (this.zoneConnectionsService.applicationMode == ApplicationMode.remoteMode){
            //open the remote info popup as soon as no player is active any longer
            this.openRemoteInfo(true);
          }
        }
      )
    }

    private destroyed$ = new Subject<void>();
    ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;
    }

  /**
   * Remote popup
   */

  get remoteRemoteActive$(){
    return combineLatest([this.zoneConnectionsService.applicationMode$, this.zoneConnectionsService.currentRemoteApplicationInfos$])
    .pipe(
      map(([applictionMode, remoteApplicationInfos]) => applictionMode == ApplicationMode.playerMode && remoteApplicationInfos != null && remoteApplicationInfos.length > 0)

    )
  }

  get isRemote$(){
    return this.zoneConnectionsService.applicationMode$
    .pipe(map(applicationMode => applicationMode == ApplicationMode.remoteMode));
  }

  public showingPopup = false;
  openRemoteInfo(showPopup: boolean) {
    if (this.remoteButton != null){
      if (showPopup != this.showingPopup){
        if (showPopup) {
          this.popupService.showPopup$.next({
            connector: this.remoteButton,
            componentType: RemotePopupV5Component,
            animationDuration: 300,
            popupDirection: PopupDirection.DOWN,
            showArrow: false,
            popupPosition: PopupPosition.BOTTOM_CENTER,
          });
        } else {
          this.popupService.hidePopup$.next(this.remoteButton);
        }
        this.showingPopup = showPopup;
      }
    }
  }
}
