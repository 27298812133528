<div fxFlex fxLayout="column" class="main">
  <tun-track-list-title-box
    [title]="title"
    (close)="onCloseSearch()">
  </tun-track-list-title-box>

  <tun-track-list-content
    fxFlex
    class="layerContainer lightPanelBackground flexBoxMinimumSizing"
    [showSuggestion]="showSuggestion"
    [audioFiles]="trackList"
    [tracksLoading]="loading"
    [style.max-width.px]="widthForContentPanel$ | async"
    (tracksEvent)="onTracksEvent($event)"
  >
  </tun-track-list-content>
</div>
