import { transition, trigger, useAnimation } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { ObservableComponent } from '@components/app-components/observable-component/observable-component';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import {
  PopupContent,
} from '@components/popups/popup/interfaces';
import { SongInfoPopupComponent } from '@components/popups/song-info-popup/song-info-popup.component';
import { AudioFile } from '@model/audioFile';
import {
  AudioFileProperty,
  formatTrackProperty,
  isTrackPropertySearchable
} from '@model/enums/audioFileProperty';
import { SongSortMode } from '@model/enums/songSortMode';
import {
  searchActionForTrackProperty,
  TrackAction,
  TrackEvent
} from '@model/events/trackEvent';
import { SongGridColors } from '@model/fieldModels/songGridColors';
import { Song } from '@model/song';
import { AppService } from '@service/app.service';
import { DragDropService } from '@service/drag-drop.service';
import { PopupService } from '@service/popup.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { fadeAnimation } from '@util/animations';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubscriptionsService } from '../../../../services/data/subscriptions.service';

@Component({
  selector: 'tun-song-grid-row',
  templateUrl: './song-grid-row.component.html',
  styleUrls: ['./song-grid-row.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // fade in
        useAnimation(fadeAnimation)
      ]),
      transition(':leave', [
        // fade out
        useAnimation(fadeAnimation, { params: { from: 1, to: 0 } })
      ])
    ])
  ]
})
export class SongGridRowComponent extends ObservableComponent
  implements OnInit, OnDestroy, OnChanges {
  // === Props === //
  @Input() draggable = false;
  @Input() index: number;
  @Input() tableRowHoverId: number;
  @Input() isBeingDraggedOver = false;
  @Input() dragLineBorderColor: string;
  @Input() borderColor: string;
  @Input() songGridColors: SongGridColors;
  @Input() song: AudioFile;
  @Input() isUserDragging = false;
  @Input() isQueue: boolean;
  @Input() firstColWidth: SafeStyle;
  @Input() secondColWidth: SafeStyle;
  @Input() thirdColWidth: SafeStyle;
  @Input() showAddArrow = false;
  @Input() showFrontDelete = false;
  @Input() showPopupDelete: boolean;
  @Input() songSortMode: SongSortMode;
  @Input() audioFileProperty: AudioFileProperty;

  // === ViewChildren === //
  @ViewChild('frontIcon', { static: true }) frontIcon: ElementRef;
  @ViewChild('moreicon', { static: true }) moreicon: ElementRef;
  @ViewChild('songgridrow', { static: true }) songGridRow: ElementRef;

  // === Emitters === //
  @Output() trackEvent = new EventEmitter<TrackEvent>();

  // === State === //
  queueIconColor = '';
  queueIconBackgroundColor = '';
  moreIconColor = '';
  moreIconBackgroundColor = '';

  draggingThisRow = false;
  propertyText = '-';
  propertySearchable = false;

  public PopupDirection = PopupDirection;
  public PopupPosition = PopupPosition;

  // === Getters === //
  get heightPerItem() {
    return this._appService.heightPerItem;
  }

  get searchEnabled(): boolean {
    return (
      environment.enableSearch &&
      this.subscriptionsService.accessRights &&
      this.subscriptionsService.accessRights.search
    );
  }

  get playOnDoubleClick(): boolean {
    return (
      this.subscriptionsService.accessRights &&
      this.subscriptionsService.accessRights.startTrack
    );
  }

  get tracksAreDraggable(): boolean {
    //we make tracks draggable if they can be added to the queue
    return (
      this.subscriptionsService.accessRights &&
      this.subscriptionsService.accessRights.addToQueue
    );
  }

  get showingSongInfoPopup() {
    return this.goingToShowSongInfoPopup || this.songInfoPopup != null;
  }


  constructor(
    private cdRef: ChangeDetectorRef,
    private _popupService: PopupService,
    private _dragDropService: DragDropService,
    private _appService: AppService,
    private _zoneConfigurationService: ZoneConfigurationService,
    private subscriptionsService: SubscriptionsService
  ) {
    super();
  }

  ngOnInit() {
    if (this.songGridColors) {
      this.queueIconColor = this.songGridColors._iconColor;
      this.moreIconColor = this.songGridColors._iconColor;
    }
    fromEvent(window, 'resize')
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this._popupService.currentInstance$
      .pipe(
        filter(
          (instance: PopupContent) =>
            instance && instance.connectedElementRef === this.moreicon
            && instance instanceof SongInfoPopupComponent
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((instance: SongInfoPopupComponent) => {

        this.songInfoPopup = instance;
        this.goingToShowSongInfoPopup = false;
      });

      this._popupService.hidePopup$
      .pipe(
        filter(
          (elementRef: ElementRef) =>
            elementRef && elementRef === this.moreicon
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.songInfoPopup = null;
      })
  }

  private _songInfoPopup: SongInfoPopupComponent;
  private songInfoPopupChanged$ = new Subject<void>();
  private set songInfoPopup(instance: SongInfoPopupComponent) {
    this.songInfoPopupChanged$.next(null);

    this._songInfoPopup = instance;

    if (instance) {
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
      instance.song = this.song as Song;
      instance.showNavigation = true;
      instance.showDelete = this.showPopupDelete;
      instance.selectTrackEvent$
        .pipe(takeUntil(this.songInfoPopupChanged$))
        .subscribe(trackEvent => {
          this.trackEvent.emit(trackEvent);
        });
    }
  }
  private get songInfoPopup(): SongInfoPopupComponent {
    return this._songInfoPopup;
  }

  private closeAllPopups() {
    this._popupService.hidePopup$.next(this.moreicon);
    this._popupService.hidePopup$.next(this.frontIcon);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.closeAllPopups();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['audioFileProperty'] || simpleChanges['song']) {
      this.adjustTrackPropertyValues();
    }

    if (simpleChanges.song) {
      this.closeAllPopups();
    }
  }

  // Called when the drag starts to attach the audiofile to the event.
  // This happens in the component where the audiofile is dragged from.
  private crt : HTMLElement = null;
  onDragStart(event: DragEvent, audioFile: AudioFile) {
    this.draggingThisRow = true;
    this.cdRef.detectChanges();

    // Create ghost image, needed to fix bug in Safari and Firefox
    // caused by virtual scrolling.
    this.crt = (event.target as HTMLElement).cloneNode(true) as HTMLElement;
    this.crt.style.width = `${this.songGridRow.nativeElement.offsetWidth}px`;
    document.body.appendChild(this.crt);

    // Normally the ghost image would start at the position of the cursor
    // but we want it to look like the user dragges an AudioFile out of its place.
    const { x, y } = this.songGridRow.nativeElement.getBoundingClientRect();
    const xOffset = event.clientX - x;
    const yOffset = event.clientY - y;

    // Apply the ghost image to the drag event.
    event.dataTransfer.setDragImage(this.crt, xOffset, yOffset);

    // Needed for Firefox, otherwise drag isn't allowed.
    event.dataTransfer.setData('fire', 'fox');

    this._dragDropService.dragStartedFromQueue = this.isQueue;
    this._dragDropService.isUserDragging = true;
    this._dragDropService.draggingAudioFile = audioFile;
  }

  // Called when the drag ends, whether it was succesful or not doesn't matter.
  // This happens in the component where the audiofile is dragged from.
  onDragEnd() {
    this.draggingThisRow = false;
    if (this.crt){
      document.body.removeChild(this.crt);
      this.crt = null;
    }


    this.cdRef.detectChanges();

    this._dragDropService.isUserDragging = false;
    this._dragDropService.isUserDraggingLong = false;
    this._dragDropService.draggingAudioFile = null;
  }

  onFieldDoubleClick() {
    this.trackEvent.emit(new TrackEvent(TrackAction.PLAY, this.song));
  }

  onFrontIconClick() {
    if (this.showFrontDelete) {
      this.trackEvent.emit(new TrackEvent(TrackAction.REMOVE, this.song));
    } else if (this.isQueue) {
      this.trackEvent.emit(new TrackEvent(TrackAction.PLAY, this.song));
    } else {
      this.trackEvent.emit(new TrackEvent(TrackAction.ADD_TO_QUEUE, this.song));
    }
  }

  onTitleClick() {
    this.trackEvent.emit(
      new TrackEvent(TrackAction.SEARCH_ON_TITLE, this.song)
    );
  }

  onGroupClick() {
    this.trackEvent.emit(
      new TrackEvent(TrackAction.SEARCH_ON_GROUP, this.song)
    );
  }

  onPropertyClick() {
    const searchAction = searchActionForTrackProperty(
      this._zoneConfigurationService.audioFileProperty
    );
    if (searchAction) {
      this.trackEvent.emit(new TrackEvent(searchAction, this.song));
    }
  }

  private goingToShowSongInfoPopup = false;
  togglePopup(togglePopup: boolean) {
    if (togglePopup) {
      this.goingToShowSongInfoPopup = true;
      this.cdRef.detectChanges();
      this._popupService.showPopup$.next({
        connector: this.moreicon,
        popupPosition: PopupPosition.BOTTOM_CENTER_THEN_TOP,
        dynamicPosition: true,
        animationDuration: 400,
        showArrow: true,
        componentType: SongInfoPopupComponent
      });


    } else {
      this._popupService.hidePopup$.next(this.moreicon);
    }
  }

  onAddClicked() {
    this.trackEvent.emit(new TrackEvent(TrackAction.ADD_TO_EDITING_PLAYLIST, this.song));
  }

  private adjustTrackPropertyValues() {
    this.propertySearchable =
      this.searchEnabled &&
      isTrackPropertySearchable(this.song, this.audioFileProperty);
    this.propertyText = formatTrackProperty(this.song, this.audioFileProperty);
  }
}
