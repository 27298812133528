import { Component, Input } from '@angular/core';

@Component({
  selector: 'tun-delete-icon',
  templateUrl: './delete-icon.component.html'
})
export class DeleteIconComponent {
  @Input() color: string;

  constructor() {}
}
