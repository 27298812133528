<div
  #songfield
  class="song-field-text-container {{isClickable ? 'hover-effect': ''}} {{ isSorted ? 'sorted' : ''  }}"
>
  <div
    class="song-field-click-background"
    [style.opacity]="opacity"
    [style.-webkit-transition.ms]="whiteBackgroundTransitionSpeed"
    [style.-moz-transition.ms]="whiteBackgroundTransitionSpeed"
    [style.transition.ms]="whiteBackgroundTransitionSpeed"
  ></div>
  <div
    #songfieldtext
    class="song-field-text"
    [style.margin-left]="songFieldMarginLeft"
    [style.-webkit-transition]="transition"
    [style.-moz-transition]="transition"
    [style.transition]="transition"
  >
    {{ text }}
  </div>
</div>
