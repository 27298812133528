import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Subject, Subscription } from 'rxjs';
import { MusicChannel } from 'src/app/model/musicChannel';
import { MusicCollection } from 'src/app/model/musicCollection';
import { MusicCollectionService } from '@service/music-collection.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicPlayerService } from '@service/music-player.service';
import { AudioFileOriginType } from '@model/enums/audioFileOriginType';
import { PlayState } from '@model/enums/playState.enum';
import { Context } from '@model/context';
import { AudioFileProperty } from '@model/enums/audioFileProperty';
import { TrackAction, TrackEvent } from '@model/events/trackEvent';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { AudioTagService } from '@service/audio.tag.service';
import { TracksEvent, TracksAction } from '@model/events/tracksEvent';
import {
  MusicCollectionEvent,
  MusicCollectionAction,
} from '@model/events/musicCollectionEvent';
import { MusicChannelService } from '@service/music-channel.service';
import { ChangeableParameter } from '@model/changeableParameter';
import { ChangeableSelectionParameter } from '@model/changeableSelectionParameter';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { AudioFile } from '../../../../../model/audioFile';
import { takeUntil } from 'rxjs/operators';
import { threadId } from 'worker_threads';
import { trigger } from '@angular/animations';
import { TrackManipulationControllerService } from '../../../../../services/audio/track-manipulation-controller.service';
import { AppService } from '../../../../../services/app/app.service';
import { ZoneConnectionsService } from '../../../../../services/authentication/zone-connections.service';
import { ApplicationMode } from '@service/authentication/zone-connections.service';
import { RemotePlayStateService } from '../../../../../services/audio/remote-play-state.service';
import { PlayModeForRemoteSharing } from '../../../../../services/data/vo/remote/remote-objects';
import { ResizedEvent } from '@components/resize/resize.directive';

@Component({
  selector: 'tun-music-channel-content-panel',
  templateUrl: './music-channel-content-panel.component.html',
  styleUrls: ['./music-channel-content-panel.component.scss'],
})
export class MusicChannelContentPanelComponent implements OnInit, OnDestroy {
  public set sizeIsOk(value: boolean) {
    if (this._sizeIsOk != value) {
      this._sizeIsOk = value;
      this.changeDetectorRef.detectChanges();
    }
  }
  public get sizeIsOk(): boolean {
    return this._sizeIsOk;
  }

  constructor(
    private zoneConfigurationService: ZoneConfigurationService,
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private musicChannelService: MusicChannelService,
    private musicCollectionService: MusicCollectionService,
    private musicPlayerService: MusicPlayerService,
    private audioTagService: AudioTagService,
    private musicManipulationService: MusicManipulationService,
    private trackManipulationControllerService: TrackManipulationControllerService,
    private loggerService: LoggerService,
    private changeDetectorRef: ChangeDetectorRef,
    private zoneConnectionsService: ZoneConnectionsService,
    private remotePlayStateService: RemotePlayStateService
  ) {}
  @Input() heightPerItem = 0;

  // bugfix -> on startup, the size of the containers is not ready until views are added to the dom. We wait to add the block components until this is ready
  private _sizeIsOk = false;

  // we only need this to log the width and height of the container for debugging
  @ViewChild('container', { static: true }) container: ElementRef;

  public musicChannel: MusicChannel = null;
  public currentAudioFileFromMusicCollectionWithId = -1;
  public playing = false;
  public audioFileProperty: AudioFileProperty;

  private LOGGER_CLASSNAME = 'MusicChannelContentPanelComponent';

  ngOnInit() {
    this.activeMusicSelectionService.selectedMusicChannel$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      musicChannel => {
        this.musicChannel = musicChannel;
      }
    );

    this.musicPlayerService.currentActiveAudioFileWithPlayInfo$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.adjustPlayingFromId();
      }
    );

    this.remotePlayStateService.remoteTrackObject$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.adjustPlayingFromId();
      }
    );

    this.zoneConnectionsService.applicationMode$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.adjustPlayingFromId();
        this.updatePlaying();
      }
    );

    this.musicPlayerService.playerState$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.updatePlaying();
      }
    );

    this.audioTagService.needClickToStartAudio$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.updatePlaying();
      }
    );

    this.remotePlayStateService.playMode$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.updatePlaying();
      }
    );

    this.zoneConfigurationService.audioFileProperty$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      audioFileProperty => {
        this.audioFileProperty = audioFileProperty;
      }
    );
  }

  private adjustPlayingFromId(){

    let track = null;

    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode ){
      if (this.musicPlayerService.currentActiveAudioFileWithPlayInfo != null){
        track = this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile;
      }
    }else if (this.zoneConnectionsService.applicationMode == ApplicationMode.remoteMode ){
      track = this.remotePlayStateService.remoteTrackObject;
    }

    if (
      track != null &&
      track instanceof AudioFile &&
      track.origin != null &&
      track.origin.type ==
        AudioFileOriginType.MUSICCOLLECTION
    ) {
      const idValue = parseInt(track.origin.data, 10);

      /*
      const dataAsNumber = Number(
        audioFileWithPlayInfo.audioFile.origin.data
      );
      */
      if ( !isNaN(idValue)) {
        this.currentAudioFileFromMusicCollectionWithId = idValue;
      } else {
        this.currentAudioFileFromMusicCollectionWithId = -1;
        this.loggerService.error(
          this.LOGGER_CLASSNAME,
          'currentAudioFileChanged',
          'Could not convert data to number altough we expect it to be a number: ' + track.origin.data
        );
      }
    } else {
      this.currentAudioFileFromMusicCollectionWithId = -1;
    }

    this.changeDetectorRef.detectChanges();
  }

  public onResizeContainer(event: ResizedEvent){
    this.sizeIsOk = this.container.nativeElement.offsetHeight > 0;
  }



  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private updatePlaying() {
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
      this.playing =
      (this.musicPlayerService.playState === PlayState.PLAYING ||
        this.musicPlayerService.playState === PlayState.STARTING_TO_PLAY) &&
      !this.audioTagService.needClickToStartAudio;
    }else if (this.zoneConnectionsService.applicationMode == ApplicationMode.remoteMode){
      this.playing = this.remotePlayStateService.playMode == PlayModeForRemoteSharing.playing || this.remotePlayStateService.playMode == PlayModeForRemoteSharing.startingPlay;
    }else{
      this.playing = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  public onTrackEvent(trackEvent: TrackEvent) {
    if (trackEvent.action === TrackAction.PLAY) {
      this.trackManipulationControllerService.playAudioFile(trackEvent.track);
    } else if (trackEvent.action === TrackAction.ADD_TO_QUEUE) {
      this.musicManipulationService.addAudioFilesToQueue([trackEvent.track]);
    }
  }

  public onTracksEvent(tracksEvent: TracksEvent) {
    if (tracksEvent.action === TracksAction.ADD_TO_QUEUE) {
      this.musicManipulationService.addAudioFilesToQueue(tracksEvent.tracks);
    }
  }

  public onMusicCollectionEvent(musicCollectionEvent: MusicCollectionEvent) {
    if (
      musicCollectionEvent.action ===
      MusicCollectionAction.SELECTED_PROPERTY_CHANGED
    ) {
      this.musicChannelService.saveMusicCollectionSelectionProperty(
        this.musicChannel,
        musicCollectionEvent.musicCollection
      );
    } else if (
      musicCollectionEvent.action === MusicCollectionAction.SHOW_DETAILS
    ) {
      this.loadMusicCollectionDetails(musicCollectionEvent.musicCollection);
    } else if (
      musicCollectionEvent.action ===
        MusicCollectionAction.PARAMETERS_CHANGED &&
      musicCollectionEvent.musicCollection instanceof Context
    ) {
      this.musicChannelService.saveContextParameters(
        this.musicChannel,
        musicCollectionEvent.musicCollection
      );
      this.musicCollectionService.loadTweakInfo(
        musicCollectionEvent.musicCollection
      );
    } else if (
      musicCollectionEvent.action === MusicCollectionAction.RESET_PARAMETERS
    ) {
      if (musicCollectionEvent.musicCollection instanceof Context) {
        this.musicChannelService.resetContextParameters(
          this.musicChannel,
          musicCollectionEvent.musicCollection
        );
        this.musicCollectionService.loadTweakInfo(
          musicCollectionEvent.musicCollection
        );
      }
    }
  }

  private loadMusicCollectionDetails(musicCollection: MusicCollection) {
    // load details (audioFiles for a playlist, changeable parameters for a context)
    if (!musicCollection.detailsLoaded && !musicCollection.detailsLoading) {
      this.musicCollectionService.loadMusicCollectionDetails(musicCollection);
    } else {
      this.loggerService.debug(
        this.LOGGER_CLASSNAME,
        'loadMusicCollectionDetails',
        'already loading / loaded for musicCollection: ' + musicCollection
      );
    }
  }
}
