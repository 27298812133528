import { Component, OnInit, OnDestroy, OnChanges, ViewChild, Input, NgZone, SimpleChange, ChangeDetectorRef } from '@angular/core';
import { AudioTagWrapper } from '@service/audioTagWrapper';
import { LoggerService } from '@service/loggers/logger.service';
import { AudioTagService } from '@service/audio.tag.service';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { ResizedEvent } from '@components/resize/resize.directive';

@Component({
  selector: 'tun-sound-spectrum-stereo-component',
  templateUrl: './sound-spectrum-stereo-component.component.html',
  styleUrls: ['./sound-spectrum-stereo-component.component.scss']
})
export class SoundSpectrumStereoComponentComponent implements OnInit, OnDestroy, OnChanges {

  private destroyed = false;

    private LOGGER_CLASSNAME = 'SoundSpectrumStereoComponent';

    @Input() audioTagWrapper: AudioTagWrapper;
    @Input() playing = false;
    @Input() tunifyColor = TunifyColor.BLUE;


    @ViewChild('visualiserCanvas', {static: true}) visualiserCanvas:any;

    constructor(
      private audioTagService: AudioTagService,
      private ngZone: NgZone,
      private changeDetectorRef: ChangeDetectorRef,
      private loggerService: LoggerService
    ) {
      this.changeDetectorRef.detach();
    }

    private splitter: ChannelSplitterNode;
    private leftAnalyser: AnalyserNode;
    private rightAnalyser: AnalyserNode;
    private merger: ChannelMergerNode;
    private leftdataArray: any;
    private rightdataArray: any;
    private canvasCtx: CanvasRenderingContext2D;
    private initDone = false;

    ngOnInit() {

        if (this.audioTagService.audioContext) {

            // set up the splitter
            this.splitter = this.audioTagService.audioContext.createChannelSplitter(2);

             // set up the left analyser
            this.leftAnalyser = this.audioTagService.audioContext.createAnalyser();
            this.leftAnalyser.minDecibels = -90;
            this.leftAnalyser.maxDecibels = -10;
            this.leftAnalyser.smoothingTimeConstant = 0.8;
            this.leftAnalyser.fftSize = this.fftSize;
            //this.leftAnalyser.connect(this.audioTagService.audioContext.destination);

            // set up the analyser
            this.rightAnalyser = this.audioTagService.audioContext.createAnalyser();
            this.rightAnalyser.minDecibels = -90;
            this.rightAnalyser.maxDecibels = -10;
            this.rightAnalyser.smoothingTimeConstant = 0.8;
            this.rightAnalyser.fftSize = this.fftSize;
            //this.rightAnalyser.connect(this.audioTagService.audioContext.destination);



            // set up the merger
            this.merger = this.audioTagService.audioContext.createChannelMerger(2);

            // connect everything
            this.splitter.connect(this.leftAnalyser, 0,0);
            this.splitter.connect(this.rightAnalyser, 1, 0);
            this.leftAnalyser.connect(this.merger, 0, 0);
            this.rightAnalyser.connect(this.merger, 0, 1);

            //test without the analysers
            //this.splitter.connect(this.merger, 0, 0);
            //this.splitter.connect(this.merger, 1, 1);

            this.merger.connect(this.audioTagService.audioContext.destination);

            this.adjustDrawingVars();


        }else {
          this.loggerService.info(this.LOGGER_CLASSNAME, 'ngOnInit', 'AudioContext does not exist');
        }

        this.canvasCtx = this.visualiserCanvas.nativeElement.getContext("2d");
        this.adjustDrawingCanvasSize();

        this.initDone = true;

        this.connectAudioElement();
    }

    public onResizeContainer(event: ResizedEvent){
      //this.loggerService.debug(this.LOGGER_CLASSNAME, 'onResize', 'resize event fired');
      this.adjustDrawingCanvasSize();
    }

    ngOnDestroy() {
        this.disconnectAudioNode();

        if (this.cancelValue >= 0) {
          cancelAnimationFrame(this.cancelValue);
        }

        this.destroyed = true;
    }

    ngOnChanges(inputChanges:  { [propName: string]: SimpleChange }) {
      if (inputChanges['audioTagWrapper']) {
          this.connectAudioElement();
      }else  if (inputChanges['playing']) {
        this.startRunningIfNeeded();
      }else if (inputChanges['tunifyColor']){
        this.adjustFillStyle();
      }
    }


    private adjustDrawingCanvasSize(){

      this.canvasCtx.canvas.width = this.visualiserCanvas.nativeElement.clientWidth;
      this.canvasCtx.canvas.height = this.visualiserCanvas.nativeElement.clientHeight;

      /*
      this.loggerService.debug(this.LOGGER_CLASSNAME, "adjustDrawingCanvasSize", "native width" + this.visualiserCanvas.nativeElement.clientWidth + " - height: " + this.visualiserCanvas.nativeElement.clientHeight
        + " canvas width: " + this.canvasCtx.canvas.width + " height: " + this.canvasCtx.canvas.height);
      */


      this.adjustFFTSize();
      //we should set the fill style again after changing the size of the canvasCtx
      this.adjustFillStyle();


    }


    private adjustFillStyle(){
      if (this.tunifyColor == TunifyColor.BLUE){
        this.canvasCtx.fillStyle = '#3CB0D1';
      }else if (this.tunifyColor == TunifyColor.GREEN){
        this.canvasCtx.fillStyle = '#62C362';
      }else if (this.tunifyColor == TunifyColor.ORANGE){
        this.canvasCtx.fillStyle = '#FF692C';
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, 'adjustFillStyle', 'TunifyColor not recognized');
        this.canvasCtx.fillStyle = '#3CB0D1';
      }
    }

    private amountOfBarsPerChannel = 0;
    private totalAmountOfBars = 0;


    private adjustFFTSize(){
      if (this.canvasCtx){

        //we try to keep the bars at 6px (and a spacer of 2)
        //we end up with an amount of bars equal to the fftSize
        let amountOfBars = this.visualiserCanvas.nativeElement.clientWidth / 4;
        let fftSizeTemp = 128; //start with max fft size
        while (fftSizeTemp > 32 && amountOfBars < fftSizeTemp){
          fftSizeTemp = fftSizeTemp / 2;
        }
        this.fftSize = fftSizeTemp;
      }
    }

    private _fftSize = 64;
    private set fftSize(value:number){
      if (this._fftSize != value){
        this._fftSize = value;

        this.adjustDrawingVars();
      }

    }
    private get fftSize():number{
      return this._fftSize;
    }

    private adjustDrawingVars(){
      if (this.leftAnalyser != null){
        this.leftAnalyser.fftSize = this.fftSize;
        this.amountOfBarsPerChannel = this.leftAnalyser.frequencyBinCount;
        this.leftdataArray = new Uint8Array(this.leftAnalyser.frequencyBinCount);
      }else{
        this.amountOfBarsPerChannel = 0;
      }
      if (this.rightAnalyser != null){
        this.rightAnalyser.fftSize = this.fftSize;
        this.rightdataArray = new Uint8Array(this.rightAnalyser.frequencyBinCount);
      }

      this.totalAmountOfBars = 2 * this.amountOfBarsPerChannel;


    }


    private connectedAudioTagWrapper: AudioTagWrapper;
    private connectAudioElement() {
      this.loggerService.info(this.LOGGER_CLASSNAME, 'connectAudioElement', 'into connectAudioElement');
      if (this.initDone) {

        if (this.connectedAudioTagWrapper !== this.audioTagWrapper) {

          this.loggerService.info(this.LOGGER_CLASSNAME, 'connectAudioElement', 'going to connect source');

          this.disconnectAudioNode();



          if (this.audioTagWrapper !== null && this.audioTagWrapper.audioGainNode != null) {

            this.connectedAudioTagWrapper = this.audioTagWrapper;

            if (this.splitter != null) {
              this.connectedAudioTagWrapper.audioGainNode.disconnect(this.audioTagService.audioContext.destination);
              this.connectedAudioTagWrapper.audioGainNode.connect(this.splitter);
            }else {
              // no analyser - do custom animation?
            }
          }
          this.startRunningIfNeeded();
        }
      }

    }

    private disconnectAudioNode() {
      this.loggerService.info(this.LOGGER_CLASSNAME, 'disconnectAudioNode', 'into disconnectAudioNode');
      if (this.connectedAudioTagWrapper != null && this.connectedAudioTagWrapper.audioGainNode != null) {
        this.loggerService.info(this.LOGGER_CLASSNAME, 'disconnectAudioNode', 'disconnecting source');

        if (this.splitter) {
          this.connectedAudioTagWrapper.audioGainNode.disconnect(this.splitter);
          this.connectedAudioTagWrapper.audioGainNode.connect(this.audioTagService.audioContext.destination);
        }

        this.connectedAudioTagWrapper = null;
      }
    }

    private callBacksRunning: boolean = false;
    private startRunningIfNeeded() {
        if (!this.callBacksRunning) {
            if (this.playing && this.connectedAudioTagWrapper){
                this.callBacksRunning = true;
                this.ngZone.runOutsideAngular(() => {
                  this.cancelValue = requestAnimationFrame(this.draw);
                });

            }
        }
    }


    private cancelValue = -1;
    private draw = (timestamp: number) => {

      if (this.destroyed) {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'draw','this component is already destroyed! Not doing anyting for time: ' + timestamp);
      } else {

        let allZero = true;

        this.leftAnalyser.getByteFrequencyData(this.leftdataArray);
        this.rightAnalyser.getByteFrequencyData(this.rightdataArray);

        this.canvasCtx.clearRect(0, 0, this.visualiserCanvas.nativeElement.width, this.visualiserCanvas.nativeElement.height);

        let barWidth = Math.floor(this.visualiserCanvas.nativeElement.width / this.totalAmountOfBars);
        let barHeight: number;
        let x = Math.floor((this.visualiserCanvas.nativeElement.width - (barWidth * this.totalAmountOfBars)) / 2);

        for (let i = 0; i < this.totalAmountOfBars; i++) {

          if (i < this.amountOfBarsPerChannel) {
            barHeight = this.leftdataArray[this.amountOfBarsPerChannel - (i + 1)];
          } else {
            barHeight = this.rightdataArray[i - this.amountOfBarsPerChannel];
          }

          //normalize to height
          barHeight = barHeight * this.visualiserCanvas.nativeElement.height / 300;


          if (barHeight > 0) {
            allZero = false;
          }

          this.canvasCtx.fillRect(x, this.visualiserCanvas.nativeElement.height - barHeight, barWidth - 1, barHeight);

          x += barWidth;
        }

        if (this.playing || !allZero) {
          this.ngZone.runOutsideAngular(() => {
            this.cancelValue = requestAnimationFrame(this.draw);
          });
        } else {
          this.callBacksRunning = false;
        }
      }
    }

}
