import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@service/authentication.service';
import { Config } from '@service/config';
import { LoggerService } from '@service/loggers/logger.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class DTO_ZoneCode {
  code: string;
  validForSeconds: number;
}

@Injectable({
  providedIn: 'root'
})
export class ZoneCodeApiService {

  private LOGGER_CLASSNAME = ZoneCodeApiService.name;

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService
  ) {

  }

  public loadZoneCode(): Observable<DTO_ZoneCode>{
    let zoneCodeObservable: Observable<DTO_ZoneCode>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadZoneConfiguration', 'not supported in mock backend');
      return null;
    }else{
      const url = Config.api4Url_zoneCode(this.authenticationService.zoneId);
      const params = new HttpParams()
                      .set('type', "link");

      zoneCodeObservable = this.httpClient.post<DTO_ZoneCode>(url, {params: params});
    }
    return zoneCodeObservable;
  }
}
