import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ZoneConfigurationService } from '@service/zone-configuration.service';

@Component({
  selector: 'tun-sabam-popup',
  templateUrl: './sabam-popup.component.html',
  styleUrls: ['./sabam-popup.component.scss']
})
export class SabamPopupComponent implements OnInit {

  public static widthForPopup = '30vw';

  get sabamId$(){
    return this.zoneConfigurationService.sabamId$;
  }

  constructor(public dialogRef: MatDialogRef<SabamPopupComponent>,
              public zoneConfigurationService:ZoneConfigurationService) {}

  ngOnInit() {
  }

  public onCancelClick(){
    this.dialogRef.close();
  }

}
