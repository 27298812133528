<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="1792px"
  height="1792px"
  viewBox="0 0 1792 1792"
  enable-background="new 0 0 1792 1792"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"
  />
</svg>
