import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit
} from '@angular/core';
import { PlayState } from '@model/enums/playState.enum';
import { AppService } from '@service/app.service';
import { MusicPlayerService } from '@service/music-player.service';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { Observable, merge } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';

interface PanelHeaderState {
  musicPlayAnimationColors: MusicPlayAnimationColors;
  heightPerItem: number;
}

const initialState: PanelHeaderState = {
  musicPlayAnimationColors: new MusicPlayAnimationColors(),
  heightPerItem: 0
};

/**
 * Component to show a header with close icon and optional buttons.
 * You should pass the optional buttons as content of this component tag. Wrap
 * the buttons in a container with either a class left-header-buttons to position
 * these buttons on the left side of the title, either the class right-header-buttons
 * to position them on the right side or either the class middle-header-buttons to
 * appear on the right side of the title.
 */
@Component({
  selector: 'tun-panel-header',
  templateUrl: './panel-header.component.html',
  styleUrls: ['./panel-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelHeaderComponent implements OnInit {
  // === Props === //
  @Input() title: string;
  @Input() isCurrentMusicCollection: boolean;
  @Input() showPlayAnimation = true;
  @Input() showClose = true;


  get heigthForTitle$(){
    return this._appService.heightPerItem$.pipe(map(value=> value -1));
  }

  // === Emitters === //
  @Output() close = new EventEmitter();

  // === State === //
  state$: Observable<PanelHeaderState>;

  constructor(
    private _musicPlayerService: MusicPlayerService,
    private _appService: AppService,
    private _activeMusicSelectionService: ActiveMusicSelectionService
  ) {}

  ngOnInit() {
    const colors$ = this._activeMusicSelectionService.selectedPlayerView$.pipe(
      map(spv => (spv === TunifyColor.BLUE ? '#277C94' : '#2D7709')),
      map(color => ({
        musicPlayAnimationColors: new MusicPlayAnimationColors()
          .selectedColor(color)
          .unselectedColor(color)
      }))
    );

    this.state$ = merge(
      colors$,
      this._appService.heightPerItem$.pipe(map(heightPerItem => ({ heightPerItem })))
    ).pipe(scan((state, command) => ({ ...state, ...command }), initialState));
  }

  // === Getters === //
  get isPlaying(): boolean {
    return (
      this.isCurrentMusicCollection &&
      this._musicPlayerService.playState === PlayState.PLAYING
    );
  }

  // === Event handlers === //
  onCloseClick() {
    this.close.emit();
  }
}
