import { Component, Input } from '@angular/core';

@Component({
  selector: 'tun-refresh-icon',
  templateUrl: './refresh-icon.component.html',
  styleUrls: ['./refresh-icon.component.scss']
})
export class RefreshIconComponent {
  @Input() color: string;

  constructor() {}
}
