import { CalendarItem } from '@model/calendarItem';

export class CalendarItemDropEvent {
  private _calendarItem: CalendarItem;
  setCalendarItem(calendarItem: CalendarItem) {
    this._calendarItem = calendarItem;
    return this;
  }
  get calendarItem(): CalendarItem {
    return this._calendarItem;
  }

  private _hour: number;
  setHour(hour: number) {
    this._hour = hour;
    return this;
  }
  get hour(): number {
    return this._hour;
  }

  private _minutes: number;
  setMinutes(minutes: number) {
    this._minutes = minutes;
    return this;
  }
  get minutes(): number {
    return this._minutes;
  }

  private _position: number;
  setPosition(position: number) {
    this._position = position;
    return this;
  }
  get position(): number {
    return this._position;
  }
}
