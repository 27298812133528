<div>
  <!-- play now button-->
  <button
    tunTooltipOnHover
    [tooltipText]="'tooltip.track.playNow'"
    [tooltipDarkThemed]="true"
    [checkAccessToStartTrack]="true"
    [popupDirection]="PopupDirection.UP"
    [popupPosition]="PopupPosition.TOP_CENTER"
    [darkThemed]="true"
    class="popup-button"
    (clickWithAccess)="onButtonClick(0)"
    [style.margin-left]="0"
  >
    <tun-play-icon></tun-play-icon>
  </button>

  <!-- find similar songs button-->
  <button
    tunTooltipOnHover
    [tooltipText]="'tooltip.track.searchSimilar'"
    [tooltipDarkThemed]="true"
    [checkAccessToSearch]="true"
    [popupDirection]="PopupDirection.UP"
    [popupPosition]="PopupPosition.TOP_CENTER"
    [darkThemed]="true"
    class="popup-button"
    (clickWithAccess)="onButtonClick(2)"
  >
    <tun-similar-icon></tun-similar-icon>
  </button>

  <!-- add to playlist button-->
  <button
    tunTooltipOnHover
    [tooltipText]="'tooltip.track.addToCustomList'"
    [tooltipDarkThemed]="true"
    [checkAccessToCustomPlaylists]="true"
    [popupDirection]="PopupDirection.UP"
    [popupPosition]="PopupPosition.TOP_CENTER"
    [darkThemed]="true"
    class="popup-button"
    (clickWithAccess)="onButtonClick(3)"
  >
    <tun-add-to-list-icon></tun-add-to-list-icon>
  </button>

  <!-- remove button -->
  <button
    tunTooltipOnHover
    [tooltipText]="'tooltip.track.delete'"
    [tooltipDarkThemed]="true"
    *ngIf="showDelete"
    class="popup-button"
    (click)="onButtonClick(4)"
    [style.margin-right]="0"
  >
    <tun-delete-icon></tun-delete-icon>
  </button>
</div>
