import { Directive, HostListener, EventEmitter, Output } from '@angular/core';
import { SongSortMode } from '@model/enums/songSortMode';

@Directive({
  selector: '[tunSongSortOnClick]'
})
export class SongSortOnClickDirective {

  // === State === //
  private songSortMode: SongSortMode = SongSortMode.NONE;

  // === Emitters === //
  @Output()
  songSortModeChange = new EventEmitter<SongSortMode>();

  @HostListener('click')
  onClick() {
    this.pickNextSongSortMode();
    this.songSortModeChange.emit(this.songSortMode);
  }

  private pickNextSongSortMode() {
    switch (this.songSortMode) {
      case SongSortMode.NONE:
        this.songSortMode = SongSortMode.TITLE;
        break;
      case SongSortMode.TITLE:
        this.songSortMode = SongSortMode.GROUP;
        break;
      case SongSortMode.GROUP:
        this.songSortMode = SongSortMode.OTHER;
        break;
      case SongSortMode.OTHER:
        this.songSortMode = SongSortMode.NONE;
        break;
      default:
        this.songSortMode = SongSortMode.NONE;
        break;
    }
  }
}
