import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'tun-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent {
  // === Props === //
  private _height = 40;

  //size of the icon compared to the total height.
  //width and height will scale according to this factor.
  //This is usefull when an icon is smaller / larger drawn in his area or if it is light / heavy
  private _iconSizeFactor = 0.5;

  get height(): number {
    return this._height;
  }

  @Input()
  set height(value: number) {
    this._height = value;
    this.calcPadding();
  }

  get iconSizeFactor(): number {
    return this._iconSizeFactor;
  }

  @Input()
  set iconSizeFactor(value: number) {
    this._iconSizeFactor = value;
    this.calcPadding();
  }

  private calcPadding() {
    //calculate height based on given pixels
    let iconHeightTemp = Math.round(this.height * this.iconSizeFactor);
    let restHeight = this.height - iconHeightTemp;
    let paddingTopAndBottom = Math.floor(restHeight / 2);

    //rounding could leave us with 1 pixel short -> add it to the icon
    if (iconHeightTemp + 2 * paddingTopAndBottom < this.height) {
      iconHeightTemp += 1;
    }

    //calculate width based on rem -> button should always be 2.7rem
    let paddingLeftAndRight = Math.round((1 - this.iconSizeFactor) * 150) / 100;
    let iconWidthTemp = 2.7 - 2 * paddingLeftAndRight;

    this.iconHeight = iconHeightTemp;
    this.iconWidth = iconWidthTemp;
    this.iconPadding = this.sanitizer.bypassSecurityTrustStyle(
      `${paddingTopAndBottom}px ${paddingLeftAndRight}rem`
    );
  }

  private _class = 'icon-container';

  get class(): string {
    return this._class;
  }

  @Input()
  set class(value: string) {
    this._class = `icon-container ${value}`.trim();
  }

  // needed for hover event
  @Input() iconName = '';

  // === State === //
  iconPadding: SafeStyle;
  iconHeight: number;
  iconWidth: number;

  constructor(private sanitizer: DomSanitizer, public elementRef: ElementRef) {}
}
