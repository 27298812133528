import { Component, ElementRef } from '@angular/core';

export const PREFIX = '--';

@Component({
  selector: 'sass-helper',
  template: '<div class="colors"></div>',
  styleUrls: ['./sass-helper.component.scss'],
})
export class SassHelperComponent {

  constructor(private elem: ElementRef) {
  }

  // Read the custom property of body section with given name.
  readProperty(name: string): string {
    let bodyStyles = window.getComputedStyle(this.elem.nativeElement.querySelector('.colors'));
    return bodyStyles.getPropertyValue(PREFIX + name);
  }
}
