import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { Util } from '@util/util';

@Component({
  selector: 'tun-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss'],
})
export class SearchButtonComponent implements OnInit {
  // === Props === //
  @Input() text: string;

  // === Emitters === //
  @Output() searchclick = new EventEmitter<Event>();

  constructor() {}

  ngOnInit() {}

  // === Events === //
  onClick(event: Event) {
    this.searchclick.emit(event);
  }

}
