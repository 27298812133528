export class SliderColors {
  _selectedColor: string;
  _unselectedColor: string;
  _unselectedTextColor: string;

  selectedColor = (color: string): SliderColors => {
    this._selectedColor = color;
    return this;
  };

  unselectedColor = (color: string): SliderColors => {
    this._unselectedColor = color;
    return this;
  };

  unselectedTextColor = (color: string): SliderColors => {
    this._unselectedTextColor = color;
    return this;
  };
}
