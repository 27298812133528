import {
  Component,
  Input,
  OnDestroy,
  AfterViewInit,
  NgZone,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnInit
} from '@angular/core';
import { ResizedEvent } from '@components/resize/resize.directive';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { LoggerService } from '@service/loggers/logger.service';

@Component({
  selector: 'tun-music-play-animation',
  templateUrl: './music-play-animation.component.html',
  styleUrls: ['./music-play-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MusicPlayAnimationComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  private LOGGER_CLASSNAME = 'MusicPlayAnimationComponent';

  public static amount = 0;

  @ViewChild('simplePlayAnimationCanvas', {static: true}) simplePlayAnimationCanvas:any;

  @Input() public playing: boolean;
  @Input() public selected: boolean;
  @Input() public musicPlayAnimationColors: MusicPlayAnimationColors;

  constructor(
    private ngZone: NgZone,
    private loggerService: LoggerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.changeDetectorRef.detach();

    MusicPlayAnimationComponent.amount++;
    //this.loggerService.debug(this.LOGGER_CLASSNAME, "constructor", "component created: " + MusicPlayAnimationComponent.amount);

  }

  ngOnChanges(simpleChanges: SimpleChanges){
    if (simpleChanges.selected || simpleChanges.musicPlayAnimationColors){
      this.adjustFillStyle();
    }
    if (simpleChanges.playing){
      this.previousCycleTimestamp = 0; //this will trigger new values (faster respond to playing / not playing)
      this.startRunningIfNeeded();
    }
  }

  ngOnInit(){
    this.initDrawingVars();
  }

  public onResize(event: ResizedEvent){
    this.adjustDrawingCanvasSize();
  }

  ngAfterViewInit() {
    this.canvasCtx = this.simplePlayAnimationCanvas.nativeElement.getContext("2d");
    this.adjustDrawingCanvasSize();

    this.initDone = true;

    this.startRunningIfNeeded();
  }


  private destroyed = false;
  ngOnDestroy() {
    //this.loggerService.debug(this.LOGGER_CLASSNAME, "onDestroy", "component destroyed: " + MusicPlayAnimationComponent.amount);
    if (this.animationFrameRequestValue !== -1){
      cancelAnimationFrame(this.animationFrameRequestValue);
      this.animationFrameRequestValue = -1;
      //this.loggerService.debug(this.LOGGER_CLASSNAME, "onDestroy", "cleaned up animationFrame");
    }

    this.destroyed = true;
  }



  private redrawAfterResize = false;
  private adjustDrawingCanvasSize(){
    this.canvasCtx.canvas.width = this.simplePlayAnimationCanvas.nativeElement.clientWidth;
    this.canvasCtx.canvas.height = this.simplePlayAnimationCanvas.nativeElement.clientHeight;


    this.barWidth = Math.floor(this.simplePlayAnimationCanvas.nativeElement.width  / this.totalAmountOfBars);
    this.redrawAfterResize = true;
    //we should set the fill style again after changing the size of the canvasCtx
    this.adjustFillStyle();

    this.startRunningIfNeeded();
  }

  private callBacksRunning: boolean = false;
  private startRunningIfNeeded() {
      if (!this.callBacksRunning) {
          if ((this.playing || this.redrawAfterResize) && this.initDone){
              this.callBacksRunning = true;
              this.previousTimestamp = 0;
              this.previousCycleTimestamp = 0;

              this.ngZone.runOutsideAngular(this.runRequestAnimationFrame);
          }
      }
  }

  private adjustFillStyle(){
    if (this.musicPlayAnimationColors && this.canvasCtx) {
      if (this.selected){
        this.canvasCtx.fillStyle = this.musicPlayAnimationColors._selectedColor;
      }else{
        this.canvasCtx.fillStyle = this.musicPlayAnimationColors._unselectedColor;
      }
    }
  }



  private canvasCtx: CanvasRenderingContext2D;
  private initDone = false;
  private animationFrameRequestValue = -1;
  private totalAmountOfBars = 3;
  private barWidth;

  private millisecondsPerCycle = 400;
  private barValue: number[]; //number between 0 and 1 -> percentage of used height.
  private adjustmentPerMillisecond: number[];
  private previousTimestamp: number = 0;
  private previousCycleTimestamp: number = 0;

  private initDrawingVars(){
    this.barValue = new Array(this.totalAmountOfBars).fill(0);
    this.adjustmentPerMillisecond = new Array(this.totalAmountOfBars).fill(0);
  }

  private runRequestAnimationFrame = () => {
    this.animationFrameRequestValue = requestAnimationFrame(this.draw);
  }

  private draw = (timestamp: number) => {
    if (this.destroyed) {
      this.loggerService.error(this.LOGGER_CLASSNAME, 'draw','this component (' + MusicPlayAnimationComponent.amount + ') is already destroyed! Not doing anyting for time: ' + timestamp);
    } else {
      //this.loggerService.debug(this.LOGGER_CLASSNAME, 'draw','this component (' + MusicPlayAnimationComponent.amount + ') is drawing timestamp ' + timestamp);
      this.redrawAfterResize = false;
      let allZero = false;
      if (this.previousCycleTimestamp == 0 || timestamp - this.previousCycleTimestamp > this.millisecondsPerCycle){
        this.previousCycleTimestamp = timestamp;
        allZero = true;
        for (let i = 0; i < this.barValue.length; i++){

          let newValue = 0.2;
          if (this.playing){
            newValue = Math.random() * 0.8 + 0.2;
          }

          this.adjustmentPerMillisecond[i] = (newValue - this.barValue[i]) / this.millisecondsPerCycle;
          if (this.adjustmentPerMillisecond[i] !== 0){
            allZero = false;
          }
        }
      }

      let millisecondsInFrame = timestamp - this.previousTimestamp;
      if (this.previousTimestamp == 0){
        millisecondsInFrame = 16; //aim for 60 frames per second
      }
      for (let i = 0; i < this.barValue.length; i++){
        this.barValue[i] = this.barValue[i] + this.adjustmentPerMillisecond[i] * millisecondsInFrame;
        if (!this.playing){
          //check not playing boundaries (depends on increasing or decreasing)
          if (this.adjustmentPerMillisecond[i] > 0){
            if (this.barValue[i] >= 0.2){
              this.barValue[i] = 0.2;
              this.previousCycleTimestamp = 0; //trigger recalculation
            }
          }else if (this.adjustmentPerMillisecond[i] < 0){
            if (this.barValue[i] <= 0.2){
              this.barValue[i] = 0.2;
              this.previousCycleTimestamp = 0; //trigger recalculation
            }
          }else{
            //rounding could bring us here -> just go to prefered values
            this.barValue[i] = 0.2;
            this.previousCycleTimestamp = 0; //trigger recalculation
          }
        }else{
          //check playing boundaries (depends on increasing or decreasing)
          if (this.adjustmentPerMillisecond[i] > 0){
            if (this.barValue[i] >= 1){
              this.barValue[i] = 1;
              this.previousCycleTimestamp = 0; //trigger recalculation
            }
          }else if (this.adjustmentPerMillisecond[i] < 0){
            if (this.barValue[i] <= 0.2){
              this.barValue[i] = 0.2;
              this.previousCycleTimestamp = 0; //trigger recalculation
            }
          }else{
            //this is ok, random number was very close to current value
          }
        }
      }

      this.previousTimestamp = timestamp;

      this.canvasCtx.clearRect(0, 0, this.simplePlayAnimationCanvas.nativeElement.width, this.simplePlayAnimationCanvas.nativeElement.height);

      let barHeight: number;
      let x = 0;

      for (let i = 0; i < this.totalAmountOfBars; i++) {
        barHeight = this.barValue[i] * this.canvasCtx.canvas.height ;
        this.canvasCtx.fillRect(x, this.canvasCtx.canvas.height - barHeight, this.barWidth - 1, barHeight);
        x += this.barWidth;
      }

      if (this.playing || !allZero) {
        this.ngZone.runOutsideAngular(this.runRequestAnimationFrame);
      } else {
        this.callBacksRunning = false;
      }
    }
  }

}
