import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { SubscriptionsService } from '@service/subscriptions.service';
import { Subject, timer, Subscription, merge } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { OnScreenMessage } from './on-screen-message/on-screen-message.component';
import { carouselYAnimation } from '@util/animations';
import { TranslateService } from '@ngx-translate/core';
import { ExternalLinksService } from '@service/external-links.service';
import { ZoneInfoService } from '@service/zone-info.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { DTO_Region } from '@service/api/zone-configuration-api.service';
import { LoggerService } from '@service/loggers/logger.service';
import { AppVersionService, MAJOR_VERSION } from '@service/app-version.service';



@Component({
  selector: 'tun-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.scss'],
  animations: [carouselYAnimation],
})
export class MessageCenterComponent implements OnInit, OnDestroy {

  readonly LOGGER_CLASSNAME = "MessageCenterComponent";

  readonly MESSAGE_ANIMATION_DURATION = 700;
  //seconds that each message is displayed
  private readonly MESSAGE_DURATION_SECONDS = 10;

  public allMessages = [];
  public currentIndex = 0;


  constructor(
    private subscriptionsService: SubscriptionsService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private appVersionService: AppVersionService,
    private zoneConfigurationService: ZoneConfigurationService,
    private externalLinksService: ExternalLinksService,
    private loggerService: LoggerService
  ) { }


  /**
   * Subscription message
   */
  private _subscriptionMessage: OnScreenMessage;
  private set subscriptionMessage(value: OnScreenMessage) {
    this.removeMessage(this._subscriptionMessage);
    this._subscriptionMessage = value;
    this.addMessage(this._subscriptionMessage);
  }
  private get subscriptionMessage(): OnScreenMessage {
    return this._subscriptionMessage;
  }


  /**
   * Help message
   */
  private _helpMessage: OnScreenMessage;
  private set helpMessage(value: OnScreenMessage) {
    this.removeMessage(this._helpMessage);
    this._helpMessage = value;
    this.addMessage(this._helpMessage);
  }
  private get helpMessage(): OnScreenMessage {
    return this._helpMessage;
  }


  /**
  * Pervious version message
  */
  private _nextVersionMessage: OnScreenMessage;
  private set nextVersionMessage(value: OnScreenMessage) {
    if (value != this._nextVersionMessage){
      this.removeMessage(this._nextVersionMessage);
      this._nextVersionMessage = value;
      this.addMessage(this._nextVersionMessage);
    }

  }
  private get nextVersionMessage(): OnScreenMessage {
    return this._nextVersionMessage;
  }

  // helper functions to manipulate all messages
  private removeMessage(message: OnScreenMessage) {
    if (message != null) {
      const index = this.allMessages.indexOf(message, 0);
      if (index > -1) {
        this.allMessages.splice(index, 1);
        this.startOrStopTimer();
      }
    }
  }

  private addMessage(message: OnScreenMessage) {
    if (message != null) {
      this.allMessages.push(message);
      this.startOrStopTimer();
    }
  }

  private cycleTimer: Subscription;
  private startOrStopTimer() {
    if (this.allMessages.length > 1) {
      if (this.cycleTimer == null) {
        this.cycleTimer =
          timer(this.MESSAGE_DURATION_SECONDS * 500, this.MESSAGE_DURATION_SECONDS * 1000)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
              if (this.currentIndex < this.allMessages.length - 1) {
                this.currentIndex++;
              } else {
                this.currentIndex = 0;
              }
              this.changeDetectorRef.detectChanges();
            });
      }
    } else {
      if (this.cycleTimer != null) {
        this.cycleTimer.unsubscribe();
        this.cycleTimer = null;
      }
    }

    if (this.currentIndex >= this.allMessages.length) {
      this.currentIndex = 0;
    }

  }

  private helpMessageWillBeCreated$ = new Subject<void>();
  private nextVersionMessageWillBeCreated$ = new Subject<void>();
  private subscriptionMessageWillBeCreated$ = new Subject<void>();
  ngOnInit() {

    //this looks wrong -> always nl-nl
    //var countryCode = this.translateService.getBrowserCultureLang().slice(0, 2);
    //console.log(countryCode);


    this.zoneConfigurationService.region$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (value) => {

          const region = value;
          this.helpMessageWillBeCreated$.next();

          //only show the help message for be customers (vl & wa profile)
          if (this.isBelgiumRegion(region) || this.isNetherlandRegion(region)) {
            const helpMessageTemp = new OnScreenMessage();
            this.translateService.stream("messageCenter.needHelp")
              .pipe(
                takeUntil(
                  merge(
                    this.helpMessageWillBeCreated$,
                    this.destroyed$
                  )
                )
              )
              .subscribe(
                value => {
                  if (this.isBelgiumRegion(region)){
                    helpMessageTemp.message = value.replace("{0}", "0800 17 017");
                  }else if (this.isNetherlandRegion(region)){
                    helpMessageTemp.message = value.replace("{0}", "0800 023 3029");
                  }else{
                    this.loggerService.error(this.LOGGER_CLASSNAME, "ngInit", "composeHelpMessage -> region " + (region != null?region.code: "NO REGION OBJECT") + " not recognized. Using default phone number");
                    helpMessageTemp.message = value.replace("{0}", "0800 17 017");
                  }
                }
              )

            this.helpMessage = helpMessageTemp;
          } else {
            this.helpMessage = null;
          }
        }
      );


    /*
    this.appVersionService.allowedVersionsSubject$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (value) => {
          this.nextVersionMessageWillBeCreated$.next();
          if (this.appVersionService.showLinkForVersion(MAJOR_VERSION.V5)) {
            let nextVersionMessage = this.nextVersionMessage;
            if (nextVersionMessage == null){
              nextVersionMessage = new OnScreenMessage();
            }

            nextVersionMessage.message = "messageCenter.nextVersion.message";
            nextVersionMessage.link = "messageCenter.nextVersion.openLink";
            nextVersionMessage.linkAction = this.openNextVersion;
            this.nextVersionMessage = nextVersionMessage;

          } else {
            if (this.nextVersionMessage != null) {
              this.nextVersionMessage = null;
            }
          }
        });

        */


    this.subscriptionsService.subscriptionMessage$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (value) => {
          this.subscriptionMessageWillBeCreated$.next();
          if (value != null && value != "") {
            const newMessage = new OnScreenMessage();
            newMessage.message = value;

            this.translateService.stream("messageCenter.subscriptions.buyNewSubscription")
              .pipe(
                takeUntil(
                  merge(
                    this.subscriptionMessageWillBeCreated$,
                    this.destroyed$
                  )
                )
              )
              .subscribe(
                value => {
                  newMessage.link = value;
                }
              )
            newMessage.linkAction = this.openBuyPage;
            this.subscriptionMessage = newMessage;
          } else {
            if (this.subscriptionsService != null) {
              this.subscriptionMessage = null;
            }
          }
        });
  }

  private isBelgiumRegion(dtoRegion: DTO_Region){
    return dtoRegion != null && dtoRegion.code != null && (dtoRegion.code.toLowerCase() == "vl" || dtoRegion.code.toLowerCase() == "wa");
  }

  private isNetherlandRegion(dtoRegion: DTO_Region){
    return dtoRegion != null && dtoRegion.code != null && dtoRegion.code.toLowerCase() == "nl";
  }

  private openBuyPage = () => {
    this.externalLinksService.openZoneBuyPage();
  }

  private openNextVersion = () => {
    this.appVersionService.changeVersionToUse(MAJOR_VERSION.V5);
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;

    this.helpMessageWillBeCreated$.complete();
    this.helpMessageWillBeCreated$ = null;

    this.nextVersionMessageWillBeCreated$.complete();
    this.nextVersionMessageWillBeCreated$ = null;

    this.subscriptionMessageWillBeCreated$.complete();
    this.subscriptionMessageWillBeCreated$ = null;
  }


}
