<div
  #calendarItemRef
  tunPopupOnClick
  [closeOnPopupClick]="false"
  [clickDelay]="100"
  (togglepopup)="toggleTooltip($event)"
  class="calendar-item"
  [draggable]="!hoverOverBottomExtender && !hoverOverTopExtender && inEditMode && !performingActionOnItem"
  (dragstart)="onDragStart()"
  (dragend)="onDragEnd()"
  [style.border-color]="
    selected
      ? selectedMusicCategoryBlockColors._labelBorderColor
      : unselectedMusicCategoryBlockColors._labelBorderColor
  "
  [style.height.px]="(heightPerItem * calendarItem.duration) / 60 - 10"
  [style.top.px]="
    heightPerItem / 2 +
    heightPerItem * calendarItem.startHour +
    ((calendarItem.startMinutes / 30) * heightPerItem) / 2
  "
>
  <div
    #calendaritem
    class="label-container"
    (mouseover)="hoverOverLabel = true"
    (mouseout)="hoverOverLabel = false"
    [style.background-image]="
      selected
        ? selectedLabelBackgroundGradient
        : unselectedLabelBackgroundGradient
    "
    [style.border-color]="
      inEditMode
        ? selected
          ? selectedMusicCategoryBlockColors._labelBorderRightColor
          : unselectedMusicCategoryBlockColors._labelBorderRightColor
        : 'transparent'
    "
  >
    <div
      *ngIf="inEditMode && !performingActionOnItem"
      class="extender"
      [style.width.%]="80"
      [style.height.px]="
        calendarItem.duration < 60
          ? heightPerItem / 4 - 1
          : heightPerItem / 2 - 1
      "
      [style.top.px]="-1"
      [class.disable-hover]="resizingOtherCalendarItem"
      (mouseenter)="hoverOverTopExtender = true"
      (mouseleave)="hoverOverTopExtender = false"
      (mousedown)="onExtenderMouseDown($event, true)"
    >
      <fa-icon
        *ngIf="!resizingOtherCalendarItem"
        class="extender-icon"
        size="xs"
        [icon]="faChevronUp"
      ></fa-icon>
    </div>
    <div
      *ngIf="inEditMode && !performingActionOnItem"
      class="extender"
      [style.width.%]="80"
      [style.height.px]="
        calendarItem.duration < 60
          ? heightPerItem / 4 - 1
          : heightPerItem / 2 - 1
      "
      [style.bottom.px]="0"
      [class.disable-hover]="resizingOtherCalendarItem"
      (mouseenter)="hoverOverBottomExtender = true"
      (mouseleave)="hoverOverBottomExtender = false"
      (mousedown)="onExtenderMouseDown($event, false)"
    >
      <fa-icon
        *ngIf="!resizingOtherCalendarItem"
        class="extender-icon"
        size="xs"
        [icon]="faChevronDown"
      ></fa-icon>
    </div>
    <div
      class="label-cover"
      [style.opacity]="
        inEditMode &&
        !isMouseDown &&
        !hoverOverTopExtender &&
        !hoverOverBottomExtender &&
        !hoverOverDeleteIcon &&
        hoverOverLabel
          ? 0.1
          : 0
      "
    ></div>
    <div
      #calendaritemtitle
      class="label"
      [style.margin-left]="marginLeft"
      [style.-webkit-transition]="transition"
      [style.-moz-transition]="transition"
      [style.transition]="transition"
      [style.flex-grow]="inEditMode ? 1 : 0"
      [style.color]="
        selected
          ? selectedMusicCategoryBlockColors._textColor
          : unselectedMusicCategoryBlockColors._textColor
      "
      [style.font-weight]="selected ? 'bold' : 'initial'"
    >
      {{ calendarItem.title }}
    </div>
    <div class="animation" [style.opacity]="showAnimation ? 1 : 0">
      <tun-music-play-animation
        [playing]="isPlaying"
        [selected]="isSelected"
        [musicPlayAnimationColors]="musicPlayAnimationColors"
      ></tun-music-play-animation>
    </div>
    <div
      *ngIf="inEditMode && !performingActionOnItem"
      class="delete-icon-container"
      (mouseover)="hoverOverDeleteIcon = true"
      (mouseout)="hoverOverDeleteIcon = false"
      (click)=onDeleteCalendarItem()
      [style.max-height.px]="heightPerItem"
    >
      <div
        class="delete-icon-cover"
        [style.width.px]="heightPerItem / 2"
        [style.height.px]="
          calendarItem.duration >= 60
            ? calendarItem.duration >= 90
              ? heightPerItem
              : heightPerItem - 4
            : heightPerItem / 2 - 4
        "
        [style.opacity]="!isMouseDown && hoverOverDeleteIcon ? 0.2 : 0"
        [style.padding-left.px]="heightPerItem / 4"
        [style.padding-right.px]="heightPerItem / 4"
      ></div>
      <div
        class="delete-icon"
        [style.width.px]="heightPerItem / 2"
        [style.height.px]="heightPerItem / 2"
        [style.padding-left.px]="heightPerItem / 4"
        [style.padding-right.px]="heightPerItem / 4"
        [style.color]="
          hoverOverDeleteIcon && !isMouseDown
            ? selected
              ? '#000'
              : '#FFF'
            : selected
            ? selectedMusicCategoryBlockColors._textColor
            : unselectedMusicCategoryBlockColors._textColor
        "
      >
        <tun-delete-icon></tun-delete-icon>
      </div>
    </div>
    <div  *ngIf="performingActionOnItem"
        [style.max-height.px]="heightPerItem">
      <tun-loader text=""></tun-loader>
    </div>

  </div>


  <div
    *ngIf="inEditMode && !performingActionOnItem"
    class="tweak-icon-container"
    (mouseover)="hoverOverTweakIcon = true"
    (mouseout)="hoverOverTweakIcon = false"
    (click)="openCalendarItemTweakPanel()"
    [style.background-color]="
      selected
        ? hoverOverTweakIcon && !isMouseDown
          ? selectedMusicCategoryBlockColors._iconBackgroundHoverColor
          : selectedMusicCategoryBlockColors._iconBackgroundColor
        : hoverOverTweakIcon && !isMouseDown
        ? unselectedMusicCategoryBlockColors._iconBackgroundHoverColor
        : unselectedMusicCategoryBlockColors._iconBackgroundColor
    "
    [style.border-color]="
      selected
        ? selectedMusicCategoryBlockColors._iconBorderColor
        : unselectedMusicCategoryBlockColors._iconBorderColor
    "
  >
    <div
      class="tweak-icon"
      [style.width.px]="heightPerItem / 2"
      [style.height.px]="heightPerItem / 2"
      [style.padding-left.px]="heightPerItem / 4"
      [style.padding-right.px]="heightPerItem / 4"
    >
      <tun-settings-icon
        *ngIf="!calendarItem.changeableParametersLoaded || calendarItem.changeableParameter"
        [color]="
          hoverOverTweakIcon && !isMouseDown
            ? '#FFF'
            : selected
            ? selectedMusicCategoryBlockColors._iconColor
            : unselectedMusicCategoryBlockColors._iconColor
        "
      ></tun-settings-icon>
      <tun-list-icon
        *ngIf="calendarItem.changeableParametersLoaded && !calendarItem.changeableParameter"
        [color]="
          hoverOverTweakIcon && !isMouseDown
            ? '#FFF'
            : selected
            ? selectedMusicCategoryBlockColors._iconColor
            : unselectedMusicCategoryBlockColors._iconColor
        "
      >
      </tun-list-icon>
    </div>
  </div>
</div>
