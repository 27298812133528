import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SliderColors } from '@model/fieldModels/sliderColors';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { SassHelperComponent } from 'src/providers/sass-helper/sass-helper.component';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';

/**
 * Possible bugs:
 * 
 * When an instance is moved/resized -> this.unselectedLineClientX should be reset to 0
 * 
 * This is partially done by registering a window.resize() listener and resetting things to 0.
 * This is not triggered when a slider component is moved / resized in our app.
 * 
 */

@Component({
  selector: 'tun-base-slider',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseSliderComponent implements AfterViewInit {
  // Unselected line defined in the HTML.
  @ViewChild('unselectedline') unselectedLine: ElementRef;

  // Selected line defined in the HTML.
  @ViewChild('selectedline') selectedLine: ElementRef;

  @ViewChild(SassHelperComponent, { static: true })
  private sassHelper: SassHelperComponent;

  // Input for the slider colors color.
  private _sliderColors: SliderColors;
  @Input() set sliderColors(value: SliderColors) {
    this._sliderColors = value;
    this.changeSliderColors();
  }
  get sliderColors(): SliderColors {
    return this._sliderColors;
  }
  // TextSlider: Only label that is displayed in a single label slider.
  // FlexibleSlider: Label that should be displayed next to the values on the slider.
  @Input() label = '';

  // Emits the selectedMinValue after an event;
  @Output() selectedMinValueEmitter = new EventEmitter();

  // Emits the selectedMaxValue after an event;
  @Output() selectedMaxValueEmitter = new EventEmitter();

  // Left attribute of the box surrounding the left slider.
  leftSliderBoxLeft: SafeStyle = '-0.8rem';

  // X-coordinate of the left slider.
  leftSliderClientX = 0;

  // Checks whether the left slider is clicked or not.
  leftSliderIsClicked = false;

  // Left attribute of the left slider, used for positioning.
  leftSliderLeft: SafeStyle = '0%';

  // Left attribute of the left slider in percent.
  leftSliderLeftPercent = 0;

  // Right attribute of the box surrounding the right slider.
  rightSliderBoxRight: SafeStyle = '-0.8rem';

  // X-coordinate of the right slider.
  rightSliderClientX = 0;

  // Checks whether the right slider is clicked or not.
  rightSliderIsClicked = false;

  // Right attribute of the right slider, used for positioning.
  rightSliderRight: SafeStyle = '0%';

  // Right attribute of the right slider in percent.
  rightSliderRightPercent = 0;

  // X-coordinate of the unselected line.
  unselectedLineClientX = 0;

  // Speed at whicht the transition happens when clicking the bar.
  transitionSpeed: SafeStyle = '0s';

  // Style of the unselected line.
  unselectedLineStyle: SafeStyle;

  // Style of the selected line.
  selectedLineStyle: SafeStyle;

  // Style of the sliders.
  sliderStyle: SafeStyle;

  constructor(
    protected sanitizer: DomSanitizer,
    protected cdRef: ChangeDetectorRef,
    private _activeMusicSelectionService: ActiveMusicSelectionService
  ) {}

  // Called when the window is resized to fix the positions of the sliders.
  onResize = () => {
    this.unselectedLineClientX = 0;
    this.cdRef.detectChanges();
  }

  // A snap happens when a slider is released.
  onMouseUp = () => {
    this.releaseSliderClicks();
    this.cdRef.detectChanges();
  }

  // A snap happens when a slider is released.
  onTouchEnd = () => {
    this.releaseSliderClicks();
    this.cdRef.detectChanges();
  }

  // If a slider was clicked, a snap will be performed.
  onMouseLeave = () => {
    this.releaseSliderClicks();
    this.cdRef.detectChanges();
  }

  // If a slider was clicked, a snap will be performed.
  onTouchCancel = () => {
    this.releaseSliderClicks();
    this.cdRef.detectChanges();
  }

  // A move happens when the user moves the cursor.
  onMouseMove = (e: MouseEvent) => {
    if (this.leftSliderIsClicked) {
      this.leftSliderMouseMove(e.clientX);
    } else if (this.rightSliderIsClicked) {
      this.rightSliderMouseMove(e.clientX);
    }
    this.cdRef.detectChanges();
  }

  // A move happens when the user moves the cursor.
  onTouchMove = (e: TouchEvent | any) => {
    if (this.leftSliderIsClicked) {
      this.leftSliderMouseMove(e.touches[0].clientX);
    } else if (this.rightSliderIsClicked) {
      this.rightSliderMouseMove(e.touches[0].clientX);
    }
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.sliderColors = this._getSliderColorsForTunifyColor(
      this._activeMusicSelectionService.selectedPlayerView
    );
    this.changeSliderColors();
    this.cdRef.detectChanges();
  }

  // Called when the user clicks the line to move a slider.
  public lineClicked(e: MouseEvent){
    this.calculateUnselectedLineClientX();
    this.transitionSpeed = '0.2s';
  }

  // Calculates the X-coordinate of the unselected line.
  calculateUnselectedLineClientX() {
    if (this.unselectedLineClientX === 0) {
      const { x } = this.unselectedLine.nativeElement.getBoundingClientRect();
      this.unselectedLineClientX = x;
    }
  }

  // Resets the transition speed.
  resetTransitionSpeed() {
    setTimeout(() => {
      this.transitionSpeed = '0s';
    }, 200);
  }

  // Emit the selectedMinValue and selectedMaxValue of the slider.
  emitOutput(minValue: any, maxValue: any) {
    this.selectedMinValueEmitter.emit(minValue);
    this.selectedMaxValueEmitter.emit(maxValue);
  }

  // If a slider is clicked, it is released.
  releaseSliderClicks() {}

  // Registration that the left slider is currently clicked by the user.
  leftSliderMouseDown = () => {
    this.leftSliderIsClicked = true;
    this.cdRef.detectChanges();
  }

  // Registration that the user is moving the left slider.
  leftSliderMouseMove(eventClientX: number, leftSliderValueWidth: number = 0) {
    // Current width of the unselected line.
    const width = this.unselectedLine.nativeElement.offsetWidth;

    // Updates the leftSliderClientX when the user clicks on the right slider after a snap.
    if (this.leftSliderClientX === 0) {
      this.leftSliderClientX = eventClientX;
    }

    // Updates the unselectedLineClientX after the screen is resized.
    this.calculateUnselectedLineClientX();

    // If the left slider or the cursor of the user deviates too much to the left.
    if (
      eventClientX <= this.unselectedLineClientX ||
      this.leftSliderLeftPercent +
        ((eventClientX - this.leftSliderClientX) / width) * 100 <=
        0
    ) {
      this.leftSliderLeftPercent = 0;
      this.leftSliderLeft = `${this.leftSliderLeftPercent}%`;
    } else if (
      eventClientX - this.unselectedLineClientX - leftSliderValueWidth >
        width * ((100 - this.rightSliderRightPercent) / 100) ||
      this.leftSliderLeftPercent +
        this.rightSliderRightPercent +
        ((eventClientX - this.leftSliderClientX) / width) * 100 >=
        100
    ) {
      this.leftSliderLeftPercent = 100 - this.rightSliderRightPercent;
      if (this.rightSliderRightPercent === 0) {
        this.leftSliderLeft = `${this.leftSliderLeftPercent}%`;
      } else {
        this.leftSliderLeft = this.sanitizer.bypassSecurityTrustStyle(
          `calc(${this.leftSliderLeftPercent}% + 1px)`
        );
      }
    } else {
      this.leftSliderLeftPercent +=
        ((eventClientX - this.leftSliderClientX) / width) * 100;
      this.leftSliderLeft = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.leftSliderLeftPercent}% + 1px)`
      );
    }

    // Update the leftSliderClientX to the clientX of the mouse event.
    this.leftSliderClientX = eventClientX;
  }

  // Registration that the right slider is currently clicked by the user.
  rightSliderMouseDown = () => {
    this.rightSliderIsClicked = true;
    this.cdRef.detectChanges();
  }

  // Registration that the user is moving the right slider.
  rightSliderMouseMove(
    eventClientX: number,
    rightSliderValueWidth: number = 0
  ) {
    // Current width of the unselected line.
    const width = this.unselectedLine.nativeElement.offsetWidth;

    // Updates the rightSliderClientX when the user clicks on the right slider after a snap.
    if (this.rightSliderClientX === 0) {
      this.rightSliderClientX = eventClientX;
    }

    // Updates the unselectedLineClientX after the screen is resized.
    this.calculateUnselectedLineClientX();

    // If the right slider or cursor of the user deviates too much to the right.
    if (
      eventClientX >= this.unselectedLineClientX + width ||
      this.rightSliderRightPercent +
        ((this.rightSliderClientX - eventClientX) / width) * 100 <=
        0
    ) {
      this.rightSliderRightPercent = 0;
      this.rightSliderRight = `${this.rightSliderRightPercent}%`;
    } else if (
      eventClientX - this.unselectedLineClientX + rightSliderValueWidth <
        width * (this.leftSliderLeftPercent / 100) ||
      this.leftSliderLeftPercent +
        this.rightSliderRightPercent +
        ((this.rightSliderClientX - eventClientX) / width) * 100 >=
        100
    ) {
      this.rightSliderRightPercent = 100 - this.leftSliderLeftPercent;
      if (this.leftSliderLeftPercent === 0) {
        this.rightSliderRight = `${this.rightSliderRightPercent}%`;
      } else {
        this.rightSliderRight = this.sanitizer.bypassSecurityTrustStyle(
          `calc(${this.rightSliderRightPercent}% - 1px)`
        );
      }
    } else {
      this.rightSliderRightPercent +=
        ((this.rightSliderClientX - eventClientX) / width) * 100;
      this.rightSliderRight = this.sanitizer.bypassSecurityTrustStyle(
        `calc(${this.rightSliderRightPercent}% - 1px)`
      );
    }

    // Update the rightSliderClientX to the clientX of the mouse event.
    this.rightSliderClientX = eventClientX;
  }

  // Change the colors of the slider.
  changeSliderColors() {
    this.unselectedLineStyle = this.sanitizer.bypassSecurityTrustStyle(
      `9px solid ${this.sliderColors._unselectedColor}`
    );
    this.selectedLineStyle = this.sanitizer.bypassSecurityTrustStyle(
      `9px solid ${this.sliderColors._selectedColor}`
    );

    this.sliderStyle = this.sanitizer.bypassSecurityTrustStyle(
      `0.8rem solid ${this.sliderColors._selectedColor}`
    );
  }

  // Checks whether the two sliders collided.
  detectCollision(): boolean {
    return (
      Math.round(this.leftSliderLeftPercent + this.rightSliderRightPercent) >=
      100
    );
  }

  private _getSliderColorsForTunifyColor(tunifyColor: TunifyColor) {
    const sliderColors = new SliderColors()
      .unselectedColor(this.sassHelper.readProperty('grey-8'))
      .unselectedTextColor(this.sassHelper.readProperty('white-1'));
    switch (tunifyColor) {
      case TunifyColor.BLUE:
        return sliderColors.selectedColor(
          this.sassHelper.readProperty('blue-5')
        );
      case TunifyColor.GREEN:
        return sliderColors.selectedColor(
          this.sassHelper.readProperty('green-1')
        );
      case TunifyColor.ORANGE:
        return sliderColors.selectedColor(
          this.sassHelper.readProperty('orange-1')
        );
      default:
        return sliderColors; // this shouldn't happen
    }
  }
}
