import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AppService } from '@service/app.service';
import { Subject, Subscription } from 'rxjs';
import { MusicChannel } from 'src/app/model/musicChannel';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { Calendar } from '@model/calendar';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-mid-stream-panel',
  templateUrl: './mid-stream-panel.component.html',
  styleUrls: ['./mid-stream-panel.component.scss']
})
export class MidStreamPanelComponent implements OnInit, OnDestroy {

  public get heightForHeader$(){
    return this.appService.heightForHeader$;
  }
  public get heightPerItem$(){
    return this.appService.heightPerItem$;
  }

  public titleForHeader = "";

  public selectedPlayerView = null;
  public TunifyColor = TunifyColor;

  private selectedMusicChannel:MusicChannel = null;
  private selectedCalendar:Calendar = null;

  constructor(private appService: AppService,
              private activeMusicSelectionService:ActiveMusicSelectionService,
              private cdRef:ChangeDetectorRef) { }


  ngOnInit() {


    this.activeMusicSelectionService.selectedPlayerView$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (playerView) => {
        this.selectedPlayerView = playerView;
        this.adjustTitle();
      }
    );

    this.activeMusicSelectionService.selectedMusicChannel$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (musicChannel) => {
        this.selectedMusicChannel = musicChannel;
        this.adjustTitle();
      }
    );

    this.activeMusicSelectionService.selectedCalendar$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (calendar) => {
        this.selectedCalendar = calendar;
        this.adjustTitle();
      }
    );
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }


  private adjustTitle(){
    if (this.selectedPlayerView == TunifyColor.BLUE && this.selectedMusicChannel){
      this.titleForHeader = this.selectedMusicChannel.name;
    }else if (this.selectedPlayerView == TunifyColor.GREEN && this.selectedCalendar){
      this.titleForHeader = this.selectedCalendar.name;
    }else{
      this.titleForHeader = "";
    }
  }

}
