<div
  #songgridrow
  class="audio-files-table-row {{ isUserDragging ? 'drag' : '' }} {{
    draggingThisRow ? 'draggingThis' : ''
  }}"
  [draggable]="tracksAreDraggable"
  (dragstart)="onDragStart($event, song)"
  (dragend)="onDragEnd()"
  [style.border-bottom-color]="
    isBeingDraggedOver ? dragLineBorderColor : borderColor
  "
  [style.height.px]="
    draggable && tableRowHoverId === 0 && index === 0
      ? heightPerItem - 2
      : draggingThisRow &&
        !(draggable && isBeingDraggedOver && tableRowHoverId !== 0)
      ? heightPerItem
      : heightPerItem - 1
  "
>
  <!-- Add to playlist icon + song title. -->
  <div
    class="song-field-container"
    [style.border-color]="borderColor"
    [style.width]="firstColWidth"
    [style.height.px]="
      draggable && tableRowHoverId === 0 && index === 0
        ? heightPerItem - 2
        : heightPerItem - 1
    "
  >
    <!-- <div
      class="icon-container queue-icon"
      [style.background-color]="queueIconBackgroundColor"
      [style.color]="queueIconColor"
      (click)="onFrontIconClick()"
    >
      <tun-delete-icon
        *ngIf="showDelete; else queueOrPlayIcon"
        [color]="queueIconColor"
      ></tun-delete-icon>
    </div>
      >
      <tun-delete-icon *ngIf="showDelete; else queueOrPlayIcon" [color]="queueIconColor"></tun-delete-icon>
    </div> -->
    <tun-icon-button
      #frontIcon
      tunTooltipOnHover
      [tooltipText]="showFrontDelete?'tooltip.track.delete':isQueue?'tooltip.track.playNow':'tooltip.track.addToQueue'"
      [checkAccessToStartTrack]="!showFrontDelete && isQueue?true:false"
      [checkAccessToAddToQueue]="!showFrontDelete && !isQueue?true:false"
      [popupDirection]="PopupDirection.UP"
      [popupPosition]="PopupPosition.TOP_CENTER"
      [height]="heightPerItem"
      class="list-icon"
      (clickWithAccess)="onFrontIconClick()"
    >
      <tun-delete-icon
        [color]="queueIconColor"
        *ngIf="showFrontDelete; else queueOrPlayIcon"
      >
      </tun-delete-icon>
    </tun-icon-button>
    <tun-song-field
      class="song-field"
      [text]="song.title"
      [isClickable]="searchEnabled"
      [isDoubleClickable]="playOnDoubleClick"
      [isSorted]="songSortMode === 'title'"
      (fieldClick)="onTitleClick()"
      (fieldDoubleClick)="onFieldDoubleClick()"
    ></tun-song-field>
  </div>

  <!-- Song group. -->
  <div
    class="song-field-container"
    [style.width]="secondColWidth"
    [style.border-color]="borderColor"
    [style.height.px]="
      draggable && tableRowHoverId === 0 && index === 0
        ? heightPerItem - 2
        : heightPerItem - 1
    "
  >
    <tun-song-field
      class="song-field"
      [text]="song.group"
      [isClickable]="searchEnabled"
      [isDoubleClickable]="playOnDoubleClick"
      [isSorted]="songSortMode === 'group'"
      (fieldClick)="onGroupClick()"
      (fieldDoubleClick)="onFieldDoubleClick()"
    ></tun-song-field>
  </div>

  <!-- Song mood + more vertical icon. -->
  <div
    class="song-field-container"
    [style.width]="thirdColWidth"
    [style.border-color]="borderColor"
    [style.height.px]="
      draggable && tableRowHoverId === 0 && index === 0
        ? heightPerItem - 2
        : heightPerItem - 1
    "
  >
    <tun-song-field
      class="song-field"
      [text]="propertyText"
      [isClickable]="propertySearchable"
      [isDoubleClickable]="playOnDoubleClick"
      [isSorted]="songSortMode === 'other'"
      (fieldClick)="onPropertyClick()"
      (fieldDoubleClick)="onFieldDoubleClick()"
    ></tun-song-field>
    <div
      #moreicon
      tunTooltipOnHover
      [tooltipText]="'tooltip.track.songInfo'"
      [tooltipEnabled]="!showingSongInfoPopup"
      tunPopupOnClick
      [clickDelay]="100"
      [closePopupAfterMouseLeave]="false"
      [closeOnOutsideElementClick]="true"
      [closeOnPopupClick]="false"
      (togglepopup)="togglePopup($event)"
    >
      <tun-icon-button [height]="heightPerItem" class="list-icon">
        <tun-more-vertical-icon></tun-more-vertical-icon>
      </tun-icon-button>
    </div>
    <tun-icon-button
      tunTooltipOnHover
      [tooltipText]="'tooltip.track.addToEditingList'"
      [height]="heightPerItem"
      class="list-icon arrow-icon"
      @fadeInOut
      *ngIf="showAddArrow"
      (click)="onAddClicked()"
    >
      <tun-arrow-down-icon></tun-arrow-down-icon>
    </tun-icon-button>
  </div>
</div>

<ng-template #queueOrPlayIcon>
  <tun-add-to-queue-icon *ngIf="!isQueue"></tun-add-to-queue-icon>
  <tun-play-icon *ngIf="isQueue"></tun-play-icon>
</ng-template>
