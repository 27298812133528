import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ZoneConnectionsService } from '../../../services/authentication/zone-connections.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-searching-player',
  templateUrl: './searching-player.component.html',
  styleUrls: ['./searching-player.component.scss']
})
export class SearchingPlayerComponent implements OnInit, OnDestroy {

  public secondsBeforeAutoStartPlayerMode: number;

  constructor(
    private zoneConnectionsService:ZoneConnectionsService
  ) { }

  ngOnInit() {
    this.zoneConnectionsService.secondsBeforeAutoStartPlayerMode$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (seconds) => {
        this.secondsBeforeAutoStartPlayerMode = seconds;
      }
    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public startnow(){
    this.zoneConnectionsService.startPlayerNow();
  }
}
