import { AudioFile } from '@model/audioFile';

export enum TracksAction {
    PLAY = "play",
    ADD_TO_QUEUE = "addToQueue"
}

export class TracksEvent {
    tracks: AudioFile[];
    action: TracksAction;
    
    constructor(action: TracksAction, tracks: AudioFile[]){
        this.action = action;
        this.tracks = tracks;
    }

    public toString(): string {
        return 'perform ' + this.action + ' for ' + this.tracks.length + ' tracks';
    }
}   