<div class="layerContainer" >

  <div class="layer" *ngIf="enableSoundSpectrum && showVisualiser">
      <div class="soundSpectrumContainer">
            <tun-sound-spectrum-stereo-component    [audioTagWrapper]="audioTagWrapper"
                                                    [playing]="playing"
                                                    [tunifyColor]="tunifyColor"
                                                    class="soundSpectrumStyle">
            </tun-sound-spectrum-stereo-component>
      </div>
  </div>

  <div class="layer" *ngIf="audioFile">

    <div
      class="audioFilePropertiesContainer"
      fxLayout="row"

    >

      <div
        #trackinfo
        data-dd-action-name="playingTrackInfo"
        [draggable]="trackIsDraggable"
        (dragstart)="onDragStart($event)"
        (dragend)="onDragEnd()"
        fxLayout="column"
        class="flexBoxMinimumSizing audioFilePropertiesStyle"
      >

        <!-- Title line-->
        <div [style.height.px]="buttonHeight" fxLayout="row" class="flexBoxMinimumSizing">

          <div fxFlex class="flexBoxMinimumSizing textBox titleTextBox">
            <div class="readabilityLabelGroup titlePosition">
                <span id="titleText" data-dd-action-name="playingTrackTitle" (click)="onPerformTrackAction(TrackAction.SEARCH_ON_TITLE)" [class]="enabledSearch?'titleText searchable':'titleText'" tunScrollOverflow>{{audioFile.title}}</span>
            </div>
          </div>

          <tun-icon-button
            *ngIf="enabledTrackActions"
            tunTooltipOnHover
            [tooltipText]="'tooltip.track.searchSimilar'"
            [tooltipDirection]="PopupDirection.DOWN"
            [tooltipPosition]="PopupPosition.BOTTOM_CENTER"
            [checkAccessToSearch]="true"
            data-dd-action-name="searchSimilarButton"
            (clickWithAccess)="onSearchSimilar()"
            [height]="buttonHeight"
            class="white-icon-button"
          >
            <tun-similar-icon></tun-similar-icon>
          </tun-icon-button>

          <div
              *ngIf="enabledTrackActions"
              #addToListButton
              class="white-icon-button"
              tunTooltipOnHover
              [tooltipText]="'tooltip.track.addToCustomList'"
              [tooltipDirection]="PopupDirection.DOWN"
              [tooltipPosition]="PopupPosition.BOTTOM_CENTER"
              [tooltipEnabled]="!showingAddToListPopup"
              [checkAccessToCustomPlaylists]="true"
              tunPopupOnClick
              data-dd-action-name="addToPlaylistButton"
              (togglepopup)="onToggleAddToListPopup($event)"
              [closePopupAfterMouseLeave]="false"
              [closeOnPopupClick]="false"
              [closeOnOutsideElementClick]="true">
            <tun-icon-button
                [height]="buttonHeight">
              <tun-add-to-list-icon></tun-add-to-list-icon>
            </tun-icon-button>
          </div>


        </div>

        <!-- Group line-->
        <div [style.height.px]="buttonHeight" fxLayout="row">

          <div fxFlex class="flexBoxMinimumSizing textBox groupTextBox">
              <div class="flexBoxMinimumSizing readabilityLabelGroup">
                  <span id="groupText" data-dd-action-name="playingTrackGroup" (click)="onPerformTrackAction(TrackAction.SEARCH_ON_GROUP)" [class]="enabledSearch?'groupText searchable':'groupText'" tunScrollOverflow>{{audioFile.group}}</span>
                </div>
          </div>

          <div class="textBox propertyBox" *ngIf="enabledTrackActions">
              <div class="flexBoxMinimumSizing readabilityLabelGroup">
                  <span id="propertyText" data-dd-action-name="playingTitleProperty" (click)="searchProperty()" [class]="enabledSearch?'propertyText searchable':'propertyText'" tunScrollOverflow>{{propertyText}}</span>
                </div>
          </div>

          <div
          *ngIf="enabledTrackActions"
          #moreButton
          tunTooltipOnHover
          [tooltipText]="'tooltip.track.songInfo'"
          [tooltipEnabled]="!showingSongInfoPopup"
          tunPopupOnClick
          [clickDelay]="100"
          [closePopupAfterMouseLeave]="false"
          [closeOnOutsideElementClick]="true"
          [closeOnPopupClick]="false"
          data-dd-action-name="trackInfoButton"
          (togglepopup)="onToggleSongInfoPopup($event)"
          class="white-icon-button">
            <tun-icon-button
                [height]="buttonHeight">
                <tun-more-vertical-icon></tun-more-vertical-icon>
            </tun-icon-button>
          </div>

        </div>
      </div>


    </div>

  </div>

</div>
