<div fxFlex fxLayout="column">
  <div
    fxLayout="row"
    [class]="streamMode == StreamMode.CALENDAR ? 'greenHeader' : 'blueHeader'"
    [style.height.px]="heightPerItem - 2"
  >
    <tun-icon-button
      tunTooltipOnHover
       [tooltipText]="'tooltip.radioQueue.refresh'"
      class="tun-icon-button-refresh"
      [height]="heightPerItem"
      (click)="refreshQueue()"
      (mouseenter)="onRefreshIconHover($event, true)"
      (mouseleave)="onRefreshIconHover($event, false)"
    >
      <tun-refresh-icon></tun-refresh-icon>
    </tun-icon-button>
  </div>

  <div
    fxFlex
    [class]="streamMode == StreamMode.CALENDAR ? 'greenContent flexBoxMinimumSizing' : 'blueContent flexBoxMinimumSizing'"
  >
    <!-- <div *ngFor="let audioFile of (radioQueuedAudioFiles | slice: 0:5)">
      {{ audioFile.title }} - {{ audioFile.group }}
      <button (click)="play(audioFile)">play</button>
      <button (click)="removeAudioFileFromRadioQueue(audioFile)">remove</button>
    </div> -->

    <!-- Audiofiles of a playlist. -->
    <tun-song-grid
      *ngIf="!(radioQueueLoading$ | async) && radioQueuedAudioFiles"
      [playlist]="radioQueuedAudioFiles"
      [maxScrollbarHeight]="5 * heightPerItem"
      [songGridColors]="songGridColors"
      [songSortMode]="'none'"
      [disableScrollbar]="disableScrollbar"
      [showPopupDelete]="true"
      (trackEvent)="onTrackEvent($event)"
    ></tun-song-grid>

    <ng-container *ngIf="radioQueueLoading$ | async">
      <tun-loader></tun-loader>
    </ng-container>

  </div>
</div>
