<div class="wrapper">

    <div mat-dialog-content class="content-container">

       <!-- upgrade image-->
      <img class="upgradeFullWidthImage" [src]="'assets/images/upgrade_v5.png'">

      <div class="header-label">
        {{'version.upgrade.popup.title' | translate }}
      </div>

      <div class="text-container">
        {{'version.upgrade.popup.info1' | translate }}
      </div>
      <div class="text-container">
        {{'version.upgrade.popup.info2' | translate }}
      </div>
    </div>

    <div mat-dialog-actions class="buttons-container">

      <button class="commit-button" (click)="onYesClick()">{{'version.upgrade.popup.button.commit' | translate }}</button>
      <button class="cancel-button" (click)="onNoClick()" cdkFocusInitial>{{'general.cancel' | translate  }}</button>

    </div>

</div>

