<div fxFlex fxLayout="column">
  <div
    class="headerBarBackground blackBottomBorder"
    [style.height.px]="heightPerItem - 1"
  >
    <div class="icons-container">
      <div class="left-icons">
        <div
          #myListsButton
          class="left"
          tunTooltipOnHover
          [tooltipText]="'tooltip.queue.addCustomPlaylist'"
          [tooltipEnabled]="!showingMyListsPopup"
          [checkAccessToCustomPlaylists]="true"
          tunPopupOnClick
          [closePopupAfterMouseLeave]="false"
          [closeOnPopupClick]="false"
          (togglepopup)="toggleMyListsPopup($event)"
          (mouseenter)="onIconHover(true, 'playlists')"
          (mouseleave)="onIconHover(false, 'playlists')"
        >
          <tun-icon-button [height]="heightPerItem - 1">
            <tun-playlists-icon
              [color]="playlistsIconColor"
            ></tun-playlists-icon>
          </tun-icon-button>
        </div>

        <!-- status container-->
        <div
          class="status"
          [style.height.px]="heightPerItem - 1"

        >
          <ng-container *ngIf="(busy$ | async) || (shuffling$ | async)">
            <tun-loader
            [text]="busyText$ | async"
            [spinnerSize]="iconHeight"
          >
            </tun-loader>
          </ng-container>
          <ng-container *ngIf="!(busy$ | async) && queuedTracksAvailable$ | async">
            <div class="durationStatus">
              {{formatSeconds(totalDuration$ | async)}}
            </div>

          </ng-container>

        </div>

      </div>



      <div
        class="right-icons {{(busy$ | async)?'disabled-button':''}}"
        *ngIf="queuedTracksAvailable$ | async"
      >
        <tun-icon-button
          tunTooltipOnHover
          [tooltipText]="'tooltip.queue.shuffle'"
					class="shuffle-button"
          [height]="heightPerItem - 1"
          (mouseenter)="onIconHover(true, 'shuffle')"
          (mouseleave)="onIconHover(false, 'shuffle')"
          (click)="shufflePlaylist()"
        >
          <tun-shuffle-icon></tun-shuffle-icon>
        </tun-icon-button>

        <tun-icon-button
          tunTooltipOnHover
          [tooltipText]="'tooltip.queue.empty'"
          [height]="heightPerItem - 1"
          [style.color]="deleteIconColor"
          (mouseenter)="onIconHover(true, 'delete')"
          (mouseleave)="onIconHover(false, 'delete')"
          (click)="deletePlaylist()"
        >
          <tun-delete-icon></tun-delete-icon>
        </tun-icon-button>
      </div>
    </div>
  </div>

  <div fxFlex class="layerContainer lightPanelBackground flexBoxMinimumSizing">

    <tun-song-grid
      *ngIf="(queuedTracks$ | async)"
      [playlist]="queuedTracks$ | async"
      [maxScrollbarHeight]="9 * heightPerItem"
      [songGridColors]="songGridColors"
      [songSortMode]="'none'"
      [draggable]="true"
      [isQueue]="true"
      [isRemote]="isRemote$ | async"
      [showPopupDelete]="true"
      (trackIndexEvent)="onTrackIndexEvent($event)"
      (trackEvent)="onTrackEvent($event)"
      class="layer"
    ></tun-song-grid>

    <div
      *ngIf="((selectedPlayerView$ | async) == TunifyColor.ORANGE) && !(queuedTracksAvailable$ | async)"
      class="layer queue-info"
    >
      <p>
        {{'orange.queue.empty.info1' | translate }}
      </p>
      <p>
        {{'orange.queue.empty.info2' | translate }}
      </p>

      <p *ngIf="activeCalendar$ | async" translate>
        orange.queue.empty.info.nextSongFromCalendar.beforeLink
        <button
          class="open-stream-link green-link"
          (click)="openStreamTab()"
        >
          {{(activeCalendar$ | async).name}}
        </button>
        orange.queue.empty.info.nextSongFromCalendar.afterLink
      </p>

      <p *ngIf="activeMusicChannel$ | async" translate>
        orange.queue.empty.info.nextSongFrom.beforeLink
        <button
          class="open-stream-link blue-link"
          (click)="openStreamTab()"
        >
        {{(activeMusicChannel$ | async).name}}
        </button>
        orange.queue.empty.info.nextSongFrom.afterLink
      </p>

    </div>

  </div>
</div>
