<div fxFlex class="flexBoxMinimumSizing playerContainer">

    <tun-searching-player   fxFlex class="flexBoxMinimumSizing"
                            *ngIf="(applicationMode$ | async) === null">

    </tun-searching-player>

    <tun-remote-track-controller
      fxFlex class="flexBoxMinimumSizing"
      *ngIf="(applicationMode$ | async) === ApplicationMode.remoteMode"
      [tunifyColor]="tunifyColor"
    >

    </tun-remote-track-controller>

    <tun-audio-file-controller  fxFlex class="flexBoxMinimumSizing"
                                *ngIf="(applicationMode$ | async) === ApplicationMode.playerMode"
                                [showNext]="showNext"
                                [buttonHeight]="heightPerItem"
                                [audioFile]="audioFile"
                                [audioFileWithPlayInfo]="audioFileWithPlayInfo"
                                [isFavorite]="isFavorite"
                                [isBanned]="isBanned"
                                [openRemoteInfoOnNewRemote]="isActivePlayer$ | async"
                                [tunifyColor]="tunifyColor"
                                (togglePlay)="onTogglePlay($event)"
                                (toggleFavorite)="onToggleFavorite($event)"
                                (toggleBanned)="onToggleBanned($event)"
                                (next)="onNext()"
                                (seekToTime)="onSeekToTime($event)"
                                (dropTrack)="onDropTrack($event)"
    >
    </tun-audio-file-controller>

</div>


