import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';

import { Subject, merge, Observable } from 'rxjs';
import { MusicChannelGroup } from 'src/app/model/musicChannelGroup';
import { LoggerService } from '@service/loggers/logger.service';

import { MusicChannel } from 'src/app/model/musicChannel';
import { MainNavigationItem } from '@components/app-components/menu/navigation-menu/model/MainNavigationItem';
import { NavigationItem } from '@components/app-components/menu/navigation-menu/model/NavigationItem';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { SearchMusicChannelService } from '@service/search-music-channel.service';
import { PlaylistService } from '@service/playlist.service';
import { Playlist } from '@model/playlist';
import { OrangeStateService } from '@service/orange-state.service';
import { MusicCollection } from '@model/musicCollection';
import { takeUntil, map, take } from 'rxjs/operators';
import { fadeAnimation, slideAnimation } from '@util/animations';
import { OrangeNavigationItem } from './model/OrangeNavigationItem';
import { Context } from '@model/context';
import { transition, useAnimation, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { NavigationItemTrackEvent } from '@components/app-components/menu/navigation-menu/main-navigation-item-view/main-navigation-item-view.component';
import { title } from 'process';

@Component({
  selector: 'tun-orange-menu',
  templateUrl: './orange-menu.component.html',
  styleUrls: ['./orange-menu.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation)
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        useAnimation(slideAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideAnimation, {
          params: {
            startX: 0,
            endX: '100%'
          }
        })
      ])
    ]),
    trigger('slideOutLeft', [
      transition(':leave', [
        useAnimation(slideAnimation, {
          params: {
            startX: 0,
            endX: '-100%'
          }
        })
      ]),
      transition(':enter', [
        useAnimation(slideAnimation, {
          params: {
            startX: '-100%',
            endX: 0
          }
        })
      ])
    ])
  ]
})
export class OrangeMenuComponent implements OnInit, OnDestroy {

  // === Observables === //
  private _destroy$ = new Subject<void>();

  // === Props === //
  @Input() heightPerItem = 0;
  @Input() widthForMenuPanel = 0;

  get loading$(){
    return this._searchMusicChannelService.loading$;
  }

  get loadingError$(){
    return this._searchMusicChannelService.loadingError$.pipe(map(error => error != null));
  }


  // === State === //
  private LOGGER_CLASSNAME = 'OrangeMenuComponent';

  /* selected musicChannel property */
  private selectedMusicChannel: MusicChannel;

  /* selected musicCollection property */
  private selectedMusicCollection: MusicCollection;

  /* selected playlist property */
  private selectedPlaylist: Playlist;

  /* musicChannelGroups property */
  private _musicChannelGroups: MusicChannelGroup[];

  private get musicChannelGroups(): MusicChannelGroup[] {
    return this._musicChannelGroups;
  }

  private set musicChannelGroups(value: MusicChannelGroup[]) {
    this._musicChannelGroups = value;
    this.createMainNavigationItems();
  }


  // dummy object to detect myLists was selected
  private selectedMainNavigationItem: MainNavigationItem;

  public mainNavigationItems: MainNavigationItem[] = [];
  public selectedNavigationItem?: NavigationItem;
  public subMenu: OrangeNavigationItem;
  isSubMenuOpen = false;

  constructor(
    private _loggerService: LoggerService,
    private _searchMusicChannelService: SearchMusicChannelService,
    private _playListService: PlaylistService,
    private _orangeStateService: OrangeStateService,
    private _translateService:TranslateService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {

    //reload all data with loading errors on startup of this component
    this.onReloadMenuContent();

    this._orangeStateService.selectedPlaylist$
      .pipe(takeUntil(this._destroy$))
      .subscribe(playlist => {
        this.selectedPlaylist = playlist;
        this.adjustSelectedItem();
      });

    this._orangeStateService.selectedMusicCollection$
      .pipe(takeUntil(this._destroy$))
      .subscribe(musicCollection => {
        this.selectedMusicCollection = musicCollection;
        this.adjustSelectedItem();
      });

    this._orangeStateService._selectedMusicChannel$
      .pipe(takeUntil(this._destroy$))
      .subscribe(musicChannel => {
        this.selectedMusicChannel = musicChannel;
        this.adjustSelectedItem();
      });

    this._searchMusicChannelService.musicChannelGroups$
      .pipe(takeUntil(this._destroy$))
      .subscribe(musicChannelGroups => this.musicChannelGroups = musicChannelGroups);

    // If either the playlists or favorites change, then we need to update the menu items.
    merge(
      this._playListService.playlists$,
      this._playListService.favorites$
    )
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.createMainNavigationItems());
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
  }

  closeSubMenu() {
    this.isSubMenuOpen = false;
    this.subMenu = undefined;
  }

  onSelectMainNavigationItem(mainNavigationItemToSelect: MainNavigationItem) {
    this.mainNavigationItems.forEach(mainNavigationItem => {
      mainNavigationItem.open = mainNavigationItem === mainNavigationItemToSelect;
    });
    this.selectedMainNavigationItem  = mainNavigationItemToSelect;
  }

  onSelectNavigationItem(navigationItem: NavigationItem) {
    if (navigationItem instanceof OrangeNavigationItem) {
      // selection of an item with a submenu -> go to submenu
      this.subMenu = navigationItem;
      this.isSubMenuOpen = true;
    } else {
      // If the navigation item has a parent, then we need to set the selectedMusicCollection$
      if (this.subMenu && navigationItem.parent) {
        const channel = this.subMenu.originalData as MusicChannel;
        const collection = navigationItem.originalData as MusicCollection;
        this._orangeStateService.selectMusicCollectionFromMusicChannel(channel, collection);
      } else if (navigationItem.originalData instanceof Playlist) {
        this._orangeStateService.selectedPlaylist = navigationItem.originalData;
      }
    }


  }

  public onDeleteNavigationItem(navigationItem: NavigationItem) {
    if (navigationItem.originalData instanceof Playlist) {
      this._playListService.deletePlaylist(navigationItem.originalData);
    }
  }

  public  onChangeNavigationItemTitle(navigationItem: NavigationItem) {
    if (navigationItem.originalData instanceof Playlist) {
      navigationItem.originalData.title = navigationItem.title;

      this._playListService.savePlaylistTitle(navigationItem.originalData);
    }
  }

  onCreateItemWithTitle(title: string) {
    const playlist: Playlist = new Playlist();
    playlist.title = title;
    this._playListService.createPlaylist(playlist);
    this._orangeStateService.selectedPlaylist = playlist;
  }

  /**
   * Create mainNavigationItems and navigationItems based on the musicChannelGroups model
   */
  private menuDestroyed$ = new Subject<void>();
  private createMainNavigationItems() {
    const newMainNavigationItems = [];

    //clean up previous subscriptions for translations
    this.menuDestroyed$.next(null);

    this.selectedMainNavigationItem = undefined;

    // Step 1a: Create 'My Lists' main item
    const mainNavigationItem = new MainNavigationItem();
    mainNavigationItem.tunifyColor = TunifyColor.ORANGE;
    mainNavigationItem.itemsCanAcceptsTrackDrops = true;

    this._translateService.get("customPlaylists.title")
        .pipe(
          takeUntil(
            merge(this._destroy$, this.menuDestroyed$)
          )
        )
        .subscribe(
          (value) => {
            mainNavigationItem.title = value;
          }
        );

    mainNavigationItem.items = [];

    // Step 1b: Create a navigation item for 'New List'
    const navigationItem = new NavigationItem();
    navigationItem.isNew = true;
    this._translateService.get("customPlaylists.new")
        .pipe(
          takeUntil(
            merge(this._destroy$, this.menuDestroyed$)
          )
        )
        .subscribe(
          (value) => {
            navigationItem.title = value;
          }
        );
    mainNavigationItem.items.push(navigationItem);

    // Step 1c: Create a navigation item for the favorites
    if (this._playListService.favorites) {
      const favoritesItem = new NavigationItem();
      favoritesItem.isFavorite = true;
      favoritesItem.acceptTrackDrops = true; // user can add tracks to the favorite list
      favoritesItem.originalData = this._playListService.favorites;
      favoritesItem.title = this._playListService.favorites.title;
      mainNavigationItem.items.push(favoritesItem);
    }

    // Step 1: Create navigation items for each playlist
    if (this._playListService.playlists) {

      // Step 1d: Create a navigation item for each playlist
      this._playListService.playlists.forEach(
        playlist => {
          const playlistItem = new NavigationItem();
          playlistItem.originalData = playlist;
          playlistItem.isEditable = true; // my lists are always editable
          playlistItem.acceptTrackDrops = true; // user can add tracks to custom lists
          mainNavigationItem.items.push(playlistItem);

          playlist.title$
          .pipe(
            takeUntil(
              merge(
                this._destroy$,
                this.menuDestroyed$
              )
            )
          )
          .subscribe(
            title => {
              playlistItem.title = title;
            }
          )
        }
      );
      newMainNavigationItems.push(mainNavigationItem);
    }

    // Step 2: Check if there are any music channel groups
    if (this.musicChannelGroups) {
      // Step 2a: Create a main navigation item per MusicChannelGroup
      this.musicChannelGroups.forEach(musicChannelGroup => {
        const mainNavigationItem = new MainNavigationItem();
        mainNavigationItem.tunifyColor = TunifyColor.ORANGE;
        mainNavigationItem.title = musicChannelGroup.name;

        // Step 2b: create a navigation item per music channel
        mainNavigationItem.items = musicChannelGroup.musicChannels.map(musicChannel => {
          const navigationItem = new OrangeNavigationItem();
          navigationItem.originalData = musicChannel;
          navigationItem.title = musicChannel.name;

          // Step 2c: Create a (sub) navigation item per music collection in this music channel
          navigationItem.items = musicChannel.musicCollections.map((musicCollection) => {
            const subNavigationItem = new NavigationItem();
            subNavigationItem.originalData = musicCollection;
            subNavigationItem.title = musicCollection.title;
            subNavigationItem.parent = navigationItem;
            return subNavigationItem;
          });

          //if this was the submenu that was open -> reselect it with the newly created object
          if (this.subMenu != null && this.subMenu.originalData instanceof MusicChannel && this.subMenu.originalData.id == musicChannel.id){
            this.subMenu = navigationItem;
          }

          return navigationItem;
        });
        newMainNavigationItems.push(mainNavigationItem);
      });
    }

    this.mainNavigationItems = newMainNavigationItems;

    this.adjustSelectedItem();
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Adjust the open mainNavigationItem and the selected navigationItems to the selected musicChannel
   */
  private adjustSelectedItem() {
    if (this.mainNavigationItems && this.mainNavigationItems.length > 0) {

      this.mainNavigationItems.forEach(mainNavigationItem => {
        mainNavigationItem.items.forEach(navigationItem => {
          if (navigationItem instanceof OrangeNavigationItem) {
            navigationItem.items.forEach((subItem) => this.adjustSelectionOfItem(mainNavigationItem, subItem));
            if (navigationItem.originalData instanceof MusicChannel) {
              navigationItem.selected = navigationItem.originalData == this.selectedMusicChannel;
            }


          } else {
            this.adjustSelectionOfItem(mainNavigationItem, navigationItem);
          }
        });
      });

      // if no items are open -> open the first one
      if (!this.selectedMainNavigationItem) {
        this.onSelectMainNavigationItem(this.mainNavigationItems[0]);
      }
    }
  }

  private adjustSelectionOfItem(mainNavigationItem: MainNavigationItem, navigationItem: NavigationItem) {
    if (navigationItem.originalData instanceof MusicChannel) {
      navigationItem.selected = this.selectedMusicChannel
        && navigationItem.originalData.id === this.selectedMusicChannel.id
        && navigationItem.originalData.musicChannelGroupId === this.selectedMusicChannel.musicChannelGroupId;

      // open the main navigation item of the selected item ---- if no mainNavigationItem is currenctly open
      if (navigationItem.selected) {
        this.onSelectMainNavigationItem(mainNavigationItem);
        this.selectedNavigationItem = navigationItem;
      }

    } else if (navigationItem.originalData instanceof Playlist) {
      // The selected playlist can come from two seperate observables
      const selectedPlaylist = this.selectedPlaylist ? this.selectedPlaylist : this.selectedMusicCollection as Playlist;
      navigationItem.selected = selectedPlaylist && navigationItem.originalData.id === selectedPlaylist.id;

      // open the main navigation item of the selected item ---- if no mainNavigationItem is currenctly open
      if (navigationItem.selected) {
        this.onSelectMainNavigationItem(mainNavigationItem);
        this.selectedNavigationItem = navigationItem;
      }
    } else if (navigationItem.originalData instanceof Context) {
      navigationItem.selected = this.selectedMusicCollection
        && navigationItem.originalData.id === this.selectedMusicCollection.id;
      if (navigationItem.selected) {
        this.onSelectMainNavigationItem(mainNavigationItem);
        this.selectedNavigationItem = navigationItem;
      }
    } else {
      if (navigationItem.originalData == null) {
        // create new etc -> never selectable
        navigationItem.selected = false;
      } else {
        this._loggerService.error(this.LOGGER_CLASSNAME, 'adjustSelectedMusicChannel', 'item not recognized: ' + navigationItem);
      }
    }
  }

  public onDropTrackOnNavigationItem(event: NavigationItemTrackEvent){
    if (event.navigationItem && event.navigationItem.originalData && event.navigationItem.originalData instanceof Playlist){
      this._playListService.addAudioFileToPlaylist(event.navigationItem.originalData, [event.track]);
    }
  }

  public onReloadMenuContent(){
    if (this._searchMusicChannelService.loadingError != null){
      this._searchMusicChannelService.loadMusicChannelGroups();
    }
    if (this._playListService.loadingError != null){
      this._playListService.loadPlaylists();
    }
    if (this._playListService.favoritesLoadingError != null){
      this._playListService.loadFavorites();
    }
  }
}
