<sass-helper></sass-helper>
<div #playerComponent fxFlex fxLayout="column" class="flexBoxMinimumSizing playerController">

  <!-- Container for the top content: Sound Spectrum + Song title & group -->
  <div fxFlex fxLayout="row" class="flexBoxMinimumSizing">

    <div *ngIf="remoteRemoteActive$ | async" class="remoteButtonContainer">
      <button #remoteButton
        class="remoteButton"
        data-dd-action-name="remotingInfoButton"
        tunPopupOnClick [closePopupAfterMouseLeave]="false"
        [closeOnPopupClick]="false"
        (togglepopup)="openRemoteInfo($event)"
      >
        <tun-remote-icon class="remote-icon"></tun-remote-icon>
      </button>
    </div>

    <tun-audio-file-playback-info fxFlex [audioFile]="audioFile" [audioFileWithPlayInfo]="audioFileWithPlayInfo"
      [audioFileProperty]="audioFileProperty" [buttonHeight]="buttonHeight" [playing]="playing"
      [tunifyColor]="tunifyColor" (trackEvent)="onTrackEvent($event)" *ngIf="audioFile!=null"
      (draggingFromThisChanged)="onDraggingFromThisChanged($event)">
    </tun-audio-file-playback-info>


  </div>

  <!-- Container for the bottom buttons and progress bar -->
  <div class="buttonsContainer" [style.height.px]="buttonHeight" fxLayout="row">

    <!-- the play / pause button container -->
    <div #togglePlayButton data-dd-action-name="togglePlayButton" class="left" (click)="onTogglePlay()">
      <tun-icon-button [height]="buttonHeight - 1" [iconSizeFactor]="0.6">
        <tun-play-icon *ngIf="!playing || needClickToStartAudio" class="whiteIcon"></tun-play-icon>
        <tun-pause-icon *ngIf="playing && !needClickToStartAudio" [class]="pauseIconClass"></tun-pause-icon>
      </tun-icon-button>
    </div>

    <tun-icon-button [height]="buttonHeight-1" data-dd-action-name="skipButton" (click)="onNext()" [iconSizeFactor]="0.6">
      <tun-skip-icon class="whiteIcon"></tun-skip-icon>
    </tun-icon-button>



    <div fxFlex class="progressContainer flexBoxMinimumSizing">
      <tun-audio-file-progress fxFlex (seekToTime)="onSeekToTime($event)" [audioFile]="audioFile"
        [audioFileWithPlayInfo]="audioFileWithPlayInfo" [tunifyColor]="tunifyColor">

      </tun-audio-file-progress>
    </div>

    <!-- the favorite button -->
    <tun-icon-button
      *ngIf="enabledTrackActions"
      [height]="buttonHeight-1"
      tunTooltipOnHover
      [tooltipText]="'tooltip.player.addToFavorites'"
      data-dd-action-name="toggleFavorite"
      (click)="onToggleFavorite()">
      <tun-thumb-up-icon [style.color]="favoriteIconColor"></tun-thumb-up-icon>
    </tun-icon-button>

    <!-- the banned button -->
    <tun-icon-button
      *ngIf="enabledTrackActions"
      [height]="buttonHeight-1"
      tunTooltipOnHover
      [tooltipText]="'tooltip.player.addToBanlist'"
      data-dd-action-name="toggleBanlist"
      (click)="onToggleBanned()">
      <tun-thumb-down-icon [style.color]="bannedIconColor"></tun-thumb-down-icon>
    </tun-icon-button>

  </div>



</div>
