import { Injectable, OnDestroy } from '@angular/core';
import { ConfigApiService, DTO_configStatus } from '@service/api/config-api.service';
import { ZoneConnectionApiService } from '@service/api/zone-connection-api.service';
import { ShareZoneConnectionToOtherAppService } from '@service/app-v5/share-zone-connection-to-other-app.service';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { LocalStorageService } from '@service/local-storage.service';
import { LoggerService } from '@service/loggers/logger.service';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export enum MAJOR_VERSION {
  STARTING = "starting",
  V4 = "4",
  V5 = "5"
}

function getMajorVersionByValue(majorVersionValue: string): MAJOR_VERSION | undefined {
  if (Object.keys(MAJOR_VERSION).map(key => MAJOR_VERSION[key]).includes(majorVersionValue)) {
    return majorVersionValue as MAJOR_VERSION;
  } else {
    return undefined
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppVersionService implements OnDestroy {

  private LOGGER_CLASSNAME = "AppVersionService";

  private readonly KEY_HTML_ALLOWED_VERSIONS_ACCESS = "HTML_ALLOWED_VERSIONS_ACCESS";

  public static DEFAULT_VERSION = MAJOR_VERSION.V5;

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private zoneConnectionApiService: ZoneConnectionApiService,
    private configApiService: ConfigApiService,
    private loggerService: LoggerService,
    private localStorageService: LocalStorageService,
    private shareZoneConnectionToOtherAppService: ShareZoneConnectionToOtherAppService
  ) {

    if (environment.default_version == "4"){
      AppVersionService.DEFAULT_VERSION = MAJOR_VERSION.V4
    }else if (environment.default_version == "5"){
      AppVersionService.DEFAULT_VERSION = MAJOR_VERSION.V5
    }

    this.loggerService.debug(this.LOGGER_CLASSNAME, "constructor", "version 4 default allowed:  " + environment.default_v4_allowed + " version 5 default allowed: " + environment.default_v5_allowed);

    //Add listener before loading the previous used version
    this.appVersionToUse$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (version) => {
        if (environment.use_redirect_location && version != MAJOR_VERSION.STARTING){
          this.shareZoneConnectionToOtherAppService.openZoneInOtherVersion(version);
        }
      }
    )

    zoneConnectionsService.activeZoneConnection$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (zoneConnection) => {
        this.clearData();
        if (zoneConnection != null){
          this.loadData();
        }
      }
    );

    this.version$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (version) => {
        this.zoneConnectionApiService.appVersion = version;
      }
    );



  }

  private loadData(){
    this.delayedStartDefaultVersion()
    this.fetchAllowedVersions()
  }

  private clearData(){
    this.cancelPrevousfetchRequests$.next();
    this.allowedVersionAccessString = "";
    this.allowedVersionsFetched = false;
    this.clearDelayedStartDefaultVersionCallback();

    this._allowedVersions = [];
  }

  public get version(): string{
    return environment.VERSION;
  }

  public get version$(): Observable<string>{
    return this.appVersionToUse$
            .pipe(
              map(
                (appVersion) => {
                  return environment.VERSION;
                }
              )
            )
  }

  /**
   * Player version to use.
   *
   * If there is a previous used version -> use the same version
   *
   * As soon as the allowed versions are fetched: check if the current version is allowed, if not change version
   *
   */


  public changeVersionToUse(version: MAJOR_VERSION){
    this.loggerService.debug(this.LOGGER_CLASSNAME, "changeVersionToUse", "version " + version + " requested.");
    if (version != MAJOR_VERSION.STARTING && this.isVersionAllowed(version)){
      this.localStorageService.lastUsedInterfaceMajorVersion = version;
      this._appVersionToUse = version;
    }
  }

    private get _appVersionToUse(): MAJOR_VERSION {
      return this._appVersionToUseSubject.value;
    }
    private set _appVersionToUse(value: MAJOR_VERSION) {
      if (this._appVersionToUse !== value) {
        this.loggerService.debug(this.LOGGER_CLASSNAME, "set appVersionToUse", "version " + value + " set.");
        this._appVersionToUseSubject.next(value);
      }
    }
    get appVersionToUse(): MAJOR_VERSION {
      return this._appVersionToUse;
    }

  private _appVersionToUseSubject: BehaviorSubject<MAJOR_VERSION> = new BehaviorSubject<MAJOR_VERSION>(MAJOR_VERSION.STARTING);
  public appVersionToUse$: Observable<MAJOR_VERSION> = this._appVersionToUseSubject.asObservable();


  public fetchPreviousUsedVersion(){
    const versionString = this.localStorageService.lastUsedInterfaceMajorVersion;
    if (versionString != null){
      const version = getMajorVersionByValue(versionString);
      if (version != undefined) {
        //When loaded from local storage, allow all versions
        this._appVersionToUse = version;
        /*
        if (this.isVersionAllowed(version)){
          this._appVersionToUse = version;
          this.loggerService.debug(this.LOGGER_CLASSNAME, "fetchPreviousUsedVersion", "version " + version + " detected.");
        }else{
          if (version == MAJOR_VERSION.V5){
            this._appVersionToUse = MAJOR_VERSION.V4;
          }else{
            this._appVersionToUse = MAJOR_VERSION.V5;
          }
          this.loggerService.warn(this.LOGGER_CLASSNAME, "fetchPreviousUsedVersion", "version " + version + " detected but is not allowed. Going to start other version.");
        }
          */
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, "fetchPreviousUsedVersion", "Could not recognize version from string " + versionString + ". Going to use " + this.appVersionToUse);
      }
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, "fetchPreviousUsedVersion", "no previous version, going to use " + this.appVersionToUse);
    }
  }

  /**
   * Allowed versions for the current active zoneConnection
   * Empty -> allow all
   */

  private get _allowedVersions(): MAJOR_VERSION[] {
    return this._allowedVersionsSubject.value;
  }
  private set _allowedVersions(value: MAJOR_VERSION[]) {

    this._allowedVersionsSubject.next(value);

    this.loggerService.debug(this.LOGGER_CLASSNAME, "set allowedVersions", "Allowed versions changed: " + this._allowedVersions.join(", "));

    //check if the current appVersionToUse is allowed, if not, start first allowed version
    if (this.appVersionToUse != MAJOR_VERSION.STARTING){
      if (!this.isVersionAllowed(this.appVersionToUse)){
        if (this.allowedVersions.length > 0){
          this._appVersionToUse = this.allowedVersions[0];
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, "set allowedVersions", "Current version is not allowed, but the array with allowed version is empty (=allow all)");
          this._appVersionToUse = MAJOR_VERSION.V5;
        }
      }
    }
  }
  get allowedVersions(): MAJOR_VERSION[] {
    return this._allowedVersions;
  }

  private _allowedVersionsSubject: BehaviorSubject<MAJOR_VERSION[]> = new BehaviorSubject<MAJOR_VERSION[]>([]);
  public allowedVersionsSubject$: Observable<MAJOR_VERSION[]> = this._allowedVersionsSubject.asObservable();

  //only show a link when the allowed versions are fetched
  public showLinkForVersion(version: MAJOR_VERSION): boolean {
    if (this.allowedVersionsFetched){
      return this.isVersionAllowed(version);
    }
    return false;
  }

  public isVersionAllowed(version: MAJOR_VERSION): boolean {
    if (this.allowedVersions.length > 0){
      return this.allowedVersions.indexOf(version) >= 0
    }else if (!this.allowedVersionsFetched){
      //as long as we did not load the allowed versions -> allow all (without showing an upgrade / downgrade link)
      return true;
    }

    if (version == MAJOR_VERSION.V4){
      return environment.default_v4_allowed;
    }else if (version == MAJOR_VERSION.V5){
      return environment.default_v5_allowed;
    }
    return false
  }

  private allowedVersionsFetched = false;
  private allowedVersionAccessString:string = "";
  private cancelPrevousfetchRequests$ = new Subject<void>();
  private fetchAllowedVersions(){
    if (this.zoneConnectionsService.activeZoneConnection != null){
      this.loggerService.debug(this.LOGGER_CLASSNAME, "fetchAllowedVersions", "Going to fetch allowed versions");
      this.cancelPrevousfetchRequests$.next();
      const allowedVersionsAccessObservable = this.configApiService.loadZoneConfigs([this.KEY_HTML_ALLOWED_VERSIONS_ACCESS]);
      if (allowedVersionsAccessObservable){
        allowedVersionsAccessObservable
        .pipe(
          takeUntil(
            merge(
              this.destroyed$,
              this.cancelPrevousfetchRequests$
            )
            )
        )
        .subscribe(
          values => {
            values.forEach((configValue) => {
              if (configValue.key == this.KEY_HTML_ALLOWED_VERSIONS_ACCESS) {
                if (configValue.status == DTO_configStatus.OK){
                  this.allowedVersionsFetched = true;
                  this.allowedVersionAccessString = configValue.value;
                }else if (configValue.status == DTO_configStatus.NOT_FOUND){
                  this.allowedVersionsFetched = true;
                  this.allowedVersionAccessString = "";
                }else{
                  this.loggerService.error(this.LOGGER_CLASSNAME, "fetchAllowedVersions", "received status " + configValue.status + " for key " + configValue.key);
                }
              }else{
                this.loggerService.error(this.LOGGER_CLASSNAME, "fetchAllowedVersions", "received status " + configValue.status + " for unknown key " + configValue.key);
              }
            });
            this.adjustUsedVersionToAllowed();
            this.startDefaultVersionIfNeeded();
          }
        )
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "fetchAllowedVersions", "no current active zoneConnection");
    }

  }



  private adjustUsedVersionToAllowed(){
    if (this.allowedVersionsFetched){
      this.clearDelayedStartDefaultVersionCallback();

      this._allowedVersions = this.parseVersionString(this.allowedVersionAccessString);
      this.startDefaultVersionIfNeeded();
    }
  }

  //helper function to parse a versions string ("4,5", "4", "5") to an array of versions
  private parseVersionString(versionString: String): MAJOR_VERSION[]{
    if (versionString != undefined){
      const allowedVersionsStrings = versionString.split(",").map(s => s.trim());
      let allowedVersions = allowedVersionsStrings
          .map(s => getMajorVersionByValue(s))
      allowedVersions = allowedVersions.filter((version): version is MAJOR_VERSION => !!version).filter(version => version !== MAJOR_VERSION.STARTING)
      return allowedVersions
    }
    return [];


  }

/**
 * Timer to start default player version when fetching the preferred players takes too long
 */

private MAX_WAITING_FOR_VERSION_MILLISECONDS = 5000;
private delayedStartDefaultVersionCallback = null;
private delayedStartDefaultVersion() {
  this.clearDelayedStartDefaultVersionCallback();
  if (this.delayedStartDefaultVersionCallback == null) {
    this.delayedStartDefaultVersionCallback = setTimeout(() => {
      this.delayedStartDefaultVersionCallback = null;
      this.startDefaultVersionIfNeeded();
    }, this.MAX_WAITING_FOR_VERSION_MILLISECONDS);
  }
}

private clearDelayedStartDefaultVersionCallback() {
  if (this.delayedStartDefaultVersionCallback) {
    clearTimeout(this.delayedStartDefaultVersionCallback);
    this.delayedStartDefaultVersionCallback = null;
  }
}


private startDefaultVersionIfNeeded(){
  if (this.appVersionToUse == MAJOR_VERSION.STARTING){
    if (this.allowedVersions.length > 0){
      this._appVersionToUse = this.allowedVersions[0];
    }else{
      this._appVersionToUse = AppVersionService.DEFAULT_VERSION;
    }
  }

}

}
