import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { combineLatest, Subscription } from "rxjs";
import { TunifyColor } from "@model/enums/tunifyColor.enum";
import { AppService } from '@service/app.service';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { map } from 'rxjs/operators';
import { trigger } from "@angular/animations";

@Component({
  selector: "tun-right-stream-panel",
  templateUrl: "./right-stream-panel.component.html",
  styleUrls: ["./right-stream-panel.component.scss"],
})
export class RightStreamPanelComponent{

  public get heightForHeader$(){
    return this.appService.heightForHeader$
  }
  public get heightPerItem$(){
    return this.appService.heightPerItem$;
  }
  public get tunifyColor$(){
    return this.activeMusicSelectionService.selectedPlayerView$;
  }
  public get heightForRadioQueue$(){
    return combineLatest([this.activeMusicSelectionService.selectedPlayerView$, this.appService.heightPerItem$])
    .pipe(
      map(([tunifyColor, heightPerItem]) => tunifyColor === TunifyColor.ORANGE ? 0 : 6 * heightPerItem)
    )
  }

  constructor(
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private appService: AppService
  ) {}

}
