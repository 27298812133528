import { Directive, AfterViewInit, OnInit, OnChanges, Input, ElementRef, Renderer2, HostListener, SimpleChanges, AfterContentChecked, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * This solution is based on angular-fitText, but with some fixes to support rem units and adapt to text changes without any flickering in the size
 */

@Directive({
  selector: '[tunFitSize]'
})
export class FitSizeDirective implements AfterViewInit, OnInit, OnChanges, OnDestroy {

  @Input() fittext? = true;
  @Input() compression? = 1;
  @Input() activateOnResize? = true;
  @Input() minFontSize?: number | 'inherit' = 0;
  @Input() maxFontSize?: number | 'inherit' = Number.POSITIVE_INFINITY;
  @Input() textToFit;
  @Input() delay? = 50;
  @Input() fontUnit?: 'px' | 'em' | 'rem' | string = 'px';

  private fittextParent: HTMLElement;
  private fittextElement: HTMLElement;
  private fittextMinFontSize: number;
  private fittextMaxFontSize: number;
  private computed: CSSStyleDeclaration;
  private newlines: number;
  private lineHeight: string;
  private display: string;
  static readonly calcFontSize = 0.5;
  private resizeTimeout;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.fittextElement = el.nativeElement;
    this.fittextParent = this.fittextElement.parentElement;
    this.computed = window.getComputedStyle(this.fittextElement);
    this.newlines = this.fittextElement.childElementCount > 0 ? this.fittextElement.childElementCount : 1;
    this.lineHeight = this.computed['line-height'];
    this.display = this.computed['display'];
  }

  public ngOnInit() {
    this.fittextMinFontSize = this.minFontSize === 'inherit' ? this.computed['font-size'] : this.minFontSize;
    this.fittextMaxFontSize = this.maxFontSize === 'inherit' ? this.computed['font-size'] : this.maxFontSize;
  }

  private destroyed$ = new Subject<void>();
  public ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public ngAfterViewInit() {
    this.setFontSize(0);
    fromEvent(window, 'resize')
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        if (this.activateOnResize) {
          this.setFontSize(0);
        }
      }
    )
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['compression'] && !changes['compression'].firstChange) {
      this.setFontSize();
    }
    if (changes['textToFit']) {
      this.fittextElement.innerHTML = this.textToFit;
      this.setFontSize(0);
    }
  }

  private setFontSize = (delay: number = this.delay): void => {
    if (delay == 0){
      this.performSizeFitting();
    }else{
      this.resizeTimeout = setTimeout(
        (() => {
          this.performSizeFitting();
        }).bind(this),
        delay
      );
    }
  };

  private performSizeFitting(){
    if (this.fittextElement.offsetHeight * this.fittextElement.offsetWidth !== 0) {
      // reset to default
      this.setStyles(FitSizeDirective.calcFontSize, 1, 'inline-block');
      // set new
      this.setStyles(this.calculateNewFontSize(), this.lineHeight, this.display);
    }
  }

  private calculateNewFontSize = (): number => {
    const ratio = (FitSizeDirective.calcFontSize * this.newlines) / this.fittextElement.offsetWidth / this.newlines;

    return Math.max(
      Math.min(
        (this.fittextParent.offsetWidth -
          (parseFloat(getComputedStyle(this.fittextParent).paddingLeft) +
            parseFloat(getComputedStyle(this.fittextParent).paddingRight)) -
          6) *
        ratio *
        this.compression,
        this.fittextMaxFontSize
      ),
      this.fittextMinFontSize
    );
  };

  private setStyles = (fontSize: number, lineHeight: number | string, display: string): void => {
    this.renderer.setStyle(this.fittextElement, 'fontSize', fontSize.toString() + this.fontUnit);
    this.renderer.setStyle(this.fittextElement, 'lineHeight', lineHeight.toString());
    this.renderer.setStyle(this.fittextElement, 'display', display);
  };

}
