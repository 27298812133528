import { transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { carouselAnimation, fadeAnimation, slideAnimation } from '@util/animations';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppV5StateService } from '../../../../services/app-v5/app-v5-state.service';
import { takeUntil } from 'rxjs/operators';

export enum SETTINGS_VIEW {
  SETTINGS_VIEW_HOME = "settingsHome",
  SETTINGS_VIEW_LANGUAGE = "settingsLanguage",
  SETTINGS_VIEW_BANLIST = "settingsBanlist",
  SETTINGS_VIEW_MUSICRIGHTS = "settingsMusicRights",
  SETTINGS_VIEW_SONGREQUEST = "settingsSongRequest",
  SETTINGS_VIEW_DESKTOP_APP = "settingsDesktopApp",
}

export const SettingsViewLevel = new Map<SETTINGS_VIEW, number>([
  [SETTINGS_VIEW.SETTINGS_VIEW_HOME, 0],
  [SETTINGS_VIEW.SETTINGS_VIEW_LANGUAGE, 1],
  [SETTINGS_VIEW.SETTINGS_VIEW_BANLIST, 1],
  [SETTINGS_VIEW.SETTINGS_VIEW_MUSICRIGHTS, 1],
  [SETTINGS_VIEW.SETTINGS_VIEW_SONGREQUEST, 1],
  [SETTINGS_VIEW.SETTINGS_VIEW_DESKTOP_APP, 1]
]);

@Component({
  selector: 'tun-settings-panel',
  templateUrl: './settings-panel.component.html',
  styleUrls: ['./settings-panel.component.scss'],
  animations: [
    carouselAnimation,
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, { params: { duration: 10 } })
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        useAnimation(slideAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideAnimation, {
          params: {
            startX: 0,
            endX: '100%'
          }
        })
      ])
    ]),
    trigger('slideOutLeft', [
      transition(':leave', [
        useAnimation(slideAnimation, {
          params: {
            startX: 0,
            endX: '-100%'
          }
        })
      ]),
      transition(':enter', [
        useAnimation(slideAnimation, {
          params: {
            startX: '-100%',
            endX: 0
          }
        })
      ])
    ])
  ]
})
export class SettingsPanelComponent implements OnDestroy {

  public CAROUSEL_DURATION = 300;
  public animationDirection: 'next' | 'prev' = 'next';


  public SETTINGS_VIEW = SETTINGS_VIEW;
  private _settingsView$ = new BehaviorSubject<SETTINGS_VIEW>(SETTINGS_VIEW.SETTINGS_VIEW_HOME);
  public set settingsView(view: SETTINGS_VIEW){
    if (this._settingsView$.value != view){
      if (SettingsViewLevel.get(this._settingsView$.value) < SettingsViewLevel.get(view)){
        this.animationDirection = 'next';
      }else{
        this.animationDirection = 'prev';
      }
      this.changeDetectorRef.detectChanges();
      this._settingsView$.next(view)
    }
  }
  public get settingsView$(){
    return this._settingsView$;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private appV5StateService: AppV5StateService
  ) {
    this.appV5StateService.selectedTab$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.settingsView = SETTINGS_VIEW.SETTINGS_VIEW_HOME;
      }
    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
  }

  onOpenSettingsView(settingsView: SETTINGS_VIEW){
    this.settingsView = settingsView;
  }

  onBack(){
    this.settingsView = SETTINGS_VIEW.SETTINGS_VIEW_HOME;
  }
}
