<div class="autocomplete-list" [style.width.px]="width">
  <div *ngIf="suggestions.length; then results else noResults"></div>
</div>

<ng-template #results>
  <tun-autocomplete-result-row *ngFor="let suggestion of suggestions; let i = index;"
    [text]="suggestion.autocompletionText" [type]="suggestion.type" [selected]="i === selectedIndex"
    (click)="onClickItem(suggestion)">
  </tun-autocomplete-result-row>
</ng-template>

<ng-template #noResults>
  <tun-autocomplete-result-row [text]="searchTerm"></tun-autocomplete-result-row>
</ng-template>
