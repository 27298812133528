<button (click)="onClick($event)">
  <div class="button-content">
    <span class="icon-container">
      <tun-search-icon></tun-search-icon>
    </span>
    <span
      #buttonText
      class="button-text"
      tunScrollOverflow
    >
      {{ text }}
    </span>
  </div>
</button>
