<sass-helper></sass-helper>
<div #playerComponent fxFlex fxLayout="column" class="flexBoxMinimumSizing playerController">

  <div class="yellowLine"></div>

  <!-- Container for the top content: Sound Spectrum + Song title & group -->
  <div fxFlex fxLayout="row" class="flexBoxMinimumSizing">

    <div class="remoteButtonContainer">
      <button #remoteButton
        class="remoteButton"
        tunPopupOnClick [closePopupAfterMouseLeave]="false"
        [closeOnPopupClick]="false"
        (togglepopup)="openRemoteInfo($event)"
      >
        <tun-remote-icon class="remote-icon"></tun-remote-icon>
      </button>
    </div>

    <!--
    <div
      *ngIf="startingTrack$ | async"
      fxFlex
    >

      <tun-loader
      [text]="(startingTrack$ | async)"
      [spinnerSize]="buttonHeight$ | async"
    >
      </tun-loader>

    </div>
    -->

    <!-- If we know the current player is not responding -->

    <div
      fxFlex
      *ngIf="!(remotePlayerActive$ | async)"
      class="remoteInfoContainer"
    >
      {{ 'connect.closed' | translate }}
    </div>

    <div
      fxFlex
      *ngIf="(remotePlayerActive$ | async) && !(remoteTrackActionsAllowed$ | async)"
      class="remoteInfoContainer"
    >
      {{ 'connect.info.noCurrentTrack' | translate }}
    </div>

    <tun-audio-file-playback-info
      fxFlex
      *ngIf="(remotePlayerActive$ | async) && (remoteTrackActionsAllowed$ | async) && (remoteTrack$ | async) !=null"
      [audioFile]="remoteTrack$ | async"
      [audioFileProperty]="audioFileProperty" [buttonHeight]="(buttonHeight$ | async)" [playing]="playing"
      [tunifyColor]="tunifyColor" (trackEvent)="onTrackEvent($event)"
      (draggingFromThisChanged)="onDraggingFromThisChanged($event)">
    </tun-audio-file-playback-info>


  </div>

  <!-- Container for the bottom buttons and progress bar -->
  <div class="buttonsContainer {{ !(remoteTrackActionsAllowed$ | async) ? 'disabledButtons' : ''}}" [style.height.px]="(buttonHeight$ | async)" fxLayout="row">

    <!-- the play / pause button container -->
    <div #togglePlayButton class="left" (click)="onTogglePlay()">
      <tun-icon-button [height]="(buttonHeight$ | async) - 1" [iconSizeFactor]="0.6">
        <tun-play-icon *ngIf="!(remotePlaying$ | async)" class="whiteIcon"></tun-play-icon>
        <tun-pause-icon  *ngIf="(remotePlaying$ | async)" [class]="pauseIconClass"></tun-pause-icon>
      </tun-icon-button>
    </div>

    <tun-icon-button [height]="(buttonHeight$ | async) - 1" (click)="onNext()" [iconSizeFactor]="0.6">
      <tun-skip-icon class="whiteIcon"></tun-skip-icon>
    </tun-icon-button>



    <div fxFlex class="progressContainer flexBoxMinimumSizing">
      <tun-remote-progress fxFlex>

      </tun-remote-progress>
    </div>

    <!-- the favorite button -->
    <tun-icon-button
      *ngIf="trackActionsAllowed$ | async"
      [height]="(buttonHeight$ | async) -1"
      tunTooltipOnHover
      [tooltipText]="'tooltip.player.addToFavorites'"
      (click)="onToggleFavorite()"
    >
      <tun-thumb-up-icon [style.color]="favoriteIconColor"></tun-thumb-up-icon>
    </tun-icon-button>

    <!-- the banned button -->
    <tun-icon-button
      *ngIf="trackActionsAllowed$ | async"
      [height]="(buttonHeight$ | async)-1"
      tunTooltipOnHover
      [tooltipText]="'tooltip.player.addToBanlist'"
      (click)="onToggleBanned()"
    >
      <tun-thumb-down-icon [style.color]="bannedIconColor"></tun-thumb-down-icon>
    </tun-icon-button>

  </div>



</div>

<!--
<div fxFlex fxLayout="column" class="flexBoxMinimumSizing remoteContainer">

  <div class="activePlayerInfoContainer" >
    You are playing on {{deviceName$ | async}}
    <button (click)="takeOver()">Take over and Start playing</button>
  </div>

  <div class="remoteTrackInfoContainer" >
    Now playing: {{(remoteTrack$ | async)?.title}} - {{(remoteTrack$ | async)?.group}}
  </div>



  <div class="buttonsContainer" fxLayout="row">


    <div class="buttonContainer">

      <button (click)="onTogglePlay()" class="controlButton" [disabled]="(remoteTrack$ | async) === null">

        <fa-icon *ngIf="!(remotePlaying$ | async)" class="whiteIcon" [icon]="faPlay"></fa-icon>
        <fa-icon *ngIf="(remotePlaying$ | async)" class="whiteIcon" [icon]="faPause"></fa-icon>
      </button>

    </div>


    <div class="buttonContainer">
      <button (click)="onNext()" class="controlButton">
        <fa-icon [icon]="faStepForward" class="whiteIcon"></fa-icon>
      </button>
    </div>


     <div fxFlex class="progressContainer">
        <tun-remote-progress  fxFlex>

        </tun-remote-progress>
     </div>


    <tun-icon-button
    *ngIf="trackActionsAllowed$ | async"
    [height]="(buttonHeight$ | async) -1"
    tunTooltipOnHover
    [tooltipText]="'tooltip.player.addToFavorites'"
    (click)="onToggleFavorite()">
    <tun-thumb-up-icon [style.color]="favoriteIconColor"></tun-thumb-up-icon>
  </tun-icon-button>


  <tun-icon-button
    *ngIf="trackActionsAllowed$ | async"
    [height]="(buttonHeight$ | async)-1"
    tunTooltipOnHover
    [tooltipText]="'tooltip.player.addToBanlist'"
    (click)="onToggleBanned()">
    <tun-thumb-down-icon [style.color]="bannedIconColor"></tun-thumb-down-icon>
  </tun-icon-button>



  </div>


</div>

-->
