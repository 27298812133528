import { Injectable, OnDestroy } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { MusicChannel } from '@model/musicChannel';
import { MusicCollection } from '@model/musicCollection';
import { Playlist } from '@model/playlist';
import { MusicCollectionApiService, MusicCollectionPackage } from '@service/api/music-collection-api.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicCollectionService } from '@service/music-collection.service';
import { SearchService } from '@service/search.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Context } from '@model/context';
import { ChangeableParameter } from '@model/changeableParameter';
import { createClassObjectForChangeableParameter } from '@service/util/changeableParameterUtil';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { ActiveMusicSelectionService } from './active-music-selection.service';
import { SearchMusicChannelService } from '@service/search-music-channel.service';
import { MusicChannelService } from '../data/music-channel.service';

@Injectable({
  providedIn: 'root'
})
export class OrangeStateService implements OnDestroy {
  constructor(
    private musicCollectionService: MusicCollectionService,
    private musicChannelService: MusicChannelService,
    private musicCollectionApiService: MusicCollectionApiService,
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private searchMusicChannelService: SearchMusicChannelService,
    private searchService: SearchService,
    private loggerService: LoggerService
  ) {
    this.searchService.searchStarts$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        if (
          this.activeMusicSelectionService.selectedPlayerView ===
          TunifyColor.ORANGE
        ) {
          this.showSearch = true;
        }
      });

      this.searchMusicChannelService.musicChannelGroups$
      .pipe(takeUntil(this._destroy$))
      .subscribe((musicChannelGroups) => {
        if ( musicChannelGroups != null ) { //when new musicChannels are loaded -> try to reselect the correct objects

          let newSelectionFound = false;
          if (this.selectedMusicCollection && this.selectedMusicChannel){

            musicChannelGroups.forEach((musicChannelGroup)  => {
              if (musicChannelGroup.musicChannels){
                musicChannelGroup.musicChannels.forEach((musicChannel) => {
                  if (musicChannel.id == this.selectedMusicChannel.id && musicChannel.musicCollections != null){
                    musicChannel.musicCollections.forEach((musicCollection) => {
                      if (musicCollection.id == this.selectedMusicCollection.id){
                        this.selectMusicCollectionFromMusicChannel(musicChannel, musicCollection);
                        newSelectionFound = true;
                      }
                    });
                  }
                });
              }

            });
          };
          if (!newSelectionFound){
            this._selectedMusicChannel = null;
            this._selectedMusicCollection = null;
          }
        }
      });

  }

  /**
   * The playlist the user is viewing in Orange
   */
  public get selectedPlaylist(): Playlist {
    return this._selectedPlaylistSubject.value;
  }
  public set selectedPlaylist(value: Playlist) {
    if (this.selectedPlaylist !== value) {
      if (value) {
        // unselect all others
        this._selectedMusicCollection = null;
        this._selectedMusicChannel = null;
        this.showSearch = false;

        // if the detail are not yet loaded -> start loading
        if (
          !value.detailsLoaded &&
          !value.detailsLoading &&
          !value.creatingPlaylist
        ) {
          this.musicCollectionService.loadMusicCollectionDetails(value);
        }
      }
      this._selectedPlaylistSubject.next(value);
    }
  }

  /**
   * The playlist the user is editing in Orange
   */
  public get editingPlaylist(): Playlist {
    return this._editingPlaylistSubject.value;
  }
  public set editingPlaylist(value: Playlist) {
    if (value === null) {
      this._editingPlaylistSubject.next(null);
    } else if (value === this.editingPlaylist) {
      this._editingPlaylistSubject.next(null);
      this._selectedPlaylistSubject.next(value);
    } else {
      this._selectedPlaylistSubject.next(null);
      this._editingPlaylistSubject.next(value);
    }
  }

  private get _selectedMusicCollection(): MusicCollection {
    return this._selectedMusicCollectionSubject.value;
  }
  private set _selectedMusicCollection(value: MusicCollection) {
    if (this._selectedMusicCollection !== value) {
      this.cancelPendingSearch.next(null);
      this._loadingTracksForMusicCollection = false;
      this._loadingTracksForMusicCollectionError = null;
      this._tracksForMusicCollection = null;

      this._selectedMusicCollectionSubject.next(value);
    }
  }
  public get selectedMusicCollection() {
    return this._selectedMusicCollection;
  }

  private get _selectedMusicChannel(): MusicChannel {
    return this._selectedMusicChannelSubject.value;
  }
  private set _selectedMusicChannel(value: MusicChannel) {
    if (this._selectedMusicChannel !== value) {
      this._selectedMusicChannelSubject.next(value);
    }
  }
  public get selectedMusicChannel() {
    return this._selectedMusicChannel;
  }

  /**
   * loading tracks for the selected musicCollection / MusicChannel
   */
  private get _loadingTracksForMusicCollection(): boolean {
    return this._loadingTracksForMusicCollectionSubject.value;
  }
  private set _loadingTracksForMusicCollection(value: boolean) {
    if (this._loadingTracksForMusicCollection !== value) {
      console.log("change _loadingTracksForMusicCollection to " + value);
      this._loadingTracksForMusicCollectionSubject.next(value);
    }
  }
  get loadingTracksForMusicCollection(): boolean {
    return this._loadingTracksForMusicCollection;
  }

  /**
   * Error emitter for retrieving the tracks for the selected musicCollection / MusicChannel
   */
  private get _loadingTracksForMusicCollectionError(): string {
    return this._loadingTracksForMusicCollectionErrorSubject.value;
  }
  private set _loadingTracksForMusicCollectionError(value: string) {
    if (this._loadingTracksForMusicCollectionError !== value) {
      this._loadingTracksForMusicCollectionErrorSubject.next(value);
    }
  }
  get loadingTracksForMusicCollectionError(): string {
    return this._loadingTracksForMusicCollectionError;
  }

  /**
   * tracks for the selected musicCollection / MusicChannel
   */
  private get _tracksForMusicCollection(): AudioFile[] {
    return this._tracksForMusicCollectionSubject.value;
  }
  private set _tracksForMusicCollection(value: AudioFile[]) {
    if (this._tracksForMusicCollection !== value) {
      this._tracksForMusicCollectionSubject.next(value);
    }
  }
  get tracksForMusicCollection(): AudioFile[] {
    return this._tracksForMusicCollection;
  }

  /**
   * Show search - indicates that the last search must be displayed in oragne
   */
  public get showSearch(): Boolean {
    return this._showSearchSubject.value;
  }
  public set showSearch(value: Boolean) {
    if (this.showSearch !== value) {
      if (value) {
        // unselect all others
        this.selectedPlaylist = null;
        this._selectedMusicCollection = null;
        this._selectedMusicChannel = null;
      }
      this._showSearchSubject.next(value);
    }
  }
  private LOGGER_CLASSNAME = 'OrangeStateService';

  // === Observables === //
  private _destroy$ = new Subject<void>();

  private _selectedPlaylistSubject: BehaviorSubject<
    Playlist
  > = new BehaviorSubject<Playlist>(null);
  public selectedPlaylist$: Observable<
    Playlist
  > = this._selectedPlaylistSubject.asObservable();

  private _editingPlaylistSubject: BehaviorSubject<
    Playlist
  > = new BehaviorSubject<Playlist>(null);
  public editingPlaylist$: Observable<
    Playlist
  > = this._editingPlaylistSubject.asObservable();

  private _selectedMusicCollectionSubject: BehaviorSubject<
    MusicCollection
  > = new BehaviorSubject<MusicCollection>(null);
  public selectedMusicCollection$: Observable<
    MusicCollection
  > = this._selectedMusicCollectionSubject.asObservable();

  private _selectedMusicChannelSubject: BehaviorSubject<
    MusicChannel
  > = new BehaviorSubject<MusicChannel>(null);
  public _selectedMusicChannel$: Observable<
    MusicChannel
  > = this._selectedMusicChannelSubject.asObservable();

  private _loadingTracksForMusicCollectionSubject: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);
  public loadingTracksForMusicCollection$: Observable<
    boolean
  > = this._loadingTracksForMusicCollectionSubject.asObservable();

  private _loadingTracksForMusicCollectionErrorSubject: BehaviorSubject<
    string
  > = new BehaviorSubject<string>(null);
  public loadingTracksForMusicCollectionError$: Observable<
    string
  > = this._loadingTracksForMusicCollectionErrorSubject.asObservable();

  private _tracksForMusicCollectionSubject: BehaviorSubject<
    AudioFile[]
  > = new BehaviorSubject<AudioFile[]>(null);
  public tracksForMusicCollection$: Observable<
    AudioFile[]
  > = this._tracksForMusicCollectionSubject.asObservable();

  private cancelPendingSearch = new Subject<void>();
  private _showSearchSubject: BehaviorSubject<Boolean> = new BehaviorSubject<
    Boolean
  >(false);
  public showSearch$: Observable<
    Boolean
  > = this._showSearchSubject.asObservable();

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$ = null;
  }

  public selectMusicCollectionFromMusicChannel(
    musicChannel: MusicChannel,
    musicCollection: MusicCollection
  ) {
    // unselect all others
    this.selectedPlaylist = null;
    this.showSearch = false;

    // select these in one go (actually does not matter because they are still seperate Observables)
    this._selectedMusicCollection = musicCollection;
    this._selectedMusicChannel = musicChannel;

    // start new search
    this.loadTracksForCurrentMusicCollection();


    //Each time we select a search musicCollection -> reset its params
    this.musicChannelService.loadMusicCollection(musicChannel.id, musicCollection)
  }

  public clearMusicCollectionSelection() {
    this._selectedMusicChannel = null;
    this._selectedMusicCollection = null;
  }

  public resetParametersForCurrentMusicCollection() {
    if (this.selectedMusicCollection && this.selectedMusicCollection instanceof Context && this.selectedMusicCollection.defaultChangeableParameter) {
      let copyOfDefaults: ChangeableParameter[] = [];
      this.selectedMusicCollection.defaultChangeableParameter.forEach(param => {
        copyOfDefaults.push(createClassObjectForChangeableParameter(param));
      });
      this.selectedMusicCollection.changeableParameter = copyOfDefaults;
    }
  }

  public loadTracksForCurrentMusicCollection() {
    let musicCollectionPackages: MusicCollectionPackage[] = [];
    let musicCollectionPackage = new MusicCollectionPackage();
    if (this.selectedMusicCollection instanceof Context) {
      musicCollectionPackage.changeableParameters = this.selectedMusicCollection.changeableParameters;
    }
    musicCollectionPackage.mcId = this.selectedMusicCollection.id;
    musicCollectionPackage.musicChannelId = this.selectedMusicChannel != null ? this.selectedMusicChannel.id : 0;
    musicCollectionPackage.weight = 1;
    musicCollectionPackages.push(musicCollectionPackage);

    const searchObservable = this.musicCollectionApiService.loadTracksForMusicCollectionPackages(musicCollectionPackages);
    if (searchObservable) {
      this.cancelPendingSearch.next(null);
      this._loadingTracksForMusicCollectionError = null;
      this._loadingTracksForMusicCollection = true;
      this._tracksForMusicCollection = null;

      searchObservable
        .pipe(
          finalize(() => {
            this.loggerService.debug(
              this.LOGGER_CLASSNAME,
              'loadTracksForCurrentMusicCollection finalize',
              'finalize loading: ' + this._loadingTracksForMusicCollection
            );
            if (!this._loadingTracksForMusicCollection) {
              this.loggerService.error(
                this.LOGGER_CLASSNAME,
                'loadTracksForCurrentMusicCollection finalize',
                'finalize but we are not loading!'
              );
            }
            this._loadingTracksForMusicCollection = false;
          }),
          takeUntil(this.cancelPendingSearch)
        )
        .subscribe(
          data => {
            const realObjects = data.map(res => createClassObjectForAudioFile(res));
            this._tracksForMusicCollection = realObjects;
          },
          error => {
            const errMsg = error.message
              ? error.message
              : error.status
                ? `${error.status} - ${error.statusText}`
                : 'Server error';
            this.loggerService.error(
              this.LOGGER_CLASSNAME,
              'handleSearchObservable',
              'err: ' + errMsg
            );
            this._loadingTracksForMusicCollectionError = 'GeneralError';
          }
        );
    } else {
      // nothing to load
    }
  }

  /**
   * show a playlist -> show the tracks of the selected playlist (e.g. from playlist popup)
   */
  public showPlaylistFromSearch(playlist: Playlist) {
    if (
      playlist != null &&
      !playlist.detailsLoaded &&
      !playlist.detailsLoading
    ) {
      this.musicCollectionService.loadMusicCollectionDetails(playlist);
    }
    this.showSearch = true;
    this._selectedPlaylistSubject.next(playlist);
  }
}
