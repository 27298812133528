import { Injectable, OnDestroy } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { LoggerService } from '../loggers/logger.service';
import { MusicPlayerService } from '@service/music-player.service';
import { PlayState } from '@model/enums/playState.enum';
import { AudioFileWithPlayInfo } from '@service/audioFileWithPlayInfo';
import { AudioFile } from '@model/audioFile';
import { PlayerStatusForRemoteSharing, TrackForRemoteSharing, CurrentTrackInfoForRemoteSharing, PlayModeForRemoteSharing, ProgressForRemoteSharing, PrelistenAction, RemoteAudioType, ConnectionType } from './vo/remote/remote-objects';
import { AudioTagService } from '@service/audio.tag.service';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { map, mergeAll, takeUntil, filter } from 'rxjs/operators';
import { DataUpdateService } from '../realTimeCommunication/data-update.service';
import { Song } from '../../model/song';
import { ZonePresenceChecker, ZonePresenceService } from '../realTimeCommunication/zone-presence.service';
import { ApplicationMode } from '@service/authentication/zone-connections.service';
import { BrandMessage } from '../../model/brandMessage';
import { TrackOrigin } from '@model/trackOrigin';
import { AudioFileOriginType } from '../../model/enums/audioFileOriginType';





@Injectable({
  providedIn: 'root'
})
export class RemoteService implements OnDestroy {

  private LOGGER_CLASSNAME = 'RemoteService';

  //prelisten feedback
  private prelistenFeedbackSubject = new Subject<boolean>();
  public prelistenFeedback$ = this.prelistenFeedbackSubject.asObservable();

  constructor(private zoneConnectionsService: ZoneConnectionsService,
              private loggerService: LoggerService,
              private musicPlayerService:MusicPlayerService,
              private audioTagService: AudioTagService,
              private dataUpdateService: DataUpdateService,
              private zonePresenceService: ZonePresenceService
              ) {

    this.zoneConnectionsService.activeZoneConnection$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (activeZoneConnection) => {
          if (activeZoneConnection == null){
              this.cleanUpData();
          }
      },
      (err: unknown) => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'activeZoneConnection subscription', 'error from activeZoneConnection subscription in RemoteService: ' + err);
      }
    );

    this.zoneConnectionsService.applicationMode$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.adjustSharePlayerState();
      },
      (err: unknown) => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'playTokenSubscription', 'error from playTokenSubscription in RemoteService: ' + err);
      }
    );

    this.zoneConnectionsService.activeZoneConnection$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.watchConnectedApplications();
      }
    );

  }

  private watchConnectedApplications(){
    const currentActiveZoneConnection = this.zoneConnectionsService.activeZoneConnection;
    if (currentActiveZoneConnection != null){
      this.zoneConnectionsService.activeZoneConnection.externalApplicationsInfo.externalApplicationsInfo$
      .pipe(
        takeUntil(
          merge(
            this.destroyed$,
            this.zoneConnectionsService.activeZoneConnection$.pipe(filter(activeZoneConnection => activeZoneConnection != currentActiveZoneConnection))
          )
        )
      )
      .subscribe(
        () => {
          this.adjustSharePlayerState();
        },
        (err: unknown) => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'watchConnectedApplications', 'error from watchConnectedApplicationsSubscription in RemoteService: ' + err);
        }
      )
    }else{
      this.adjustSharePlayerState();
    }

  }


  private adjustSharePlayerState(){
    this.sharePlayerState = this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode && this.otherRemoteOrListenerConnected();
  }

  private otherRemoteOrListenerConnected(): boolean{
    return this.zoneConnectionsService.activeZoneConnection && this.zoneConnectionsService.activeZoneConnection.externalApplicationsInfo.externalApplicationsInfo != null
          && (this.zoneConnectionsService.activeZoneConnection.externalApplicationsInfo.externalApplicationsInfo.filter(appInfo => appInfo.connectionType == ConnectionType.remote).length > 0
              || this.zoneConnectionsService.activeZoneConnection.externalApplicationsInfo.externalApplicationsInfo.filter(appInfo => appInfo.connectionType == ConnectionType.listener).length > 0);
  }

  private stopSharePlayerState$ = new Subject<void>();
  private _sharePlayerState = false;
  private set sharePlayerState(value: boolean){
    if (this._sharePlayerState != value){
      this._sharePlayerState = value;

      //add or remove listeners
      if (this._sharePlayerState){
        this.musicPlayerService.currentActiveAudioFileWithPlayInfo$
        .pipe(
          takeUntil(
            merge(
              this.stopSharePlayerState$,
              this.destroyed$
            )
          )
        )
        .subscribe(
          audioFileWithPlayInfo => {
            this.watchingSeekEventOfAudioFileWithPlayInfo = audioFileWithPlayInfo;
            this.sendCurrentAudioFile = true;
            this.sendCurrentProgress = true;
            this.delayedSendCurrentStateForRemotes();
          }
        );

        this.musicPlayerService.playerState$
        .pipe(
          takeUntil(
            merge(
              this.stopSharePlayerState$,
              this.destroyed$
            )
          )
        )
        .subscribe(
          () => {
            this.sendCurrentPlayMode = true;
            this.sendCurrentProgress = true; //so we have synced views after play starts or we are paused
            this.delayedSendCurrentStateForRemotes();
          }
        );

        this.audioTagService.needClickToStartAudio$
        .pipe(
          takeUntil(
            merge(
              this.stopSharePlayerState$,
              this.destroyed$
            )
          )
        )
        .subscribe(
          () => {
            this.sendCurrentPlayMode = true;
            this.sendCurrentProgress = true;
            this.delayedSendCurrentStateForRemotes();
          }
        )


        this.dataUpdateService.remoteNeedsToKnowPlayerState$
        .pipe(
          takeUntil(
            merge(
              this.stopSharePlayerState$,
              this.destroyed$
            )
          )
        )
        .subscribe(
          () => {
            this.sendCurrentAudioFile = true;
            this.sendCurrentPlayMode = true;
            this.sendCurrentProgress = true; //so we have synced views after play starts or we are paused
            this.delayedSendCurrentStateForRemotes();
          }
        );

        this.zonePresenceService.remoteJoins$
        .pipe(
          takeUntil(
            merge(
              this.stopSharePlayerState$,
              this.destroyed$
            )
          )
        )
        .subscribe(
          () => {
            this.sendCurrentAudioFile = true;
            this.sendCurrentPlayMode = true;
            this.sendCurrentProgress = true; //so we have synced views after play starts or we are paused
            this.delayedSendCurrentStateForRemotes();
          }
        );

        this.zonePresenceService.listenerJoins$
        .pipe(
          takeUntil(
            merge(
              this.stopSharePlayerState$,
              this.destroyed$
            )
          )
        )
        .subscribe(
          () => {
            this.sendCurrentAudioFile = true;
            this.sendCurrentPlayMode = true;
            this.sendCurrentProgress = true; //so we have synced views after play starts or we are paused
            this.delayedSendCurrentStateForRemotes();
          }
        );


      }else{
        this.stopSharePlayerState$.next();
      }
    }
  }
  private get sharePlayerState():boolean{
    return this._sharePlayerState;
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private cleanUpData(){
    this.clearDelayedSendCurrentStateForRemotesCalls();
  }

  private _watchingSeekEventOfAudioFileWithPlayInfo: AudioFileWithPlayInfo;
  private set watchingSeekEventOfAudioFileWithPlayInfo(audioFileWithPlayInfo: AudioFileWithPlayInfo){
    if (this._watchingSeekEventOfAudioFileWithPlayInfo !== audioFileWithPlayInfo){
      if (this._watchingSeekEventOfAudioFileWithPlayInfo != null){
        this._watchingSeekEventOfAudioFileWithPlayInfo.seekDone.off(this.seekOccurred);
      }

      this._watchingSeekEventOfAudioFileWithPlayInfo = audioFileWithPlayInfo;

      if (this._watchingSeekEventOfAudioFileWithPlayInfo != null){
        this._watchingSeekEventOfAudioFileWithPlayInfo.seekDone.on(this.seekOccurred);
      }
    }
  }
  private get watchingSeekEventOfAudioFileWithPlayInfo(): AudioFileWithPlayInfo{
    return this._watchingSeekEventOfAudioFileWithPlayInfo;
  }

  private seekOccurred = () =>{
    this.sendCurrentProgress = true;
    this.delayedSendCurrentStateForRemotes();
  }



  //we are delaying are messages to avoid to much trafic
  private sendCurrentStateTimeOut: NodeJS.Timeout;
  private delayedSendCurrentStateForRemotes(){
   this.clearDelayedSendCurrentStateForRemotesCalls();
    this.sendCurrentStateTimeOut = setTimeout(() => {
      this.sendCurrentStateForRemotes();
    }, 500);
  }

  private clearDelayedSendCurrentStateForRemotesCalls(){
    if (this.sendCurrentStateTimeOut){
      clearTimeout(this.sendCurrentStateTimeOut);
      this.sendCurrentStateTimeOut = null;
    }
  }

  private sendCurrentAudioFile = false;
  private sendCurrentPlayMode = false;
	private sendCurrentProgress = false;
  private sendCurrentStateForRemotes(){
    this.clearDelayedSendCurrentStateForRemotesCalls();
    if (this.sendCurrentAudioFile || this.sendCurrentPlayMode || this.sendCurrentProgress){
       const playerStatus = new PlayerStatusForRemoteSharing();
       playerStatus.current = new CurrentTrackInfoForRemoteSharing();

        if (this.sendCurrentAudioFile){
          if (this.musicPlayerService.currentActiveAudioFileWithPlayInfo && this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile){
            const currentAudioFile = this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile;

            playerStatus.current.track = new TrackForRemoteSharing();
            playerStatus.current.track.id = currentAudioFile.id;
            playerStatus.current.track.title = currentAudioFile.title;
            playerStatus.current.track.group = currentAudioFile instanceof AudioFile ?  currentAudioFile.group : "";
            playerStatus.current.track.duration = currentAudioFile.duration;
            playerStatus.current.track.type = currentAudioFile.type;

            if (currentAudioFile instanceof AudioFile){
              const track = currentAudioFile as AudioFile;
              playerStatus.current.track.origin = track.origin;
            }else if (currentAudioFile instanceof BrandMessage){
              playerStatus.current.track.origin = new TrackOrigin();
              playerStatus.current.track.origin.type = AudioFileOriginType.BRAND_AUDIO_MESSAGE;
              playerStatus.current.track.origin.data = '';
              playerStatus.current.track.origin.correlationId = '';
            }else{
              this.loggerService.error(this.LOGGER_CLASSNAME, 'sendCurrentStateForRemotes', 'playableAudio type not supported ' + currentAudioFile);
            }

            this.sendCurrentAudioFile = false;
          }else{
            playerStatus.current.track = new TrackForRemoteSharing();
            playerStatus.current.track.type = RemoteAudioType.none;
          }
        }

        if (this.sendCurrentPlayMode){
          if (this.musicPlayerService.playState === PlayState.PLAYING){
            if (this.audioTagService.needClickToStartAudio){
              playerStatus.current.playMode = PlayModeForRemoteSharing.startingPlay;
            }else{
              playerStatus.current.playMode = PlayModeForRemoteSharing.playing;
            }
          }else if (this.musicPlayerService.playState === PlayState.STARTING_TO_PLAY){
            playerStatus.current.playMode = PlayModeForRemoteSharing.startingPlay;
          }else if (this.musicPlayerService.playState === PlayState.PAUSED){
            playerStatus.current.playMode = PlayModeForRemoteSharing.pause;
          }else if (this.musicPlayerService.playState === PlayState.STOPPED){
            playerStatus.current.playMode = PlayModeForRemoteSharing.stopped;
          }
          this.sendCurrentPlayMode = false;
        }

        if (this.sendCurrentProgress){
          if (this.musicPlayerService.currentActiveAudioFileWithPlayInfo && this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile){
            playerStatus.current.progress = new ProgressForRemoteSharing();
            playerStatus.current.progress.currentTime = this.musicPlayerService.currentActiveAudioFileWithPlayInfo.currentTime * 1000;
            playerStatus.current.progress.totalTime = this.musicPlayerService.currentActiveAudioFileWithPlayInfo.duration * 1000;
            this.sendCurrentProgress = false;
          }else{
            playerStatus.current.progress = new ProgressForRemoteSharing();
            playerStatus.current.progress.currentTime = 0;
            playerStatus.current.progress.totalTime = 0;
          }
        }

        this.dataUpdateService.sendMessage('player-state', playerStatus);
      }else{
        //nothing to share
      }

  }

/*
  private connectRemoting(){
      if (this.authenticationService.zoneId != null){
          if (this.socket == null) {




              this.socket.on('player-state', (playerStatus, ack) => {
                  //update everything in the angular zone to trigger change detection
                  this.ngZone.run(() => {
                      var track;

                      if (playerStatus && playerStatus.current){

                          if (playerStatus && playerStatus.current.track){
                            track = null;
                              if (playerStatus.current.track.id){
                                track = new TrackForRemoteSharing();
                                track.id = playerStatus.current.audioFile.id;
                                track.title = playerStatus.current.audioFile.title;
                                track.group = playerStatus.current.audioFile.group;
                                track.duration = playerStatus.current.audioFile.duration;
                              }
                              this.remotePlayStateService.remoteTrackReceived(track);

                          }

                          if (playerStatus && playerStatus.current.thumbInfo) {
                              this.remotePlayStateService.remoteThumbsInfoReceived(playerStatus.current.thumbInfo);
                          }

                          if (playerStatus && playerStatus.current.playMode){
                              var playerStateString = playerStatus.current.playMode;
                              var playerState: PlayModeForRemoteSharing;
                              if (playerStateString === 'stopped'){
                                playerState = PlayModeForRemoteSharing.stopped;
                              }else if (playerStateString === 'playing'){
                                playerState = PlayModeForRemoteSharing.playing;
                              }else if (playerStateString === 'startingPlay'){
                                playerState = PlayModeForRemoteSharing.startingPlay;
                              }else if (playerStateString === 'pause'){
                                playerState = PlayModeForRemoteSharing.pause;
                              }

                              this.remotePlayStateService.remotePlayModeReceived(playerState);
                          }

                          if (playerStatus && playerStatus.current.progress){
                              this.remotePlayStateService.remoteProgressReceived(playerStatus.current.progress);
                          }
                      }
                  });
              });

              //remoteMode api calls
              this.socket.on('player-action', (playerAction, ack)=> {
                if (this.playTokenService.applicationMode === ApplicationMode.playerMode){
                  this.loggerService.debug(this.LOGGER_CLASSNAME, "socket.player-action", "received action: " + playerAction);

                  if (playerAction !== null && typeof(playerAction) === 'string'){
                    if (playerAction.toLowerCase() === "play"){
                      this.musicManipulationService.play();
                    }else if (playerAction.toLowerCase() === "pause"){
                      this.musicManipulationService.pause();
                    }else if (playerAction.toLowerCase() === "next"){
                      this.musicManipulationService.next();
                    }
                  }else{
                    this.loggerService.error(this.LOGGER_CLASSNAME, "socket.player-action", "playeraction is not a string");
                  }
                }else{
                  this.loggerService.error(this.LOGGER_CLASSNAME, "socket.player-action", "We received a player action but we are not in playerMode -> ignoring action");
                }
              });

              this.socket.on('adjust-thumbs', (thumbsInfo, ack)=> {
                if (this.playTokenService.applicationMode === ApplicationMode.playerMode){
                  var audioFileId = thumbsInfo.audioFileId;
                  var favorite = thumbsInfo.favorite;
                  var banned = thumbsInfo.banned;
                  this.loggerService.debug(this.LOGGER_CLASSNAME, "socket.adjust-thumbs", "adjust thumbs for " + audioFileId + " -> favorite: " + favorite + " & banned: " + banned);
                  this.loggerService.warn(this.LOGGER_CLASSNAME, "socket.adjust-thumbs", "We received an adjust thumbs, but this is not yet implemented");
                }else{
                  this.loggerService.error(this.LOGGER_CLASSNAME, "socket.player-action", "We received a player action but we are not in playerMode -> ignoring action");
                }
              });


              this.socket.on('share-player-state', () => {
                if (this.playTokenService.applicationMode === ApplicationMode.playerMode){
                  this.sendCurrentAudioFile = true;
                  this.sendCurrentPlayMode = true;
                  this.sendCurrentProgress = true;
                  this.sendCurrentThumbInfo = true;
                  this.sendCurrentStateForRemotes();
                }else{
                  this.loggerService.error(this.LOGGER_CLASSNAME, "socket.share-player-state", "We received a share player state action but we are not in playerMode -> ignoring message");
                }
              });



              //prelisten calls
              this.socket.on('prelisten-active-feedback', (prelistenDeviceConnected) => {
                  this.prelistenFeedbackSubject.next(prelistenDeviceConnected);
                  this.loggerService.debug(this.LOGGER_CLASSNAME, "socket.prelisten-active-feedback", "We received a prelisten connected state: " + prelistenDeviceConnected);
              });

              //remote track actions
              this.socket.on('track-action', (trackAction, ack)=> {
                if (this.playTokenService.applicationMode === ApplicationMode.playerMode){
                  var trackId = trackAction.trackId;
                  var command = trackAction.command;
                  let trackCommand = stringToTrackCommand(command);
                  if (trackCommand){
                    this.trackActionService.performTrackCommand(trackId, trackCommand);
                  }else{
                    this.loggerService.error(this.LOGGER_CLASSNAME, "socket.track-action", "command not recognized: " + command);
                  }
                  this.loggerService.debug(this.LOGGER_CLASSNAME, "socket.track-action", "received track command " + command + " for track " + trackId);
                }else{
                  this.loggerService.error(this.LOGGER_CLASSNAME, "socket.track-action", "We received a track action but we are not in playerMode -> ignoring action");
                }
              });

          }

          //console.log('connected: ' + this.socket.connected);

          // this.socket.connected is corrupt => we can't use it to test if we are really connected
          if (this.socket){
              this.loggerService.debug(this.LOGGER_CLASSNAME, 'connectRemote', 'socket is not yet connected. Performing connect.');
              this.socket.connect();
          }else{
              this.loggerService.debug(this.LOGGER_CLASSNAME, 'connectRemote', 'socket is already connected. Nothing to do.');
             this.loggerService.debug(this.LOGGER_CLASSNAME, "connectRemote", 'socket is already connected. Nothing to do.');
          }

      }else{
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'connectRemote', 'into connectRemote but no credentials present: going to disconnect\'');
          this.disconnectRemoting();
      }
  }

  */






  //send prelisten action
  public sendPrelistenAction(prelistenAction: PrelistenAction){
    this.dataUpdateService.sendPrelistenAction(prelistenAction);

  }

}
