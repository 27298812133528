<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M15.18,5.54C13.196,2.934,11.042,2,9.119,1.985C7.197,2,5.042,2.934,3.058,5.54C2.433,6.36,1.195,8.358,1.107,8.5
	c0.088,0.142,1.326,2.14,1.951,2.96C5.042,14.065,7.197,15,9.119,15.015c1.922-0.016,4.077-0.95,6.061-3.555
	c0.625-0.821,1.863-2.819,1.951-2.96C17.043,8.358,15.805,6.36,15.18,5.54z M14.306,8.5c0,2.864-2.322,5.187-5.187,5.187
	S3.933,11.364,3.933,8.5s2.322-5.187,5.187-5.187S14.306,5.635,14.306,8.5z"
  />
  <circle fill="currentColor" cx="9.119" cy="8.5" r="2.341" />
</svg>
