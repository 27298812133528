import { Component, OnInit, OnDestroy, AfterViewInit, NgZone, ViewChild, ChangeDetectorRef, ElementRef, Input, Sanitizer } from '@angular/core';
import { AppService } from '@service/app.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PopupService } from '@service/popup.service';
import { PopupPosition, PopupDirection } from '@components/popups/popup/enums';
import { SuggestionPopupComponent } from '@components/popups/suggestion-popup/suggestion-popup.component';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TrackIndexEvent } from '@model/events/trackIndexEvent';
import { LoggerService } from '@service/loggers/logger.service';
import { PlaylistComponent } from '@components/app-components/panels/playlist/playlist.component';
import { SongSorter } from '@components/app-components/fields/song-grid/song-sorter.pipe';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { PlaylistService } from '@service/playlist.service';
import { trigger } from '@angular/animations';

@Component({
  selector: 'tun-track-list-content',
  templateUrl: './track-list-content.component.html',
  styleUrls: ['./track-list-content.component.scss'],
  // memory leak work-around: add no-op animation in parent component of each songGrid. Otherwise, the @HostBinding('@fadeIn') animation causes the component to stay in memory.
  animations: [trigger("noop", [])]
})
export class TrackListContentComponent extends PlaylistComponent implements OnInit {

  readonly LOGGER_CLASSNAME = "TrackListContentComponent";

  // === Props === //
  @Input() showSuggestion = true;

  // === ViewChildren === ///
  //@ViewChild('tracksContainer', { static: true }) tracksContainer: any;


  // === State === //

  heightPerItem = 0;
  iconHeight = 0;
  iconPadding: SafeStyle = '';
  maxScrollbarHeight = 0;

  addToListIconColor = '#C8C8C8';
  sortIconColor = '#C8C8C8';
  deejayIconColor = '#C8C8C8';

  get songRequestsEnabled(){
    return environment.enableSongRequests;
  }

  constructor(
    appService: AppService,
    _songSorter: SongSorter,
    _zoneConfigurationService: ZoneConfigurationService,
    _popupService: PopupService,
    _musicManipulationService: MusicManipulationService,
    _playlistService: PlaylistService,
    _changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private loggerService: LoggerService
  ) {
    super(appService, _songSorter, _zoneConfigurationService, _popupService, _musicManipulationService, _playlistService, _changeDetectorRef);
  }

  ngOnInit() {
    this.appService.heightPerItem$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        height => {
          this.heightPerItem = height;
          this.iconHeight = height / 2 - 1;
          this.iconPadding = this.sanitizer.bypassSecurityTrustStyle(
            `calc(${height}px / 4) 0.75rem`
          );
        }
      );
  }

  toggleSuggestionPopup(showPopup: boolean) {
    if (showPopup) {
      this._popupService.showPopup$.next({
        connector: this.suggestionButton.elementRef,
        popupPosition: PopupPosition.BOTTOM_CENTER,
        componentType: SuggestionPopupComponent,
        showArrow: true,
        animationDuration: 300,
        dynamicPosition: true,
        popupDirection: PopupDirection.DOWN
      });
    } else {
      this._popupService.hidePopup$.next(this.suggestionButton.elementRef);
    }
  }

  onTrackIndexEvent(event: TrackIndexEvent){
    this.loggerService.error(this.LOGGER_CLASSNAME, "onTrackIndexEvent", "action " +  event.action + " not implemented");
  }
}
