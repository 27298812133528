import { Component, OnInit } from '@angular/core';
import { AppService } from '@service/app.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';

@Component({
  selector: 'tun-loading-view',
  templateUrl: './loading-view.component.html',
  styleUrls: ['./loading-view.component.scss']
})
export class LoadingViewComponent implements OnInit {

  get heightForHeader$(){
    return this._appService.heightForHeader$;
  }
  get heightPerItem$(){
    return this._appService.heightPerItem$;
  }

  get widthForMenu$(){
    return this._appService.widthForMenuPanel$;
  }

  get loading$(){
    return this._zoneConfigurationService.zoneConfigurationLoading$;
  }

  get loadingError$(){
    return this._zoneConfigurationService.zoneConfigurationError$;
  }


  constructor(
    private _appService: AppService,
    private _zoneConfigurationService:ZoneConfigurationService
  ) { }

  ngOnInit() {
  }

  retryClick(){
    this._zoneConfigurationService.loadZoneConfiguration();
  }

}
