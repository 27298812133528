import { Injectable } from '@angular/core';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { MusicManipulationService } from './music-manipulation.service';
import { ApplicationMode } from '@service/authentication/zone-connections.service';
import { AudioFile } from '../../model/audioFile';
import { RemoteActionsService } from './remote-actions.service';
import { LoggerService } from '../loggers/logger.service';
import { QueueService } from '../data/queue.service';
import { takeUntil, delay, filter } from 'rxjs/operators';
import { isFinal } from '../data/vo/asyncStatus';
import { stat } from 'fs';
import { AsyncStatus } from '@service/vo/asyncStatus';

/**
 * This service acts as a controller to perform the correct action for the current application mode
 */
@Injectable({
  providedIn: 'root'
})
export class TrackManipulationControllerService {

  private LOGGER_CLASSNAME = 'TrackManipulationControllerService';

  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private musicManipulationService: MusicManipulationService,
    private remoteActionsService: RemoteActionsService,
    private queueService: QueueService,
    private loggerService: LoggerService
  ) { }


  public playAudioFile(audioFile: AudioFile){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
      this.musicManipulationService.playAudioFile(audioFile);
    }else{
      if (this.zoneConnectionsService.currentRemotePlayerApplicationInfo != null){
        if (this.zoneConnectionsService.currentRemotePlayerApplicationInfo.listensToRemoteCommands){
          const notifier = this.remoteActionsService.remotePlayTrack(audioFile);
          notifier
            .pipe(
              takeUntil(
                notifier
                  .pipe(
                    delay(50),
                    filter(status => isFinal(status))
                  )
              )
            )
            .subscribe(
              (status) => {
                if (status == AsyncStatus.COMPLETED){
                  if (audioFile instanceof AudioFile){
                    this.queueService.removeAudioFileFromQueue(audioFile);
                    this.queueService.removeAudioFilesFromRadioQueue([audioFile]);
                  }
                }
              }
            )
          //remove the track from any queue -> normally this is done once the track is played, but this match is done at object level

        }else{
          this.loggerService.warn(this.LOGGER_CLASSNAME, "playAudioFile", "remote player does not listens to remote commands: not going to send play action");
        }
      }else{
        this.loggerService.warn(this.LOGGER_CLASSNAME, "playAudioFile", "No remote player active");
      }


    }
  }
}
