import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  ChangeDetectorRef
} from '@angular/core';
import { Util } from '@util/util';
import { OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { threadId } from 'worker_threads';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[tunScrollOverflow]'
})
export class ScrollOverflowDirective implements OnInit, OnDestroy {
  private _isMouseIn = false;
  private _transitionSpeed = 0;

  private _overflow = 0;
  private fieldHoverTimeout: NodeJS.Timeout;
  private _animatingOut = false;

  @HostBinding('class.text-ellipsis')
  get hasTextEllipsis() {
    return !this._isMouseIn && !this._animatingOut;
  }

  @HostBinding('style.margin-left.px')
  scrollMargin = 0;

  @HostBinding('style.transition')
  transition = 'margin 0ms linear 0s';

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {

  }

  public ngOnInit(){
    fromEvent(this.elementRef.nativeElement, 'mouseenter')
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      () => {
        this.onHover();
      }
    );

    fromEvent(this.elementRef.nativeElement, 'mouseleave')
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      () => {
        this.onStopHover();
      }
    );
  }

  private destroyed$ = new Subject<void>();
  public ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private mouseEnterDate: Date;
  private onHover() {
    clearTimeout(this.fieldHoverTimeout);
    this._animatingOut = false;

    const offsetWidth = this.elementRef
      ? this.elementRef.nativeElement.offsetWidth
      : 0;
    const scrollWidth = this.elementRef
      ? this.elementRef.nativeElement.scrollWidth
      : 0;

    // we need to keep these in variables because they are changed by the animation
    this._overflow = scrollWidth - offsetWidth;
    if (this._overflow > 0) {
      this.scrollMargin = (this._overflow + 1) * -1; // +1 for rounding problems
      this._transitionSpeed = Util.getOverflow(this.elementRef) * 12;
      this.transition = `margin ${this._transitionSpeed}ms linear 0s`;
      this._isMouseIn = true;
      this.mouseEnterDate = new Date();
      this.changeDetectorRef.detectChanges();
    }
  }

  private onStopHover() {
    if (this.scrollMargin != 0) {
      // calculate the percentage that has animated
      const hoveredTime = new Date().getTime() - this.mouseEnterDate.getTime();
      const realTransitionPercentage =
        Math.min(this._transitionSpeed, hoveredTime) / this._transitionSpeed;

      this._transitionSpeed = this._overflow * 3 * realTransitionPercentage;
      this.transition = `margin ${this._transitionSpeed}ms linear 0s`;
      // this.changeDetectorRef.detectChanges();
      this._animatingOut = true;
      this._isMouseIn = false;
      this.scrollMargin = 0;
      // this.changeDetectorRef.detectChanges();
      this.fieldHoverTimeout = setTimeout(() => {
        this._overflow = 0; // reset
        this._animatingOut = false;
        this._transitionSpeed = 0;
        this.transition = 'margin 0ms linear 0s';
        this.changeDetectorRef.detectChanges();
      }, this._transitionSpeed);
    }
  }
}
