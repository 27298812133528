import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Playlist } from '@model/playlist';

@Component({
  selector: 'tun-my-lists-popup-row',
  templateUrl: './my-lists-popup-row.component.html',
  styleUrls: ['./my-lists-popup-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyListsPopupRowComponent {
  // === Props === //
  @Input() title: string;
  @Input() isSearchEnabled: boolean;
  @Input() isAddToQueueEnabled: boolean;

  // === Emitters === //
  @Output()
  search = new EventEmitter<Playlist>();

  @Output()
  select = new EventEmitter<Playlist>();

  onSearch() {
    this.search.emit();
  }

  onSelectPlaylist() {
    this.select.emit();
  }
}
