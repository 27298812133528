<div class="orange-sub-menu">
  <div
    class="title-container"
    [style.width.px]="widthForMenuPanel"
    [style.height.px]="heightPerItem">

    <tun-icon-button
      [height]="heightPerItem"
      (click)="goBack()"
    >
      <tun-back-arrow-icon></tun-back-arrow-icon>
    </tun-icon-button>

    <div class="title">
      {{ title }}
    </div>
  </div>
  <div class="content">
    <tun-navigation-item-view
      *ngFor="let item of navigationItems"
      [tunifyColor]="tunifyColor"
      [heightPerItem]="heightPerItem"
      [navigationItem]="item"
      [mainNavigationItemIndex]="0"
      (selectItem)="onItemClicked($event)"
      (createItemWithTitle)="onCreateItemWithTitle($event)">
    </tun-navigation-item-view>
  </div>
</div>
