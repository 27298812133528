import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { CheckBoxColor } from '@model/fieldModels/checkboxColor';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';

@Component({
  selector: 'tun-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  // === Props === //
  @Input() title: string;
  @Input() isChecked: boolean;
  @Input() checkBoxColor: CheckBoxColor;
  @Input() isShuffleCheckbox = false;
  @Input() shuffleIconColors: MusicPlayAnimationColors;

  // === Emitters === //
  @Output() isCheckedChanged = new EventEmitter();

  checkboxColor: string;
  colorAdjustment: string;

  constructor(
    public cdRef: ChangeDetectorRef,
    public ngZone: NgZone,
    private _activeMusicSelectionService: ActiveMusicSelectionService
  ) {}

  ngOnInit() {
    this.colorAdjustment =
      this.checkBoxColor === CheckBoxColor.GREY ? '' : 'darken';

    this.checkboxColor = this._getColorClass(
      this._activeMusicSelectionService.selectedPlayerView
    );
  }

  toggleChecked() {
    this.isChecked = !this.isChecked;
    this.isCheckedChanged.emit(this.isChecked);
  }

  // === Private methods === //
  private _getColorClass(tunifyColor: TunifyColor): string {
    switch (tunifyColor) {
      case TunifyColor.BLUE:
        return 'blue-checkbox';
      case TunifyColor.GREEN:
        return 'green-checkbox';
      case TunifyColor.ORANGE:
        return 'orange-checkbox';
      default:
        return 'blue-checkbox';
    }
  }
}
