<button
  class="search-icon popup-button"
  (click)="onSearch()"
  *ngIf="isSearchEnabled"
>
  <tun-search-icon class="icon search"></tun-search-icon>
</button>
<button class="add-to-queue popup-button" (click)="onSelectPlaylist()">
	<tun-add-to-queue-icon class="icon queue" *ngIf="isAddToQueueEnabled"></tun-add-to-queue-icon>
	<ng-content select=".icon"></ng-content>
  <div class="title-container">
    <span class="title" tunScrollOverflow>
      {{ title }}
    </span>
  </div>
</button>
