<div fxFlex fxLayout="column" class="flexBoxMinimumSizing">
  <div
    class="headerBarBackground blackBottomBorder"
    [style.min-height.px]="heightPerItem - 1"
  ></div>
  <div
    fxFlex
    fxLayout="column"
    class="lightRasterBackground flexBoxMinimumSizing"
  >
    <div class="container" (tunResize)="onResizeContainer($event)" #container>
      <!-- <tun-changeable-parameters [changeableParameters]="params"></tun-changeable-parameters> -->
      <tun-music-category-block-grid
        *ngIf="sizeIsOk"
        [musicChannel]="musicChannel"
        [heightPerItem]="heightPerItem"
        [showPlayAnimationForMusicCollectionId]="
          currentAudioFileFromMusicCollectionWithId
        "
        [playing]="playing"
        [audioFileProperty]="audioFileProperty"
        (trackEvent)="onTrackEvent($event)"
        (tracksEvent)="onTracksEvent($event)"
        (musicCollectionEvent)="onMusicCollectionEvent($event)"
      ></tun-music-category-block-grid>
    </div>
  </div>
</div>
