<tun-popup-header (close)="closePopup()">
  {{ title | translate}}
</tun-popup-header>

<div class="content">
  <tun-my-lists-popup-content
    [status]="status"
    [favorites]="favorites$ | async"
    [playlists]="playlists$ | async"
    [isSearchEnabled]="isSearchEnabled"
    [isAddToQueueEnabled]="isAddToQueueEnabled"
    (search)="onSearch($event)"
    (select)="onSelectPlaylist($event)"
		(newplaylist)="onNewPlaylist($event)"
  >
		<tun-my-lists-popup-row 
		class="top"
		title="{{'general.queue' | translate}}"
		*ngIf="!isAddToQueueEnabled"
		(select)="onAddToQueue($event)"
		>
			<tun-queue-icon class="icon"></tun-queue-icon>		
		</tun-my-lists-popup-row>
  </tun-my-lists-popup-content>
</div>
