import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { PlaylistComponent } from '@components/app-components/panels/playlist/playlist.component';
import { AppService } from '@service/app.service';
import { PopupService } from '@service/popup.service';
import { SongSorter } from '@components/app-components/fields/song-grid/song-sorter.pipe';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { TrackIndexEvent } from '@model/events/trackIndexEvent';
import { PlaylistService } from '@service/playlist.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { trigger } from '@angular/animations';

@Component({
  selector: 'tun-editable-playlist',
  templateUrl: './editable-playlist.component.html',
  styleUrls: ['./editable-playlist.component.scss'],
  // memory leak work-around: add no-op animation in parent component of each songGrid. Otherwise, the @HostBinding('@fadeIn') animation causes the component to stay in memory.
  animations: [trigger("noop", [])]
})
export class EditablePlaylistComponent extends PlaylistComponent
  implements OnInit {
  // === Props === //
  @Input() inEditMode: boolean;

  // === Emitters === //
  @Output() edit = new EventEmitter<void>();
  @Output() trackindex = new EventEmitter<TrackIndexEvent>();

  // === State === //
  enableTransition: boolean;

  constructor(
    appService: AppService,
    _songSorter: SongSorter,
    _zoneConfigurationService: ZoneConfigurationService,
    _popupService: PopupService,
    _musicManipulationService: MusicManipulationService,
    _playlistService: PlaylistService,
    _changeDetectorRef: ChangeDetectorRef
  ) {
    super(
      appService,
      _songSorter,
      _zoneConfigurationService,
      _popupService,
      _musicManipulationService,
      _playlistService,
      _changeDetectorRef
    );
  }

  ngOnInit() {}

  // === Event handlers === //

  toggleEditMode() {
    this.enableTransition = true;
    this.edit.emit();
    this.hidePopups();
  }

  onTrackIndexEvent(trackIndexEvent: TrackIndexEvent) {
    this.trackindex.next(trackIndexEvent);
  }
}
