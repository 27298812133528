import { Component} from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from '@service/app.service';
import { IntercomService } from '../../../services/app/intercom.service';

@Component({
  selector: 'tun-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent{

  get heightForTopBar$(){
    return this._appService.heightForTopBar$;
  }

  get enableIntercomButton(){
    return environment.enableIntercomMessenger;
  }

  public get unreadIntercomMessages$(){
    return this._intercomService.unreadMessages$;
  }

  constructor(
    private _intercomService: IntercomService,
    private _appService: AppService
    ) { }
}
