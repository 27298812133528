import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { MusicChannelService } from '@service/music-channel.service';
import { MusicChannelGroup } from 'src/app/model/musicChannelGroup';
import { LoggerService } from '@service/loggers/logger.service';

import { MusicChannel } from 'src/app/model/musicChannel';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { MainNavigationItem } from '@components/app-components/menu/navigation-menu/model/MainNavigationItem';
import { NavigationItem } from '@components/app-components/menu/navigation-menu/model/NavigationItem';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { SearchService } from '@service/search.service';
import { takeUntil, map } from 'rxjs/operators';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { RecommendationsMusicChannel, RecommendationsService } from '../../../../../../services/data/recommendations.service';
import { Analytics } from '@angular/fire/analytics';
import { logEvent } from '@firebase/analytics';
import { RealUserMonitorService } from '@service/real-user-monitor.service';

@Component({
  selector: 'tun-music-channel-menu',
  templateUrl: './music-channel-menu.component.html',
  styleUrls: ['./music-channel-menu.component.scss']
})
export class MusicChannelMenuComponent implements OnInit, OnDestroy {

  private LOGGER_CLASSNAME = 'MusicChannelMenuComponent';

  public mainNavigationItems:MainNavigationItem[] = [];

  public helpMessage:string = null;

  public get loadingMenuContent$(){
    return this.musicChannelService.loading$;
  }

  public get loadingError$(){
    return this.musicChannelService.loadingError$.pipe(map(error => error != null));
  }


  private _recommendationsMainNavigationItem : MainNavigationItem;
  private get recommendationsMainNavigationItem() : MainNavigationItem{
    if (this._recommendationsMainNavigationItem == null){
      this._recommendationsMainNavigationItem = new MainNavigationItem();
      this._recommendationsMainNavigationItem.tunifyColor = TunifyColor.BLUE;
      this._recommendationsMainNavigationItem.title = "musicChannels.menu.item.recommendations";
      this._recommendationsMainNavigationItem.titleNeedsTranslation = true;
      this._recommendationsMainNavigationItem.showAmountItems = true;
    }
    return this._recommendationsMainNavigationItem;
  }

  @Input() public heightPerItem = 0;

  constructor(
    private loggerService: LoggerService,
    private recommendationsService: RecommendationsService,
    private musicChannelService:MusicChannelService,
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private searchService: SearchService,
    private realUserMonitorService: RealUserMonitorService
  )
  {


  }

  /* musicChannelGroups property */
  private _musicChannelGroups: MusicChannelGroup[];
  private set musicChannelGroups(value: MusicChannelGroup[]){
    this._musicChannelGroups = value;
    this.createMainNavigationItems();
  }
  private get musicChannelGroups():MusicChannelGroup[]{
    return this._musicChannelGroups;
  }

  private oneMainNavigationIsOpen = false;
  //create mainNavigationItems and navigationItems based on the musicChannelGroups model
  private createMainNavigationItems(){
    const newMainNavigationItems = [];
    this.oneMainNavigationIsOpen = false;
    //special item for recommendations
    if (this.recommendationsService.recommendedMusicChannels && this.recommendationsService.recommendedMusicChannels.length > 0){
      this.recommendationsMainNavigationItem.items = [];
      this.recommendationsService.recommendedMusicChannels.forEach(recommendedMusicChannel => {
        const navigationItem = new NavigationItem();
          navigationItem.originalData = recommendedMusicChannel;
          navigationItem.translateableTitlePrefix = 'musicChannels.menu.item.titlePrefix.discover';
          navigationItem.showTitleAsLink = true;
          navigationItem.isSelecteable = false;
          navigationItem.title = recommendedMusicChannel.musicChannel.name;
          this.recommendationsMainNavigationItem.items.push(navigationItem);
      });

      //The info item
      const navigationItem = new NavigationItem();
      navigationItem.showAsInfoItem = true;
      navigationItem.translateableInfoText = 'musicChannels.menu.item.recommendation.info';
      navigationItem.isSelecteable = false;
      this.recommendationsMainNavigationItem.items.push(navigationItem);

      newMainNavigationItems.push(this.recommendationsMainNavigationItem);
    }

    if (this.musicChannelGroups){

      this.musicChannelGroups.forEach(musicChannelGroup => {
        const mainNavigationItem = new MainNavigationItem();
        mainNavigationItem.tunifyColor = TunifyColor.BLUE;
        mainNavigationItem.title = musicChannelGroup.name;

        mainNavigationItem.items = [];
        musicChannelGroup.musicChannels.forEach(musicChannel => {
          const navigationItem = new NavigationItem();
          navigationItem.originalData = musicChannel;
          navigationItem.title = musicChannel.name;
          mainNavigationItem.items.push(navigationItem);
        });
        newMainNavigationItems.push(mainNavigationItem);
      });
    }

    this.mainNavigationItems = newMainNavigationItems;

    this.adjustSelectedMusicChannel();

  }

  //adjust the open mainNavigationItem and the selected navigationItems to the selected musicChannel
  private adjustSelectedMusicChannel(){
    if (this.mainNavigationItems && this.mainNavigationItems.length > 0){
      this.mainNavigationItems.forEach(mainNavigationItem=> {
        mainNavigationItem.items.forEach(navigationItem =>{
          if (navigationItem.originalData instanceof MusicChannel){
            navigationItem.selected = this.activeMusicSelectionService.selectedMusicChannel && navigationItem.originalData.id == this.activeMusicSelectionService.selectedMusicChannel.id;

            //open the main navigation item of the selected item
            if (navigationItem.selected){
              this.onSelectMainNavigationItem(mainNavigationItem);
            }

          }else if (navigationItem.originalData == null || navigationItem.originalData instanceof RecommendationsMusicChannel) {
            //this.loggerService.debug(this.LOGGER_CLASSNAME, "adjustSelectedMusicChannel", "we should not select a recommendation");
          }else{
            this.loggerService.error(this.LOGGER_CLASSNAME, "adjustSelectedMusicChannel", "item not recognized: " + navigationItem);
          }
        });
      });

      //if no items are open -> open the first one that is not the recommendations
      if (!this.oneMainNavigationIsOpen){
        let indexToOpen = 0;
        while (indexToOpen < this.mainNavigationItems.length && this.mainNavigationItems[indexToOpen] == this.recommendationsMainNavigationItem){
          indexToOpen++;
        }
        if (indexToOpen < this.mainNavigationItems.length){
          this.onSelectMainNavigationItem(this.mainNavigationItems[indexToOpen]);
        }

      }

    }
  }

  //if no item is selected and we are fully loaded => show popup with info
  private adjustInfoPopup(){
    if (this.activeMusicSelectionService.selectedMusicChannel == null && !this.activeMusicSelectionService.selectedMusicChannelLoading){
      this.helpMessage = "menuPanel.musicChannel.helpPopup.select";
    }else{
      this.helpMessage = null;
    }
  }

  ngOnInit() {

    //when we show the menu -> check if our data needs to be reloaded for error
    this.onReloadMenuContent();

    //listen for the selected musicChannel first so we work with the correct value once we create the components
    this.activeMusicSelectionService.selectedMusicChannel$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.adjustSelectedMusicChannel();
        this.adjustInfoPopup();
      }
    );

    this.musicChannelService.musicChannelGroups$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (musicChannelGroups) => {
        this.musicChannelGroups = musicChannelGroups;
      }
    );

    this.recommendationsService.recommendedMusicChannels$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (recommendedMusicChannels) => {
        this.createMainNavigationItems();
      }
    );

    this.activeMusicSelectionService.selectedMusicChannelLoading$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.adjustInfoPopup();
      }
    );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;

    this.mainNavigationItems = null;
  }



  public onSelectMainNavigationItem(mainNavigationItemToSelect: MainNavigationItem){

    if (mainNavigationItemToSelect ==  this.recommendationsMainNavigationItem){
      //Real User Monitoring
      this.realUserMonitorService.logEvent("recommendation", "openMainItem", {"isAlreadyOpen":  mainNavigationItemToSelect.open + "",})
    }

    this.mainNavigationItems.forEach(mainNavigationItem => {
       mainNavigationItem.open = mainNavigationItem == mainNavigationItemToSelect;
    });
    this.oneMainNavigationIsOpen = true;


  }

  public onSelectNavigationItem(navigationItem:NavigationItem){
    //select it as the current Playing item
    //let origin:MusicChannel = navigationItem.originalData as MusicChannel;
    //let test2:MusicChannel = new MusicChannel();

    //this.loggerService.debug(this.LOGGER_CLASSNAME, "onSelectNavigationItem", "typeOf origin: " + (origin instanceof MusicChannel?"true":"false") + " -- typeof test2: " + (test2 instanceof MusicChannel?"true":"false"));

    if (navigationItem.originalData instanceof MusicChannel){
      this.activeMusicSelectionService.changeActiveMusicChannel(navigationItem.originalData);
      this.searchService.closeSearch();
    }else if (navigationItem.originalData instanceof RecommendationsMusicChannel){
      this.activeMusicSelectionService.changeActiveMusicChannel((navigationItem.originalData as RecommendationsMusicChannel).musicChannel);
      this.searchService.closeSearch();
      this.adjustSelectedMusicChannel();

      //Real User Monitoring
      const musicChannel = (navigationItem.originalData as RecommendationsMusicChannel).musicChannel
      this.realUserMonitorService.logEvent("recommendation", "startMusicChannel", {"musicChannel_id": musicChannel.id + "", "musicChannel": musicChannel.name})
    }
  }

  public onReloadMenuContent(){
    if (this.musicChannelService.loadingError != null){
      this.musicChannelService.loadMusicChannelGroups();
    }
  }
}
