<div fxFlex fxLayout="column" class="flexBoxMinimumSizing">

  <tun-player-panel [style.height.px]="heightForHeader$ |async"
                    [tunifyColor]="tunifyColor$ |async"
  >
  </tun-player-panel>

  <tun-queue-panel fxFlex [heightPerItem]="heightPerItem$ |async" class="flexBoxMinimumSizing"> </tun-queue-panel>

  <tun-radio-queue-panel class="animated"
    [style.height.px]="heightForRadioQueue$ |async"
    [heightPerItem]="heightPerItem$ |async"
    [tunifyColor]="tunifyColor$ |async"
  >
  </tun-radio-queue-panel>
</div>
