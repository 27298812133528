import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tun-add-to-list-icon',
  templateUrl: './add-to-list-icon.component.html',
  styleUrls: ['./add-to-list-icon.component.scss']
})
export class AddToListIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
