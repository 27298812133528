<div fxFlex fxLayout="column" class="flexBoxMinimumSizing">

  <div
    class="headerBarBackground blackBottomBorder"
    fxFlex="0 0 0"
    fxLayout="row"
    [style.min-height.px]="heigthForTitle$ | async"
  >
    <tun-icon-button
      tunTooltipOnHover
      [tooltipText]="'tooltip.trackList.addAllToQueue'"
      [checkAccessToAddToQueue]="true"
      [height]="heigthForTitle$ | async"
      (clickWithAccess)="onAddToQueueClick()"
    >
      <tun-add-to-queue-icon></tun-add-to-queue-icon>
    </tun-icon-button>

    <tun-icon-button
      tunTooltipOnHover
      [tooltipText]="'tooltip.trackList.sort'"
      [height]="heigthForTitle$ | async"
      tunSongSortOnClick
      (songSortModeChange)="onSongSortModeChange($event)"
    >
      <tun-sort-icon></tun-sort-icon>
    </tun-icon-button>
    <div

      *ngIf="songRequestsEnabled && showSuggestion"
    >
      <tun-icon-button
      #suggestionButton
      tunPopupOnClick
      [closeOnPopupClick]="false"
      [closePopupAfterMouseLeave]="false"
      (togglepopup)="toggleSuggestionPopup($event)"
      [height]="heigthForTitle$ | async">
        <tun-suggestion-icon></tun-suggestion-icon>
      </tun-icon-button>
    </div>
  </div>

  <div
    #tracksContainer
    fxFlex
    class="layerContainer lightRasterBackground flexBoxMinimumSizing"
  >
  <ng-container [ngSwitch]="playlistState">
    <tun-song-grid
      *ngSwitchCase="PlaylistState.tracks"
      [playlist]="audioFiles"
      [songSortMode]="songSortMode"
      [showPopupDelete]="showPopupDelete"
      (trackIndexEvent)="onTrackIndexEvent($event)"
      (trackEvent)="onTrackEvent($event)"
      borderColor="#171C1D"
      [maxScrollbarHeight]="
        (availableHeightForPlayer$ | async) -
        (heightPerItem$ | async) -
        (heightForHeader$ | async) -
        (heightForTopBar$ | async) * 2
      "
      class="layer"
    ></tun-song-grid>

    <ng-container *ngSwitchCase="PlaylistState.loading">
      <tun-loader class="layer" text="search.feedback.busy"></tun-loader>
    </ng-container>

    <div  *ngSwitchCase="PlaylistState.empty"
      class="layer feedback-container">
      <p translate>
        search.feedback.empty
      </p>
    </div>

  </ng-container>
  </div>
</div>

<!--
<ng-template #songGrid>
  <tun-song-grid
    *ngIf="trackList"
    [maxScrollbarHeight]="maxScrollbarHeight"
    borderColor="#171C1D"
    [playlist]="trackList"
    [songSortMode]="songSortMode"

    (trackEvent)="onTrackEvent($event)"
  >
  </tun-song-grid>
</ng-template>

-->
