export class MusicCategoryBlockColors {
  _containerBorderColor: string;
  _labelBackgroundGradientStartColor: string;
  _labelBackgroundGradientEndColor: string;
  _labelBackgroundGradientHoverStartColor: string;
  _labelBackgroundGradientHoverEndColor: string;
  _labelBorderColor: string;
  _labelBorderRightColor: string;
  _iconBackgroundColor: string;
  _iconBackgroundHoverColor: string;
  _iconBorderColor: string;
  _iconColor: string;
  _textColor: string;

  containerBorderColor = (color: string): MusicCategoryBlockColors => {
    this._containerBorderColor = color;
    return this;
  }

  labelBackgroundGradientStartColor = (
    color: string
  ): MusicCategoryBlockColors => {
    this._labelBackgroundGradientStartColor = color;
    return this;
  }

  labelBackgroundGradientEndColor = (
    color: string
  ): MusicCategoryBlockColors => {
    this._labelBackgroundGradientEndColor = color;
    return this;
  }

  labelBackgroundGradientHoverStartColor = (
    color: string
  ): MusicCategoryBlockColors => {
    this._labelBackgroundGradientHoverStartColor = color;
    return this;
  }

  labelBackgroundGradientHoverEndColor = (
    color: string
  ): MusicCategoryBlockColors => {
    this._labelBackgroundGradientHoverEndColor = color;
    return this;
  }

  labelBorderColor = (color: string): MusicCategoryBlockColors => {
    this._labelBorderColor = color;
    return this;
  }

  labelBorderRightColor = (color: string): MusicCategoryBlockColors => {
    this._labelBorderRightColor = color;
    return this;
  }

  iconBackgroundColor = (color: string): MusicCategoryBlockColors => {
    this._iconBackgroundColor = color;
    return this;
  }

  iconBackgroundHoverColor = (color: string): MusicCategoryBlockColors => {
    this._iconBackgroundHoverColor = color;
    return this;
  }

  iconBorderColor = (color: string): MusicCategoryBlockColors => {
    this._iconBorderColor = color;
    return this;
  }

  iconColor = (color: string): MusicCategoryBlockColors => {
    this._iconColor = color;
    return this;
  }

  textColor = (color: string): MusicCategoryBlockColors => {
    this._textColor = color;
    return this;
  }
}
