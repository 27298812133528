import { Directive, ElementRef, Input, SimpleChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tunScrollBarVisible]'
})

export class ScrollBarVisibleDirective {

  constructor(
    private elr: ElementRef,
    private renderer: Renderer2
  ) {}

  @Input('tunScrollBarVisible') isVisible: boolean;

  ngOnInit() {
    this.setPadding();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.setPadding();
    }
  }

  private setPadding() {
    const paddingRight = this.isVisible ? '1.2rem' : 0;
    this.renderer.setStyle(this.elr.nativeElement.querySelector('.ps-content'), 'padding-right', paddingRight);
  }
}
