<div class="container">
  <!-- Favorites & playlists -->
  <tun-my-lists-popup-content
    [status]="status"
    [favorites]="favorites"
    [playlists]="playlists"
    (select)="onSelectPlaylist($event)"
		(newplaylist)="onNewPlaylist($event)"
  >
    <!-- Queue -->
    <tun-my-lists-popup-row
      title="{{'general.queue' | translate}}"
      class="top"
      (select)="onListOptionClick(0)"
    >
			<tun-queue-icon class="icon"></tun-queue-icon>
    </tun-my-lists-popup-row>
  </tun-my-lists-popup-content>
</div>
