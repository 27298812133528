import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tun-similar-icon',
  templateUrl: './similar-icon.component.html',
  styleUrls: ['./similar-icon.component.scss']
})
export class SimilarIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
