import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { TweakStatus } from '@model/enums/tweakStatus';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

interface TweakStrengthBar {
  selected?: boolean;
}

@Component({
  selector: 'tun-tweakstrength-bar',
  templateUrl: './tweakstrength-bar.component.html',
  styleUrls: ['./tweakstrength-bar.component.scss']
})
export class TweakstrengthBarComponent implements AfterViewInit, OnDestroy {
  // === Constants === //
  /** Adjust amount of bars relative to window width here */
  readonly WINDOW_WIDTH_PERCENTAGE = 0.015;

  // === Props === //
  @Input() set percentage(value: number) {
    if (this._amountOfBars === 0) {
      this._amountOfBars = Math.floor(
        window.innerWidth * this.WINDOW_WIDTH_PERCENTAGE
      );
    }

    if (value > 0) {
      const selectedBars = Array<TweakStrengthBar>(
        Math.floor(this._amountOfBars * (value / 100))
      ).fill({ selected: true });
      this._tweakStrengthBars = selectedBars.concat(
        Array(this._amountOfBars - selectedBars.length).fill({})
      );
    } else {
      this._tweakStrengthBars = Array(this._amountOfBars).fill({});
    }
  }

  @Input() status = TweakStatus.NORMAL;

  // === State === //
  private _amountOfBars = 0;
  private _tweakStrengthBars: TweakStrengthBar[] = [];

  // === Observables === //
  private _destroy$ = new Subject();

  // === Getters === //
  get tweakStrengthBars() {
    return this._tweakStrengthBars;
  }

  get statusClass(): string {
    switch (this.status) {
      case TweakStatus.WARNING:
        return 'warning';
      case TweakStatus.ERROR:
        return 'error';
      default:
        return 'normal';
    }
  }

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this._amountOfBars = Math.floor(
      window.innerWidth * this.WINDOW_WIDTH_PERCENTAGE
    );
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(300),
        map(({ target: window }) => (<Window>window).innerWidth),
        takeUntil(this._destroy$)
      )
      .subscribe(width => {
        this._amountOfBars = Math.floor(width * this.WINDOW_WIDTH_PERCENTAGE);
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
  }
}
