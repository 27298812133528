import { Injectable, OnDestroy } from '@angular/core';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicPlayerService } from './music-player.service';
import { BehaviorSubject, Observable, Subject, timer, merge, combineLatest } from 'rxjs';
import { AudioFile } from '@model/audioFile';
import { QueueService } from '@service/queue.service';
import { AudioFilePlayState } from '@service/vo/audioFilePlayState';
import { Song } from '@model/song';
import { BufferService } from './buffer.service';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { takeUntil} from 'rxjs/operators';
import { AuthenticationService } from '@service/authentication.service';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { BrandMessageQueueService } from '../data/brand-message-queue.service';
import { PlayableAudio } from '../../model/audioFile';
import { BrandMessage } from '../../model/brandMessage';
import { ZoneConnectionsService, ApplicationMode } from '../authentication/zone-connections.service';

/**
 * This service is responsible for keeping track of the playstate of the current audioFile.
 *
 * This service pushes audioFilePlayState changes to musicPlayerService.
 *
 * This service will only change the play mode to playing if we are actually in play mode.
 *
 */

 export enum ActivePlayer{
  activePlayerLeft = "activePlayerLeft",
  activePlayerRight = "activePlayerRight"
 }

@Injectable({
  providedIn: 'root'
})
export class PlayStateService implements OnDestroy {
  private LOGGER_CLASSNAME = 'PlayStateService';

  private _currentAudioFilePlayState: AudioFilePlayState;
  private set currentAudioFilePlayState(
    audioFilePlayState: AudioFilePlayState
  ) {
    if (this._currentAudioFilePlayState != audioFilePlayState) {
      this._currentAudioFilePlayState = audioFilePlayState;
      this.loggerService.debug(this.LOGGER_CLASSNAME, "set currentAudioFilePlayState", "Adjust to " + this.currentAudioFilePlayState);

      this.syncPlayState();
    }
  }
  private get currentAudioFilePlayState(): AudioFilePlayState {
    return this._currentAudioFilePlayState;
  }

  private syncPlayState(){
    //push to musicPlayerService if we are in playmode
    if (this.zoneConnectionsService.applicationMode === ApplicationMode.playerMode){
      this.musicPlayerService.adjustToPlayState(this._currentAudioFilePlayState);
    }
  }


  /**
   * The active player (used in tunify Orange)
   */
  private get _activePlayer():ActivePlayer {
    return this._activePlayerSubject.value;
  }
  private set _activePlayer(value:ActivePlayer) {
    if (this._activePlayer != value){
        this._activePlayerSubject.next(value);
    }
  }
  get activePlayer():ActivePlayer{
    return this._activePlayer;
  }
  private _activePlayerSubject:BehaviorSubject<ActivePlayer> = new BehaviorSubject<ActivePlayer>(null);
  public activePlayer$:Observable<ActivePlayer> = this._activePlayerSubject.asObservable();


  /**
   * The next playableAudio
   */
  private set _nextPlayableAudio(value: PlayableAudio) {
    if (this._nextPlayableAudio !== value) {
      this._nextPlayableAudioSubject.next(value);

      //use fadepositions when a next playable audio is available
      this.musicPlayerService.useFadeOutPosition = value != null;
    }
  }
  private get _nextPlayableAudio(): PlayableAudio {
    return this._nextPlayableAudioSubject.value;
  }
  public get nextPlayableAudio(): PlayableAudio {
    return this._nextPlayableAudio;
  }
  private _nextPlayableAudioSubject: BehaviorSubject<PlayableAudio> = new BehaviorSubject<PlayableAudio>(null);
  public nextPlayableAudio$: Observable<PlayableAudio> = this._nextPlayableAudioSubject.asObservable();


  private _destroyed$ = new Subject();
  constructor(
    private loggerService: LoggerService,
    private musicPlayerService: MusicPlayerService,
    private queueService: QueueService,
    private bufferService: BufferService,
    private zoneConnectionsService: ZoneConnectionsService,
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private _authenticationService: AuthenticationService,
    private brandMessageQueueService: BrandMessageQueueService
  ) {

    this.musicPlayerService.waitingForAudioFile$
    .pipe(
      takeUntil(this._destroyed$)
    )
    .subscribe(
      waitingForAudioFile => {
        this.waitingForAudioFile = waitingForAudioFile;
        if (this.waitingForAudioFile) {
          this.startNextAudioFile(null, false);
        }
      }
    );

    this.queueService.queueLoadingDone$
    .pipe(
      takeUntil(this._destroyed$)
    )
    .subscribe(value => {
      if (value && this.waitingForAudioFile) {
        this.startNextAudioFile(null, false);
      }
    });

    this.queueService.radioQueueLoadingDone$
    .pipe(
      takeUntil(this._destroyed$)
    )
    .subscribe(value => {
      if (value && this.waitingForAudioFile) {
        this.startNextAudioFile(null, false);
      }
    });

    this.zoneConnectionsService.applicationMode$
    .pipe(
      takeUntil(this._destroyed$)
    )
    .subscribe(
      applicationMode => {
        if (applicationMode === ApplicationMode.playerMode){
          if (this.currentAudioFilePlayState){
            this.syncPlayState()
          }else{
            //if we don't have a playstate -> start with first audioFile
            this.startNextAudioFile(null, false);
          }
        }else{
          this.musicPlayerService.stopAll();
          this.waitingForAudioFile = false;
        }
      }
    );

   this.activeMusicSelectionService.selectedPlayerView$.subscribe(
      tunifyColor => {
        //when we go out of tunify orange, we enforce the right player
        if (tunifyColor !== TunifyColor.ORANGE){
          this._activePlayer = ActivePlayer.activePlayerRight;
        }
      }
    );

    this._authenticationService.loggedIn$
    .pipe(
      takeUntil(this._destroyed$)
    )
    .subscribe(
     loggedIn => {
        if (!loggedIn){
          this.currentAudioFilePlayState = null;
          //playback is automatically stopped because our play token is invalidated
        }
      }
    );


    combineLatest([this.brandMessageQueueService.nextBrandMessage$, this.queueService.nextAudioFile$])
    .pipe(
      takeUntil(this._destroyed$)
    ).subscribe(
      ([brandMessage, nextAudioFile]) => {
        if (brandMessage){
          this._nextPlayableAudio = brandMessage;
        }else{
          this._nextPlayableAudio = nextAudioFile;
        }
      }
    );


  }

  ngOnDestroy(){
    this._destroyed$.next();
    this._destroyed$.complete();
    this._destroyed$ = null;

    this.waitingForAudioFileChanged$.next(null);
    this.waitingForAudioFileChanged$.complete();
    this.waitingForAudioFileChanged$ = null;
  }

  private _waitingForAudioFile:boolean = false;
  private waitingForAudioFileChanged$ = new Subject<void>()
  private set waitingForAudioFile(value: boolean){
    if (this._waitingForAudioFile != value){
      this._waitingForAudioFile = value;
      this.waitingForAudioFileChanged$.next(null);
      this.loggerService.debug(this.LOGGER_CLASSNAME, "waitingForAudioFile setter", "value changed to " + this._waitingForAudioFile);
      if (this._waitingForAudioFile){
        timer(5000)
        .pipe(
          takeUntil(
            merge(
              this._destroyed$,
              this.waitingForAudioFileChanged$
              )
          )
        )
        .subscribe( () => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, "waitingForAudioFile setter", "waiting too long -> going to force start");

          //force loading a track when it takes to long
          //this can start a track from the radio queue when loading the waiting queue takes to long
          this.startNextAudioFile(null, false, true);
        });
      }
    }
  }
  private get waitingForAudioFile(): boolean{
    return this._waitingForAudioFile;
  }

  public startNextAudioFile(audioFileToStart?:PlayableAudio, forcePlay:boolean = false, forceStartTrack: boolean = false){

    //we can only start if we are in player mode (we have an active token)
    if (this.zoneConnectionsService.applicationMode === ApplicationMode.playerMode){

      const nextPlayableAudioAccordingToBrandsMessageQueue = this.brandMessageQueueService.getNextBrandMessage();

      let nextPlayableAudioAccordingToQueue = null;
      nextPlayableAudioAccordingToQueue = this.queueService.getNextAudioFile(null, null, forceStartTrack);

      //always play a brands message when one is ready in the queue
      let nextPlayableAudioToStart: PlayableAudio = nextPlayableAudioAccordingToBrandsMessageQueue;
      if (nextPlayableAudioToStart == null){
        nextPlayableAudioToStart = audioFileToStart;
        if (nextPlayableAudioToStart == null){
          //we should only ask the next song if both queues are loaded (or if there is a song in the waiting queue, or we have waited x amount of seconds for the queue to load)
          //todo -> check if the queueService is ready
          nextPlayableAudioToStart = nextPlayableAudioAccordingToQueue;
        }
      }else{
        //if the user wants to start a track but we need to play a brandsMessage -> put is as first in the queue
        //TODO
      }



      if (nextPlayableAudioToStart != null) {
        this.waitingForAudioFile = false;

        //switch if we are in orange and we start the next audioFile
        if (this.activeMusicSelectionService.selectedPlayerView === TunifyColor.ORANGE && nextPlayableAudioToStart === nextPlayableAudioAccordingToQueue){
          if (this.activePlayer === ActivePlayer.activePlayerRight){
            this._activePlayer = ActivePlayer.activePlayerLeft;
          }else{
            this._activePlayer = ActivePlayer.activePlayerRight;
          }
        }else{
          this._activePlayer = ActivePlayer.activePlayerRight;
        }


        if (
          this._currentAudioFilePlayState == null ||
          this._currentAudioFilePlayState.playableAudio != nextPlayableAudioToStart
        ) {
          const audioFilePlayState = new AudioFilePlayState();
          audioFilePlayState.playableAudio = nextPlayableAudioToStart;

          //adjust position when we actually change the audioFile
          if (nextPlayableAudioToStart && nextPlayableAudioToStart instanceof Song) {
            audioFilePlayState.position =
            nextPlayableAudioToStart.startAudioSignal / 1000;
          } else {
            audioFilePlayState.position = 0;
          }

          //keep the playing state. If no previous playstate -> start playing
          audioFilePlayState.playing = forcePlay || this.currentAudioFilePlayState == null || this.currentAudioFilePlayState.playing;

          this.currentAudioFilePlayState = audioFilePlayState;
        } else {
          this.loggerService.error(
            this.LOGGER_CLASSNAME,
            'startNextAudioFile',
            nextPlayableAudioToStart + ' was already set'
          );
        }

        //remove audioFile if it was in a queue
        if (nextPlayableAudioToStart instanceof AudioFile){
          if (this.queueService.queue){
            this.queueService.removeAudioFileFromQueue(nextPlayableAudioToStart);
          }
          if (this.queueService.radioQueue){
            this.queueService.removeAudioFilesFromRadioQueue([nextPlayableAudioToStart]);
          }
        }else if (nextPlayableAudioToStart instanceof BrandMessage){
          this.brandMessageQueueService.removeBrandMessageFromQueue(nextPlayableAudioToStart);
        }
      } else {
        this.waitingForAudioFile = true;
      }
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, "startNextAudioFile", "We are not in playerMode -> ignoring startNextAudioFile");
    }

  }

  public play() {
    if (
      this.currentAudioFilePlayState != null
    ) {
      const newAudioFilePlayState = this.musicPlayerService.generateUpToDateState();
      newAudioFilePlayState.playing = true;

      this.currentAudioFilePlayState = newAudioFilePlayState;
    }
  }

  public pause(){
    if (this.currentAudioFilePlayState != null && this.currentAudioFilePlayState.playing ){
      const newAudioFilePlayState = this.musicPlayerService.generateUpToDateState();
      newAudioFilePlayState.playing = false;

      this.currentAudioFilePlayState = newAudioFilePlayState;
    }
  }

  public seekToTime(time: number) {
    const newAudioFilePlayState = this.musicPlayerService.generateUpToDateState();
    newAudioFilePlayState.position = time;

    this.currentAudioFilePlayState = newAudioFilePlayState;
  }

  //used to take over the playsession with the current remote track at that position
  public startPlayableAudioAtTime(playableAudio: PlayableAudio, time:number){
    const audioFilePlayState = new AudioFilePlayState();
    audioFilePlayState.playableAudio = playableAudio;
    audioFilePlayState.position = time;
    audioFilePlayState.playing = true;

    this.currentAudioFilePlayState = audioFilePlayState;
  }
}
