import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  NgZone,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { MusicCollection } from '@model/musicCollection';
import { Playlist } from '@model/playlist';
import { Context } from '@model/context';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { MusicCategoryBlockColors } from '@model/fieldModels/musicCategoryBlockColors';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { merge, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  MusicCollectionEvent,
  MusicCollectionAction,
} from '@model/events/musicCollectionEvent';
import { ResizedEvent } from '@components/resize/resize.directive';

@Component({
  selector: 'tun-music-category-block',
  templateUrl: './music-category-block.component.html',
  styleUrls: ['./music-category-block.component.scss'],
})
export class MusicCategoryBlockComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  constructor(
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}
  @ViewChild('labelContainer', { static: true }) labelContainer: ElementRef;
  @ViewChild('iconContainer', { static: true }) iconContainer: ElementRef;
  @ViewChild('label', { static: true }) label: ElementRef;

  @Input() musicCollection: MusicCollection;

  @Input() selectedMusicCategoryBlockColors: MusicCategoryBlockColors;
  @Input() unselectedMusicCategoryBlockColors: MusicCategoryBlockColors;

  @Input() musicPlayAnimationColors: MusicPlayAnimationColors;

  @Input() componentWidth: number;
  @Input() componentHeight: number;

  @Input() animatePlayAnimation = false;
  @Input() showPlayAnimationForMusicCollectionId: number = null;

  @Output() musicCollectionEvent: EventEmitter<
    MusicCollectionEvent
  > = new EventEmitter<MusicCollectionEvent>();

  icon: string;
  selected = false;

  containerBorderBottom: SafeStyle;
  containerBorderTop: SafeStyle;
  labelBackgroundGradient: SafeStyle;
  labelBackgroundGradientHover: SafeStyle;
  labelBorderRight: SafeStyle;

  iconHover = false;
  labelHover = false;

  labelContentJustification: SafeStyle = 'start';
  labelHoverTimeout: NodeJS.Timer;
  labelMarginLeft: SafeStyle = '0px';
  transitionSpeed = 0;
  offsetWidth = 0;
  scrollWidth = 0;
  transition: SafeStyle = 'all 0ms linear 0s';

  private isSelectedSubscription: Subscription;

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.musicCollection) {
      this.pickRightIcon();
      this.watchMusicCollection();
    }

    if (
      simpleChanges.selectedMusicCategoryBlockColors ||
      simpleChanges.unselectedMusicCategoryBlockColors
    ) {
      this.setColors();
    }
  }

  private willWatchOtherMusicCollection$ = new Subject<void>();
  private watchMusicCollection() {
    this.willWatchOtherMusicCollection$.next();
    if (this.musicCollection) {
      this.musicCollection.selected$
      .pipe(
        takeUntil(
          merge(this.willWatchOtherMusicCollection$, this.destroyed$)
        )
      )
      .subscribe(
        value => {
          this.selected = value;
          this.setColors();
        }
      );
    }else{
      this.selected = false;
      this.setColors();
    }
  }


  ngOnInit() {
    this.pickRightIcon();

  }
  public onResizeLabel(event: ResizedEvent){
    this.resetLabel();
  }

  private mouseEventsRegistered = false;
  ngAfterViewInit() {
    this.setColors();

    if (!this.mouseEventsRegistered){
      this.mouseEventsRegistered = true;

      this.ngZone.runOutsideAngular(() => {
        this.labelContainer.nativeElement.addEventListener(
          'mouseenter',
          this.onLabelMouseEnter
        );
        this.labelContainer.nativeElement.addEventListener(
          'mouseleave',
          this.onLabelMouseLeave
        );

        this.iconContainer.nativeElement.addEventListener(
          'mouseenter',
          this.onIconMouseEnter
        );
        this.iconContainer.nativeElement.addEventListener(
          'mouseleave',
          this.onIconMouseLeave
        );
      });
    }

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;

    if (this.labelHoverTimeout){
      clearTimeout(this.labelHoverTimeout);
      this.labelHoverTimeout = null;
    }

    if (this.mouseEventsRegistered){
      this.labelContainer.nativeElement.removeEventListener(
        'mouseenter',
        this.onLabelMouseEnter
      );
      this.labelContainer.nativeElement.removeEventListener(
        'mouseleave',
        this.onLabelMouseLeave
      );

      this.iconContainer.nativeElement.removeEventListener(
        'mouseenter',
        this.onIconMouseEnter
      );
      this.iconContainer.nativeElement.removeEventListener(
        'mouseleave',
        this.onIconMouseLeave
      );

      this.mouseEventsRegistered = false;
    }
  }

  // If musicCollection is of type Playlist, icon should be a ListIcon.
  // Else if musicCollection is of type Context, icon should be a SettingsIcon.
  pickRightIcon() {
    if (this.musicCollection instanceof Playlist) {
      this.icon = 'listIcon';
    } else if (this.musicCollection instanceof Context) {
      this.icon = 'settingsIcon';
    }
  }

  // Checks whether the icon is a list icon or a settings icon.
  isListIcon = () => {
    return this.icon === 'listIcon';
  }

  // Activated when the icon is clicked.
  onIconClick = () => {
    this.musicCollectionEvent.emit(
      new MusicCollectionEvent(
        MusicCollectionAction.SHOW_DETAILS,
        this.musicCollection
      )
    );
  }

  // Go from selected to unselected or vice versa.
  toggleSelected = () => {
    // this.selected = !this.selected;
    this.musicCollection.selected = !this.selected;
    this.resetLabel();
    this.musicCollectionEvent.emit(
      new MusicCollectionEvent(
        MusicCollectionAction.SELECTED_PROPERTY_CHANGED,
        this.musicCollection
      )
    );
  }

  onLabelMouseEnter = () => {
    this.labelHover = true;
    if (this.musicCollection) {
      clearTimeout(this.labelHoverTimeout);
      if (this.offsetWidth === 0) {
        this.offsetWidth = this.label.nativeElement.offsetWidth;
      }

      if (this.scrollWidth === 0) {
        this.scrollWidth = this.label.nativeElement.scrollWidth;
      }

      if (this.offsetWidth < this.scrollWidth) {
        this.labelContentJustification = 'space-between';
        this.transitionSpeed = (this.scrollWidth - this.offsetWidth) * 50;
        this.transition = `all ${this.transitionSpeed}ms linear 0s`;
        this.labelMarginLeft = this.sanitizer.bypassSecurityTrustStyle(
          `-${this.scrollWidth - this.offsetWidth}px`
        );
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  onLabelMouseLeave = () => {
    this.labelHover = false;
    if (this.musicCollection) {
      if (this.labelMarginLeft !== ('0px' as SafeStyle)) {
        this.labelMarginLeft = '0px';
        this.transitionSpeed = ((this.scrollWidth - this.offsetWidth) * 50) / 2;
        this.transition = `all ${this.transitionSpeed}ms linear 0s`;

        this.labelHoverTimeout = setTimeout(() => {
          this.labelContentJustification = 'start';
          this.transitionSpeed = 0;
          this.transition = 'all 0ms linear 0s';
        }, this.transitionSpeed);
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  onIconMouseEnter = () => {
    this.iconHover = true;
    this.changeDetectorRef.detectChanges();
  }

  onIconMouseLeave = () => {
    this.iconHover = false;
    this.changeDetectorRef.detectChanges();
  }

  resetLabel = () => {
    if (this.labelHoverTimeout) {
      clearTimeout(this.labelHoverTimeout);
    }
    this.offsetWidth = 0;
    this.scrollWidth = 0;
    this.labelMarginLeft = '0px';
    this.transitionSpeed = 0;
    this.labelContentJustification = 'start';
  }

  setColors = () => {
    if (
      this.selectedMusicCategoryBlockColors &&
      this.unselectedMusicCategoryBlockColors
    ) {
      if (this.selected) {
        this.containerBorderTop = `1px solid ${
          this.selectedMusicCategoryBlockColors._containerBorderColor
        }`;
        this.containerBorderBottom = `1px solid transparent`;
        this.labelBackgroundGradient = `linear-gradient(${
          this.selectedMusicCategoryBlockColors
            ._labelBackgroundGradientStartColor
        }, ${
          this.selectedMusicCategoryBlockColors._labelBackgroundGradientEndColor
        })`;
        this.labelBackgroundGradientHover = `linear-gradient(${
          this.selectedMusicCategoryBlockColors
            ._labelBackgroundGradientHoverStartColor
        }, ${
          this.selectedMusicCategoryBlockColors
            ._labelBackgroundGradientHoverEndColor
        })`;
        this.labelBorderRight = `1px solid ${
          this.selectedMusicCategoryBlockColors._labelBorderRightColor
        }`;
      } else {
        this.containerBorderBottom = `1px solid ${
          this.unselectedMusicCategoryBlockColors._containerBorderColor
        }`;
        this.containerBorderTop = `1px solid transparent`;
        this.labelBackgroundGradient = `linear-gradient(${
          this.unselectedMusicCategoryBlockColors
            ._labelBackgroundGradientStartColor
        }, ${
          this.unselectedMusicCategoryBlockColors
            ._labelBackgroundGradientEndColor
        })`;
        this.labelBackgroundGradientHover = `linear-gradient(${
          this.unselectedMusicCategoryBlockColors
            ._labelBackgroundGradientHoverStartColor
        }, ${
          this.unselectedMusicCategoryBlockColors
            ._labelBackgroundGradientHoverEndColor
        })`;
        this.labelBorderRight = `1px solid ${
          this.unselectedMusicCategoryBlockColors._labelBorderRightColor
        }`;
      }
    }
  }
}
