import { Component } from '@angular/core';

@Component({
  selector: 'tun-thumb-up-icon',
  templateUrl: './thumb-up-icon.component.html',
  styleUrls: ['./thumb-up-icon.component.scss']
})
export class ThumbUpIconComponent {
  constructor() {}
}
