import { Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { MyListsPopupContent, AddToListSelection, ListOption } from '../popup/interfaces';
import { PopupService } from '@service/popup.service';
import { PlaylistService } from '@service/playlist.service';
import { Subject, Observable, timer } from 'rxjs';
import { Playlist } from '@model/playlist';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { takeUntil, tap, filter } from 'rxjs/operators';

@Component({
  selector: 'tun-my-lists-popup',
  templateUrl: './my-lists-popup.component.html',
  styleUrls: ['./my-lists-popup.component.scss']
})
export class MyListsPopupComponent implements OnDestroy, MyListsPopupContent {
  // === Props === //
  @Input() title = 'popup.queueList.title';
  @Input() isSearchEnabled = true;
  @Input() isAddToQueueEnabled = true;
  @Input() connectedElementRef: ElementRef;

  private _status: AsyncStatus;

  get status(): AsyncStatus {
    return this._status;
  }

  @Input()
  set status(value: AsyncStatus) {
    if (value !== this._status) {
      this._status = value;
      if (this._status === AsyncStatus.COMPLETED) {
        // Close the popup after delay
        timer(this.DELAY_STATUS_CHANGE)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(() => this.closePopup());
      } else if (this._status === AsyncStatus.ERROR) {
        // Show the options again after 1s
        timer(this.DELAY_STATUS_CHANGE)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(() => (this.status = undefined));
      } else if (this.status === AsyncStatus.CANCELLED) {
        // Close the popup after delay
        timer(this.DELAY_STATUS_CHANGE)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(() => this.closePopup());
      } else if (this.status === AsyncStatus.INVALID_ACTION) {
        // Close the popup after delay
        timer(this.DELAY_STATUS_CHANGE)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(() => this.closePopup());
      }
    }
  }

  // === State === //
  // time to wait for the COMPLETED or ERROR action to be executed (in milliseconds)
  private readonly DELAY_STATUS_CHANGE = 2000;
  private componentDestroyed$ = new Subject<boolean>();

  get playlists$(): Observable<Playlist[]> {
    return this._playlistService.playlists$;
  }

  get favorites$(): Observable<Playlist> {
    return this._playlistService.favorites$;
  }

  // === Observables === //
  private _onSearchSubject = new Subject<Playlist>();
  private _onSelectSubject = new Subject<AddToListSelection>();

  onSearch$ = this._onSearchSubject.asObservable();
  onSelect$ = this._onSelectSubject.asObservable();

  constructor(
    private _popupService: PopupService,
    private _playlistService: PlaylistService,
    public elementRef: ElementRef
  ) {}

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$ = null;
  }

  closePopup() {
    this._popupService.hidePopup$.next(this.connectedElementRef);
  }

  onSearch(playlist: Playlist) {
    this._onSearchSubject.next(playlist);
  }

	onAddToQueue() {
		this._onSelectSubject.next({listOption: ListOption.QUEUE});
	}

  onSelectPlaylist(playlist: Playlist) {
		this._onSelectSubject.next({listOption: ListOption.PLAYLIST, playlist});
  }

  onNewPlaylist(playlist: Playlist) {
    this._onSelectSubject.next({listOption: ListOption.NEW_LIST, playlist: playlist});
	}
}
