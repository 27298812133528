import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TunifyLogoComponent } from './tunify-logo/tunify-logo.component';
import { AudioFileControllerComponent } from './audio-file-controller/audio-file-controller.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AudioFilePlaybackInfoComponent } from './audio-file-controller/audio-file-playback-info/audio-file-playback-info.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AudioFileProgressComponent } from './audio-file-controller/audio-file-progress/audio-file-progress.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RemoteTrackControllerComponent } from './remote-track-controller/remote-track-controller.component';
import { RemoteProgressComponent } from './remote-track-controller/remote-progress/remote-progress.component';
import { SearchingPlayerComponent } from './searching-player/searching-player.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconsModule } from '@components/icons/icons.module';
import { AppComponentsModule } from '@components/app-components/app-components.module';
import { SoundSpectrumStereoComponentComponent } from './audio-file-controller/sound-spectrum-stereo-component/sound-spectrum-stereo-component.component';
import { PopupsModule } from '@components/popups/popups.module';
import { UtilsModule } from '@util/utils.module';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ResizeModule } from '@components/resize/resize.module';

@NgModule({
  declarations: [
    TunifyLogoComponent,
    AudioFileControllerComponent,
    AudioFilePlaybackInfoComponent,
    AudioFileProgressComponent,
    SoundSpectrumStereoComponentComponent,
    AudioFileProgressComponent,
    RemoteTrackControllerComponent,
    RemoteProgressComponent,
    SearchingPlayerComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FontAwesomeModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    IconsModule,
    AppComponentsModule,
    PopupsModule,
    UtilsModule,
    TranslateModule,
    ResizeModule
  ],
  exports: [
    TunifyLogoComponent,
    AudioFileControllerComponent,
    RemoteTrackControllerComponent,
    SearchingPlayerComponent
  ]
})
export class PlayerComponentsModule { }
