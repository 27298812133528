<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
>
  <polygon fill="currentColor" points="17 8.8 10 4.5 10 9 7 9 7 15 " />
  <polygon
    fill="currentColor"
    points="8 7 8 5 5 5 5 2 3 2 3 5 0 5 0 7 3 7 3 10 5 10 5 7 "
  />
</svg>
