import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownMenuComponent } from '@components/app-components/fields/calendar-dropdown/dropdown-menu/dropdown-menu.component';
import { SingleDropdownComponent } from '@components/app-components/fields/single-dropdown/single-dropdown.component';
import { IconsModule } from '@components/icons/icons.module';
import { OptionsMenuComponent } from '@components/popups/options-menu/options-menu.component';
import { SongDetailsComponent } from '@components/popups/song-info-popup/song-details/song-details.component';
import { UtilsModule } from '@util/utils.module';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ScrollbarModule } from 'src/app/scrollbar/scrollbar.module';
import { SearchButtonComponent } from '../app-components/buttons/search-button/search-button.component';
import { BlockedSongRowComponent } from './blocked-songs-popup/blocked-song-row/blocked-song-row.component';
import { BlockedSongsPopupComponent } from './blocked-songs-popup/blocked-songs-popup.component';
import { DoubleAudioFilesPopupComponent } from './double-audio-files-popup/double-audio-files-popup.component';
import { MyListsPopupRowComponent } from './my-lists-popup/my-lists-popup-row/my-lists-popup-row.component';
import { MyListsPopupComponent } from './my-lists-popup/my-lists-popup.component';
import { PopupArrowComponent } from './popup-arrow/popup-arrow.component';
import { PopupContentDirective } from './popup-content.directive';
import { PopupHeaderComponent } from './popup-header/popup-header.component';
import { PopupOnClickDirective } from './popup-on-click.directive';
import { PopupOnHoverDirective } from './popup-on-hover.directive';
import { PopupComponent } from './popup/popup.component';
import { AddSongToListComponent } from './song-info-popup/add-song-to-list/add-song-to-list.component';
import { PopupNavigationComponent } from './song-info-popup/popup-navigation/popup-navigation.component';
import { SongInfoPopupComponent } from './song-info-popup/song-info-popup.component';
import { SongPreviewComponent } from './song-info-popup/song-preview/song-preview.component';
import { SuggestionPopupComponent } from './suggestion-popup/suggestion-popup.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { SabamPopupComponent } from './sabam-popup/sabam-popup.component';
import { MyListsPopupContentComponent } from './my-lists-popup/my-lists-popup-content/my-lists-popup-content.component';
import { AddToNewListComponent } from './song-info-popup/add-to-new-list/add-to-new-list.component';
import { CheckAccessDirective } from './access-checks/check-access.directive';
import { VersionPopupComponent } from './version-popup/version-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagePopupComponent } from './language-popup/language-popup.component';
import { TooltipOnHoverDirective } from './tooltip-on-hover/tooltip-on-hover.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RemotePopupComponent } from './remote-popup/remote-popup.component';
import { NoPlayerConnectPopupComponent } from './no-player-connect-popup/no-player-connect-popup.component';
import { TooManyTracksPopupComponent } from './too-many-tracks-popup/too-many-tracks-popup.component';
import { ResizeModule } from '@components/resize/resize.module';
import { RemotePopupV5Component } from './remote-popup-v5/remote-popup-v5.component';


@NgModule({
    declarations: [
        PopupOnHoverDirective,
        TooltipComponent,
        PopupOnClickDirective,
        PopupArrowComponent,
        PopupComponent,
        PopupContentDirective,
        SongInfoPopupComponent,
        SearchButtonComponent,
        SongDetailsComponent,
        PopupNavigationComponent,
        SongPreviewComponent,
        AddSongToListComponent,
        SuggestionPopupComponent,
        PopupHeaderComponent,
        MyListsPopupComponent,
        MyListsPopupRowComponent,
        BlockedSongsPopupComponent,
        BlockedSongRowComponent,
        OptionsMenuComponent,
        ConfirmPopupComponent,
        SabamPopupComponent,
        VersionPopupComponent,
        MyListsPopupContentComponent,
        AddToNewListComponent,
        CheckAccessDirective,
        LanguagePopupComponent,
        TooltipOnHoverDirective,
        RemotePopupComponent,
        NoPlayerConnectPopupComponent,
        TooManyTracksPopupComponent,
        RemotePopupV5Component,
    ],
    imports: [
        TranslateModule,
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        IconsModule,
        MatCheckboxModule,
        BrowserAnimationsModule,
        UtilsModule,
        ScrollbarModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        ResizeModule
    ],
    exports: [
        TooltipComponent,
        PopupOnHoverDirective,
        PopupOnClickDirective,
        SongInfoPopupComponent,
        SearchButtonComponent,
        MyListsPopupComponent,
        BlockedSongsPopupComponent,
        OptionsMenuComponent,
        ConfirmPopupComponent,
        SabamPopupComponent,
        CheckAccessDirective,
        LanguagePopupComponent,
        TooltipOnHoverDirective,
        TooManyTracksPopupComponent,
        RemotePopupV5Component
    ],
    providers: [DatePipe]
})
export class PopupsModule {}
