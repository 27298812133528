import { MusicCollection } from '@model/musicCollection';

export enum MusicCollectionAction {
    SHOW_DETAILS = "showDetails",
    SELECTED_PROPERTY_CHANGED = "selectedPropertyChanged", //when a musicCollection goes from selected to unselected or visa versa
    PARAMETERS_CHANGED = "parametersChanged",
    RESET_PARAMETERS = "resetParameters"
}

export class MusicCollectionEvent {
    musicCollection: MusicCollection;
    action: MusicCollectionAction;
    
    constructor(action: MusicCollectionAction, musicCollection: MusicCollection){
        this.action = action;
        this.musicCollection = musicCollection;
    }

    public toString(): string {
        return 'perform ' + this.action + ' for ' + this.musicCollection;
    }
}   