import { Component, Input } from '@angular/core';

@Component({
  selector: 'tun-settings-icon',
  templateUrl: './settings-icon.component.html',
  styleUrls: ['./settings-icon.component.scss']
})
export class SettingsIconComponent {

  @Input() color: string;

  constructor() { }

}
