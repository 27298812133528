import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  SimpleChanges,
  ChangeDetectorRef,
  OnDestroy,
  NgZone,
  AfterViewChecked
} from '@angular/core';
import { LoggerService } from '@service/loggers/logger.service';
import { MainNavigationItem } from '../model/MainNavigationItem';
import { NavigationItem } from '../model/NavigationItem';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { Subscription, Subject } from 'rxjs';
import { ScrollbarComponent } from 'src/app/scrollbar/scrollbar.component';
import { takeUntil } from 'rxjs/operators';
import { DragDropService } from '@service/drag-drop.service';
import { AudioFile } from '@model/audioFile';

export class NavigationItemTrackEvent {
  navigationItem: NavigationItem;
  track: AudioFile;
}

@Component({
  selector: 'tun-main-navigation-item-view',
  templateUrl: './main-navigation-item-view.component.html',
  styleUrls: ['./main-navigation-item-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainNavigationItemViewComponent implements OnInit, AfterViewChecked, OnDestroy, OnChanges {

  constructor(
    private loggerService: LoggerService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private dragDropService: DragDropService
  ) {

  }
  private set contentHeight(value: number) {
    if (this._contentHeight != value) {
      this._contentHeight = value;
      this.updateHeight();
    }
  }
  private get contentHeight(): number {
    return this._contentHeight;
  }
  private LOGGER_CLASSNAME = 'MainNavigationItemViewComponent';

  @Input() public heightPerItem = 0;
  @Input() public maxHeightForContent = 0;
  @Input() public mainNavigationItem: MainNavigationItem;
  @Input() public mainNavigationItemIndex: number;

  @Output() selectMainNavigationItem = new EventEmitter<MainNavigationItem>();
  @Output() selectNavigationItem = new EventEmitter<NavigationItem>();
  @Output() deleteNavigationItem = new EventEmitter<NavigationItem>();
  @Output() createItemWithTitle = new EventEmitter<string>();
  @Output() changeNavigationItemTitle = new EventEmitter<NavigationItem>();
  @Output() dropTrack = new EventEmitter<NavigationItemTrackEvent>();

  @ViewChild('expandableContent', { static: true }) expandable;
  @ViewChild('scroller', { static: true }) scroller: ScrollbarComponent;

  private componentDestroyed$ = new Subject<boolean>();


  public mainItemColorStyle = '';
  public mainItemStyle = 'closed';
  public showVScrollBarWhenOpen = true;
  public scrollbarColor: string;

  private openSubscription: Subscription;

  private _contentHeight = -1;

  ngOnChanges(inputChanges: SimpleChanges) {
    if (inputChanges.mainNavigationItem) {
      this.adjustStyleToColor(); // we are not going to watch the tunifyColor property because it should not change
      this.watchMainNavigationItem();
    }
    if (inputChanges.heightPerItem || inputChanges.maxHeightForContent || inputChanges.mainNavigationItem) {
      this.calculateHeight();
    }
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.adjustDropListeners(this.mainNavigationItem && this.mainNavigationItem.itemsCanAcceptsTrackDrops);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.componentDestroyed$ = null;

    this.adjustDropListeners(false);
  }

  public amountNormalItems(){
    if (this.mainNavigationItem && this.mainNavigationItem.items){
      return this.mainNavigationItem.items.filter(item => !item.showAsInfoItem).length
    }
    return 0
  }

  private dropListenersAdded = false;
  private adjustDropListeners(listenForDrops: boolean) {
    if (listenForDrops) {
      if (!this.dropListenersAdded) {
        this.ngZone.runOutsideAngular(() => {
          this.expandable.nativeElement.addEventListener('dragover', this.onDragOver);
          this.expandable.nativeElement.addEventListener(
            'dragenter',
            this.onDragEnter
          );
          this.expandable.nativeElement.addEventListener(
            'dragleave',
            this.onDragLeave
          );
          this.expandable.nativeElement.addEventListener('drop', this.onDrop);
        });
        this.dropListenersAdded = true;
      }
    } else {
      if (this.dropListenersAdded) {
        this.ngZone.runOutsideAngular(() => {
          this.expandable.nativeElement.removeEventListener(
            'dragover',
            this.onDragOver
          );
          this.expandable.nativeElement.removeEventListener(
            'dragenter',
            this.onDragEnter
          );
          this.expandable.nativeElement.removeEventListener(
            'dragleave',
            this.onDragLeave
          );
          this.expandable.nativeElement.removeEventListener('drop', this.onDrop);
        });
        this.dropListenersAdded = false;
      }
    }
  }

  private watchMainNavigationItem() {
    this.cleanupOpenSubscription();
    if (this.mainNavigationItem) {
      this.openSubscription = this.mainNavigationItem.open$
        .pipe(
          takeUntil(this.componentDestroyed$)
        )
        .subscribe(
          value => {
            this.adjustStyleToOpen();
            this.calculateHeight();
            this.scrollWhenFullyOpen();
            //his.scrollToSelectedItem();
            this.changeDetectorRef.detectChanges();
          }
        );
    }
  }


  private timerScroller: NodeJS.Timer;
  private scrollWhenFullyOpen() {
    if (this.mainNavigationItem.open) {
      this.clearTimer();
      this.ngZone.runOutsideAngular(
        () => {
          this.timerScroller = setTimeout(() => {
            this.timerScroller = null;
            // run normal events in angular zone
            this.ngZone.run(() => {
              this.scrollToSelectedItem();
              this.clearTimer();
            });
          }, 500);
        }
      );
    } else {
      this.clearTimer();
    }
  }

  private clearTimer() {
    if (this.timerScroller) {
      clearTimeout(this.timerScroller);
      this.timerScroller = null;
    }
  }


  private scrollToSelectedItem() {
    if (this.mainNavigationItem && this.mainNavigationItem.open) {
      let openItemIndex = -1;


      this.mainNavigationItem.items.forEach(
        (item, index) => {
          if (item.selected) {
            openItemIndex = index;
          }
        }
      );


      if (openItemIndex >= 0) {
        const position = openItemIndex * this.heightPerItem;

        this.scroller.makeVisible(position, position + this.heightPerItem);
      }

    }
  }

  private cleanupOpenSubscription() {
    if (this.openSubscription) {
      this.openSubscription.unsubscribe();
      this.openSubscription = null;
    }
  }

  private adjustStyleToOpen() {
    if (this.mainNavigationItem && this.mainNavigationItem.open) {
      this.mainItemStyle = 'open';
    } else {
      this.mainItemStyle = 'closed';
    }
  }

  private adjustStyleToColor() {
    if (
      this.mainNavigationItem &&
      this.mainNavigationItem.tunifyColor &&
      this.mainNavigationItem.tunifyColor == TunifyColor.BLUE
    ) {
      this.mainItemColorStyle = 'blueMainNavigationItem';
      this.scrollbarColor = 'blue';
    } else if (
      this.mainNavigationItem &&
      this.mainNavigationItem.tunifyColor &&
      this.mainNavigationItem.tunifyColor == TunifyColor.GREEN
    ) {
      this.mainItemColorStyle = 'greenMainNavigationItem';
      this.scrollbarColor = 'green';
    } else if (
      this.mainNavigationItem &&
      this.mainNavigationItem.tunifyColor &&
      this.mainNavigationItem.tunifyColor == TunifyColor.ORANGE
    ) {
      this.mainItemColorStyle = 'orangeMainNavigationItem';
      this.scrollbarColor = 'orange';
    } else {
      this.mainItemColorStyle = '';
      this.scrollbarColor = '';
    }
  }

  private updateHeight = () => {
    const el = this.expandable.nativeElement;

    el.style.height = this.contentHeight + 'px';
  }

  private calculateHeight() {
    let heightForContent = 0;

    this.showVScrollBarWhenOpen =
      this.mainNavigationItem.items.length * this.heightPerItem >
      this.maxHeightForContent;

    if (
      this.mainNavigationItem &&
      this.mainNavigationItem.open &&
      this.mainNavigationItem.items &&
      this.mainNavigationItem.items.length > 0
    ) {
      const amountInfoItems = this.mainNavigationItem.items.filter(item => item.showAsInfoItem).length;
      heightForContent = Math.min(
        (this.mainNavigationItem.items.length + amountInfoItems) * this.heightPerItem,
        this.maxHeightForContent
      );
    }

    // this.loggerService.debug(this.LOGGER_CLASSNAME, "calculateHeight", "going to set heigth: " + heightForContent);
    this.contentHeight = heightForContent;
  }

  public clickMainItem(mainNavigationItem: MainNavigationItem) {
    this.loggerService.debug(
      this.LOGGER_CLASSNAME,
      'clickMainItem',
      'main item clicked'
    );

    this.selectMainNavigationItem.emit(this.mainNavigationItem);
  }

  public onSelectItem(navigationItem: NavigationItem) {
    /*
    this.loggerService.debug(
      this.LOGGER_CLASSNAME,
      'onSelectItem',
      'select item'
    );
    */

    this.selectNavigationItem.emit(navigationItem);
  }

  public onDeleteItem(navigationItem: NavigationItem) {
    this.loggerService.debug(
      this.LOGGER_CLASSNAME,
      'onDeleteItem',
      'delete item'
    );

    this.deleteNavigationItem.emit(navigationItem);
  }

  public onCreateItemWithTitle(title: string) {
    this.createItemWithTitle.emit(title);
  }

  public onChangeItemTitle(navigationItem: NavigationItem) {
    this.changeNavigationItemTitle.emit(navigationItem);
  }

  public makeItemVisible(index: number) {
    const position = index * this.heightPerItem;
    this.scroller.makeVisible(position, position + this.heightPerItem);
  }

  /**
   * Drop functions
   */

  public dragOverItemIndex: number = -1;
  private canDropAtCurrentIndex = false;

  private hoveredItemIndex(event: DragEvent): number {
    const composedPath = event.composedPath();

    for (let i = 0; i < composedPath.length; i++) {
      const element = composedPath[i];
      if (
        element instanceof HTMLElement &&
        element.hasAttribute('item-index')
      ) {
        return Number.parseInt(element.getAttribute('item-index'), 10);
      }
    }

    return -1;
  }

  private canDropAtIndex(index: number): boolean {
    if (this.mainNavigationItem && this.mainNavigationItem.items && this.mainNavigationItem.items.length > this.dragOverItemIndex && this.mainNavigationItem.items[this.dragOverItemIndex] != undefined) {
      return this.mainNavigationItem.items[this.dragOverItemIndex].acceptTrackDrops;
    }
  }


  //to avoid inspecting the DOM tree on each dragOver event, keep track of when to recalc
  private recalcAboveY: number = undefined;
  private recalcUnderY: number = undefined;
  private countDragEnters: number = 0;
  onDragEnter = (event: DragEvent) => {
    this.countDragEnters++;
    if (this.dragDropService.draggingAudioFile === null) {
      return;
    }

    this.recalcAboveY = undefined;
    this.recalcUnderY = undefined;

    event.stopPropagation();

    this.calcDragOverItem(event);
  };

  onDragOver = (event: DragEvent) => {
    if (this.dragDropService.draggingAudioFile === null) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();

    this.calcDragOverItem(event);
  };

  private calcDragOverItem(event: DragEvent) {
    if (
      (this.recalcAboveY === undefined ||
        this.recalcUnderY === undefined ||
        event.y > this.recalcAboveY ||
        event.y < this.recalcUnderY)
    ) {
      const tableRowHoverIndex = this.hoveredItemIndex(event);

      if (this.dragOverItemIndex !== tableRowHoverIndex) {
        this.dragOverItemIndex = tableRowHoverIndex;

        //when the index changes, recalculate if we can drop here
        this.canDropAtCurrentIndex = this.canDropAtIndex(this.dragOverItemIndex);

        this.changeDetectorRef.detectChanges();
      }

      //calclulate when we should recalculate - only when we have a valid index
      if (this.dragOverItemIndex >= 0) {
        const yOffCurrentTarget = event.y - event.offsetY;
        this.recalcUnderY = yOffCurrentTarget;
        this.recalcAboveY = yOffCurrentTarget + this.heightPerItem;
      } else {
        this.recalcUnderY = undefined;
        this.recalcAboveY = undefined;
      }

    }

    //this has to be set for every DragEvent
    if (this.canDropAtCurrentIndex) {
      event.dataTransfer.dropEffect = "copy";
    }
  }


  onDragLeave = (event: DragEvent) => {
    this.countDragEnters--;

    if (this.dragDropService.draggingAudioFile === null) {
      return;
    }

    event.stopPropagation();

    if (this.countDragEnters == 0) {
      this.dragOverItemIndex = -1;
      this.recalcUnderY = undefined;
      this.recalcAboveY = undefined;
      this.changeDetectorRef.detectChanges();
    }
  };

  // Called when an audiofile is about to be dropped.
  // This happens in the component where the audiofile will be added to the playlist.
  onDrop = (event: DragEvent) => {
    this.countDragEnters = 0;

    if (this.dragDropService.draggingAudioFile === null) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();

    const index = this.dragOverItemIndex;
    this.dragOverItemIndex = -1;

    if (this.mainNavigationItem && this.mainNavigationItem.items && this.mainNavigationItem.items.length > this.dragOverItemIndex) {
      const navigationItem = this.mainNavigationItem.items[index];
      if (navigationItem.acceptTrackDrops) {
        //todo -> play 'drop' animation
        const audioFile: AudioFile = this.dragDropService.draggingAudioFile;
        const navigationItemTrackEvent = new NavigationItemTrackEvent();
        navigationItemTrackEvent.track = audioFile;
        navigationItemTrackEvent.navigationItem = navigationItem;
        this.dropTrack.emit(navigationItemTrackEvent);
      }

    }





    /*
    let action: Play;
    if (
      this.dragDropService.dragStartedFromQueue &&
      (this.isEditable || this.isQueue)
    ) {
      action = TrackIndexAction.MOVE_TO_INDEX;
    } else {
      action = TrackIndexAction.ADD_AT_INDEX;
    }


    console.log(action);

    const trackIndexEvent: TrackIndexEvent = new TrackIndexEvent(
      action,
      audioFile,
      index
    );

    this.ngZone.run(() => {
      this.trackIndexEvent.emit(trackIndexEvent);
    });
    */
  };



}
