import { DragDropModule } from "@angular/cdk/drag-drop";
import { OverlayModule } from "@angular/cdk/overlay";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { IconsModule } from "@components/icons/icons.module";
import { DoubleAudioFilesPopupComponent } from "@components/popups/double-audio-files-popup/double-audio-files-popup.component";
import { PopupComponent } from "@components/popups/popup/popup.component";
import { PopupsModule } from "@components/popups/popups.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UtilsModule } from "@util/utils.module";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG
} from "ngx-perfect-scrollbar";
import { ScrollbarModule } from "src/app/scrollbar/scrollbar.module";
import { SassHelperComponent } from "../../../providers/sass-helper/sass-helper.component";
import { CheckboxComponent } from "../app-components/fields/checkbox/checkbox.component";
import { IconButtonComponent } from "./buttons/icon-button/icon-button.component";
import { AutocompleteResultRowComponent } from "./fields/autocomplete-result/autocomplete-result-row/autocomplete-result-row.component";
import { AutocompleteResultComponent } from "./fields/autocomplete-result/autocomplete-result.component";
import { BaseSliderComponent } from "./fields/base-slider/base-slider.component";
import { CalendarDropdownComponent } from "./fields/calendar-dropdown/calendar-dropdown.component";
import { DropdownMenuComponent } from "./fields/calendar-dropdown/dropdown-menu/dropdown-menu.component";
import { CalendarItemComponent } from "./fields/calendar-item/calendar-item.component";
import { FlexibleSliderComponent } from "./fields/flexible-slider/flexible-slider.component";
import { MusicCategoryBlockGridComponent } from "./fields/music-category-block-grid/music-category-block-grid.component";
import { MusicCollectionSorter } from "./fields/music-category-block-grid/music-collection-sorter.pipe";
import { MusicCategoryBlockComponent } from "./fields/music-category-block/music-category-block.component";
import { MapSliderOptions } from "./fields/music-collection-configuration-panel/map-slider-options.pipe";
import { MusicCollectionConfigurationPanelComponent } from "./fields/music-collection-configuration-panel/music-collection-configuration-panel.component";
import { MusicPlayAnimationComponent } from "./fields/music-play-animation/music-play-animation.component";
import { SearchFieldComponent } from "./fields/search-field/search-field.component";
import { SingleDropdownComponent } from "./fields/single-dropdown/single-dropdown.component";
import { SongFieldComponent } from "./fields/song-field/song-field.component";
import { SongGridRowComponent } from "./fields/song-grid-row/song-grid-row.component";
import { SongGridComponent } from "./fields/song-grid/song-grid.component";
import { SongSorter } from "./fields/song-grid/song-sorter.pipe";
import { TextSliderComponent } from "./fields/text-slider/text-slider.component";
import { TweakstrengthBarComponent } from "./fields/tweakstrength-bar/tweakstrength-bar.component";
import { FitSizeDirective } from "./fit-size-directive/fit-size.directive";
import { MainNavigationItemViewComponent } from "./menu/navigation-menu/main-navigation-item-view/main-navigation-item-view.component";
import { NavigationItemViewComponent } from "./menu/navigation-menu/navigation-item-view/navigation-item-view.component";
import { NavigationMenuComponent } from "./menu/navigation-menu/navigation-menu.component";
import { NewNavigationItemPopupComponent } from "./menu/navigation-menu/new-navigation-item-popup/new-navigation-item-popup.component";
import { ChangeableParametersComponent } from "./panels/changeable-parameters/changeable-parameters.component";
import { PanelHeaderComponent } from "./panels/panel-header/panel-header.component";
import { PlaylistComponent } from "./panels/playlist/playlist.component";
import { EditablePlaylistComponent } from "./panels/editable-playlist/editable-playlist.component";
import { MusicCollectionComponent } from "./panels/music-collection/music-collection.component";
import { TooltipComponent } from '@components/popups/tooltip/tooltip.component';
import { TranslateModule } from '@ngx-translate/core';
import { SearchResultsPlaylistComponent } from '@components/app-components/panels/search-results-playlist/search-results-playlist.component';
import { ResizeModule } from "@components/resize/resize.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
    declarations: [
        FitSizeDirective,
        CheckboxComponent,
        BaseSliderComponent,
        TextSliderComponent,
        FlexibleSliderComponent,
        MusicPlayAnimationComponent,
        TweakstrengthBarComponent,
        MusicCategoryBlockComponent,
        NavigationMenuComponent,
        MainNavigationItemViewComponent,
        NavigationItemViewComponent,
        DoubleAudioFilesPopupComponent,
        MusicCategoryBlockGridComponent,
        MusicCollectionConfigurationPanelComponent,
        MapSliderOptions,
        MusicCollectionSorter,
        SongGridComponent,
        SongFieldComponent,
        SongSorter,
        SongGridRowComponent,
        SassHelperComponent,
        CalendarItemComponent,
        CalendarDropdownComponent,
        DropdownMenuComponent,
        SingleDropdownComponent,
        SearchFieldComponent,
        AutocompleteResultComponent,
        AutocompleteResultRowComponent,
        IconButtonComponent,
        NewNavigationItemPopupComponent,
        PanelHeaderComponent,
        ChangeableParametersComponent,
        PlaylistComponent,
        EditablePlaylistComponent,
        MusicCollectionComponent,
        SearchResultsPlaylistComponent
    ],
    imports: [
        CommonModule,
        MatCheckboxModule,
        IconsModule,
        FlexLayoutModule,
        ScrollingModule,
        PerfectScrollbarModule,
        ScrollbarModule,
        DragDropModule,
        FontAwesomeModule,
        PerfectScrollbarModule,
        OverlayModule,
        PopupsModule,
        BrowserAnimationsModule,
        UtilsModule,
        ReactiveFormsModule,
        TranslateModule,
        ResizeModule
    ],
    exports: [
        FitSizeDirective,
        CheckboxComponent,
        TextSliderComponent,
        FlexibleSliderComponent,
        MusicPlayAnimationComponent,
        TweakstrengthBarComponent,
        MusicCategoryBlockComponent,
        NavigationMenuComponent,
        MusicCategoryBlockGridComponent,
        MusicCollectionConfigurationPanelComponent,
        DoubleAudioFilesPopupComponent,
        SongGridComponent,
        SongFieldComponent,
        SongSorter,
        SongFieldComponent,
        SongGridRowComponent,
        SassHelperComponent,
        CalendarItemComponent,
        CalendarDropdownComponent,
        DropdownMenuComponent,
        SingleDropdownComponent,
        SearchFieldComponent,
        AutocompleteResultComponent,
        AutocompleteResultRowComponent,
        IconButtonComponent,
        NavigationItemViewComponent,
        PanelHeaderComponent,
        ChangeableParametersComponent,
        PlaylistComponent,
        EditablePlaylistComponent,
        MusicCollectionComponent,
        SearchResultsPlaylistComponent
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class AppComponentsModule {}
