import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'tun-add-to-new-list',
  templateUrl: './add-to-new-list.component.html',
  styleUrls: ['./add-to-new-list.component.scss']
})
export class AddToNewListComponent implements OnInit {

  // === State === //
  titleForm: UntypedFormGroup;

  // === EventEmitters === //
  @Output() save = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.titleForm = this.fb.group({
      title: ['', [Validators.required]]
    });
  }

  onSave() {
    if (this.titleForm.valid) {
      this.save.emit(this.titleForm.value.title);
    }
  }

  onCancel() {
    this.cancel.emit();
  }
}
