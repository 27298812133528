import { Component, OnInit, Input } from '@angular/core';
import { ConnectionType } from '@service/vo/remote/remote-objects';
import { map, filter } from 'rxjs/operators';
import { ZoneConnection } from '../../../../model/zoneConnection';
import { AppService } from '../../../../services/app/app.service';
import { ApplicationMode, ZoneConnectionsService } from '../../../../services/authentication/zone-connections.service';
import { MatDialog } from '@angular/material/dialog';
import { NoPlayerConnectPopupComponent } from '../../../../components/popups/no-player-connect-popup/no-player-connect-popup.component';

@Component({
  selector: 'tun-zone-connection-view',
  templateUrl: './zone-connection-view.component.html',
  styleUrls: ['./zone-connection-view.component.scss']
})
export class ZoneConnectionViewComponent implements OnInit {


  //connect is enabled when a remote player is active and it is compatible with Tunify Connect
  get enableConnect$(){
    return this.zoneConnection.externalApplicationsInfo.externalApplicationsInfo$
      .pipe(
        map(externalApplicationsInfos => externalApplicationsInfos != null ? externalApplicationsInfos.filter(externalApplicationsInfo => externalApplicationsInfo.connectionType == ConnectionType.player) : []),
        map(playerExternalApplicationInfos => playerExternalApplicationInfos.length > 0 && playerExternalApplicationInfos[0].connectEnabled)
      )
  }

  get playerActive$(){
    return this.zoneConnection.externalApplicationsInfo.externalApplicationsInfo$
    .pipe(
      map(externalApplicationsInfos => externalApplicationsInfos != null ? externalApplicationsInfos.filter(externalApplicationsInfo => externalApplicationsInfo.connectionType == ConnectionType.player) : []),
      map(playerExternalApplicationInfos => playerExternalApplicationInfos.length > 0)
    )
  }

  get enablePlayer$(){
    return this.zoneConnection.externalApplicationsInfo.externalApplicationsInfo$
      .pipe(
        map(externalApplicationsInfos => externalApplicationsInfos != null ? externalApplicationsInfos.filter(externalApplicationsInfo => externalApplicationsInfo.connectionType == ConnectionType.player && externalApplicationsInfo.dedicated) : []),
        map(playerExternalApplicationInfos => playerExternalApplicationInfos.length == 0)
      )
  }

  //only show a loading info state if we do not already have 'some' name
  get loadingZoneInfo$(){
    return this.zoneConnection.loadingZoneInfo$
      .pipe(map(loading => this.zoneConnection.name != null ? false : loading))
  }

  get heightPerItem$(){
    return this.appService.heightPerItem$;
  }

  get nameForView(){
    if (this._zoneConnection.name){
      return this._zoneConnection.name;
    }else if (this._zoneConnection.username){
      return this._zoneConnection.username;
    }
    return this._zoneConnection.zoneId;
  }

  _zoneConnection: ZoneConnection;
  @Input()
  set zoneConnection(value: ZoneConnection){
    this._zoneConnection = value;
  }
  get zoneConnection(){
    return this._zoneConnection;
  }

  constructor(
    private appService: AppService,
    private zoneConnectionsService: ZoneConnectionsService,
    private _matDialog: MatDialog,
  ) {

  }

  ngOnInit() {
  }

  public connectAsPlayer(){
    this.zoneConnectionsService.activateZoneConnection(this.zoneConnection, ApplicationMode.playerMode);
  }

  public connectAsRemote(){
    this.zoneConnectionsService.activateZoneConnection(this.zoneConnection, ApplicationMode.remoteMode);
  }

  public showNotConnectableAsPlayerInfo(){
    this._matDialog.open(NoPlayerConnectPopupComponent, { width: NoPlayerConnectPopupComponent.widthForPopup, data: {
      applicationMode: ApplicationMode.playerMode,
      zoneConnection: this.zoneConnection
    }});
  }

  public showNotConnectableAsRemoteInfo(){
    this._matDialog.open(NoPlayerConnectPopupComponent, { width: NoPlayerConnectPopupComponent.widthForPopup, data: {
      applicationMode: ApplicationMode.remoteMode,
      zoneConnection: this.zoneConnection
    }});
  }

}
