import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'tun-tunify-logo',
  templateUrl: './tunify-logo.component.html',
  styleUrls: ['./tunify-logo.component.scss']
})
export class TunifyLogoComponent implements OnInit, OnChanges {

  verticalPadding = 20;
  heightForLogo: number;

  @Input() totalHeightForComponent;
  @Input() royaltyFree = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges){
    if (simpleChanges.totalHeightForComponent){
      this.calcHeightPadding();
    }
  }

  private calcHeightPadding(){
    this.verticalPadding = Math.floor(this.totalHeightForComponent * 0.26);
    this.heightForLogo = this.totalHeightForComponent - 2 * this.verticalPadding;
  }

}
