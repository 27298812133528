<div class="container">
  <div *ngIf="header" class="header" translate>{{ header }}</div>
  <button
    *ngFor="let item of items; let i = index"
    class="dropdown-item"
    [class.selected]="i === selectedIndex"
    (click)="onItemClick(i)"
    [disabled]="i === selectedIndex"
    translate>
    {{ item }}
  </button>
  <button class="last-item" (click)="onItemClick(items.length)" translate>
    {{ lastItem }}
  </button>
</div>
