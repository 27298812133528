<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
  y="0px" viewBox="0 0 18 18" enable-background="new 0 0 18 18" xml:space="preserve">
  <rect x="4" y="14" fill="currentColor" width="12" height="1" />
  <rect x="4" y="11" fill="currentColor" width="12" height="1" />
  <rect x="11" y="5" fill="currentColor" width="5" height="1" />
  <rect x="9" y="8" fill="currentColor" width="7" height="1" />
  <g>
    <polygon fill="currentColor" points="9,5 6,5 6,2 4,2 4,5 1,5 1,7 4,7 4,10 6,10 6,7 9,7 	" />
  </g>
</svg>
