import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tun-calendar-icon',
  templateUrl: './calendar-icon.component.html',
  styleUrls: ['./calendar-icon.component.scss']
})
export class CalendarIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
