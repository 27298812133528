<sass-helper></sass-helper>
<div class="container">
  <!-- Left slider. -->
  <div
    #leftSliderBox
    class="slider-box left-side"
    [style.padding]="leftSliderBoxPadding"
    [style.left]="leftSliderBoxLeft"
  ></div>
  <div
    class="slider-container left-side"
    [style.left.%]="leftSliderLeftPercent"
    [style.transition]="transitionSpeed"
    [style.-webkit-transition]="transitionSpeed"
    [style.-moz-transition]="transitionSpeed"
  >
    <div
      #leftSlider
      [style.border-top]="sliderStyle"
      class="slider slider-left"
    ></div>
    <div
      #leftSliderValue
      class="value value-left"
      [style.color]="valueStyle"
      [style.left]="leftSliderValueLeft"
    >
      {{ selectedMinValue }} {{ label }}
    </div>
  </div>

  <!-- Selected and unselected line. -->
  <div class="lines" (click)="lineClicked($event)">
    <div
      #unselectedline
      [style.border-bottom]="unselectedLineStyle"
      class="line unselected-line"
    ></div>
    <div
      #selectedline
      [style.border-bottom]="selectedLineStyle"
      class="line selected-line"
      [style.left.%]="leftSliderLeftPercent"
      [style.right.%]="selectedLineRight"
      [style.transition]="transitionSpeed"
      [style.-webkit-transition]="transitionSpeed"
      [style.-moz-transition]="transitionSpeed"
    ></div>
  </div>

  <!-- Right slider. -->
  <div
    #rightSliderBox
    class="slider-box right-side"
    [style.padding]="rightSliderBoxPadding"
    [style.right]="rightSliderBoxRight"
  ></div>
  <div
    class="slider-container right-side"
    [style.right.%]="rightSliderRightPercent"
    [style.transition]="transitionSpeed"
    [style.-webkit-transition]="transitionSpeed"
    [style.-moz-transition]="transitionSpeed"
  >
    <div [style.border-bottom]="sliderStyle" class="slider slider-right"></div>
    <div
      #rightSliderValue
      class="value value-right"
      [style.color]="valueStyle"
      [style.right]="rightSliderValueRight"
    >
      {{ selectedMaxValue }} {{ label }}
    </div>
  </div>
</div>
