import { Pipe, PipeTransform } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { SongSortMode } from '@model/enums/songSortMode';
import { AudioFileProperty, trackPropertyValue } from '@model/enums/audioFileProperty';

@Pipe({ name: 'songSorter', pure: true })
export class SongSorter implements PipeTransform {

  transform(
    audioFiles: Array<AudioFile>,
    sortMode: SongSortMode,
    audioFileProperty: AudioFileProperty
  ): Array<AudioFile> {
    const files = Array<AudioFile>(...audioFiles);
    switch (sortMode) {
      case SongSortMode.NONE:
        return files;
      case SongSortMode.TITLE:
        return files.sort((a, b) => {
          return a.title === b.title ? 0 : a.title < b.title ? -1 : 1;
        });
      case SongSortMode.GROUP:
        return files.sort((a, b) => {
          return a.group === b.group ? 0 : a.group < b.group ? -1 : 1;
        });
      case SongSortMode.OTHER: // This is just mood for now.
        return files.sort((a, b) => {
          const trackPropA = trackPropertyValue(a, audioFileProperty);
          const trackPropB = trackPropertyValue(b, audioFileProperty);
          return trackPropA === trackPropB
            ? 0
            : trackPropA < trackPropB
            ? -1
            : 1;
        });
      default:
        return files;
    }
  }
}
