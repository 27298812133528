
  <!--
    We need the fadeIn animation on the container 
    to prevent the slideOutLeft animation from running at the initial render.
  -->
  <div class="orange-menu-container" @fadeIn>

    <tun-navigation-menu
      fxFlex
      @slideOutLeft
      *ngIf="!isSubMenuOpen"
      [mainNavigationItems]="mainNavigationItems"
      [heightPerItem]="heightPerItem"
      [loading]="loading$ | async"
      [showLoadError]="loadingError$ | async"
      [style.width.px]="widthForMenuPanel"
      (selectMainNavigationItem)="onSelectMainNavigationItem($event)"
      (selectNavigationItem)="onSelectNavigationItem($event)"
      (deleteNavigationItem)="onDeleteNavigationItem($event)"
      (createItemWithTitle)="onCreateItemWithTitle($event)"
      (changeNavigationItemTitle)="onChangeNavigationItemTitle($event)"
      (dropTrackOnNavigationItem)="onDropTrackOnNavigationItem($event)"
      (reloadMenuContent)="onReloadMenuContent()"
      >
    </tun-navigation-menu>

    <div
      fxFlex
      @slideInRight
      *ngIf="isSubMenuOpen"
      class="orange-sub-menu-container"
      [style.width.px]="widthForMenuPanel">
      <tun-orange-sub-menu
        [style.width.px]="widthForMenuPanel"
        [widthForMenuPanel]="widthForMenuPanel"
        [heightPerItem]="heightPerItem"
        [navigationItem]="subMenu"
        (back)="closeSubMenu()"
        (selectItem)="onSelectNavigationItem($event)"
        (createItemWithTitle)="onCreateItemWithTitle($event)">
      </tun-orange-sub-menu>
    </div>
  </div>




